import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import useCurrencies from 'hooks/useCurrencies';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import Input from 'components/common/input';
import DateRangePicker from "components/common/dateRangePicker";

import { getPlayers, setPlayersFilters } from "store/actions/portal/players/players.action";

import { FILTER_PLAYER_STATE } from "constants/filter.constants";

/** Players Page Filters Component */

const Filters = ({
    setPlayersFilters,
    getPlayers,
    filters,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const [ currencies, getCurrencies ] = useCurrencies(false);

    return (
        <FiltersWrapper
            loadFn={getPlayers}
            setFiltersFn={setPlayersFilters}
            updateProps={[globalProjectId]}
            filters={filters}
            datePickerFields={[
                { name: "date", time: false },
                { name: "modified", time: false }
            ]}
            searchFieldName="nameOrId"
            onInit={() => {
                getCurrencies();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.players.externalId')}
                        name="externalId"
                    >
                        <Input
                            placeholder={`${t("backoffice.common.select")} ${t("backoffice.players.externalId")}`}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.status')}
                        name="state"
                    >
                        <Select
                            options={[
                                { value: FILTER_PLAYER_STATE.ALL, text: t('backoffice.common.all') },
                                { value: FILTER_PLAYER_STATE.ACTIVE, text: t('backoffice.common.active') },
                                { value: FILTER_PLAYER_STATE.BLOCKED, text: t('backoffice.common.blocked') },
                                { value: FILTER_PLAYER_STATE.PARTIALY_BLOCKED, text: t('backoffice.players.partiallyBlocked') },
                                { value: FILTER_PLAYER_STATE.UNKNOWN, text: t('backoffice.players.unknown') }
                            ]}
                            placeholder={`${t("backoffice.common.select")} ${t("backoffice.common.status")}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.players.currency')}
                        name="currencies"
                    >
                        <Select
                            options={
                                currencies.map(item => (
                                    { value: item.code, text: item.code }
                                ))
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.players.currency')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            isMultiple={true}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.players.email')}
                        name="email"
                        className='rt--general-form-item'
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.players.email')}`}
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.players.email')}`}
                            maxLength={50} />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.creationDate')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.lastUpdateDate')}
                        name="modified"
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get players */
    getPlayers: PropTypes.func,
    /** Redux action to set players filters */
    setPlayersFilters: PropTypes.func,
    /** Redux state property, players filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getPlayers: nextPage => {
            dispatch(getPlayers(nextPage));
        },
        setPlayersFilters: filters => {
            dispatch(setPlayersFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.players.filters,
        globalProjectId: state.common.globalProjectId
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);