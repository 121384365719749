import { message } from "antd";

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import {
  setProjectsActionBefore,
  setProjectsActionFinished,
  setProjectsSaveActionBefore,
  setProjectsSaveActionFinished,
} from "./projects.action";

import {
  SET_PROJECT_CUSTOM_HOSTNAMES,
  SET_PROJECT_DOMAIN_SITES,
  UPDATE_PROJECT_DOMAIN_SITES,
} from "../../../actionTypes";

const setCustomHostnames = (hostnames) => ({
  type: SET_PROJECT_CUSTOM_HOSTNAMES,
  payload: { hostnames },
});

export const getCustomHostnames = () => {
  return (dispatch) => {
    dispatch(setProjectsActionBefore());
    return axios({
      url: ApiUrls.GET_CUSTOM_HOSTNAMES,
      method: Methods.GET,
    })
      .then(({ data: { value: hostnames } }) => {
        dispatch(setCustomHostnames(hostnames));
        dispatch(setProjectsActionFinished());
      })
      .catch((ex) => {
        dispatch(setProjectsActionFinished());
      });
  };
};

export const addCustomHostname = (domainType, customHostname, onSuccess) => {
  return (dispatch) => {
    dispatch(setProjectsSaveActionBefore());
    return axios({
      url: ApiUrls.ADD_CUSTOM_HOSTNAME,
      method: Methods.POST,
      data: {
        domainType,
        customHostname,
      },
    })
      .then(({ data }) => {
        if (isResponseSuccess(data)) {
          message.success(data.message);
          dispatch(setCustomHostnames(data.value));
          onSuccess && onSuccess();
        }
        dispatch(setProjectsSaveActionFinished());
      })
      .catch((ex) => {
        dispatch(setProjectsSaveActionFinished());
      });
  };
};

export const removeCustomHostname = (domainType, customHostname) => {
  return (dispatch) => {
    dispatch(setProjectsSaveActionBefore());
    return axios({
      url: ApiUrls.REMOVE_CUSTOM_HOSTNAME,
      method: Methods.DELETE,
      data: {
        domainType,
        customHostname,
      },
    })
      .then(({ data }) => {
        if (isResponseSuccess(data)) {
          message.success(data.message);
          dispatch(setCustomHostnames(data.value));
          onSuccess && onSuccess();
        }
        dispatch(setProjectsSaveActionFinished());
      })
      .catch((ex) => {
        dispatch(setProjectsSaveActionFinished());
      });
  };
};

export const checkCustomHostnameStatus = (domainType, customHostname) => {
  return (dispatch) => {
    dispatch(setProjectsSaveActionBefore());
    return axios({
      url: ApiUrls.CHECK_CUSTOM_HOSTNAME_STATUS,
      method: Methods.POST,
      data: {
        domainType,
        customHostname,
      },
    })
      .then(({ data }) => {
        if (isResponseSuccess(data)) {
          message.success(data.message);
          dispatch(setCustomHostnames(data.value, domainType));
          onSuccess && onSuccess();
        }
        dispatch(setProjectsSaveActionFinished());
      })
      .catch((ex) => {
        dispatch(setProjectsSaveActionFinished());
      });
  };
};

/** Sites */

const setDomainSites = (sites) => ({
  type: SET_PROJECT_DOMAIN_SITES,
  payload: { sites },
});

const updateDomainSites = (site) => ({
  type: UPDATE_PROJECT_DOMAIN_SITES,
  payload: { site },
});

export const getDomainSites = () => {
  return (dispatch) => {
    dispatch(setProjectsActionBefore());
    return axios({
      url: ApiUrls.GET_DOMAIN_SITES,
      method: Methods.GET,
    })
      .then(({ data: { value: sites } }) => {
        dispatch(setDomainSites(sites));
        dispatch(setProjectsActionFinished());
      })
      .catch((ex) => {
        dispatch(setProjectsActionFinished());
      });
  };
};

export const addDomainSite = (domain, onSuccess) => {
  return (dispatch) => {
    dispatch(setProjectsSaveActionBefore());
    return axios({
      url: ApiUrls.ADD_DOMAIN_SITE,
      method: Methods.POST,
      data: {
        domain,
      },
    })
      .then(({ data }) => {
        if (isResponseSuccess(data)) {
          message.success(data.message);
          dispatch(setDomainSites(data.value));
          onSuccess && onSuccess();
        }
        dispatch(setProjectsSaveActionFinished());
      })
      .catch((ex) => {
        dispatch(setProjectsSaveActionFinished());
      });
  };
};

export const removeDomainSite = (id) => {
  return (dispatch) => {
    dispatch(setProjectsSaveActionBefore());
    return axios({
      url: ApiUrls.REMOVE_DOMAIN_SITE,
      method: Methods.DELETE,
      data: {
        id,
      },
    })
      .then(({ data }) => {
        if (isResponseSuccess(data)) {
          message.success(data.message);
          dispatch(setDomainSites(data.value));
        }
        dispatch(setProjectsSaveActionFinished());
      })
      .catch((ex) => {
        dispatch(setProjectsSaveActionFinished());
      });
  };
};

export const addSiteCname = (name, id, onSuccess) => {
  return (dispatch) => {
    dispatch(setProjectsSaveActionBefore());
    return axios({
      url: ApiUrls.ADD_DOMAIN_RECORD,
      method: Methods.POST,
      data: {
        name,
        id,
      },
    })
      .then(({ data }) => {
        if (isResponseSuccess(data)) {
          message.success(data.message);
          dispatch(updateDomainSites(data.value));
          onSuccess && onSuccess();
        }
        dispatch(setProjectsSaveActionFinished());
      })
      .catch((ex) => {
        dispatch(setProjectsSaveActionFinished());
      });
  };
};

export const removeSiteCname = (recordId, id) => {
  return (dispatch) => {
    dispatch(setProjectsSaveActionBefore());
    return axios({
      url: ApiUrls.REMOVE_DOMAIN_RECORD,
      method: Methods.DELETE,
      data: {
        recordId,
        id,
      },
    })
      .then(({ data }) => {
        if (isResponseSuccess(data)) {
          message.success(data.message);
          dispatch(updateDomainSites(data.value));
        }
        dispatch(setProjectsSaveActionFinished());
      })
      .catch((ex) => {
        dispatch(setProjectsSaveActionFinished());
      });
  };
};
