import {
    SET_PERFORMANCE_REPORTS_ACTION_BEFORE,
    SET_PERFORMANCE_REPORTS_ACTION_FINISH
} from "../../../../actionTypes";

export const setPerformanceReportsActionBefore = () => ({
    type: SET_PERFORMANCE_REPORTS_ACTION_BEFORE,
});

export const setPerformanceReportsActionFinished = () => ({
    type: SET_PERFORMANCE_REPORTS_ACTION_FINISH,
});