//#region react
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

//#endregion

//#region actions
import {
    getPermissionRequests,
    approvePermissionRequest,
    rejectPermissionRequest
} from "store/actions/portal/userManagement/requests/pending.action";
//#endregion

//#region components
import Table from "components/common/table";
import Confirmation from "components/common/confirmation";
import TabTableDashboardLayout from "components/layouts/tab/table";
import Notes from "components/common/notes";
//#endregion

//#region hooks
import useFirstRender from 'hooks/useFirstRender';
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { tableColumnsCreator } from "utils/tableColumnsCreator";
import { hasPermission } from 'utils/permissions';
import { binaryToFlags } from "utils/common";
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { EXPAND_TABLE_TYPES } from 'constants/common.constants';
import { PERMISSION_REQUEST_TYPE } from "constants/permissionRequest.constants";
import { PENDING_PERMISSION_REQUESTS } from "constants/pageName.constants";

import { getTableColumns, expandColumns } from "../columns";
//#endregion

//#region types
import permissionRequestType from "types/permissionRequest/request.type";
import notificationType from 'types/notification/notification.type';
//#endregion

const EXPANDED_ROW_UNIQUE_KEY_PROP = "index";

const ACTIONS = {
    APPROVE: 1,
    REJECT: 2
}

const mapPropToEachRowData = (rowsData) => {
    return (
        rowsData.map((rowData, index) => ({
            change: rowData,
            index: index + 1
        }))
    );
}

const getExpandTableDetails = ({ record, expandTableColumns }) => {
    if (!record.changes) {
        return null;
    }

    const expandTableData = mapPropToEachRowData(record.changes);

    return {
        columns: expandTableColumns,
        data: expandTableData,
        uniqueKey: EXPANDED_ROW_UNIQUE_KEY_PROP,
    }
}

/** Permission Requests Page Component */
const PermissionRequestsComponent = ({
    getPermissionRequests,
    requests,
    isLoading,
    globalCompanyId,
    approvePermissionRequest,
    rejectPermissionRequest,
    notifications
}) => {
    const { t } = useTranslation();

    const { search } = useLocation();

    const [confirmation, setConfirmation] = useState(null);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PENDING_PERMISSION_REQUESTS });

    const objectId = (new URLSearchParams(search)).get("objectId");
    const objectType = (new URLSearchParams(search)).get("objectType");
    const actionTypesFromSearch = (new URLSearchParams(search)).get("actionTypes") ?? 0;
    const actionTypes = binaryToFlags(Object.values(PERMISSION_REQUEST_TYPE), Number(actionTypesFromSearch))

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasPermissionRequestModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PERMISSION_REQUESTS,
        action: PERMISSION_ACTION.MODIFY
    })

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        expandTableColumns,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            expandColumns,
            constructForInclude: true,
            includedColumns,
            additionalProps: {
                isHistory: false
            }
        });
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasPermissionRequestModifyPermission) {
        tableRowActions.push(
            {
                title: t('backoffice.common.reject'),
                icon: "close",
                onClick: record => {
                    setConfirmation({
                        id: record.id,
                        type: ACTIONS.REJECT
                    })
                },
            },
            {
                title: t('backoffice.common.approve'),
                icon: "ok",
                onClick: record => {
                    setConfirmation({
                        id: record.id,
                        type: ACTIONS.APPROVE
                    })
                },
            },
        )
    }

    const handleConfirmation = note => {
        if (confirmation.type === ACTIONS.APPROVE) {
            approvePermissionRequest(confirmation.id);
        } else if (confirmation.type === ACTIONS.REJECT) {
            rejectPermissionRequest(confirmation.id, note);
        }
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
    }

    //#endregion

    useFirstRender({
        dependencies: [globalCompanyId, notifications.length],
        onFirstRenderCB: () => getPermissionRequests(objectId, actionTypes, objectType),
        afterFirstRenderCB: () => getPermissionRequests()
    })

    return (
        <TabTableDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={requests}
                total={requests.length}
                disableFullView={true}
                noPagination={true}
                actions={tableRowActions}
                expandable={{
                    title: t('backoffice.users.changes'),
                    disabled: requests.filter(requestData => !requestData.changes?.length).map(request => request.id),
                    details: record => (
                        getExpandTableDetails({
                            record,
                            expandTableColumns,
                        })
                    ),
                    type: EXPAND_TABLE_TYPES.TABLE
                }}
            />
            {
                (
                    confirmation?.type === ACTIONS.APPROVE
                ) && (
                    <Confirmation
                        title={t('backoffice.common.approve')}
                        message={t('backoffice.common.approveConfirmationMessage')}
                        onOk={handleConfirmation}
                        onCancel={() => setConfirmation(null)}
                        isVisible={true}
                    />
                )
            }

            {
                (
                    confirmation?.type === ACTIONS.REJECT
                ) && (
                    <Notes
                        onClose={() => setConfirmation(null)}
                        onSuccess={value => {
                            handleConfirmation(value);
                        }}
                    />
                )
            }

        </TabTableDashboardLayout>
    )
};

/** PermissionRequestsComponent propTypes
 * PropTypes
*/
PermissionRequestsComponent.propTypes = {
    /** Redux action to get permission requests */
    getPermissionRequests: PropTypes.func,
    /** Redux state property, represents the array of requests  */
    requests: PropTypes.arrayOf(permissionRequestType),
    /** Redux state property, is true when loading requests */
    isLoading: PropTypes.bool,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux action to approve permission request */
    approvePermissionRequest: PropTypes.func,
    /** Redux action to reject permission request */
    rejectPermissionRequest: PropTypes.func,
    /** Redux state, represents the array of notifications  */
    notifications: PropTypes.arrayOf(notificationType),
};

const mapDispatchToProps = dispatch => ({
    getPermissionRequests: (userId, actionType, objectType) => {
        dispatch(getPermissionRequests(userId, actionType, objectType));
    },

    approvePermissionRequest: id => {
        dispatch(approvePermissionRequest(id));
    },

    rejectPermissionRequest: (id, note) => {
        dispatch(rejectPermissionRequest(id, note));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.permissionRequests.isLoading,
        requests: state.permissionRequests.pending.requests,
        globalCompanyId: state.common.globalCompanyId,
        notifications: state.notifications.notifications,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PermissionRequestsComponent);
