import React from "react";

import CampaignsEditComponent from "pages/marketingTools/campaigns/edit/campaign-edit.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const CampaignsEditRoute = () => {
    return <CampaignsEditComponent />;
};

export default withPermission(
    withAuth(CampaignsEditRoute),
    [
        {
            resource: PERMISSION_RESOURCE.MARKETING_TOOLS_CAMPAIGN,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.MARKETING_TOOLS_CREATIVE,
            action: PERMISSION_ACTION.VIEW,
        },
    ],
    Paths.PORTAL
);
