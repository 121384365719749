import React from 'react';

import MobileFilters from './mobile';
import DesktopFilters from './desktop';

import { isMobile } from 'utils/common';

const Filters = props => {
    return (
        isMobile()
            ? <MobileFilters { ...props } />
            : <DesktopFilters { ...props } />
    )
}

export default Filters;