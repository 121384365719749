import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Spin } from "antd";

import { unsubscribeFromNewsletter } from 'store/actions/portal/communications/newsletter/unsubscribe.action';

/** Newsletter Unsubscribe Page Component */
const UnsubscribeComponent = ({
    unsubscribeFromNewsletter,
    isLoading
}) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const token = searchParams.get("token");

    const [unsubscribed, setUnsubscribed] = useState(false)

    useEffect(() => {
        if (token) {
            unsubscribeFromNewsletter(token, () => {
                setUnsubscribed(true)
            })
        }
    }, [])

    return (
        <div className="rt--auth-form">
            {
                !token ? (
                    <div className='rt--auth-error rt--mb-16 rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16'>
                        <b className='rt--title rt--font-medium rt--font-normal'>
                            {
                                t("backoffice.authentication.noTokenFound")
                            }
                        </b>
                    </div>
                ) : (
                    <Spin spinning={isLoading}>
                        {
                            unsubscribed && (
                                <div className='rt--auth-success rt--mb-16 rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16'>
                                    <b className='rt--title rt--font-medium rt--font-normal'>
                                        {
                                            t("backoffice.communication.youUnsubscribedFromNewsletter")
                                        }
                                    </b>
                                </div>
                            )
                        }
                    </Spin>
                )
            }
        </div>
    )
}

/** UnsubscribeComponent propTypes
     * PropTypes
*/
UnsubscribeComponent.propTypes = {
    /** Redux state property, is true when unsubscribe request is in process */
    isLoading: PropTypes.bool,
    /** Redux action to unsubscribe from newsletter */
    unsubscribeFromNewsletter: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        unsubscribeFromNewsletter: (token, onSuccess) => {
            dispatch(unsubscribeFromNewsletter(token, onSuccess))
        }
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.newsletter.isLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnsubscribeComponent);