import React from "react";

import SystemPayments from "pages/settings/systemPayments/systemPayments.component";
import withPermission from "hocs/withPermission";
import withAuth from "hocs/withAuth";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const SystemPaymentsRoute = () => {
    return <SystemPayments />;
};

export default withPermission(
    withAuth(SystemPaymentsRoute),
    {
        resource: PERMISSION_RESOURCE.SYSTEM_PAYMENT_METHODS,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.PORTAL
);
