import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { sendToPromoIframe } from "utils/promo";

import {
    setPromoCustomizeActionBefore,
    setPromoCustomizeActionFinished,
    setPromoCustomizeSaveActionBefore,
    setPromoCustomizeSaveActionFinished
} from "./common.action";

import {
    SET_PROMO_CUSTOMIZE_DEFAULT_LANGUAGES,
    SET_PROMO_CUSTOMIZE_LANGUAGES,
    SET_PROMO_CUSTOMIZE_SECTION_DATA,
    SET_PROMO_CUSTOMIZE_SECTION_STATE,
    SET_PROMO_CUSTOMIZE_SECTION_TRANSLATIONS,
    UPDATE_PROMO_CUSTOMIZE_SECTION_TRANSLATIONS,
    SET_PROMO_CUSTOMIZE_SECTION_TRANSLATION_GROUPS
} from 'store/actionTypes';

import { TRANSLATION_SOURCE_TYPE } from "constants/translation.constants";
import { PROMO_SECTION_TYPE, PROMO_UPDATE_TYPE } from "constants/promo.constants";

//#region ------------------------------------------ SECTIONS -----------------------------------------//

const setPromoSectionData = (data, sectionType) => ({
    type: SET_PROMO_CUSTOMIZE_SECTION_DATA,
    payload: { data, sectionType }
})

export const getPromoSection = (sectionType) => {
    return (dispatch, getState) => {
        dispatch(setPromoCustomizeActionBefore());

        const state = getState();
        const selectedLanguageCode = state.promoCustomize.customizeLanguage;

        return axios({
            url: ApiUrls.GET_PROMO_SECTION_ITEM,
            method: Methods.GET,
            params: {
                type: sectionType,
                languageCode: selectedLanguageCode
            }
        })
            .then(({ data: { value } }) => {
                dispatch(
                    setPromoSectionData(
                        value,
                        sectionType,
                    )
                );
                dispatch(setPromoCustomizeActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeActionFinished());
            })
    }
}

export const savePromoSection = (sectionType, requestBody) => {
    return (dispatch, getState) => {
        dispatch(setPromoCustomizeSaveActionBefore());

        const state = getState();
        const selectedLanguageCode = state.promoCustomize.customizeLanguage;

        return axios({
            url: ApiUrls.SAVE_PROMO_SECTION_ITEM,
            method: Methods.POST,
            data: {
                ...requestBody,
                type: sectionType,
                languageCode: selectedLanguageCode
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(
                        setPromoSectionData(
                            data.value,
                            sectionType,
                        )
                    );

                    sendToPromoIframe(PROMO_UPDATE_TYPE.DYNAMIC_DATA, {
                        sectionType,
                        data: data.value
                    })
                }
                dispatch(setPromoCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeSaveActionFinished());
            })
    }
}


//#region ------------------------------------------- BRANDS ------------------------------------------//

export const getPromoBrands = () => {
    return (dispatch, getState) => {
        dispatch(setPromoCustomizeActionBefore());

        const state = getState();
        const selectedLanguageCode = state.promoCustomize.customizeLanguage.toUpperCase();

        return axios({
            url: ApiUrls.GET_PROMO_BRANDS,
            method: Methods.GET,
            params: {
                languageCode: selectedLanguageCode
            }
        })
            .then(({ data: { value } }) => {
                dispatch(
                    setPromoSectionData(
                        value,
                        PROMO_SECTION_TYPE.BRANDS,
                    ),
                );
                dispatch(setPromoCustomizeActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeActionFinished());
            })
    }
}

export const addPromoBrand = (requestBody) => {
    return (dispatch, getState) => {
        dispatch(setPromoCustomizeSaveActionBefore());

        const state = getState();
        const selectedLanguageCode = state.promoCustomize.customizeLanguage;

        return axios({
            url: ApiUrls.ADD_PROMO_BRAND,
            method: Methods.POST,
            data: {
                ...requestBody,
                languageCode: selectedLanguageCode,
            }
        })
            .then(({ data: { value } }) => {
                dispatch(
                    setPromoSectionData(
                        value,
                        PROMO_SECTION_TYPE.BRANDS,
                    ),
                );

                sendToPromoIframe(PROMO_UPDATE_TYPE.DYNAMIC_DATA, {
                    sectionType: PROMO_SECTION_TYPE.BRANDS,
                    data: value
                })

                dispatch(setPromoCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeSaveActionFinished());
            })
    }
}

export const savePromoBrand = (requestBody) => {
    return (dispatch, getState) => {
        dispatch(setPromoCustomizeSaveActionBefore());

        const state = getState();
        const selectedLanguageCode = state.promoCustomize.customizeLanguage;

        return axios({
            url: ApiUrls.SAVE_PROMO_BRAND,
            method: Methods.POST,
            data: {
                ...requestBody,
                languageCode: selectedLanguageCode
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(
                        setPromoSectionData(
                            data.value,
                            PROMO_SECTION_TYPE.BRANDS,
                        ),
                    );

                    sendToPromoIframe(PROMO_UPDATE_TYPE.DYNAMIC_DATA, {
                        sectionType: PROMO_SECTION_TYPE.BRANDS,
                        data: data.value
                    })
                }
                dispatch(setPromoCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeSaveActionFinished());
            })
    }
}

export const deletePromoBrand = (requestBody) => {
    return (dispatch, getState) => {
        dispatch(setPromoCustomizeSaveActionBefore());

        const state = getState();
        const selectedLanguageCode = state.promoCustomize.customizeLanguage;

        return axios({
            url: ApiUrls.DELETE_PROMO_BRAND,
            method: Methods.DELETE,
            data: {
                ...requestBody,
                languageCode: selectedLanguageCode
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(
                        setPromoSectionData(
                            data.value,
                            PROMO_SECTION_TYPE.BRANDS,
                        ),
                    );
                    sendToPromoIframe(PROMO_UPDATE_TYPE.DYNAMIC_DATA, {
                        sectionType: PROMO_SECTION_TYPE.BRANDS,
                        data: data.value
                    })
                }
                dispatch(setPromoCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeSaveActionFinished());
            })
    }
}

//#endregion

//#region ------------------------------------------- STORIES -----------------------------------------//

export const getPromoStories = () => {
    return (dispatch, getState) => {
        dispatch(setPromoCustomizeActionBefore());

        const state = getState();
        const selectedLanguageCode = state.promoCustomize.customizeLanguage.toUpperCase();

        return axios({
            url: ApiUrls.GET_PROMO_STORY_GROUPS,
            method: Methods.GET,
            params: {
                languageCode: selectedLanguageCode
            }
        })
            .then(({ data: { value } }) => {
                dispatch(setPromoSectionData(value, PROMO_SECTION_TYPE.STORIES));
                dispatch(setPromoCustomizeActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeActionFinished());
            })
    }
}

export const addPromoStoryBlock = (requestBody) => {
    return (dispatch, getState) => {
        dispatch(setPromoCustomizeSaveActionBefore());

        const state = getState();
        const selectedLanguageCode = state.promoCustomize.customizeLanguage;

        const formData = new FormData();

        Object.keys(requestBody).forEach((key) => {
            formData.append(key, requestBody[key]);
        });

        formData.append("languageCode", selectedLanguageCode)

        return axios({
            url: ApiUrls.ADD_PROMO_STORY_GROUP,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            data: formData
        })
            .then(({ data: { value } }) => {
                dispatch(
                    setPromoSectionData(value, PROMO_SECTION_TYPE.STORIES),
                );

                sendToPromoIframe(PROMO_UPDATE_TYPE.DYNAMIC_DATA, {
                    sectionType: PROMO_SECTION_TYPE.STORIES,
                    data: value
                })

                dispatch(setPromoCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeSaveActionFinished());
            })
    }
}

export const savePromoStoryBlock = (requestBody) => {
    return (dispatch, getState) => {
        dispatch(setPromoCustomizeSaveActionBefore());

        const state = getState();
        const selectedLanguageCode = state.promoCustomize.customizeLanguage;

        const formData = new FormData();

        Object.keys(requestBody).forEach((key) => {
            formData.append(key, requestBody[key]);
        });

        formData.append("languageCode", selectedLanguageCode)

        return axios({
            url: ApiUrls.SAVE_PROMO_STORY_GROUP,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            data: formData
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(
                        setPromoSectionData(data.value, PROMO_SECTION_TYPE.STORIES),
                    );

                    sendToPromoIframe(PROMO_UPDATE_TYPE.DYNAMIC_DATA, {
                        sectionType: PROMO_SECTION_TYPE.STORIES,
                        data: data.value
                    })
                }
                dispatch(setPromoCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeSaveActionFinished());
            })
    }
}

export const deletePromoStoryBlock = (requestBody) => {
    return (dispatch, getState) => {
        dispatch(setPromoCustomizeSaveActionBefore());

        const state = getState();
        const selectedLanguageCode = state.promoCustomize.customizeLanguage;

        return axios({
            url: ApiUrls.DELETE_PROMO_STORY_GROUP,
            method: Methods.DELETE,
            data: {
                ...requestBody,
                languageCode: selectedLanguageCode
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(
                        setPromoSectionData(data.value, PROMO_SECTION_TYPE.STORIES),
                    );

                    sendToPromoIframe(PROMO_UPDATE_TYPE.DYNAMIC_DATA, {
                        sectionType: PROMO_SECTION_TYPE.STORIES,
                        data: data.value
                    })
                }
                dispatch(setPromoCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeSaveActionFinished());
            })
    }
}

const getUpdatedStoriesData = (oldStoriesData, groupId, stories) => {
    return oldStoriesData.reduce((newStoriesData, oldStoryData) => {
        if (oldStoryData.id === groupId) {
            newStoriesData.push({
                ...oldStoryData,
                stories: stories,
            })

            return newStoriesData
        }

        newStoriesData.push(oldStoryData);

        return newStoriesData
    }, [])
}

export const addPromoStory = (requestBody) => {
    return (dispatch, getState) => {
        dispatch(setPromoCustomizeSaveActionBefore());

        const state = getState();
        const selectedLanguageCode = state.promoCustomize.customizeLanguage;

        const formData = new FormData();

        Object.keys(requestBody).forEach((key) => {
            formData.append(key, requestBody[key]);
        });

        formData.append("languageCode", selectedLanguageCode)

        return axios({
            url: ApiUrls.ADD_PROMO_STORY,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            data: formData
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    const oldStoriesData = state.promoCustomize.sections[PROMO_SECTION_TYPE.STORIES].data;

                    const newStoriesData = getUpdatedStoriesData(
                        oldStoriesData,
                        requestBody.groupId,
                        data.value
                    )

                    dispatch(
                        setPromoSectionData(newStoriesData, PROMO_SECTION_TYPE.STORIES),
                    );

                    sendToPromoIframe(PROMO_UPDATE_TYPE.DYNAMIC_DATA, {
                        sectionType: PROMO_SECTION_TYPE.STORIES,
                        data: newStoriesData
                    })
                }
                dispatch(setPromoCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeSaveActionFinished());
            })
    }
}

export const deletePromoStory = (requestBody) => {
    return (dispatch, getState) => {
        dispatch(setPromoCustomizeSaveActionBefore());

        const state = getState();
        const selectedLanguageCode = state.promoCustomize.customizeLanguage;

        return axios({
            url: ApiUrls.DELETE_PROMO_STORY,
            method: Methods.DELETE,
            data: {
                ...requestBody,
                languageCode: selectedLanguageCode
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    const oldStoriesData = state.promoCustomize.sections[PROMO_SECTION_TYPE.STORIES].data;

                    const newStoriesData = getUpdatedStoriesData(
                        oldStoriesData,
                        requestBody.groupId,
                        data.value
                    )

                    dispatch(
                        setPromoSectionData(newStoriesData, PROMO_SECTION_TYPE.STORIES),
                    );

                    sendToPromoIframe(PROMO_UPDATE_TYPE.DYNAMIC_DATA, {
                        sectionType: PROMO_SECTION_TYPE.STORIES,
                        data: newStoriesData
                    })
                }
                dispatch(setPromoCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeSaveActionFinished());
            })
    }
}

//#endregion

//#region ------------------------------------------- FOOTER ------------------------------------------//


export const getPromoContacts = () => {
    return dispatch => {
        dispatch(setPromoCustomizeActionBefore());

        return axios({
            url: ApiUrls.GET_PROMO_CONTACTS,
            method: Methods.GET
        })
            .then(({ data: { value } }) => {
                dispatch(setPromoSectionData(value, PROMO_SECTION_TYPE.FOOTER));
                dispatch(setPromoCustomizeActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeActionFinished());
            })
    }
}

export const savePromoContacts = (requestBody) => {
    return dispatch => {
        dispatch(setPromoCustomizeSaveActionBefore());

        return axios({
            url: ApiUrls.SAVE_PROMO_CONTACTS,
            method: Methods.POST,
            data: {
                ...requestBody,
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(
                        dispatch(setPromoSectionData(data.value, PROMO_SECTION_TYPE.FOOTER))
                    );
                    sendToPromoIframe(PROMO_UPDATE_TYPE.CONTACTS, {
                        data: data.value
                    })
                }
                dispatch(setPromoCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeSaveActionFinished());
            })
    }
}

//#endregion

//#endregion

//#region --------------------------------------- TOGGLE SECTION --------------------------------------//

const setPromoSectionState = ({ enabled, sectionName }) => ({
    type: SET_PROMO_CUSTOMIZE_SECTION_STATE,
    payload: { enabled, sectionName }
})

export const togglePromoSection = ({ type, enabled, sectionName }) => {
    return (dispatch, getState) => {
        dispatch(setPromoCustomizeSaveActionBefore());

        const state = getState();
        const selectedLanguageCode = state.promoCustomize.customizeLanguage;

        return axios({
            url: ApiUrls.SAVE_PROMO_SECTION,
            method: Methods.POST,
            data: {
                type,
                enabled,
                languageCode: selectedLanguageCode
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(setPromoSectionState({ enabled, sectionName }));

                    sendToPromoIframe(PROMO_UPDATE_TYPE.SECTION, {
                        sectionType : type,
                        enabled
                    })
                }
                dispatch(setPromoCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeSaveActionFinished());
            })
    }
}


//#endregion

//#region ----------------------------------------- LANGUAGES -----------------------------------------//

const setPromoLanguages = (languages) => ({
    type: SET_PROMO_CUSTOMIZE_LANGUAGES,
    payload: languages
})

const changePromoDefaultLanguage = (newDefaultLanguageCode) => ({
    type: SET_PROMO_CUSTOMIZE_DEFAULT_LANGUAGES,
    payload: newDefaultLanguageCode
})

const constructLanguagesTableList = (availableLanguagesObj) => {
    const languages = [];

    for (const key in availableLanguagesObj) {
        languages.push({
            languageCode: key,
            enabled: availableLanguagesObj[key]
        })
    }

    return languages;
}

export const getPromoLanguages = () => {
    return dispatch => {
        dispatch(setPromoCustomizeActionBefore());

        return axios({
            url: ApiUrls.GET_PROMO_LANGUAGES,
            method: Methods.GET,
        })
            .then(({ data: { value: availableLanguagesObj } }) => {
                dispatch(setPromoLanguages(constructLanguagesTableList(availableLanguagesObj)));
                dispatch(setPromoCustomizeActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeActionFinished());
            })
    }
}

export const savePromoLanguages = (requestBody) => {
    return dispatch => {
        dispatch(setPromoCustomizeSaveActionBefore());

        return axios({
            url: ApiUrls.SAVE_PROMO_LANGUAGE,
            method: Methods.POST,
            data: requestBody
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(setPromoLanguages(constructLanguagesTableList(data.value)));

                    sendToPromoIframe(PROMO_UPDATE_TYPE.LANGUAGES_LIST, {
                        languages: data.value
                    })
                }
                dispatch(setPromoCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeSaveActionFinished());
            })
    }
}

export const setPromoDefaultLanguage = (requestBody) => {
    return dispatch => {
        dispatch(setPromoCustomizeSaveActionBefore());

        return axios({
            url: ApiUrls.SET_PROMO_DEFAULT_LANGUAGE,
            method: Methods.POST,
            data: requestBody
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(changePromoDefaultLanguage(requestBody.languageCode));
                    sendToPromoIframe(PROMO_UPDATE_TYPE.DEFAULT_LANGUAGE_CODE, {
                        language: requestBody.languageCode
                    })
                }
                dispatch(setPromoCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeSaveActionFinished());
            })
    }
}

//#endregion

//#region ---------------------------------------- TRANSLATIONS ---------------------------------------//

const setPromoTranslations = (translations, sectionType) => ({
    type: SET_PROMO_CUSTOMIZE_SECTION_TRANSLATIONS,
    payload: { translations, sectionType }
})

const updatePromoTranslations = (sectionType, key, data) => ({
    type: UPDATE_PROMO_CUSTOMIZE_SECTION_TRANSLATIONS,
    payload: { sectionType, key, data }
})

const setPromoTranslationGroups = groups => ({
    type: SET_PROMO_CUSTOMIZE_SECTION_TRANSLATION_GROUPS,
    payload: { groups }
})

const makeObjectOfSeparatedTranslationKeys = (translations) => {
    return translations.reduce((acc, translationData) => {
        // In translationData.translations we have only one item because we can choose only one language
        const translationInfo = translationData.translations[0]
        const currentTranslation = translationInfo.text;
        const isOverwritten = translationInfo.source === TRANSLATION_SOURCE_TYPE.OVERRIDEN;


        return {
            ...acc,
            [translationData.key]: {
                isOverwritten,
                key: translationData.key,
                translation: currentTranslation,
            }
        }
    }, {})
}

export const getPromoSectionTranslations = (sectionName, sectionType) => {
    return (dispatch, getState) => {
        dispatch(setPromoCustomizeActionBefore());

        const state = getState();

        const selectedLanguageCode = state.promoCustomize.customizeLanguage;

        const promoTranslationGroups = state.promoCustomize.translationGroups;
        const currentSectionGroupData = promoTranslationGroups.find(groupData => (
            groupData.name.toLowerCase() === sectionName.toLowerCase()
        ))

        return axios({
            url: ApiUrls.GET_PROMO_TRANSLATION,
            method: Methods.GET,
            params: {
                languageCode: selectedLanguageCode,
                subGroupId: currentSectionGroupData.id
            }
        })
            .then(({ data: { value: translations } }) => {
                dispatch(setPromoTranslations(
                    makeObjectOfSeparatedTranslationKeys(translations),
                    sectionType
                ));

                dispatch(setPromoCustomizeActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeActionFinished());
            })
    }
}

export const savePromoSectionTranslations = (sectionName, sectionType, key, text) => {
    return (dispatch, getState) => {
        dispatch(setPromoCustomizeActionBefore());

        const state = getState();

        const selectedLanguageCode = state.promoCustomize.customizeLanguage;

        const promoTranslationGroups = state.promoCustomize.translationGroups;
        const currentSectionGroupData = promoTranslationGroups.find(groupData => (
            groupData.name.toLowerCase() === sectionName.toLowerCase()
        ))

        return axios({
            url: ApiUrls.SAVE_PROMO_TRANSLATION,
            method: Methods.POST,
            data: {
                languageCode: selectedLanguageCode.toUpperCase(),
                subGroupId: currentSectionGroupData.id,
                key,
                text,
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    const newTranslationData = {
                        translation: data.value.text,
                        isOverwritten: data.value.source === TRANSLATION_SOURCE_TYPE.OVERRIDEN,
                        key,
                    }

                    dispatch(
                        updatePromoTranslations(
                            sectionType,
                            key,
                            newTranslationData,
                        )
                    );
                    sendToPromoIframe(PROMO_UPDATE_TYPE.TEXT, {
                        sectionType,
                        key,
                        text: data.value.text
                    })
                }

                dispatch(setPromoCustomizeActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeActionFinished());
            })
    }
}

export const resetPromoSectionTranslations = (sectionName, sectionType, key) => {
    return (dispatch, getState) => {
        dispatch(setPromoCustomizeActionBefore());

        const state = getState();

        const selectedLanguageCode = state.promoCustomize.customizeLanguage;

        const promoTranslationGroups = state.promoCustomize.translationGroups;
        const currentSectionGroupData = promoTranslationGroups.find(groupData => (
            groupData.name.toLowerCase() === sectionName.toLowerCase()
        ))

        return axios({
            url: ApiUrls.RESET_PROMO_TRANSLATION,
            method: Methods.DELETE,
            data: {
                languageCode: selectedLanguageCode.toUpperCase(),
                subGroupId: currentSectionGroupData.id,
                key,
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    const newTranslationData = {
                        translation: data.value.text,
                        isOverwritten: data.value.source === TRANSLATION_SOURCE_TYPE.OVERRIDEN,
                        key,
                    }

                    dispatch(
                        updatePromoTranslations(
                            sectionType,
                            key,
                            newTranslationData,
                        )
                    );

                    sendToPromoIframe(PROMO_UPDATE_TYPE.TEXT, {
                        sectionType,
                        key,
                        text: data.value.text
                    })
                }

                dispatch(setPromoCustomizeActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeActionFinished());
            })
    }
}

export const getPromoTranslationGroups = () => {
    return dispatch => {

        return axios({
            url: ApiUrls.GET_PROMO_TRANSLATION_GROUPS,
            method: Methods.GET,
            params: {}
        })
            .then(({ data: { value: groups } }) => {
                dispatch(setPromoTranslationGroups(groups[0].subGroups));
            })
            .catch((ex) => {
                console.log(ex)
            });
    }
}
//#endregion