import PropTypes from 'prop-types';

import { PROJECT_PAYMENT_STATE } from 'constants/project.constants';

export const paymentCurrencyType = PropTypes.shape({
    currencyCode: PropTypes.string,
    commission: PropTypes.number,
    minThreshold: PropTypes.number
})

export default PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    state: PropTypes.oneOf(Object.values(PROJECT_PAYMENT_STATE)),
    currencies: PropTypes.arrayOf(paymentCurrencyType)
})