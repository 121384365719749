export const addQueryStringParamsToUrl = (path, search, hash, params) => {
    const queryParams = new URLSearchParams(search);
    Object.keys(params).forEach(p => {
        queryParams.append(p, params[p])
    });

    let queryString = queryParams.toString();
    queryString = queryString ? "?" + queryString : queryString;

    return `${path}${queryString}${hash}`;
}

export const deleteQueryStringParamsToUrl = (path, search, hash, params) => {
    const queryParams = new URLSearchParams(search);
    params.forEach(p => {
        queryParams.delete(p)
    });

    let queryString = queryParams.toString();
    queryString = queryString ? "?" + queryString : queryString;

    return `${path}${queryString}${hash}`;
}