import React from 'react';

import { useTranslation } from 'react-i18next';

import Tabs from 'components/common/tabs';

import SystemNotificationEmail from './sections/email';

import Paths from 'constants/path.constants';

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';

/** System Notifications Edit Page Component */
const SystemNotificationsEdit = () => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.communication.email"),
            permissions: [
                { resource: PERMISSION_RESOURCE.COMMUNICATION_SYSTEM_TEMPLATES, action: PERMISSION_ACTION.VIEW },
                { resource: PERMISSION_RESOURCE.COMMUNICATION_PROJECT_TEMPLATES, action: PERMISSION_ACTION.VIEW }
            ],
            component: <SystemNotificationEmail />
        }
    ]

    return (
        <Tabs
            items={items}
            mainPage={
                { title: t('backoffice.menu.systemNotifications'), path: Paths.SYSTEM_NOTIFICATIONS }
            }
        />
    )
}


export default SystemNotificationsEdit;