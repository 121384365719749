import { useDispatch, useSelector } from 'react-redux';

const selectGlobalCompanyId = state => state.common.globalCompanyId;
const selectGlobalProjectId = state => state.common.globalProjectId;
const selectCompanies = state => state.profile.userInfo.companies;

const useCurrentProject = () => {
    //#region ------------------------------------------- REDUX --------------------------------------------//

    const dispatch = useDispatch();
    const globalCompanyId = useSelector(selectGlobalCompanyId);
    const globalProjectId = useSelector(selectGlobalProjectId);
    const companies = useSelector(selectCompanies);

    //#endregion

    const company = companies?.find(c => c.id === globalCompanyId);
    const project = (company?.projects ?? []).find(p => p.id === globalProjectId);

    return project;
}

export default useCurrentProject;