import axios from "axios";
import { message } from "antd";

import { isResponseSuccess } from "utils/request";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
    SET_AFFILIATE_GROUP_MEMBERS,
    SET_AFFILIATE_GROUP_MEMBERS_SORTING,
    SET_AFFILIATE_GROUP_MEMBERS_FILTERS,
    SET_AFFILIATE_GROUP_FILTER
} from "../../../../actionTypes";

import { 
    setAffiliateGroupsActionBefore, 
    setAffiliateGroupsActionFinished ,
    setAffiliateGroupsSaveActionBefore,
    setAffiliateGroupsSaveActionFinished
} from "./groups.action";

import { isMobile } from "utils/common";

import { AFFILIATE_GROUP_OPERATION_TYPE } from "constants/affiliate.constants";


const setAffiliateGroupMembers = (members, add) => ({
    type: SET_AFFILIATE_GROUP_MEMBERS,
    payload: { members, add }
})


export const setAffiliateGroupMembersSorting = sorting => ({
    type: SET_AFFILIATE_GROUP_MEMBERS_SORTING,
    payload: { sorting },
});

export const setAffiliateGroupMembersFilters = filters => ({
    type: SET_AFFILIATE_GROUP_MEMBERS_FILTERS,
    payload: { filters },
});

export const getAffiliateGroupMembers = (id, nextPage) => {
    return (dispatch, getState) => {
        const membersData = getState().affiliateGroups.edit.members;

        const page = nextPage === "first" ? 1 : nextPage === "next" ? membersData.sorting.page + 1 : membersData.sorting.page;
        
        let params = {
            ...membersData.sorting,
            ...membersData.filters,
            id
        };

        if(page > 1 && membersData.total <= (page - 1 ) * membersData.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && membersData.total === 0){
            return Promise.resolve();
        }

        dispatch(setAffiliateGroupsActionBefore());
        return axios({
            url: ApiUrls.GET_AFFILIATE_GROUP_MEMBERS,
            method: Methods.GET,
            params: params
        })
        .then(({data : {value: members}}) => {
            dispatch(setAffiliateGroupMembers(members, isMobile() && page !== 1));
            if (nextPage)
                dispatch(setAffiliateGroupMembersSorting({ ...membersData.sorting, page: page }));
            dispatch(setAffiliateGroupsActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliateGroupsActionFinished());
        })
    }
}

const setAffiliateGroupFilter = filter => ({
    type: SET_AFFILIATE_GROUP_FILTER,
    payload: { filter }
})

export const getAffiliateGroupFilter = id => {
    return dispatch => {

        return axios({
            url: ApiUrls.GET_AFFILIATE_GROUP_FILTER,
            method: Methods.GET,
            params: { id }
        })
        .then(({data : {value: filter}}) => {
            dispatch(setAffiliateGroupFilter(filter))
        })
        .catch(Function.prototype)
    }
}

export const saveAffiliateGroupFilter = (id, filter, onSuccess) => {
    return dispatch => {
        dispatch(setAffiliateGroupsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_AFFILIATE_GROUP_FILTER,
            method: Methods.POST,
            data: {
                id, filter
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getAffiliateGroupMembers(id));
                onSuccess && onSuccess();
            } 
            dispatch(setAffiliateGroupsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliateGroupsSaveActionFinished());
        })
    }
}

export const saveAffiliateGroupMembers = (id, affiliates, operationType, onSuccess) => {
    return dispatch => {
        dispatch(setAffiliateGroupsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_AFFILIATE_GROUP_MEMBERS,
            method: Methods.POST,
            data: {
                id, affiliates, operationType
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getAffiliateGroupMembers(id));
                onSuccess && onSuccess();
            } 
            dispatch(setAffiliateGroupsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliateGroupsSaveActionFinished());
        })
    }
}

export const deleteAffiliateGroupMember = (id, groupId) => {
    return (dispatch) => {
        dispatch(setAffiliateGroupsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_AFFILIATE_GROUP_MEMBERS,
            method: Methods.POST,
            data: {
                id: groupId, 
                affiliates: [id], 
                operationType: AFFILIATE_GROUP_OPERATION_TYPE.REMOVE
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    dispatch(getAffiliateGroupMembers(groupId));
                } 
                dispatch(setAffiliateGroupsSaveActionFinished());
            })
            .catch(() => {
                dispatch(setAffiliateGroupsSaveActionFinished());
            });
    };
}