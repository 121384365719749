import PropTypes from 'prop-types';

import { DB_CONNECTION_STATE } from 'constants/common.constants';

export default PropTypes.shape({
    userName: PropTypes.string,
    ipAddress: PropTypes.string,
    state: PropTypes.oneOf(Object.values(DB_CONNECTION_STATE)),
    minutes: PropTypes.number,
    query: PropTypes.string
})