import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Form, Col, Row, Button } from 'antd';

import Select from 'components/common/select';
import Input from 'components/common/input';

import { isMobile } from 'utils/common';

import { MEDIA_TYPE } from 'constants/campaign.constants';

import autoSuggestionType from 'types/autoSuggestion/autoSuggestion.type';

const FIELD_NAMES = {
    NAME: "name",
    TRAFFIC_SOURCES: "trafficSources",
    CAMPAIGN: "campaignId",
    TYPE: "type",
    CREATIVE: "creative",
}

const MediaCreateEditForm = ({
    formInstance,
    onCampaignChange,
    selectedCreativeData,
    campaigns,
    showLightbox,
    formInitialValues,
    creatives,
    trafficSources,
    onValuesChange
}) => {
    const { t } = useTranslation();

    return (
        <Form
            className="rt--form"
            form={formInstance}
            colon={false}
            requiredMark={false}
            layout="vertical"
            initialValues={formInitialValues}
            onValuesChange={onValuesChange}
        >
            <Row gutter={[16, 0]}>
                <Col xs={24} sm={12}>
                    <Form.Item
                        name={FIELD_NAMES.NAME}
                        label={`${t('backoffice.campaigns.name')} *`}
                        className='rt--general-form-item'
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.campaigns.name')}`}
                        rules={[
                            { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                            { max: 30, message: t('backoffice.validation.fieldInvalid') },
                            { min: 3, message: t('backoffice.validation.fieldInvalid') },
                        ]}
                        validateFirst
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.campaigns.name')}`}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item
                        name={FIELD_NAMES.CAMPAIGN}
                        label={`${t('backoffice.campaigns.campaign')} *`}
                        className={(formInitialValues.campaignId ? " rt--form-item-disabled" : "")}
                        rules={[
                            { required: true, message: t('backoffice.validation.fieldRequired') },
                        ]}
                    >
                        <Select
                            options={campaigns.map(campaign => ({
                                value: campaign.id,
                                text: campaign.name
                            }))}
                            disabled={Boolean(formInitialValues.campaignId)}
                            onChange={onCampaignChange}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.campaigns.campaign')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>


                <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                        const selectedCampaign = getFieldValue(FIELD_NAMES.CAMPAIGN);

                        const selectedCampaignData = (
                            campaigns.find(campaign => campaign.id === selectedCampaign)
                        )

                        if (!Boolean(selectedCampaignData)) {
                            return <></>
                        }

                        return (
                            <Col span={24}>
                                <div className='rt--media-campaign-url rt--mb-16'>
                                    <span className='rt--media-campaign-url-placeholder rt--mr-4'>
                                        {t('backoffice.campaigns.campaignLink')}:
                                    </span>
                                    <em>
                                        <a
                                            className='rt--media-campaign-url-link'
                                            href={`https://${selectedCampaignData.url}`}
                                            target='_blank'
                                            rel="noreferrer"
                                        >
                                            {`https://${selectedCampaignData.url}`}
                                        </a>
                                    </em>
                                </div>
                            </Col>
                        )
                    }}
                </Form.Item>

                <Col xs={24} sm={12}>
                    <Form.Item
                        name={FIELD_NAMES.TRAFFIC_SOURCES}
                        label={t('backoffice.campaigns.trafficSource')}
                        className={(!isMobile() ? ' rt--form-item-without-margin' : '')}
                    >
                        <Select
                            options={trafficSources.map(source => ({
                                value: source.longId,
                                text: source.name
                            }))}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.campaigns.trafficSource')}`}
                            loading={false}
                            isMultiple={true}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                    <Form.Item
                        name={FIELD_NAMES.TYPE}
                        label={`${t('backoffice.campaigns.mediaType')} *`}
                        className={'rt--form-item-without-margin' + (formInitialValues.type ? " rt--form-item-disabled" : "")}
                        rules={[
                            { required: true, message: t('backoffice.validation.fieldRequired') },
                        ]}
                    >
                        <Select
                            options={[
                                { value: MEDIA_TYPE.LINK, text: t("backoffice.campaigns.directLink") },
                                { value: MEDIA_TYPE.CREATIVE, text: t("backoffice.campaigns.creative") },
                            ]}
                            disabled={Boolean(formInitialValues.type)}
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.campaigns.mediaType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                        const selectedMediaType = getFieldValue(FIELD_NAMES.TYPE);
                        const selectedCampaignId = getFieldValue(FIELD_NAMES.CAMPAIGN);

                        if (!Boolean(selectedCampaignId) || selectedMediaType !== MEDIA_TYPE.CREATIVE) {
                            return <></>
                        }

                        if (creatives.length === 0) {
                            return (
                                <Form.Item
                                    name={FIELD_NAMES.CREATIVE}
                                    rules={[
                                        () => ({
                                            validator() {
                                                if (!Boolean(selectedCreativeData)) {
                                                    return Promise.reject(t('backoffice.validation.fieldRequired'))
                                                }

                                                return Promise.resolve();
                                            }
                                        })
                                    ]}
                                >
                                    <i className='rt--text-secondary rt--mt-16 rt--ml-8'>
                                        {t('backoffice.campaigns.noCreativesFound')}
                                    </i>
                                </Form.Item>

                            )
                        }

                        return (
                            <>
                                <Col xs={24} sm={12}>
                                    <Form.Item
                                        name={FIELD_NAMES.CREATIVE}
                                        rules={[
                                            () => ({
                                                validator() {
                                                    if (!Boolean(selectedCreativeData)) {
                                                        return Promise.reject(t('backoffice.validation.fieldRequired'))
                                                    }

                                                    return Promise.resolve();
                                                }
                                            })
                                        ]}
                                    >
                                        <Button
                                            className="rt--button rt--button-main rt--mt-16"
                                            onClick={showLightbox}
                                            disabled={Boolean(formInitialValues.creativeData)}
                                        >
                                            {t("backoffice.campaigns.selectCreative")}
                                        </Button>
                                    </Form.Item>
                                </Col>

                                {Boolean(selectedCreativeData) && (
                                    <Col span={24}>
                                        <Input
                                            readOnly={true}
                                            value={selectedCreativeData.name}
                                        />
                                    </Col>
                                )}
                            </>
                        )
                    }}
                </Form.Item>
            </Row>
        </Form>
    );
}

/** MediaCreateEditForm propTypes
    * PropTypes
*/
MediaCreateEditForm.propTypes = {
    formInstance: PropTypes.object,
    onCampaignChange: PropTypes.func,
    showLightbox: PropTypes.func,
    selectedCreativeData: PropTypes.object,
    formInitialValues: PropTypes.object,
    campaigns: PropTypes.arrayOf(autoSuggestionType),
    creatives: PropTypes.arrayOf(autoSuggestionType),
    trafficSources: PropTypes.arrayOf(autoSuggestionType),
    onValuesChange: PropTypes.func
}

export default MediaCreateEditForm;