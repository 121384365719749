import PropTypes from 'prop-types';

export default {
    /** Breadcrumbs items */
    items: PropTypes.arrayOf(PropTypes.shape({
        /** Breadcrumb path */
        path: PropTypes.string,
        /** Breadcrumb title */
        title: PropTypes.string
    })),
    /** Breadcrumbs classname */
    className: PropTypes.string,
    /** Function to fire on back button click on mobile */
    onBack: PropTypes.func,
    /** Extra content */
    extra: PropTypes.node
}