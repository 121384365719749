import PropTypes from 'prop-types';

import { USER_STATE } from "constants/user.constants";

import walletType from "../wallet/wallet.type";

export const trafficSourceType = PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number
})

export const additionalInfoType = PropTypes.shape({
    id: PropTypes.string,
    longId: PropTypes.number,
    userName: PropTypes.string,
    managerId: PropTypes.string,
    managerLongId: PropTypes.number,
    managerUserName: PropTypes.string,
    defaultLanguageCode: PropTypes.string,
    referralId: PropTypes.string,
    referralLongId: PropTypes.number,
    referralUserName: PropTypes.string,
    additionalInfo: PropTypes.string,
    targetMarket: PropTypes.number,
    receiveNewsletter: PropTypes.bool
})

export const personalInfoType = PropTypes.shape({
    id: PropTypes.string,
    longId: PropTypes.number,
    userName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    state: PropTypes.number,
    country: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    zipCode: PropTypes.string,
    gender: PropTypes.number,
    address: PropTypes.string,
    comments: PropTypes.string,
    isTesting: PropTypes.bool,
    securityLevel: PropTypes.number,
    lastActiveAt: PropTypes.string,
    createdAt: PropTypes.string,
    defaultLanguageCode: PropTypes.string,
    allowEdit: PropTypes.bool,
    loginAttemptDetails: PropTypes.shape({
        failedLoginAttemptCount: PropTypes.number,
        failed2FAAttemptCount: PropTypes.number,
        unlockTotalCount: PropTypes.number,
        lockTime: PropTypes.string,
        unlockTime: PropTypes.string,
        is2FALocked: PropTypes.bool
    })
})

export default PropTypes.shape({
    id: PropTypes.string,
    userName: PropTypes.string,
    email: PropTypes.string,
    isEmailVerified: PropTypes.bool,
    subAffiliatesCount: PropTypes.number,
    lastLogin: PropTypes.string,
    referalId: PropTypes.string,
    referalLongId: PropTypes.number,
    referalUserName: PropTypes.string,
    companyId: PropTypes.string,
    projectId: PropTypes.string,
    state: PropTypes.oneOf(Object.values(USER_STATE)),
    createdAt: PropTypes.string,
    country: PropTypes.string,
    securityLevel: PropTypes.number,
    wallets: PropTypes.arrayOf(walletType),
    currencyCodes: PropTypes.arrayOf(PropTypes.string),
    targetMarket: PropTypes.number
})