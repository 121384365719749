import React from "react";

import InvoicesComponent from "pages/payment/invoices/invoices.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";
import Paths from "constants/path.constants";

const InvoicesRoute = () => {
    return <InvoicesComponent />;
};

export default withPermission(
    withAuth(InvoicesRoute),
    { resource: PERMISSION_RESOURCE.PAYMENTS_INVOICES, action: PERMISSION_ACTION.VIEW },
    Paths.PORTAL
);
