import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Button } from 'antd';

import {
    getPromoContacts,
    savePromoContacts,
} from 'store/actions/portal/promoCustomize/sections.action';

import Select from 'components/common/select';
import Input from 'components/common/input';

import { isFormChanged } from 'utils/form';

import {
    AVAILABLE_CONTACTS,
    PROMO_SECTION_TYPE,
    SECTION_NAMES,
} from 'constants/promo.constants';
import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';

const FORM_FIELD_NAMES = {
    CONTACTS: "contacts"
}

const Contacts = ({
    data,
    getPromoContacts,
    savePromoContacts,
    onTabChange,
}) => {
    const { t } = useTranslation();

    const [isFormTouched, setIsFormTouched] = useState(false);

    const initialFormValuesRef = useRef(data)

    const [formInstance] = Form.useForm();
    const { setFieldsValue, validateFields } = formInstance;

    const handleForm = () => {
        validateFields()
            .then((fieldValues) => {
                const requestBody = {
                    ...fieldValues
                }

                delete requestBody[FORM_FIELD_NAMES.CONTACTS];

                savePromoContacts(requestBody);
                setIsFormTouched(false);
            })
            .catch((ex) => { })
    }

    const handleFormValuesChange = (_, formCurrentValues) => {
        setIsFormTouched(
            isFormChanged(formCurrentValues, initialFormValuesRef.current)
        );
    }

    // Get Promo Contacts
    useEffect(() => {
        getPromoContacts();
    }, []);

    // Set Fields Value When Data Loaded
    useEffect(() => {
        const formData = {
            ...data,
            [FORM_FIELD_NAMES.CONTACTS]: Object.keys(data)
        }

        setFieldsValue(formData)
        initialFormValuesRef.current = formData;
    }, [data]);

    useEffect(() => {
        onTabChange(isFormTouched, SECTION_NAMES.FOOTER, UNSAVED_FORM_PAGE_TYPE.CUSTOMIZE_SECTIONS);
    }, [isFormTouched])

    return (
        <div className='rt--flex rt--flex-col rt--justify-between'>
            <Form
                className="rt--form"
                colon={false}
                form={formInstance}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    [FORM_FIELD_NAMES.CONTACTS]: []
                }}
                onValuesChange={handleFormValuesChange}
            >
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item
                            name={FORM_FIELD_NAMES.CONTACTS}
                            label={t('backoffice.promo.contacts')}
                        >
                            <Select
                                options={
                                    AVAILABLE_CONTACTS.map(contactType => (
                                        { value: contactType.TYPE, text: contactType.NAME }
                                    ))}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.promo.contacts')}`}
                                isMultiple={true}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Form.Item shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                            return getFieldValue(FORM_FIELD_NAMES.CONTACTS).map(contactType => {
                                const contact = AVAILABLE_CONTACTS.find(c => c.TYPE === contactType)
                                return (
                                    <Col 
                                        span={6} 
                                        key={contactType}
                                    >
                                        <Form.Item
                                            name={contactType}
                                            label={`${contact?.NAME} *`}
                                            className="rt--general-form-item"
                                            rules={[
                                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                                { max: contact?.MAX_LENGTH, message: t('backoffice.validation.fieldInvalid') },
                                                { min: contact?.MIN_LENGTH, message: t('backoffice.validation.fieldInvalid') },
                                                { pattern: contact?.REGEXP, message: t('backoffice.validation.fieldInvalid') }
                                            ]}
                                            validateFirst
                                        >
                                            <Input
                                                placeholder={`${t('backoffice.common.enter')} ${contact?.NAME}`}
                                                maxLength={contact?.MAX_LENGTH}
                                            />
                                        </Form.Item>
                                    </Col>
                                )
                            })
                        }}
                    </Form.Item>
                </Row>
            </Form>
            <div className='rt--flex rt--justify-end rt--mb-16'>
                <Button
                    htmlType="button"
                    type="primary"
                    className="rt--button rt--button-primary"
                    onClick={handleForm}
                    disabled={!isFormTouched}
                >
                    <span className='rt--font-normal'>
                        {t('backoffice.common.save')}
                    </span>
                </Button>
            </div>
        </div>
    );
}

/** Contacts propTypes
 * PropTypes
*/
Contacts.propTypes = {
    data: PropTypes.objectOf(PropTypes.string),
    /** Redux action to get promo contacts */
    getPromoContacts: PropTypes.func,
    /** Redux action to save promo contacts */
    savePromoContacts: PropTypes.func,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    getPromoContacts: () => {
        dispatch(getPromoContacts())
    },
    savePromoContacts: (requestBody) => {
        dispatch(savePromoContacts(requestBody))
    },
});

const mapStateToProps = (state) => {
    return {
        data: state.promoCustomize.sections[PROMO_SECTION_TYPE.FOOTER].data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);