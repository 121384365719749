import React from "react";

import i18n from "translations/config";

import { AFFILIATE_ACCESS_TYPE } from "constants/affiliate.constants";

export const getTableColumns = additionalProps => {

    const {
        isAffiliate,
        formatAmount,
        formatCurrencyWithSymbol,
        currencyCode,
        hasAccess 
    } = additionalProps

    return [
        hasAccess(AFFILIATE_ACCESS_TYPE.PLAYER_ID) && {
            title: "backoffice.reports.playerId",
            dataIndex: "id",
            mobileLevel: 1,
            alwaysVisible: true,
            copy: true
        },
        hasAccess(AFFILIATE_ACCESS_TYPE.PLAYER_USERNAME) && {
            title: "backoffice.reports.playerUsername",
            dataIndex: "userName",
            mobileLevel: 2,
            alwaysVisible: true
        },
        {
            title: "backoffice.reports.registrationDate",
            dataIndex: "registrationDate",
            isDateTime: true,
            mobileLevel: 3,
        },
        {
            title: "backoffice.reports.country",
            dataIndex: "country",
            render: value => value ? i18n.t(`backoffice.countries.${value}`) : "-",
        },
        !isAffiliate && {
            title: "backoffice.reports.affiliateId",
            dataIndex: "affiliateId",
            copy: true
        },
        !isAffiliate && {
            title: "backoffice.reports.affiliateUsername",
            dataIndex: "affiliateUserName",
        },

        {
            title: "backoffice.reports.brand",
            dataIndex: "brandName",
        },

        {
            title: "backoffice.reports.campaign",
            dataIndex: "campaignName",
        },

        {
            title: "backoffice.reports.mediaName",
            dataIndex: "mediaName"
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_COUNT) && {
            title: "backoffice.reports.depositCount",
            dataIndex: "depositCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.depositAmount")),
            dataIndex: "depositAmount",
            render: value => formatAmount(value, currencyCode),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.FTD_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.ftdAmount")),
            dataIndex: "ftdAmount",
            render: value => formatAmount(value, currencyCode),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.FTD_AMOUNT) && {
            title: "backoffice.reports.qftd",
            dataIndex: "isCPACalculated",
            render: value => value ? i18n.t("backoffice.common.yes") : i18n.t("backoffice.common.no"),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NET_DEPOSIT_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.netDeposit")),
            dataIndex: "netDepositAmount",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_COUNT) && {
            title: "backoffice.reports.sportBetCount",
            dataIndex: "sportBetCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.sportBet")),
            dataIndex: "sportBetAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.WON_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.sportWonAmount")),
            dataIndex: "sportWonAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.GGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.sportGGR")),
            dataIndex: "sportGGR",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BONUS_WON_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.sportBonusWonAmount")),
            dataIndex: "sportBonusWonAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.ADMIN_FEE) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.sportAdminFee")),
            dataIndex: "sportFeeAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.sportNGR")),
            dataIndex: "sportNGR",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_COUNT) && {
            title: "backoffice.reports.casinoBetCount",
            dataIndex: "casinoBetCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.casinoBet")),
            dataIndex: "casinoBetAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.WON_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.casinoWonAmount")),
            dataIndex: "casinoWonAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.GGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.casinoGGR")),
            dataIndex: "casinoGGR",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BONUS_WON_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.casinoBonusWonAmount")),
            dataIndex: "casinoBonusWonAmount",
            render: value => formatAmount(value, currencyCode)
        },
        
        hasAccess(AFFILIATE_ACCESS_TYPE.ADMIN_FEE) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.casinoAdminFee")),
            dataIndex: "casinoFeeAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.casinoNGR")),
            dataIndex: "casinoNGR",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.ngr")),
            dataIndex: "ngr",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.ADMIN_FEE) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.depositFee")),
            dataIndex: "depositFeeAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.ADJUSTMENTS) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.adjustments")),
            dataIndex: "adjustmentsAmount",
            render: value => formatAmount(value, currencyCode)
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.sportRevShare")),
            dataIndex: "sportRevShareAmount",
            render: value => formatAmount(value, currencyCode),
            mobileLevel: 4
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.casinoRevShare")),
            dataIndex: "casinoRevShareAmount",
            render: value => formatAmount(value, currencyCode),
            mobileLevel: 5
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.totalRevShare")),
            dataIndex: "totalRevShareAmount",
            render: value => formatAmount(value, currencyCode),
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.cpaAmount")),
            dataIndex: "cpaAmount",
            render: value => formatAmount(value, currencyCode),
            mobileLevel: 6
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.totalEarning")),
            dataIndex: "totalCommissionAmount",
            render: value => formatAmount(value, currencyCode)
        },
    ]
}