export const columns = [
    {
        title: "backoffice.permissiongroups.name",
        dataIndex: "name",
        sorter: true,
        alwaysVisible: true,
        mobileLevel: 1,
    },
    {
        title: "backoffice.permissiongroups.description",
        dataIndex: "description",
        short: true,
        mobileLevel: 3,
    },
    {
        title: "backoffice.permissiongroups.userCount",
        dataIndex: "users",
        mobileLevel: 5,
        render: (value) => value.length,
    },

    {
        title: "backoffice.permissiongroups.createdAt",
        dataIndex: "createdAt",
        isDateTime: true,
        sorter: true,
    },
    {
        title: "backoffice.permissiongroups.lastUpdatedAt",
        dataIndex: "modifiedAt",
        isDateTime: true,
        sorter: true,
        mobileLevel: 2,
    },
    {
        title: "backoffice.permissiongroups.lastUpdatedBy",
        dataIndex: "modifiedBy",
        sorter: true,
    },
];
