import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import Input from 'components/common/input';

import { COMPANY_CONTROL_RULE, COMPANY_CONTROL_TYPE } from 'constants/company.constants';

import { validateByRegexp } from 'utils/validate';
import { toLowerCaseFirstLetter } from 'utils/common';
import LanguageUtils from 'utils/languages';

/** Affiliate Payment Method Requisits form Component */

const PaymentMethodRequisitsForm = ({
    formControls,
    prefix,
    translations
}) => {

    const { t } = useTranslation();

    const languageCode = LanguageUtils.getSelectedLanguage().toUpperCase();

    const activeFormControls = useMemo(() => {
        return formControls?.filter(control => control.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN) ?? []
    }, [formControls])

    /** Mapped translations for table */
    const mappedTranslations = useMemo(() => {
        const result = {};
        (translations ?? []).forEach(tr => {
            result[tr.key] = tr.translations?.find(t => t.languageCode === languageCode)?.text ?? tr.text
        })

        return result;
    }, [translations])

    const getFieldRules = control => {
        const rules = [];

        if (control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED) {
            if (
                control.controlType === COMPANY_CONTROL_TYPE.SELECT
            ) {
                rules.push({ required: true, message: t('backoffice.validation.fieldRequired') })
            } else if (
                control.controlType === COMPANY_CONTROL_TYPE.INPUT ||
                control.controlType === COMPANY_CONTROL_TYPE.PASSWORD
            ) {
                rules.push({ required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') })
            } else if (control.controlType === COMPANY_CONTROL_TYPE.CHECKBOX) {
                rules.push(
                    () => ({
                        validator(_, value) {
                            if (!value) {
                                return Promise.reject(t('backoffice.validation.fieldRequired'))
                            } else {
                                return Promise.resolve()
                            }
                        }
                    })
                )
            }
        }

        if (control.regExp) {
            rules.push(
                () => ({
                    validator(_, value) {
                        return validateByRegexp(value, control.regExp);
                    }
                })
            )
        }

        return rules;
    }

    return (
        <Col span={24}>
            <Row gutter={[16, 0]}>
                {
                    activeFormControls.map((formControl, index) => {
                        return (
                            <Col span={24} key={formControl.name}>
                                <Form.Item
                                    label={`${mappedTranslations[formControl.name.toLowerCase()]} ${formControl.selectedRule === COMPANY_CONTROL_RULE.REQUIRED ? '*' : ''}`}
                                    name={[prefix, formControl.name]}
                                    rules={getFieldRules(formControl)}
                                    className={'rt--general-form-item' + (index === activeFormControls.length - 1 ? " rt--form-item-without-margin" : "")}
                                    data-placeholder={`${t('backoffice.common.enter')} ${mappedTranslations[formControl.name.toLowerCase()]}`}
                                >
                                    <Input
                                        placeholder={`${t('backoffice.common.enter')} ${mappedTranslations[formControl.name.toLowerCase()]}`}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    })
                }
            </Row>
        </Col>
    )
}

PaymentMethodRequisitsForm.propTypes = {
    /** Form controls */
    formControls: PropTypes.array,
    /** Form prefix name */
    prefix: PropTypes.string,
    translations: PropTypes.array
}

export default PaymentMethodRequisitsForm;