import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Button, Checkbox } from 'antd';

import Dropdown from "components/common/dropdown";
import Icon from "components/common/icon";

import { ALL } from 'constants/common.constants';
import { isFunction } from 'utils/common';

/** Checkboxes Dropdown Component */
const CheckboxesDropdown = ({
    columns,
    buttons = [],
    buttonText,
    buttonClassName,
    allowEmpty,
    selectAllByDefault,
    defaultSelectedColumns,
    onChange
}) => {
    const { t } = useTranslation();

    const [opened, setOpened] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState(selectAllByDefault ? columns.map(c => c.key) : defaultSelectedColumns ? defaultSelectedColumns : []);

    const allIsSelected = selectedColumns.length === columns.length;

    /** Function , returns modal content JSX
       * @function
       * @param {boolean} value
       * @memberOf CheckboxesDropdown
   */
    const handleSelectAll = () => {
        const selected = allIsSelected ? [] : columns.map(c => c.key);
        setSelectedColumns(selected);
        onChange && onChange(selected);
    }

    /** Set default values */
    useEffect(() => {
        if (selectAllByDefault) {
            setSelectedColumns(columns.map(c => c.key))
        } else {
            setSelectedColumns(defaultSelectedColumns ? defaultSelectedColumns : []);
        }

    }, [selectAllByDefault])

    /** Function, to call on checkboxes change
       * @function
       * @memberOf CheckboxesDropdown
   */
    const handleCheckboxesChange = value => {
        setSelectedColumns(value);
        onChange && onChange(value);
    }


    /** Function , hide popup
       * @function
       * @memberOf CheckboxesDropdown
   */
    const onCancel = () => setOpened(false);

    /** Function , returns modal content JSX
       * @function
       * @returns {JSX}
       * @memberOf CheckboxesDropdown
   */
    const renderDropdownContent = () => {
        return (

            <div className="rt--checkboxes-modal rt--dropdown-menu">
                <div className="rt--checkboxes-modal-content rt--flex rt--flex-col">
                    <div className='rt--checkboxes-modal-content-head rt--pl-8 rt--pr-8'>
                        <div className="rt--flex rt--align-center rt--justify-start">
                            <Checkbox
                                checked={allIsSelected}
                                onChange={e => handleSelectAll()}
                                indeterminate={allIsSelected}
                            />
                            <span className="rt--title rt--font-normal rt--font-regular rt--pl-8">{allIsSelected ? t('backoffice.common.deselectAll') : t('backoffice.common.selectAll')}</span>
                        </div>
                    </div>
                    <div className='rt--checkboxes-modal-content-list rt--pb-4 rt--pt-4 rt--flex-equal'>
                        <Checkbox.Group
                            value={selectedColumns}
                            onChange={value => handleCheckboxesChange(value)}
                        >
                            {
                                columns.map(column => (
                                    <div className="rt--checkboxes-modal-content-row rt--flex rt--align-center rt--justify-start rt--pl-8 rt--pr-8" key={column.key}>
                                        <Checkbox value={column.key} />
                                        <span className="rt--title rt--font-normal rt--font-regular rt--pl-8">
                                            {
                                                isFunction(column.title) ? column.title() : column.title
                                            }
                                        </span>
                                    </div>
                                ))
                            }
                        </Checkbox.Group>
                    </div>
                    {
                        buttons.length > 0 && (
                            <div className="rt--checkboxes-modal-footer rt--pl-8 rt--pr-8 rt--pt-8 rt--flex rt--align-center rt--justify-center">
                                {
                                    buttons.map((b, index) => (
                                        <Button
                                            key={index}
                                            type="secondary"
                                            htmlType="button"
                                            className="rt--button rt--ml-4 rt--mr-4"
                                            disabled={!allowEmpty ? selectedColumns.length === 0 : false}
                                            onClick={() => {
                                                b.onClick(selectedColumns);
                                                onCancel()
                                            }}
                                        >
                                            {b.text}
                                        </Button>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }

    return (
        <div className={"rt--checkboxes-dropdown " + (buttonClassName ? buttonClassName : "")}>
            <Dropdown
                popupVisible={opened}
                overlay={renderDropdownContent()}
                onVisibleChange={e => setOpened(e)}
                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
            >
                <div className="rt--checkboxes-dropdown-content rt--flex rt--align-center">
                    <span className='rt--title rt--font-bold rt--font-normal'>{buttonText}</span>
                    <Icon name="down" className="rt--icon-rotate" />
                </div>
            </Dropdown>
        </div>
    )
}

/** CheckboxesDropdown propTypes
    * PropTypes
*/
CheckboxesDropdown.propTypes = {
    /** Array od columns of table which can be exported */
    columns: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
        key: PropTypes.string
    })),
    /** Buttons array */
    buttons: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        onClick: PropTypes.func
    })),
    /** Dropdown toggle button text */
    buttonText: PropTypes.string,
    /** Dropdown toggle button className */
    buttonClassName: PropTypes.node,
    /** Allow to uncheck all checkboxes */
    allowEmpty: PropTypes.bool,
    /** Select all checkboxes by default */
    selectAllByDefault: PropTypes.bool,
    /** Default Selected columns */
    defaultSelectedColumns: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.oneOf([ALL])
    ]),
    /** Fires on change */
    onChange: PropTypes.func
}

export default CheckboxesDropdown;