import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Input from 'components/common/input';

import { renameNewsletterTemplate } from 'store/actions/portal/communications/newsletter/templates.action';

import { isMobile } from 'utils/common';

import { NAME_REGEX } from 'constants/regex.constants';
import { POPUP_SIZE } from 'constants/common.constants';

import templateType from 'types/newsletter/template.type';

/** Template Renaming Popup Component */
const TemplateRenameComponent = ({
    isSaving,
    renameNewsletterTemplate,
    template,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    /** Fires when form submitted
       * @function
       * @memberOf TemplateRenameComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                renameNewsletterTemplate(
                    {
                        ...data,
                        id: template.id
                    },
                    () => {
                        onClose()
                    });
            }).catch(ex => {
                console.log(ex)
            })
    }

    return (
        <Modal
            title={t('backoffice.communication.renameNewsletter')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.communication.rename')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.SMALLEST}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    name: template.name
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.communication.newsletterName')} *`}
                            name="name"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className='rt--general-form-item rt--form-item-without-margin'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.communication.newsletterName')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.communication.newsletterName')}`}
                                maxLength={30}
                                autoFocus={!isMobile()}
                                autoComplete="off"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** TemplateRenameComponent propTypes
    * PropTypes
*/
TemplateRenameComponent.propTypes = {
    /** Redux state property, is true when renaming template request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to rename newsletter template */
    renameNewsletterTemplate: PropTypes.func,
    /** Renaming template */
    template: templateType,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        renameNewsletterTemplate: (data, onSuccess) => {
            dispatch(renameNewsletterTemplate(data, onSuccess));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.newsletter.isSaving
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateRenameComponent)