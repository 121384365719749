import {
    SET_AFFILIATE_ACCESS_SETTINGS_ACTION_BEFORE,
    SET_AFFILIATE_ACCESS_SETTINGS_ACTION_FINISH,
    SET_AFFILIATE_ACCESS_SETTINGS_SAVE_ACTION_BEFORE,
    SET_AFFILIATE_ACCESS_SETTINGS_SAVE_ACTION_FINISH,
    SET_AFFILIATE_ACCESS_SETTINGS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_AFFILIATE_ACCESS_SETTINGS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_AFFILIATE_ACCESS_SETTINGS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_AFFILIATE_ACCESS_SETTINGS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_AFFILIATE_ACCESS_SETTINGS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_AFFILIATE_ACCESS_SETTINGS:
            return {
                ...state,
                accessSettings: payload.accessSettings
            };
        default:
            return state;
    }
};
