import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Button } from "antd";

import Select from "components/common/select";
import Search from "components/common/search";
import ExportButton from "components/common/exportButton";
import ColumnsButton from "components/common/columnsButton";
import Icon from "components/common/icon";

import { isMobile } from "utils/common";

import { SEARCH_TYPE } from "components/common/search/constants";

import buttonType from 'types/components/button.type';
import exportButtonType from 'types/components/exportButton.type';
import columnsButtonType from 'types/components/columnsButton.type';
import searchType from 'types/components/search.type';

const PLACEMENT = {
    RIGHT: "right",
    LEFT: "left"
}

/** Tab Table dashboard layout */
const TabTableDashboardLayout = ({
    children,
    header,
    buttons = [],
    extraContent,
    dropdown,
    isContentScrollable = false
}) => {
    const isMobileDevice = isMobile();
    const haveEnabledButtons = buttons.some(buttonProps => buttonProps.enabled);
    const enabledRightPlacedButtons = buttons.filter(
        buttonProps => buttonProps.placement !== PLACEMENT.LEFT && buttonProps.enabled
    );
    const enabledLeftPlacedButtons = buttons.filter(
        buttonProps => buttonProps.placement === PLACEMENT.LEFT && buttonProps.enabled
    );

    /** Function to Render dropdown 
         * @function
         * @returns {JSX}
         * @memberOf TabTableDashboardLayout
    */
    const renderDropdown = () => (
        <Select
            options={
                dropdown.items.map(item => (
                    { value: item.key, text: item.value }
                ))
            }
            placeholder={dropdown.placeholder}
            value={dropdown.value}
            onChange={dropdown.onChange}
            isDark={true}
            className="rt--tab-table-layout-header-actions-dropdown"
            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
        />
    )

    return (
        <div className="rt--tab-table-layout">
            <div className="rt--tab-table-layout-inner rt--flex rt--flex-col">
                {
                    (
                        header && (
                            (header.button && header.button.enabled) ||
                            (header.export && !isMobile()) ||
                            (header.columns && !isMobile()) ||
                            (header.search && !isMobile()) ||
                            (header.date && !isMobile()) ||
                            (header.filters && !isMobile()) ||
                            header.content || dropdown
                        )
                    ) && (
                        <div className={"rt--tab-table-layout-header rt--flex rt--align-center rt--justify-between " + (!isMobile() ? "rt--pl-24 rt--pr-24 rt--pt-16 rt--pb-16" : "rt--pl-12 rt--pr-12 rt--pt-12 rt--pb-12")}>

                            {
                                header.content ? header.content : (
                                    <Fragment>
                                        {
                                            !isMobile() && (
                                                <div className="rt--tab-table-layout-header-search rt--flex">
                                                    {
                                                        header.search && (
                                                            <Search
                                                                {...header.search}
                                                            />
                                                        )
                                                    }

                                                    {
                                                        header.date && (
                                                            <Search
                                                                {...header.date}
                                                                type={SEARCH_TYPE.DATEPICKER}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            )
                                        }

                                        {dropdown && renderDropdown()}

                                        <div className="rt--tab-table-layout-header-actions rt--flex rt--align-center rt--justify-end rt--flex-equal">

                                            {
                                                !isMobile() && (
                                                    <Fragment>
                                                        {
                                                            header.columns && (
                                                                <ColumnsButton
                                                                    {...header.columns}
                                                                />
                                                            )
                                                        }
                                                        {
                                                            header.export && (
                                                                <ExportButton
                                                                    {...header.export}
                                                                />
                                                            )
                                                        }
                                                        {
                                                            header.filters ? header.filters : null
                                                        }
                                                    </Fragment>
                                                )
                                            }
                                            {
                                                header.button && header.button.enabled ? (
                                                    <Button
                                                        icon={<Icon name={header.button.icon} />}
                                                        type={header.button.type}
                                                        className={
                                                            "rt--button " +
                                                            (!isMobile() ? "rt--ml-16 " : "") +
                                                            (header.button.className ? header.button.className : "") +
                                                            (header.button.type === "primary" ? "rt--button-main" : "")
                                                        }
                                                        onClick={header.button.onClick}
                                                        disabled={header.button.disabled ? true : false}
                                                    >
                                                        {
                                                            dropdown && isMobile() ? null : header.button.text
                                                        }

                                                    </Button>
                                                ) : null
                                            }
                                        </div>
                                    </Fragment>
                                )
                            }


                        </div>
                    )
                }
                {
                    isMobile() && header && (
                        (header.button && header.button.enabled && header.content) ||
                        header.filters || header.search
                    ) && (
                        <div className="rt--tab-table-layout-header-mobile-actions rt--flex rt--justify-end rt--align-center rt--pr-12 rt--pt-8">
                            {
                                header.button && header.button.enabled && header.content && (
                                    <Button
                                        icon={<Icon name={header.button.icon} />}
                                        type={header.button.type}
                                        className={
                                            "rt--button " +
                                            (!isMobile() ? "rt--ml-16 " : "rt--ml-8 ") +
                                            (header.button.className ? header.button.className : "") +
                                            (header.button.type === "primary" ? "rt--button-main" : "")
                                        }
                                        onClick={header.button.onClick}
                                        disabled={header.button.disabled ? true : false}
                                    ></Button>
                                )
                            }

                            {
                                header.filters ? header.filters : null
                            }
                            {
                                header.search && (
                                    <Search
                                        {...header.search}
                                    />
                                )
                            }
                        </div>
                    )
                }
                <div className={isContentScrollable ? "rt--overflow-auto rt--main-scrollable" : "rt--tab-table-layout-content rt--flex rt--flex-col rt--flex-equal"}>
                    {
                        (extraContent || (isMobile() && header?.date)) && (
                            <div className={"rt--tab-table-layout-content-extra rt--pt-16 rt--pb-16" + (!isMobile() ? " rt--pl-24 rt--pr-24" : " rt--pl-16 rt--pr-16")}>
                                { extraContent }
                                {
                                    (isMobile() && header?.date) && (
                                        <Search
                                            {...header.date}
                                            type={SEARCH_TYPE.DATEPICKER}
                                        />
                                    )
                                }
                            </div>
                        )
                    }
                    
                    <div className={
                        "rt--tab-table-layout-content-table rt--flex-equal " +
                        (!isContentScrollable ? "rt--main-scrollable " : "") +
                        (isMobile() ? "rt--pb-16 rt--pl-16 rt--pr-16" : "rt--pb-24 rt--pl-24 rt--pr-24") +
                        (!extraContent && !(isMobile() && header?.date) ? isMobile() ? " rt--pt-16" : " rt--pt-24" : "")
                    }
                    >
                        {children}
                    </div>
                </div>
                {
                    haveEnabledButtons && (
                        <div
                            className={
                                ("rt--tab-table-layout-footer") + (isMobileDevice ? " rt--flex" : " rt--flex rt--align-center") +
                                (isMobileDevice ? "rt--pl-16 rt--pr-16 rt--pt-8 rt--pb-8" : "rt--pl-24 rt--pr-24 rt--pt-16 rt--pb-16") +
                                ((!isMobileDevice && enabledLeftPlacedButtons.length > 0) ? " rt--justify-between" : " rt--justify-end")
                            }
                        >
                            {
                                (!isMobileDevice && enabledLeftPlacedButtons.length > 0) && (
                                    <div className="rt--flex rt--align-center">
                                        {
                                            enabledLeftPlacedButtons.map(buttonProps => {
                                                const { type, className = "rt--button-primary", onClick, text, disabled = false, htmlType = "submit", loading } = buttonProps;
                                                return (
                                                    <Button
                                                        type={type}
                                                        className={"rt--button " + (className)}
                                                        onClick={onClick}
                                                        key={text}
                                                        disabled={disabled}
                                                        htmlType={htmlType}
                                                        loading={loading}
                                                    >
                                                        {
                                                            text
                                                        }

                                                    </Button>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }

                            {
                                enabledRightPlacedButtons.length > 0 && (
                                    <div
                                        className={
                                            "rt--flex rt--align-center" +
                                            (isMobileDevice && enabledRightPlacedButtons.length > 1 ? " rt--justify-between" : " rt--justify-end")
                                        }
                                    >
                                        {
                                            enabledRightPlacedButtons.map(buttonProps => {
                                                const { type, className = "", onClick, text, disabled = false, htmlType = "submit", loading } = buttonProps;

                                                return (
                                                    <Button
                                                        type={type}
                                                        className={"rt--button rt--button-primary" + (className)}
                                                        onClick={onClick}
                                                        key={text}
                                                        disabled={disabled}
                                                        htmlType={htmlType}
                                                        loading={loading}
                                                    >
                                                        {
                                                            text
                                                        }

                                                    </Button>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}

/** TabTableDashboardLayout propTypes
 * PropTypes
*/
TabTableDashboardLayout.propTypes = {
    /** Layout Content */
    children: PropTypes.node,
    /** Header */
    header: PropTypes.shape({
        /** Header button */
        button: PropTypes.shape(buttonType),
        /** Search options */
        search: PropTypes.shape(searchType),
        /** Filters component */
        filters: PropTypes.node,
        /** Columns Button */
        columns: PropTypes.shape(columnsButtonType),
        /** Export Button */
        export: PropTypes.shape(exportButtonType),
        /** Header content */
        content: PropTypes.node
    }),
    /** Extra content under table */
    extraContent: PropTypes.node,
    /** Buttons */
    buttons: PropTypes.arrayOf(PropTypes.shape(buttonType)),
    /** Dropdown options */
    dropdown: PropTypes.object,
    /** Allows to scroll content (prevents default content behavior) */
    isContentScrollable: PropTypes.bool
}

export default TabTableDashboardLayout;