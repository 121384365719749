import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getUser } from 'utils/auth';
import { isMobile, distinctArrayOfObjects } from "utils/common";

import { USER_ROLE } from "constants/user.constants";
import { WALLET_TYPE } from "constants/wallet.constants";

import CurrenciesDropdownMobile from './mobile';
import CurrenciesDropdownDesktop from './desktop';

import currencyType from "types/profile/currency.type";

/** Currencies Dropdown */
const CurrenciesDropdown = ({
    currencies
}) => {

    const { t } = useTranslation();

    const userRole = getUser()?.role;
    const allowImpactOnCPA = getUser()?.allowImpactOnCPA;


    /** Function, to get avalable wallets for current user
       * @function
       * @returns {array} wallets
       * @memberOf CurrenciesDropdown
   */
    const getAvailableWallets = () => {
        let wallets = [];

        if (userRole === USER_ROLE.AFFILIATE) {
            /** Earning Balance */
            wallets.push({
                type: WALLET_TYPE.EARNING_BALANCE,
                title: t("backoffice.wallet.earningBalance")
            })

            if (!allowImpactOnCPA) {
                /** CPA Balance */
                wallets.push({
                    type: WALLET_TYPE.CPA_BALANCE,
                    title: t("backoffice.wallet.cpaBalance")
                })
            }
        }

        return wallets
    }

    /** Function, to make credit Line text
       * @function
       * @param {string} type - wallet type
       * @param {object} currency
       * @returns {string}
       * @memberOf CurrenciesDropdown
   */
    const makeWalletAmount = (type, currencyCode) => {
        let amount = 0;

        const currency = currencies.find(c => c.currencyCode === currencyCode?.toUpperCase());
        if(!currency){
            return amount;
        } 

        const wallet = currency.wallets.find(b => b.type === type);

        if(!wallet){
            return amount;
        }

        if(type === WALLET_TYPE.EARNING_BALANCE && allowImpactOnCPA){
            const earningAmount = currency.wallets.find(b => b.type === WALLET_TYPE.EARNING_BALANCE)?.amount ?? 0;
            const cpaAmount = currency.wallets.find(b => b.type === WALLET_TYPE.CPA_BALANCE)?.amount ?? 0;
            return earningAmount + cpaAmount;
        }

        return wallet.amount;
    }

    const sortCurrencies = curs => {
        const sortedCurrencies = [...curs];

        const availableWallets = getAvailableWallets();

        if (availableWallets.some(w => w.type === WALLET_TYPE.EARNING_BALANCE)) {
            sortedCurrencies.sort((c1, c2) => {
                const c1Balance = makeWalletAmount(WALLET_TYPE.EARNING_BALANCE, c1.currencyCode);
                const c2Balance = makeWalletAmount(WALLET_TYPE.EARNING_BALANCE, c2.currencyCode);
                return Number(c2Balance) - Number(c1Balance);
            })
        }

        return sortedCurrencies;
    }

    return (
        isMobile()
            ? <CurrenciesDropdownMobile
                availableWallets={getAvailableWallets()}
                makeWalletAmount={makeWalletAmount}
                currencies={sortCurrencies(currencies)}
            />
            : <CurrenciesDropdownDesktop
                availableWallets={getAvailableWallets()}
                makeWalletAmount={makeWalletAmount}
                currencies={sortCurrencies(currencies)}
            />
    );
}

/** CurrenciesDropdown propTypes
    * PropTypes
*/
CurrenciesDropdown.propTypes = {
    /** Redux state property, current user currencies */
    currencies: PropTypes.arrayOf(currencyType)
}

const mapStateToProps = state => {
    return {
        currencies: state.profile.currencies,
    }
}

export default connect(mapStateToProps, null)(CurrenciesDropdown)
