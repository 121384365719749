import {
    SET_COMPANIES_ACTION_BEFORE,
    SET_COMPANIES_ACTION_FINISH,
    SET_COMPANIES,
    SET_COMPANIES_SORTING,
    SET_COMPANIES_FILTERS,
    SET_COMPANIES_SAVE_ACTION_BEFORE,
    SET_COMPANIES_SAVE_ACTION_FINISH,
    SET_COMPANY_GENERAL_INFO,
    SET_COMPANY_PASSWORD_SETTINGS,
    SET_COMPANY_TOKEN_SETTINGS,
    SET_COMPANY_AUTHENTICATION_SETTINGS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_COMPANIES_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_COMPANIES_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_COMPANIES:
            return {
                ...state,
                companies: !payload.add ? payload.companies.item2 : state.companies.concat(payload.companies.item2),
                total: payload.companies.item1,
            };

        case SET_COMPANIES_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_COMPANIES_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_COMPANIES_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_COMPANIES_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_COMPANY_GENERAL_INFO:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...payload.info
                    }
                }
            }
        case SET_COMPANY_PASSWORD_SETTINGS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    passwordSettings: payload.passwordSettings
                }
            }
        case SET_COMPANY_TOKEN_SETTINGS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    tokenSettings: payload.tokenSettings
                }
            }
        case SET_COMPANY_AUTHENTICATION_SETTINGS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    authenticationSettings: payload.authenticationSettings
                }
            }
        default:
            return state;
    }
};
