export const TARGET_MARKET = {
    EUROPE: 1 << 0,
    ASIA: 1 << 1,
    AFRICA: 1 << 2,
    NORTH_AMERICA: 1 << 3,
    CENTRAL_AND_SOUTH_AMERICA: 1 << 4,
    MIDDLE_EAST_NORTH_AFRICA: 1 << 5,
};

export const TARGET_MARKETS_TT_KEYS = {
    [TARGET_MARKET.EUROPE]: "europe",
    [TARGET_MARKET.ASIA]: "asia",
    [TARGET_MARKET.AFRICA]: "africa",
    [TARGET_MARKET.NORTH_AMERICA]: "northAmerica",
    [TARGET_MARKET.CENTRAL_AND_SOUTH_AMERICA]: "centralAndSouthAmerica",
    [TARGET_MARKET.MIDDLE_EAST_NORTH_AFRICA]: "middleEastNorthAfrica",
}

export const CONTACT_CHANNEL_KEYS = {
    EMAIL: 1,
    SKYPE: 2,
    WHATSAPP: 3,
    TELEGRAM: 4,
    VIBER: 5,
    FACEBOOK: 6,
    INSTAGRAM: 7,
    TWITTER: 8
};

export const CONTACT_CHANNEL_TYPES = {
    [CONTACT_CHANNEL_KEYS.EMAIL]: "Email",
    [CONTACT_CHANNEL_KEYS.SKYPE]: "Skype",
    [CONTACT_CHANNEL_KEYS.WHATSAPP]: "WhatsApp",
    [CONTACT_CHANNEL_KEYS.TELEGRAM]: "Telegram",
    [CONTACT_CHANNEL_KEYS.VIBER]: "Viber",
    [CONTACT_CHANNEL_KEYS.FACEBOOK]: "Facebook",
    [CONTACT_CHANNEL_KEYS.INSTAGRAM]: "Instagram",
    [CONTACT_CHANNEL_KEYS.TWITTER]: "Twitter"
}

export const REG_FORM_CONTROL_NAMES = {
    USERNAME: "UserName",
    EMAIL: "Email",
    PASSWORD: "Password",
    CONFIRM_PASSWORD: "ConfirmPassword",
    FIRST_NAME: "FirstName",
    LAST_NAME: "LastName",
    PHONE_NUMBER: "PhoneNumber",
    COUNTRY: "Country",
    STATE: "State",
    CITY: "City",
    ADDRESS: "Address",
    ZIPCODE: "ZipCode",
    CONTACT_CHANNEL: "ContactChannel",
    CONTACT_CHANNEL_NAME: "ContactChannelName",
    ADDITIONAL_INFO: "AdditionalInfo",
    TARGET_MARKET: "TargetMarket",
    TRAFFIC_SOURCE: "TrafficSource",
    CURRENCY_CODE: "CurrencyCode",
    PAYMENT_METHOD: "PaymentMethod",
    RECEIVE_NEWSLETTER: "ReceiveNewsletter"
}

export const AFFILIATE_GROUP_TYPE = {
    STATIC: 1,
    DYNAMIC: 2
}

export const AFFILIATE_GROUP_OPERATION_TYPE = {
    ADD: 1,
    REMOVE: 2
}

export const AFFILIATE_ACCESS_TYPE = {
    CLICK_SOURCE: 1 << 0,
    DEPOSIT_COUNT: 1 << 1,
    DEPOSIT_AMOUNT: 1 << 2,
    BET_AMOUNT: 1 << 3,
    BET_COUNT: 1 << 4,
    WITHDRAW_COUNT: 1 << 5,
    WITHDRAW_AMOUNT: 1 << 6,
    NET_DEPOSIT_AMOUNT: 1 << 7,
    GGR: 1 << 8,
    NGR: 1 << 9,
    BONUS_WON_AMOUNT: 1 << 10,
    WON_AMOUNT: 1 << 11,
    ADMIN_FEE: 1 << 12,
    ADJUSTMENTS: 1 << 13,
    PLAYER_ID: 1 << 14,
    PLAYER_USERNAME: 1 << 15,
    FTD_AMOUNT: 1 << 16,
    FTD_COUNT: 1 << 17
}

export const AFFILIATE_ACCESS_TYPE_TRANSLATIONS = {
    [AFFILIATE_ACCESS_TYPE.CLICK_SOURCE]: "clickSource",
    [AFFILIATE_ACCESS_TYPE.DEPOSIT_COUNT]: "depositCount",
    [AFFILIATE_ACCESS_TYPE.DEPOSIT_AMOUNT]: "depositAmount",
    [AFFILIATE_ACCESS_TYPE.FTD_COUNT]: "ftdCount",
    [AFFILIATE_ACCESS_TYPE.FTD_AMOUNT]: "ftdAmount",
    [AFFILIATE_ACCESS_TYPE.BET_AMOUNT]: "betAmount",
    [AFFILIATE_ACCESS_TYPE.BET_COUNT]: "betCount",
    [AFFILIATE_ACCESS_TYPE.WITHDRAW_COUNT]: "withdrawCount",
    [AFFILIATE_ACCESS_TYPE.WITHDRAW_AMOUNT]: "withdrawAmount",
    [AFFILIATE_ACCESS_TYPE.NET_DEPOSIT_AMOUNT]: "netDepositAmount",
    [AFFILIATE_ACCESS_TYPE.GGR]: "ggr",
    [AFFILIATE_ACCESS_TYPE.NGR]: "ngr",
    [AFFILIATE_ACCESS_TYPE.WON_AMOUNT]: "wonAmount",
    [AFFILIATE_ACCESS_TYPE.BONUS_WON_AMOUNT]: "bonusWonAmount",
    [AFFILIATE_ACCESS_TYPE.ADMIN_FEE]: "adminFee",
    [AFFILIATE_ACCESS_TYPE.ADJUSTMENTS]: "adjustments",
    [AFFILIATE_ACCESS_TYPE.PLAYER_ID]: "playerId",
    [AFFILIATE_ACCESS_TYPE.PLAYER_USERNAME]: "playerUsername",
}