import { NOTIFICATION_STATE_ENUM } from "constants/notification.constants";

import sessionStorageUtils from "utils/sessionStorage";

export const getUnReadNotificationCount = (notificationsInfo) => {

    if(sessionStorageUtils.get("unreadNotificationsCount") !== null ){
        return sessionStorageUtils.get("unreadNotificationsCount")
    }

    const unReadNotifications = notificationsInfo.filter(
        notificationInfo => notificationInfo.state === NOTIFICATION_STATE_ENUM.UNREAD
    )

    return unReadNotifications.length
}