import React from "react";

import DBConnectionsComponent from "pages/developer/dbConnections/dbConnections.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const DBConnectionsRoute = () => {
    return <DBConnectionsComponent />;
};

export default withPermission(
    withAuth(DBConnectionsRoute),
    { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
    Paths.DEFAULT
);
