import {
    SET_AFFILIATE_MANAGERS_ACTION_BEFORE,
    SET_AFFILIATE_MANAGERS_ACTION_FINISH,
    SET_AFFILIATE_MANAGERS,
    SET_AFFILIATE_MANAGERS_SORTING,
    SET_AFFILIATE_MANAGERS_FILTERS,
    SET_AFFILIATE_MANAGERS_SAVE_ACTION_BEFORE,
    SET_AFFILIATE_MANAGERS_SAVE_ACTION_FINISH,
    SET_AFFILIATE_MANAGER_GENERAL_INFO,
    SET_AFFILIATE_MANAGER_AFFILIATES,
    SET_AFFILIATE_MANAGER_AFFILIATES_SORTING,
    SET_AFFILIATE_MANAGER_AFFILIATES_FILTERS,
    SET_AFFILIATE_MANAGER_AVAILABLE_AFFILIATES
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_AFFILIATE_MANAGERS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_AFFILIATE_MANAGERS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_AFFILIATE_MANAGERS:
            return {
                ...state,
                affiliateManagers: !payload.add ? payload.affiliateManagers.item2 : state.affiliateManagers.concat(payload.affiliateManagers.item2),
                total: payload.affiliateManagers.item1,
            };

        case SET_AFFILIATE_MANAGERS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_AFFILIATE_MANAGERS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_AFFILIATE_MANAGERS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_AFFILIATE_MANAGERS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_AFFILIATE_MANAGER_GENERAL_INFO:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...payload.info
                    }
                }
            };
        case SET_AFFILIATE_MANAGER_AFFILIATES:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    affiliates: {
                        ...state.edit.affiliates,
                        affiliates: !payload.add ? payload.affiliates.item2 : state.edit.affiliates.affiliates.concat(payload.affiliates.item2),
                        total: payload.affiliates.item1,
                    },
                },
            };
        case SET_AFFILIATE_MANAGER_AFFILIATES_SORTING:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    affiliates: {
                        ...state.edit.affiliates,
                        sorting: payload.sorting
                    },
                },
            };
        case SET_AFFILIATE_MANAGER_AFFILIATES_FILTERS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    affiliates: {
                        ...state.edit.affiliates,
                        filters: payload.filters,
                        sorting: {
                            ...state.edit.affiliates.sorting,
                            page: 1
                        }
                    },
                },
            };
        case SET_AFFILIATE_MANAGER_AVAILABLE_AFFILIATES:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    affiliates: {
                        ...state.edit.affiliates,
                        availableAffiliates: payload.affiliates
                    },
                },
            };
        default:
            return state;
    }
};
