import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";

import { addProjectLanguage } from "store/actions/portal/projects/languages.action";
import { getSystemAvailableLanguages } from "store/actions/portal/common/common.action";

import { POPUP_SIZE } from 'constants/common.constants';
import languageType from 'types/language/language.type';

/** Language Adding Popup Component */
const LanguageAddComponent = ({
    isSaving,
    addProjectLanguage,
    getSystemAvailableLanguages,
    systemAvailableLanguages,
    languages,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    /** Load system available languages */
    useEffect(() => {
        getSystemAvailableLanguages()
    }, [])


    /** Fires when form submitted
       * @function
       * @memberOf LanguageAddComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                addProjectLanguage(data.languageCode, onClose);

            }).catch(() => { })
    }

    return (
        <Modal
            title={t('backoffice.languages.addLanguage')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.SMALLEST}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.languages.selectLanguage')} *`}
                            name="languageCode"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') }
                            ]}
                            className="rt--form-item-without-margin"
                        >
                            <Select
                                options={
                                    Object.keys(systemAvailableLanguages).
                                        filter(item => !languages.some(l => l.code.toLowerCase() === item.toLowerCase())).
                                        map(item => (
                                            { value: item.toUpperCase(), text: item.toUpperCase() + " - " + systemAvailableLanguages[item] }
                                        ))
                                }
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.languages.language')}`}
                                search={true}
                            />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>
    )
}

/** LanguageAddComponent propTypes
    * PropTypes
*/
LanguageAddComponent.propTypes = {
    /** Redux state property, is true when adding language request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to add language for project */
    addProjectLanguage: PropTypes.func,
    /** Redux action to get system available languages */
    getSystemAvailableLanguages: PropTypes.func,
    /** Redux state property, represents the object of available system languages */
    systemAvailableLanguages: PropTypes.object,
    /** Redux state property, represents the array of project languages  */
    languages: PropTypes.arrayOf(languageType),
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        addProjectLanguage: (languageCode, onSuccess) => {
            dispatch(addProjectLanguage(languageCode, onSuccess));
        },

        getSystemAvailableLanguages: () => {
            dispatch(getSystemAvailableLanguages())
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.projects.isSaving,
        systemAvailableLanguages: state.common.systemAvailableLanguages,
        languages: state.projects.edit.languages.languages
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageAddComponent)