import PropTypes from 'prop-types';

export default PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    testProjectId: PropTypes.string,
    gateways: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        tests: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            resultCode: PropTypes.number,
            message: PropTypes.string,
            fullMessage: PropTypes.string,
        }))
    }))
})