import PropTypes from 'prop-types';

import { USER_STATE } from 'constants/user.constants';

export default PropTypes.shape({
    id: PropTypes.string,
    longId: PropTypes.number,
    userName: PropTypes.string,
    createdAt: PropTypes.string,
    state: PropTypes.oneOf(Object.values(USER_STATE)),
    lastLogin: PropTypes.string,
    loginAttemptDetails: PropTypes.shape({
        failedLoginAttemptCount: PropTypes.number,
        failed2FAAttemptCount: PropTypes.number,
        unlockTotalCount: PropTypes.number,
        lockTime: PropTypes.string,
        unlockTime: PropTypes.string,
        is2FALocked: PropTypes.bool
    }),
    receivedReferralCommissions: PropTypes.objectOf(PropTypes.number)
})