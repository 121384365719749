import PropTypes from 'prop-types';

import { USER_STATE } from 'constants/user.constants';

export default PropTypes.shape({
    id: PropTypes.number,
    affiliateId: PropTypes.string,
    affiliateUserName: PropTypes.string,
    affiliateState: PropTypes.oneOf(Object.values(USER_STATE)),
    viewCount: PropTypes.number,
    validClickCount: PropTypes.number,
    invalidClickCount: PropTypes.number,
    details: PropTypes.arrayOf(PropTypes.shape({
        depositAmount: PropTypes.number,
        depositCount: PropTypes.number,
        playerRegistrationCount: PropTypes.number,
        withdrawAmount: PropTypes.number,
        withdrawCount: PropTypes.number,
        netDepositAmount: PropTypes.number,
        sportBetCount: PropTypes.number,
        sportBetAmount: PropTypes.number,
        sportWonAmount: PropTypes.number,
        sportBonusWonAmount: PropTypes.number,
        sportGGR: PropTypes.number,
        sportNGR: PropTypes.number,
        casinoBetCount: PropTypes.number,
        casinoBetAmount: PropTypes.number,
        casinoWonAmount: PropTypes.number,
        casinoBonusWonAmount: PropTypes.number,
        casinoGGR: PropTypes.number,
        casinoNGR: PropTypes.number
    }))
})