import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import UnsavedChangesContext from './unsavedChangesContext';

const UnsavedChangesProvider = ({ children }) => {
    const unsavedChangesStoreRef = useRef({});

    const providerValue = useMemo(() => {
        const register = (name) => {
            unsavedChangesStoreRef.current[name] = {
                changed: false,
            }
        }

        const remove = (name) => {
            delete unsavedChangesStoreRef.current[name]
        }

        const reset = () => {
            unsavedChangesStoreRef.current = {};
        }

        const setToStore = (name, changed) => {
            unsavedChangesStoreRef.current[name].changed = changed
        }

        return {
            unsavedChangesStore: unsavedChangesStoreRef.current,
            register,
            remove,
            reset,
            setToStore,
        }
    }, [])



    return (
        <UnsavedChangesContext.Provider value={providerValue}>
            {children}
        </UnsavedChangesContext.Provider>
    );
}

/** UnsavedChangesProvider propTypes
    * PropTypes
*/
UnsavedChangesProvider.propTypes = {
    children: PropTypes.node,
}

export default UnsavedChangesProvider;