import {
    SET_SYSTEM_TIMEZONES_ACTION_BEFORE,
    SET_SYSTEM_TIMEZONES_ACTION_FINISH,
    SET_SYSTEM_TIMEZONES
} from '../../actionTypes';


export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_SYSTEM_TIMEZONES_ACTION_BEFORE:
            return {
                ...state,
                isTimezonesLoading: true
            }
        case SET_SYSTEM_TIMEZONES_ACTION_FINISH:
            return {
                ...state,
                isTimezonesLoading: false
            }
        case SET_SYSTEM_TIMEZONES:
            return {
                ...state,
                timezones: payload.timezones
            }
        default:
            return state;
    }
}