import axios from 'axios';
import { isResponseSuccess } from "utils/request";

import Methods from 'constants/methods.constants';
import ApiUrls from 'constants/api.constants';

import {
    VERIFY_EMAIL_ACTION_BEFORE,
    VERIFY_EMAIL_ACTION_FINISH
} from '../../actionTypes';

const setVerifyEmailActionBefore = () => ({
    type: VERIFY_EMAIL_ACTION_BEFORE
})

const setVerifyEmailActionFinished = () => ({
    type: VERIFY_EMAIL_ACTION_FINISH
})

export const verifyEmail = (token, cb) => {
    return dispatch => {
        dispatch(setVerifyEmailActionBefore());
        return axios({
            url: ApiUrls.VERIFY_EMAIL,
            method: Methods.POST,
            data: { token }
        })
        .then(({ data }) => {
            dispatch(setVerifyEmailActionFinished());
            if(isResponseSuccess(data)) {
                cb && cb();
            } 
        })
        .catch(() => {
            dispatch(setVerifyEmailActionFinished());
        })
    }
}




