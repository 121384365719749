import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import TextAreaInput from 'components/common/textAreaInput';

/** Notes Popup Component */
const NotesComponent = ({
    onClose,
    onSuccess,
    title,
    noteTitle,
    cancelText,
    okText,
    maxLength=1000
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    /** Fires when form submitted
       * @function
       * @memberOf NotesComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                onSuccess && onSuccess(data.note)
                onClose();
            }).catch(() => { })
    }

    const noteLabel = noteTitle ?? t('backoffice.users.notes');

    return (
        <Modal
            title={title ?? t('backoffice.users.notes')}
            cancelText={cancelText ?? t('backoffice.common.cancel')}
            okText={okText ?? t('backoffice.common.save')}
            onOk={handleForm}
            onCancel={onClose}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    note: ""
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24} >
                        <Form.Item
                            label={`${noteLabel} *`}
                            name="note"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: maxLength, message: t('backoffice.validation.fieldInvalid') },
                                { min: 6, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className="rt--form-item-without-margin rt--general-form-item"
                            data-placeholder={`${t('backoffice.common.enter')} ${noteLabel}`}
                            validateFirst
                        >
                            <TextAreaInput
                                placeholder={`${t('backoffice.common.enter')} ${noteLabel}`}
                                maxLength={maxLength}
                                rows={6}
                            />
                        </Form.Item>
                    </Col>
                    
                </Row>
            </Form>
        </Modal>
    )
}

/** NotesComponent propTypes
    * PropTypes
*/
NotesComponent.propTypes = {
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Fires on form submit */
    onSuccess: PropTypes.func,
    /** Popup title */
    title: PropTypes.string,
    /** Form Note Field Label */
    noteTitle: PropTypes.string,
    /** Modal Cancel text */
    cancelText: PropTypes.string,
    /** Modal Ok text */
    okText: PropTypes.string,
    /** Max length of input */
    maxLength: PropTypes.number
}

export default NotesComponent;