//#region sections
import FAQ from "pages/promoCustomize/editor/sections/FAQ";
import Commission from "pages/promoCustomize/editor/sections/commission";
import EarnProcess from "pages/promoCustomize/editor/sections/earnProcess";
import Footer from "pages/promoCustomize/editor/sections/footer";
import GeneralInfo from "pages/promoCustomize/editor/sections/generalInfo";
import LanguageConfig from "pages/promoCustomize/editor/sections/languageConfig";
import MarketingTools from "pages/promoCustomize/editor/sections/marketingTools";
import Statistics from "pages/promoCustomize/editor/sections/statistics";
import Stories from "pages/promoCustomize/editor/sections/stories";
import Testimonials from "pages/promoCustomize/editor/sections/testimonials";
import Brands from 'pages/promoCustomize/editor/sections/brands';
import SEO from 'pages/promoCustomize/editor/sections/SEO';
//#endregion

//#region styles
import Colors from 'pages/promoCustomize/editor/styles/colors';
import Fonts from 'pages/promoCustomize/editor/styles/fonts';
import Logo from 'pages/promoCustomize/editor/styles/logo';
//#endregion

//#region Language
import LanguagesMenuItem from 'pages/promoCustomize/editor/languageMenuItem';
//#endregion

export const PROMO_SECTION_TYPE = {
    LOGO: 1 << 0,
    GENERAL_INFO: 1 << 1,
    BRANDS: 1 << 2,
    STATISTICS: 1 << 3,
    STORIES: 1 << 4,
    EARN_PROCESS: 1 << 5,
    COMMISSION: 1 << 6,
    MARKETING_TOOLS: 1 << 7,
    TESTIMONIAL: 1 << 8,
    FAQ: 1 << 9,
    FOOTER: 1 << 10,
    SEO: 1 << 11,
}

export const PROMO_FILE_TYPE = {
    LOGO: 1,
    FAVICON: 2,
    BANNER: 3,
    BACKGROUND: 4,
    BACKGROUND_1: 5,
    BACKGROUND_2: 6,
    BACKGROUND_3: 7,
    BACKGROUND_4: 8,
    BACKGROUND_5: 9,
    BACKGROUND_6: 10,
    BACKGROUND_7: 11,
    BACKGROUND_8: 12,
    BACKGROUND_9: 13,
    BACKGROUND_10: 14,
    LOGO_1: 15,
    LOGO_2: 16,
    LOGO_3: 17,
    LOGO_4: 18,
    LOGO_5: 19,
    LOGO_6: 20,
    LOGO_7: 21,
    LOGO_8: 22,
    LOGO_9: 23,
    LOGO_10: 24
}

export const PROMO_FILE_NAMES = {
    [PROMO_FILE_TYPE.LOGO]: "logo",
    [PROMO_FILE_TYPE.FAVICON]: "favicon",
    [PROMO_FILE_TYPE.BANNER]: "banner",
    [PROMO_FILE_TYPE.BACKGROUND]: "background",
    [PROMO_FILE_TYPE.BACKGROUND_1]: "background1",
    [PROMO_FILE_TYPE.BACKGROUND_2]: "background2",
    [PROMO_FILE_TYPE.BACKGROUND_3]: "background3",
    [PROMO_FILE_TYPE.BACKGROUND_4]: "background4",
    [PROMO_FILE_TYPE.BACKGROUND_5]: "background5",
    [PROMO_FILE_TYPE.BACKGROUND_6]: "background6",
    [PROMO_FILE_TYPE.BACKGROUND_7]: "background7",
    [PROMO_FILE_TYPE.BACKGROUND_8]: "background8",
    [PROMO_FILE_TYPE.BACKGROUND_9]: "background9",
    [PROMO_FILE_TYPE.BACKGROUND_10]: "background10",
    [PROMO_FILE_TYPE.LOGO_1]: "logo1",
    [PROMO_FILE_TYPE.LOGO_2]: "logo2",
    [PROMO_FILE_TYPE.LOGO_3]: "logo3",
    [PROMO_FILE_TYPE.LOGO_4]: "logo4",
    [PROMO_FILE_TYPE.LOGO_5]: "logo5",
    [PROMO_FILE_TYPE.LOGO_6]: "logo6",
    [PROMO_FILE_TYPE.LOGO_7]: "logo7",
    [PROMO_FILE_TYPE.LOGO_8]: "logo8",
    [PROMO_FILE_TYPE.LOGO_9]: "logo9",
    [PROMO_FILE_TYPE.LOGO_10]: "logo10",
}

export const PROMO_UPDATE_TYPE = {
    COLORS: 1,
    FONT_FAMILY: 2,
    IMAGE: 3,
    DYNAMIC_DATA: 4,
    SECTION: 5,
    TEXT: 6,
    SCROLL_TO_SECTION: 7,
    CONTACTS: 8,
    LANGUAGE: 9,
    LANGUAGES_LIST: 10,
    DEFAULT_LANGUAGE_CODE: 11
}

export const AVAILABLE_CONTACTS = [
    {
        TYPE: "email",
        NAME: "Email",
        MIN_LENGTH: 4,
        MAX_LENGTH: 1000,
        REGEXP: "[a-zA-Z0-9_\\.\\-]+\\@[a-zA-Z0-9_\\.\\-]+\\.[a-zA-Z]{2,5}$"
    },
    {
        TYPE: "skype",
        NAME: "Skype",
        MIN_LENGTH: 6,
        MAX_LENGTH: 32,
        REGEXP: "\\s*(?<account>[\\w.]*)"
    },
    {
        TYPE: "whatsApp",
        NAME: "WhatsApp",
        MIN_LENGTH: 5,
        MAX_LENGTH: 20,
        REGEXP: "^\\+(?:[0-9]●?){6,14}[0-9]$"
    },
    {
        TYPE: "telegram",
        NAME: "Telegram",
        MIN_LENGTH: 5,
        MAX_LENGTH: 32,
        REGEXP: "^(?=.{5,32}$)(?!.*__)(?!^(telegram|admin|support))[a-z][a-z0-9_]*[a-z0-9]$"
    },
    {
        TYPE: "viber",
        NAME: "Viber",
        MIN_LENGTH: 5,
        MAX_LENGTH: 20,
        REGEXP: "^\\+(?:[0-9] ?){6,14}[0-9]$"
    },
    {
        TYPE: "facebook",
        NAME: "Facebook",
        MIN_LENGTH: 2,
        MAX_LENGTH: 1000,
        REGEXP: "^[a-zA-Z0-9]+$"
    },
    {
        TYPE: "instagram",
        NAME: "Instagram",
        MIN_LENGTH: 2,
        MAX_LENGTH: 1000,
        REGEXP: "^[a-zA-Z0-9]+$"
    },
    {
        TYPE: "twitter",
        NAME: "Twitter",
        MIN_LENGTH: 5,
        MAX_LENGTH: 50,
        REGEXP: "^[a-zA-Z0-9]+$"
    }
]

export const INPUT_REGEX = /\S/;

export const SECTION_NAMES = {
    LANGUAGE_CONFIGS: "languageConfigs",
    BRANDS: "brands",
    COMMISSION: "commission",
    EARN_PROCESS: "earnProcess",
    FAQ: "faq",
    FOOTER: "footer",
    GENERAL_INFO: "generalInfo",
    MARKETING_TOOLS: "marketingTools",
    SEO: "seo",
    STATISTICS: "statistics",
    STORIES: "stories",
    TESTIMONIAL: "testimonial",

    COLORS: "colors",
    FONTS: "fonts",
    LOGO: "logo",
}

export const SECTION_PREDEFINED_DATA_BY_SECTION_NAMES = {
    [SECTION_NAMES.LANGUAGE_CONFIGS]: {
        key: SECTION_NAMES.LANGUAGE_CONFIGS,
        text: "backoffice.promo.languageConfig",
        hideDeactivationSwitch: true,
        content: LanguageConfig,
        menuItemComponent: LanguagesMenuItem,
    },
    [SECTION_NAMES.GENERAL_INFO]: {
        key: SECTION_NAMES.GENERAL_INFO,
        sectionType: PROMO_SECTION_TYPE.GENERAL_INFO,
        text: "backoffice.promo.generalInfo",
        content: GeneralInfo,
        hideDeactivationSwitch: true,
    },
    [SECTION_NAMES.BRANDS]: {
        key: SECTION_NAMES.BRANDS,
        sectionType: PROMO_SECTION_TYPE.BRANDS,
        text: "backoffice.promo.brands",
        content: Brands,
    },
    [SECTION_NAMES.STORIES]: {
        key: SECTION_NAMES.STORIES,
        sectionType: PROMO_SECTION_TYPE.STORIES,
        text: "backoffice.promo.stories",
        content: Stories,
    },
    [SECTION_NAMES.EARN_PROCESS]: {
        key: SECTION_NAMES.EARN_PROCESS,
        sectionType: PROMO_SECTION_TYPE.EARN_PROCESS,
        text: "backoffice.promo.earnProcess",
        content: EarnProcess,
    },
    [SECTION_NAMES.STATISTICS]: {
        key: SECTION_NAMES.STATISTICS,
        sectionType: PROMO_SECTION_TYPE.STATISTICS,
        text: "backoffice.promo.statistics",
        content: Statistics,
    },
    [SECTION_NAMES.COMMISSION]: {
        key: SECTION_NAMES.COMMISSION,
        sectionType: PROMO_SECTION_TYPE.COMMISSION,
        text: "backoffice.promo.commission",
        content: Commission,
    },
    [SECTION_NAMES.MARKETING_TOOLS]: {
        key: SECTION_NAMES.MARKETING_TOOLS,
        sectionType: PROMO_SECTION_TYPE.MARKETING_TOOLS,
        text: "backoffice.promo.marketingTools",
        content: MarketingTools,
    },
    [SECTION_NAMES.TESTIMONIAL]: {
        key: SECTION_NAMES.TESTIMONIAL,
        sectionType: PROMO_SECTION_TYPE.TESTIMONIAL,
        text: "backoffice.promo.testimonials",
        content: Testimonials,
    },
    [SECTION_NAMES.FAQ]: {
        key: SECTION_NAMES.FAQ,
        sectionType: PROMO_SECTION_TYPE.FAQ,
        text: "backoffice.promo.FAQ",
        content: FAQ,
    },
    [SECTION_NAMES.SEO]: {
        key: SECTION_NAMES.SEO,
        sectionType: PROMO_SECTION_TYPE.SEO,
        text: "backoffice.promo.SEO",
        content: SEO,
        hideDeactivationSwitch: true,
    },
    [SECTION_NAMES.FOOTER]: {
        key: SECTION_NAMES.FOOTER,
        sectionType: PROMO_SECTION_TYPE.FOOTER,
        text: "backoffice.promo.footer",
        content: Footer,
        hideDeactivationSwitch: true,
    },
}

export const SECTIONS_DEFAULT_SELECTED_KEY = SECTION_PREDEFINED_DATA_BY_SECTION_NAMES[SECTION_NAMES.GENERAL_INFO].key;

export const STYLES_EDITOR_MENU_ITEMS = {
    [SECTION_NAMES.COLORS]: {
        key: SECTION_NAMES.COLORS,
        text: "backoffice.promo.colors",
        hideDeactivationSwitch: true,
        content: Colors,
    },

    [SECTION_NAMES.FONTS]: {
        key: SECTION_NAMES.FONTS,
        text: "backoffice.promo.fonts",
        hideDeactivationSwitch: true,
        content: Fonts,
    },

    [SECTION_NAMES.LOGO]: {
        key: SECTION_NAMES.LOGO,
        text: "backoffice.promo.logo",
        hideDeactivationSwitch: true,
        content: Logo,
    }
}

export const STYLES_DEFAULT_SELECTED_KEY = SECTION_NAMES.COLORS // Colors