import React from "react";

import { useTranslation } from "react-i18next";

import Icon from "components/common/icon";

import { isMobile } from "utils/common";
import { classNames } from "utils/common";

const NotFound = () => {
    const { t } = useTranslation();

    const isMobileDevice = isMobile();

    return (
        <div
            className={classNames(
                "rt--flex rt--flex-col rt--justify-center rt--align-center",
                isMobileDevice && "rt--pt-12 rt--mb-12",
                !isMobileDevice && "rt--select-empty",
            )}
        >
            <Icon name="empty" />
            <span
                className={classNames(
                    isMobileDevice && "rt--font-normal rt--mt-8",
                    !isMobileDevice && "rt--font-big rt--font-regular rt--mb-6 rt--mt-16",
                )}
            >
                {t("backoffice.common.noDataFound")}
            </span>
        </div>
    );
};

export default NotFound;
