import PropTypes from 'prop-types';

import currencyType from 'types/currency/currency.type';

export default PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarId: PropTypes.string,
    currencyCode: PropTypes.string,
    languageCode: PropTypes.string,
    companies: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.number,
        projectCountLimit: PropTypes.number
    })),
    permissions: PropTypes.object,
    currencies: PropTypes.arrayOf(currencyType),
    mainCompanyId: PropTypes.string,
    securityLevel: PropTypes.number,
    formatSettings: PropTypes.shape({
        numberFormatting: PropTypes.number,
        dateFormatting: PropTypes.number,
        timeFormatting: PropTypes.number
    })
})