import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProjectCurrencies } from "store/actions/portal/projects/currencies.action";

const selectGlobalProjectId = state => state.common.globalProjectId;
const selectProjectCurrencies = state => state.projects.edit.currencies;

const useCurrencies = (autoCall = true) => {
    //#region ------------------------------------------- REDUX --------------------------------------------//

    const dispatch = useDispatch();
    const globalProjectId = useSelector(selectGlobalProjectId);
    const currencies = useSelector(selectProjectCurrencies);

    //#endregion

    const fn = useCallback(() => {
        dispatch(getProjectCurrencies())
    }, [globalProjectId])

    // Load Currencies
    useEffect(() => {
        if(autoCall){
            fn()
        } 
    }, [globalProjectId, dispatch]);


    return [ currencies, fn ];
}

export default useCurrencies;