export const getFirstEmptyInputIndex = (passwordArr) => {
	return passwordArr.findIndex((inputValue) => !inputValue);
};

export const isPasswordInputsFilled = (passwordArr) => {
	for (const character of passwordArr) {
		if (!character) {
			return false;
		}
	}

	return true;
};

export const isPasswordEmpty = (passwordArr) => {
	return !passwordArr.some(item => Boolean(item))
}
