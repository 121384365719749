import PropTypes from 'prop-types';

import { MEDIA_TYPE } from 'constants/campaign.constants';
import { PROJECT_BRAND_PRODUCT_TYPE } from 'constants/project.constants';

export default PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.oneOf(Object.values(MEDIA_TYPE)),
    brandId: PropTypes.string,
    campaignId: PropTypes.string,
    campaignName: PropTypes.string,
    affiliateId: PropTypes.string,
    affiliateUserName: PropTypes.string,
    trafficSources: PropTypes.arrayOf(PropTypes.number),
    viewCount: PropTypes.number,
    validClickCount: PropTypes.number,
    invalidClickCount: PropTypes.number,
    languageCode: PropTypes.string,
    product: PropTypes.oneOf(Object.values(PROJECT_BRAND_PRODUCT_TYPE)),
    details: PropTypes.arrayOf(PropTypes.shape({
        depositAmount: PropTypes.number,
        depositCount: PropTypes.number,
        ggr: PropTypes.number,
        ngr: PropTypes.number,
        playerRegistrationCount: PropTypes.number,
        withdrawAmount: PropTypes.number,
        withdrawCount: PropTypes.number
    }))
})