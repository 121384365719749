import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Switch } from 'antd';

import ContentBlockSkeleton from './contentBlockSkeleton';

import { classNames } from 'utils/common';

import menuItemsType from 'types/promo/menuItems.type';

const ContentBlock = ({
    items,
    activeItemKey,
    alreadyRenderedSectionKeys,
}) => {
    const { t } = useTranslation();

    if (items.length === 0) {
        return (
            <ContentBlockSkeleton />
        )
    }

    return (
        <>
            {items.map(item => {
                if (!alreadyRenderedSectionKeys.includes(item.key)) {
                    return null
                }

                return (
                    <div
                        key={item.key}
                        className={(classNames(
                            "rt--customize-widget-main-content rt--flex-equal",
                            item.key === activeItemKey && "rt--customize-widget-main-content-active"
                        ))}
                    >
                        <div className="rt--switcher rt--customize-widget-main-content-header">
                            {
                                !item.hideDeactivationSwitch && (
                                    <Switch onChange={item.onSectionToggle} checked={item.enabled} />
                                )
                            }
                            <h4 className="rt--title rt--font-bold rt--font-big">
                                {t(item.text)}
                            </h4>
                        </div>
                        <div className="rt--customize-widget-main-content-block">
                            {item.content}
                        </div>
                    </div>
                )
            })}
        </>
    )
}

/** ContentBlock propTypes
 * PropTypes
*/
ContentBlock.propTypes = {
    /** Items that should be rendered in menu */
    items: PropTypes.arrayOf(menuItemsType),
    /** key of active item */
    activeItemKey: PropTypes.string,
    alreadyRenderedSectionKeys: PropTypes.array
};

export default ContentBlock