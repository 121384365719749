import React from "react";

import Status from "components/common/status";

import { STATUS_TYPES } from "constants/status.constants";

export const tableColumns = [
    {
        title: "backoffice.players.username",
        dataIndex: "userName",
        sorter: true,
        alwaysVisible: true,
        mobileLevel: 1,
    },
    {
        title: "backoffice.players.id",
        dataIndex: "longId",
        alwaysVisible: true,
        sorter: true,
        copy: true,
    },
    {
        title: "backoffice.common.balance",
        dataIndex: "balance",
        isNumeric: true,
        sorter: true,
        mobileLevel: 6,
        render: (value) => value || 0,
    },
    {
        title: "backoffice.players.withdrawableBalance",
        dataIndex: "withdrawableBalance",
        isNumeric: true,
        mobileLevel: 7,
        sorter: true,
        render: (value) => value || 0,
    },
    {
        title: "backoffice.players.belongsTo",
        dataIndex: "parentName",
        mobileLevel: 3,
        render: (value) => value || "-",
    },
    {
        title: "backoffice.players.firstName",
        dataIndex: "firstName",
        render: (value) => value || "-",
    },
    {
        title: "backoffice.players.lastName",
        dataIndex: "lastName",
        render: (value) => value || "-",
    },
    {
        title: "backoffice.players.status",
        dataIndex: "state",
        sorter: true,
        mobileLevel: 4,
        render: (value) => <Status status={value} type={STATUS_TYPES.PLAYER} />,
    },
    {
        title: "backoffice.players.currency",
        dataIndex: "currencyCode",
        mobileLevel: 5,
    },
    {
        title: "backoffice.players.email",
        dataIndex: "email",
        render: (value) => value || "_",
    },
    {
        title: "backoffice.players.externalId",
        dataIndex: "externalId",
        sorter: true,
        copy: true,
    },
    {
        title: "backoffice.players.createdAt",
        dataIndex: "createdAt",
        isDateTime: true,
        sorter: true,
        mobileLevel: 2,
    },
    {
        title: "backoffice.players.createdBy",
        dataIndex: "createdBy",
        sorter: true,
    },
    {
        title: "backoffice.players.lastUpdatedAt",
        dataIndex: "modifiedAt",
        isDateTime: true,
        sorter: true,
    },
    {
        title: "backoffice.players.lastUpdateBy",
        dataIndex: "modifiedBy",
        sorter: true,
    },
];
