import {
    SET_USER_INFO_ACTION_BEFORE,
    SET_USER_INFO_ACTION_FINISH,
    SET_USER_INFO,
    SET_USER_CURRENCY,
    UPDATE_USER_AVATAR,
    SET_PROFILE_ACTION_BEFORE,
    SET_PROFILE_ACTION_FINISH,
    SET_PROFILE_SAVE_ACTION_BEFORE,
    SET_PROFILE_SAVE_ACTION_FINISH,
    CHANGE_PASSWORD_ACTION_BEFORE,
    CHANGE_PASSWORD_ACTION_FINISH,
    SET_PERSONAL_INFORMATION,
    SET_FORMAT_SETTINGS,
    SET_CURRENCIES,
    UPDATE_CURRENCIES
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_USER_INFO_ACTION_BEFORE:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    isLoading: true
                }
            };
        case SET_USER_INFO_ACTION_FINISH:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    isLoading: false
                }
            };
        case SET_USER_INFO:
            return {
                ...state,
                userInfo: {
                    ...payload.userInfo
                }
            };
        case SET_CURRENCIES:
            return {
                ...state,
                currencies: payload.currencies,
            };
        case UPDATE_CURRENCIES:
            return {
                ...state,
                currencies: state.currencies.map(c => {
                    if(c.currencyCode === payload.currencyCode?.toUpperCase()){
                        return {
                            ...c,
                            wallets: c.wallets.map(bal => ({
                                ...bal,
                                amount: bal.type  === payload.walletType ? payload.amount : bal.amount
                            }))
                        }
                    }
                    return c;
                }),
            }
        case SET_USER_CURRENCY:
            return {
                ...state,
                currency: payload.currency
            }
        case UPDATE_USER_AVATAR:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    avatarId: payload.avatarId
                }
            }
        case SET_PROFILE_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_PROFILE_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_PERSONAL_INFORMATION:
            return {
                ...state,
                personalInformation: payload.personalInformation,
                userInfo: {
                    ...state.userInfo,
                    firstName: payload.personalInformation.firstName,
                    lastName: payload.personalInformation.lastName
                }
            }
        case SET_FORMAT_SETTINGS:
            return {
                ...state,
                formatSettings: payload.formatSettings,
                userInfo: {
                    ...state.userInfo,
                    formatSetting: payload.formatSettings
                }
            }
        case SET_PROFILE_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true
            }
        case SET_PROFILE_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false
            }
        case CHANGE_PASSWORD_ACTION_BEFORE:
            return {
                ...state,
                isPasswordChanging: true
            }
        case CHANGE_PASSWORD_ACTION_FINISH:
            return {
                ...state,
                isPasswordChanging: false
            }
        default:
            return state;
    }
};
