import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useFormat from 'hooks/useFormat';

import { getInvoicesTotals } from 'store/actions/portal/payment/invoices/totals.action';

import { INVOICE_HISTORY_TYPE } from 'constants/invoice.constants';

import invoiceTotalType from 'types/payment/invoiceTotal.type';

/** Total Invoices Component */
const InvoiceTotals = ({
    getInvoicesTotals,
    totals,
    type,
    affiliateNameOrId,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const { formatAmount } = useFormat();

    /** Load invoices totals */
    useEffect(() => {
        getInvoicesTotals(type);
    }, [affiliateNameOrId, globalProjectId])

    const allCurrencies = Object.keys(totals);

    /** Function to get amount of total
       * @function
       * @param {string} currency
       * @returns {string}
       * @memberOf InvoiceTotals
    */
    const getAmount = currency => {
        const currencyTotals = totals[currency];

        let total = 0;

        if (currencyTotals){
            total = type === INVOICE_HISTORY_TYPE.PENDING ? currencyTotals.pendingInvoices : currencyTotals.paidoutInvoices
        }

        return formatAmount(total, currency)
    }

    return allCurrencies.length > 0 ? (
        <div className='rt--flex rt--align-center rt--overflow-x rt--no-scrollbar'>
            {
                allCurrencies.map(cur => (
                    <div
                        key={cur}
                        className="rt--widget-wallet rt--pl-16 rt--pr-16 rt--pt-12 rt--pb-12 rt--mr-16"
                    >
                        <div className='rt--flex rt--align-center rt--justify-center rt--widget-wallet-inner'>
                            <span className='rt--title rt--font-extra-bold rt--font-big rt--pr-12 rt--widget-wallet-title'>{cur.toUpperCase()}</span>
                            <div className='rt--flex rt--flex-col rt--align-start rt--ml-16'>
                                <b className='rt--title rt--font-regular rt--font-small'>
                                    {
                                        type === INVOICE_HISTORY_TYPE.PENDING ? t("backoffice.invoices.pendingInvoice") : t("backoffice.invoices.paidInvoice")
                                    }:
                                </b>
                                <span className='rt--title rt--font-bold rt--font-big'>
                                    {
                                        getAmount(cur)
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                ))

            }
        </div>
    ) : <Fragment />
}

/** InvoiceTotals propTypes
    * PropTypes
*/
InvoiceTotals.propTypes = {
    /** Redux action to get invoices totals */
    getInvoicesTotals: PropTypes.func,
    /** Redux state, represents the invoices totals */
    totals: PropTypes.objectOf(invoiceTotalType),
    /** Invoice history type type */
    type: PropTypes.oneOf(Object.values(INVOICE_HISTORY_TYPE)),
    /** Redux state property, invoices filters affiliate name or id */
    affiliateNameOrId: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getInvoicesTotals: type => {
            dispatch(getInvoicesTotals(type));
        }
    }
)

const mapStateToProps = (state, props) => {
    const type = props.type;
    return {
        globalProjectId: state.common.globalProjectId,
        totals: state.invoices.totals[type],
        affiliateNameOrId: type === INVOICE_HISTORY_TYPE.PENDING ? state.invoices.pending.filters.affiliateNameOrId : state.invoices.history.filters.affiliateNameOrId,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceTotals)