import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Form, Button } from 'antd';

import PasswordRules from "components/common/passwordRules";
import Input from 'components/common/input';


import { getResetPasswordSettings } from 'store/actions/auth/passwordSettings.action';
import { resetPassword } from 'store/actions/auth/resetPassword.action';

import { validatePassword } from "utils/password";

import Paths from 'constants/path.constants';

import companyPasswordSettingsType from "types/company/passwordSettings.type";


/** Reset Password Page Component */

const ResetPasswordComponent = ({
    resetPassword,
    getResetPasswordSettings,
    passwordSettings,
    isLoading,
    isReseting,
}) => {

    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const [formInstance] = Form.useForm();
    const { validateFields, getFieldValue } = formInstance;

    const navigate = useNavigate();

    const token = searchParams.get("t");

    /** Load password settings */
    useEffect(() => {
        if (token) {
            getResetPasswordSettings(token);
        }
    }, [])

    /** Fires when form submitted
       * @function
       * @memberOf ResetPasswordComponent
   */
    const handleForm = () => {
        validateFields()
            .then(({ newPassword, confirmPassword }) => {
                resetPassword(token, newPassword, confirmPassword, () => {
                    navigate(`${Paths.LOGIN}`);
                });
            }).catch(() => { })
    }

    return (
        <div className="rt--auth-form">
            {
                token ? (
                    <Fragment>
                        <Form
                            form={formInstance}
                            requiredMark={false}
                            layout="vertical"
                            initialValues={{
                                newPassword: "",
                                confirmPassword: ""
                            }}
                        >
                            <h4 className="rt--auth-form-title rt--pb-40">{t('backoffice.authentication.resetPassword')}</h4>

                            <Form.Item
                                label={`${t('backoffice.authentication.password')} *`}
                                name="newPassword"
                                validateFirst
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            return validatePassword(value, passwordSettings);
                                        }
                                    })
                                ]}
                                className='rt--general-form-item'
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.authentication.password')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.authentication.password')}`}
                                    type='Password'
                                    onChange={() => {
                                        setTimeout(() => {
                                            if (getFieldValue('confirmPassword') !== "")
                                                validateFields(['confirmPassword'])
                                        }, 0)
                                    }} />

                            </Form.Item>
                            <Form.Item
                                label={`${t('backoffice.authentication.confirmPassword')} *`}
                                name="confirmPassword"
                                validateFirst
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (value !== getFieldValue("newPassword")) {
                                                return Promise.reject(t('backoffice.validation.passwordsDoNotMatch'))
                                            }
                                            return Promise.resolve();
                                        }
                                    })
                                ]}
                                className='rt--general-form-item'
                                data-placeholder={`${t('backoffice.authentication.confirmPassword')}`}
                            >

                                <Input
                                    type='Password'
                                    placeholder={`${t('backoffice.authentication.confirmPassword')}`}
                                    onPaste={e => e.preventDefault()}
                                    onContextMenu={e => e.preventDefault()}
                                    onCopy={e => e.preventDefault()}
                                />
                            </Form.Item>
                            {
                                !isLoading && Object.keys(passwordSettings).length > 0 ?
                                    <Form.Item>
                                        <div className='rt--mb-40'>
                                            <PasswordRules passwordSettings={passwordSettings} />
                                        </div>
                                    </Form.Item> :
                                    null
                            }
                            <Form.Item>
                                <Button
                                    loading={isReseting}
                                    type="primary"
                                    htmlType="submit"
                                    className="rt--button rt--button-primary"
                                    onClick={handleForm}
                                >
                                    <span>{t('backoffice.common.save')}</span>
                                </Button>
                            </Form.Item>
                        </Form>
                    </Fragment>
                ) : (
                    <div className='rt--auth-error rt--mb-16 rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16'>
                        <b className='rt--title rt--font-medium rt--font-normal'>
                            {
                                t("backoffice.authentication.noTokenFound")
                            }
                        </b>
                    </div>
                )
            }
        </div>
    ) 
}

/** ResetPasswordComponent propTypes
    * PropTypes
*/
ResetPasswordComponent.propTypes = {
    /** Redux state property, is true when reset password request is in process */
    isReseting: PropTypes.bool,
    /** Redux state property, is true when loading password settings */
    isLoading: PropTypes.bool,
    /** Redux action to get password settings */
    getResetPasswordSettings: PropTypes.func,
    /** Redux action to reset password */
    resetPassword: PropTypes.func,
    /** Redux state property, the password settings */
    passwordSettings: companyPasswordSettingsType,
}

const mapDispatchToProps = dispatch => (
    {
        getResetPasswordSettings: token => {
            dispatch(getResetPasswordSettings(token));
        },
        resetPassword: (token, newPassword, confirmPassword, onSuccess) => {
            dispatch(resetPassword(token, newPassword, confirmPassword, onSuccess))
        }
    }
)

const mapStateToProps = state => {
    return {
        isReseting: state.auth.isReseting,
        isLoading: state.auth.passwordSettings.isLoading,
        passwordSettings: state.auth.passwordSettings.passwordSettings
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ResetPasswordComponent
);