import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Table from "components/common/table";

import { tableColumnsCreator } from "utils/tableColumnsCreator";

import { getTableColumns } from './columns';
import { LEADERBOARD_TYPE } from '../constants';

import useFormat from 'hooks/useFormat';

import leaderType from "types/dashboard/leader.type";

const LeaderboardDesktopView = ({
    leaders,
    isLoading,
    type,
    currencyCode,
    isPlayerData
}) => {
    const { formatAmount } = useFormat();

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            additionalProps: {
                type,
                formatAmount,
                currencyCode,
                isPlayerData
            }
        });
    }, [type, currencyCode,isPlayerData])

    return (
        <Table
            loading={isLoading}
            data={leaders}
            columns={mainTableColumns}
            disableFullView={true}
            noPagination={true}
        />
    );
}

/** LeaderboardDesktopView propTypes*/
LeaderboardDesktopView.propTypes = {
    /** Redux state property, top agents */
    leaders: PropTypes.arrayOf(leaderType),
    /** Redux state property, top agents loading state */
    isLoading: PropTypes.bool,
    /** Type */
    type: PropTypes.oneOf([
        LEADERBOARD_TYPE.FINANCIAL,
        LEADERBOARD_TYPE.OPERATIONAL,
    ]),
    /** Currency code */
    currencyCode: PropTypes.string,
    /** Is the leaders are players */
    isPlayerData: PropTypes.bool
};

export default LeaderboardDesktopView;