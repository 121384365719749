import React, { useEffect, useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Modal as AntModal, Drawer, Button } from 'antd';

import Icon from "components/common/icon";

import { isMobile } from "utils/common";

import { POPUP_SIZE } from 'constants/common.constants';

/** Modal Component */
const Modal = ({
    children,
    okText,
    cancelText,
    title,
    onOk,
    onCancel,
    isLoading,
    className,
    onCancelButtonClick,
    okButtonClassname,
    size,
    hideOkButton,
    hideCancelButton,
    disableOkButton,
    disableCancelButton,
    maskClosable,
    hideTitle,
    closable = false,
    footer,
    ...rest
}) => {

    const [visible, setVisible] = useState(false);

    const scrollTop = useRef(0);

    // hide when hash change
    useEffect(() => {
        const closeOnNavigationChange = () => {
            onCancel();
        }

        addEventListener("hashchange", closeOnNavigationChange);

        return () => {
            removeEventListener("hashchange", closeOnNavigationChange);
        }
    }, []);

    useEffect(() => {
        setTimeout(() => setVisible(true), 50);
    }, [])

    useEffect(() => {
        if (visible) {
            if (isMobile()) {
                scrollTop.current = document.documentElement.scrollTop;
                document.documentElement.classList.add("rt--no-scroll");
            }
        
        } else {
            if (document.documentElement.classList.contains("rt--no-scroll")) {
                document.documentElement.classList.remove("rt--no-scroll");
                if (isMobile()) {
                    document.documentElement.scrollTop = scrollTop.current;
                }
            }
        }

    }, [visible])

    useEffect(() => () => {
        document.documentElement.classList.remove("rt--no-scroll");
    }, [])

    return !isMobile() ? (
        <AntModal
            visible={visible}
            className={'rt--modal' + (className ? " " + className : '')}
            title={title}
            cancelText={cancelText}
            okText={okText}
            onOk={onOk}
            onCancel={onCancel}
            width={size || POPUP_SIZE.SMALLEST}
            maskClosable={closable}
            closable={closable}
            okButtonProps={{ className: okButtonClassname ? okButtonClassname : 'rt--button-primary', loading: isLoading, hidden: hideOkButton ? true : false, disabled: disableOkButton ? true : false }}
            cancelButtonProps={{ className: 'rt--button-ghost', type: "text", hidden: hideCancelButton ? true : false, disabled: disableCancelButton ? true : false }}
            centered
            getContainer={() => document.getElementsByClassName("rt--portal-layout")[0] || document.getElementsByClassName("App")[0]}
            footer={footer ? footer : !cancelText && !okText ? null : undefined}
            {...rest}
        >
            {children}
        </AntModal>
    ) : (
        <Drawer
            visible={visible}
            className={
                'rt--modal' + 
                (className ? " " + className : '') + 
                (!okText && !cancelText ? " rt--modal-without-footer" : "") +
                (hideTitle ? " rt--modal-without-header" : "")
            }
            title={title}
            placement="bottom"
            maskClosable={maskClosable !== undefined ? maskClosable : true}
            onClose={onCancel}
            closeIcon={(<Icon name='close'/>)}
            footer={footer ? footer : cancelText || okText ? (
                <div className='rt--modal-footer rt--flex rt--justify-end rt--align-center'>
                    {
                        cancelText && (
                            <Button
                                type="link"
                                className="rt--button-ghost rt--ml-8"
                                onClick={onCancelButtonClick || onCancel}
                                disabled={disableCancelButton}
                            >
                                <span>{cancelText}</span>
                            </Button>
                        )
                    }

                    {
                        okText && (
                            <Button
                                loading={isLoading}
                                type="primary"
                                className="rt--button rt--button-primary rt--ml-16"
                                onClick={onOk}
                                disabled={disableOkButton}
                            >
                                <span>{okText}</span>
                            </Button>
                        )
                    }
                </div>
            ) : undefined}
            getContainer={() => document.getElementsByClassName("rt--portal-layout")[0] || document.getElementsByClassName("App")[0]}
        >
            {children}
        </Drawer>
    )
}

/** Modal propTypes
    * PropTypes
*/
Modal.propTypes = {
    /** Modal content */
    children: PropTypes.node,
    /** Ok button Text */
    okText: PropTypes.string,
    /** Cancel button Text */
    cancelText: PropTypes.string,
    /** Title */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Ok button click handler */
    onOk: PropTypes.func,
    /** Cancel handler */
    onCancel: PropTypes.func,
    /** Is loading */
    isLoading: PropTypes.bool,
    /** The modal classname */
    className: PropTypes.string,
    /** Fires on cancel button click */
    onCancelButtonClick: PropTypes.func,
    /** Modal size */
    size: PropTypes.oneOf(Object.values(POPUP_SIZE)),
    /** Hide ok button */
    hideOkButton: PropTypes.bool,
    /** Hide cancel button */
    hideCancelButton: PropTypes.bool,
    /** Disable ok button */
    disableOkButton: PropTypes.bool,
    /** Disable cancel button */
    disableCancelButton: PropTypes.bool,
    /*/ Is mask closable in mobile */
    maskClosable: PropTypes.bool,
    /*/ Is closable in mobile */
    closable: PropTypes.bool,
    /* Hide title for mobile */
    hideTitle: PropTypes.bool,
    /** Footer */
    footer: PropTypes.node,
    /** Ok button classname */
    okButtonClassname: PropTypes.string
}

export default Modal;