export const AFFILIATES_LIST = "affiliates";
export const AFFILIATES_PAYMENT_METHODS = "affiliatesPaymentMethods";
export const ERROR_LOGS = "errorlogs";
export const CAMPAIGNS = "campaigns";
export const CREATIVES = "creatives";
export const MEDIA_TOOLS = "mediaTools";
export const USER_LOGS = "userLogs";
export const PLAYERS = "players";
export const ACCESS_MANAGERS = "accessManagers";
export const AFFILIATE_MANAGERS = "affiliateManagers";
export const ADMINS = "admins";
export const COMMISSION_PLAN_LIST = "commissionPlanList";
export const SYSTEM_NOTIFICATIONS = "systemNotifications";
export const INVOICES_HISTORY = "invoicesHistory";
export const PENDING_INVOICES = "pendingInvoices";
export const CALCULATION_APPROVALS = "calculationApprovals";
export const BRANDS = "brands";
export const PROJECT_CURRENCIES = "projectCurrencies";
export const PROJECT_LANGUAGES = "projectLanguages";
export const CLICKS_REPORT = "clicksReport";
export const MEDIAS_REPORT = "mediasReport";
export const AFFILIATE_REPORT = "affiliateReport";
export const PLAYER_REPORT = "playerReport";
export const COMMISSION_CALCULATIONS = "commissionCalculations";
export const AGGREGATED_DATA = "aggregatedData";
export const AFFILIATE_MANAGER_AFFILIATES = "affiliateManagerAffiliates";
export const COMMISSIONS_LIST = "commissionsList"
export const JOBS = "jobs";
export const BRAND_URLS = "brandURLS";
export const PROJECT_PAYMENTS = "projectPayments";
export const PROJECT_PAYMENTS_EDIT = "projectPaymentsEdit";
export const SESSIONS = "sessions";
export const SYSTEM_CURRENCY = "systemCurrencies";
export const SYSTEM_LANGUAGES = "systemLanguages";
export const SYSTEM_PAYMENT = "systemPayment";
export const PERMISSION_GROUPS_EDIT = "PermissionGroupsEdit";
export const PERMISSION_GROUPS = "PermissionGroups";
export const REQUESTS = "requests";
export const ADMIN_PERMISSION_GROUP = "adminPermissionGroup";
export const PENDING_PERMISSION_REQUESTS = "pendingPermissionRequest";
export const COMPANIES = "companies";
export const PROJECTS = "projects";
export const WALLET_HISTORY = "walletHistory";
export const NEWSLETTER_TEMPLATES = "newsletterTemplates";
export const NEWSLETTERS = "newsletters";
export const NEWSLETTER_AFFILIATES = "NEWSLETTER_AFFILIATES";
export const AFFILIATE_GROUPS = "AFFILIATE_GROUPS";
export const AFFILIATE_GROUP_MEMBERS = "AFFILIATE_GROUP_MEMBERS";
export const INTEGRATION_REQUESTS = "INTEGRATION_REQUESTS";