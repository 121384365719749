import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import {
    getMediaReport,
    setMediaReportFilters
} from 'store/actions/portal/reports/marketing/mediaReport.action';

import FiltersWrapper from "components/common/filters";
import DateRangePicker from "components/common/dateRangePicker";
import Select from 'components/common/select';
import Input from 'components/common/input';

import useAutosuggestion from 'hooks/useAutosuggestion';

import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import countries from 'systemData/countries';
import { MEDIA_TYPE } from 'constants/campaign.constants';

/** Media Report Page Filters Component */
const Filters = ({
    setMediaReportFilters,
    getMediaReport,
    filters,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const [ campaigns, getCampaigns ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.CAMPAIGN, 
        autoGet: false
    });

    const [ brands, getBrands ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BRAND, 
        autoGet: false
    });

    const [ affiliateNames, getAffiliateNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AFFILIATES, 
        autoGet: false
    });

    return (
        <FiltersWrapper
            loadFn={getMediaReport}
            setFiltersFn={setMediaReportFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: true },
            ]}
            onInit={() => {
                getAffiliateNames();
                getBrands();
                getCampaigns();
            }}
            searchFieldName={["mediaNameOrId", "date"]}
        >
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.brand')}
                        name="brandId"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    brands.map(brand => ({
                                        value: brand.id,
                                        text: brand.name
                                    }))
                                )
                            }
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.reports.brand')}`}
                            search={true}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.affiliateUsername')}
                        name="affiliateId"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    affiliateNames.map(affiliate => ({
                                        value: affiliate.id,
                                        text: affiliate.name
                                    }))
                                )
                            }
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.reports.affiliateUsername')}`}
                            search={true}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.campaign')}
                        name="campaignNameOrId"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    campaigns.map(campaign => ({
                                        value: campaign.id,
                                        text: campaign.name
                                    }))
                                )
                            }
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.reports.campaign')}`}
                            search={true}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.mediaType')}
                        name="mediaType"
                    >
                        <Select
                            options={[
                                { value: null, text: t('backoffice.common.all') },
                                { value: MEDIA_TYPE.LINK, text: t("backoffice.campaigns.directLink") },
                                { value: MEDIA_TYPE.CREATIVE, text: t("backoffice.campaigns.creative") },
                            ]}
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.reports.mediaType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get media report */
    getMediaReport: PropTypes.func,
    /** Redux action to set media report filters */
    setMediaReportFilters: PropTypes.func,
    /** Redux state property, media report filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getMediaReport: () => {
            dispatch(getMediaReport())
        },
        setMediaReportFilters: (filters, keepPage) => {
            dispatch(setMediaReportFilters(filters, keepPage));
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.marketingReports.mediaReport.filters,
        globalProjectId: state.common.globalProjectId,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);