import React, { cloneElement, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Input from 'components/common//input';
import TextAreaInput from 'components/common/textAreaInput';
import Icon from 'components/common/icon';

import { classNames, isFunction } from 'utils/common';

const InputWithAdditionalActions = ({
    type = "input",
    onConfirm,
    onReject,
    onReset,
    onChange,
    value,
    initialValue,
    isOverwritten,
    ...restProps
}) => {
    const [showActionButtons, setShowActionButtons] = useState(false);

    const initialValueRef = useRef(initialValue);

    const handleInternalFocus = () => {
        setShowActionButtons(true);
    }

    const handleInternalBlur = () => {
        setTimeout(() => {
            setShowActionButtons(false);
            onChange(initialValueRef.current);
        }, 200)
    }

    const handleCancelIconClick = () => {
        if (!isFunction(onChange)) {
            return;
        }

        onChange(initialValueRef.current);
        setShowActionButtons(false)
    }

    useEffect(() => {
        initialValueRef.current = initialValue;
    }, [initialValue]);

    return (
        <div className='rt--additional-actions'>
            {
                type === "input"
                    ? (
                        <Input
                            {...restProps}
                            onFocus={handleInternalFocus}
                            onBlur={handleInternalBlur}
                            onChange={onChange}
                            value={value}
                        />
                    ) : type === "textArea" ? (
                        <TextAreaInput
                            {...restProps}
                            onFocus={handleInternalFocus}
                            onBlur={handleInternalBlur}
                            onChange={onChange}
                            value={value}
                        />
                    ) : type === "inputDropdown" ? (
                        <Input.Dropdown
                            {...restProps}
                            onFocus={handleInternalFocus}
                            onBlur={handleInternalBlur}
                            onChange={onChange}
                            value={value}
                        />
                    ) : null
            }
            <div className={classNames(
                'rt--additional-actions-buttons',
                type === 'textArea' && 'rt--additional-actions-buttons-bottom-right'
            )}>
                {isOverwritten && (
                    <div
                        className='rt--additional-actions-buttons-item'
                        onClick={onReset}
                    >
                        <Icon name="reload" />
                    </div>
                )}
                {
                    ( showActionButtons && initialValueRef.current !== value ) && (
                        <>
                            <div
                                className='rt--additional-actions-buttons-item'
                                onClick={handleCancelIconClick}
                            >
                                <Icon name="close" className="rt--error-text" />
                            </div>
                            <div
                                className='rt--additional-actions-buttons-item'
                                onClick={(e) => onConfirm(value, e)}
                            >
                                <Icon name="success" className="rt--success-text" />
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    );
}

/** InputWithAdditionalActions propTypes
 * PropTypes
*/
InputWithAdditionalActions.propTypes = {
    type: PropTypes.oneOf([
        "input",
        "textArea",
        "inputDropdown"
    ]),
    onConfirm: PropTypes.func,
    onReject: PropTypes.func,
    onReset: PropTypes.func,
    onChange: PropTypes.func,
    isOverwritten: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    initialValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
};

export default InputWithAdditionalActions;