import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Modal from "components/common/modal";
import Icon from "components/common/icon";

import { WALLET_TYPE } from "constants/wallet.constants";

import useFormat from 'hooks/useFormat';

import currencyType from "types/profile/currency.type";

/** Mobile Currencies dropdown component on Header */

const MobileCurrenciesDropdown = ({
    currencies,
    availableWallets,
    makeWalletAmount
}) => {

    const { t } = useTranslation();

    const { formatAmount } = useFormat();

    const [showModal, setShowModal] = useState(false);

    return (
        <div className="rt--header-actions rt--header-item">
            <div
                className='rt--header-actions rt--header-actions-content-icon rt--header-item rt--pr-16 rt--mr-16 rt--flex rt--align-center'
                onClick={() => setShowModal(true)}
            >
                <Icon name='wallet' />
            </div>

            {
                showModal && (
                    <Modal
                        hideTitle={true}
                        hideOkButton={true}
                        hideCancelButton={true}
                        onCancel={() => setShowModal(false)}
                    >
                        <div className='rt--header-actions-wallet-table'>
                            {
                                currencies.map(c => (
                                    <div className='rt--header-actions-wallet-table-item rt--mb-8 rt--pl-12 rt--pr-12 rt--pt-12 rt--pb-12' key={c.currencyCode}>
                                        <b className='rt--title rt--font-big rt--font-extra-bold'>{c.currencyCode}</b>
                                        <div className='rt--flex rt--align-center rt--justify-between rt--mt-8'>
                                            {
                                                availableWallets.map(wallet => (
                                                    <div 
                                                        className={'rt--header-actions-wallet-table-item-wallets rt--pr-4' + (availableWallets.length === 1 ? " rt--header-actions-wallet-table-item-wallets-single" : " ")} 
                                                        key={wallet.type}
                                                    >
                                                        <div
                                                            className={
                                                                'rt--header-actions-wallet-table-item-wallet-title rt--flex rt--flex-equal rt--align-center ' +
                                                                (availableWallets.length === 1 ? "rt--justify-start" : "rt--justify-start")
                                                            }
                                                        >
                                                            <span className='rt--title rt--font-small rt--font-regular'>{wallet.title}</span>
                                                        </div>
                                                        <div
                                                            className={
                                                                'rt--header-actions-wallet-table-item-wallet rt--flex rt--flex-equal rt--align-center ' +
                                                                (availableWallets.length === 1 ? "rt--justify-start" : "rt--justify-start")
                                                            }
                                                        >
                                                            <span className='rt--title rt--font-normal rt--font-bold'>
                                                                {
                                                                    formatAmount(
                                                                        makeWalletAmount(wallet.type, c.currencyCode),
                                                                        c.currencyCode
                                                                    )
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>

                                ))
                            }
                        </div>
                    </Modal>
                )
            }


        </div>
    )
}

/** MobileCurrenciesDropdown propTypes
    * PropTypes
*/
MobileCurrenciesDropdown.propTypes = {
    /** Current user currencies */
    currencies: PropTypes.arrayOf(currencyType),
    /** Available Wallets */
    availableWallets: PropTypes.arrayOf(PropTypes.shape({
        /** Title */
        title: PropTypes.string,
        /** Type */
        type: PropTypes.oneOf(Object.values(WALLET_TYPE))
    })),
    /** Make wallet amount */
    makeWalletAmount: PropTypes.func
}


export default MobileCurrenciesDropdown;

