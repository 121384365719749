import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import TableContext from '../../contex';

import Tooltip from "components/common/tooltip";
import Icon from "components/common/icon";

import { classNames, isFunction, isMobile } from 'utils/common';

const StandardView = ({ actions, rowData }) => {
    const { showConfiramtionModal } = useContext(TableContext);

    const isMobileDevice = isMobile();

    const handleInternalClick = (actionData, e) => {
        e.preventDefault();
        e.stopPropagation();

        if (actionData.showConfirmation) {
            showConfiramtionModal({
                rowData,
                title: actionData.confirmationTitle,
                message: actionData.confirmationMessage,
                onOk: actionData.onClick
            })
            return
        }

        actionData.onClick(rowData);
    }

    return (
        <div
            className={classNames(
                "rt--table-col-actions rt--flex rt--align-center rt--justify-end",
                isMobileDevice && "rt--flex-col"
            )}
        >
            {
                actions.map((actionData, index) => (
                    <div
                        onClick={(e) => handleInternalClick(actionData, e)}
                        key={index}
                        className={classNames(
                            "rt--table-col-actions-item rt--ml-8",
                            isMobileDevice && "rt--mb-8"
                        )}
                    >
                        <Tooltip
                            title={
                                <span>
                                    {
                                        isFunction(actionData.title)
                                            ? actionData.title(rowData)
                                            : actionData.title
                                    }
                                </span>
                            }
                        >
                            <Icon 
                                name={
                                    !isMobileDevice ? actionData.icon : 
                                        actionData.icon === "right" ? "edit" : actionData.icon
                                } 
                            />
                        </Tooltip>
                    </div>
                ))
            }
        </div>
    );
}

/** StandardView propTypes
 * PropTypes
*/
StandardView.propTypes = {
    actions: PropTypes.array,
    rowData: PropTypes.object,
}

export default StandardView;