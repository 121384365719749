import { sendDataToIframe } from './common';

import { PROMO_FILE_NAMES, PROMO_FILE_TYPE } from "constants/promo.constants";
import { CUSTOMIZE_IFRAME_ID } from "constants/customize.constants";

export const sendToPromoIframe = (type, configuration) => {
    sendDataToIframe(CUSTOMIZE_IFRAME_ID, {
        type,
        configuration
    })
}
export class TableDataBuilder {
    constructor(tableData) {
        this.tableData = this.#init(tableData);
    }

    // Construction of table list
    #init(data) {
        const tableData = Object.entries(data).map(([rowKey, enabled], index) => {
            return {
                id: index,
                enabled,
                rowKey,
                title: "text",
                description: "text",
            }
        })

        return tableData;
    }

    mergeDataWithTranslations(translations, translationNames) {
        this.tableData = this.tableData.map(rowData => {

            const translationsToMerge = {};

            translationNames.forEach(name => {
                const translationData = translations[`${name}${rowData.rowKey}`];

                translationsToMerge[name] = translationData?.translation;
                translationsToMerge[`${name}TranslationKey`] = translationData?.key;
                translationsToMerge[`${name}IsOverwritten`] = translationData?.isOverwritten;
            });

            return {
                ...rowData,
                ...translationsToMerge
            }
        })

        return this;
    }

    mergeDataWithImages(imgPathsObj, imgNames) {
        this.tableData = this.tableData.map(rowData => {

            const imagesToMerge = {};

            imgNames.forEach(name => {
                imagesToMerge[`${name}FileType`] = PROMO_FILE_TYPE[`${name.toUpperCase()}_${rowData.rowKey}`];
                imagesToMerge[name] = imgPathsObj[PROMO_FILE_NAMES[imagesToMerge[`${name}FileType`]]]
            })

            return {
                ...rowData,
                ...imagesToMerge,
            }
        })

        return this
    }

    build() {
        return this.tableData
    }
}
