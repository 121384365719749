import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Row, Col, Spin, Switch } from 'antd';

import TabFormDashboardLayout from "components/layouts/tab/form";

import Input from 'components/common/input';
import Select from 'components/common/select';
import Icon from 'components/common/icon';
import Tooltip from 'components/common/tooltip';

import { NAME_REGEX } from "constants/regex.constants";
import { AFFILIATE_GROUP_TYPE } from 'constants/affiliate.constants';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { getAffiliateGroupGeneralInfo, saveAffiliateGroupGeneralInfo } from "store/actions/portal/affiliates/groups/general.action";

import affiliateGroupType from 'types/affiliate/group.type';

import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';
import { getUser } from 'utils/auth';

/** Affiliate Group Edit Page General Info Tab Component */
const GeneralInfoComponent = ({
    generalInfo,
    getAffiliateGroupGeneralInfo,
    saveAffiliateGroupGeneralInfo,
    isSaving,
    isLoading,
    onTabChange
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.AFFILIATE_GROUPS, action: PERMISSION_ACTION.MODIFY })

    /** Get general info */
    useEffect(() => {
        getAffiliateGroupGeneralInfo(searchParams.id);
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            name: generalInfo.name,
            visibleToAssignedAffiliates: generalInfo.visibleToAssignedAffiliates,
            editableByOtherUsers: generalInfo.editableByOtherUsers
        });
    }, [generalInfo])

    /** Check is form changed
       * @function
       * @param {object} formValues - form current values
       * @returns {boolean}
       * @memberOf GeneralInfoComponent
   */
    const formChanged = formValues => {
        return isFormChanged(
            {
                ...formValues
            },
            {
                name: generalInfo.name,
                visibleToAssignedAffiliates: generalInfo.visibleToAssignedAffiliates,
                editableByOtherUsers: generalInfo.editableByOtherUsers,
            }
        )
    }

    /** Fires when form submitted
       * @function
       * @memberOf GeneralInfoComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveAffiliateGroupGeneralInfo({
                    ...data,
                    id: searchParams.id
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    /** Is the group created by the current user */
    const isCurrentUserCreator = generalInfo.createdBy === getUser()?.userName;

    /** Can edit */
    const canEdit = hasModifyPermission && ( isCurrentUserCreator || generalInfo.editableByOtherUsers)

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: canEdit,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
            id={generalInfo.id}
            longId={generalInfo.longId}
        >
            <Spin
                spinning={isLoading}
                wrapperClassName="rt--form-spin"
            >
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(_, formValues) => setIsFormTouched(formChanged({ ...formValues }))}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={`${t('backoffice.affiliates.name')} *`}
                                name="name"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                    { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst
                                className={'rt--general-form-item' + (!canEdit ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.affiliates.name')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.affiliates.name')}`}
                                    maxLength={30}
                                    disabled={!canEdit}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={`${t('backoffice.affiliates.groupType')} *`}
                                className="rt--form-item-disabled"
                            >
                                <Select
                                    options={[
                                        { value: AFFILIATE_GROUP_TYPE.STATIC, text: t('backoffice.affiliates.static') },
                                        { value: AFFILIATE_GROUP_TYPE.DYNAMIC, text: t('backoffice.affiliates.dynamic') }
                                    ]}
                                    disabled={true}
                                    value={generalInfo.groupType}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                                <Form.Item
                                    name="visibleToAssignedAffiliates"
                                    valuePropName="checked"
                                    className='rt--form-item-without-margin'
                                >
                                    <Switch 
                                        disabled={!canEdit}
                                    />
                                </Form.Item>
                                <label
                                    className={
                                        'rt--pl-8 rt--switcher-label rt--flex rt--align-center' + 
                                        (!canEdit ? " rt--form-item-disabled": "")
                                    }
                                >
                                    <span className='rt--title rt--font-regular rt--font-normal'>{t('backoffice.affiliates.groupVisibleToAffiliates')}</span>
                                    <Tooltip
                                        title={t('backoffice.affiliates.groupVisibleToAffiliatesTooltip')}
                                        trigger={["hover", "click"]}
                                        placement="top"
                                        enableMobile={true}
                                    >
                                        <div className="rt--ml-4 rt--flex rt--align-center rt--cursor-pointer">
                                            <Icon name="info" size={18} />
                                        </div>
                                    </Tooltip>
                                </label>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                                <Form.Item
                                    name="editableByOtherUsers"
                                    valuePropName="checked"
                                    className='rt--form-item-without-margin'
                                >
                                    <Switch 
                                        disabled={!canEdit || !isCurrentUserCreator}
                                    />
                                </Form.Item>
                                <label
                                    className={
                                        'rt--pl-8 rt--switcher-label rt--flex rt--align-center' + 
                                        (!canEdit || !isCurrentUserCreator ? " rt--form-item-disabled": "")
                                    }
                                >
                                    <span className='rt--title rt--font-regular rt--font-normal'>{t('backoffice.affiliates.editableByOtherUsers')}</span>
                                    <Tooltip
                                        title={t('backoffice.affiliates.editableByOtherUsersTooltip')}
                                        trigger={["hover", "click"]}
                                        placement="top"
                                        enableMobile={true}
                                    >
                                        <div className="rt--ml-4 rt--flex rt--align-center rt--cursor-pointer">
                                            <Icon name="info" size={18} />
                                        </div>
                                    </Tooltip>
                                </label>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </TabFormDashboardLayout>
    )
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Redux state property, current editing affiliate group */
    generalInfo: affiliateGroupType,
    /** Redux action to get affiliate group General info */
    getAffiliateGroupGeneralInfo: PropTypes.func,
    /** Redux action to save affiliate group General info */
    saveAffiliateGroupGeneralInfo: PropTypes.func,
    /** Redux state property, is true when general info is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when general info is loading */
    isLoading: PropTypes.bool,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getAffiliateGroupGeneralInfo: id => {
            dispatch(getAffiliateGroupGeneralInfo(id));
        },

        saveAffiliateGroupGeneralInfo: data => {
            dispatch(saveAffiliateGroupGeneralInfo(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        generalInfo: state.affiliateGroups.edit.general,
        isSaving: state.affiliateGroups.isSaving,
        isLoading: state.affiliateGroups.isLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent)