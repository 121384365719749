export const CREATIVE_TYPE = {
    IMAGE: 1,
    HTML: 2
}

export const MEDIA_TYPE = {
    LINK: 1,
    CREATIVE: 2,
}

export const CREATIVE_HTML_SIZES = [
    { width: 100, height: 40 },
    { width: 100, height: 100 },
    { width: 120, height: 60 },
    { width: 120, height: 90 },
    { width: 120, height: 240 },
    { width: 120, height: 600 },
    { width: 125, height: 125 },
    { width: 150, height: 150 },
    { width: 150, height: 600 },
    { width: 160, height: 600 },
    { width: 180, height: 150 },
    { width: 234, height: 60 },
    { width: 243, height: 60 },
    { width: 240, height: 400 },
    { width: 250, height: 250 },
    { width: 300, height: 50 },
    { width: 300, height: 100 },
    { width: 300, height: 250 },
    { width: 300, height: 200 },
    { width: 300, height: 600 },
    { width: 320, height: 50 },
    { width: 320, height: 100 },
    { width: 320, height: 320 },
    { width: 320, height: 480 },
    { width: 336, height: 280 },
    { width: 360, height: 640 },
    { width: 468, height: 60 },
    { width: 728, height: 90 },
    { width: 970, height: 90 },
    { width: 970, height: 250 }
]

export const CREATIVE_IMAGE_FORMATS = [
    "unknown", "image/jpeg", "image/gif", "image/png", "image/mp4", "image/svg", "image/webp"
]

export const MEDIA_STATE = {
    ACTIVE: 1,
    INACTIVE: 2,
}

export const CAMPAIGN_STATE = {
    ACTIVE: 1,
    INACTIVE: 2,
}