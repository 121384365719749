import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Form, Button } from 'antd';

import PasswordRules from "components/common/passwordRules";
import Input from 'components/common/input';
import TwoFactorComponent from "../login/twoFactor";


import { getPasswordSettings } from 'store/actions/auth/passwordSettings.action';
import { setPassword } from 'store/actions/auth/resetPassword.action';

import { validatePassword } from "utils/password";
import { loginUser } from 'utils/auth';

import Paths from 'constants/path.constants';
import { TOKEN_TYPE } from 'constants/auth.constants';

import companyPasswordSettingsType from "types/company/passwordSettings.type";


/** Set Password Page Component */

const SetPasswordComponent = ({
    setPassword,
    getPasswordSettings,
    passwordSettings,
    isLoading,
    isReseting,
}) => {

    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const [formInstance] = Form.useForm();
    const { validateFields, getFieldValue } = formInstance;

    const navigate = useNavigate();

    const [twoFactor, setTwoFactor] = useState(null);

    const token = searchParams.get("t");
    const userName = searchParams.get("userName");

    /** Load password settings */
    useEffect(() => {
        if (token) {
            getPasswordSettings(token);
        }
    }, [])

    /** Fires after success login
      * @function
      * @param {Object} data - The data returned from server after login call
      * @memberOf SetPasswordComponent
  */
    const authenticateCB = data => {
        if (data.tokenType === TOKEN_TYPE.NONE) {
            loginUser(data);
            setTimeout(() => {
                location.reload();
            }, 0)
        } else if (data.tokenType === TOKEN_TYPE.QR || data.tokenType === TOKEN_TYPE.TOKEN) {
            setTwoFactor({
                tokenType: data.tokenType,
                token: data.token,
                userName: userName
            });
        } else if (data.tokenType === TOKEN_TYPE.PASSWORD_EXPIRED) {
            navigate(`${Paths.SET_PASSWORD}?t=${data.token}&userName=${userName}`);
        }
    }


    /** Fires when form submitted
       * @function
       * @memberOf SetPasswordComponent
   */
    const handleForm = () => {
        validateFields()
            .then(({ newPassword, confirmPassword }) => {
                setPassword(token, newPassword, confirmPassword, data => {
                    authenticateCB(data)
                });
            }).catch(() => { })
    }

    return (
        <div className="rt--auth-form">
            {
                token ? (
                    <Fragment>
                        <Form
                            form={formInstance}
                            requiredMark={false}
                            layout="vertical"
                            initialValues={{
                                newPassword: "",
                                confirmPassword: ""
                            }}
                        >
                            <div className='rt--mb-64'>
                                <span className="rt--title rt--font-bigest rt--font-bold ">{t('backoffice.authentication.setNewPassword')}</span>
                            </div>
                            <Form.Item
                                label={`${t('backoffice.authentication.password')} *`}
                                name="newPassword"
                                validateFirst
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            return validatePassword(value, passwordSettings);
                                        }
                                    })
                                ]}
                                className='rt--general-form-item'
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.authentication.password')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.authentication.password')}`}
                                    type='Password'
                                    onChange={() => {
                                        setTimeout(() => {
                                            if (getFieldValue('confirmPassword') !== "")
                                                validateFields(['confirmPassword'])
                                        }, 0)
                                    }} />

                            </Form.Item>
                            <Form.Item
                                label={`${t('backoffice.authentication.confirmPassword')} *`}
                                name="confirmPassword"
                                validateFirst
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (value !== getFieldValue("newPassword")) {
                                                return Promise.reject(t('backoffice.validation.passwordsDoNotMatch'))
                                            }
                                            return Promise.resolve();
                                        }
                                    })
                                ]}
                                className='rt--general-form-item'
                                data-placeholder={`${t('backoffice.authentication.confirmPassword')}`}
                            >

                                <Input
                                    type='Password'
                                    placeholder={`${t('backoffice.authentication.confirmPassword')}`}
                                    onPaste={e => e.preventDefault()}
                                    onContextMenu={e => e.preventDefault()}
                                    onCopy={e => e.preventDefault()}
                                />
                            </Form.Item>
                            {
                                !isLoading && Object.keys(passwordSettings).length > 0 ?
                                    <Form.Item>
                                        <div className='rt--mb-40'>
                                            <PasswordRules passwordSettings={passwordSettings} />
                                        </div>
                                    </Form.Item> :
                                    null
                            }
                            <Form.Item>
                                <Button
                                    loading={isReseting}
                                    type="primary"
                                    htmlType="submit"
                                    className="rt--button rt--button-primary"
                                    onClick={handleForm}
                                >
                                    <span>{t('backoffice.common.save')}</span>
                                </Button>
                            </Form.Item>
                        </Form>

                        {
                            twoFactor && (
                                <TwoFactorComponent
                                    tokenType={twoFactor.tokenType}
                                    token={twoFactor.token}
                                    userName={twoFactor.userName}
                                    onClose={() => {
                                        setTwoFactor(null);
                                        navigate(Paths.LOGIN, {replace: true});
                                    }}
                                />
                            )
                        }
                    </Fragment>
                ) : (
                    <div className='rt--auth-error rt--mb-16 rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16'>
                        <b className='rt--title rt--font-medium rt--font-normal'>
                            {
                                t("backoffice.authentication.noTokenFound")
                            }
                        </b>
                    </div>
                )
            }
        </div>
    ) 
}

/** SetPasswordComponent propTypes
    * PropTypes
*/
SetPasswordComponent.propTypes = {
    /** Redux state property, is true when set password request is in process */
    isReseting: PropTypes.bool,
    /** Redux state property, is true when loading password settings */
    isLoading: PropTypes.bool,
    /** Redux action to get password settings */
    getPasswordSettings: PropTypes.func,
    /** Redux action to set password */
    setPassword: PropTypes.func,
    /** Redux state property, the password settings */
    passwordSettings: companyPasswordSettingsType,
}

const mapDispatchToProps = dispatch => (
    {
        getPasswordSettings: token => {
            dispatch(getPasswordSettings(token));
        },
        setPassword: (token, newPassword, confirmPassword, onSuccess) => {
            dispatch(setPassword(token, newPassword, confirmPassword, onSuccess))
        }
    }
)

const mapStateToProps = state => {
    return {
        isReseting: state.auth.isReseting,
        isLoading: state.auth.passwordSettings.isLoading,
        passwordSettings: state.auth.passwordSettings.passwordSettings
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    SetPasswordComponent
);