import {
    SET_ALL_COMPANIES,
    SET_GLOBAL_COMPANY_ID,
    SET_GLOBAL_PROJECT_ID,
    SET_CHANGE_PROJECT_ACTION_BEFORE,
    SET_CHANGE_PROJECT_ACTION_FINISH,
    SET_SYSTEM_AVAILABLE_LANGUAGES_ACTION_BEFORE,
    SET_SYSTEM_AVAILABLE_LANGUAGES_ACTION_FINISH,
    SET_SYSTEM_AVAILABLE_LANGUAGES,
    SET_PROJECT_AVAILABLE_LANGUAGES_BEFORE,
    SET_PROJECT_AVAILABLE_LANGUAGES_FINISH,
    SET_PROJECT_AVAILABLE_LANGUAGES,
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_ALL_COMPANIES:
            return {
                ...state,
                allCompanies: payload.companies,
            };
        case SET_GLOBAL_COMPANY_ID:
            return {
                ...state,
                globalCompanyId: payload.id ? payload.id : state.globalCompanyId,
            };
        case SET_GLOBAL_PROJECT_ID:
            return {
                ...state,
                globalProjectId: payload.id ? payload.id : undefined,
                projectAvailableLanguages: {}
            };

        case SET_CHANGE_PROJECT_ACTION_BEFORE:
            return {
                ...state,
                isProjectChanging: true
            }
        case SET_CHANGE_PROJECT_ACTION_FINISH:
            return {
                ...state,
                isProjectChanging: false
            }
        case SET_SYSTEM_AVAILABLE_LANGUAGES_ACTION_BEFORE:
            return {
                ...state,
                isSystemAvailableLanguagesLoading: true,
            };
        case SET_SYSTEM_AVAILABLE_LANGUAGES_ACTION_FINISH:
            return {
                ...state,
                isSystemAvailableLanguagesLoading: false,
            };
        case SET_SYSTEM_AVAILABLE_LANGUAGES:
            return {
                ...state,
                systemAvailableLanguages: payload.languages,
            };
        case SET_PROJECT_AVAILABLE_LANGUAGES_BEFORE:
            return {
                ...state,
                isProjectAvailableLanguagesLoading: true
            }
        case SET_PROJECT_AVAILABLE_LANGUAGES_FINISH:
            return {
                ...state,
                isProjectAvailableLanguagesLoading: false
            }
        case SET_PROJECT_AVAILABLE_LANGUAGES:
            return {
                ...state,
                projectAvailableLanguages: payload.languages
            }
        default:
            return state;
    }
};
