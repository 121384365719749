import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import DateRangePicker from "components/common/dateRangePicker";

import { getSessions, setSessionsFilters } from "store/actions/portal/sessions/sessions.action";

import { USER_TYPE } from "constants/user.constants";

/** Sessions Page Filters Component */

const Filters = ({
    setSessionsFilters,
    getSessions,
    filters,
    userRole,
    userId,
    globalProjectId
}) => {
    const { t } = useTranslation();

    return (
        <FiltersWrapper
            loadFn={() => getSessions(userRole, userId)}
            setFiltersFn={setSessionsFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: true },
            ]}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.dateAndTime')}
                        name="date"
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker
                            showTime={true}
                            allowClear={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get sessions */
    getSessions: PropTypes.func,
    /** Redux action to set sessions filters */
    setSessionsFilters: PropTypes.func,
    /** Redux state property, sessions filters */
    filters: PropTypes.object,
    /** Current user type */
    userRole: PropTypes.oneOf(Object.values(USER_TYPE)),
    /**Current user Id */
    userId: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getSessions: (userRole, userId) => {
            dispatch(getSessions(userRole, userId))
        },
        setSessionsFilters: filters => {
            dispatch(setSessionsFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.sessions.filters,
        globalProjectId: state.common.globalProjectId
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);