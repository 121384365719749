import React, { useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";

import { useNavigate } from 'react-router-dom';

import Sidebar from "./sidebar";
import Toolbar from "./toolbar";
import Preview from "./preview";

import { isMobile } from "utils/common";

import { CUSTOMIZE_PREVIEW_DEVICE_MODE } from "constants/customize.constants";

/** Customize Internal Component */
const CustomizeInternalComponent = ({
    toolbarConfigs = {},
    sidebarConfigs = { items: [] },
    preview = {}
}) => {
    const navigate = useNavigate();

    const [previewDeviceMode, setPreviewDeviceMode] = useState(CUSTOMIZE_PREVIEW_DEVICE_MODE.DESKTOP);

    useLayoutEffect(() => {
        if (isMobile()) {
            navigate(-1)
        }
    }, [])

    return (
        <div className="rt--customize-view rt--pl-24 rt--pr-24 rt--pt-24 rt--pb-24 rt--flex">
            <Sidebar 
                items={sidebarConfigs.items}
            />
            <div className="rt--customize-view-content rt--ml-24 rt--flex rt--flex-col rt--flex-equal">
                <Toolbar
                    setPreviewDeviceMode={setPreviewDeviceMode}
                    previewDeviceMode={previewDeviceMode}
                    title={toolbarConfigs.title}
                    buttons={toolbarConfigs.buttons}
                    backButton={toolbarConfigs.backButton}
                />
                <Preview
                    previewDeviceMode={previewDeviceMode} 
                    preview={preview}
                />
            </div>
        </div>
    );
};

/** CustomizeInternalComponent propTypes
 * PropTypes
*/
CustomizeInternalComponent.propTypes = {
    /** Toolbar configs */
    toolbarConfigs: PropTypes.shape({
        /** Title */
        title: PropTypes.string,
        /** Buttons */
        buttons: PropTypes.arrayOf(PropTypes.shape({
            className: PropTypes.string,
            loading: PropTypes.bool,
            text: PropTypes.string,
            onClick: PropTypes.func
        })),
        /** Back Button */
        backButton: PropTypes.shape({
            text: PropTypes.string,
            onClick: PropTypes.func
        })
    }),
    /** Sidebar configs */
    sidebarConfigs: PropTypes.shape({
        /** Items */
        items: PropTypes.arrayOf(PropTypes.shape({
            content: PropTypes.node,
            icon: PropTypes.string,
            unsaved: PropTypes.bool,
            popupClassName: PropTypes.string
        }))
    }),
    /** Preview */
    preview: PropTypes.shape({
        url: PropTypes.string,
        html: PropTypes.string
    })
};

export default CustomizeInternalComponent;
