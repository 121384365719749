import React from "react";

import NewsletterCustomizeComponent from "pages/newsletterCustomize/newsletterCustomize.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";
import Paths from "constants/path.constants";

const NewsletterCustomizeRoute = () => {
    return <NewsletterCustomizeComponent />;
};

export default withPermission(
    withAuth(NewsletterCustomizeRoute),
    { resource: PERMISSION_RESOURCE.COMMUNICATION_NEWSLETTER, action: PERMISSION_ACTION.VIEW },
    Paths.PORTAL
);
