import store from 'store/configureStore';

import { getUser } from './auth';

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';
import { USER_ROLE, USER_STATE } from 'constants/user.constants';

/** Get current user permissions
     * @function
     * @returns {Object}
 */
export const getPermissions = () => {
    const userInfo = store.getState().profile.userInfo;

    if(getUser().role === USER_ROLE.ACCESS_MANAGER){
        return {
            [PERMISSION_RESOURCE.ADMIN] : [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.MODIFY],
            [PERMISSION_RESOURCE.ADMIN_GENERALINFO] : [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY],
            [PERMISSION_RESOURCE.ADMIN_PERMISSIONS] : [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.DELETE],
            [PERMISSION_RESOURCE.ADMIN_PROJECT_ACCESS] : [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.DELETE],
            [PERMISSION_RESOURCE.ADMIN_COMPANY_ACCESS] : [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.DELETE],
            [PERMISSION_RESOURCE.PERMISSION_GROUP]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.CREATE],
            [PERMISSION_RESOURCE.PERMISSION_REQUESTS]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.ACCESS_MANAGER]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.MODIFY],
            [PERMISSION_RESOURCE.ACCESS_MANAGER_GENERALINFO]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY],
        }
    } else if(getUser().role === USER_ROLE.AFFILIATE_MANAGER){
        return {
            [PERMISSION_RESOURCE.PROJECT] : [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.PROJECT_GENERALINFO]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.PROJECT_BRANDS]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.PROJECT_BRAND_URLS]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.PROJECT_CURRENCIES]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.DELETE],
            [PERMISSION_RESOURCE.PROJECT_LANGUAGE_SETTINGS]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE],
            [PERMISSION_RESOURCE.PROJECT_PAYMENT_METHODS]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.DELETE],
            [PERMISSION_RESOURCE.PROJECT_PROMO]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.MODIFY],
            [PERMISSION_RESOURCE.PROJECT_REFERRAL_SETTINGS]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.PROJECT_REGISTRATION_CONFIGURATION]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY],
            [PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.DELETE],
            [PERMISSION_RESOURCE.PROJECT_PAYOUT_SETTINGS]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.PROJECT_AFFILIATE_ACCESS_SETTINGS]: [PERMISSION_ACTION.VIEW],
            
            [PERMISSION_RESOURCE.AFFILIATE]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.DELETE, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.AFFILIATE_GENERALINFO]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY],
            [PERMISSION_RESOURCE.AFFILIATE_COMMISSION_PLAN]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.DELETE],
            [PERMISSION_RESOURCE.AFFILIATE_PAYMENT_METHODS]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.DELETE],
            [PERMISSION_RESOURCE.AFFILIATE_SESSIONS]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.AFFILIATE_WALLETS]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY],
            [PERMISSION_RESOURCE.AFFILIATE_GROUPS]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.DELETE],

            [PERMISSION_RESOURCE.MARKETING_TOOLS]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.MARKETING_TOOLS_CAMPAIGN]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.DELETE, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.MARKETING_TOOLS_CREATIVE]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.DELETE, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.MARKETING_TOOLS_MEDIA]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.EXPORT],

            [PERMISSION_RESOURCE.AFFILIATE_MANAGER]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.AFFILIATE_MANAGER_GENERALINFO]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.AFFILIATE_MANAGER_AFFILIATES]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.AFFILIATE_MANAGER_SESSIONS]: [PERMISSION_ACTION.VIEW],

            [PERMISSION_RESOURCE.COMMUNICATION]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.COMMUNICATION_SYSTEM_TEMPLATES]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.COMMUNICATION_NEWSLETTER]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.DELETE],

            [PERMISSION_RESOURCE.PAYMENTS_INVOICES]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.DELETE, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.PAYMENTS_WALLETS_HISTORY]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.PAYMENTS_COMMISSION_CALCULATIONS]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.EXPORT],

            [PERMISSION_RESOURCE.REPORT_CLICKS]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.REPORT_MEDIA_PERFORMANCE]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.REPORT_AFFILIATE_PERFORMANCE]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.REPORT_AFFILIATE_PERFORMANCE_TOTALS]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.REPORT_PLAYER_PERFORMANCE]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.REPORT_PLAYER_PERFORMANCE_TOTALS]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.REPORT_AGGREGATION_HISTORY]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.EXPORT],

            [PERMISSION_RESOURCE.DASHBOARD]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.DASHBOARD_FINANCIAL]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.DASHBOARD_OPERATIONAL]: [PERMISSION_ACTION.VIEW]
        }
    } else if(getUser().role === USER_ROLE.AFFILIATE){
        return {
            [PERMISSION_RESOURCE.MARKETING_TOOLS]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.MARKETING_TOOLS_CAMPAIGN]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.MARKETING_TOOLS_CREATIVE]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.MARKETING_TOOLS_MEDIA]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.PAYMENTS_INVOICES]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.AFFILIATE]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.AFFILIATE_GENERALINFO]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.AFFILIATE_COMMISSION_PLAN]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.AFFILIATE_PAYMENT_METHODS]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.DELETE],
            [PERMISSION_RESOURCE.AFFILIATE_SESSIONS]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.PAYMENTS_WALLETS_HISTORY]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.REPORT_CLICKS]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.REPORT_MEDIA_PERFORMANCE]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.REPORT_AFFILIATE_PERFORMANCE]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.REPORT_PLAYER_PERFORMANCE]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.EXPORT],
            [PERMISSION_RESOURCE.REPORT_PLAYER_PERFORMANCE_TOTALS]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.DASHBOARD]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.DASHBOARD_FINANCIAL]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.DASHBOARD_OPERATIONAL]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.PAYMENTS_COMMISSION_CALCULATIONS]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.EXPORT],
        }
    }

    return userInfo?.permissions ?? null;
}

/** Check if has permission
     * @function
     * @param {string} permission - permission 
     * @returns {boolean}
 */
export const hasPermission = permission => {
    const permissions = getPermissions();

    if(
        getUser()?.userState === USER_STATE.BLOCKED && 
        [PERMISSION_ACTION.CREATE, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.DELETE].includes(permission.action))
    {
        return false
    }

    return (permissions?.[permission.resource] ?? []).includes(permission.action);
}

/** Check if has permissions
     * @function
     * @param {array} permissions - permissions array
     * @returns {boolean}
 */
export const hasPermissions = permissions => {

    return permissions.every(perm => hasPermission(perm));
}

/** Check if has one of permissions
     * @function
     * @param {array} permissions - permissions array
     * @returns {boolean}
 */
export const hasOneOfPermissions = permissions => {
    return permissions.some(perm => hasPermission(perm));
}