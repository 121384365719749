import React, { useEffect } from 'react';

import useLogo from 'hooks/useLogo';

import particles from "utils/particles";

import { LOGO_TYPE } from 'constants/common.constants';

/** Auth Layout Sidebar component */
const AuthSidebar = () => {

    const { logos } = useLogo()

    useEffect(() => {
        particles();
    }, [])


    return (
        <div className='rt--auth-sidebar'>
            <div></div>
            <img src={`${import.meta.env.SYSTEM_CDN_URL}/${logos[LOGO_TYPE.LOGO]}`} alt="logo" />
            <div className='rt--auth-sidebar-powered'>
                <span className='rt--font-smallest rt--font-bold'>Powered by</span>
                <a className='rt--auth-link-web rt--font-smallest rt--font-extra-bold rt--ml-4' href='https://centrivo.io/' target='blank'>
                    Digitain.com
                </a>
            </div>
        </div>
    )
}

export default AuthSidebar;