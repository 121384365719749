import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import { updateCampaignState } from "./general.action";

import {
    SET_CAMPAIGNS_ACTION_BEFORE,
    SET_CAMPAIGNS_ACTION_FINISH,
    SET_CAMPAIGNS,
    SET_CAMPAIGNS_SORTING,
    SET_CAMPAIGNS_FILTERS,
    SET_CAMPAIGNS_SAVE_ACTION_BEFORE,
    SET_CAMPAIGNS_SAVE_ACTION_FINISH
} from "../../../../actionTypes";
import { CAMPAIGN_STATE } from 'constants/campaign.constants';

export const setCampaignsActionBefore = () => ({
    type: SET_CAMPAIGNS_ACTION_BEFORE,
});

export const setCampaignsActionFinished = () => ({
    type: SET_CAMPAIGNS_ACTION_FINISH,
});

export const setCampaignsSaveActionBefore = () => ({
    type: SET_CAMPAIGNS_SAVE_ACTION_BEFORE,
});

export const setCampaignsSaveActionFinished = () => ({
    type: SET_CAMPAIGNS_SAVE_ACTION_FINISH,
});

const setCampaigns = (campaigns, add) => ({
    type: SET_CAMPAIGNS,
    payload: { campaigns, add },
});

export const setCampaignsSorting = sorting => ({
    type: SET_CAMPAIGNS_SORTING,
    payload: { sorting },
});

export const setCampaignsFilters = filters => ({
    type: SET_CAMPAIGNS_FILTERS,
    payload: { filters },
});

export const getCampaigns = nextPage => {
    return (dispatch, getState) => {
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().campaigns.sorting.page + 1 : getState().campaigns.sorting.page;
        let params = {
            ...getState().campaigns.sorting,
            ...getState().campaigns.filters,
            page: page
        };

        const brandIdsQuery = getState().campaigns.filters.brandIds.map(b => "brandIds=" + b).join("&");
        const brandUrlIdsQuery = getState().campaigns.filters.brandUrlIds.map(b => "brandUrlIds=" + b).join("&");

        delete params.brandIds;
        delete params.brandUrlIds;

        if(page > 1 && getState().campaigns.total <= (page - 1 ) * getState().campaigns.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().campaigns.total === 0){
            return Promise.resolve();
        }
        
        dispatch(setCampaignsActionBefore());

        return axios({
            url: `${ApiUrls.GET_CAMPAIGNS}?${brandIdsQuery}&${brandUrlIdsQuery}`,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: campaigns } }) => {
                dispatch(setCampaigns(campaigns, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setCampaignsSorting({ ...getState().campaigns.sorting, page: page }));
                dispatch(setCampaignsActionFinished());
            })
            .catch((ex) => {
                dispatch(setCampaignsActionFinished());
            });
    }
}

export const createCampaign = (campaign, onSuccess) => {
    return (dispatch) => {
        dispatch(setCampaignsSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_CAMPAIGN,
            method: Methods.POST,
            data: {
                ...campaign
            },
        })
            .then(({ data }) => {
                dispatch(setCampaignsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(() => {
                dispatch(setCampaignsSaveActionFinished());
            });
    };
};

export const activateCampaign = id => {
    return (dispatch) => {

        dispatch(setCampaignsSaveActionBefore());

        return axios({
            url: ApiUrls.ACTIVATE_CAMPAIGN,
            method: Methods.POST,
            data: { id },
        })
            .then(({ data }) => {
                if(isResponseSuccess(data)){
                    dispatch(updateCampaignState(CAMPAIGN_STATE.ACTIVE));
                    message.success(data.message);
                }
                dispatch(setCampaignsSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setCampaignsSaveActionFinished());
            });
    }
}

export const deactivateCampaign = id => {
    return (dispatch) => {

        dispatch(setCampaignsSaveActionBefore());

        return axios({
            url: ApiUrls.DEACTIVATE_CAMPAIGN,
            method: Methods.POST,
            data: { id },
        })
            .then(({ data }) => {
                if(isResponseSuccess(data)){
                    dispatch(updateCampaignState(CAMPAIGN_STATE.INACTIVE));
                    message.success(data.message);
                }
                dispatch(setCampaignsSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setCampaignsSaveActionFinished());
            });
    }
}
