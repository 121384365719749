//#region react
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
//#endregion

//#region actions
import {
    getCommissionCalculations,
    setCommissionCalculationsSorting,
    setCommissionCalculationsFilters
} from 'store/actions/portal/reports/commissionCalculations/commissionCalculations.action';
//#endregion

//#region components
import MainDashboardLayout from "components/layouts/main";
import Table from "components/common/table";
import Filters from "./filters.component";
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { getUser } from 'utils/auth';
import { hasPermission } from 'utils/permissions';
//#endregion

//#region constants
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { COMMISSION_CALCULATIONS } from 'constants/pageName.constants';
import ApiUrls from 'constants/api.constants';
import { getExpandTableColumns, getTableColumns } from './columns';
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';
import { USER_ROLE } from 'constants/user.constants';
//#endregion

//#region hooks
import useFormat from 'hooks/useFormat';
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endRegion

//#region types
import commissionCalculationType from 'types/commissionPlan/commissionCalculation.type';
import sortingType from 'types/common/sorting.type';
//#endregion

const ROW_UNIQUE_KEY = "uniqueKey";

/** Commission Calculations History Component */
const CommissionCalculationsComponent = ({
    calculations,
    sorting,
    filters,
    total,
    isLoading,
    getCommissionCalculations,
    setCommissionCalculationsSorting,
    setCommissionCalculationsFilters,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const allowImpactOnCPA = getUser()?.allowImpactOnCPA;

    const { formatAmount } = useFormat();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: COMMISSION_CALCULATIONS });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PAYMENTS_COMMISSION_CALCULATIONS,
        action: PERMISSION_ACTION.EXPORT,
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setCommissionCalculationsFilters({
            ...filters,
            affiliateUserNameOrId: value
        })
    }

    const handleDateSearchChange = value => {
        setCommissionCalculationsFilters({
            ...filters,
            from: value[0],
            to: value[1]
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        expandTableColumns,
        columnsThatCanBeIncluded,
        columnsForExport
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            expandColumns: getExpandTableColumns,
            includedColumns,
            constructForInclude: true,
            constructForExport: true,
            additionalProps: {
                formatAmount,
                allowImpactOnCPA
            }
        })
    }, [includedColumns, allowImpactOnCPA]);

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        date: {
            onSearch: handleDateSearchChange,
            placeholder: t('backoffice.common.date'),
            showTime: true,
            allowClear: false,
            loadFn: getCommissionCalculations,
            enabledMountsCount: 12,
            value: [filters.from, filters.to]
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },

        breadcrumbs: {
            items: [
                { title: t('backoffice.menu.commissionCalculations') }
            ]
        },
    }

    if(getUser()?.role !== USER_ROLE.AFFILIATE){
        headerPartsData.search = {
            onSearch: handleSearchChange,
            loadFn: getCommissionCalculations,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.AFFILIATES,
                dependencies: [globalProjectId]
            },
            placeholder: t("backoffice.commissioncalculations.affiliateUserNameOrId"),
        }
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.commissionCalculations"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_COMMISSION_CALCULATION_HISTORY,
            filters: filters,
        }
    }

    //#endregion

    const disabledExpandRowsIds = calculations.filter(calculation => !Boolean(calculation.details)).map(calculation => calculation[ROW_UNIQUE_KEY])
    
    return (
        <MainDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={calculations}
                loadFn={getCommissionCalculations}
                sorting={sorting}
                setSortingFn={setCommissionCalculationsSorting}
                filters={filters}
                setFiltersFn={setCommissionCalculationsFilters}
                total={total}
                updateProps={[globalProjectId]}
                uniqueKey={ROW_UNIQUE_KEY}
                expandable={{
                    title: t("backoffice.affiliates.calculations"),
                    disabled: disabledExpandRowsIds,
                    details: rowData => ({
                        columns: expandTableColumns,
                        data: rowData.details,
                        uniqueKey: ROW_UNIQUE_KEY
                    }),
                }}
            />

        </MainDashboardLayout>
    )
}

/** CommissionCalculationsComponent propTypes
    * PropTypes
*/
CommissionCalculationsComponent.propTypes = {
    /** Redux state property, represents the commission calculations */
    calculations: PropTypes.arrayOf(commissionCalculationType),
    /** Redux state property, commission calculations sorting details */
    sorting: sortingType,
    /** Redux state property, commission calculations filters */
    filters: PropTypes.object,
    /** Redux state property, commission calculations total count */
    total: PropTypes.number,
    /** Redux state property, is true when loading commission calculations */
    isLoading: PropTypes.bool,
    /** Redux action to get commission calculations */
    getCommissionCalculations: PropTypes.func,
    /** Redux action to set commission calculations sorting details */
    setCommissionCalculationsSorting: PropTypes.func,
    /** Redux action to set commission calculations filters */
    setCommissionCalculationsFilters: PropTypes.func,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getCommissionCalculations: nextPage => {
            dispatch(getCommissionCalculations(nextPage))
        },
        setCommissionCalculationsSorting: sorting => {
            dispatch(setCommissionCalculationsSorting(sorting));
        },
        setCommissionCalculationsFilters: filters => {
            dispatch(setCommissionCalculationsFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        calculations: state.commissionCalculations.calculations,
        total: state.commissionCalculations.total,
        sorting: state.commissionCalculations.sorting,
        filters: state.commissionCalculations.filters,
        isLoading: state.commissionCalculations.isLoading,
        globalProjectId: state.common.globalProjectId
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionCalculationsComponent)