import React from "react";

import Status from "components/common/status";
import LockInfo from "components/common/lockInfo";
import Icon from "components/common/icon";

import { STATUS_TYPES } from "constants/status.constants";
import { USER_STATE } from "constants/user.constants";

export const tableColumns = [
    {
        title: "backoffice.affiliates.id",
        dataIndex: "longId",
        copy: true,
        mobileLevel: 1
    },
    {
        title: "backoffice.affiliates.userName",
        dataIndex: "userName",
        mobileLevel: 2
    },
    {
        title: "backoffice.affiliates.status",
        dataIndex: "state",
        mobileLevel: 3,
        render: (value, record) => (
            <Status
                status={value}
                type={STATUS_TYPES.AFFILIATE}
                icon={[USER_STATE.LOCKED_LOGIN, USER_STATE.LOCKED_2_FA].includes(value) ? <Icon name="info" size={16} className="rt--ml-4" /> : null}
                tooltip={
                    [USER_STATE.LOCKED_2_FA, USER_STATE.LOCKED_LOGIN].includes(value) ?
                    (
                        <LockInfo
                            is2FALocked={record?.loginAttemptDetails?.is2FALocked}
                            lastLogin={record?.lastLogin}
                            lockTime={record?.loginAttemptDetails?.lockTime}
                        />
                    ) : null
                } 
            />
        ),
    },
    {
        title: "backoffice.affiliates.registrationDate",
        dataIndex: "createdAt",
        isDateTime: true,
        mobileLevel: 4
    },
];

export const expandTableColumns = [
    {
        title: "backoffice.affiliates.currency",
        dataIndex: "currencyCode",
    },
    {
        title: "backoffice.affiliates.receivedReferralCommission",
        dataIndex: "amount",
        isNumeric: true,
    }
]