import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import {
    SET_PERMISSION_GROUPS_ACTION_BEFORE,
    SET_PERMISSION_GROUPS_ACTION_FINISH,
    SET_PERMISSION_GROUPS,
    SET_PERMISSION_GROUPS_SORTING,
    SET_PERMISSION_GROUPS_SAVE_ACTION_BEFORE,
    SET_PERMISSION_GROUPS_SAVE_ACTION_FINISH
} from "../../../../actionTypes";

export const setPermissionGroupsActionBefore = () => ({
    type: SET_PERMISSION_GROUPS_ACTION_BEFORE,
});

export const setPermissionGroupsActionFinished = () => ({
    type: SET_PERMISSION_GROUPS_ACTION_FINISH,
});

export const setPermissionGroupsSaveActionBefore = () => ({
    type: SET_PERMISSION_GROUPS_SAVE_ACTION_BEFORE,
});

export const setPermissionGroupsSaveActionFinished = () => ({
    type: SET_PERMISSION_GROUPS_SAVE_ACTION_FINISH,
});

const setPermissionGroups = (permissionGroups, add) => ({
    type: SET_PERMISSION_GROUPS,
    payload: { permissionGroups, add },
});

export const setPermissionGroupsSorting = sorting => ({
    type: SET_PERMISSION_GROUPS_SORTING,
    payload: { sorting },
});


export const getPermissionGroups = nextPage => {
    return (dispatch, getState) => {
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().permissionGroups.sorting.page + 1 : getState().permissionGroups.sorting.page;
        let params = {
            ...getState().permissionGroups.sorting,
            page: page
        };

        if(page > 1 && getState().permissionGroups.total <= (page - 1 ) * getState().permissionGroups.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().permissionGroups.total === 0){
            return Promise.resolve();
        }
        
        dispatch(setPermissionGroupsActionBefore());

        return axios({
            url: ApiUrls.GET_PERMISSION_GROUPS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: permissionGroups } }) => {
                dispatch(setPermissionGroups(permissionGroups, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setPermissionGroupsSorting({ ...getState().permissionGroups.sorting, page: page }));
                dispatch(setPermissionGroupsActionFinished());
            })
            .catch((ex) => {
                dispatch(setPermissionGroupsActionFinished());
            });
    }
}

export const createPermissionGroup = (permissionGroup, onSuccess) => {
    return (dispatch) => {
        dispatch(setPermissionGroupsSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_PERMISSION_GROUP,
            method: Methods.POST,
            data: {
                ...permissionGroup
            },
        })
            .then(({ data }) => {
                dispatch(setPermissionGroupsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(err => {
                dispatch(setPermissionGroupsSaveActionFinished());
            });
    };
};

export const deletePermissionGroup = id => {
    return (dispatch) => {
        dispatch(setPermissionGroupsSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_PERMISSION_GROUP,
            method: Methods.DELETE,
            data: { id }
        })
            .then(({ data }) => {
                dispatch(setPermissionGroupsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    dispatch(getPermissionGroups());
                }
            })
            .catch(() => {
                dispatch(setPermissionGroupsSaveActionFinished());
            });
    };
};
