import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form, Input } from 'antd';

import FiltersWrapper from "components/common/filters";
import DateRangePicker from "components/common/dateRangePicker";
import Select from "components/common/select";

import {
    getNewsletters,
    setNewslettersFilters
} from 'store/actions/portal/communications/newsletter/newsletters.action';

import useLanguages from 'hooks/useLanguages';

/** Notifications Page Newsletters Tab Filters Component */

const Filters = ({
    getNewsletters,
    setNewslettersFilters,
    filters,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const [ languages, getLanguages ] = useLanguages(false);

    return (
        <FiltersWrapper
            loadFn={getNewsletters}
            setFiltersFn={setNewslettersFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: false },
                { name: "send", time: true }
            ]}
            onInit={() => {
                getLanguages();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.communication.subject')}
                        name="subject"
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.communication.subject')}`}
                        className="rt--general-form-item"
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.communication.subject')}`}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.communication.language')}
                        name="languageCode"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    Object.keys(languages)
                                        .map(item => (
                                            { value: item.toUpperCase(), text: t(`backoffice.languages.${item}`) }
                                        ))
                                )
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.communication.language')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.creationDate')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.communication.sendDate')}
                        name="send"
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker
                            showTime={true}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get newsletters */
    getNewsletters: PropTypes.func,
    /** Redux action to set newsletters filters */
    setNewslettersFilters: PropTypes.func,
    /** Redux state property, newsletters filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getNewsletters: () => {
            dispatch(getNewsletters())
        },
        setNewslettersFilters: filters => {
            dispatch(setNewslettersFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        globalProjectId: state.common.globalProjectId,
        filters: state.newsletter.newsletters.filters
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);