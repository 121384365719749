import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setCompaniesActionBefore, setCompaniesActionFinished, setCompaniesSaveActionBefore, setCompaniesSaveActionFinished } from './companies.action';

import { SET_COMPANY_GENERAL_INFO } from "../../../actionTypes";

const setCompanyGeneralInfo = info => ({
    type: SET_COMPANY_GENERAL_INFO,
    payload: { info }
})

export const getCompanyGeneralInfo = () => {
    return dispatch => {
        dispatch(setCompaniesActionBefore());

        return axios({
            url: ApiUrls.GET_COMPANY_GENERAL_INFO,
            method: Methods.GET
        })
        .then(({data : {value: info}}) => {
            dispatch(setCompanyGeneralInfo(info))
            dispatch(setCompaniesActionFinished());
        })
        .catch((ex) => {
            dispatch(setCompaniesActionFinished());
        })
    }
}

export const saveCompanyGeneralInfo = company => {
    return dispatch => {
        dispatch(setCompaniesSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_COMPANY_GENERAL_INFO,
            method: Methods.POST,
            data: {
                ...company
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setCompanyGeneralInfo(data.value));
            } 
            dispatch(setCompaniesSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCompaniesSaveActionFinished());
        })
    }
}
