import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { setAccountError } from "store/actions/auth/auth.action";

import { RESPONSE_CODES } from "constants/errorCode.constants";

import useDate from 'hooks/useDate';

let interval = null;

/** Login Page Error message Component */

const ErrorComponent = ({
    errorData,
    setAccountError
}) => {

    const { t } = useTranslation();

    const { dateService } = useDate();

    const [countDown, setCountDown] = useState(null)

    /** Start interval */
    useEffect(() => {
        clearInterval(interval);
        if (errorData.resultCode === RESPONSE_CODES.AccountLoginTemporaryLocked) {
            interval = setInterval(() => {
                if (errorData) {
                    const expireTime = errorData.expireTime;
                    const date = dateService.makeUtcDateToLocal(expireTime);
                    
                    const diff = dateService.getDifference(dateService.getNow(), date, "seconds")
                    
                    if (diff <= 0) {
                        setCountDown(
                            dateService.toString((0 - diff) * 1000, 'mm:ss')
                        );
                    } else {
                        setCountDown(null);
                        setAccountError(null);
                        clearInterval(interval);
                    }
                }
            }, 1000)
        }


    }, [errorData.expireTime])

    useEffect(() => {
        return () => clearInterval(interval);
    }, [])

    return (
        <div className={
            'rt--mb-16 rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16 ' + 
            ( errorData.resultCode === RESPONSE_CODES.NotApproved ? "rt--auth-warning" : "rt--auth-error")
        }>
            <b className='rt--title rt--font-medium rt--font-normal'>
                {
                    errorData.resultCode === RESPONSE_CODES.AccountLoginTemporaryLocked ?
                        t("backoffice.authentication.accountTemporaryLockedText") : errorData.resultCode === RESPONSE_CODES.Blocked ?
                            t("backoffice.authentication.accountBlockedText") : errorData.resultCode === RESPONSE_CODES.NotApproved ?
                                t("backoffice.authentication.accountNotApprovedText") : errorData.resultCode === RESPONSE_CODES.Declined ?
                                    t("backoffice.authentication.accountDeclinedText") : t("backoffice.authentication.accountLockedText")
                }
            </b>
            {
                errorData.resultCode === RESPONSE_CODES.AccountLoginTemporaryLocked && (
                    <div className='rt--mt-8 rt--auth-locked-countdown'>
                        <span className='rt--title rt--font-medium rt--font-normal rt--pt-8'>{countDown || " "}</span>
                    </div>
                )
            }

        </div>
    )
}

ErrorComponent.propTypes = {
    /** Redux state property the account error data */
    errorData: PropTypes.shape({
        resultCode: PropTypes.number,
        expireTime: PropTypes.string
    }),
    /** Redux action to set account error data */
    setAccountError: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        setAccountError: errorData => {
            dispatch(setAccountError(errorData))
        }
    }
)

const mapStateToProps = state => {
    return {
        errorData: state.auth.errorData
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ErrorComponent
);