import PropTypes from 'prop-types';

export default PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    createdAt: PropTypes.string,
    modifiedAt: PropTypes.string,
    lastUpdateBy: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.string)
})