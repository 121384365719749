export const TEMPLATE_CHANNEL_TYPE = {
    EMAIL: 1,
    INBOX: 2
}

export const TEMPLATE_TRIGGER_TYPE = {
    NONE: 1,
    REGISTRATION: 2,
    FORGOT_PASSWORD: 3,
    FORCE_CHANGE: 4,
    DECLINED: 5,
    APPROVED: 6,
    BLOCKED: 7,
    UNBLOCKED: 8
}

export const TEMPLATE_TRIGGER_NAMES_TT_KEYS = {
    [TEMPLATE_TRIGGER_TYPE.REGISTRATION]: "registrationTrigger",
    [TEMPLATE_TRIGGER_TYPE.FORGOT_PASSWORD]: "forgotPasswordTrigger",
    [TEMPLATE_TRIGGER_TYPE.FORCE_CHANGE]: "forceChangeTrigger",
    [TEMPLATE_TRIGGER_TYPE.DECLINED]: "declinedTrigger",
    [TEMPLATE_TRIGGER_TYPE.APPROVED]: "approvedTrigger",
    [TEMPLATE_TRIGGER_TYPE.BLOCKED]: "blockedTrigger",
    [TEMPLATE_TRIGGER_TYPE.UNBLOCKED]: "unblockedTrigger",
}

export const TEMPLATE_VARIABLE_TYPE = {
    PROJECT_NAME: 1,
    PROJECT_EMAIL: 2,
    FIRST_NAME: 3,
    LAST_NAME: 4,
    VERIFICATION_LINK: 5,
    PROJECT_MAIN_DOMAIN: 6
}