import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setUsersActionBefore, setUsersActionFinished, setUsersSaveActionBefore, setUsersSaveActionFinished } from './users.action';

import { SET_USER_PERMISSIONS } from "../../../../actionTypes";

const setUserPermissions = permissions => ({
    type: SET_USER_PERMISSIONS,
    payload: { permissions }
})

export const getUserPermissions = id => {
    return dispatch => {
        dispatch(setUsersActionBefore());

        return axios({
            url: ApiUrls.GET_USER_PERMISSIONS,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: permissions}}) => {
            dispatch(setUserPermissions(permissions))
            dispatch(setUsersActionFinished());
        })
        .catch((ex) => {
            dispatch(setUsersActionFinished());
        })
    }
}

export const saveUserPermissions = data => {
    return dispatch => {
        dispatch(setUsersSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_USER_PERMISSIONS,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setUserPermissions(data.value));
            } 
            dispatch(setUsersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setUsersSaveActionFinished());
        })
    }
}
