import dateService from "utils/date";

import { ORDER_DIRECTION } from "constants/common.constants";

import sessionStorageUtils from "utils/sessionStorage";
import LocalStorageUtils from "utils/localStorage";
import LanguageUtils from "utils/languages";
import { isString } from "utils/javaScriptTypes";

import { COMPANY_PASSWORD_PATTERN } from "constants/company.constants";
import { TRANSLATION_TYPE } from "constants/translation.constants";

import {
	FILTER_TEST_STATE,
	FILTER_USER_STATE,
	FILTER_PLAYER_STATE,
	FILTER_COUNTRY,
} from "constants/filter.constants";
import { PROMO_SECTION_TYPE } from "constants/promo.constants";
import { CUSTOMIZE_FONT_FAMILIES } from "constants/customize.constants";
import { INVOICE_HISTORY_TYPE } from "constants/invoice.constants";

export default {
	common: {
		globalCompanyId: sessionStorageUtils.get("selectedCompanyId") || null,
		globalProjectId: sessionStorageUtils.get("selectedProjectId") || null,
		allCompanies: [],
		isProjectChanging: false,
		systemAvailableLanguages: {},
		isSystemAvailableLanguagesLoading: false,
		projectAvailableLanguages: {},
		isProjectAvailableLanguagesLoading: false,
	},
	auth: {
		isLoading: false,
		expires: -1,
		QRBase64: null,
		passwordSettings: {
			isLoading: false,
			passwordSettings: {},
		},
		isReseting: false,
		isVerifying: false,
		isForgeting: false,
		wsToken: sessionStorageUtils.get("authorizationData")
			? sessionStorageUtils.get("authorizationData").wsToken
			: null,
		translations: {
			languages: sessionStorageUtils.get("languages") ?? {},
			isLanguagesLoading: false,
			isLanguagesLoaded: sessionStorageUtils.get("languages") ? true : false,
			loaded: false,
		},
        logos: []
	},
	register: {
		isLoading: false,
		isSaving: false,
		registrationForm: [],
		availableCurrencies: {},
		availablePayments: [],
		availableChannels: {},
		passwordSettings: {},
		paymentDetails: null,
	},
	companies: {
		companies: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		filters: {
			nameOrId: "",
		},
		edit: {
			general: {},
			passwordSettings: {
				passwordMinLimit: 12,
				passwordMaxLimit: 24,
				forceChange: 3,
				pattern: {
					type: COMPANY_PASSWORD_PATTERN.CONTAINS,
					contain: [],
					alsoIncludeCharacters: "",
					regularExpression: "",
				},
			},
			tokenSettings: {
				lifetimeMinutes: 15,
				multideviceLogin: true,
			},
			authenticationSettings: {},
		},
	},
	projects: {
		projects: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		filters: {
			nameOrId: "",
			isTesting: FILTER_TEST_STATE.ALL,
			to: "",
			from: "",
			modifiedFrom: "",
			modifiedTo: "",
		},
		availableCurrencies: {
			availableCurrencies: {},
			isLoading: false,
		},
		edit: {
			general: {},
            additionalParams: {},
			customHostnames: {
				portal: {},
				promo: {},
				tracking: {},
			},
			sites: [],
			brands: [],
			currencies: [],
			languages: {
				languages: [],
			},
			provider: 0,
			registrationConfig: {
				formControls: [],
			},
			paymentMethods: {
				paymentMethods: [],
				edit: {
					paymentMethod: {
						currencies: [],
					},
				},
			},
			referralSettings: {},
			payoutSettings: {},
			feeSettings: {},
			integration: {},
			chatUI: {},
			promo: {},
		},
		gameProviders: [],
	},
	affiliates: {
		affiliates: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		filters: {
			nameOrId: "",
			state: FILTER_USER_STATE.ALL,
			email: "",
			from: "",
			to: "",
			lastLoginFrom: "",
			lastLoginTo: "",
			parentUserNameOrId: "",
			countries: FILTER_COUNTRY.ALL,
			currencies: [],
		},
		edit: {
			general: {
				personal: {},
				additional: {},
				trafficSources: [],
			},
			finance: {
				paymentMethods: {
					isLoading: false,
					data: [],
					availablePaymentMethods: [],
					paymentMethodFormControls: {},
				},
			},
			subAffiliates: {
				subAffiliates: [],
				referralUrl: null,
				referralCommissionPercent: 0,
				filters: {
					userNameOrId: "",
				},
			},
            isNegativeCarryOverEnabled: true
		},
	},
	affiliateGroups: {
		groups: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		filters: {
			nameOrId: "",
			groupType: "",
			from: "",
			to: "",
			modifiedFrom: "",
			modifiedTo: "",
		},
		availableAffiliates: [],
		edit: {
			general: {},
			members: {
				members: [],
				total: 0,
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "CreatedAt",
					orderDirection: ORDER_DIRECTION.DESC,
				},
				filters: {
					nameOrId: "",
				},
				groupFilter: {},
			},
		},
	},
    affiliateAccess: {
        isLoading: false,
        isSaving: false,
        accessSettings: 0
    },
	wallet: {
		isLoading: false,
		isSaving: false,
		affiliateWallet: {
			wallets: [],
		},
		transferSuccess: false,
		showTransferResult: false,
		transferResultData: [],
		history: {
			transfers: [],
			total: 0,
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "TransactionTime",
				orderDirection: ORDER_DIRECTION.DESC,
			},
			filters: {
				transactionId: "",
				transactionType: "",
				currencyCode: "",
				userNameOrId: "",
				from: dateService.toISOString(dateService.yesterday(true)),
				to: dateService.toISOString(dateService.startOfTomorrow()),
			},
		},
	},
	commissionPlans: {
		isLoading: false,
		isSaving: false,
		commissionPlans: [],
		filters: {
			nameOrId: "",
			productType: "",
			status: "",
			currencyCode: "",
			type: "",
			from: "",
			to: "",
			modifiedFrom: "",
			modifiedTo: "",
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		total: 0,
		availableCommissionPlans: [],
		isAvailableLoading: false,
		edit: {
			general: {},
		},
	},
	invoices: {
		isLoading: false,
		isSaving: false,
		availablePayments: [],
		paymentTranslations: {},
		pending: {
			invoices: [],
			filters: {
				paymentId: "",
				affiliateNameOrId: "",
				id: "",
				from: "",
				to: "",
			},
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "CreatedAt",
				orderDirection: ORDER_DIRECTION.DESC,
			},
		},
		history: {
			invoices: [],
			filters: {
				paymentId: "",
				affiliateNameOrId: "",
				id: "",
				state: "",
				from: dateService.toISOString(dateService.monthsAgo(1, true)),
				to: dateService.toISOString(dateService.startOfTomorrow()),
				modifiedFrom: "",
				modifiedTo: "",
			},
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "CreatedAt",
				orderDirection: ORDER_DIRECTION.DESC,
			},
		},
		totals: {
			isLoading: false,
			[INVOICE_HISTORY_TYPE.PENDING]: {},
			[INVOICE_HISTORY_TYPE.HISTORY]: {},
		},
		invoiceGenerationType: null,
	},
	balanceHistory: {
		isLoading: false,
		history: [],
		filters: {
			transactionId: "",
			transactionType: "",
			userNameOrId: "",
			currencyCode: "",
			walletType: "",
			from: dateService.toISOString(dateService.monthsAgo(3, true)),
			to: dateService.toISOString(dateService.startOfTomorrow()),
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "TransactionTime",
			orderDirection: ORDER_DIRECTION.DESC,
		},
	},
	players: {
		players: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		filters: {
			nameOrId: "",
			externalId: "",
			state: FILTER_PLAYER_STATE.ALL,
			email: "",
			parentUserNameOrId: "",
			currencies: [],
			from: "",
			to: "",
			modifiedFrom: "",
			modifiedTo: "",
		},
		edit: {
			general: {},
		},
		info: null,
	},
	marketingReports: {
		isLoading: false,
		clickReport: {
			report: [],
			total: 0,
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "ClickDate",
				orderDirection: ORDER_DIRECTION.DESC,
			},
			filters: {
				isAllInvalid: "",
				campaignNameOrId: "",
				mediaNameOrId: "",
				affiliateId: "",
				brandId: "",
				iPAddress: "",
				countryCode: "",
				status: "",
				from: dateService.toISOString(dateService.yesterday(true)),
				to: dateService.toISOString(dateService.startOfTomorrow()),
			},
		},
		mediaReport: {
			report: [],
			total: 0,
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "Date",
				orderDirection: ORDER_DIRECTION.DESC,
			},
			filters: {
				campaignNameOrId: "",
				mediaNameOrId: "",
				affiliateId: "",
				brandId: "",
				mediaType: null,
				from: dateService.toISOString(dateService.yesterday(true)),
				to: dateService.toISOString(dateService.startOfTomorrow()),
			},
            totals: {}
		},
	},
	performanceReports: {
		isLoading: false,
		affiliateReport: {
			report: [],
			total: 0,
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "Date",
				orderDirection: ORDER_DIRECTION.DESC,
			},
			filters: {
				affiliateUserNameOrId: "",
				from: dateService.toISOString(dateService.yesterday(true)),
				to: dateService.toISOString(dateService.startOfTomorrow()),
			},
			totals: {},
		},
		playerReport: {
			report: [],
			total: 0,
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "Date",
				orderDirection: ORDER_DIRECTION.DESC,
			},
			filters: {
				userId: "",
				currencyCode: "",
				btag: "",
				brandId: "",
				affiliateId: "",
				from: dateService.toISOString(dateService.yesterday(true)),
				to: dateService.toISOString(dateService.startOfTomorrow()),
			},
			totals: {},
		},
	},
	commissionCalculations: {
		calculations: [],
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "affiliateId",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		filters: {
			type: "",
			affiliateUserNameOrId: "",
			currencyCode: "",
			status: "",
			modifiedFrom: "",
			modifiedTo: "",
			from: dateService.toISOString(dateService.monthsAgo(1, true)),
			to: dateService.toISOString(dateService.startOfTomorrow()),
		},
	},
	calculationApprovals: {
		calculations: [],
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "affiliateId",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		filters: {
			type: "",
			affiliateUserNameOrId: "",
			currencyCode: "",
			from: "",
			to: "",
		},
	},
	aggregatedData: {
		report: [],
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "Date",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		filters: {
			playerId: "",
			affiliateUserNameOrId: "",
			currencyCode: "",
			from: dateService.toISOString(dateService.yesterday(true)),
			to: dateService.toISOString(dateService.startOfTomorrow()),
		},
	},
	campaigns: {
		campaigns: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		filters: {
			nameOrId: "",
			isPrivate: "",
			brandIds: [],
			brandUrlIds: [],
			productType: "",
			languageCode: "",
			countryCode: "",
			from: "",
			to: "",
			modifiedFrom: "",
			modifiedTo: "",
		},
		edit: {
			general: {},
			creatives: {
				creatives: [],
				total: 0,
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "CreatedAt",
					orderDirection: ORDER_DIRECTION.DESC,
				},
				filters: {
					nameOrId: "",
					from: "",
					to: "",
					widthFrom: "",
					widthTo: "",
					heightFrom: "",
					heightTo: "",
					type: "",
				},
				isHtmlLoading: false,
				htmls: {},
			},
		},
	},
	mediaTools: {
		mediaTools: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		creationResult: null,
		mediaCodesData: {},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		filters: {
			affiliateId: null,
			campaignIds: [],
			brandIds: [],
			type: null,
			from: "",
			to: "",
			modifiedFrom: "",
			modifiedTo: "",
		},
	},
	users: {
		users: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		filters: {
			nameOrId: "",
			state: FILTER_USER_STATE.ALL,
			permissionGroup: "",
			from: "",
			to: "",
			modifiedFrom: "",
			modifiedTo: "",
		},
		availableCompanies: {
			isLoading: false,
			availableCompanies: {},
		},
		edit: {
			general: {},
			permissionGroups: {
				groups: [],
				availableGroups: {},
				isAvailableGroupsLoading: false,
			},
			permissions: [],
			companyAccess: {
				companies: [],
				mainCompany: {},
				availableCompanies: [],
			},
		},
	},
	systemNotifications: {
		templates: [],
		isLoading: false,
		isSaving: false,
		edit: {
			email: {
				template: [],
				templateVariables: [],
				systemDefault:
					LocalStorageUtils.get("systemDefaultTemplate") === null
						? true
						: LocalStorageUtils.get("systemDefaultTemplate"),
			},
		},
	},
	newsletter: {
		isLoading: false,
		isSaving: false,
		newsletters: {
			newsletters: [],
			filters: {
				subject: "",
				languageCode: "",
				from: "",
				to: "",
				sendFrom: "",
				sendTo: "",
			},
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "CreatedAt",
				orderDirection: ORDER_DIRECTION.DESC,
			},
			isTestSending: false,
			edit: {
				general: {},
			},
		},
		templates: {
			templates: [],
			filters: {
				nameOrId: "",
				languageCode: "",
				from: "",
				to: "",
				modifiedFrom: "",
				modifiedTo: "",
			},
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "CreatedAt",
				orderDirection: ORDER_DIRECTION.DESC,
			},
		},
		affiliates: {
			isLoading: false,
			availableAffiliates: [],
			affiliates: [],
			total: 0,
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "CreatedAt",
				orderDirection: ORDER_DIRECTION.DESC,
			},
		},
	},
	accessManagers: {
		accessManagers: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		filters: {
			nameOrId: "",
			state: FILTER_USER_STATE.ALL,
			from: "",
			to: "",
			modifiedFrom: "",
			modifiedTo: "",
		},
		edit: {
			general: {},
		},
	},
	affiliateManagers: {
		affiliateManagers: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		filters: {
			nameOrId: "",
			state: FILTER_USER_STATE.ALL,
			from: "",
			to: "",
			modifiedFrom: "",
			modifiedTo: "",
		},
		edit: {
			general: {},
			affiliates: {
				affiliates: [],
				total: 0,
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "AddedAt",
					orderDirection: ORDER_DIRECTION.DESC,
				},
				filters: {
					nameOrId: "",
				},
				availableAffiliates: [],
			},
		},
	},
	account: {
		isLoading: false,
		isSaving: false,
		projectAccess: {
			companies: [],
		},
	},
	permissions: {
		permissions: [],
		isLoading: false,
		isResourcesLoading: false,
		resources: [],
	},
	permissionGroups: {
		isLoading: false,
		isSaving: false,
		permissionGroups: [],
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "Created",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		edit: {
			general: {
				name: "",
				permissions: [],
			},
			users: {
				users: [],
				total: 0,
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "AddedAt",
					orderDirection: ORDER_DIRECTION.DESC,
				},
				filters: {
					userNameOrId: "",
				},
			},
		},
	},
	permissionRequests: {
		isLoading: false,
		isSaving: false,
		pending: {
			requests: [],
		},
		history: {
			history: [],
			total: 0,
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "ModifiedAt",
				orderDirection: ORDER_DIRECTION.DESC,
			},
			filters: {
				objectType: "",
				actionTypes: null,
				status: "",
				modifiedFrom: dateService.toISOString(dateService.yesterday(true)),
				modifiedTo: dateService.toISOString(dateService.startOfTomorrow()),
				from: "",
				to: "",
			},
		},
		count: {},
	},
	profile: {
		userInfo: {
			companies: [],
			currency: {},
			isLoading: true,
		},
        currency: isString(sessionStorageUtils.get("currency")) ? sessionStorageUtils.get('currency') : null,
        currencies: [],
		personalInformation: {
			companies: [],
		},
		formatSettings: {},
		isLoading: false,
		isSaving: false,
		isPasswordChanging: false,
	},
	sessions: {
		sessions: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "StartTime",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		filters: {
			userNameOrId: "",
			from: dateService.toISOString(dateService.yesterday(true)),
			to: dateService.toISOString(dateService.startOfTomorrow()),
		},
	},
	translations: {
		isLoading: false,
		isGroupsLoading: false,
		isPublishing: false,
		isUnpublishing: false,
		groups: {
			[TRANSLATION_TYPE.PROMO]: [],
			[TRANSLATION_TYPE.BO]: [],
		},
		translations: [],
		systemDefault:
			LocalStorageUtils.get("systemDefaultTranslation") === null
				? true
				: LocalStorageUtils.get("systemDefaultTranslation"),
	},
	systemCurrencies: {
		currencies: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.ASC,
		},
		availableCurrencies: {},
		systemAvailableCurrencies: [],
		isAvailableLoading: false,
	},
	systemLanguages: {
		languages: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		availableLanguages: {},
		isAvailableLoading: false,
	},
	systemPayments: {
		payments: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		filters: {
			nameOrId: undefined,
			projectNameOrId: undefined,
			labels: [],
			state: FILTER_USER_STATE.ALL,
			from: "",
			to: "",
		},
		edit: {
			general: {
				formControls: [],
			},
		},
		translations: [],
	},
	notifications: {
		isLoading: false,
		notifications: [],
		sound: false,
	},
	userLogs: {
		isLoading: false,
		userLogs: [],
		total: 0,
		filters: {
			userNameOrId: "",
			resource: "",
			resourceId: "",
			action: "",
			actionType: "",
			year: dateService.getCurrentYMD().year,
			month: dateService.getCurrentYMD().month,
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "LogTime",
			orderDirection: ORDER_DIRECTION.DESC,
		},
		resources: {},
	},
	errors: {
		isLoading: false,
		errors: [],
		total: 0,
		filters: {
			apiType: "",
			userId: "",
			controller: "",
			action: "",
			year: dateService.getCurrentYMD().year,
			month: dateService.getCurrentYMD().month,
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "ErrorTime",
			orderDirection: ORDER_DIRECTION.DESC,
		},
	},
	requests: {
		isLoading: false,
		requests: [],
		total: 0,
		filters: {
			status: "",
			action: "",
			year: dateService.getCurrentYMD().year,
			month: dateService.getCurrentYMD().month,
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC,
		},
	},
	generations: {
		generations: [],
		isLoading: false,
	},
	dbConnections: {
		dbConnections: {},
		isLoading: false,
	},
	jobs: {
		jobs: [],
		isLoading: false,
		isSaving: false,
		isDetailsLoading: false,
		jobSettings: {},
		jobDetails: {},
	},
	monitoring: {
		isLoading: false,
		isServicesLoading: false,
		services: [],
		service: {},
	},
	postDeploymentActions: {
		isLoading: false,
		isDataExporting: false,
		languagesData: {
			isLoading: false,
			languages: [],
		},
	},
	platforms: {
		isLoading: false,
		platforms: [],
		isTesting: false,
		platformTests: {
			gateways: [],
		},
	},
	system: {
		isTimezonesLoading: false,
		timezones: [],
	},
	promoCustomize: {
		customizeLanguage: LanguageUtils.getSelectedLanguage(),
		isLoading: false,
		isSaving: false,
		isPublishing: false,
		configuration: {},
		colors: {
			brandColor: "",
			secondaryColor: "",
			backgroundColor: "",
			textAndIconColor: "",
		},
		fontFamily: CUSTOMIZE_FONT_FAMILIES[0],
		images: {},
		languages: [],
		translationGroups: [],
		sections: {
			[PROMO_SECTION_TYPE.GENERAL_INFO]: {
				translations: {},
			},
			[PROMO_SECTION_TYPE.STATISTICS]: {
				data: {},
				translations: {},
			},
			[PROMO_SECTION_TYPE.EARN_PROCESS]: {
				data: {},
				translations: {},
			},
			[PROMO_SECTION_TYPE.BRANDS]: {
				data: [],
			},
			[PROMO_SECTION_TYPE.FAQ]: {
				data: {},
				translations: {},
			},
			[PROMO_SECTION_TYPE.TESTIMONIAL]: {
				data: {},
				translations: {},
			},
			[PROMO_SECTION_TYPE.MARKETING_TOOLS]: {
				data: {},
				translations: {},
			},
			[PROMO_SECTION_TYPE.COMMISSION]: {
				data: {},
				translations: {},
			},
			[PROMO_SECTION_TYPE.SEO]: {
				data: [],
				translations: {},
			},
			[PROMO_SECTION_TYPE.STORIES]: {
				data: [],
			},
			[PROMO_SECTION_TYPE.FOOTER]: {
				data: {},
				translations: {},
			},
		},
	},
	newsletterCustomize: {
		isLoading: false,
		isSaving: false,
		configuration: {},
		templateVariables: [],
	},
};
