import PropTypes from 'prop-types';

export default PropTypes.objectOf(PropTypes.shape({
    currencyCode: PropTypes.string,
    depositAmount: PropTypes.number,
    depositCount: PropTypes.number,
    withdrawAmount: PropTypes.number,
    withdrawCount: PropTypes.number,
    netDepositAmount: PropTypes.number,
    ngr: PropTypes.number,
    sportBetCount: PropTypes.number,
    sportBetAmount: PropTypes.number,
    sportWonAmount: PropTypes.number,
    sportBonusWonAmount: PropTypes.number,
    sportGGR: PropTypes.number,
    sportNGR: PropTypes.number,
    casinoBetCount: PropTypes.number,
    casinoBetAmount: PropTypes.number,
    casinoWonAmount: PropTypes.number,
    casinoBonusWonAmount: PropTypes.number,
    casinoGGR: PropTypes.number,
    casinoNGR: PropTypes.number,
    adjustmentsAmount: PropTypes.number
}))