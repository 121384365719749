import PropTypes from 'prop-types';

export default PropTypes.shape({
    resource: PropTypes.string,
    name: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.shape({
        action: PropTypes.oneOf(["Modify", "Delete", "View", "Create", "Export"]),
        description: PropTypes.string,
        canEdit: PropTypes.bool,
        checked: PropTypes.bool
    })),
    subPermissions: PropTypes.arrayOf(PropTypes.shape({
        resource: PropTypes.string,
        name: PropTypes.string,
        actions: PropTypes.arrayOf(PropTypes.shape({
            action: PropTypes.oneOf(["Modify", "Delete", "View", "Create", "Export"]),
            description: PropTypes.string,
            canEdit: PropTypes.bool,
            checked: PropTypes.bool
        }))
    }))
})