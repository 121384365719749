import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Row, Col, Spin } from 'antd';

import PermissionsList from 'components/common/permissions';
import TabFormDashboardLayout from "components/layouts/tab/form";
import Select from "components/common/select";
import TextAreaInput from 'components/common/textAreaInput';
import Input from 'components/common/input';
import Notes from 'components/common/notes';
import PendingRequestsNote from 'components/common/pendingRequestsNote';

import { getPermissionGroupGeneralInfo, savePermissionGroupGeneralInfo } from "store/actions/portal/userManagement/permissionGroups/general.action";

import { SECURITY_LEVELS, USER_ROLE, USER_TYPE } from 'constants/user.constants';
import { PERMISSION_REQUEST_OBJECT_TYPE, PERMISSION_REQUEST_TYPE } from 'constants/permissionRequest.constants';

import { getUser } from 'utils/auth';
import { isMobile } from 'utils/common';
import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { DESCRIPTION_REGEX, NAME_REGEX } from 'constants/regex.constants';

import permissionGroupGeneralInfoType from "types/permissionGroup/generalInfo.type";
import userInfoType from 'types/profile/userInfo.type';


/** Permission Group Edit Page General Info Tab Component */
const GeneralInfoComponent = ({
    getPermissionGroupGeneralInfo,
    savePermissionGroupGeneralInfo,
    isSaving,
    isLoading,
    generalInfo,
    userInfo,
    onTabChange
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);
    const [isCheckedAll, setIsCheckedAll] = useState(false);

    const _permissionRef = useRef(null);

    const [initialValues, setInitialValues] = useState({ permissions: {} });

    const [notes, setNotes] = useState(null);

    const userType = Number((new URLSearchParams(location.search)).get("type") || USER_TYPE.ADMIN);

    const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.MODIFY })


    /** Load user general info */
    useEffect(() => {
        getPermissionGroupGeneralInfo(searchParams.id)
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        const permissions = {}
        generalInfo.permissions.forEach(perm => {
            let data = {};
            data['parent'] = {};
            perm.actions.forEach(a => {
                data['parent'][a.action] = a.checked
            })
            perm.subPermissions.forEach(sub => {
                data[sub.resource] = {};
                sub.actions.forEach(a => {
                    data[sub.resource][a.action] = a.checked
                })
            })
            permissions[perm.resource] = data;
        })

        const values = {
            name: generalInfo.name,
            description: generalInfo.description,
            permissions: permissions
        };

        if (userType === USER_TYPE.ADMIN) {
            values.securityLevel = generalInfo.securityLevel;
        }

        setFieldsValue(values);

        setInitialValues(values);
    }, [generalInfo])


    /** Fires when form submitted
       * @function
       * @memberOf GeneralInfoComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const perms = Object.keys(data.permissions || {}).map(resource => ({
                    resource: resource,
                    actions: data.permissions[resource].parent ? Object.keys(data.permissions[resource].parent).filter(k => data.permissions[resource]['parent'][k] === true) : [],
                    subPermissions: Object.keys(data.permissions[resource]).filter(r => r !== "parent").map(r => ({
                        resource: r,
                        actions: data.permissions[resource][r] ? Object.keys(data.permissions[resource][r]).filter(k => data.permissions[resource][r][k] === true) : []
                    }))
                }));

                const d = {
                    id: searchParams.id,
                    permissions: perms,
                    name: data.name,
                    description: data.description
                }

                if (userType === USER_TYPE.ADMIN) {
                    d.securityLevel = generalInfo.securityLevel;
                }

                if (getUser().role === USER_ROLE.ACCESS_MANAGER) {
                    setNotes(d);
                    return;
                }

                savePermissionGroupGeneralInfo(d);

                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    const canEditNameAndDescription = hasModifyPermission && getUser()?.role !== USER_ROLE.ACCESS_MANAGER

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasModifyPermission,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                {
                    (
                        <PendingRequestsNote
                            objectId={searchParams.id}
                            actionTypes={[PERMISSION_REQUEST_TYPE.GROUP_MODIFY]}
                            objectType={PERMISSION_REQUEST_OBJECT_TYPE.PERMISSION_GROUP}
                            dependencies={[generalInfo]}
                        />
                    )
                }

                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(_, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, initialValues))}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={`${t('backoffice.permissiongroups.name')} *`}
                                name="name"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                    { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst
                                className={'rt--general-form-item' + (!canEditNameAndDescription ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.permissiongroups.name')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.permissiongroups.name')}`}
                                    maxLength={100}
                                    disabled={!canEditNameAndDescription}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={`${t('backoffice.permissiongroups.description')}`}
                                name="description"
                                rules={[
                                    { pattern: DESCRIPTION_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                className={"rt--general-form-item" + (!canEditNameAndDescription ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.permissiongroups.description')}`}
                            >
                                <TextAreaInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.permissiongroups.description')}`}
                                    maxLength={150}
                                    rows={1}
                                    disabled={!canEditNameAndDescription}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            {
                                userType === USER_TYPE.ADMIN && (

                                    <Form.Item
                                        label={t('backoffice.permissiongroups.securityLevel')}
                                        name="securityLevel"
                                        className='rt--form-item-disabled'
                                    >
                                        <Select
                                            options={
                                                SECURITY_LEVELS
                                                    .filter(level => level <= userInfo.securityLevel)
                                                    .map(level => (
                                                        { value: level, text: level }
                                                    ))
                                            }
                                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.permissiongroups.securityLevel')}`}
                                            disabled={true}
                                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                        />
                                    </Form.Item>
                                )
                            }
                        </Col>
                        {
                            hasModifyPermission && !_permissionRef?.current?.isDisabled && (
                                <Col xs={24} sm={12} xl={6}>
                                    <Form.Item
                                        label={isMobile() ? "" : " "}
                                    >
                                        <div className='rt--flex rt--justify-end rt--align-center'>
                                            <span
                                                className='rt--title rt--font-normal rt--font-medium rt--cursor-pointer'
                                                onClick={() => _permissionRef.current && _permissionRef.current.toggleAll(isCheckedAll)}
                                            >
                                                {
                                                    isCheckedAll ? t("backoffice.common.deselectAll") : t("backoffice.common.selectAll")
                                                }
                                            </span>
                                        </div>
                                    </Form.Item>
                                </Col>
                            )
                        }

                    </Row>
                    <PermissionsList
                        ref={_permissionRef}
                        permissions={generalInfo.permissions}
                        isLoading={isLoading}
                        formInstance={formInstance}
                        initialFormValues={initialValues.permissions}
                        editable={true}
                        onCheckAll={() => setIsFormTouched(true)}
                        onChange={value => setIsCheckedAll(value)}
                        fieldName="permissions"
                        disabled={!hasModifyPermission}
                    />

                </Form>

                {
                    notes && (
                        <Notes
                            onClose={() => setNotes(null)}
                            onSuccess={value => {
                                savePermissionGroupGeneralInfo({
                                    ...notes,
                                    note: value
                                });
                                setIsFormTouched(false);
                            }}
                        />
                    )
                }
            </Spin>
        </TabFormDashboardLayout>
    )
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Redux action to get permission group General info */
    getPermissionGroupGeneralInfo: PropTypes.func,
    /** Redux action to save permission group General info */
    savePermissionGroupGeneralInfo: PropTypes.func,
    /** Redux state property, is true when general info is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when general info is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the general info of current editing permission group  */
    generalInfo: permissionGroupGeneralInfoType,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        savePermissionGroupGeneralInfo: data => {
            dispatch(savePermissionGroupGeneralInfo(data));
        },

        getPermissionGroupGeneralInfo: id => {
            dispatch(getPermissionGroupGeneralInfo(id));
        }
    }
)

const mapStateToProps = state => {
    return {
        generalInfo: state.permissionGroups.edit.general,
        isSaving: state.permissionGroups.isSaving,
        isLoading: state.permissionGroups.isLoading,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent)