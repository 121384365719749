import React from "react";

import CalculationApprovalsComponent from "pages/payment/calculationApprovals/calculationApprovals.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const CalculationApprovalsRoute = () => {
    return <CalculationApprovalsComponent />;
};

export default withPermission(
    withAuth(CalculationApprovalsRoute),
    { resource: PERMISSION_RESOURCE.PAYMENTS_COMMISSION_CALCULATIONS, action: PERMISSION_ACTION.VIEW },
    Paths.PORTAL
);
