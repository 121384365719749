import React, { Fragment } from 'react';

import ProfileDropdown from './profileDropdown';
import CurrenciesDropdown from './currenciesDropdown';
import CompaniesDropdown from '../companiesDropdown';
import Notifications from '../notifications';
import LoggedinAsAffiliate from './loggedinAsAffiliate';
import Icon from 'components/common/icon';

import { isMobile } from "utils/common";
import { getUser } from 'utils/auth';

import { USER_ROLE } from "constants/user.constants";

/** Header component */

const Header = () => {

    return (
        <Fragment>
            {
                isMobile() && <LoggedinAsAffiliate />
            }
            <div className='rt--header rt--flex rt--justify-between rt--align-center rt--pl-16 rt--pr-16'>

                {
                    isMobile() && (
                        <div
                            className='rt--header-menu-btn rt--flex rt--align-center'
                            onClick={() => document.body.classList.add("rt--body-open-sidebar")}
                        >
                            <Icon name='menu' />
                        </div>
                    )
                }
                {
                    !isMobile() && <LoggedinAsAffiliate />
                }

                <CompaniesDropdown />

                <div className='rt--header-right rt--flex rt--align-center rt--flex-equal rt--justify-end'>
                    {
                        getUser()?.role === USER_ROLE.AFFILIATE && (
                            <CurrenciesDropdown />
                        )
                    }

                    <Notifications />

                    <ProfileDropdown />
                </div>

            </div>
        </Fragment>
    )

}

export default Header;
