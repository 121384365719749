import {
    SET_MARKETING_REPORTS_ACTION_BEFORE,
    SET_MARKETING_REPORTS_ACTION_FINISH
} from "../../../../actionTypes";

export const setMarketingReportsActionBefore = () => ({
    type: SET_MARKETING_REPORTS_ACTION_BEFORE,
});

export const setMarketingReportsActionFinished = () => ({
    type: SET_MARKETING_REPORTS_ACTION_FINISH,
});