import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { 
    SET_FORMAT_SETTINGS
} from "../../../actionTypes";

import { 
    setProfileActionBefore,
    setProfileActionFinished,
    setProfileSaveActionBefore,
    setProfileSaveActionFinished
} from "./profile.action";


const setFormatSettings = formatSettings => ({
    type: SET_FORMAT_SETTINGS,
    payload: { formatSettings }
})

export const getFormatSettings = () => {
    return dispatch => {
        dispatch(setProfileActionBefore());

        return axios({
            url: ApiUrls.GET_FORMAT_SETTINGS,
            method: Methods.GET
        })
        .then(({data : {value: formatSettings}}) => {
            dispatch(setFormatSettings(formatSettings));
            dispatch(setProfileActionFinished());
        })
        .catch(() => {
            dispatch(setProfileActionFinished());
        })
    }
}

export const saveFormatSettings = formatSettings => {
    return dispatch => {
        dispatch(setProfileSaveActionBefore());

        return axios({
            url: ApiUrls.SAVE_FORMAT_SETTINGS,
            method: Methods.POST,
            data: {
                ...formatSettings
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setFormatSettings(data.value));
            } 
            dispatch(setProfileSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProfileSaveActionFinished());
        })
    }
}