//#region React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
//#endregion

import { Form, Col, Row } from 'antd';

//#region Components
import Modal from "components/common/modal";
import Select from "components/common/select";
import Input from "components/common/input";
//#endregion

//#region Actions
import { addProjectBrandUrl, saveProjectBrandUrl } from "store/actions/portal/projects/brands.action";
//#endregion

//#region Constants
import { POPUP_SIZE } from "constants/common.constants";
import { PROJECT_BRAND_PRODUCT_TYPE } from 'constants/project.constants';
import { NAME_REGEX } from 'constants/regex.constants';
//#endregion

//#region utils
import { isMobile } from 'utils/common';
import { isFormChanged } from "utils/form";
//#endregion

//#region types
import brandUrlType from 'types/project/brandUrl.type';
//#endregion

//#region hooks
import useLanguages from 'hooks/useLanguages';
//#endregion

/** Add/Edit Brand URL to the Brand Popup Component */
const AddEditBrandUrl = ({
    addProjectBrandUrl,
    saveProjectBrandUrl,
    url,
    mainDomain,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const { search } = useLocation();

    /** Is Edit Mode */
    const editMode = url !== null;

    const [isFormTouched, setIsFormTouched] = useState(editMode ? false : true);

    const currentBrandId = (new URLSearchParams(search)).get("brandId");

    const defaultValues = editMode ? {
        languageCode: url.languageCode,
        relative: url.relative,
        subDomain: url.subDomain,
        product: url.product,
        name: url.name
    } : {};

    const [ languages ] = useLanguages();

    useEffect(() => {
        if(editMode){
            setFieldsValue(defaultValues)
        }
    }, [url])

    const handleForm = () => {
        validateFields()
            .then((data) => {
                const d = {
                    product: data.product,
                    subDomain: data.subDomain,
                    relative: data.relative,
                    languageCode: data.languageCode,
                    name: data.name
                }

                d.brandId = currentBrandId;
                if(editMode){
                    d.id = url.id
                }

                if(editMode){
                    saveProjectBrandUrl(d, onClose)
                } else {
                    addProjectBrandUrl(d, onClose)
                }

            }).catch(ex => { 
                console.log(ex)
            })
    };

    return (
        <Modal
            title={editMode ? t('backoffice.projects.editUrl') : t('backoffice.projects.addUrl')}
            cancelText={t('backoffice.common.cancel')}
            okText={editMode ? t('backoffice.common.save') : t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            size={POPUP_SIZE.BIG}
            disableOkButton={!isFormTouched}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                onValuesChange={(_, formValues) => {
                    if (editMode) {
                        setIsFormTouched(isFormChanged({ ...formValues }, { ...defaultValues }))
                    }
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <div className='rt--flex rt--align-end rt--justify-between rt--form-item-inputs'>
                            <Form.Item
                                label={t('backoffice.projects.url')}
                                name="subDomain"
                                className='rt--general-form-item rt--flex-equal rt--form-item-inputs-left'
                                data-placeholder={t('backoffice.projects.subDomain')}
                            >
                                <Input
                                    placeholder={t('backoffice.projects.subDomain')}
                                    autoFocus={!isMobile()}
                                />
                            </Form.Item>
                            <Form.Item
                                label={" "}
                            >
                                <div className='rt--form-item-inputs-separator rt--pl-12 rt--pr-12 rt--flex rt--align-center rt--justify-center'>
                                    <span className='rt--title rt--font-normal rt--font-regular'>{mainDomain}</span>
                                </div>
                            </Form.Item>
                            <Form.Item
                                label={" "}
                                name="relative"
                                className='rt--general-form-item rt--flex-equal rt--form-item-inputs-right'
                                data-placeholder="/"
                            >
                                <Input
                                    placeholder="/"
                                />
                            </Form.Item>
                        </div>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label={`${t('backoffice.projects.name')} *`}
                            name="name"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { min: 2, message: t('backoffice.validation.fieldInvalid') },
                                { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className="rt--general-form-item"
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.name')}`}
                            validateFirst
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.name')}`}
                                maxLength={30}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label={`${t('backoffice.projects.language')} *`}
                            name="languageCode"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') }
                            ]}
                            className="rt--form-item-without-margin"
                        >
                            <Select
                                options={
                                    Object.keys(languages)
                                        .map(item => (
                                            { value: item.toUpperCase(), text: t(`backoffice.languages.${item}`) }
                                        ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.language')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                search={true}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label={`${t('backoffice.projects.product')} *`}
                            name="product"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') }
                            ]}
                            className="rt--form-item-without-margin"
                        >
                            <Select
                                options={[
                                    { value: PROJECT_BRAND_PRODUCT_TYPE.CASINO, text: t('backoffice.projects.casino') },
                                    { value: PROJECT_BRAND_PRODUCT_TYPE.SPORT, text: t('backoffice.projects.sport') },
                                    { value: PROJECT_BRAND_PRODUCT_TYPE.OTHER, text: t('backoffice.projects.other') }
                                ]}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.product')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

AddEditBrandUrl.propTypes = {
    /** Redux action to add Brand url to the brand */
    addProjectBrandUrl: PropTypes.func,
    /** Redux action to update Brand url of the brand */
    saveProjectBrandUrl: PropTypes.func,
    /** Current Editing URL */
    url: brandUrlType,
    /** Main Domain */
    mainDomain: PropTypes.string,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        addProjectBrandUrl: (url, onSuccess) => {
            dispatch(addProjectBrandUrl(url, onSuccess));
        },

        saveProjectBrandUrl: (url, onSuccess) => {
            dispatch(saveProjectBrandUrl(url, onSuccess));
        }
    }
)


export default connect(null, mapDispatchToProps)(AddEditBrandUrl);