import React from 'react';

import { useParams } from "react-router-dom";
import { connect } from 'react-redux';

import Sessions from '../../../../../sessions/sessions.component';

import { PERMISSION_RESOURCE } from "constants/permissions.constants";
import { USER_TYPE, USER_ROLE } from 'constants/user.constants';

import { getUser } from 'utils/auth';

import userInfoType from 'types/profile/userInfo.type';

/** Affiliate Edit Page Sessions Tab Component */
const SessionsComponent = ({
    userInfo
}) => {
    const searchParams = useParams();

    const affiliateId = getUser()?.role === USER_ROLE.AFFILIATE ? userInfo.id : searchParams.id;

    return (
        <Sessions
            userRole={USER_TYPE.AFFILIATE}
            userId={affiliateId}
            permissionResource={PERMISSION_RESOURCE.AFFILIATE_SESSIONS}
        />
    )
}

SessionsComponent.propTypes = {
    /** Redux state property, the user info */
    userInfo: userInfoType
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, null)(SessionsComponent);
