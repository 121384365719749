import {
    SET_DB_CONNECTIONS_ACTION_BEFORE,
    SET_DB_CONNECTIONS_ACTION_FINISH,
    SET_DB_CONNECTIONS
} from '../../actionTypes';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_DB_CONNECTIONS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_DB_CONNECTIONS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_DB_CONNECTIONS:
            return {
                ...state,
                dbConnections: payload.connections
            }
        default:
            return state;
    }
}