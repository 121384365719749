import PropTypes from 'prop-types';

import autoSuggestionType from "types/autoSuggestion/autoSuggestion.type";

import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { SEARCH_TYPE } from 'components/common/search/constants';

export default {
    /** Search Type */
    type: PropTypes.oneOf(Object.values(SEARCH_TYPE)),
    /** Function to call on search */
    onSearch: PropTypes.func,
    /** Function to call on change */
    onChange: PropTypes.func,
    /** Value if input needs to be controled from outside */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    /** Function to call to laod table data */
    loadFn: PropTypes.func,
    /** Autosuggestion */
    autoSuggestion: PropTypes.shape({
        /** Autosuggestion type */
        type: PropTypes.oneOf(Object.values(AUTOSUGGESTION_TYPE)),
        /** Autosuggestion update dependenceis */
        dependencies: PropTypes.array,
        /** Params for autosuggeston call */
        actionParams: PropTypes.object,
        /** Autosuggestion Items */
        items: PropTypes.arrayOf(autoSuggestionType),
        /** Should get data automatically */
        autoGet: PropTypes.bool,
        /** Search field name, for autosuggestion data get if autoGet is turned off */
        searchFieldName: PropTypes.string
    }),
    /** The placeholder */
    placeholder: PropTypes.string,
    /** Max characters of value */
    maxLength: PropTypes.number,
    /** Input referance */
    inputRef: PropTypes.object,
    /** Allow clear for datepicke */
    allowClear: PropTypes.bool,
    /** Show time for datepicker */
    showTime: PropTypes.bool,
    /** Depend predefined ranges enabled mounts count */
    enabledMountsCount: PropTypes.number
}