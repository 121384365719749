import React from "react";

export const tableColumns = [
    {
        title: "backoffice.common.id",
        dataIndex: "longId",
        alwaysVisible: true,
        copy: true,
        mobileLevel: 1
    },

    {
        title: "backoffice.projects.name",
        dataIndex: "name",
        alwaysVisible: true,
        mobileLevel: 2
    },

    {
        title: "backoffice.projects.mainDomain",
        dataIndex: "domain",
        mobileLevel: 3,
        render: value => {
            return (
                <span 
                    className="rt--title rt--font-normal rt--font-regular rt--table-col-link"
                    onClick={() => window.open( `https://${value}`,"_blank")}
                >
                    <b>{ value }</b>
                </span>
            )
        }
    },

    {
        title: "backoffice.projects.urls",
        dataIndex: "urls",
        render: value => value.length,
        mobileLevel: 4
    },

    {
        title: "backoffice.projects.createdAt",
        dataIndex: "createdAt",
        isDateTime: true,
        mobileLevel: 5
    },

    {
        title: "backoffice.projects.lastUpdatedAt",
        dataIndex: "modifiedAt",
        isDateTime: true,
        mobileLevel: 6
    },
];
