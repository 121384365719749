//#region react
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
//#endregion

//#region actions
import {
    getUserLogs,
    getUserLogDetails,
    setUserLogsSorting
} from "store/actions/portal/userLogs/userLogs.action";
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import Filters from "./filters.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import { EXPAND_TABLE_TYPES } from 'constants/common.constants';
import ApiUrls from 'constants/api.constants';
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { USER_LOGS } from "constants/pageName.constants";
import { expandColumns, tableColumns } from "./columns";
//#endregion

//#region types
import userLogType from "types/userLog/userLog.type";
import sortingType from "types/common/sorting.type";
//#endregion

const EXPANDED_ROW_UNIQUE_KEY_PROP = "index";

const addIndexPropToEachRowData = (rowsData) => {
    return (
        rowsData.map((rowData, index) => ({
            ...rowData,
            index: index + 1
        }))
    );
}

const getExpandTableDetails = ({ record, expandTableColumns }) => {
    if (!record.details) {
        return null;
    }

    const expandTableData = addIndexPropToEachRowData(record.details);

    return {
        columns: expandTableColumns,
        data: expandTableData,
        uniqueKey: EXPANDED_ROW_UNIQUE_KEY_PROP,
    }
}

const UserLogsComponent = ({
    getUserLogs,
    getUserLogDetails,
    userLogs,
    isLoading,
    total,
    setUserLogsSorting,
    sorting,
    filters,
    globalCompanyId
}) => {
    const { t } = useTranslation();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: USER_LOGS });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.USER_LOGS,
        action: PERMISSION_ACTION.EXPORT
    })

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        expandTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            expandColumns,
            mainColumns: tableColumns,
            constructForExport: true,
            constructForInclude: true,
        });
    }, [includedColumns])

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns,
        },
        breadcrumbs: {
            items: [
                { title: t('backoffice.menu.userLogs') }
            ]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.userLogs"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_USER_LOGS,
            filters: filters,
        }
    }

    //#endregion

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={userLogs}
                loadFn={getUserLogs}
                sorting={sorting}
                setSortingFn={setUserLogsSorting}
                total={total}
                updateProps={[globalCompanyId]}
                expandable={{
                    title: t('backoffice.userlogs.logHistory'),
                    detailsLoadFn: record => (
                        !Boolean(record.details) && getUserLogDetails(record.id)
                    ),
                    details: record => (
                        getExpandTableDetails({
                            record,
                            expandTableColumns,
                        })
                    ),
                    type: EXPAND_TABLE_TYPES.TABLE
                }}
            />
        </MainDashboardLayout>
    )
};

/** UserLogsComponent propTypes
 * PropTypes
*/
UserLogsComponent.propTypes = {
    /** Redux action to get user logs */
    getUserLogs: PropTypes.func,
    /** Redux action to get user logs details */
    getUserLogDetails: PropTypes.func,
    /** Redux state property, represents the array of user logs  */
    userLogs: PropTypes.arrayOf(userLogType),
    /** Redux state property, is true when loading user logs */
    isLoading: PropTypes.bool,
    /** Redux state property, user logs total count */
    total: PropTypes.number,
    /** Redux action to set user logs sorting details */
    setUserLogsSorting: PropTypes.func,
    /** Redux state property, user logs sorting details */
    sorting: sortingType,
    /** Redux state property, users filters */
    filters: PropTypes.object,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    getUserLogs: nextPage => {
        dispatch(getUserLogs(nextPage));
    },
    getUserLogDetails: id => {
        dispatch(getUserLogDetails(id))
    },
    setUserLogsSorting: sorting => {
        dispatch(setUserLogsSorting(sorting));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.userLogs.isLoading,
        userLogs: state.userLogs.userLogs,
        total: state.userLogs.total,
        sorting: state.userLogs.sorting,
        filters: state.userLogs.filters,
        globalCompanyId: state.common.globalCompanyId
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserLogsComponent);
