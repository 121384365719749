import { USER_STATE } from "constants/user.constants";

export const getDisabledExpandRowsIds = affiliates => {
    return (
        affiliates.filter(affiliateData => affiliateData.wallets.length === 0 || affiliateData.state === USER_STATE.DELETED).map(affiliateData => affiliateData.id)
    )
}

export const constructAffiliatesWalletData = affiliates => {
    return affiliates?.map(affiliate => {
        const combinedWalletDataByCurrency = {};

        affiliate?.wallets?.forEach(walletData => {
            if (!combinedWalletDataByCurrency[walletData.currencyCode]) {

                combinedWalletDataByCurrency[walletData.currencyCode] = {
                    "currencyCode": walletData.currencyCode,
                    [walletData.type]: {
                        ...walletData
                    }
                }

                return;
            }

            combinedWalletDataByCurrency[walletData.currencyCode][walletData.type] = {
                ...walletData
            }
        })

        affiliate.wallets = Object.values(combinedWalletDataByCurrency);

        return affiliate;
    })
}

export const updateWalletsAfterAdjustment = (initial, updated) => {
    let wallets = [...initial];
    updated.forEach(u => {
        const walletForCurrencyExist = wallets.find(w => w.currencyCode === u.currencyCode);
        if (walletForCurrencyExist) {
            wallets = wallets.map(w => {
                let wallet = { ...w };

                if (wallet.currencyCode !== u.currencyCode) return wallet;

                if (wallet[u.type] !== undefined) {
                    wallet[u.type].amount = u.totalAmount;
                } else {
                    wallet[u.type] = {
                        amount: u.totalAmount,
                        type: u.type,
                        currencyCode: u.currencyCode
                    }
                }
                return wallet;
            })
        } else {
            wallets.push({
                currencyCode: u.currencyCode,
                [u.type]: {
                    amount: u.totalAmount,
                    type: u.type,
                    currencyCode: u.currencyCode
                }
            })
        }
    })


    return wallets;
}