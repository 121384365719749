import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Tabs from "components/common/tabs";

import PayoutSettingsComponent from './payoutSettings';
import PaymentMethodsComponent from './paymentMethods';
import FeeComponent from './fee';

import { getUser } from 'utils/auth';

import { USER_ROLE } from 'constants/user.constants';
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';

/** Project Edit Page Financial Tab Component */
const FinancialComponent = ({
    onTabChange
}) => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.projects.calculationAndPayouts"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_PAYOUT_SETTINGS, action: PERMISSION_ACTION.VIEW }],
            component: <PayoutSettingsComponent/>
        },
        {
            title: t("backoffice.projects.paymentMethods"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_PAYMENT_METHODS, action: PERMISSION_ACTION.VIEW }],
            component: <PaymentMethodsComponent />
        },
        {
            title: t("backoffice.projects.fee"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_PAYOUT_SETTINGS, action: PERMISSION_ACTION.VIEW }],
            component: <FeeComponent/>,
            disabled: getUser()?.role !== USER_ROLE.ADMIN
        },
    ]

    return (
        <Tabs.SubTabs 
            items={items}
            onTabChange={onTabChange}
        />
    )
}

/** FinancialComponent propTypes
    * PropTypes
*/
FinancialComponent.propTypes = {
    /** Fires when for any form in tab, saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

export default FinancialComponent;
