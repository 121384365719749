import React from 'react';

import UnsubscribeComponent from 'pages/communications/newsletter/unsubscribe/unsubscribe.component';
import withAnanymous from 'hocs/withAnanymous';

const UnsubscribeRoute = () => {
    return (
        <UnsubscribeComponent /> 
    )
}

export default withAnanymous(UnsubscribeRoute);