import {
    SET_MONITORING_ACTION_BEFORE,
    SET_MONITORING_ACTION_FINISH,
    SET_MONITORING_SERVICE_ACTION_BEFORE,
    SET_MONITORING_SERVICE_ACTION_FINISH,
    SET_MONITORING_SERVICES,
    SET_MONITORING_SERVICE
} from '../../actionTypes';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_MONITORING_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_MONITORING_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_MONITORING_SERVICE_ACTION_BEFORE:
            return {
                ...state,
                isServicesLoading: true
            }
        case SET_MONITORING_SERVICE_ACTION_FINISH:
            return {
                ...state,
                isServicesLoading: false
            }
        case SET_MONITORING_SERVICES:
            return {
                ...state,
                services: payload.services
            }
        case SET_MONITORING_SERVICE:
            return {
                ...state,
                service: payload.service
            }
        default:
            return state;
    }
}