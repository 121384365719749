import { useContext, useEffect } from "react";

import ConfirmationContext from "components/common/confirmationProvider/confirmationContext";

const useConfirmation = ({
    // all props can be overwritten at the time of calling the showConfirmationPopup function
    title,
    message,
    onConfirm,
    onReject,
    okText,
    cancelText,
} = {}) => {
    const {
        showConfirmationPopup,
        resetConfirmationPopupData,
    } = useContext(ConfirmationContext);

    const showConfirmationPopupWrapped = (data = {}) => {
        const {
            title: currentTitle = title,
            message: currentMessage = message,
            onConfirm: currentOnConfirm = onConfirm,
            onReject: currentOnReject = onReject,
            okText: currentOnText = okText,
            cancelText: currentOnCancel = cancelText,
        } = data;

        showConfirmationPopup({
            title: currentTitle,
            message: currentMessage,
            onConfirm: currentOnConfirm,
            onReject: currentOnReject,
            okText: currentOnText,
            cancelText: currentOnCancel,
        })
    }

    // Reset Confirmation Popup data after component unmount
    useEffect(() => {
        return () => {
            resetConfirmationPopupData();
        }
    }, []);

    return {
        showConfirmationPopup: showConfirmationPopupWrapped
    };
}

export default useConfirmation;