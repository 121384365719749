import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Select from 'components/common/select';
import DateRangePicker from "components/common/dateRangePicker";

import { getAccessManagers, setAccessManagersFilters } from "store/actions/portal/userManagement/accessManagers/accessManagers.action";

import { FILTER_USER_STATE } from "constants/filter.constants";

/** Access Managers Page Filters Component */

const Filters = ({
    setAccessManagersFilters,
    getAccessManagers,
    filters,
    globalCompanyId
}) => {
    const { t } = useTranslation();

    return (
        <FiltersWrapper
            loadFn={getAccessManagers}
            setFiltersFn={setAccessManagersFilters}
            filters={filters}
            updateProps={[globalCompanyId]}
            datePickerFields={[
                { name: "date", time: false },
                { name: "modified", time: false },
            ]}
            searchFieldName="nameOrId"
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.status')}
                        name="state"
                    >
                        <Select
                            options={[
                                { value: FILTER_USER_STATE.ALL, text: t('backoffice.common.all') },
                                { value: FILTER_USER_STATE.IN_PROGRESS, text: t('backoffice.common.inactive') },
                                { value: FILTER_USER_STATE.ACTIVE, text: t('backoffice.common.active') },
                                { value: FILTER_USER_STATE.BLOCKED, text: t('backoffice.common.blocked') },
                                { value: FILTER_USER_STATE.EXPIRED, text: t('backoffice.common.expired') }
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>


                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.creationDate')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.lastUpdateDate')}
                        name="modified"
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get users */
    getAccessManagers: PropTypes.func,
    /** Redux action to set acccess managers filters */
    setAccessManagersFilters: PropTypes.func,
    /** Redux state property, users filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalCompanyId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getAccessManagers: () => {
            dispatch(getAccessManagers())
        },
        setAccessManagersFilters: filters => {
            dispatch(setAccessManagersFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.accessManagers.filters,
        globalCompanyId: state.common.globalCompanyId
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);