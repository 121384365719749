import {
    SET_ACCOUNT_PROJECT_ACCESS,
    SET_ACCOUNTS_ACTION_BEFORE,
    SET_ACCOUNTS_ACTION_FINISH,
    SET_ACCOUNTS_SAVE_ACTION_BEFORE,
    SET_ACCOUNTS_SAVE_ACTION_FINISH
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_ACCOUNTS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_ACCOUNTS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_ACCOUNTS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true
            }
        case SET_ACCOUNTS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false
            }
        case SET_ACCOUNT_PROJECT_ACCESS:
            return {
                ...state,
                projectAccess: {
                    ...payload.projectAccess
                }
            }
        default:
            return state;
    }
};
