import React from "react";

import AggregatedDataComponent from "pages/reports/aggregatedData/aggregatedData.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const AggregatedDataRoute = () => {
    return <AggregatedDataComponent />;
};

export default withPermission(
    withAuth(AggregatedDataRoute),
    { resource: PERMISSION_RESOURCE.REPORT_AGGREGATION_HISTORY, action: PERMISSION_ACTION.VIEW },
    Paths.PORTAL
);
