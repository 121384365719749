import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setProjectsActionBefore, setProjectsActionFinished, setProjectsSaveActionBefore, setProjectsSaveActionFinished } from './projects.action';

import { 
    SET_PROJECT_CURRENCIES
} from "../../../actionTypes";

const setProjectCurrencies = currencies => ({
    type: SET_PROJECT_CURRENCIES,
    payload: { currencies }
})

export const getProjectCurrencies = () => {
    return dispatch => {
        dispatch(setProjectsActionBefore());
        return axios({
            url: ApiUrls.GET_PROJECT_CURRENCIES,
            method: Methods.GET
        })
        .then(({data : {value: currencies}}) => {
            dispatch(setProjectCurrencies(currencies))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const addProjectCurrency = (currency, id, onSuccess) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.ADD_PROJECT_CURRENCY,
            method: Methods.POST,
            data: {
                ...currency
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getProjectCurrencies());

                onSuccess && onSuccess();
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

export const saveProjectCurrency = (currency, onSuccess) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_CURRENCY,
            method: Methods.POST,
            data: {
                ...currency
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getProjectCurrencies());
                onSuccess && onSuccess();
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

export const deleteProjectCurrency = code => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_PROJECT_CURRENCY,
            method: Methods.DELETE,
            data: {
                code: code
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getProjectCurrencies());
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

