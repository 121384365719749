import React from 'react';
import { makeImagePath } from "utils/common";

export const tableColumns = [
    {
        title: "backoffice.payments.logo",
        dataIndex: "logoPath",
        alwaysVisible: true,
        tooltip: false,
        disableExport: true,
        render: (logoPath) => (<img src={makeImagePath(logoPath)} style={{"maxWidth": 100, "height": 34}} alt="Payment Logo" />)
    },
    {
        title: "backoffice.payments.name",
        dataIndex: "name",
        alwaysVisible: true,
        sorter: true,
        mobileLevel: 1
    },
    {
        title: "backoffice.payments.projects",
        dataIndex: "projects",
        multi: true,
        mobileLevel: 2,
    },
    {
        title: "backoffice.payments.labels",
        dataIndex: "labels",
        multi: true,
        mobileLevel: 3,
    },
    {
        title: "backoffice.payments.createdAt",
        dataIndex: "createdAt",
        isDateTime: true,
        sorter: true,
        mobileLevel: 4,
    },
    {
        title:"backoffice.payments.createdBy",
        dataIndex: "createdBy",
        mobileLevel: 5,
    }
];