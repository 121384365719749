import React from "react";

import SystemNotifications from "pages/communications/systemNotifications/systemNotifications.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const SystemNotificationsRoute = () => {
    return <SystemNotifications />;
};

export default withPermission(
    withAuth(SystemNotificationsRoute),
    { resource: PERMISSION_RESOURCE.COMMUNICATION, action: PERMISSION_ACTION.VIEW },
    Paths.PORTAL
);
