import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Select from 'components/common/select';
import DateRangePicker from "components/common/dateRangePicker";
import AutoComplete from "components/common/autoComplete";

import { getBalanceHistory, setBalanceHistoryFilters } from "store/actions/portal/payment/balanceHistory/balanceHistory.action";

import { makeTransactionText } from "utils/wallet"

import useAutosuggestion from 'hooks/useAutosuggestion';
import useCurrencies from 'hooks/useCurrencies';

import { WALLET_OPERATION_TYPE, WALLET_TYPE } from "constants/wallet.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

/** Balance History Page Filters Component */

const Filters = ({
    setBalanceHistoryFilters,
    getBalanceHistory,
    filters,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const [ affiliateNames, getAffiliateNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AFFILIATES, 
        autoGet: false
    });

    const [ currencies, getCurrencies ] = useCurrencies(false);

    const availableOperationTypes = [
        WALLET_OPERATION_TYPE.COMMISSION_CALCULATION,
        WALLET_OPERATION_TYPE.COMMISSION_RECALCULATION,
        WALLET_OPERATION_TYPE.COMMISSION_APPROVE,
        WALLET_OPERATION_TYPE.INVOICE_CREATE,
        WALLET_OPERATION_TYPE.INVOICE_APPROVE,
        WALLET_OPERATION_TYPE.ROLLBACK,
        WALLET_OPERATION_TYPE.DEPOSIT,
        WALLET_OPERATION_TYPE.WITHDRAW,
        WALLET_OPERATION_TYPE.REFERRAL_CALCULATION,
        WALLET_OPERATION_TYPE.REFERRAL_CALCULATION_APPROVE
    ]

    return (
        <FiltersWrapper
            loadFn={getBalanceHistory}
            setFiltersFn={setBalanceHistoryFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: true }
            ]}
            searchFieldName={["transactionId", "date"]}
            onInit={() => {
                getAffiliateNames();
                getCurrencies();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.wallet.usernameOrId')}
                        name="userNameOrId"
                    >
                        <AutoComplete
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.usernameOrId')}`}
                            items={affiliateNames}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.wallet.transactionType')}
                        name="transactionType"
                    >
                        <Select
                            options={[
                                { value: "", text: t("backoffice.common.all") },
                                ...availableOperationTypes.map(type => (
                                    { value: type, text: makeTransactionText(type) }
                                ))
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.wallet.transactionType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.wallet.currency')}
                        name="currencyCode"
                    >
                        <Select
                            options={[
                                { value: "", text: t("backoffice.common.all") },
                                ...currencies.map(c => (
                                    { value: c.code.toUpperCase(), text: c.code.toUpperCase() }
                                ))
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.wallet.currency')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.wallet.balanceType')}
                        name="walletType"
                        className='rt--form-item-without-margin'
                    >
                        <Select
                            options={[
                                { value: "", text: t("backoffice.common.all") },
                                { value: WALLET_TYPE.EARNING_NOT_APPROVED_BALANCE, text: t("backoffice.wallet.earningNotApprovedBalance")},
                                { value: WALLET_TYPE.EARNING_BALANCE, text: t("backoffice.wallet.earningBalance")},
                                { value: WALLET_TYPE.CPA_NOT_APPROVED_BALANCE, text: t("backoffice.wallet.cpaNotApprovedBalance")},
                                { value: WALLET_TYPE.CPA_BALANCE, text: t("backoffice.wallet.cpaBalance")},
                                { value: WALLET_TYPE.FROZEN_BALANCE, text: t("backoffice.wallet.frozenBalance")},
                                { value: WALLET_TYPE.PAIDOUT_BALANCE, text: t("backoffice.wallet.paidoutBalance")},
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.wallet.balanceType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get balance history */
    getBalanceHistory: PropTypes.func,
    /** Redux action to set balance history filters */
    setBalanceHistoryFilters: PropTypes.func,
    /** Redux state property, balance history filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
}

const mapDispatchToProps = dispatch => (
    {
        getBalanceHistory: () => {
            dispatch(getBalanceHistory())
        },
        setBalanceHistoryFilters: filters => {
            dispatch(setBalanceHistoryFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.balanceHistory.filters,
        globalProjectId: state.common.globalProjectId,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);