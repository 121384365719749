import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setCompaniesActionBefore, setCompaniesActionFinished, setCompaniesSaveActionBefore, setCompaniesSaveActionFinished } from './companies.action';

import { SET_COMPANY_PASSWORD_SETTINGS } from "../../../actionTypes";

const setCompanyPasswordSettings = passwordSettings => ({
    type: SET_COMPANY_PASSWORD_SETTINGS,
    payload: { passwordSettings }
})

export const getCompanyPasswordSettings = companyId => {
    return dispatch => {
        dispatch(setCompaniesActionBefore());

        return axios({
            url: ApiUrls.COMPANY_GET_PASSWORD_SETTINGS,
            method: Methods.GET,
            params: {
                companyId
            }
        })
        .then(({data : {value: passwordSettings}}) => {
            dispatch(setCompanyPasswordSettings(passwordSettings))
            dispatch(setCompaniesActionFinished());
        })
        .catch((ex) => {
            dispatch(setCompaniesActionFinished());
        })
    }
}

export const saveCompanyPasswordSettings = data => {
    return dispatch => {
        dispatch(setCompaniesSaveActionBefore());
        return axios({
            url: ApiUrls.COMPANY_SAVE_PASSWORD_SETTINGS,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setCompanyPasswordSettings(data.value));
            } 
            dispatch(setCompaniesSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCompaniesSaveActionFinished());
        })
    }
}
