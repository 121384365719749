import {
    SET_BALANCE_HISTORY_ACTION_BEFORE,
    SET_BALANCE_HISTORY_ACTION_FINISH,
    SET_BALANCE_HISTORY,
    SET_BALANCE_HISTORY_FILTERS,
    SET_BALANCE_HISTORY_SORTING
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_BALANCE_HISTORY_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_BALANCE_HISTORY_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_BALANCE_HISTORY:
            return {
                ...state,
                history: !payload.add ? payload.history.item2 : state.history.concat(payload.history.item2),
                total: payload.history.item1
            };
        case SET_BALANCE_HISTORY_SORTING:
            return {
                ...state,
                sorting: payload.sorting
            };
        case SET_BALANCE_HISTORY_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        default:
            return state;
    }
}