import React from 'react';

const TwoFactorDigitalCodeView = () => {
	return (
		<div className='rt--mt-16 rt--mb-16'>
			<img src={`${import.meta.env.SYSTEM_CDN_URL}/system/static/images/digital-code.svg`} alt="Digital Code"/>
		</div>
	);
}
 
export default TwoFactorDigitalCodeView;