import React from "react";

import i18n from "translations/config";

import { makeUrl } from "utils/common";

import { PROJECT_BRAND_PRODUCT_TYPE } from "constants/project.constants";

export const getTableColumns = additionalProps => {
    const {
        mainDomain
    } = additionalProps; 

    return [
        {
            title: "backoffice.projects.name",
            dataIndex: "name",
            alwaysVisible: true,
            mobileLevel: 1
        },
        {
            title: "backoffice.projects.url",
            dataIndex: "url",
            mobileLevel: 2,
            render: (_, record) => {
                const url = makeUrl(mainDomain, record.subDomain, record.relative);
                return (
                    <span 
                        className="rt--title rt--font-normal rt--font-regular rt--table-col-link"
                        onClick={() => window.open( `https://${url}`,"_blank")}
                    >
                        <b>{ url }</b>
                    </span>
                )
            }
        },
    
        {
            title: "backoffice.projects.language",
            dataIndex: "languageCode",
            mobileLevel: 3,
            render: value => value ? i18n.t(`backoffice.languages.${value.toLowerCase()}`) : ""
        },
    
        {
            title: "backoffice.projects.product",
            dataIndex: "product",
            render: value => value === PROJECT_BRAND_PRODUCT_TYPE.CASINO ? 
                            i18n.t(`backoffice.projects.casino`) : value === PROJECT_BRAND_PRODUCT_TYPE.SPORT ?
                            i18n.t(`backoffice.projects.sport`) : value === PROJECT_BRAND_PRODUCT_TYPE.OTHER ? 
                            i18n.t(`backoffice.projects.other`) : "",
            mobileLevel: 4
        },
        {
            title: "backoffice.projects.createdAt",
            dataIndex: "createdAt",
            isDateTime: true,
            mobileLevel: 5
        },
        {
            title: "backoffice.projects.createdBy",
            dataIndex: "createdBy",
            mobileLevel: 6
        },
    ]
}
