import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import DateRangePicker from "components/common/dateRangePicker";

import { getCampaigns, setCampaignsFilters } from "store/actions/portal/marketingTools/campaigns/campaigns.action";

import { makeUrl } from 'utils/common';

import { PROJECT_BRAND_PRODUCT_TYPE } from 'constants/project.constants';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';
import useLanguages from 'hooks/useLanguages';

import countries from 'systemData/countries';

/** Campaigns Page Filters Component */

const Filters = ({
    setCampaignsFilters,
    getCampaigns,
    filters,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const [ languages, getLanguages ] = useLanguages(false);

    const [selectedBrandIds, setSelectedBrandIds] = useState([]);

    const [ brands, getBrands ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BRAND,
        autoGet: false
    });

    const selectedBrands = brands.filter(brand => selectedBrandIds.includes(brand.id));

    const urls = selectedBrands.reduce(
        (res, brand) => res.concat(
            (brand.urls ?? []).map(url => ({
                id: url.id,
                name: makeUrl(brand?.domain, url.subDomain, url.relative)
            }))
        ),
        [],
    );

    const onFilterChange = (changed, setFieldsValue) => {
        if (changed.brandIds) {
            setFieldsValue({
                "brandUrlIds": []
            })
        }
    }

    return (
        <FiltersWrapper
            loadFn={getCampaigns}
            setFiltersFn={setCampaignsFilters}
            filters={filters}
            datePickerFields={[
                { name: "date", time: false },
                { name: "modified", time: false }
            ]}
            searchFieldName={["nameOrId", "date"]}
            updateProps={[globalProjectId]}
            onFilterChange={onFilterChange}
            onInit={() => {
                getBrands();
                getLanguages();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.campaigns.availablility')}
                        name="isPrivate"
                    >
                        <Select
                            options={[
                                { value: "", text: t('backoffice.common.all') },
                                { value: true, text: t('backoffice.campaigns.private') },
                                { value: false, text: t('backoffice.campaigns.public') }
                            ]}
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.campaigns.availability')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.projects.product')}
                        name="productType"
                    >
                        <Select
                            options={[
                                { value: "", text: t('backoffice.common.all') },
                                { value: PROJECT_BRAND_PRODUCT_TYPE.CASINO, text: t('backoffice.projects.casino') },
                                { value: PROJECT_BRAND_PRODUCT_TYPE.SPORT, text: t('backoffice.projects.sport') },
                                { value: PROJECT_BRAND_PRODUCT_TYPE.OTHER, text: t('backoffice.projects.other') }
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.product')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.campaigns.language')}
                        name="languageCode"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    Object.keys(languages)
                                        .map(item => (
                                            { value: item.toUpperCase(), text: t(`backoffice.languages.${item}`) }
                                        ))
                                )
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.language')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.campaigns.country')}
                        name="countryCode"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    countries.map(c => (
                                        { value: c.iso2, text: t(`backoffice.countries.${c.iso2}`) }
                                    ))
                                )
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.campaigns.country')}`}
                            search={true}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.campaigns.brand')}
                        name="brandIds"
                    >
                        <Select
                            options={
                                brands
                                    .map(item => (
                                        { value: item.id, text: item.name }
                                    ))
                            }
                            onChange={value => setSelectedBrandIds(value)}
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.campaigns.brand')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            search={true}
                            isMultiple={true}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.campaigns.url')}
                        name="brandUrlIds"
                        className={selectedBrandIds.length === 0 ? "rt--form-item-disabled" : ""}
                    >
                        <Select
                            options={
                                urls
                                    .map(item => (
                                        { value: item.id, text: item.name }
                                    ))
                            }
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.campaigns.url')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            search={true}
                            isMultiple={true}
                            disabled={selectedBrandIds.length === 0}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.lastUpdateDate')}
                        name="modified"
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get campaigns */
    getCampaigns: PropTypes.func,
    /** Redux action to set campaigns filters */
    setCampaignsFilters: PropTypes.func,
    /** Redux state property, campaigns filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getCampaigns: () => {
            dispatch(getCampaigns())
        },
        setCampaignsFilters: filters => {
            dispatch(setCampaignsFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.campaigns.filters,
        globalProjectId: state.common.globalProjectId,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);