import {
    SET_SYSTEM_CURRENCIES_ACTION_BEFORE,
    SET_SYSTEM_CURRENCIES_ACTION_FINISH,
    SET_SYSTEM_CURRENCIES,
    SET_SYSTEM_CURRENCIES_SORTING,
    SET_SYSTEM_CURRENCIES_SAVE_ACTION_BEFORE,
    SET_SYSTEM_CURRENCIES_SAVE_ACTION_FINISH,
    SET_SYSTEM_AVAILABLE_CURRENCIES_ACTION_BEFORE,
    SET_SYSTEM_AVAILABLE_CURRENCIES_ACTION_FINISH,
    SET_SYSTEM_AVAILABLE_CURRENCIES,
    SET_AVAILABLE_CURRENCIES
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_SYSTEM_CURRENCIES_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_SYSTEM_CURRENCIES_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_SYSTEM_CURRENCIES:
            return {
                ...state,
                currencies: !payload.add ? payload.currencies.item2 : state.currencies.concat(payload.currencies.item2),
                total: payload.currencies.item1,
            };
        case SET_SYSTEM_CURRENCIES_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_SYSTEM_CURRENCIES_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_SYSTEM_CURRENCIES_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_SYSTEM_AVAILABLE_CURRENCIES_ACTION_BEFORE:
            return {
                ...state,
                isAvailableLoading: true,
            };
        case SET_SYSTEM_AVAILABLE_CURRENCIES_ACTION_FINISH:
            return {
                ...state,
                isAvailableLoading: false,
            };
        case SET_SYSTEM_AVAILABLE_CURRENCIES:
            return {
                ...state,
                systemAvailableCurrencies: payload.currencies,
            };
        case SET_AVAILABLE_CURRENCIES:
            return {
                ...state,
                availableCurrencies: payload.currencies,
            };
        default:
            return state;
    }
};
