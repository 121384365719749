import React from 'react';

import { useParams } from "react-router-dom";

import Sessions from '../../../../../sessions/sessions.component';

import {USER_TYPE } from "constants/user.constants";
import { PERMISSION_RESOURCE } from "constants/permissions.constants";

/** User Edit Page Sessions Tab Component */
const SessionsComponent = () => {
    const searchParams = useParams();

    return (
        <Sessions 
            userRole={USER_TYPE.ADMIN}
            userId={searchParams.id}
            permissionResource={PERMISSION_RESOURCE.ADMIN_SESSIONS}
        />
    )
}

export default SessionsComponent;
