import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Menu } from 'antd';

import InternalInput from './internalInput';
import Dropdown from '../dropdown';
import Icon from '../icon';

import { classNames, isFunction } from 'utils/common';
import {
    componentShouldBeControlled,
    splitDropdownAndInputValues,
} from './helpers';

const InputDropdown = ({
    items,
    onDropdownChange,
    dropdownReadOnly = false,
    ...restProps
}) => {
    const [internalValue, setInternalValue] = useState("");
    const [selectedItem, setSelectedItem] = useState(items[0].value);

    const mergedValue = componentShouldBeControlled(restProps) ? restProps.value : internalValue;
    const mergedChange = componentShouldBeControlled(restProps) ? restProps.onChange : setInternalValue;

    const [inputValue] = splitDropdownAndInputValues(mergedValue, selectedItem);

    const hasMoreThaneOneItem = items.length > 1;

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleDropdownChange = ({ key: selectedItemValue }) => {
        const selectedItemData = items.find(itemData => String(itemData.value) === selectedItemValue);

        setSelectedItem(selectedItemData.value);

        if (isFunction(onDropdownChange)) {
            onDropdownChange(selectedItemData.value);
        }

        // In case of dropdown change input value also should be updated
        const newInputValue = (
            Boolean(inputValue)
                ? `${selectedItemData.value}${inputValue}`
                : ""
        );

        mergedChange(newInputValue);
    }

    const handleInternalChange = (e) => {
        const newValue = e.target.value;

        if (dropdownReadOnly) {
            return mergedChange(newValue);
        }

        const wholeValue = (
            Boolean(newValue)
                ? `${selectedItem}${newValue}`
                : ""
        );

        mergedChange(wholeValue);
    }

    //#endregion

    return (
        <div className="rt--input-dropdown">
            <Dropdown
                disabled={!hasMoreThaneOneItem || dropdownReadOnly}
                overlay={
                    <Menu className="rt--input-dropdown-menu" selectedKeys={[selectedItem]} onClick={handleDropdownChange}>
                        {items.map(itemData => (
                            <Menu.Item key={itemData.value} className="rt--input-dropdown-menu-item">
                                {itemData.label}
                            </Menu.Item>
                        ))}
                    </Menu>
                }
            >
                <div
                    className={classNames(
                        "rt--input-dropdown-selector",
                        hasMoreThaneOneItem && "rt--cursor-pointer rt--pl-5"
                    )}
                >
                    <span className="rt--input-dropdown-selector-content">
                        {selectedItem}
                    </span>
                    {hasMoreThaneOneItem && <Icon name="down" size={18} />}
                </div>
            </Dropdown>

            <div className='rt--input-dropdown-separator'>
                <div className='rt--input-dropdown-separator-line' />
            </div>


            <InternalInput
                {...restProps}
                className="rt--input-dropdown-input"
                onChange={handleInternalChange}
                value={inputValue}
            />
        </div>
    );
}

/** InputDropdown propTypes
    * PropTypes
*/
InputDropdown.propTypes = {
    onDropdownChange: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.node,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
    })),
    dropdownReadOnly: PropTypes.bool
}

export default InputDropdown;