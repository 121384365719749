import PropTypes from 'prop-types';

import { CREATIVE_TYPE } from 'constants/campaign.constants';
import { PROJECT_BRAND_PRODUCT_TYPE } from 'constants/project.constants';

export default PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.oneOf(Object.values(CREATIVE_TYPE)),
    dimension: PropTypes.string,
    createdAt: PropTypes.string,
    createdBy: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
    filePath: PropTypes.string,
    product: PropTypes.oneOf(Object.values(PROJECT_BRAND_PRODUCT_TYPE)),
    languageCode: PropTypes.string,
    url: PropTypes.string,
    brandName: PropTypes.string,
    usedCount: PropTypes.number,
    size: PropTypes.number,
    imageType: PropTypes.number
})