import PropTypes from 'prop-types';


export default PropTypes.shape({
    brandName: PropTypes.string,
    playerExternalId: PropTypes.string,
    playerId: PropTypes.number,
    playerUserName: PropTypes.string,
    currencyCode: PropTypes.string,
    depositAmount: PropTypes.number,
    depositCount: PropTypes.number,
    withdrawAmount: PropTypes.number,
    withdrawCount: PropTypes.number,
    casinoTurnover: PropTypes.number,
    casinoGGR: PropTypes.number,
    casinoNGR: PropTypes.number,
    casinoCount: PropTypes.number,
    casinoBonusTurnover: PropTypes.number,
    sportTurnover: PropTypes.number,
    sportGGR: PropTypes.number,
    sportNGR: PropTypes.number,
    sportCount: PropTypes.number,
    sportBonusTurnover: PropTypes.number,
    adjustments: PropTypes.number,
    generationId: PropTypes.number,
    generationFrom: PropTypes.string,
    generationTo: PropTypes.string
})