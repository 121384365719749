import PropTypes from 'prop-types';

export default PropTypes.shape({
    name: PropTypes.string,
    publishedAt: PropTypes.string,
    publishedBy: PropTypes.string,
    modifiedBy: PropTypes.string,
    enabled: PropTypes.bool,
    exists: PropTypes.bool,
    modifiedAt: PropTypes.string,
    portalDomain: PropTypes.string, 
    promoDomain: PropTypes.string,
})