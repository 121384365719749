import PropTypes from 'prop-types';

import { PROJECT_BRAND_PRODUCT_TYPE } from 'constants/project.constants';

export default PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    brandName: PropTypes.string,
    languageCode: PropTypes.string,
    product: PropTypes.oneOf(Object.values(PROJECT_BRAND_PRODUCT_TYPE)),
    isPrivate: PropTypes.bool,
    description: PropTypes.string,
    affiliates: PropTypes.object
})