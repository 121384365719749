import React from "react";

import Icon from "components/common/icon";
import { useTranslation } from "react-i18next";

const PlayerCount = ({ count }) => {

const { t } = useTranslation();

  return (

    <div className="rt--chart-operational-pie-block-total rt--flex rt--align-center">
      <div className="rt--flex rt--flex-col">
        <span className="rt--chart-operational-pie-block-total-title rt--font-bold rt--font-normal">
          {t("backoffice.dashboard.totalPlayerCount")}
        </span>
        <span className="rt--chart-operational-pie-block-total-count rt--mt-4">
            {count}
        </span>
      </div>
      <div className="rt--chart-operational-pie-block-total-count-icon rt--flex rt--align-center rt--justify-center">
        <Icon name="playerCount"/>
      </div>
    </div>

  );
};

export default PlayerCount;
