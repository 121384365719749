import React from 'react';
import { makeImagePath } from 'utils/common';

export const tableColumns = [
    {
        title: "backoffice.promo.name",
        dataIndex: "name",
    },
    {
        title: "backoffice.promo.image",
        dataIndex: "imageUrl",
        render: (value) => {
            return (
                <img
                    src={makeImagePath(value)}
                    alt=" Story Block Cover Image"
                    className="rt--promo-customize-img-rounded"
                />
            )
        }
    },
]