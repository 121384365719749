import React from 'react';

export const tableColumns = [
    {
        title: "backoffice.permissiongroups.name",
        dataIndex: "name",
        showOnlyValue: true,
        alwaysVisible: true,
        mobileLevel: 1,
        mobileRender: (value) => (
            <span className="rt--font-normal rt--font-extra-bold">
                {value}
            </span>
        ),
    },
    {
        title: "backoffice.permissiongroups.description",
        dataIndex: "description",
        showOnlyValue: true,
        mobileLevel: 2,
    }
];