import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { Button, Spin, Row, Col, Checkbox } from 'antd';


import RegisterForm from './registerForm';

import {
    getRegistrationForm,
    getAvailableCurrencies,
    getAvailablePayments,
    getAvailableChannels,
    getPasswordSettings,
    getPaymentDetails,
    register
} from "store/actions/auth/register.action";

import Paths from "constants/path.constants";

import registrationFormType from 'types/project/registrationForm.type';
import systemPaymentType from 'types/systemPayments/systemPayment.type';
import passwordSettingsType from 'types/company/passwordSettings.type';
import paymentDetailsType from 'types/systemPayments/paymentDetails.type';


/** Register Page Component */

const RegisterComponent = ({
    isLoading,
    isSaving,
    register,
}) => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const _scrollAreaRef = useRef(null);
    const registerFormInstanceRef = useRef(null);

    const [acceptTerms, setAcceptTerms] = useState(false);

    const handleFormSave = () => {
        registerFormInstanceRef.current.handleForm({ acceptTerms });
    };

    return (
        <Spin spinning={isLoading}>
            <div className="rt--auth-form rt--auth-form-register rt--portal-layout">
                <Row
                    gutter={[16, 0]}
                    className='rt--auth-form-register-wrapper'
                    ref={_scrollAreaRef}
                >
                    <Col span={24}>
                        <span className="rt--title rt--font-big rt--font-regular">{
                            t('backoffice.authentication.alreadyHaveAccount')}
                            <Link to={Paths.LOGIN} >
                                <span className="rt--title rt--font-big rt--font-regular rt--pl-4 rt--pr-4">
                                    {t('backoffice.authentication.signIn')}
                                </span>
                            </Link>
                        </span>
                        <b className="rt--title rt--font-bigest rt--font-bold rt--mb-32 rt--mt-12">{t('backoffice.authentication.becomeAffiliate')}</b>

                        <RegisterForm 
                            id={searchParams.get("id")}
                            formInstaceRef={registerFormInstanceRef}
                            enableRecaptcha={true}
                            getFieldLabel={(control) => t('backoffice.registrationform.' + control.name)}
                            getScrollArea={() => _scrollAreaRef.current}
                            getFieldPopupContainer={(id) => document.getElementById(id)}
                            onRegisterSuccess={() => navigate(Paths.LOGIN)}
                            registerAction={register}
                        />

                        <Row gutter={[16, 0]}>
                            <Col span={24}>
                                <div className="rt--flex-inline rt--align-center rt--form-item-checkbox rt--mb-14">
                                    <Checkbox
                                        className='rt--form-item-inline rt--form-item-without-margin rt--form-item-checkbox-with-label'
                                        onChange={e => setAcceptTerms(e.target.checked)}
                                    />
                                    <span className="rt--text-secondary rt--font-normal rt--font-regular">
                                        {t('backoffice.authentication.readAndAccept')} {t('backoffice.authentication.termsAndPrivacy')}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div className='rt--mt-16 rt--auth-buttons'>
                    <Button
                        loading={isSaving}
                        type="primary"
                        htmlType="submit"
                        className="rt--button rt--button-primary"
                        onClick={handleFormSave}
                        disabled={!acceptTerms}
                    >
                        <span className='rt--font-normal'>{t('backoffice.authentication.register')}</span>
                    </Button>
                </div>
            </div>
        </Spin>
    )
}

/** RegisterComponent propTypes
    * PropTypes
*/
RegisterComponent.propTypes = {
    /** React Property, given ref from Parent Component */
    registerFormRef: PropTypes.object,
    /** Redux state property, is true when loading registration form */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when registering */
    isSaving: PropTypes.bool,
    /** Redux state property, registration form */
    registrationForm: PropTypes.arrayOf(registrationFormType),
    /** Redux action to get registration form */
    getRegistrationForm: PropTypes.func,
    /** Redux action to get available currencies */
    getAvailableCurrencies: PropTypes.func,
    /** Redux action to get available payments */
    getAvailablePayments: PropTypes.func,
    /** Redux action to get available channels */
    getAvailableChannels: PropTypes.func,
    /** Redux action to get available channels */
    getPasswordSettings: PropTypes.func,
    /** Redux action to get payment details */
    getPaymentDetails: PropTypes.func,
    /** Redux state property, available currencies */
    availableCurrencies: PropTypes.object,
    /** Redux state property, available payments */
    availablePayments: PropTypes.arrayOf(systemPaymentType),
    /** Redux state property, available channels */
    availableChannels: PropTypes.object,
    /** Redux state property, password settings */
    passwordSettings: passwordSettingsType,
    /** Redux state property, payment details */
    paymentDetails: paymentDetailsType,
    /** Redux action to register */
    register: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        getRegistrationForm: id => {
            dispatch(getRegistrationForm(id));
        },

        getAvailableCurrencies: id => {
            dispatch(getAvailableCurrencies(id));
        },

        getAvailablePayments: (id, currencyCode) => {
            dispatch(getAvailablePayments(id, currencyCode));
        },

        getAvailableChannels: id => {
            dispatch(getAvailableChannels(id));
        },

        getPasswordSettings: id => {
            dispatch(getPasswordSettings(id));
        },

        getPaymentDetails: (id, paymentId) => {
            dispatch(getPaymentDetails(id, paymentId));
        },

        register: (data, token, cb) => {
            dispatch(register(data, token, cb));
        }
    }
)

const mapStateToProps = state => {
    return {
        registrationForm: state.register.registrationForm,
        isLoading: state.register.isLoading,
        isSaving: state.register.isSaving,
        availableCurrencies: state.register.availableCurrencies,
        availablePayments: state.register.availablePayments,
        availableChannels: state.register.availableChannels,
        passwordSettings: state.register.passwordSettings,
        paymentDetails: state.register.paymentDetails,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    RegisterComponent
);