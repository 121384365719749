import { TRANSLATION_SOURCE_TYPE } from "constants/translation.constants";

import {
    SET_SYSTEM_PAYMENTS_ACTION_BEFORE,
    SET_SYSTEM_PAYMENTS_ACTION_FINISH,
    SET_SYSTEM_PAYMENTS,
    SET_SYSTEM_PAYMENTS_FILTERS,
    SET_SYSTEM_PAYMENTS_SORTING,
    SET_SYSTEM_PAYMENTS_GENERAL_INFO,
    SET_SYSTEM_PAYMENT_SAVE_ACTION_BEFORE,
    SET_SYSTEM_PAYMENT_SAVE_ACTION_FINISH,
    SET_SYSTEM_PAYMENT_FORM_TRANSLATION,
    SET_SYSTEM_PAYMENT_FORM_TRANSLATIONS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_SYSTEM_PAYMENTS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_SYSTEM_PAYMENTS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_SYSTEM_PAYMENTS:
            return {
                ...state,
                payments: !payload.add ? payload.payments.item2 : state.payments.concat(payload.payments.item2),
                total: payload.payments.item1,
            };
        case SET_SYSTEM_PAYMENTS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };    
        case SET_SYSTEM_PAYMENTS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_SYSTEM_PAYMENTS_GENERAL_INFO:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...payload.info
                    }
                }
            }    
        case SET_SYSTEM_PAYMENT_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_SYSTEM_PAYMENT_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_SYSTEM_PAYMENT_FORM_TRANSLATION:
            return {
                ...state,
                translations: state.translations.map(tr => {
                    let t = {...tr};
                    if(tr.key === payload.key){
                        t.translations = t.translations.map(item => 
                            {
                                if(item.languageCode === payload.translation.languageCode){
                                    return payload.translation
                                } else if (payload.translation.languageCode === "EN" && item.source === TRANSLATION_SOURCE_TYPE.SYSTEM_DEFAULT){
                                    return { ...item, text: payload.translation.text}
                                } else {
                                    return item;
                                }
                            }
                        )
                    }
                    return t;
                })
            }
        case SET_SYSTEM_PAYMENT_FORM_TRANSLATIONS:
            return {
                ...state,
                translations: payload.translations
            }
        default:
            return state;
    }
};
