import { message } from 'antd'
import axios from 'axios';

import Methods from 'constants/methods.constants';
import ApiUrls from 'constants/api.constants';
import { isResponseSuccess } from "utils/request";

import {
    SET_RESET_PASSWORD_ACTION_BEFORE,
    SET_RESET_PASSWORD_ACTION_FINISH
} from '../../actionTypes';


const setResetPasswordActionBefore = () => ({
    type: SET_RESET_PASSWORD_ACTION_BEFORE
})

const setResetPasswordActionFinished = () => ({
    type: SET_RESET_PASSWORD_ACTION_FINISH
})

export const resetPassword = (token, newPassword, confirmPassword, onSuccess) => {
    return dispatch => {
        dispatch(setResetPasswordActionBefore());

        return axios({
            url: ApiUrls.RESET_PASSWORD,
            method: Methods.POST,
            data: { token, newPassword, confirmPassword }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                onSuccess && onSuccess();
            } 
            dispatch(setResetPasswordActionFinished());
        })
        .catch((ex) => {
            dispatch(setResetPasswordActionFinished());
        })
    }
}

export const setPassword = (token, newPassword, confirmPassword, onSuccess) => {
    return dispatch => {
        dispatch(setResetPasswordActionBefore());

        return axios({
            url: ApiUrls.SET_PASSWORD,
            method: Methods.POST,
            data: { newPassword, confirmPassword },
            headers: {
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                const user = { ... ( data?.value ?? {})};
                onSuccess && onSuccess(user);
            } 
            dispatch(setResetPasswordActionFinished());
        })
        .catch((ex) => {
            dispatch(setResetPasswordActionFinished());
        })
    }
}

