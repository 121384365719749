//#region react
import React, { Fragment, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
//#endregion

//#region libraries
import { Checkbox, Drawer, Input } from 'antd';
//#endregion

//#region components
import Selector from './selector';
import NotFound from '../notFound';
import Icon from "components/common/icon";
//#endregion

import useDebounce from 'hooks/useDebounce';

//#region utils
import {
    getMergedSelectedValues,
    filterAndSortBySearchValue,
    getSelectedValues
} from '../helpers';
import { classNames } from 'utils/common';
//#endregion

//#region constants
import { ALL_VALUE } from '../constants';
//#endregion

const SelectMobile = ({
    options: outsideOptions,
    value,
    onChange,
    disabled,
    placeholder,
    className = "",
    withLabel,
    search = false,
    add = false,
    isMultiple,
    maxMultipleSelect,
    showSelectAllButton = true,
    isDark,
}) => {
    const { t } = useTranslation();

    const [options, setOptions] = useState(outsideOptions);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const debouncedSearch = useDebounce(searchValue);

    const filteredOptions = useMemo(() => {
        return filterAndSortBySearchValue({ options, searchValue: debouncedSearch, add });
    }, [options, debouncedSearch, add]);

    const isAllOptionVisible = (
        isMultiple &&
        showSelectAllButton &&
        filteredOptions.length > 1 &&
        debouncedSearch === ""
    );

    const mergedSelectedValues = getMergedSelectedValues({
        isMultiple,
        options,
        selectedValues: value,
        isAllOptionVisible
    });

    /** Function returns true if option selected
       * @function
       * @param {object} option
       * @returns {bool}
       * @memberOf Select
   */
    const isOptionSelected = (optionValue, mergedSelectedValues) => {
        if (isMultiple) {
            return mergedSelectedValues.includes(optionValue)
        }

        return value === optionValue;
    }

    const handleInternalChange = (selectedValue) => {
        if(maxMultipleSelect){
            if(
                mergedSelectedValues.length >= maxMultipleSelect &&
                !mergedSelectedValues.includes(selectedValue)
            ){
                return true
            }
        }

        let newSelected = isMultiple ? [...mergedSelectedValues] : value;

        if (isMultiple) {
            newSelected = (
                newSelected.includes(selectedValue)
                    ? newSelected.filter(value => value !== selectedValue)
                    : [...newSelected, selectedValue]
            )

            if (add && selectedValue === debouncedSearch && options.findIndex((option) => option.value === debouncedSearch) === -1) {
                setOptions((prevOptions) => [{ text: debouncedSearch, value: debouncedSearch }, ...prevOptions]);
            }

        } else {
            if (newSelected === selectedValue) {
                return;
            }

            newSelected = selectedValue;
            setIsDrawerVisible(false);
        }

        const updatedSelectedValues = getSelectedValues({
            isMultiple,
            options,
            prevSelectedValues: mergedSelectedValues,
            newSelectedValues: newSelected,
        })

        setSearchValue("");
        onChange(updatedSelectedValues);
    }

    /** Fires when the selector is clicked
       * @function
       * @param {object} e
       * @memberOf Selector
   */
    const handleSelectorClick = (e) => {
        if (disabled) {
            return;
        }

        e.stopPropagation();
        e.preventDefault();

        setIsDrawerVisible(true);
    }

    /** Fires when the tag's close icon is clicked
       * @function
       * @param {string} tagValue
       * @param {object} e
       * @memberOf Tag
   */
    const handleTagClose = (tagValue, e) => {
        e.stopPropagation();
        e.preventDefault();

        handleInternalChange(tagValue);
    }

    /** Fires when the drawer is clicked
       * @function
       * @memberOf Drawer
   */
    const handleDrawerCloseIconClick = () => {
        setIsDrawerVisible(false)
    }

    /** Change search input state
       * @function
       * @param {object} e
       * @memberOf Input
   */
    const handleSearchInputChange = (e) => {
        if (search?.pattern && e.target.value.match(search.pattern) === null) {
            return;
        }

        setSearchValue(e.target.value)
    }

    // Prevent page scroll when drawer visible
    useEffect(() => {
        if (isDrawerVisible) {
            document.documentElement.classList.add("rt--no-scroll");
        } else {
            if (document.documentElement.classList.contains("rt--no-scroll")) {
                document.documentElement.classList.remove("rt--no-scroll");
            }

            setSearchValue("");
        }

        return () => {
            document.documentElement.classList.remove("rt--no-scroll");
        }

    }, [isDrawerVisible]);

    useEffect(() => {
        setOptions(outsideOptions);
    }, [outsideOptions]);

    return (
        <Fragment>
            <Selector
                className={className}
                onClick={handleSelectorClick}
                options={options}
                disabled={disabled}
                isDark={isDark}
                isMultiple={isMultiple}
                placeholder={placeholder}
                value={value}
                withLabel={withLabel}
                onTagClose={handleTagClose}
            />

            <Drawer
                visible={isDrawerVisible}
                closable={true}
                maskClosable={true}
                onClose={handleDrawerCloseIconClick}
                getContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                closeIcon={<Icon name='close' />}
                title={placeholder || " "}
                className="rt--modal rt--modal-select"
                placement="bottom"
            >
                <div className="rt--select-dropdown rt--pt-8 rt--pb-8 rt--pl-16 rt--pr-16">
                    {search && (
                        <div className="rt--select-dropdown-search rt--mt-4 rt--mb-8">
                            <Input
                                value={searchValue}
                                onChange={handleSearchInputChange}
                                prefix={<Icon name='search' />}
                                placeholder={t("backoffice.common.search")}
                                className='rt--input'
                            />
                        </div>
                    )}

                    {options.length === 0 && add == false && (
                        <NotFound />
                    )}

                    <div className='rt--select-dropdown-menu'>
                        {isAllOptionVisible && (
                            <div
                                onClick={() => handleInternalChange(ALL_VALUE)}
                                className="rt--select-dropdown-menu-item rt--select-all rt--flex rt--align-center"
                            >
                                {isMultiple && (
                                    <Checkbox
                                        className="rt--select-checkbox"
                                        checked={isOptionSelected(ALL_VALUE, mergedSelectedValues)}
                                    />
                                )}

                                <span className="rt--title rt--font-regular rt--font-normal">
                                    {value?.length === options.length ? t("backoffice.common.deselectAll") : t("backoffice.common.selectAll")}
                                </span>
                            </div>
                        )}

                        {filteredOptions.map(option => (
                            <div
                                key={option.value || option.text}
                                onClick={() => !option.subs && !option.disabled ? handleInternalChange(option.value) : null}
                                className={classNames(
                                    "rt--select-dropdown-menu-item",
                                    option.subs && "rt--select-dropdown-menu-item-subs",
                                    !option.subs && "rt--flex rt--align-center rt--justify-between",
                                    !isMultiple && isOptionSelected(option.value, mergedSelectedValues) && (
                                        "rt--select-dropdown-menu-item-selected"
                                    ),
                                )}
                            >
                                {isMultiple && (
                                    <Checkbox
                                        className="rt--select-checkbox"
                                        checked={isOptionSelected(option.value, mergedSelectedValues)}
                                    />
                                )}

                                <div
                                    className={classNames(
                                        "rt--flex rt--flex-equal rt--align-center rt--justify-between",
                                        option.subs && "rt--select-dropdown-menu-item-subs-title"
                                    )}
                                >
                                    <span className="rt--title rt--font-regular rt--font-normal">
                                        {option.text}
                                    </span>

                                    {withLabel && (
                                        <small className="rt--title rt--font-regular rt--font-small rt--pl-16 rt--pr-16">
                                            {option.label}
                                        </small>
                                    )}

                                </div>

                                {(!isMultiple && isOptionSelected(option.value, mergedSelectedValues) && !option.subs) && (
                                    <Icon name='ok' />
                                )}

                                {option.subs && (
                                    <div className='rt--select-dropdown-menu-item-subs-list'>
                                        {option.subs.map(sub => (
                                            <div
                                                key={sub.value || sub.text}
                                                onClick={() => handleInternalChange(sub.value)}
                                                className={classNames(
                                                    "rt--select-dropdown-menu-item-subs-list-item rt--flex rt--align-center rt--justify-between",
                                                    isOptionSelected(sub.value, mergedSelectedValues) && (
                                                        "rt--select-dropdown-menu-item-subs-list-item-selected"
                                                    )
                                                )}
                                            >
                                                <span className='rt--title rt--font-regular rt--font-normal'>
                                                    {sub.text}
                                                </span>

                                                {
                                                    isOptionSelected(sub.value, mergedSelectedValues) && (
                                                        <Icon name='ok' />
                                                    )
                                                }
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </Drawer>
        </Fragment>
    );
}

/** SelectMobile propTypes
    * PropTypes
*/
SelectMobile.propTypes = {
    /** Select options */
    options: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        label: PropTypes.string,
        subs: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        }))
    })),
    /** Callback which called on input change */
    onChange: PropTypes.func,
    /** Input value */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.bool]),
    /** Is disabled */
    disabled: PropTypes.bool,
    /** Placeholder */
    placeholder: PropTypes.string,
    /** Classname */
    className: PropTypes.string,
    /** Are the options with label */
    withLabel: PropTypes.bool,
    /** Enable search */
    search: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            pattern: PropTypes.instanceOf(RegExp),
        })
    ]),
    /** Enable add new option */
    add: PropTypes.bool,
    /** Is Multiple mode select */
    isMultiple: PropTypes.bool,
    /** Is dark mode dropdown */
    isDark: PropTypes.bool,
    /** Show select all button or not */
    showSelectAllButton: PropTypes.bool,
    /** Max possible multiple selection count */
    maxMultipleSelect: PropTypes.number
}

export default SelectMobile;