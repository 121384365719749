import axios from "axios";
import { message } from "antd";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import {
    SET_SYSTEM_PAYMENTS_ACTION_BEFORE,
    SET_SYSTEM_PAYMENTS_ACTION_FINISH,
    SET_SYSTEM_PAYMENTS,
    SET_SYSTEM_PAYMENT_SAVE_ACTION_BEFORE,
    SET_SYSTEM_PAYMENT_SAVE_ACTION_FINISH,
    SET_SYSTEM_PAYMENTS_SORTING,
    SET_SYSTEM_PAYMENTS_FILTERS,
    SET_SYSTEM_PAYMENT_FORM_TRANSLATION,
    SET_SYSTEM_PAYMENT_FORM_TRANSLATIONS
} from "../../../../actionTypes";

export const setSystemPaymentsActionBefore = () => ({
    type: SET_SYSTEM_PAYMENTS_ACTION_BEFORE,
});

export const setSystemPaymentsActionFinished = () => ({
    type: SET_SYSTEM_PAYMENTS_ACTION_FINISH,
});

export const setSystemPaymentSaveActionBefore = () => ({
    type: SET_SYSTEM_PAYMENT_SAVE_ACTION_BEFORE,
});

export const setSystemPaymentSaveActionFinished = () => ({
    type: SET_SYSTEM_PAYMENT_SAVE_ACTION_FINISH,
});

const setSystemPayments = (payments, add) => ({
    type: SET_SYSTEM_PAYMENTS,
    payload: { payments, add },
})

export const setSystemPaymentsSorting = sorting => ({
    type: SET_SYSTEM_PAYMENTS_SORTING,
    payload: { sorting },
});

export const setSystemPaymentsFilters = filters => ({
    type: SET_SYSTEM_PAYMENTS_FILTERS,
    payload: { filters },
});

export const getSystemPayments = nextPage => {
    return (dispatch, getState) => {
        const state = getState();

        const page = nextPage === "first" ? 1 : nextPage === "next" ? state.systemPayments.sorting.page + 1 : state.systemPayments.sorting.page;
        const { labels, ...restFilters } = state.systemPayments.filters;
        const joinedArrayParams = labels.map(label => `labels=${label}`).join("&");
        const additionalQueryParams = joinedArrayParams.length ? `?${joinedArrayParams}` : "";
        const params = {
            ...state.systemPayments.sorting,
            ...restFilters,
            page: page
        };

        if (page > 1 && state.systemPayments.total <= (page - 1) * state.systemPayments.sorting.limit) {
            return Promise.resolve();
        }

        if (nextPage === "next" && state.systemPayments.total === 0) {
            return Promise.resolve();
        }

        dispatch(setSystemPaymentsActionBefore());

        return axios({
            url: `${ApiUrls.GET_SYSTEM_PAYMENT_METHODS}${additionalQueryParams}`,
            method: Methods.GET,
            params,
        })
            .then(({ data: { value: payments } }) => {
                dispatch(setSystemPayments(payments, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setSystemPaymentsSorting({ ...state.systemPayments.sorting, page }));
                dispatch(setSystemPaymentsActionFinished());
            })
            .catch((ex) => {
                dispatch(setSystemPaymentsActionFinished());
            });
    }
}

export const createSystemPayment = (data, onSuccess) => {
    return (dispatch) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            if(key === "labels") {
                data[key].forEach((label) => formData.append(key, label));
                return;
            }

            formData.append(key, data[key]);
        });

        dispatch(setSystemPaymentSaveActionBefore());
        const cancelTokenSource = axios.CancelToken.source();

        return axios({
            url: ApiUrls.CREATE_SYSTEM_PAYMENT_METHOD,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            cancelToken: cancelTokenSource.token,
            data: formData
        })
            .then(({ data }) => {
                dispatch(setSystemPaymentSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess(data.value);
                }
            })
            .catch(() => {
                dispatch(setSystemPaymentSaveActionFinished());
            });
    };
};

export const deleteSystemPayment = (id, onSuccess) => {
    return (dispatch) => {
        dispatch(setSystemPaymentSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_SYSTEM_PAYMENT_METHOD,
            method: Methods.DELETE,
            data: { id }
        })
            .then(({ data }) => {
                dispatch(setSystemPaymentSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(() => {
                dispatch(setSystemPaymentSaveActionFinished());
            });
    };
};

/** Translations */

const setSystemPaymentFormTranslation = (key, translation) => ({
    type: SET_SYSTEM_PAYMENT_FORM_TRANSLATION,
    payload: { key, translation }
})

const setSystemPaymentFormTranslations = translations => ({
    type: SET_SYSTEM_PAYMENT_FORM_TRANSLATIONS,
    payload: { translations }
})

export const getSystemPaymentFormTranslation = id => {
    return dispatch => {

        return axios({
            url: ApiUrls.GET_SYSTEM_PAYMENT_METHOD_FORM_TRANSLATIONS,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({ data }) => {
            dispatch(setSystemPaymentFormTranslations(data.value.translations));
        })
        .catch((ex) => {
            console.log(ex)
        })
    }
}

export const saveSystemPaymentFormTranslation = translation => {
    return dispatch => {
        dispatch(setSystemPaymentSaveActionBefore());

        return axios({
            url: ApiUrls.SAVE_SYSTEM_PAYMENT_METHOD_FORM_TRANSLATIONS,
            method: Methods.POST,
            data: {
                ...translation
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setSystemPaymentFormTranslation(translation.translationKey, data.value));
            }
            dispatch(setSystemPaymentSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setSystemPaymentSaveActionFinished());
        })
    }
}