import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Menu } from 'antd';

import Dropdown from "components/common/dropdown";
import Icon from "components/common/icon";

import { getUser } from 'utils/auth';

import useFormat from 'hooks/useFormat';

import { WALLET_TYPE } from "constants/wallet.constants";

import currencyType from "types/profile/currency.type";

/** Desktop Currencies dropdown component on Header */

const DesktopCurrenciesDropdown = ({
    currency,
    currencies,
    availableWallets,
    makeWalletAmount
}) => {

    const { formatAmount } = useFormat();

    const [dropdownVisible, setDropdownVisible] = useState(false);

    return (
        <div className="rt--header-actions rt--header-item rt--header-actions-currencies rt--mr-16 rt--flex rt--align-center">
            {
                getUser() && (
                    <Dropdown
                        overlayClassName="rt--header-actions-currencies-dropdown"
                        overlay={(
                            <Menu>
                                {
                                    availableWallets.length > 0 && (
                                        <Menu.Item
                                            key="*"
                                            className='rt--header-actions-wallet-item-header'
                                        >
                                            <div className='rt--header-actions-wallet rt--header-actions-wallet-title rt--flex rt--pl-12 rt--pr-12'>
                                                {
                                                    availableWallets.map(wallet => (
                                                        <div className='rt--flex rt--flex-col rt--flex-equal rt--justify-center rt--align-start' key={wallet.type}>
                                                            <span className='rt--title rt--font-small rt--font-regular'>{wallet.title}</span>
                                                        </div>
                                                    ))
                                                }
                                                <div></div>
                                            </div>
                                        </Menu.Item>
                                    )
                                }

                                {
                                    currencies.map(c => {
                                        return (
                                            <Menu.Item
                                                key={c.currencyCode}
                                                className="rt--header-actions-wallet-item"
                                            >
                                                <div className='rt--header-actions-wallet'>
                                                    {
                                                        availableWallets.map(wallet => (
                                                            <div className='rt--flex rt--flex-col rt--flex-equal rt--justify-center rt--align-start' key={wallet.type}>
                                                                <b className='rt--title rt--font-normal rt--font-bold'>
                                                                    {
                                                                        formatAmount(
                                                                            makeWalletAmount(wallet.type, c.currencyCode), 
                                                                            c.currencyCode
                                                                        )
                                                                    }
                                                                </b>
                                                            </div>
                                                        ))
                                                    }
                                                    <div className={
                                                        'rt--flex rt--flex-col rt--flex-equal rt--justify-center ' + 
                                                        (availableWallets.length > 0 ? "rt--align-end" : "rt--align-center")
                                                    }>
                                                        <b className='rt--title rt--font-normal'>{c.currencyCode}</b>
                                                    </div>
                                                </div>

                                            </Menu.Item>
                                        )
                                    })
                                }
                            </Menu>
                        )}
                        disabled={false}
                        popupVisible={dropdownVisible}
                        onVisibleChange={visible => setDropdownVisible(visible)}
                        getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                    >
                        <div className='rt--header-actions-content rt--flex rt--align-center rt--pl-8 rt--pr-8'>
                            <div className='rt--header-actions-content-icon rt--flex rt--align-center rt--justify-center rt--mr-12'>
                                <Icon name='wallet' />
                            </div>

                            {
                                availableWallets.map(wallet => (
                                    <div className='rt--flex rt--flex-col rt--justify-center rt--align-start rt--mr-12 rt--header-actions-wallet' key={wallet.type}>
                                        <span className='rt--title rt--font-small rt--font-regular'>{wallet.title}:</span>
                                        <b className='rt--title rt--font-small'>
                                            {
                                                formatAmount(
                                                    makeWalletAmount(wallet.type, currency), 
                                                    currency
                                                )
                                            }
                                        </b>
                                    </div>
                                ))
                            }


                            <b className='rt--title rt--font-normal'>
                                {currency ?? ""}
                            </b>
                            <Icon name='down-small' className="rt--header-actions-content-arrow" />
                        </div>
                    </Dropdown>
                )
            }

        </div>
    )
}

/** CurrenciesDropdown propTypes
    * PropTypes
*/
DesktopCurrenciesDropdown.propTypes = {
    /** Redux state property, the current user currency */
    currency: PropTypes.string,
    /** Redux state property, current user currencies */
    currencies: PropTypes.arrayOf(currencyType),
    /** Available Wallets */
    availableWallets: PropTypes.arrayOf(PropTypes.shape({
        /** Title */
        title: PropTypes.string,
        /** Type */
        type: PropTypes.oneOf(Object.values(WALLET_TYPE))
    })),
    /** Make wallet amount */
    makeWalletAmount: PropTypes.func
}

const mapStateToProps = state => {
    return {
        currency: state.profile.currency,
    }
}

export default connect(mapStateToProps, null)(DesktopCurrenciesDropdown)

