import React from 'react';

import Status from 'components/common/status';

import { STATUS_TYPES } from 'constants/status.constants';

export const columns = [
    {
        title: "backoffice.affiliates.id",
        dataIndex: "longId",
        copy: true,
        alwaysVisible: true
    },
    {
        title: "backoffice.affiliates.name",
        dataIndex: "name",
        alwaysVisible: true,
        sorter: true,
        mobileLevel: 2
    },
    {
        title: "backoffice.affiliates.groupType",
        dataIndex: "groupType",
        mobileLevel: 3,
        render: value => (
            <Status
                status={value}
                type={STATUS_TYPES.AFFILIATE_GROUP}
            />
        ),
        sorter: true,
    },
    {
        title: "backoffice.affiliates.createdAt",
        dataIndex: "createdAt",
        isDateTime: true,
        sorter: true,
        mobileLevel: 4,
    },
    {
        title: "backoffice.affiliates.createdBy",
        dataIndex: "createdBy",
        sorter: true,
        mobileLevel: 5
    },
    {
        title: "backoffice.affiliates.lastModifiedAt",
        dataIndex: "modifiedAt",
        isDateTime: true,
        sorter: true,
        mobileLevel: 6
    },
    {
        title: "backoffice.affiliates.lastModifiedBy",
        dataIndex: "modifiedBy",
        sorter: true,
    }
]