import React from "react";

import Financial from "pages/dashboard/financial";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const FinancialRoute = () => {
    return <Financial />;
};

export default withPermission(
    withAuth(FinancialRoute),
    {
        resource: PERMISSION_RESOURCE.DASHBOARD_FINANCIAL,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.DASHBOARD_FINANCIAL
);