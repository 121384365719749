//#region react
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region actions
import {
    getAffiliateGroups,
    setAffiliateGroupsSorting,
    setAffiliateGroupsFilters,
    deleteAffiliateGroup
} from "store/actions/portal/affiliates/groups/groups.action";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import GroupCreateComponent from './group-create.component';
import Filters from "./filters.component";
//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import Paths from 'constants/path.constants';
import { AFFILIATE_GROUPS } from "constants/pageName.constants";
import { columns } from "./columns";
//#endregion

//#region types
import sortingType from "types/common/sorting.type";
import groupType from "types/affiliate/group.type";
//#endregion


/** Affiliate Groups Page Component */
const AffiliateGroupsComponent = ({
    getAffiliateGroups,
    groups,
    isLoading,
    total,
    setAffiliateGroupsSorting,
    setAffiliateGroupsFilters,
    deleteAffiliateGroup,
    sorting,
    filters,
    globalProjectId
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [showCreatePopup, setShowCreatePopup] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: AFFILIATE_GROUPS });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasCreatePermission = hasPermission({ resource: PERMISSION_RESOURCE.AFFILIATE_GROUPS, action: PERMISSION_ACTION.CREATE });
    const hasDeletePermission = hasPermission({ resource: PERMISSION_RESOURCE.AFFILIATE_GROUPS, action: PERMISSION_ACTION.DELETE });

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setAffiliateGroupsFilters({
            ...filters,
            nameOrId: value
        })
    }

    const handleDateSearchChange = value => {
        setAffiliateGroupsFilters({
            ...filters,
            from: value[0],
            to: value[1]
        })
    }

    const handleRightArrowIconClick = (record) => {
        navigate({
            pathname: `${Paths.AFFILIATE_GROUPS_EDIT}/${record.id}`,
            search: `name=${record.name}&groupType=${record.groupType}`
        })
    }


    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsThatCanBeIncluded
    } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            mainColumns: columns,
            constructForInclude: true
        })
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    tableRowActions.push({
        title: t("backoffice.common.deepView"),
        icon: "right",
        onClick: handleRightArrowIconClick,
    })

    if (hasDeletePermission) {
        tableRowActions.push({
            title: t('backoffice.common.delete'),
            confirmationMessage: t("backoffice.affiliates.doYouWantToDeleteGroup"),
            confirmationTitle: t("backoffice.affiliates.deleteGroup"),
            icon: "trash",
            showConfirmation: true,
            onClick: record => deleteAffiliateGroup(record.id),
            disabled: record => !record.isRemovable
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        search: {
            onSearch: handleSearchChange,
            loadFn: getAffiliateGroups,
            placeholder: t("backoffice.affiliates.groupNameOrId"),
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.AFFILIATE_GROUPS,
                dependencies: [globalProjectId]
            }
        },
        date: {
            onSearch: handleDateSearchChange,
            placeholder: t('backoffice.common.creationDate'),
            showTime: false,
            loadFn: getAffiliateGroups,
            value: [filters.from, filters.to]
        },
        buttons: [
            {
                icon: "plus",
                type: "primary",
                onClick: () => setShowCreatePopup(true),
                enabled: hasCreatePermission,
                text: t('backoffice.affiliates.createGroup'),
            }
        ],
        breadcrumbs: {
            items: [
                {
                    title: t('backoffice.menu.affiliateGroups')
                }
            ],
        }
    }

    //#endregion

    return (
        <MainDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={groups}
                loadFn={getAffiliateGroups}
                sorting={sorting}
                setSortingFn={setAffiliateGroupsSorting}
                total={total}
                updateProps={[globalProjectId]}
                actions={tableRowActions}
            />

            {showCreatePopup && (
                <GroupCreateComponent
                    onClose={() => setShowCreatePopup(false)}
                />
            )}
        </MainDashboardLayout>
    );
};

/** AffiliateGroupsComponent propTypes
 * PropTypes
*/
AffiliateGroupsComponent.propTypes = {
    /** Redux action to get affiliate groups */
    getAffiliateGroups: PropTypes.func,
    /** Redux state property, represents the array of affiliate groups  */
    groups: PropTypes.arrayOf(groupType),
    /** Redux state property, is true when loading affiliate groups */
    isLoading: PropTypes.bool,
    /** Redux state property, affiliate groups total count */
    total: PropTypes.number,
    /** Redux action to set affiliate groups sorting details */
    setAffiliateGroupsSorting: PropTypes.func,
    /** Redux action to set affiliate groups sorting details */
    setAffiliateGroupsFilters: PropTypes.func,
    /** Redux action to delete affiliate group */
    deleteAffiliateGroup: PropTypes.func,
    /** Redux state property, affiliate groups sorting details */
    sorting: sortingType,
    /** Redux state property, affiliate groups filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    getAffiliateGroups: nextPage => {
        dispatch(getAffiliateGroups(nextPage));
    },
    setAffiliateGroupsSorting: sorting => {
        dispatch(setAffiliateGroupsSorting(sorting));
    },
    setAffiliateGroupsFilters: filters => {
        dispatch(setAffiliateGroupsFilters(filters));
    },
    deleteAffiliateGroup: id => {
        dispatch(deleteAffiliateGroup(id))
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.affiliateGroups.isLoading,
        groups: state.affiliateGroups.groups,
        total: state.affiliateGroups.total,
        sorting: state.affiliateGroups.sorting,
        filters: state.affiliateGroups.filters,
        globalProjectId: state.common.globalProjectId
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AffiliateGroupsComponent);
