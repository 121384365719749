import axios from "axios";
import { message } from 'antd';

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { BO_GROUP_ID, PROMO_GROUP_ID, TRANSLATION_TYPE } from "constants/translation.constants";

import { getLanguages } from 'store/actions/auth/translations.action';

import {
    SET_TRANSLATIONS_ACTION_BEFORE,
    SET_TRANSLATIONS_ACTION_FINISH,
    SET_TRANSLATION_GROUPS_ACTION_BEFORE,
    SET_TRANSLATION_GROUPS_ACTION_FINISH,
    SET_TRANSLATION_GROUPS,
    SET_TRANSLATIONS,
    UPDATE_TRANSLATION,
    SET_TRANSLATIONS_SAVE_ACTION_BEFORE,
    SET_TRANSLATIONS_SAVE_ACTION_FINISH,
    SET_TRANSLATIONS_SYSTEM_DEFAULT,
    SET_TRANSLATIONS_PUBLISH_ACTION_BEFORE,
    SET_TRANSLATIONS_PUBLISH_ACTION_FINISH,
    SET_TRANSLATIONS_UNPUBLISH_ACTION_BEFORE,
    SET_TRANSLATIONS_UNPUBLISH_ACTION_FINISH
} from "../../../../actionTypes";

const setTranslationsActionBefore = () => ({
    type: SET_TRANSLATIONS_ACTION_BEFORE,
});

const setTranslationsActionFinished = () => ({
    type: SET_TRANSLATIONS_ACTION_FINISH,
});

const setTranslationsSaveActionBefore = () => ({
    type: SET_TRANSLATIONS_SAVE_ACTION_BEFORE,
});

const setTranslationsSaveActionFinished = () => ({
    type: SET_TRANSLATIONS_SAVE_ACTION_FINISH,
});

const setTranslations = translations => ({
    type: SET_TRANSLATIONS,
    payload: { translations }
})

const updateTranslation = ( translation, key) => ({
    type: UPDATE_TRANSLATION,
    payload: { translation, key }
})

const setTranslationGroupsActionBefore = () => ({
    type: SET_TRANSLATION_GROUPS_ACTION_BEFORE,
});

const setTranslationGroupsActionFinished = () => ({
    type: SET_TRANSLATION_GROUPS_ACTION_FINISH,
});

const setTranslationGroups = ( groups, translationType ) => ({
    type: SET_TRANSLATION_GROUPS,
    payload: { groups, translationType }
})

const setTranslationsPublishActionBefore = () => ({
    type: SET_TRANSLATIONS_PUBLISH_ACTION_BEFORE
})

const setTranslationsPublishActionFinished = () => ({
    type: SET_TRANSLATIONS_PUBLISH_ACTION_FINISH
})

const setTranslationsUnPublishActionBefore = () => ({
    type: SET_TRANSLATIONS_UNPUBLISH_ACTION_BEFORE
})

const setTranslationsUnPublishActionFinished = () => ({
    type: SET_TRANSLATIONS_UNPUBLISH_ACTION_FINISH
})

export const createTranslation = (translation, languageCodes, onSuccess) => {
    return (dispatch) => {
        dispatch(setTranslationsSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_TRANSLATION,
            method: Methods.POST,
            data: translation
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    onSuccess && onSuccess(data.value);

                    if(translation.groupId === PROMO_GROUP_ID){
                        dispatch(getPromoTranslations(translation.subGroupId, languageCodes, true));
                    } else if (translation.groupId === BO_GROUP_ID){
                        dispatch(getBOTranslations(translation.subGroupId, languageCodes));
                    }

                }
                dispatch(setTranslationsSaveActionFinished());
            })
            .catch(() => {
                dispatch(setTranslationsSaveActionFinished());
            });
    };
};

export const resetTranslation = translation => {
    return (dispatch) => {
        dispatch(setTranslationsSaveActionBefore());
        return axios({
            url: ApiUrls.RESET_TRANSLATION,
            method: Methods.DELETE,
            data: translation
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(updateTranslation(data.value, translation.key));
                }
                dispatch(setTranslationsSaveActionFinished());
            })
            .catch(() => {
                dispatch(setTranslationsSaveActionFinished());
            });
    };
};

export const getTranslationGroups = translationType => {
    return dispatch => {
        
        dispatch(setTranslationGroupsActionBefore());

        const params = {};

        const groupId = translationType === TRANSLATION_TYPE.BO ? BO_GROUP_ID : PROMO_GROUP_ID;

        params.groupId = groupId;
        params.isSeparate = true;

        return axios({
            url: ApiUrls.GET_TRANSLATION_GROUPS,
            method: Methods.GET,
            params: params
        })
            .then(({ data: { value: groups } }) => {
                dispatch(setTranslationGroups(groups[0].subGroups, translationType));
                dispatch(setTranslationGroupsActionFinished());
            })
            .catch((ex) => {
                dispatch(setTranslationGroupsActionFinished());
            });
    }
}

export const setTranslationsSystemDefault = systemDefault => ({
    type: SET_TRANSLATIONS_SYSTEM_DEFAULT,
    payload: { systemDefault }
})

/** Promo Translations */

export const getPromoTranslations = (subGroupId, languageCodes, isSystemDefault) => {
    return dispatch => {
        dispatch(setTranslationsActionBefore());
        const languageCodesQuery = languageCodes.slice(0, 3).map(l => "languageCodes=" + l).join("&");

        return axios({
            url: `${ApiUrls.GET_TRANSLATIONS}?${languageCodesQuery}`,
            method: Methods.GET,
            params: {
                baseLanguageCode: "en",
                groupId: PROMO_GROUP_ID,
                subGroupId,
                isSystemDefault
            }
        })
            .then(({ data: { value: translations } }) => {
                dispatch(setTranslations(translations));
                dispatch(setTranslationsActionFinished());
            })
            .catch((ex) => {
                dispatch(setTranslationsActionFinished());
            });
    }
}

export const savePromoTranslations = translation => {
    return (dispatch) => {
        dispatch(setTranslationsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_TRANSLATION,
            method: Methods.POST,
            data: {
                groupId: PROMO_GROUP_ID,
                isSystemDefault: true,
                ...translation
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(updateTranslation(data.value, translation.key));
                }
                dispatch(setTranslationsSaveActionFinished());
            })
            .catch(() => {
                dispatch(setTranslationsSaveActionFinished());
            });
    };
};

export const publishPromoTranslations = (isSystemDefault, languageCode) => {
    return (dispatch) => {
        dispatch(setTranslationsPublishActionBefore());
        return axios({
            url: ApiUrls.PUBLISH_TRANSLATIONS,
            method: Methods.POST,
            data: { isSystemDefault, languageCode }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                }
                dispatch(setTranslationsPublishActionFinished());
            })
            .catch(() => {
                dispatch(setTranslationsPublishActionFinished());
            });
    };
};

export const unpublishPromoTranslations = languageCode => {
    return (dispatch) => {
        dispatch(setTranslationsUnPublishActionBefore());
        const d = {};
        if(languageCode){
            d.languageCode = languageCode;
        }

        return axios({
            url: ApiUrls.UNPUBLISH_TRANSLATIONS,
            method: Methods.POST,
            data: d
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                }
                dispatch(setTranslationsUnPublishActionFinished());
            })
            .catch(() => {
                dispatch(setTranslationsUnPublishActionFinished());
            });
    };
};

/** BO Translations */

export const getBOTranslations = ( subGroupId, languageCodes ) => {

    return dispatch => {
        
        dispatch(setTranslationsActionBefore());
        const languageCodesQuery = languageCodes.slice(0, 3).map(l => "languageCodes=" + l).join("&");
        return axios({
            url: `${ApiUrls.GET_BO_TRANSLATIONS}?${languageCodesQuery}`,
            method: Methods.GET,
            params: {
                baseLanguageCode: "en",
                groupId: BO_GROUP_ID,
                subGroupId,
                isSystemDefault: true
            }
        })
            .then(({ data: { value: translations } }) => {
                dispatch(setTranslations(translations));
                dispatch(setTranslationsActionFinished());
            })
            .catch((ex) => {
                dispatch(setTranslationsActionFinished());
            });
    }
}

export const saveBOTranslation = translation => {
    return (dispatch) => {
        dispatch(setTranslationsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_BO_TRANSLATION,
            method: Methods.POST,
            data: {
                ...translation,
                groupId: BO_GROUP_ID,
                isSystemDefault: true
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(updateTranslation(data.value, translation.key));
                }
                dispatch(setTranslationsSaveActionFinished());
            })
            .catch(() => {
                dispatch(setTranslationsSaveActionFinished());
            });
    };
};

export const publishBOTranslations = languageCode => {
    return (dispatch) => {
        dispatch(setTranslationsPublishActionBefore());
        return axios({
            url: ApiUrls.PUBLISH_BO_TRANSLATIONS,
            method: Methods.POST,
            data: { 
                languageCode,
                isSystemDefault: true 
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(getLanguages());
                    message.success(data.message);
                }
                dispatch(setTranslationsPublishActionFinished());
            })
            .catch(() => {
                dispatch(setTranslationsPublishActionFinished());
            });
    };
};
