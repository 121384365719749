import PropTypes from 'prop-types';

import brandUrlType from './brandUrl.type';

export default PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    domain: PropTypes.string,
    createdAt: PropTypes.string,
    createdBy: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
    urls: PropTypes.arrayOf(brandUrlType)
})