import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { SET_PROJECT_PROMO, SET_PROJECT_PROMO_STATUS } from "store/actionTypes";

import { setProjectsActionBefore, setProjectsActionFinished, setProjectsSaveActionBefore, setProjectsSaveActionFinished } from './projects.action';

const setPromo = (info) => ({
    type: SET_PROJECT_PROMO,
    payload: { info },
});

const setPromoStatus = enabled => ({
    type: SET_PROJECT_PROMO_STATUS,
    payload: { enabled },
});



export const getPromo = () => {
    return (dispatch) => {
        dispatch(setProjectsActionBefore());
        return axios({
            url: ApiUrls.GET_PROMO_INFO,
            method: Methods.GET,
        })
        .then(({data : {value: info}}) => {
            dispatch(setPromo(info))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const setupPromo = () => {
    return (dispatch) => {
        dispatch(setProjectsActionBefore());
        return axios({
            url: ApiUrls.SETUP_PROMO,
            method: Methods.POST,
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getPromo());
            } 

            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const changePromoStatus = enabled => {
    return (dispatch) => {
        // dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SET_PROMO_STATUS,
            method: Methods.POST,
            data: {
                enabled
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setPromoStatus(enabled));
            } 

            // dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            // dispatch(setProjectsSaveActionFinished());
        })
    }
}

export const savePromoInfo = data => {
    return (dispatch) => {
        dispatch(setProjectsSaveActionBefore());
        console.log(data)
        return axios({
            url: ApiUrls.SAVE_PROMO_INFO,
            method: Methods.POST,
            data: data
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setPromo(data.value));
            } 

            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}