import React from "react";
import PropTypes from "prop-types";

import { Button } from "antd";

import Icon from 'components/common/icon';

import { CUSTOMIZE_PREVIEW_DEVICE_MODE } from "constants/customize.constants";

/** Customize Toolbar Component */
const Toolbar = ({
    previewDeviceMode,
    setPreviewDeviceMode,
    title,
    buttons = [],
    backButton
}) => {


    return (
        <div className="rt--customize-toolbar rt--pl-24 rt--pr-24 rt--pt-10 rt--pb-10 rt--mb-16 rt--flex rt--align-center rt--justify-between">
            <div className="rt--customize-toolbar-domain">
                <span className="rt--text-secondary rt--font-bold rt--font-big">
                    {title || ""}
                </span>
            </div>
            {
                (
                    <div className="rt--customize-toolbar-devices rt--flex rt--flex-equal rt--justify-center rt--align-center">
                        {
                            Object.values(CUSTOMIZE_PREVIEW_DEVICE_MODE).map(device => (
                                <div
                                    key={device}
                                    className={
                                        "rt--customize-toolbar-devices-item rt--flex rt--justify-center rt--align-center rt--pl-4 rt--pr-4 rt--pt-4 rt--pb-4 rt--ml-8 rt--mr-8" +
                                        (device === previewDeviceMode ? " rt--customize-toolbar-devices-item-active" : "")
                                    }
                                    onClick={() => setPreviewDeviceMode(device)}
                                >
                                    <Icon name={device === CUSTOMIZE_PREVIEW_DEVICE_MODE.TABLET ? "tablet" : device === CUSTOMIZE_PREVIEW_DEVICE_MODE.MOBILE ? "mobile" : "desktop"} />
                                </div>
                            ))
                        }
                    </div>
                )
            }


            <div className="rt--customize-toolbar-actions rt--flex rt--justify-end rt--align-center">
                {
                    backButton && (
                        <Button
                            htmlType="button"
                            type="text"
                            className="rt--button rt--button-ghost rt--mr-8"
                            onClick={backButton.onClick}
                        >
                            <span>{backButton.text}</span>
                        </Button>
                    )
                }
                {
                    buttons.map((button, index) => (
                        <Button
                            htmlType="button"
                            type="primary"
                            className={"rt--button rt--ml-16 " + button.className}
                            onClick={button.onClick}
                            loading={button.loading}
                            key={index}
                        >
                            <span>{button.text}</span>
                        </Button>
                    ))
                }
            </div>
        </div>
    );
};

/** Toolbar propTypes
 * PropTypes
*/
Toolbar.propTypes = {
    /** Function to set preview device mode */
    setPreviewDeviceMode: PropTypes.func,
    /** Preview device mode */
    previewDeviceMode: PropTypes.oneOf(Object.values(CUSTOMIZE_PREVIEW_DEVICE_MODE)),
    /** Title */
    title: PropTypes.string,
    /** Buttons */
    buttons: PropTypes.arrayOf(PropTypes.shape({
        className: PropTypes.string,
        loading: PropTypes.bool,
        text: PropTypes.string,
        onClick: PropTypes.func
    })),
    /** Back Button */
    backButton: PropTypes.shape({
        text: PropTypes.string,
        onClick: PropTypes.func
    })
};


export default Toolbar;
