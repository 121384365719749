import PropTypes from 'prop-types';

import { PROJECT_DOMAIN_STATUS } from 'constants/project.constants';

export default PropTypes.shape({
    id: PropTypes.string,
    domain: PropTypes.string,
    nS1:PropTypes.string,
    nS2:PropTypes.string,
    records: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
    })),
    status: PropTypes.oneOf(Object.values(PROJECT_DOMAIN_STATUS))
})