//#region react
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
//#endregion

//#region libraries
import { Input } from "antd";
//#endregion

//#region actions
import {
    getErrors,
    setErrorsSorting
} from "store/actions/portal/developer/errors/errors.action";
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import Modal from "components/common/modal";
import Filters from "./filters.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import { POPUP_SIZE } from "constants/common.constants";
import { ERROR_LOGS } from "constants/pageName.constants";
import { tableColumns } from "./columns";
//#endregion

//#region types
import errorType from "types/error/error.type";
import sortingType from "types/common/sorting.type";
//#endregion

const ErrorsComponent = ({
    getErrors,
    errors,
    isLoading,
    total,
    setErrorsSorting,
    sorting,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const [stackTrace, setStackTrace] = useState(null);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: ERROR_LOGS });

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const showStackTraceList = (record) => {
        setStackTrace(record.stackTrace)
    }

    const hideStackTraceList = () => {
        setStackTrace(null)
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded is only needed in the desktop version.
    const { mainTableColumns, columnsThatCanBeIncluded } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            mainColumns: tableColumns,
            constructForInclude: true,
        })
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [{
        title: t('backoffice.errorlogs.stackTrace'),
        icon: "code",
        onClick: showStackTraceList,
        disabled: record => !Boolean(record.stackTrace),
    }];

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.errors') }]
        }
    }

    //#endregion

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={errors}
                loadFn={getErrors}
                sorting={sorting}
                setSortingFn={setErrorsSorting}
                total={total}
                updateProps={[globalProjectId]}
                actions={tableRowActions}
            />

            {Boolean(stackTrace) && (
                <Modal
                    title={t('backoffice.menu.errors')}
                    cancelText={t('backoffice.common.cancel')}
                    size={POPUP_SIZE.EXTRA_BIG}
                    hideOkButton={true}
                    onCancel={hideStackTraceList}
                    >
                    <Input.TextArea
                        className="rt--stacktrace"
                        autoSize={true}
                        disabled={true}
                        value={stackTrace}
                    />
                </Modal>
            )}
        </MainDashboardLayout>
    )
};

/** ErrorsComponent propTypes
 * PropTypes
*/
ErrorsComponent.propTypes = {
    /** Redux action to get errors */
    getErrors: PropTypes.func,
    /** Redux state property, represents the array of errors  */
    errors: PropTypes.arrayOf(errorType),
    /** Redux state property, is true when loading errors */
    isLoading: PropTypes.bool,
    /** Redux state property, errors total count */
    total: PropTypes.number,
    /** Redux action to set errors sorting details */
    setErrorsSorting: PropTypes.func,
    /** Redux state property, user logs sorting details */
    sorting: sortingType,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    getErrors: nextPage => {
        dispatch(getErrors(nextPage));
    },
    setErrorsSorting: sorting => {
        dispatch(setErrorsSorting(sorting));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.errors.isLoading,
        errors: state.errors.errors,
        total: state.errors.total,
        sorting: state.errors.sorting,
        globalProjectId: state.common.globalProjectId
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorsComponent);
