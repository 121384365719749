export const tableColumns = [
    {
        title: "backoffice.projects.name",
        dataIndex: "name",
        sorter: true,
        alwaysVisible: true,
        mobileLevel: 1,
        render: (value, record) => record.isTesting ? `${value} (Test)` : value,
    },
    {
        title: "backoffice.projects.platform",
        dataIndex: "platform",
        mobileLevel: 5,
        render: value => value || "-",
    },
    {
        title: "backoffice.projects.gateway",
        dataIndex: "gateway",
        mobileLevel: 6,
        render: value => value || "-",
    },
    {
        title: "backoffice.projects.createdAt",
        dataIndex: "createdAt",
        isDateTime: true,
        sorter: true,
    },
    {
        title: "backoffice.projects.createdBy",
        dataIndex: "createdBy",
        sorter: true,
    },
    {
        title: "backoffice.projects.lastUpdatedAt",
        dataIndex: "modifiedAt",
        isDateTime: true,
        sorter: true,
        mobileLevel: 2,
    },
    {
        title: "backoffice.projects.lastUpdatedBy",
        dataIndex: "modifiedBy",
        sorter: true,
        mobileLevel: 3,
        render: value => value || "-",
    },

];