import React, { useState, useEffect } from 'react';

import { useLocation } from "react-router-dom";

import NewslettersListComponent from './newsletter-list.component';
import NewsletterEditComponent from './editNewsletter';

const NewslettersComponent = () => {
    const { search } = useLocation();

    const [ currentNewsletterId, setCurrentNewsletterId] = useState((new URLSearchParams(search)).get("newsletterId"));

    /** Change view depend on query string param change */
    useEffect(() => {
        const currentNewsletterId = (new URLSearchParams(search)).get("newsletterId");
        setCurrentNewsletterId(currentNewsletterId);
    }, [search])

    return !currentNewsletterId ? (
        <NewslettersListComponent />
    ) : (
        <NewsletterEditComponent/>
    )
}

export default NewslettersComponent; 