import React from "react";

import { useTranslation } from "react-i18next";

import Tabs from "components/common/tabs";

import PendingComponent from "./pending/pending.component";
import HistoryComponent from "./history/history.component";

/** Invoices Page Component */
const InvoicesComponent = () => {

    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.invoices.pending"),
            component: <PendingComponent />
        },
        {
            title: t("backoffice.invoices.history"),
            component: <HistoryComponent />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={[
                { title: t('backoffice.menu.payment') },
                { title: t('backoffice.menu.invoices') }
            ]}
        />
    )
};

export default InvoicesComponent
