import PropTypes from 'prop-types';

import { USER_TYPE } from "constants/user.constants"

export default PropTypes.shape({
    id: PropTypes.number,
    userId: PropTypes.number,
    userName: PropTypes.string,
    ip: PropTypes.string,
    country: PropTypes.string,
    companyId: PropTypes.number,
    projectId: PropTypes.number,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    userRole: PropTypes.oneOf(Object.values(USER_TYPE))
})