import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Row, Col, DatePicker } from 'antd';

import Modal from "components/common/modal";
import Icon from "components/common/icon";

import useDate from 'hooks/useDate';

import { approveInvoice } from "store/actions/portal/payment/invoices/invoices.action";

import { POPUP_SIZE } from 'constants/common.constants';

import { isMobile } from 'utils/common';

import invoiceType from 'types/payment/invoice.type';
import NumericInput from 'components/common/numericInput';

/** Invoice Pay Popup Component */
const InvoicePayComponent = ({
    isSaving,
    approveInvoice,
    invoice,
    onClose
}) => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    /** Fires when form submitted
       * @function
       * @memberOf InvoicePayComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const values = {
                    id: invoice.id,
                    createdAt: invoice.createdAt,
                    paymentDate: data.paymentDate
                }

                approveInvoice(
                    values,
                    () => {
                        onClose();
                    });
            }).catch(ex => {
                console.log(ex)
            })
    }

    return (
        <Modal
            title={t('backoffice.invoices.payInvoice')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.invoices.pay')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.SMALL}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={t("backoffice.invoices.paymentDate")}
                            name="paymentDate"
                        >
                            <DatePicker
                                className="rt--datepicker"
                                dropdownClassName='rt--datepicker-popup rt--datepicker-withtime-popup'
                                format={`${dateService.getFormat(true)}`}
                                showTime={{ format: dateService.getFormat(true, true) }}
                                disabledDate={d => dateService.isAfter(d, dateService.getNow()) }
                                showToday={false}
                                showNow={false}
                                allowClear={true}
                                placeholder={t('backoffice.common.selectDate')}
                                suffixIcon={<Icon name='date' />}
                                getPopupContainer={isMobile() ? '' : () => document.getElementsByClassName("rt--portal-layout")[0]}
                                inputReadOnly={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.invoices.amount')}
                            className="rt--general-form-item rt--form-item-disabled"
                        >
                            <NumericInput
                                value={invoice.amount}
                                disabled={true}
                            />
                        </Form.Item>
                        <em className='rt--text-light rt--font-small rt--font-regular'>{t("backoffice.invoices.payInvoiceNote")}</em>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** InvoicePayComponent propTypes
    * PropTypes
*/
InvoicePayComponent.propTypes = {
    /** Redux state property, is true when approve invoice request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to approve invoice */
    approveInvoice: PropTypes.func,
    /** Current editin invoice */
    invoice: invoiceType,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        approveInvoice: (invoice, onSuccess) => {
            dispatch(approveInvoice(invoice, onSuccess));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.invoices.isSaving
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePayComponent)