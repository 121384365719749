import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setCompaniesActionBefore, setCompaniesActionFinished, setCompaniesSaveActionBefore, setCompaniesSaveActionFinished } from './companies.action';

import { SET_COMPANY_AUTHENTICATION_SETTINGS } from "../../../actionTypes";

const setCompanyAuthenticationSettings = authenticationSettings => ({
    type: SET_COMPANY_AUTHENTICATION_SETTINGS,
    payload: { authenticationSettings }
})

export const getCompanyAuthenticationSettings = () => {
    return dispatch => {
        dispatch(setCompaniesActionBefore());

        return axios({
            url: ApiUrls.COMPANY_GET_AUTHENTICATION_SETTINGS,
            method: Methods.GET
        })
        .then(({data : {value: authenticationSettings}}) => {
            dispatch(setCompanyAuthenticationSettings(authenticationSettings))
            dispatch(setCompaniesActionFinished());
        })
        .catch((ex) => {
            dispatch(setCompaniesActionFinished());
        })
    }
}

export const saveCompanyAuthenticationSettings = data => {
    return dispatch => {
        dispatch(setCompaniesSaveActionBefore());
        return axios({
            url: ApiUrls.COMPANY_SAVE_AUTHENTICATION_SETTINGS,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setCompanyAuthenticationSettings(data.value));
            } 
            dispatch(setCompaniesSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCompaniesSaveActionFinished());
        })
    }
}
