import {
    SET_PERMISSIONS_ACTION_BEFORE,
    SET_PERMISSIONS_ACTION_FINISH,
    SET_PERMISSIONS,
    SET_PERMISSION_RESOURCES_ACTION_BEFORE,
    SET_PERMISSION_RESOURCES_ACTION_FINISH,
    SET_PERMISSIONS_RESOURCES
} from '../../actionTypes';


export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_PERMISSIONS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_PERMISSIONS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_PERMISSIONS:
            return {
                ...state,
                permissions: payload.permissions
            }
        case SET_PERMISSION_RESOURCES_ACTION_BEFORE:
            return {
                ...state,
                isResourcesLoading: true
            }
        case SET_PERMISSION_RESOURCES_ACTION_FINISH:
            return {
                ...state,
                isResourcesLoading: false
            }
        case SET_PERMISSIONS_RESOURCES:
            return {
                ...state,
                resources: payload.resources
            }
        default:
            return state;
    }
}