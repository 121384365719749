import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

import { isMobile, isIOS } from 'utils/common';

/** GeneralInput */

const TextAreaInput = ({
	value,
	placeholder,
	resizable = true,
	...rest

}) => {

	const inputRef = useRef(null);

	/** Fires on input change
	   * @function
	   * @memberOf TextAreaInput
   */

	useEffect(() => {
		if(inputRef.current && inputRef.current.resizableTextArea.textArea){
			if(value){
				inputRef.current.resizableTextArea.textArea.classList.add("rt--hide-placeholder")
			} else {
				inputRef.current.resizableTextArea.textArea.classList.remove("rt--hide-placeholder")
			}
		}
		
	}, [value])


	return (
		<Input.TextArea
			{...rest}
			ref={inputRef}
			value={value}
			style={{ resize: !resizable ? "none" : undefined }}
			placeholder={(isMobile() && isIOS() ? null : placeholder)}
		/>
	)
}

/** Input propTypes
	* PropTypes
*/

TextAreaInput.propTypes = {
	/** Callback which called on input change */
	onChange: PropTypes.func,
	/** Input value */
	value: PropTypes.string,
	/** Input placeholder */
	placeholder: PropTypes.string,
	resizable: PropTypes.bool
}

export default TextAreaInput;