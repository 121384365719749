import {
    SET_MARKETING_REPORTS_ACTION_BEFORE,
    SET_MARKETING_REPORTS_ACTION_FINISH,
    SET_CLICK_REPORT,
    SET_CLICK_REPORT_SORTING,
    SET_CLICK_REPORT_FILTERS,
    SET_MEDIA_REPORT,
    SET_MEDIA_REPORT_SORTING,
    SET_MEDIA_REPORT_FILTERS,
    SET_MEDIA_REPORT_TOTALS
} from '../../actionTypes';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_MARKETING_REPORTS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_MARKETING_REPORTS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }

        case SET_CLICK_REPORT:
            return {
                ...state,
                clickReport: {
                    ...state.clickReport,
                    report: !payload.add ? payload.report.item2 : state.clickReport.report.concat(payload.report.item2),
                    total: payload.report.item1,
                }
            }
        case SET_CLICK_REPORT_FILTERS:
            return {
                ...state,
                clickReport: {
                    ...state.clickReport,
                    filters: payload.filters,
                    sorting: {
                        ...state.clickReport.sorting,
                        page: 1
                    }
                }
            }
        case SET_CLICK_REPORT_SORTING:
            return {
                ...state,
                clickReport: {
                    ...state.clickReport,
                    sorting: payload.sorting
                }
            };

        case SET_MEDIA_REPORT:
            return {
                ...state,
                mediaReport: {
                    ...state.mediaReport,
                    report: !payload.add ? payload.report.item2 : state.mediaReport.report.concat(payload.report.item2),
                    total: payload.report.item1,
                }
            }
        case SET_MEDIA_REPORT_FILTERS:
            return {
                ...state,
                mediaReport: {
                    ...state.mediaReport,
                    filters: payload.filters,
                    sorting: {
                        ...state.mediaReport.sorting,
                        page: 1
                    }
                }
            }
        case SET_MEDIA_REPORT_SORTING:
            return {
                ...state,
                mediaReport: {
                    ...state.mediaReport,
                    sorting: payload.sorting
                }
            };
        case SET_MEDIA_REPORT_TOTALS:
            return {
                ...state,
                mediaReport: {
                    ...state.mediaReport,
                    totals: payload.totals,
                }
            }
        default:
            return state;
    }
}