import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {Input as AntInput} from "antd"

import { countDecimals } from 'utils/common';

import Input from '../input';


/** Numeric Input Component, allows only numeric characters in input */
const NumericInput = ({
	onChange,
	value,
	onBlur,
	placeholder,
	style,
	isInteger,
	disabled,
	decimalsCount,
	isMobileNumber,
	isIPAddress,
	isOriginalInput,
	...rest
}) => {

	const [hidePlaceholder, setHidePlaceholder] = useState(false)
	/** Fires on input change
	   * @function
	   * @description checks to allow only numeric characters
	   * @memberOf NumericInput
   */
  
	const onChangeHandler = e => {
		const { value } = e.target;
		let reg = isInteger ? /^-?[0-9]*([0-9]*)?$/ : /^-?[0-9]*(\.[0-9]*)?$/;
		if(isMobileNumber){
			reg = /^\+?([0-9]*)?$/;
		}

		if(isIPAddress){
			reg = /^[0-9]+(?:\.[0-9]+)*\.?$/;
		}

		if (
			(
				!isNaN(value) && reg.test(value) && !value.startsWith("-") && (!decimalsCount || countDecimals(value) <= decimalsCount)
			) || (
				(isMobileNumber || isIPAddress) && reg.test(value)
			) || value === ''
		) {
			onChange && onChange(value);
		}
	};

	/** Fires on input blur
	   * @function
	   * @description remove "." from value, if it's the last character
	   * @memberOf NumericInput
   */
	const onBlurHandler = () => {
		const v = value === undefined || value === null ? "" : value;
		let valueTemp = v + "";
		if (!isInteger && valueTemp.charAt(v.length - 1) === '.') {
			valueTemp = valueTemp.slice(0, -1);
		}
		if (valueTemp !== valueTemp.replace(/0*(\d+)/, '$1') && !isMobileNumber && !isIPAddress) {
			onChange(valueTemp.replace(/0*(\d+)/, '$1'));
		}

		if (onBlur) {
			onBlur();
		}
	}

	useEffect(() => {
		if (value !== undefined && value !== null && value !== "") {
			setHidePlaceholder(true)
		} else {
			setHidePlaceholder(false)
		}
	}, [value])

	return isOriginalInput ? (
		<AntInput
			{...rest}
			style={style}
			onChange={onChangeHandler}
			onBlur={onBlurHandler}
			placeholder={placeholder}
			value={value}
			disabled={disabled}
			autoComplete="off"
			className={hidePlaceholder ? "rt--hide-placeholder" : ""}
		/>
	) : (
		<Input
			{...rest}
			style={style}
			onChange={onChangeHandler}
			onBlur={onBlurHandler}
			placeholder={placeholder}
			value={value}
			disabled={disabled}
			autoComplete="off"
			className={hidePlaceholder ? "rt--hide-placeholder" : ""}
		/>
	)
}

/** NumericInput propTypes
	* PropTypes
*/
NumericInput.propTypes = {
	/** Callback which called on input change */
	onChange: PropTypes.func,
	/** Input value*/
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/** Callback which called on input blur */
	onBlur: PropTypes.func,
	/** Input placeholder */
	placeholder: PropTypes.string,
	/** Input styles */
	style: PropTypes.object,
	/** Should input value be integer */
	isInteger: PropTypes.bool,
	/**Is input disabled */
	disabled: PropTypes.bool,
	/** Limitation for decimal numbers */
	decimalsCount: PropTypes.number,
	/** Allow Plus for mobile inputs */
	isMobileNumber: PropTypes.bool,
	/** Allow '.' for ip address inputs */
	isIPAddress: PropTypes.bool,
	/** Is original input */
	isOriginalInput: PropTypes.bool
}

export default NumericInput;