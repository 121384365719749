import React, { useState, useEffect } from 'react';

import { useLocation } from "react-router-dom";

import PaymentListComponent from './payment-list.component';
import PaymentEditComponent from './edit';

const PaymentMethodsComponent = () => {
    const { search } = useLocation();

    const [ currentPaymentId, setPaymentBrandId] = useState((new URLSearchParams(search)).get("paymentId"));

    /** Change view depend on query string param change */
    useEffect(() => {
        const currentPaymentId = (new URLSearchParams(search)).get("paymentId");
        setPaymentBrandId(currentPaymentId);
    }, [search])

    return !currentPaymentId ? (
        <PaymentListComponent />
    ) : (
        <PaymentEditComponent
            paymentId={currentPaymentId}
        />
    )
}

export default PaymentMethodsComponent;