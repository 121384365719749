const countries = [
	{
		id: "e50f8e8f543f4243a744fd1e99d900d2",
		iso1: "AF",
		iso2: "AFG",
		name: "Afghanistan",
	},
	{
		id: "6164b90e520a465494c78330c87d24b5",
		iso1: "AX",
		iso2: "ALA",
		name: "Aland Islands",
	},
	{
		id: "5ed8f065efb648d8820f718abc52ff74",
		iso1: "AL",
		iso2: "ALB",
		name: "Albania",
	},
	{
		id: "84cb2fb5609545a69a61ed2fc5e6751c",
		iso1: "DZ",
		iso2: "DZA",
		name: "Algeria",
	},
	{
		id: "98c55df25199413487f54a2a30a439e3",
		iso1: "AS",
		iso2: "ASM",
		name: "American Samoa",
	},
	{
		id: "8be12fdc1f9f497989431ef517aaba89",
		iso1: "AD",
		iso2: "AND",
		name: "Andorra",
	},
	{
		id: "07821beb2fe14eeea3660a3aadd89d16",
		iso1: "AO",
		iso2: "AGO",
		name: "Angola",
	},
	{
		id: "192fb89ececd4789935e5d2a5d093d68",
		iso1: "AI",
		iso2: "AIA",
		name: "Anguilla",
	},
	{
		id: "19f10b1f3542498a8775926cc120bafe",
		iso1: "AQ",
		iso2: "ATA",
		name: "Antarctica",
	},
	{
		id: "e9bf3f1aa3724794940077e1ab2b2b12",
		iso1: "AG",
		iso2: "ATG",
		name: "Antigua and Barbuda",
	},
	{
		id: "927bcc7a705348a18dc374cc51f0d1cd",
		iso1: "AR",
		iso2: "ARG",
		name: "Argentina",
	},
	{
		id: "1d424dd28ab5479dbafd79a493b7b9ac",
		iso1: "AM",
		iso2: "ARM",
		name: "Armenia",
	},
	{
		id: "1ed92d2bb0c140c7b5124e9cdcf0ca15",
		iso1: "AW",
		iso2: "ABW",
		name: "Aruba",
	},
	{
		id: "e2d7a0f21c69404aaf5c336c009753a8",
		iso1: "AU",
		iso2: "AUS",
		name: "Australia",
	},
	{
		id: "df507d0a498b44b389706cd8ac63a5ae",
		iso1: "AT",
		iso2: "AUT",
		name: "Austria",
	},
	{
		id: "11f84790b4d74ddebb32d46870f4d1a1",
		iso1: "BS",
		iso2: "BHS",
		name: "Bahamas",
	},
	{
		id: "1cc22cafdbe34e75bc6d03e92e6c90c9",
		iso1: "BH",
		iso2: "BHR",
		name: "Bahrain",
	},
	{
		id: "38037c9e66a84c9e9aefef13f7ff225d",
		iso1: "BD",
		iso2: "BGD",
		name: "Bangladesh",
	},
	{
		id: "e5b9efafedc84b55997b9dc80080b2e1",
		iso1: "BB",
		iso2: "BRB",
		name: "Barbados",
	},
	{
		id: "01571711ce6347f9a4dddb6878b3fa31",
		iso1: "BY",
		iso2: "BLR",
		name: "Belarus",
	},
	{
		id: "ec763ed9fffd474a87a2bcd7fe57987c",
		iso1: "BE",
		iso2: "BEL",
		name: "Belgium",
	},
	{
		id: "91e5ede2e7f744ceaa195b7f2dce12df",
		iso1: "BZ",
		iso2: "BLZ",
		name: "Belize",
	},
	{
		id: "daa93e839e10447eaba590f3df0c2026",
		iso1: "BJ",
		iso2: "BEN",
		name: "Benin",
	},
	{
		id: "2111d1f617994280b2adb404a57246cb",
		iso1: "BM",
		iso2: "BMU",
		name: "Bermuda",
	},
	{
		id: "15cda14fd09c4c8b8715d061d3aa1210",
		iso1: "BT",
		iso2: "BTN",
		name: "Bhutan",
	},
	{
		id: "543d763bd1614c8cad039bc3c08a3e55",
		iso1: "BO",
		iso2: "BOL",
		name: "Bolivia (Plurinational State of)",
	},
	{
		id: "e48f042669284df9b06acda5504dbda1",
		iso1: "BQ",
		iso2: "BES",
		name: "Bonaire, Sint Eustatius and Saba",
	},
	{
		id: "0f32f9ee8f32481ca54b26717f4673ce",
		iso1: "BA",
		iso2: "BIH",
		name: "Bosnia and Herzegovina",
	},
	{
		id: "f8594e37dd86485e8fefd3ba3568946f",
		iso1: "BW",
		iso2: "BWA",
		name: "Botswana",
	},
	{
		id: "a44334fc787d4df1af2fd7873804a987",
		iso1: "BV",
		iso2: "BVT",
		name: "Bouvet Island",
	},
	{
		id: "8a63a1be70b747ddb0a6e2493fe97eb7",
		iso1: "BR",
		iso2: "BRA",
		name: "Brazil",
	},
	{
		id: "9171c34212fb440aa8de2b2fc85f6076",
		iso1: "IO",
		iso2: "IOT",
		name: "British Indian Ocean Territory",
	},
	{
		id: "e05fe1e1c65a4934aad8a793198ff43a",
		iso1: "BN",
		iso2: "BRN",
		name: "Brunei Darussalam",
	},
	{
		id: "4088ce7495d5481fb7b5e9defb380a12",
		iso1: "BG",
		iso2: "BGR",
		name: "Bulgaria",
	},
	{
		id: "f05d40e00a3f42268c02c1ed4fda0d80",
		iso1: "BF",
		iso2: "BFA",
		name: "Burkina Faso",
	},
	{
		id: "8c085bee0c4a4e3dbbb3e255789a6459",
		iso1: "BI",
		iso2: "BDI",
		name: "Burundi",
	},
	{
		id: "510b612e3c8c454d9b5bef13674a44e2",
		iso1: "CV",
		iso2: "CPV",
		name: "Cabo Verde",
	},
	{
		id: "67f656d3951c404389dd2a562d372beb",
		iso1: "KH",
		iso2: "KHM",
		name: "Cambodia",
	},
	{
		id: "a1396c33940c4cbf858091e307ca0a1b",
		iso1: "CM",
		iso2: "CMR",
		name: "Cameroon",
	},
	{
		id: "2e2f6a8232f7494ba2d05e90b1163ac1",
		iso1: "CA",
		iso2: "CAN",
		name: "Canada",
	},
	{
		id: "126e190775ec48168cb57557d58ca58c",
		iso1: "KY",
		iso2: "CYM",
		name: "Cayman Islands",
	},
	{
		id: "7c74265573a1481cb17163ab5ef0463a",
		iso1: "CF",
		iso2: "CAF",
		name: "Central African Republic",
	},
	{
		id: "30787fe496b04f0092c2f681bb2a062b",
		iso1: "TD",
		iso2: "TCD",
		name: "Chad",
	},
	{
		id: "ebe043e3a6f34b6ca25e95d79de0dae5",
		iso1: "CL",
		iso2: "CHL",
		name: "Chile",
	},
	{
		id: "b79d704d8ba04df081e768cdfa782c6b",
		iso1: "CN",
		iso2: "CHN",
		name: "China",
	},
	{
		id: "3a6ba27259054907979b5e9fe8541d20",
		iso1: "CX",
		iso2: "CXR",
		name: "Christmas Island",
	},
	{
		id: "d68f93afd7844a3d97f7ba13d4a55bc3",
		iso1: "CC",
		iso2: "CCK",
		name: "Cocos (Keeling) Islands",
	},
	{
		id: "1c45263d9f00460cae57fc7030876c46",
		iso1: "CO",
		iso2: "COL",
		name: "Colombia",
	},
	{
		id: "66f006d3a57d42e3bb6aa1dde0373fcc",
		iso1: "KM",
		iso2: "COM",
		name: "Comoros",
	},
	{
		id: "06cd9bc49286442389697c3a4b722502",
		iso1: "CG",
		iso2: "COG",
		name: "Congo",
	},
	{
		id: "a14397c12044456da06553a4ac43be0a",
		iso1: "CD",
		iso2: "COD",
		name: "Congo  Democratic Republic of the",
	},
	{
		id: "836b12078fb0455fb74fecea4f61e124",
		iso1: "CK",
		iso2: "COK",
		name: "Cook Islands",
	},
	{
		id: "0f320d341b794b5091435409aacfb1a3",
		iso1: "CR",
		iso2: "CRI",
		name: "Costa Rica",
	},
	{
		id: "820c1d686c934ae6b6154684aee4806d",
		iso1: "HR",
		iso2: "HRV",
		name: "Croatia",
	},
	{
		id: "e8be5ec501b342c598d6385819a780f8",
		iso1: "CU",
		iso2: "CUB",
		name: "Cuba",
	},
	{
		id: "6350bccd75b64239b3faac2dded3e1e2",
		iso1: "CW",
		iso2: "CUW",
		name: "Curaçao",
	},
	{
		id: "4ec25a2a3b194a2b9b244625304e69b5",
		iso1: "CY",
		iso2: "CYP",
		name: "Cyprus",
	},
	{
		id: "629ae789a2114bca9f64a969d7ac0e77",
		iso1: "CZ",
		iso2: "CZE",
		name: "Czechia",
	},
	{
		id: "27534693e6f54d109c51e2e4041d4136",
		iso1: "CI",
		iso2: "CIV",
		name: "Côte d'Ivoire",
	},
	{
		id: "e3188dccb1e34830892b07b10a966dea",
		iso1: "DK",
		iso2: "DNK",
		name: "Denmark",
	},
	{
		id: "24c7a583dd56432db325d6a657823055",
		iso1: "DJ",
		iso2: "DJI",
		name: "Djibouti",
	},
	{
		id: "ef8824e0ae7345f68d053da3ef2e65ab",
		iso1: "DM",
		iso2: "DMA",
		name: "Dominica",
	},
	{
		id: "c9ce0143c95e4d9d90932ca1f4c5f73b",
		iso1: "DO",
		iso2: "DOM",
		name: "Dominican Republic",
	},
	{
		id: "484db78b44d845beaefcf8d709b4fd4b",
		iso1: "EC",
		iso2: "ECU",
		name: "Ecuador",
	},
	{
		id: "e83d9b2eae044980a7b774b2a3cb842b",
		iso1: "EG",
		iso2: "EGY",
		name: "Egypt",
	},
	{
		id: "64ea652e6d3d44d285f4f1d822270b2b",
		iso1: "SV",
		iso2: "SLV",
		name: "El Salvador",
	},
	{
		id: "178d3c59e22349e6aefc4afe4c00a40a",
		iso1: "GQ",
		iso2: "GNQ",
		name: "Equatorial Guinea",
	},
	{
		id: "145032ed933f4be7808e32832ef10b3b",
		iso1: "ER",
		iso2: "ERI",
		name: "Eritrea",
	},
	{
		id: "694cf3da998245bbb8b39d4a0c71123e",
		iso1: "EE",
		iso2: "EST",
		name: "Estonia",
	},
	{
		id: "cc7c4644971548d7bb04bf0444990e24",
		iso1: "SZ",
		iso2: "SWZ",
		name: "Eswatini",
	},
	{
		id: "4047b76185d745b5b15eeee090b864cd",
		iso1: "ET",
		iso2: "ETH",
		name: "Ethiopia",
	},
	{
		id: "be04475b2f3448a2a03c2709f74e5cbd",
		iso1: "FK",
		iso2: "FLK",
		name: "Falkland Islands (Malvinas)",
	},
	{
		id: "624d9bcf3545442bab70665e709e76b2",
		iso1: "FO",
		iso2: "FRO",
		name: "Faroe Islands",
	},
	{
		id: "1cb07516467e455f9c48c649ce7e45e6",
		iso1: "FJ",
		iso2: "FJI",
		name: "Fiji",
	},
	{
		id: "02463c9db7c048ce950472a716d6bf43",
		iso1: "FI",
		iso2: "FIN",
		name: "Finland",
	},
	{
		id: "a39410fdc57e4498a0060b7aa1a78278",
		iso1: "FR",
		iso2: "FRA",
		name: "France",
	},
	{
		id: "15227e6b96364db790397ea5f2c4a41b",
		iso1: "GF",
		iso2: "GUF",
		name: "French Guiana",
	},
	{
		id: "a57375766ac84792b3896afdd375718f",
		iso1: "PF",
		iso2: "PYF",
		name: "French Polynesia",
	},
	{
		id: "45314f4560f84c729a262102630f9493",
		iso1: "TF",
		iso2: "ATF",
		name: "French Southern Territories",
	},
	{
		id: "f4fa29c823934ce19a8df38a6b2ab9f1",
		iso1: "GA",
		iso2: "GAB",
		name: "Gabon",
	},
	{
		id: "99d8ab86bfdf4e4ca764b8bf1ad36a12",
		iso1: "GM",
		iso2: "GMB",
		name: "Gambia",
	},
	{
		id: "2ce37db56438465bb4b8e211e6606a1a",
		iso1: "GE",
		iso2: "GEO",
		name: "Georgia",
	},
	{
		id: "97469b222d36477a897b577b580eecf9",
		iso1: "DE",
		iso2: "DEU",
		name: "Germany",
	},
	{
		id: "570b2cef9f98475c8c1c400608ffca94",
		iso1: "GH",
		iso2: "GHA",
		name: "Ghana",
	},
	{
		id: "73e796ab731d44359fd48cdc260b9d41",
		iso1: "GI",
		iso2: "GIB",
		name: "Gibraltar",
	},
	{
		id: "c223e65a2dcd4eefb4012ed521cabcc1",
		iso1: "GR",
		iso2: "GRC",
		name: "Greece",
	},
	{
		id: "b8d022e8672340c7bebbd3d9885fa526",
		iso1: "GL",
		iso2: "GRL",
		name: "Greenland",
	},
	{
		id: "2d93068376f247619df67535b71995aa",
		iso1: "GD",
		iso2: "GRD",
		name: "Grenada",
	},
	{
		id: "dbef1230e9e641c0ab4d2005b2a94428",
		iso1: "GP",
		iso2: "GLP",
		name: "Guadeloupe",
	},
	{
		id: "3cafd837a358403fba25c0518b431f52",
		iso1: "GU",
		iso2: "GUM",
		name: "Guam",
	},
	{
		id: "e7e82fd2c88d42d8910f5954538835bb",
		iso1: "GT",
		iso2: "GTM",
		name: "Guatemala",
	},
	{
		id: "bd7563ec1127494f85f197b215490e29",
		iso1: "GG",
		iso2: "GGY",
		name: "Guernsey",
	},
	{
		id: "b69fd011bf7240da8c3c6dd22c35ef55",
		iso1: "GN",
		iso2: "GIN",
		name: "Guinea",
	},
	{
		id: "cc4efa1ea73c4ca490acc11a7b70e081",
		iso1: "GW",
		iso2: "GNB",
		name: "Guinea-Bissau",
	},
	{
		id: "0694050dc5b6474b8399171fbf994b92",
		iso1: "GY",
		iso2: "GUY",
		name: "Guyana",
	},
	{
		id: "22aec69a13f74681b30781f1df34884c",
		iso1: "HT",
		iso2: "HTI",
		name: "Haiti",
	},
	{
		id: "3b7304df9364403a84b66588ce7e85d5",
		iso1: "HM",
		iso2: "HMD",
		name: "Heard Island and McDonald Islands",
	},
	{
		id: "ef8a064b47f24b2d84c0988e2e2f41eb",
		iso1: "VA",
		iso2: "VAT",
		name: "Holy See",
	},
	{
		id: "5c8e93fa2aaf43eca22e51562baafda2",
		iso1: "HN",
		iso2: "HND",
		name: "Honduras",
	},
	{
		id: "13c42375e6964203b03a1fe0b023931d",
		iso1: "HK",
		iso2: "HKG",
		name: "Hong Kong",
	},
	{
		id: "9434b96684d64ef98fb58433e12aa670",
		iso1: "HU",
		iso2: "HUN",
		name: "Hungary",
	},
	{
		id: "e78ce9e606f141669ff05c88ec013abc",
		iso1: "IS",
		iso2: "ISL",
		name: "Iceland",
	},
	{
		id: "4042f08e0490457ba0993504eefae645",
		iso1: "IN",
		iso2: "IND",
		name: "India",
	},
	{
		id: "c5866a48210b4ab38b869f415d618768",
		iso1: "ID",
		iso2: "IDN",
		name: "Indonesia",
	},
	{
		id: "020df47de9c748b0914b74decd7a56d5",
		iso1: "IR",
		iso2: "IRN",
		name: "Iran (Islamic Republic of)",
	},
	{
		id: "fdd87b116b094b7099f9f45132d1ee9a",
		iso1: "IQ",
		iso2: "IRQ",
		name: "Iraq",
	},
	{
		id: "63627938c16143d7a5c785a544b5394b",
		iso1: "IE",
		iso2: "IRL",
		name: "Ireland",
	},
	{
		id: "b96688dc5be741508e676257ab18ef5a",
		iso1: "IM",
		iso2: "IMN",
		name: "Isle of Man",
	},
	{
		id: "8d96ce6067624073a92992d2681a91c6",
		iso1: "IL",
		iso2: "ISR",
		name: "Israel",
	},
	{
		id: "5bf0c7169a7b45628f35a02b16e41bf8",
		iso1: "IT",
		iso2: "ITA",
		name: "Italy",
	},
	{
		id: "370d1675fded47779b825b24b57fa7a3",
		iso1: "JM",
		iso2: "JAM",
		name: "Jamaica",
	},
	{
		id: "90a5a0ac8bd54e64b1523960cde43b8d",
		iso1: "JP",
		iso2: "JPN",
		name: "Japan",
	},
	{
		id: "0c80f9c2d0a64b6f9800b8d14c722047",
		iso1: "JE",
		iso2: "JEY",
		name: "Jersey",
	},
	{
		id: "f1e97ea930294065991fc51438934d8a",
		iso1: "JO",
		iso2: "JOR",
		name: "Jordan",
	},
	{
		id: "d4063fbd96284792b549bcc2f7f69c29",
		iso1: "KZ",
		iso2: "KAZ",
		name: "Kazakhstan",
	},
	{
		id: "ee06ac467ab54bcbb5343f8f190f8002",
		iso1: "KE",
		iso2: "KEN",
		name: "Kenya",
	},
	{
		id: "0da37ea1b5d84e0b9bb50627e7191d9d",
		iso1: "KI",
		iso2: "KIR",
		name: "Kiribati",
	},
	{
		id: "5ceac139b98d41b2a99a6b4fcb43640d",
		iso1: "KP",
		iso2: "PRK",
		name: "Korea (Democratic People's Republic of)",
	},
	{
		id: "8fd93a7345a2419c964e43180ab2fb3b",
		iso1: "KR",
		iso2: "KOR",
		name: "Korea, Republic of",
	},
	{
		id: "2dc662523eaf4fa4b926b390c0c5eed5",
		iso1: "KW",
		iso2: "KWT",
		name: "Kuwait",
	},
	{
		id: "425e7fa5f0204503930f33c26bec0137",
		iso1: "KG",
		iso2: "KGZ",
		name: "Kyrgyzstan",
	},
	{
		id: "7c5fafe5f5514751978c542383ec9814",
		iso1: "LA",
		iso2: "LAO",
		name: "Lao People's Democratic Republic",
	},
	{
		id: "76ff329982f34f8f8cb381a0d71e80f8",
		iso1: "LV",
		iso2: "LVA",
		name: "Latvia",
	},
	{
		id: "b721c605a82f47f5875596678b61276b",
		iso1: "LB",
		iso2: "LBN",
		name: "Lebanon",
	},
	{
		id: "5e443681e65141bcb2ea2db20c44ea1c",
		iso1: "LS",
		iso2: "LSO",
		name: "Lesotho",
	},
	{
		id: "8616f262083540ddae8ea4185d571b1a",
		iso1: "LR",
		iso2: "LBR",
		name: "Liberia",
	},
	{
		id: "701d07ff3a374c5787574658cdfe8d79",
		iso1: "LY",
		iso2: "LBY",
		name: "Libya",
	},
	{
		id: "d71cbc7395f643e8b6b9d3d5ea107115",
		iso1: "LI",
		iso2: "LIE",
		name: "Liechtenstein",
	},
	{
		id: "7d45f57d8967416cbf2e1a1d1ccf408e",
		iso1: "LT",
		iso2: "LTU",
		name: "Lithuania",
	},
	{
		id: "6c3de328fd804e4caed3effe74b99e45",
		iso1: "LU",
		iso2: "LUX",
		name: "Luxembourg",
	},
	{
		id: "15e9a147849f47bea45211864c01bad7",
		iso1: "MO",
		iso2: "MAC",
		name: "Macao",
	},
	{
		id: "0fa7ca969b20419191018ea3e517e11b",
		iso1: "MG",
		iso2: "MDG",
		name: "Madagascar",
	},
	{
		id: "12a2d26e445745a28c8036e3e6fad6bd",
		iso1: "MW",
		iso2: "MWI",
		name: "Malawi",
	},
	{
		id: "152f0d0bbf2a4cbe86eaeb489ca25b24",
		iso1: "MY",
		iso2: "MYS",
		name: "Malaysia",
	},
	{
		id: "6da6af07554c43a2ba7de20641168ee8",
		iso1: "MV",
		iso2: "MDV",
		name: "Maldives",
	},
	{
		id: "d47d4c282cd4418ab09f04213f78242a",
		iso1: "ML",
		iso2: "MLI",
		name: "Mali",
	},
	{
		id: "9add38c862684366b5d1c16a56b6e7a0",
		iso1: "MT",
		iso2: "MLT",
		name: "Malta",
	},
	{
		id: "5524803cf3ba4647b239e2d1b0762c33",
		iso1: "MH",
		iso2: "MHL",
		name: "Marshall Islands",
	},
	{
		id: "c600600c3df142a39349b1ddd3ff17c5",
		iso1: "MQ",
		iso2: "MTQ",
		name: "Martinique",
	},
	{
		id: "28e7271778b54fb4bea078a69c00c7b1",
		iso1: "MR",
		iso2: "MRT",
		name: "Mauritania",
	},
	{
		id: "50f89009c84448f0b9a7be62145be664",
		iso1: "MU",
		iso2: "MUS",
		name: "Mauritius",
	},
	{
		id: "8cebd2a2d6484ae29f41b21410ae986b",
		iso1: "YT",
		iso2: "MYT",
		name: "Mayotte",
	},
	{
		id: "a7f4672c9f14447ea621d909125c1e25",
		iso1: "MX",
		iso2: "MEX",
		name: "Mexico",
	},
	{
		id: "ef0ebb7670104a5f8841c7d08a2862cb",
		iso1: "FM",
		iso2: "FSM",
		name: "Micronesia (Federated States of)",
	},
	{
		id: "cf838fa50b0248e4bc2b70f31a2e80f0",
		iso1: "MD",
		iso2: "MDA",
		name: "Moldova, Republic of",
	},
	{
		id: "b5def95256bd4ae7979e8c47548bb725",
		iso1: "MC",
		iso2: "MCO",
		name: "Monaco",
	},
	{
		id: "fc32e54ace004a978fe639ce02e5e9d8",
		iso1: "MN",
		iso2: "MNG",
		name: "Mongolia",
	},
	{
		id: "ea87bbacd3e849c4a96fa9521121b2d9",
		iso1: "ME",
		iso2: "MNE",
		name: "Montenegro",
	},
	{
		id: "3b4f7dc2b44942a396cde83bd261541c",
		iso1: "MS",
		iso2: "MSR",
		name: "Montserrat",
	},
	{
		id: "c5d4d307225843dd988f0abb1d0ddf9d",
		iso1: "MA",
		iso2: "MAR",
		name: "Morocco",
	},
	{
		id: "6bbe8908548443b9a71bdebdfcfc46df",
		iso1: "MZ",
		iso2: "MOZ",
		name: "Mozambique",
	},
	{
		id: "6e4a5734f2df445d8a34ba99d8117601",
		iso1: "MM",
		iso2: "MMR",
		name: "Myanmar",
	},
	{
		id: "e353d98f19f445b2a986619f9a93c0eb",
		iso1: "NA",
		iso2: "NAM",
		name: "Namibia",
	},
	{
		id: "88f2a74e879e477d9089f38fc6627d16",
		iso1: "NR",
		iso2: "NRU",
		name: "Nauru",
	},
	{
		id: "5531113ac91042dbaedf26842898e710",
		iso1: "NP",
		iso2: "NPL",
		name: "Nepal",
	},
	{
		id: "153fc686686e48569a36c1443e86dcf2",
		iso1: "NL",
		iso2: "NLD",
		name: "Netherlands",
	},
	{
		id: "e54ce831893749e9b0b73f97715855f1",
		iso1: "NC",
		iso2: "NCL",
		name: "New Caledonia",
	},
	{
		id: "36ceedd4309b4422ad38ed8f323efb03",
		iso1: "NZ",
		iso2: "NZL",
		name: "New Zealand",
	},
	{
		id: "b21c78e36be04709914b0bbf334d00bb",
		iso1: "NI",
		iso2: "NIC",
		name: "Nicaragua",
	},
	{
		id: "df792582c292414ab91154eee0f8102e",
		iso1: "NE",
		iso2: "NER",
		name: "Niger",
	},
	{
		id: "44d5b1d335cf4ed78aadcdfef79c7f67",
		iso1: "NG",
		iso2: "NGA",
		name: "Nigeria",
	},
	{
		id: "4234c02b6548405bb162aec44139a313",
		iso1: "NU",
		iso2: "NIU",
		name: "Niue",
	},
	{
		id: "57804c18905248918122fc713ae77ece",
		iso1: "NF",
		iso2: "NFK",
		name: "Norfolk Island",
	},
	{
		id: "fe4edadb99d5416cba9f9f732dcc5a1c",
		iso1: "MK",
		iso2: "MKD",
		name: "North Macedonia",
	},
	{
		id: "f58dd13123b947d7b9ac0f46c3aa17b4",
		iso1: "MP",
		iso2: "MNP",
		name: "Northern Mariana Islands",
	},
	{
		id: "acfbc4baf0924e589b0216ca0164fa51",
		iso1: "NO",
		iso2: "NOR",
		name: "Norway",
	},
	{
		id: "ad6f8f6011b0428aa2e05d603bb42da5",
		iso1: "OM",
		iso2: "OMN",
		name: "Oman",
	},
	{
		id: "73fea1e5d2cb4032817f3b5d4e8aa731",
		iso1: "PK",
		iso2: "PAK",
		name: "Pakistan",
	},
	{
		id: "e7f8c8efd9404689875aaedad965656d",
		iso1: "PW",
		iso2: "PLW",
		name: "Palau",
	},
	{
		id: "f897a8bc1c8c446aa2d426ad913e430a",
		iso1: "PS",
		iso2: "PSE",
		name: "Palestine, State of",
	},
	{
		id: "f5625d151f6d496f91f8eedfe7e0fa3f",
		iso1: "PA",
		iso2: "PAN",
		name: "Panama",
	},
	{
		id: "13b2af6fe40843bb8c812e94a7ab3cb3",
		iso1: "PG",
		iso2: "PNG",
		name: "Papua New Guinea",
	},
	{
		id: "c4bbe80b6e97477597244801b3548d59",
		iso1: "PY",
		iso2: "PRY",
		name: "Paraguay",
	},
	{
		id: "18384b877d5241a59750f0c750c7c3a0",
		iso1: "PE",
		iso2: "PER",
		name: "Peru",
	},
	{
		id: "c1a1f139c2db4526b31f5c696ce907ff",
		iso1: "PH",
		iso2: "PHL",
		name: "Philippines",
	},
	{
		id: "c3de87f9df864642832d23ce061fb047",
		iso1: "PN",
		iso2: "PCN",
		name: "Pitcairn",
	},
	{
		id: "c75ea3fe05764f03b5682e5918e3dfcc",
		iso1: "PL",
		iso2: "POL",
		name: "Poland",
	},
	{
		id: "4cd932404c4447989908c27c426d2814",
		iso1: "PT",
		iso2: "PRT",
		name: "Portugal",
	},
	{
		id: "58c17eff7de646aca4ce40049355218c",
		iso1: "PR",
		iso2: "PRI",
		name: "Puerto Rico",
	},
	{
		id: "a9855b9023614e508d29f18867fd1073",
		iso1: "QA",
		iso2: "QAT",
		name: "Qatar",
	},
	{
		id: "c8de23597aca40b69398f7ba0fadf74a",
		iso1: "RO",
		iso2: "ROU",
		name: "Romania",
	},
	{
		id: "405332a12b5342dfb7904e56dca03878",
		iso1: "RU",
		iso2: "RUS",
		name: "Russian Federation",
	},
	{
		id: "70037162abab4109b20c32c12e90bf98",
		iso1: "RW",
		iso2: "RWA",
		name: "Rwanda",
	},
	{
		id: "0adc2d9c71c845b6b6c70c7c29c09532",
		iso1: "RE",
		iso2: "REU",
		name: "Réunion",
	},
	{
		id: "b08f92a9afa44ee4adc4d21c91bafd47",
		iso1: "BL",
		iso2: "BLM",
		name: "Saint Barthélemy",
	},
	{
		id: "ef2cfcfab9344524a54cb20d915eb4ae",
		iso1: "SH",
		iso2: "SHN",
		name: "Saint Helena, Ascension and Tristan da Cunha",
	},
	{
		id: "cdbcb5d3c5574f43b1c6a71b3aa3d2eb",
		iso1: "KN",
		iso2: "KNA",
		name: "Saint Kitts and Nevis",
	},
	{
		id: "30caf6d470ed4d7faa3034cbcbf39330",
		iso1: "LC",
		iso2: "LCA",
		name: "Saint Lucia",
	},
	{
		id: "6fe62170c5a845d3aea7122ce0eea0c0",
		iso1: "MF",
		iso2: "MAF",
		name: "Saint Martin (French part)",
	},
	{
		id: "9d4091ccdf0a4731808ce0405fb450ed",
		iso1: "PM",
		iso2: "SPM",
		name: "Saint Pierre and Miquelon",
	},
	{
		id: "96b7249510134c83ac56e270089d4114",
		iso1: "VC",
		iso2: "VCT",
		name: "Saint Vincent and the Grenadines",
	},
	{
		id: "b28c37e4329244d3a7508c3e8d03fd5e",
		iso1: "WS",
		iso2: "WSM",
		name: "Samoa",
	},
	{
		id: "a2a1f273cb274538a2efc8fdbc8b89e0",
		iso1: "SM",
		iso2: "SMR",
		name: "San Marino",
	},
	{
		id: "eb248899d7bd47379b327215a6201035",
		iso1: "ST",
		iso2: "STP",
		name: "Sao Tome and Principe",
	},
	{
		id: "2151e3caca6e4b8993f752ca5aef3f60",
		iso1: "SA",
		iso2: "SAU",
		name: "Saudi Arabia",
	},
	{
		id: "44b7a979505d4575a3573199974daf0d",
		iso1: "SN",
		iso2: "SEN",
		name: "Senegal",
	},
	{
		id: "b64c770a31044e3f8fb2ab14f9e2e7fb",
		iso1: "RS",
		iso2: "SRB",
		name: "Serbia",
	},
	{
		id: "04a33f528ae846cf8ee4c5c99d9ddd3d",
		iso1: "SC",
		iso2: "SYC",
		name: "Seychelles",
	},
	{
		id: "c78ce695b35b4a169cb7d12b7ade9b6d",
		iso1: "SL",
		iso2: "SLE",
		name: "Sierra Leone",
	},
	{
		id: "2dea0be3c990435b8de02a2271651521",
		iso1: "SG",
		iso2: "SGP",
		name: "Singapore",
	},
	{
		id: "de55e45f6dcd46a4a062cc53b6d9c99c",
		iso1: "SX",
		iso2: "SXM",
		name: "Sint Maarten (Dutch part)",
	},
	{
		id: "7683c8de0d8448988ed637ced140ee2e",
		iso1: "SK",
		iso2: "SVK",
		name: "Slovakia",
	},
	{
		id: "132159de3c9c408cac1519ddd14af8ec",
		iso1: "SI",
		iso2: "SVN",
		name: "Slovenia",
	},
	{
		id: "d7350357bb574b7db81f838ebb823a94",
		iso1: "SB",
		iso2: "SLB",
		name: "Solomon Islands",
	},
	{
		id: "7d112902e75c4784ac7249c771f9961b",
		iso1: "SO",
		iso2: "SOM",
		name: "Somalia",
	},
	{
		id: "5722ee7294c04e88bcbc6ccfa9231b5f",
		iso1: "ZA",
		iso2: "ZAF",
		name: "South Africa",
	},
	{
		id: "485126a431cc4e0da82e8640d67d2ca1",
		iso1: "GS",
		iso2: "SGS",
		name: "South Georgia and the South Sandwich Islands",
	},
	{
		id: "4e993a479d8947c086cb053b939ec783",
		iso1: "SS",
		iso2: "SSD",
		name: "South Sudan",
	},
	{
		id: "0dbd6b94e66b4241bed9e0d4011e9bee",
		iso1: "ES",
		iso2: "ESP",
		name: "Spain",
	},
	{
		id: "c6b479d84cde41488305005c0f29307f",
		iso1: "LK",
		iso2: "LKA",
		name: "Sri Lanka",
	},
	{
		id: "55f8572509d545cc967f075b2431763e",
		iso1: "SD",
		iso2: "SDN",
		name: "Sudan",
	},
	{
		id: "4f4ec12c1b384af987b5a2a62415e189",
		iso1: "SR",
		iso2: "SUR",
		name: "Suriname",
	},
	{
		id: "44170b17bd174e68a4a1f3e8294b4456",
		iso1: "SJ",
		iso2: "SJM",
		name: "Svalbard and Jan Mayen",
	},
	{
		id: "afefefe0d9d24b83bfa01d723fa8baa2",
		iso1: "SE",
		iso2: "SWE",
		name: "Sweden",
	},
	{
		id: "dbbfc500fedb4f87a5e79e38528c0fb9",
		iso1: "CH",
		iso2: "CHE",
		name: "Switzerland",
	},
	{
		id: "9e426394065145aab1d68236ac4b936e",
		iso1: "SY",
		iso2: "SYR",
		name: "Syrian Arab Republic",
	},
	{
		id: "b1ba9ff76dc845fdb2ea78cbe319b8d3",
		iso1: "TW",
		iso2: "TWN",
		name: "Taiwan, Province of China",
	},
	{
		id: "6d02b6b2fefb481086c6d6e1ab03e06f",
		iso1: "TJ",
		iso2: "TJK",
		name: "Tajikistan",
	},
	{
		id: "8d2f64d4b6e54393b53f009de8c0611d",
		iso1: "TZ",
		iso2: "TZA",
		name: "Tanzania, United Republic of",
	},
	{
		id: "8731de67c95445dbad0bef1d917236d5",
		iso1: "TH",
		iso2: "THA",
		name: "Thailand",
	},
	{
		id: "1f2e622d45f047f898482f67af796472",
		iso1: "TL",
		iso2: "TLS",
		name: "Timor-Leste",
	},
	{
		id: "16f8e9fccd164296ac912e87f9994e48",
		iso1: "TG",
		iso2: "TGO",
		name: "Togo",
	},
	{
		id: "9324d985a8a846c6b88894870bdede83",
		iso1: "TK",
		iso2: "TKL",
		name: "Tokelau",
	},
	{
		id: "85cbcf76203d4f29a2fcf759665b7b61",
		iso1: "TO",
		iso2: "TON",
		name: "Tonga",
	},
	{
		id: "b10ae2f99b4b4eb28e7090a8177f3496",
		iso1: "TT",
		iso2: "TTO",
		name: "Trinidad and Tobago",
	},
	{
		id: "6a051332432f40d38c575f35f968728a",
		iso1: "TN",
		iso2: "TUN",
		name: "Tunisia",
	},
	{
		id: "befbe989b6bb49a391ff532b4c0eefca",
		iso1: "TR",
		iso2: "TUR",
		name: "Turkey",
	},
	{
		id: "007d6fe6bd38461dbaafb40753e99b27",
		iso1: "TM",
		iso2: "TKM",
		name: "Turkmenistan",
	},
	{
		id: "1120ca2c57a9482e8ed26814e7bd872a",
		iso1: "TC",
		iso2: "TCA",
		name: "Turks and Caicos Islands",
	},
	{
		id: "59b6140cf4934917a9b9e6e58b74f65f",
		iso1: "TV",
		iso2: "TUV",
		name: "Tuvalu",
	},
	{
		id: "4de3f6a8634944fda99de6fdcb977e95",
		iso1: "UG",
		iso2: "UGA",
		name: "Uganda",
	},
	{
		id: "df6a43d243f54950820a86d0c1b06b82",
		iso1: "UA",
		iso2: "UKR",
		name: "Ukraine",
	},
	{
		id: "db349cb2aed443ae9122e4b4d56f2262",
		iso1: "AE",
		iso2: "ARE",
		name: "United Arab Emirates",
	},
	{
		id: "0b6f98123c3d4e2f926c8ad80874f932",
		iso1: "GB",
		iso2: "GBR",
		name: "United Kingdom of Great Britain and Northern Ireland",
	},
	{
		id: "d607887950194a599db3da8fa1d8cb7a",
		iso1: "UM",
		iso2: "UMI",
		name: "United States Minor Outlying Islands",
	},
	{
		id: "ca35aa88341d46edb4ed32da828f9446",
		iso1: "US",
		iso2: "USA",
		name: "United States of America",
	},
	{
		id: "d85249c8dbd5454a99fb3d457bafda54",
		iso1: "UY",
		iso2: "URY",
		name: "Uruguay",
	},
	{
		id: "cbbc336c233a4310aa8f964f89580006",
		iso1: "UZ",
		iso2: "UZB",
		name: "Uzbekistan",
	},
	{
		id: "bee686ba9ff348d998403359c01fd502",
		iso1: "VU",
		iso2: "VUT",
		name: "Vanuatu",
	},
	{
		id: "af8460fc3a44494197806d7a0d3d2538",
		iso1: "VE",
		iso2: "VEN",
		name: "Venezuela (Bolivarian Republic of)",
	},
	{
		id: "646f87e016c94e7f89df00abe30e2cec",
		iso1: "VN",
		iso2: "VNM",
		name: "Viet Nam",
	},
	{
		id: "2e94247639fb4ae7bdc640b471a4df75",
		iso1: "VG",
		iso2: "VGB",
		name: "Virgin Islands (British)",
	},
	{
		id: "4d08b01800e84d7d8fb36af2cc083f97",
		iso1: "VI",
		iso2: "VIR",
		name: "Virgin Islands (U.S.)",
	},
	{
		id: "0486bbe6ad34494e8a99cccc77b98abc",
		iso1: "WF",
		iso2: "WLF",
		name: "Wallis and Futuna",
	},
	{
		id: "e580a4911a5a4f2aa68efc17342b974f",
		iso1: "EH",
		iso2: "ESH",
		name: "Western Sahara",
	},
	{
		id: "5a3394ccc2d14872bc66851c658efd64",
		iso1: "YE",
		iso2: "YEM",
		name: "Yemen",
	},
	{
		id: "748fbe36777341e8a5d51f9babc4ff32",
		iso1: "ZM",
		iso2: "ZMB",
		name: "Zambia",
	},
	{
		id: "173a37f317894904a38c34d3356ed6c8",
		iso1: "ZW",
		iso2: "ZWE",
		name: "Zimbabwe",
	},
];

export default countries;
