import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { 
    setNewsletterActionBefore, 
    setNewsletterActionFinished
} from "./newsletter.action";

export const unsubscribeFromNewsletter = (token, onSuccess) => {
    return dispatch => {
        dispatch(setNewsletterActionBefore());
        return axios({
            url: ApiUrls.UNSUBSCRIBE_FROM_NEWSLETTER,
            method: Methods.GET,
            params: {
                token
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                onSuccess && onSuccess();
            } 
            dispatch(setNewsletterActionFinished());
        })
        .catch((ex) => {
            dispatch(setNewsletterActionFinished());
        })
    }
}
