import i18n from "translations/config";

export const columns = [
    {
        title: "backoffice.communication.newsletterName",
        dataIndex: "name",
        sorter: true,
        alwaysVisible: true,
        mobileLevel: 1
    },
    {
        title: "backoffice.communication.language",
        dataIndex: "languageCode",
        mobileLevel: 2,
        render: value => value ? i18n.t(`backoffice.languages.${value.toLowerCase()}`) : "-"
    },
    {
        title: "backoffice.communication.createdAt",
        dataIndex: "createdAt",
        isDateTime: true,
        sorter: true,
        mobileLevel: 3
    },
    {
        title: "backoffice.communication.createdBy",
        dataIndex: "createdBy",
        sorter: true,
        mobileLevel: 4
    },
    {
        title: "backoffice.communication.modifiedAt",
        dataIndex: "modifiedAt",
        isDateTime: true,
        sorter: true,
        mobileLevel: 5
    },
    {
        title: "backoffice.communication.modifiedBy",
        dataIndex: "modifiedBy",
        sorter: true,
        mobileLevel: 6,
        render: value => value || "-",
    },

];