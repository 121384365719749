import React from 'react';

import ActivateComponent from 'components/auth/activate/activate.component';
import withAnanymous from 'hocs/withAnanymous';

const ActivateRoute = () => {
    return (
        <ActivateComponent /> 
    )
}

export default withAnanymous(ActivateRoute);