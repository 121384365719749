import axios from 'axios';

import Methods from 'constants/methods.constants';
import ApiUrls from 'constants/api.constants';

import {
    SET_NOTIFICATIONS_ACTION_BEFORE,
    SET_NOTIFICATIONS_ACTION_FINISH,
    SET_NOTIFICATIONS,
    ADD_NOTIFICATION,
    SET_NOTIFICATION_SOUND,
    DELETE_NOTIFICATION,
    MARK_AS_READ_NOTIFICATION
} from '../../../actionTypes';


const setNotificationsActionBefore = () => ({
    type: SET_NOTIFICATIONS_ACTION_BEFORE
})

const setNotificationsActionFinished = () => ({
    type: SET_NOTIFICATIONS_ACTION_FINISH
})

const setNotifications = notifications => ({
    type: SET_NOTIFICATIONS,
    payload: { notifications }
})

const deleteNotificationSuccess = id => ({
    type: DELETE_NOTIFICATION,
    payload: { id }
})

const markNotificationAsReadSuccess = id => ({
    type: MARK_AS_READ_NOTIFICATION,
    payload: { id }
})

export const setNotificationSound = show => ({
    type: SET_NOTIFICATION_SOUND,
    payload: { show }
})

export const addNotification = notification => ({
    type: ADD_NOTIFICATION,
    payload: { notification }
})


export const getNotifications = () => {
    return dispatch => {
        dispatch(setNotificationsActionBefore());

        return axios({
            url: ApiUrls.GET_NOTIFICATIONS,
            method: Methods.GET
        })
        .then(({data : {value: notifications}}) => {
            dispatch(setNotifications(notifications));
            dispatch(setNotificationsActionFinished());
        })
        .catch(() => {
            dispatch(setNotificationsActionFinished());
        })
    }
}

export const markNotificationAsRead = id => {
    return dispatch => {
        dispatch(markNotificationAsReadSuccess(id));
        return axios({
            url: ApiUrls.MARK_AS_READ_NOTIFICATION,
            method: Methods.POST,
            data: { id }
        })
        .then(({ data }) => {
            console.log(data)
        })
        .catch((ex) => {
            console.log(ex);
        })
    }
}

export const deleteNotification = id => {
    return dispatch => {
        dispatch(deleteNotificationSuccess(id));
        return axios({
            url: ApiUrls.DELETE_NOTIFICATION,
            method: Methods.DELETE,
            data: { id }
        })
        .then(({ data }) => {
            console.log(data)
        })
        .catch((ex) => {
            console.log(ex);
        })
    }
}

