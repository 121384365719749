import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from 'utils/common';

const MenuItem = ({ onItemClick, isActive, text, hasUnsavedChanges }) => {
    return (
        <div
            onClick={onItemClick}
            className={classNames(
                "rt--customize-widget-main-menu-item rt--pl-16 rt--pr-16 rt--pb-10 rt--pt-10 rt--flex rt--align-center",
                hasUnsavedChanges && "rt--customize-widget-main-menu-item-unsaved",
                isActive && "rt--customize-widget-main-menu-item-active",
            )}
        >
            <span className="rt--title rt--font-regular rt--font-normal">
                {text}
            </span>
        </div>
    )
}

/** MenuItem propTypes
 * PropTypes
*/
MenuItem.propTypes = {
    /** Fire when item is clicked */
    onItemClick: PropTypes.func,
    /** Indicates whether the element is active or not */
    isActive: PropTypes.bool,
    /** Item text */
    text: PropTypes.string,
    hasUnsavedChanges: PropTypes.bool,
};

export default MenuItem;