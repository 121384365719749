import React, { useEffect, useState, Fragment, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { Menu } from 'antd';

import Dropdown from "components/common/dropdown";
import Search from "components/common/search";
import Icon from "components/common/icon";
import MobileDropdown from "./mobileDropdown";

import { changeProject } from 'store/actions/portal/common/common.action';

import { isMobile } from 'utils/common';
import { USER_ROLE } from "constants/user.constants";
import Paths from 'constants/path.constants';
import { SEARCH_TYPE } from 'components/common/search/constants';

import { getUser } from 'utils/auth';

import companyType from "types/company/company.type";
import userInfoType from 'types/profile/userInfo.type';

const PATHS_TO_SHOW_DROPDOWN_DISABLED = [
    Paths.COMPANIES_EDIT + "/:id",
    Paths.PROJECTS_EDIT + "/:id",
    Paths.AFFILIATES_EDIT + "/:id",
    Paths.AFFILIATE_MANAGERS_EDIT + "/:id",
    Paths.USERS_EDIT + "/:id",
    Paths.PERMISSION_GROUPS_EDIT + "/:id",
    Paths.SETTINGS_PAYMENTS,
    Paths.SETTINGS_PAYMENTS_EDIT + "/:id",
    Paths.CAMPAIGNS_EDIT + "/:id",
    Paths.AFFILIATE_GROUPS_EDIT + "/:id"
]

const QUERY_PARAMS_TO_SHOW_DROPDOWN_DISABLED = [
    "newsletterId", "newsletterName"
]

const PATHS_TO_SHOW_COMPANIES_WITHOUT_PROJECT = [
    Paths.COMPANIES,
    Paths.COMPANIES_EDIT + "/:id",
    Paths.PROJECTS,
    Paths.PROJECTS_EDIT + "/:id",
    Paths.PERMISSIONS,
    Paths.PERMISSION_GROUPS,
    Paths.PERMISSION_GROUPS_EDIT + "/:id",
    Paths.USERS,
    Paths.USERS_EDIT + "/:id",
    Paths.ACCESS_MANAGERS,
    Paths.ACCESS_MANAGERS_EDIT + "/:id",
    Paths.PERMISSION_REQUESTS,
    Paths.USER_LOGS,
    Paths.TRANSLATIONS_PROMO,
    Paths.TRANSLATIONS_BO,
    Paths.SETTINGS_CURRENCIES,
    Paths.SETTINGS_LANGUAGES,
    Paths.DEVELOPER_ERRORS,
    Paths.DEVELOPER_JOBS,
    Paths.DEVELOPER_MONITORING,
    Paths.DEVELOPER_PLATFORM_TEST,
    Paths.DEVELOPER_POST_DEPLOYMENT_ACTIONS,
    Paths.DEVELOPER_DB_CONNECTIONS,
    Paths.PERMISSION_REQUESTS
]

/** Companies global filter dropdown component on Header */
const GlobalCompaniesDropdown = ({
    changeProject,
    globalCompanyId,
    globalProjectId,
    companies
}) => {
    const { t } = useTranslation();

    const searchInputRef = useRef(null);

    const [companiesDropdownVisible, setCompaniesDropdownVisible] = useState(false);
    const [projectsDropdownVisible, setProjectsDropdownVisible] = useState(false);

    const location = useLocation();
    const params = useParams();

    const [searchCompanyValue, setSearchCompanyValue] = useState("");
    const [searchProjectValue, setSearchProjectValue] = useState("");

    const userRole = getUser()?.role;

    const shouldShowDropdown = userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.ACCESS_MANAGER;

    const shouldShowProjectsDropdown = userRole === USER_ROLE.ADMIN;

    /** Function to get projects
       * @function
       * @param {string} id
       * @returns {array}
       * @memberOf GlobalCompaniesDropdown
   */
    const getProjects = id => {
        const cId = id || globalCompanyId;

        const company = companies.find(c => c.id === cId);
        if (!company) return [];
        return (company.projects || []).filter(p => !searchProjectValue || p.id.toLowerCase() === searchProjectValue.toLowerCase() || p.name.toLowerCase().includes(searchProjectValue.toLowerCase()));
    }

    /** Filter projects by type
       * @function
       * @returns {array}
       * @memberOf GlobalCompaniesDropdown
   */
    const getProjectsForType = id => {
        const cId = id || globalCompanyId;
        let p = location.pathname;
        if (params.id) {
            p = location.pathname.replace(params.id, ":id")
        }
        let result = []

        result = [...getProjects(cId)];

        return result;
    }

    /** Filter companies by type
       * @function
       * @returns {array}
       * @memberOf GlobalCompaniesDropdown
   */
    const getCompaniesForType = () => {
        if(userRole === USER_ROLE.ACCESS_MANAGER){
            return getCompanies();
        }

        let p = location.pathname;
        if (params.id) {
            p = location.pathname.replace(params.id, ":id")
        }
        if(
            PATHS_TO_SHOW_COMPANIES_WITHOUT_PROJECT.includes(p)
        ){
            return getCompanies();
        }
        return getCompanies().filter(c => getProjectsForType(c.id).length > 0)
    }


    /** Fires on dropdown change
       * @function
       * @param {string}
       * @param {string} type - company/project
       * @memberOf GlobalCompaniesDropdown
   */
    const onChange = (value, type) => {
        if (type === "company") {
            const companyId = value;
            let projectId;
            const c = companies.find(c => c.id === value);

            if(c && c.projects && c.projects[0]) {
                if ( c.projects && c.projects[0]) {
                    projectId = c.projects[0].id;
                } 
            }
            changeProject(companyId, projectId);
            setSearchCompanyValue("");
            setCompaniesDropdownVisible(false);
        } else {
            changeProject(globalCompanyId, value);
            setSearchProjectValue("");
            setProjectsDropdownVisible(false);
        }
    }

    /** Function to get filtered companies
     * @function
     * @returns {array}
     * @memberOf GlobalCompaniesDropdown
    */
    const getCompanies = () => companies.filter(c => !searchCompanyValue || c.id.toLowerCase() === searchCompanyValue.toLowerCase() || c.name.toLowerCase().includes(searchCompanyValue.toLowerCase()))


    /** Function to detect when the dropdown should be disabled
       * @function
       * @returns {boolean}
       * @memberOf GlobalCompaniesDropdown
   */
    const isDropdownDisabled = () => {
        let p = location.pathname;
        if (params.id) {
            p = location.pathname.replace(params.id, ":id")
        }

        /** Check if there is a param in url search to disabled dropdown */
        const search = location.search;
        if(search){
            const urlParams = new URLSearchParams(search);
            const hasDisabledQueryParam = QUERY_PARAMS_TO_SHOW_DROPDOWN_DISABLED.some(p => urlParams.has(p))
            if(hasDisabledQueryParam) return true;
        }
        
        return PATHS_TO_SHOW_DROPDOWN_DISABLED.includes(p)
    };

    /** Focus Search input */
    useEffect(() => {
        if(projectsDropdownVisible){
            setTimeout(() => {
                searchInputRef?.current?.focus()
            }, 100)
        }
    }, [projectsDropdownVisible])

    const selectedCompany = useMemo(() => {
        return companies.find(p => p.id === globalCompanyId)
    }, [companies, globalCompanyId])

    const selectedProject = useMemo(() => {
        if(!globalProjectId || !globalCompanyId) return null;
        const projects = selectedCompany?.projects || [];
        return projects.find(p => p.id === globalProjectId)
    }, [globalProjectId, selectedCompany])

    return shouldShowDropdown ? (
        <div className={"rt--companies-dropdown rt--flex rt--align-center" + (isDropdownDisabled() ? " rt--companies-dropdown-disabled" : "")}>
            {
                isMobile() ? (
                    <MobileDropdown
                        companies={getCompaniesForType()}
                        projects={getProjectsForType()}
                        searchCompanyValue={searchCompanyValue}
                        searchProjectValue={searchProjectValue}
                        setSearchCompanyValue={setSearchCompanyValue}
                        setSearchProjectValue={setSearchProjectValue}
                        disabled={isDropdownDisabled()}
                    />
                ) : (
                    <Fragment>
                        <span className='rt--companies-dropdown-title rt--title rt--font-normal rt--font-regular rt--pr-8'>{t("backoffice.companies.company")}:</span>
                        <div className='rt--companies-dropdown-group rt--flex rt--align-center'>

                            <Dropdown
                                disabled={isDropdownDisabled()}
                                overlay={(
                                    <Fragment>
                                        <div className='rt--companies-dropdown-search rt--pl-8 rt--pr-8 rt--pt-8 rt--pb-8'>
                                            <Search
                                                type={SEARCH_TYPE.INPUT}
                                                onChange={value => setSearchCompanyValue(value)}
                                                value={searchCompanyValue}
                                            />
                                        </div>

                                        <Menu
                                            onClick={e => e.key && onChange(e.key, "company")}
                                            className="rt--companies-dropdown-menu"
                                            selectedKeys={[globalCompanyId, "search"]}
                                        >
                                            {
                                                getCompaniesForType().length > 0 ?
                                                    getCompaniesForType().map(c => (
                                                        <Menu.Item
                                                            key={c.id}
                                                            className={c.id === globalCompanyId ? "rt--companies-dropdown-menu-active" : ""}
                                                        >
                                                            <span className='rt--title rt--font-regular rt--font-normal'>{c.name}</span>
                                                        </Menu.Item>
                                                    )) : (
                                                        <Menu.Item
                                                            className="rt--companies-dropdown-empty"
                                                            disabled
                                                            key="notFound"
                                                        >
                                                            {t("backoffice.common.notFound")}
                                                        </Menu.Item>
                                                    )
                                            }
                                        </Menu>
                                    </Fragment>
                                )}
                                title={t("backoffice.companies.company")}
                                popupVisible={companiesDropdownVisible}
                                onVisibleChange={visible => setCompaniesDropdownVisible(visible)}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            >
                                <div className="rt--companies-dropdown-content rt--flex rt--align-center rt--justify-between rt--pl-12 rt--pr-4">
                                    <span className='rt--title rt--font-normal rt--font-bold rt--pr-4'>{selectedCompany?.name ?? ""}</span>
                                    <Icon name='down-small' className='rt--icon-rotate'/> 
                                </div>
                            </Dropdown>

                            {
                                shouldShowProjectsDropdown && (
                                    <Dropdown
                                        disabled={isDropdownDisabled()}
                                        overlay={(
                                            <Fragment>
                                                <div className='rt--companies-dropdown-search rt--pl-8 rt--pr-8 rt--pt-8 rt--pb-8'>
                                                    <Search
                                                        type={SEARCH_TYPE.INPUT}
                                                        onChange={value => setSearchProjectValue(value)}
                                                        value={searchProjectValue}
                                                        inputRef={searchInputRef}
                                                    />
                                                </div>

                                                <Menu
                                                    onClick={e => e.key && onChange(e.key, "project")}
                                                    className="rt--companies-dropdown-menu"
                                                    selectedKeys={[globalProjectId, "search"]}
                                                >
                                                    {
                                                        getProjectsForType().length > 0 ?
                                                            getProjectsForType().map(c => (
                                                                <Menu.Item
                                                                    key={c.id}
                                                                    className={c.id === globalProjectId ? "rt--companies-dropdown-menu-active" : ""}
                                                                >
                                                                    <span className='rt--title rt--font-regular rt--font-normal'>{c.name}</span>
                                                                </Menu.Item>
                                                            )) : (
                                                                <Menu.Item
                                                                    className="rt--companies-dropdown-empty"
                                                                    disabled
                                                                    key="notFound"
                                                                >
                                                                    {t("backoffice.common.notFound")}
                                                                </Menu.Item>
                                                            )
                                                    }
                                                </Menu>
                                            </Fragment>
                                        )}
                                        popupVisible={projectsDropdownVisible}
                                        onVisibleChange={visible => setProjectsDropdownVisible(visible)}
                                        getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                    >
                                        <div className="rt--companies-dropdown-content rt--companies-dropdown-content-last rt--flex rt--align-center rt--justify-between rt--pl-12 rt--pr-4 rt--ml-1">
                                            <span className='rt--title rt--font-normal rt--font-bold rt--pr-4'>
                                                {
                                                    globalProjectId ? (selectedProject?.name ?? "") :
                                                        <span className='rt--companies-dropdown-content-placeholder rt--title rt--font-normal rt--font-regular'>{`-- ${t("backoffice.common.choose")} ${t("backoffice.common.project")} --`}</span>
                                                }
                                            </span>
                                             <Icon name='down-small' className='rt--icon-rotate' /> 
                                        </div>
                                    </Dropdown>
                                )
                            }

                        </div>
                    </Fragment>
                )
            }


        </div>
    ) : <div />
}

/** GlobalCompaniesDropdown propTypes
    * PropTypes
*/
GlobalCompaniesDropdown.propTypes = {
    /** Redux state property, represents the array of all companies  */
    companies: PropTypes.arrayOf(companyType),
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux action to change global company/project id */
    changeProject: PropTypes.func,
    /** Redux state property, the user info */
    userInfo: userInfoType
}

const mapDispatchToProps = dispatch => (
    {
        changeProject: (companyId, projectId) => {
            dispatch(changeProject(companyId, projectId));
        }
    }
)

const mapStateToProps = state => {
    return {
        companies: state.common.allCompanies,
        globalCompanyId: state.common.globalCompanyId,
        globalProjectId: state.common.globalProjectId,
        userInfo: state.profile.userInfo
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    GlobalCompaniesDropdown
);