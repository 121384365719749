import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import {
    SET_COMMISSION_CALCULATIONS_ACTION_BEFORE,
    SET_COMMISSION_CALCULATIONS_ACTION_FINISH,
    SET_COMMISSION_CALCULATIONS,
    SET_COMMISSION_CALCULATIONS_SORTING,
    SET_COMMISSION_CALCULATIONS_FILTERS
} from "../../../../actionTypes";

const setCommissionCalculationsActionBefore = () => ({
    type: SET_COMMISSION_CALCULATIONS_ACTION_BEFORE,
});

const setCommissionCalculationsActionFinished = () => ({
    type: SET_COMMISSION_CALCULATIONS_ACTION_FINISH,
});

const setCommissionCalculations = (calculations, add) => ({
    type: SET_COMMISSION_CALCULATIONS,
    payload: { calculations, add },
});

export const setCommissionCalculationsSorting = sorting => ({
    type: SET_COMMISSION_CALCULATIONS_SORTING,
    payload: { sorting },
});

export const setCommissionCalculationsFilters = filters => ({
    type: SET_COMMISSION_CALCULATIONS_FILTERS,
    payload: { filters },
});


export const getCommissionCalculations = nextPage => {
    return (dispatch, getState) => {

        const commissionCalculations = getState().commissionCalculations;
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? commissionCalculations.sorting.page + 1 : commissionCalculations.sorting.page;
        
        let params = {
            ...commissionCalculations.sorting,
            ...commissionCalculations.filters,
            page: page
        };

        if(page > 1 && commissionCalculations.total <= (page - 1 ) * commissionCalculations.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && commissionCalculations.total === 0){
            return Promise.resolve();
        }

        dispatch(setCommissionCalculationsActionBefore());

        return axios({
            url: ApiUrls.GET_COMMISSION_CALCULATION_HISTORY,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: calculations } }) => {
                dispatch(setCommissionCalculations({
                    ...calculations,
                    item2: calculations.item2.map(c => ({...c, uniqueKey: c.calculationId + "_" + c.affiliiateId + "_" + c.currencyCode}))
                }, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setCommissionCalculationsSorting({ ...commissionCalculations.sorting, page: page }));
                dispatch(setCommissionCalculationsActionFinished());
            })
            .catch(() => {
                dispatch(setCommissionCalculationsActionFinished());
            });
    }
}
