import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";


import {
    SET_PLATFORMS_ACTION_BEFORE,
    SET_PLATFORMS_ACTION_FINISH,
    SET_PLATFORMS,
    SET_PLATFORM_TEST_ACTION_BEFORE,
    SET_PLATFORM_TEST_ACTION_FINISH,
    SET_PLATFORM_TESTS
} from "../../../actionTypes";

/** Platforms */

const setPlatformsBefore = () => ({
    type: SET_PLATFORMS_ACTION_BEFORE
})

const setPlatformsFinished = () => ({
    type: SET_PLATFORMS_ACTION_FINISH
})

const setPlatforms = platforms => ({
    type: SET_PLATFORMS,
    payload: { platforms }
})

export const getPlatforms = () => {
    return dispatch => {
        dispatch(setPlatformsBefore());
        return axios({
            url: ApiUrls.GET_PLATFORMS,
            method: Methods.GET
        })
        .then(({data : {value: platforms}}) => {
            dispatch(setPlatforms(platforms));
            dispatch(setPlatformsFinished());
        })
        .catch(err => {
            dispatch(setPlatformsFinished());
        })
    }
}

/** Test Platform */

const setPlatformTestActionBefore = () => ({
    type: SET_PLATFORM_TEST_ACTION_BEFORE
});

const setPlatformTestActionFinished = () => ({
    type: SET_PLATFORM_TEST_ACTION_FINISH
});

const setPlatformTests = platformTests => ({
    type: SET_PLATFORM_TESTS,
    payload: { platformTests }
})

export const testPlatform = platformId => {
    return dispatch => {
        dispatch(setPlatformTestActionBefore());

        return axios({
            url: ApiUrls.TEST_PLATFORM,
            method: Methods.GET,
            params: { platformId }
        })
        .then(({ data: { value: tests } }) => {
            dispatch(setPlatformTests(tests));
            dispatch(setPlatformTestActionFinished());
        })
        .catch(() => {
            dispatch(setPlatformTestActionFinished());
        })
    }
}

export const resetPlatformTests = () => {
    return dispatch => {
        dispatch(setPlatformTests({gateways:[]}));
    }
}