import PropTypes from 'prop-types';

export default PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    platformId: PropTypes.string,
    description: PropTypes.string,
    enabled: PropTypes.bool,
    isTesting: PropTypes.bool,
    createdAt: PropTypes.string,
    modifiedAt: PropTypes.string,
    createdBy: PropTypes.string,
    modifiedBy: PropTypes.string,
    type: PropTypes.number
})