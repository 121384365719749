import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Actions from './actions';
import TableColumnContent from './tableColumnContent';
import DefaultEmptyView from '../defaultEmptyView';

import { classNames, isFunction } from 'utils/common';
import { mobileExpandableColumnsCreator } from "../helpers";

import { DEFAULT_UNIQUE_PROP } from '../constants';

const ExpandTableView = ({
    detailsObj = {},
    isLoading,
    renderEmpty,
    mobileListView,
    canShowContetn = false,
    detailsLoadFn,
    rowData
}) => {

    const {
        content,
        data = [],
        uniqueKey = DEFAULT_UNIQUE_PROP,
        columns = [],
        actions = []
    } = detailsObj;

    const expandableTableColumns = mobileExpandableColumnsCreator({ mainColumns: columns });

    useEffect(() => {
        detailsLoadFn && detailsLoadFn(rowData)
    }, [])

    if (canShowContetn && content) {
        return content;
    }
    if (!isLoading && data.length === 0) {
        return (
            isFunction(renderEmpty)
                ? renderEmpty()
                : <DefaultEmptyView isExpandedView={true}/>
        )
    }

    return (
        <div className="rt--table-expanded">
            <div className="rt--table">
                {
                    data.map(rowData => {
                        return (
                            <div className="rt--table-item rt--mb-8" key={rowData[uniqueKey]}>
                                <div className="rt--table-item-inner rt--flex">
                                    <div className="rt--table-item-row rt--flex-equal rt--flex">
                                        {
                                            expandableTableColumns.map(columnsData => {
                                                return (
                                                    <div
                                                        key={columnsData.mobileLevel}
                                                        className={classNames(
                                                            "rt--table-item-col rt--flex rt--flex-col rt--justify-start rt--mb-8",
                                                            mobileListView && "rt--table-item-col-list"
                                                        )}
                                                    >
                                                        {
                                                            !columnsData.showOnlyValue && (
                                                                <b
                                                                    className={classNames(
                                                                        "rt--title rt--font-normal rt--font-regular rt--pb-4",
                                                                        columnsData.isRed && "rt--error-text"
                                                                    )}
                                                                >
                                                                    {columnsData.title}
                                                                </b>
                                                            )
                                                        }
                                                        <TableColumnContent
                                                            rowData={rowData}
                                                            columnData={columnsData}
                                                        />

                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        actions.length > 0 && (
                                            <Actions
                                                actions={actions}
                                                rowData={rowData}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

/** ExpandTableView propTypes
 * PropTypes
*/
ExpandTableView.propTypes = {
    detailsObj: PropTypes.object,
    isLoading: PropTypes.bool,
    renderEmpty: PropTypes.func,
    mobileListView: PropTypes.bool,
    canShowContetn: PropTypes.bool,
    detailsLoadFn: PropTypes.func,
    rowData: PropTypes.object
};


export default ExpandTableView;