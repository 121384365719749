export const isString = (param) => {
    return typeof param === "string";
};

export const isNumber = (param) => {
    return typeof param === "number";
};

export const isBoolean = (param) => {
    return typeof param === "boolean";
};

export const isValidObject = (param) => {
    if (typeof param !== "object") {
        return false;
    }

    if (Array.isArray(param)) {
        return false;
    }

    if (param === null) {
        return false;
    }

    return true;
};
