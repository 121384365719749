import { useSelector } from 'react-redux';

import { NUMBER_FORMAT } from 'constants/common.constants';

const selectFormatSettings = state => state.profile.userInfo.formatSetting;
const selectCurrencies = state => state.profile.currencies;
const selectGlobalProjectId = state => state.common.globalProjectId;

const getCurrencyDecimalCount = (currencyCode, currencies = [], projectId) => {
    let currency = currencies.find(c => c.currencyCode.toLowerCase() === currencyCode.toLowerCase() && c.projectId === projectId);
    if(currency){
        return currency.currency?.decimalCount ?? 0;
    }
    return 0;
}

const getCurrencySymbol = (currencyCode, currencies = []) => {
    let currency = currencies.find(c => c.currencyCode.toLowerCase() === currencyCode.toLowerCase());
    if(currency){
        return currency.currency?.symbol || currency.currencyCode;
    }
    return currencyCode;
}

const useFormat = () => {

    //#region ------------------------------------------- REDUX --------------------------------------------//

    const formatSettings = useSelector(selectFormatSettings);
    const currencies = useSelector(selectCurrencies);
    const globalProjectId = useSelector(selectGlobalProjectId);

    //#endregion

    // Format Number
    const formatNumber = num => {
        if (num === null || num === undefined) return "";

        const numberFormatting = formatSettings?.numberFormatting ? formatSettings.numberFormatting : NUMBER_FORMAT.FORMAT_1;
        let separator, decimal = null;
        switch (numberFormatting) {
            case NUMBER_FORMAT.FORMAT_1:
                separator = " ";
                decimal = ".";
                break;
            case NUMBER_FORMAT.FORMAT_2:
                separator = ",";
                decimal = ".";
                break;
            case NUMBER_FORMAT.FORMAT_3:
                separator = ".";
                decimal = ",";
                break;
            case NUMBER_FORMAT.FORMAT_4:
                separator = " ";
                decimal = ",";
                break;
            case NUMBER_FORMAT.FORMAT_5:
                separator = "";
                decimal = ".";
                break;
            case NUMBER_FORMAT.FORMAT_6:
                separator = "";
                decimal = ",";
                break;
            default:
                separator = " ";
                decimal = ".";
                break;
        }
        const str = num.toString();
        const parts = str.split(".");
        if (separator !== "") {
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
        }
        return parts.join(decimal);
    }

    // Format amount
    const formatAmount = (amount, currencyCode, date) => {
        if(amount === null || amount === undefined) return "-";

        const decimalCount = getCurrencyDecimalCount(currencyCode, currencies, globalProjectId, date);
        let num = Number(amount);
        const degree = Math.pow(10, decimalCount);

        num = Math.trunc(num * degree) / degree

        return formatNumber( Number(num).toFixed( decimalCount ) );
    }

    // Format amount
    const formatCurrencyWithSymbol = (currencyCode, value, addBrackats = true) => {
        const symbol = getCurrencySymbol(currencyCode, currencies, globalProjectId);
        if(addBrackats){
            return `${value} (${symbol})`
        } else {
            return `${value} ${symbol}`
        }
    }

    return {
        formatNumber,
        formatAmount,
        formatCurrencyWithSymbol
    }
}

export default useFormat;