import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { connect } from 'react-redux';

import { Menu, Button } from 'antd';

import Clock from "./clock";
import Icon from "components/common/icon";

import useUnsavedChangesConfirmation from 'hooks/useUnsavedChangesConfirmation';
import useLogo from 'hooks/useLogo';

import { isMobile } from "utils/common";
import { isMenuItemAvailable } from "utils/menu";
import { logout } from 'utils/auth';

import getMenuItems from "./items";

import { LOGO_TYPE, UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';

/** Sidebar component */

const Sidebar = ({
    collapsed,
    onCollapse,
    globalProjectId
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { logos } = useLogo()

    const { pathname, search } = useLocation();
    const params = useParams();

    const [selectedKeys, setSelectedKeys] = useState([]);
    const [openedKeys, setOpenedKeys] = useState([]);

    const navigateWithConfirmation = useUnsavedChangesConfirmation({
        cb: navigate,
        subscribe: [UNSAVED_FORM_PAGE_TYPE.TAB, UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    /** Detects which menu items should be opened by default depend on route
       * @function
       * @memberOf Sidebar
   */
    const setDefaultOpenedKeys = () => {
        const result = [];
        let path = pathname;

        if (params) {
            Object.keys(params).forEach(k => {
                path = path.replace(params[k], ":" + k)
            })
        }

        if (search) {
            path = path + search;
        }

        getMenuItems().forEach(item => {
            if (item.items) {
                item.items.forEach(i => {
                    if (i.items) {
                        i.items.forEach(it => {
                            if (it.match && (it.match.some(p => path.includes(p)))) {
                                result.push(item.id);
                                result.push(i.id);
                            }
                        })
                    } else {
                        if (i.match && (i.match.some(p => path.includes(p)))) {
                            result.push(item.id);
                        }
                    }

                })
            }
        })
        setOpenedKeys(result);
        return result;
    }

    /** Update active menu items depend on url change
       * @function
       * @memberOf Sidebar
   */
    const setDefaultSelectedKeys = () => {
        const result = [];

        getMenuItems().forEach(item => {
            let path = pathname;

            if (params) {
                Object.keys(params).forEach(k => {
                    path = path.replace(params[k], ":" + k)
                })
            }

            if (search) {
                path = path + search;
            }

            if (item.match && (item.match.some(p => path.includes(p)))) {
                result.push(item.id);
            }
            if (item.items) {
                item.items.forEach(i => {

                    if (i.items) {
                        i.items.forEach(it => {
                            if (it.match && (it.match.some(p => path.includes(p)))) {
                                result.push(it.id);
                            }
                        })
                    } else {
                        if (i.match && (i.match.some(p => path.includes(p)))) {
                            result.push(i.id);
                        }
                    }

                })
            }
        })
        setSelectedKeys(result);
    }

    /** Fires on menu item click
       * @function
       * @memberOf Sidebar
   */
    const handleMenuItemClick = url => {
        navigateWithConfirmation(url);
        document.body.classList.remove("rt--body-open-sidebar")
    }

    /** Update active menu items, when path changes */
    useEffect(() => {
        if (!collapsed) {
            setDefaultOpenedKeys()
        }
        setDefaultSelectedKeys();
    }, [pathname + search])

    /** Function event listener for window resize
       * @function
       * @memberOf Sidebar
   */
    const onWindowResize = () => {
        if (window.innerWidth < 1440) {
            onCollapse(true)
        } else {
            onCollapse(false)
        }
    }


    useEffect(() => {
        if (!isMobile()) {
            onWindowResize();
            window.removeEventListener('resize', onWindowResize);
            window.addEventListener('resize', onWindowResize);

            return () => {
                window.removeEventListener('resize', onWindowResize);
            };
        }
    }, [])


    return (
        <div className='rt--sidebar-content rt--pt-24 rt--flex rt--flex-col'>
            {
                isMobile() && (
                    <div
                        className='rt--sidebar-content-close'
                        onClick={() => document.body.classList.remove("rt--body-open-sidebar")}
                    >
                        <Icon name='close' />
                    </div>
                )

            }
            <div className='rt--sidebar-content-logo rt--flex rt--align-center rt--pl-16 rt--pr-16'>
                {
                    <img alt="logo" src={`${import.meta.env.SYSTEM_CDN_URL}/${logos[LOGO_TYPE.LOGO]}`} />
                }
            </div>

            <div className='rt--sidebar-content-logo-collapse'>
                {
                    collapsed ? <img alt='logo' src={`${import.meta.env.SYSTEM_CDN_URL}/${logos[LOGO_TYPE.COLLAPSED_LOGO]}`} /> : ''
                }
            </div>

            <div className='rt--sidebar-content-menu rt--flex-equal'>
                <Menu
                    mode="inline"
                    expandIcon={<Icon name='down' className='rt--sidebar-content-menu-down' />}
                    selectedKeys={selectedKeys}
                    openKeys={openedKeys}
                    onOpenChange={keys => { setOpenedKeys(keys.filter(k => k.length === 1 || keys.includes(k[0]))) }}
                >
                    {
                        getMenuItems()
                            .filter(item => isMenuItemAvailable(item, globalProjectId))
                            .map(item => item.items ? (
                                <Menu.SubMenu
                                    popupClassName="rt--sidebar-content-menu-sub"
                                    key={item.id}
                                    icon={<Icon name={item.icon} />}
                                    title={t(item.title)}
                                    expandIcon={<Icon name='down' className='rt--sidebar-content-menu-down' />}
                                >
                                    {
                                        item.items.filter(item => isMenuItemAvailable(item, globalProjectId)).map(i => (
                                            <Menu.Item
                                                key={i.id}
                                                onClick={() => handleMenuItemClick(i.url)}
                                            >
                                                <span>{t(i.title)}</span>
                                            </Menu.Item>
                                        ))
                                    }
                                </Menu.SubMenu>
                            ) : (
                                <Menu.Item
                                    key={item.id}
                                    icon={<Icon name={item.icon} />}
                                    onClick={() => handleMenuItemClick(item.url)}
                                >
                                    <span>{t(item.title)}</span>
                                </Menu.Item>
                            ))
                    }
                </Menu>
            </div>
            {
                isMobile() && (
                    <div className='rt--sidebar-content-logout rt--pl-16 rt--pr-16 rt--mt-24'>
                        <div className='rt--sidebar-content-logout-separator rt--mb-32' />
                        <Button
                            className='rt--sidebar-content-logout-button'
                            onClick={logout}
                        >
                            <Icon name='logout' />
                            <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.common.logout')}</span>
                        </Button>
                    </div>
                )
            }

            <div className='rt--sidebar-content-footer rt--pl-24 rt--pr-16 rt--flex rt--justify-between rt--align-center rt--pb-26'>
                <Clock collapsed={collapsed} />
                {
                    !isMobile() && (
                        <div className='rt--sidebar-content-footer-collapse' onClick={() => onCollapse(!collapsed)}>
                            <Icon name={collapsed ? 'expand' : 'collapse'} size={20}/>
                        </div>
                    )
                }

            </div>
        </div>
    )

}

Sidebar.propTypes = {
    /** Is Sidebar collapsed */
    collapsed: PropTypes.bool,
    /** Function to call to collapse sidebar*/
    onCollapse: PropTypes.func,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapStateToProps = state => {
    return {
        globalProjectId: state.common.globalProjectId
    }
}

export default connect(
    mapStateToProps,
    null
)(
    Sidebar
);
