import * as signalR from '@microsoft/signalr';

import { getUser } from 'utils/auth';
import { getAPIUrls } from 'utils/common';

import { SIGNALR_CONNECTION_TYPES } from 'constants/common.constants';

export default class SignalRUtils {

    constructor(connectionURL, callback) {
        this.connection = null;
        this.promise = null;
        this.connectionURL = connectionURL;
        this.callback = callback;
        this.additionalHandlers = [];
        this.connected = false;
        this.buildConnection();
    }

    /** Init SignalR
         * @function
         * @memberof SignalRUtils
     */
    buildConnection(){
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(this.connectionURL, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .build();
        this.startConnection();

        this.connection.onclose(err => {
            if (err) {
                this.handleConnectionError()
            }
        })
    }

    /** Start signalR connection if it is not started yet
         * @function
         * @returns {Promise} 
         * @memberof SignalRUtils
     */
    startConnection() {
        if (!this.promise) {
            this.promise = this.connection.start()
                .then(() => {
                    this.connected = true;
                    this.callback && this.callback(this.getConnection());
                    if(this.additionalHandlers.length > 0){
                        this.additionalHandlers.forEach(handler => handler())
                    }
                }).catch(() => {
                    this.handleConnectionError()
                });
        }
        return this.promise;
    }


    /** Function to call when connection failed
         * @function
         * @memberof SignalRUtils
     */
    handleConnectionError(){
        setTimeout(() => {
            this.promise = null;
            this.startConnection();
        }, 5000);
    }

    /** Function to get connection
         * @function
         * @returns {object} connection - signalR connection         
         * @memberof SignalRUtils
     */
    getConnection(){
        return this.connection;
    }

    /** Function to stop connection
         * @function
         * @memberof SignalRUtils
     */
    stopConnection(){
        this.connection.stop();
        this.additionalHandlers = [];
    }

    /** Function to add handler for connection
         * @function
         * @memberof SignalRUtils
     */
    addHandler(handler){
        if(this.connected){
            handler();
        } else {
            this.additionalHandlers.push(handler);
        }
    }

    /** Init all connections
         * @function
         * @static
         * @param {function} callback
         * @memberof SignalRUtils
     */
    static buildConnections(callback){
        SignalRUtils.connections = [];
        const token = getUser()?.wsToken ?? null;

        const urls = getAPIUrls();

        const adminSignalR = new SignalRUtils(`${urls.SIGNALR_ADMIN}?accessToken=${token}&apiType=admin` , () => callback(adminSignalR, SIGNALR_CONNECTION_TYPES.ADMIN));

        const jobsSignalR = new SignalRUtils(`${urls.SIGNALR_JOBS}?accessToken=${token}&apiType=admin` , () => callback(adminSignalR, SIGNALR_CONNECTION_TYPES.JOB));

        SignalRUtils.connections.push(adminSignalR);
        SignalRUtils.connections.push(jobsSignalR);
    }

    /** Init all connections
         * @function
         * @static
         * @return {array} array of connections
         * @memberof SignalRUtils
     */
    static getConnections(){
        return SignalRUtils.connections || [];
    }
}

