import React from 'react';
import PropTypes from 'prop-types';

const CustomizedThumbnail = ({ imgSrc, name, imgSize }) => {
    return (
        <div className='rt--lightbox-thumbnail'>
            <div className='rt--lightbox-thumbnail-img-container'>
                <img
                    className='rt--lightbox-thumbnail-img-container-img'
                    src={imgSrc}
                    alt='img'
                />
            </div>
            <span className='rt--lightbox-thumbnail-text' title={`${name} | ${imgSize}`}>
                {name} | {imgSize}
            </span>
        </div>
    );
}

/** CustomizedThumbnail propTypes
    * PropTypes
*/
CustomizedThumbnail.propTypes = {
    imgSrc: PropTypes.string,
    name: PropTypes.string,
    imgSize: PropTypes.string,
}

export default CustomizedThumbnail;