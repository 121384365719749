import PropTypes from 'prop-types';

export default PropTypes.shape({
    id: PropTypes.string,
    errorTime: PropTypes.string,
    controller: PropTypes.string,
    message: PropTypes.string,
    requestId: PropTypes.string,
    stackTrace: PropTypes.string,
    ip: PropTypes.string,
    action: PropTypes.string,
    countryCode: PropTypes.string
})