//#region React
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
//#endregion

import { Form, Col, Row } from "antd";

//#region Components
import Modal from "components/common/modal";
import Input from "components/common/input";
//#endregion

//#region Actions
import { addCustomHostname } from "store/actions/portal/projects/domains.action";
//#endregion

//#region Constants
import { POPUP_SIZE } from "constants/common.constants";
import { DOMAIN_REGEX } from "constants/regex.constants";
import { DOMAIN_TYPE } from "constants/project.constants";
//#endregion

/** Add Custom Hostname Popup Component */
const AddCustomHostnameComponent = ({
    addCustomHostname,
    isSaving,
    type,
    onClose,
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const [value, setValue] = useState("")

    const handleForm = () => {
        validateFields()
            .then((data) => {
                addCustomHostname(type, data.customHostname, onClose);
            })
            .catch(() => {});
    };

    return (
        <Modal
            title={t("backoffice.projects.addHostname")}
            cancelText={t("backoffice.common.cancel")}
            okText={t("backoffice.common.add")}
            onOk={handleForm}
            disableOkButton={isSaving || value === ""}
            onCancel={onClose}
            size={POPUP_SIZE.SMALL}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t("backoffice.projects.hostname")} *`}
                            name="customHostname"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { pattern: DOMAIN_REGEX, message: t( "backoffice.validation.fieldInvalid" ) },
                            ]}
                            validateFirst={true}
                            className="rt--general-form-item rt--form-item-without-margin"
                            data-placeholder={`${t(
                                "backoffice.common.enter"
                            )} ${t("backoffice.projects.hostname")}`}
                        >
                            <Input.Dropdown
                                placeholder={`${t(
                                    "backoffice.common.enter"
                                )} ${t("backoffice.projects.hostname")}`}
                                dropdownReadOnly={true}
                                items={[
                                    { label: "https://", value: "https://" },
                                ]}
                                onChange={e => setValue(e)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

AddCustomHostnameComponent.propTypes = {
    /** Redux action to add hostname to the Project */
    addCustomHostname: PropTypes.func,
    /** Redux ste property, is true when save request is in process */
    isSaving: PropTypes.bool,
    /** Site id */
    type: PropTypes.oneOf(Object.values(DOMAIN_TYPE)),
    /** Fires on popup close */
    onClose: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    addCustomHostname: (type, customHostname, onSuccess) => {
        dispatch(addCustomHostname(type, customHostname, onSuccess));
    },
});

const mapStateToProps = (state) => {
    return {
        isSaving: state.projects.isSaving,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCustomHostnameComponent);
