import React from "react";

import PermissionGroupsComponent from "pages/userManagement/permissionGroups/permissionGroups.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const PermissionGroupsRoute = () => {
    return <PermissionGroupsComponent />;
};

export default withPermission(
    withAuth(PermissionGroupsRoute),
    {
        resource: PERMISSION_RESOURCE.PERMISSION_GROUP,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.PORTAL
);
