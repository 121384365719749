import PropTypes from 'prop-types';

import { STATUS_TYPES } from "constants/status.constants";

export const StatusPropTypes = {
    /** Status */
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Status type */
    type: PropTypes.oneOf(Object.values(STATUS_TYPES)),
    /** Status icon */
    icon: PropTypes.node,
    /** Tooltip */
    tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
}

export default PropTypes.shape(StatusPropTypes);