import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import {
    setProjectsActionBefore,
    setProjectsActionFinished,
    setProjectsSaveActionBefore,
    setProjectsSaveActionFinished,
} from './projects.action';

import {
    SET_PROJECT_PAYMENT_METHODS,
    SET_PROJECT_PAYMENT_STATE,
    SET_PROJECT_PAYMENT_AVAILABLE_CURRENCIES,
    ADD_PROJECT_PAYMENT_CURRENCY,
    SET_PROJECT_PAYMENT_METHOD_DETAILS,
    CHANGE_PROJECT_PAYMENT_METHOD_DETAILS
} from "../../../actionTypes";

const setProjectPayments = paymentMethods => ({
    type: SET_PROJECT_PAYMENT_METHODS,
    payload: { paymentMethods }
})

const setProjectPaymentState = (isChecked, paymentId) => ({
    type: SET_PROJECT_PAYMENT_STATE,
    payload: { paymentId, isChecked }
})

const setProjectPaymentAvailableCurrencies = (currencies) => ({
    type: SET_PROJECT_PAYMENT_AVAILABLE_CURRENCIES,
    payload: { currencies }
})

const setPaymentDetails = (payment) => ({
    type: SET_PROJECT_PAYMENT_METHOD_DETAILS,
    payload: { payment }
})

const changePaymentDetails = currency => ({
    type: CHANGE_PROJECT_PAYMENT_METHOD_DETAILS,
    payload: { currency }
})

const setProjectPaymentCurrency = (currency) => ({
    type: ADD_PROJECT_PAYMENT_CURRENCY,
    payload: { currency }
})

export const getProjectPaymentMethods = (paymentId = null) => {
    return dispatch => {
        dispatch(setProjectsActionBefore());

        return axios({
            url: ApiUrls.GET_PROJECT_PAYMENT_METHODS,
            method: Methods.GET,
            params: { id: paymentId }
        })
            .then(({ data: { value: { payments } } }) => {
                dispatch(paymentId !== null ? setPaymentDetails(payments[0]) : setProjectPayments(payments))
                dispatch(setProjectsActionFinished());
            })
            .catch((ex) => {
                dispatch(setProjectsActionFinished());
            })
    }
}

export const addProjectPaymentMethod = ({ id }, onSuccess) => {
    return (dispatch) => {
        dispatch(setProjectsSaveActionBefore());

        return axios({
            url: ApiUrls.ADD_PROJECT_PAYMENT_METHOD,
            method: Methods.POST,
            data: { id }
        })
            .then(({ data }) => {
                dispatch(setProjectsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess(data.value.payments.find((payment) => payment.id === id));
                    setProjectPayments(data.value.payments);
                }
            })
            .catch(() => {
                dispatch(setProjectsSaveActionFinished());
            });
    };
};

export const deleteProjectPaymentMethod = (id, onSuccess) => {
    return (dispatch) => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_PROJECT_PAYMENT_METHOD,
            method: Methods.DELETE,
            data: { id }
        })
            .then(({ data }) => {
                dispatch(setProjectsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess()
                }
            })
            .catch(() => {
                dispatch(setProjectsSaveActionFinished());
            });
    };
};

export const changeProjectPaymentMethodState = (isChecked, id) => {
    return (dispatch) => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: isChecked ? ApiUrls.ACTIVATE_PROJECT_PAYMENT_METHOD : ApiUrls.DEACTIVATE_PROJECT_PAYMENT_METHOD,
            method: Methods.POST,
            data: { id }
        })
            .then(({ data }) => {
                dispatch(setProjectsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    dispatch(setProjectPaymentState(isChecked, id));
                }
            })
            .catch(() => {
                dispatch(setProjectsSaveActionFinished());
            });
    };
}

export const getAvailableProjectPaymentCurrencies = (id) => {
    return (dispatch) => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.GET_AVAILABLE_PROJECT_PAYMENT_METHOD_CURRENCIES,
            method: Methods.GET,
            params: { id }
        })
            .then(({ data: { value: currencies } }) => {
                dispatch(setProjectsSaveActionFinished());

                dispatch(setProjectPaymentAvailableCurrencies(currencies));
            })
            .catch(() => {
                dispatch(setProjectsSaveActionFinished());
            });
    };
}

export const changeProjectPaymentMethodDetails = (requestBody, onSuccess) => {
    return (dispatch) => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.CHANGE_PROJECT_PAYMENT_METHOD_DETAILS,
            method: Methods.POST,
            data: requestBody
        })
            .then(({ data }) => {
                dispatch(setProjectsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    const {id, ...currency } = requestBody;

                    onSuccess && onSuccess(currency);
                    dispatch(changePaymentDetails(currency));
                    message.success(data.message);
                }
            })
            .catch(() => {
                dispatch(setProjectsSaveActionFinished());
            });
    };
}

export const addProjectPaymentMethodCurrency = (requestBody, onSuccess) => {
    return (dispatch) => {
        dispatch(setProjectsSaveActionBefore());

        return axios({
            url: ApiUrls.ADD_PROJECT_PAYMENT_METHOD_CURRENCY,
            method: Methods.POST,
            data: requestBody
        })
            .then(({ data }) => {
                dispatch(setProjectsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess()
                    setProjectPaymentCurrency(requestBody.currencyCode);
                }
            })
            .catch(() => {
                dispatch(setProjectsSaveActionFinished());
            });
    };
};

export const deleteProjectPaymentCurrency = (requestBody, onSuccess) => {
    return (dispatch) => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_PROJECT_PAYMENT_METHOD_CURRENCY,
            method: Methods.DELETE,
            data: requestBody
        })
            .then(({ data }) => {
                dispatch(setProjectsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess()
                }
            })
            .catch(() => {
                dispatch(setProjectsSaveActionFinished());
            });
    };
};