import React, { useRef, useEffect } from "react";
import PropTypes from 'prop-types';

import { isMobile } from "utils/common";

/** Function - hook
	 * @function
	 * @param { object } ref - reference to element
	 * @param { func } callback - function to call when clicked outside
	 * @memberOf useOutsideAlerter
 */
const useOutsideAlerter = (ref, callback) => {

	/** Function to detect if click is outside component
		 * @function
		 * @param { object } event - click event object
		 * @memberOf useOutsideAlerter
	 */
	const handleClickOutside = event => {
		if (ref.current && !ref.current.contains(event.target)) {
			if (callback) callback();
		}
	}

	/** Add event listener */
	useEffect(() => {
		document.addEventListener(!isMobile() ? "mousedown" : "touchend", handleClickOutside);
		return () => {
			document.removeEventListener(!isMobile() ? "mousedown" : "touchend", handleClickOutside);
		};
	});
}

/** Component to add "click outside" event on wrapped component */
const OutsideAlerter = ({ children, callback }) => {
	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef, callback);

	return <div ref={wrapperRef}>{children}</div>;
}

/** OutsideAlerter propTypes
	* PropTypes
*/
OutsideAlerter.propTypes = {
	/** Wrapped component */
	children: PropTypes.element.isRequired,
	/** Function to call when clicked outside */
	callback: PropTypes.func
};

export default OutsideAlerter;