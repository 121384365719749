import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from "components/common/table";
import Modal from 'components/common/modal';
import Icon from "components/common/icon";

import { showTransferResult } from "store/actions/portal/wallet/transfer.action";

import { tableColumnsCreator } from 'utils/tableColumnsCreator';

import { POPUP_SIZE } from 'constants/common.constants';
import { RESPONSE_CODES } from 'constants/errorCode.constants';
import { tableColumns } from './columns';

/** Transfer result component */
const TransferResult = ({
    transferResultData,
    hideTransferResult,
    onClose
}) => {
    const { t } = useTranslation();

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({ mainColumns: tableColumns })
    }, [])

    /** Is transfer failed */
    const isTransferFailed = transferResultData.some(r => r.status !== RESPONSE_CODES.Success);

    return (
        <Modal
            title={(
                <div className='rt--flex rt--align-center rt--justify-center'>
                    {
                        isTransferFailed && <Icon name='warning'/>
                    }

                    <span className='rt--title rt--font-bold rt--font-big rt--pl-4'>
                        {
                            isTransferFailed ? t("backoffice.wallet.transferFailed") : t("backoffice.wallet.transferSuccessful")
                        }
                    </span>
                </div>
            )}
            hideOkButton={true}
            cancelText={t('backoffice.common.close')}
            onCancel={() => {
                hideTransferResult();
                onClose && onClose()
            }}
            size={POPUP_SIZE.BIG}
            className={isTransferFailed ? "rt--modal-error" : "rt--modal-success"}
        >
            <div className='rt--transfer-error'>
                <div className="rt--modal-table rt--modal-table-regular">
                    <Table
                        columns={mainTableColumns}
                        data={transferResultData || []}
                        noPagination={true}
                        disableFullView={true}
                        chooser={{
                            type: "icon",
                            dataIndex: "status",
                            renderIcon: record => (
                                record.status === RESPONSE_CODES.Success
                                    ? <Icon name='success'/>
                                    : <Icon name='warning'/>
                            )
                        }}
                    />
                </div>
            </div>
        </Modal>
    )
}

/** TransferResult propTypes
    * PropTypes
*/
TransferResult.propTypes = {
    /** Redux state property, transfer result data data */
    transferResultData: PropTypes.array,
    /** Redux action to hide transfer result */
    hideTransferResult: PropTypes.func,
    /** Fires on popup close */
    onClose: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        transferResultData: state.wallet.transferResultData
    }
}

const mapDispatchToProps = dispatch => (
    {
        hideTransferResult: () => {
            dispatch(showTransferResult(false));
        },
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(TransferResult)