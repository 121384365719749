import React, { memo, useState, useMemo } from "react";
import PropTypes from 'prop-types';

import { useTranslation } from "react-i18next";
import { Tabs as AntTabs } from 'antd';

import Filters from "../../components/filters";
import LineChart from "components/common/lineChart";

import { FINANCIAL_ENTITY_TYPE, LINE_CHART_CONFIGS_BY_ENTITY, LINE_CHART_ENTITY_TYPE } from "../../constants";

import { isMobile } from "utils/common";

import {
    getLineChartData,
    getFinancialLineChartTooltip
} from "../../helpers";

import { constructLineChartConfigs } from "utils/lineChart";

import {
    getCheckPointsTooltipConfigs,
    getLegendConfigs,
    getMainLineConfigs,
    getXAxisConfigs
} from "utils/lineChart/configCreators";

import useFinancialLineChart from "../../hooks/useFinancialLineChart";
import useDate from "hooks/useDate";
import useFormat from "hooks/useFormat";

import currencyType from "types/profile/currency.type";
import useAccess from "hooks/useAccess";
import { AFFILIATE_ACCESS_TYPE } from "constants/affiliate.constants";

const FinancialLineChart = ({
    updateLastUpdatedDate,
    filters,
    updateFilters,
    currencyCode,
    currencies
}) => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    const { formatCurrencyWithSymbol, formatAmount } = useFormat();

    const { hasAccess } = useAccess();

    const initialEntityType = (
        hasAccess(AFFILIATE_ACCESS_TYPE.GGR) || hasAccess(AFFILIATE_ACCESS_TYPE.NGR) ? FINANCIAL_ENTITY_TYPE.GGR : 
        hasAccess(AFFILIATE_ACCESS_TYPE.BET_AMOUNT) ? FINANCIAL_ENTITY_TYPE.TURNOVER : 
        hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_AMOUNT) || hasAccess(AFFILIATE_ACCESS_TYPE.FTD_AMOUNT) || hasAccess(AFFILIATE_ACCESS_TYPE.NET_DEPOSIT_AMOUNT) ? FINANCIAL_ENTITY_TYPE.DEPOSIT_AMOUNT : null
    )

    const [entityType, setEntityType] = useState(initialEntityType)

    const { chartData, isLoading } = useFinancialLineChart(
        entityType,
        filters.period,
        updateLastUpdatedDate,
        currencyCode
    );

    /** Chart Data */
    const configs = useMemo(() => {
        const valuePropNames = [];
        const lineNames = [];
        if(entityType === FINANCIAL_ENTITY_TYPE.GGR){
            if(hasAccess(AFFILIATE_ACCESS_TYPE.GGR)){
                valuePropNames.push(LINE_CHART_ENTITY_TYPE.GGR);
                lineNames.push(`<span class='rt--highchart-legend-title' data-type='type-ggr'>${t("backoffice.dashboard.ggr")}</span>`)
            }
            if(hasAccess(AFFILIATE_ACCESS_TYPE.NGR)){
                valuePropNames.push(LINE_CHART_ENTITY_TYPE.NGR);
                lineNames.push(`<span class='rt--highchart-legend-title' data-type='type-ngr'>${t("backoffice.dashboard.ngr")}</span>`)
            }
        } else if(entityType === FINANCIAL_ENTITY_TYPE.TURNOVER){
            if(hasAccess(AFFILIATE_ACCESS_TYPE.BET_AMOUNT)){
                valuePropNames.push(LINE_CHART_ENTITY_TYPE.TURNOVER);
                lineNames.push(`<span class='rt--highchart-legend-title' data-type='type-turnover'>${t("backoffice.dashboard.turnover")}</span>`)
            }
        } else if(entityType === FINANCIAL_ENTITY_TYPE.DEPOSIT_AMOUNT){
            if(hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_AMOUNT)){
                valuePropNames.push(LINE_CHART_ENTITY_TYPE.DEPOSIT_AMONT);
                lineNames.push(`<span class='rt--highchart-legend-title' data-type='type-depositAmount'>${t("backoffice.dashboard.depositAmount")}</span>`)
            }
            if(hasAccess(AFFILIATE_ACCESS_TYPE.FTD_AMOUNT)){
                valuePropNames.push(LINE_CHART_ENTITY_TYPE.FTD_AMOUNT);
                lineNames.push(`<span class='rt--highchart-legend-title' data-type='type-ftdAmount'>${t("backoffice.dashboard.ftdAmount")}</span>`)
            }
            if(hasAccess(AFFILIATE_ACCESS_TYPE.NET_DEPOSIT_AMOUNT)){
                valuePropNames.push(LINE_CHART_ENTITY_TYPE.NET_DEPOSIT_AMOUNT);
                lineNames.push(`<span class='rt--highchart-legend-title' data-type='type-netDeposit'>${t("backoffice.dashboard.netDeposit")}</span>`)
            }
        }
        const charts = valuePropNames.map(valuePropName => (
            getLineChartData(chartData || [], filters.period, valuePropName, dateService)
        ))

        const categories = charts[0].categories;
        const dates = charts[0].dates;

        const counts = {};

        if(valuePropNames.includes(LINE_CHART_ENTITY_TYPE.GGR)){
            const ggrChartIndex = valuePropNames.findIndex(v => v === LINE_CHART_ENTITY_TYPE.GGR)
            counts["ggr"] = charts[ggrChartIndex].counts;
            counts["sportGGR"] = getLineChartData(chartData || [], filters.period, "sportGGR", dateService)?.counts;
            counts["casinoGGR"] = getLineChartData(chartData || [], filters.period, "casinoGGR", dateService)?.counts;
        }

        if(valuePropNames.includes(LINE_CHART_ENTITY_TYPE.NGR)){
            const ngrChartIndex = valuePropNames.findIndex(v => v === LINE_CHART_ENTITY_TYPE.NGR)
            counts["ngr"] = charts[ngrChartIndex].counts;
            counts["sportNGR"] = getLineChartData(chartData || [], filters.period, "sportNGR", dateService)?.counts;
            counts["casinoNGR"] = getLineChartData(chartData || [], filters.period, "casinoNGR", dateService)?.counts;
        }

        if(valuePropNames.includes(LINE_CHART_ENTITY_TYPE.TURNOVER)){
            const turnoverChartIndex = valuePropNames.findIndex(v => v === LINE_CHART_ENTITY_TYPE.TURNOVER)
            counts["turnover"] = charts[turnoverChartIndex].counts;
            counts["sportTurnover"] = getLineChartData(chartData || [], filters.period, "sportTurnover", dateService)?.counts;
            counts["casinoTurnover"] = getLineChartData(chartData || [], filters.period, "casinoTurnover", dateService)?.counts;
        }

        if(valuePropNames.includes(LINE_CHART_ENTITY_TYPE.DEPOSIT_AMONT)){
            const depositAmountChartIndex = valuePropNames.findIndex(v => v === LINE_CHART_ENTITY_TYPE.DEPOSIT_AMONT)
            counts["deposit"] = charts[depositAmountChartIndex].counts;
        }

        if(valuePropNames.includes(LINE_CHART_ENTITY_TYPE.FTD_AMOUNT)){
            const ftdAmountChartIndex = valuePropNames.findIndex(v => v === LINE_CHART_ENTITY_TYPE.FTD_AMOUNT)
            counts["ftdAmount"] = charts[ftdAmountChartIndex].counts;
        }

        if(valuePropNames.includes(LINE_CHART_ENTITY_TYPE.NET_DEPOSIT_AMOUNT)){
            const netDepositAmountChartIndex = valuePropNames.findIndex(v => v === LINE_CHART_ENTITY_TYPE.NET_DEPOSIT_AMOUNT)
            counts["netDeposit"] = charts[netDepositAmountChartIndex].counts;
        }

        return constructLineChartConfigs({
            xAxisConfigs: getXAxisConfigs({
                checkPoints: categories,
            }),
            checkPointsTooltipConfigs: getCheckPointsTooltipConfigs({
                tooltipHTMLGenerator: (element) => (
                    getFinancialLineChartTooltip(
                        element,
                        {
                            dates: dates,
                            counts: counts,
                            currencyCode,
                            period: filters.period,
                            entityType, 
                            dateService,
                            formatAmount,
                            formatCurrencyWithSymbol
                        }
                    )
                )
            }),
            lines: charts.map((chart, index) => (
                getMainLineConfigs({
                    ...LINE_CHART_CONFIGS_BY_ENTITY[valuePropNames[index]],
                    data: chart.values,
                    name: lineNames[index],
                }) 
            )),
            legend: getLegendConfigs({
                valueFormatter: value => formatCurrencyWithSymbol(currencyCode, formatAmount(value, currencyCode), false)
            })
        })
    }, [chartData, filters.period, entityType, dateService, currencyCode, currencies])

    if(
        [
            AFFILIATE_ACCESS_TYPE.GGR, 
            AFFILIATE_ACCESS_TYPE.NGR, 
            AFFILIATE_ACCESS_TYPE.BET_AMOUNT, 
            AFFILIATE_ACCESS_TYPE.DEPOSIT_AMOUNT, 
            AFFILIATE_ACCESS_TYPE.FTD_AMOUNT,
            AFFILIATE_ACCESS_TYPE.NET_DEPOSIT_AMOUNT
        ].every(type => !hasAccess(type))
    ) return null;

    return (
        <div className="rt--chart-section rt--pb-24 rt--pl-24 rt--pr-24 rt--pt-24">
            <AntTabs
                animated={false}
                defaultActiveKey={FINANCIAL_ENTITY_TYPE.PLAYER}
                onChange={value => setEntityType(Number(value))}
                destroyInactiveTabPane={true}
                className='rt--tabs rt--chart-tab'
                tabBarExtraContent={
                    !isMobile() ? (
                        <Filters 
                            filters={filters} 
                            updateFilters={updateFilters} 
                        />
                    ) : undefined
                }
            >
                {
                    ( hasAccess(AFFILIATE_ACCESS_TYPE.GGR) || hasAccess(AFFILIATE_ACCESS_TYPE.NGR) ) && (
                        <AntTabs.TabPane
                            tab={<span>{t("backoffice.dashboard.gamingRevenue")}</span>}
                            key={FINANCIAL_ENTITY_TYPE.GGR}
                        >
                            <LineChart
                                configs={configs}
                                hideChart={chartData.length === 0}
                                isLoading={isLoading}
                            />
                        </AntTabs.TabPane>
                    )
                }

                {
                    ( hasAccess(AFFILIATE_ACCESS_TYPE.BET_AMOUNT) ) && (
                        <AntTabs.TabPane
                            tab={<span>{t("backoffice.dashboard.turnover")}</span>}
                            key={FINANCIAL_ENTITY_TYPE.TURNOVER}
                        >
                            <LineChart
                                configs={configs}
                                hideChart={chartData.length === 0}
                                isLoading={isLoading}
                            />
                        </AntTabs.TabPane>
                    )
                }

                {
                    ( 
                        hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_AMOUNT) ||
                        hasAccess(AFFILIATE_ACCESS_TYPE.FTD_AMOUNT) ||
                        hasAccess(AFFILIATE_ACCESS_TYPE.NET_DEPOSIT_AMOUNT)
                    ) && (
                        <AntTabs.TabPane
                            tab={<span>{t("backoffice.dashboard.playerDeposit")}</span>}
                            key={FINANCIAL_ENTITY_TYPE.DEPOSIT_AMOUNT}
                        >
                            <LineChart
                                configs={configs}
                                hideChart={chartData.length === 0}
                                isLoading={isLoading}
                            />
                        </AntTabs.TabPane>
                    )
                }
            </AntTabs>
        </div>
    )
}

FinancialLineChart.propTypes = {
    updateLastUpdatedDate: PropTypes.func,
    filters: PropTypes.object,
    updateFilters: PropTypes.func,
    currencyCode: PropTypes.string,
    currencies: PropTypes.arrayOf(currencyType)
};

export default memo(FinancialLineChart);