import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Spin } from "antd";

import Tabs from 'components/common/tabs';
import Icon from 'components/common/icon';

import ServiceComponent from "./service.component";

import { getMonitoringServices } from "store/actions/portal/developer/monitoring/monitoring.action";

import monitoringType from "types/monitoring/monitoring.type";

/** Monitoring Page Component */
const MonitoringComponent = ({
    getMonitoringServices,
    services,
    isLoading
}) => {

    const { t } = useTranslation();

    useEffect(() => {
        getMonitoringServices();
    }, [])

    const items = services.map(serv => (
        {
            title: (
                <div className="rt--flex rt--align-center">
                    <span>{serv.name}</span>
                    {
                        serv.totalFailed > 1 && (
                            <Icon name="alert" className={"rt--ml-4 " + (serv.totalFailed > 10 ? "rt--error-text" : "rt--success-text")} />
                        )
                    }
                </div>
            ),
            component: <ServiceComponent monitoring={services.find(s => s.id === serv.id)} />
        }
    ))

    if (isLoading) {
        return <Spin spinning />
    }

    return (
        <Tabs
            items={items}
            mainPage={
                {
                    title: t('backoffice.menu.serviceMonitoring')
                }
            }
            destroyInactiveTabPane={true}
        />
    )
};

/** MonitoringComponent propTypes
 * PropTypes
*/
MonitoringComponent.propTypes = {
    /**Redux action to get all monitoring services in system */
    getMonitoringServices: PropTypes.func,
    /**Redux state property, represents the array of all monitoring services */
    services: PropTypes.arrayOf(monitoringType),
    /** Redux state property, is true when loading Monitoring Services */
    isLoading: PropTypes.bool,
};

const mapDispatchToProps = dispatch => ({
    getMonitoringServices: () => {
        dispatch(getMonitoringServices())
    }
});

const mapStateToProps = (state) => {
    return {
        services: state.monitoring.services,
        isLoading: state.monitoring.isLoading,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MonitoringComponent);
