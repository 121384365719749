import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MenuItem from './item';
import MenuSkeleton from './menuSkeleton';

import menuItemsType from 'types/promo/menuItems.type';

const Menu = ({
    items,
    onItemClick,
    activeItemKey,
    unsavedTabs,
}) => {
    const { t } = useTranslation();

    if (items.length === 0) {
        return (
           <MenuSkeleton />
        )
    }

    return (
        <div className="rt--customize-widget-main-menu rt--pt-8 rt--pb-8">
            {items.map(item => {
                if (!Boolean(item.menuItemComponent)) {
                    return (
                        <MenuItem
                            key={item.key}
                            isActive={item.key === activeItemKey}
                            text={t(item.text)}
                            onItemClick={() => onItemClick(item.key)}
                            hasUnsavedChanges={unsavedTabs.includes(item.key)}
                        />
                    )
                }

                return createElement(
                    item.menuItemComponent,
                    {
                        onItemClick: () => onItemClick(item.key),
                        isActive: item.key === activeItemKey,
                        key: item.key,
                        text: t(item.text),
                        hasUnsavedChanges: unsavedTabs.includes(item.key)
                    }
                )
            })}
        </div>
    );
}

/** Menu propTypes
 * PropTypes
*/
Menu.propTypes = {
    /** Fire when item is clicked */
    onItemClick: PropTypes.func,
    /** key of active item */
    activeItemKey: PropTypes.string,
    /** Items that should be rendered in menu */
    items: PropTypes.arrayOf(menuItemsType),
    /** Unsaved Tabs */
    unsavedTabs: PropTypes.arrayOf(PropTypes.string),
};

export default Menu;