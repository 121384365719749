import React from "react";

import SystemLanguagesComponent from "pages/settings/systemLanguages/systemLanguages.component";
import withPermission from "hocs/withPermission";
import withAuth from "hocs/withAuth";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const SystemLanguagesRoute = () => {
    return <SystemLanguagesComponent />;
};

export default withPermission(
    withAuth(SystemLanguagesRoute),
    {
        resource: PERMISSION_RESOURCE.SYSTEM_LANGUAGES,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.PORTAL
);
