import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setPermissionGroupsActionBefore, setPermissionGroupsActionFinished, setPermissionGroupsSaveActionBefore, setPermissionGroupsSaveActionFinished } from './permissionGroups.action';

import { SET_PERMISSION_GROUP_GENERAL_INFO } from "../../../../actionTypes";

const setPermissionGroupGeneralInfo = info => ({
    type: SET_PERMISSION_GROUP_GENERAL_INFO,
    payload: { info }
})

export const getPermissionGroupGeneralInfo = id => {
    return dispatch => {
        dispatch(setPermissionGroupsActionBefore());

        return axios({
            url: ApiUrls.GET_PERMISSION_GROUP_PERMISSIONS,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: info}}) => {
            dispatch(setPermissionGroupGeneralInfo(info))
            dispatch(setPermissionGroupsActionFinished());
        })
        .catch((ex) => {
            dispatch(setPermissionGroupsActionFinished());
        })
    }
}

export const savePermissionGroupGeneralInfo = permissionGroup => {
    return dispatch => {
        dispatch(setPermissionGroupsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PERMISSION_GROUP_PERMISSIONS,
            method: Methods.POST,
            data: {
                ...permissionGroup
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setPermissionGroupGeneralInfo(data.value));
            } 
            dispatch(setPermissionGroupsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setPermissionGroupsSaveActionFinished());
        })
    }
}
