import axios from 'axios';

import Methods from 'constants/methods.constants';
import ApiUrls from 'constants/api.constants';

import sessionStorage from 'utils/sessionStorage';

import { 
    SET_PUBLISH_LANGUAGES_ACTION_BEFORE,
    SET_PUBLISH_LANGUAGES_ACTION_FINISH,
    SET_PUBLISH_LANGUAGES,
    SET_TRANSLATIONS_LOADED 
} from "../../actionTypes";

const setLanguagesActionBefore = () => ({
    type: SET_PUBLISH_LANGUAGES_ACTION_BEFORE,
});

const setLanguagesActionFinished = () => ({
    type: SET_PUBLISH_LANGUAGES_ACTION_FINISH,
});

const setLanguages = data => ({
    type: SET_PUBLISH_LANGUAGES,
    payload: { data }
})

export const getLanguages = () => {
    return ( dispatch, getState)  => {
        const languages = getState().auth.translations.languages;
        if(Object.keys(languages).length > 0){
            return;
        }

        dispatch(setLanguagesActionBefore());
        return axios({
            url: ApiUrls.GET_SYSTEM_AVAILABLE_PUBLISHED_LANGUAGES,
            method: Methods.GET
        })
        .then(({data : {value: languages}}) => {
            dispatch(setLanguages(languages));
            sessionStorage.set("languages", languages);
            dispatch(setLanguagesActionFinished());
        })
        .catch(() => {
            dispatch(setLanguagesActionFinished());
        })
    }
}

export const setTranslationsLoaded = () => ({
    type: SET_TRANSLATIONS_LOADED,
})