import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";


import {
    SET_PLAYERS_ACTION_BEFORE,
    SET_PLAYERS_ACTION_FINISH,
    SET_PLAYERS,
    SET_PLAYERS_SORTING,
    SET_PLAYERS_FILTERS,
    SET_PLAYERS_SAVE_ACTION_BEFORE,
    SET_PLAYERS_SAVE_ACTION_FINISH,
    SET_UPDATE_PLAYER_DATA,
    SET_PLAYER_GENERAL_INFO_BY_EXTERNAL_ID
} from "../../../actionTypes";

export const setPlayersActionBefore = () => ({
    type: SET_PLAYERS_ACTION_BEFORE,
});

export const setPlayersActionFinished = () => ({
    type: SET_PLAYERS_ACTION_FINISH,
});

export const setPlayersSaveActionBefore = () => ({
    type: SET_PLAYERS_SAVE_ACTION_BEFORE,
});

export const setPlayersSaveActionFinished = () => ({
    type: SET_PLAYERS_SAVE_ACTION_FINISH,
});

const setPlayers = (players, add) => ({
    type: SET_PLAYERS,
    payload: { players, add },
});

export const setPlayersSorting = sorting => ({
    type: SET_PLAYERS_SORTING,
    payload: { sorting },
});

export const setPlayersFilters = filters => ({
    type: SET_PLAYERS_FILTERS,
    payload: { filters },
});

const setUpdatePlayerData = ( data, id ) => ({
    type: SET_UPDATE_PLAYER_DATA,
    payload: { data, id },
});


export const getPlayers = nextPage => {
    return (dispatch, getState) => {
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().players.sorting.page + 1 : getState().players.sorting.page;
        let params = {
            ...getState().players.sorting,
            ...getState().players.filters,
            page: page
        };

        if(page > 1 && getState().players.total <= (page - 1 ) * getState().players.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().players.total === 0){
            return Promise.resolve();
        }

        let currenciesStr = "";
        let currencies = getState().players.filters.currencies;

        if (currencies?.length > 0) {
            currencies.forEach(code => {
                currenciesStr += `${currenciesStr !== "" ? "&" : ""}currencies=${code}`
            })
        }
        
        delete params.currencies;
        
        dispatch(setPlayersActionBefore());

        return axios({
            url: `${ApiUrls.GET_PLAYERS}?${currenciesStr}`,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: players } }) => {
                dispatch(setPlayers(players, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setPlayersSorting({ ...getState().players.sorting, page: page }));
                dispatch(setPlayersActionFinished());
            })
            .catch((ex) => {
                dispatch(setPlayersActionFinished());
            });
    }
}


export const updatePlayerData = id => {
    return dispatch => {
        dispatch(setPlayersActionBefore());

        return axios({
            url: ApiUrls.GET_PLAYER_GENERAL_INFO,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({ data }) => {
            if(data.value) {
                dispatch(setUpdatePlayerData(data.value, id))
            }
            dispatch(setPlayersActionFinished());
        })
        .catch((ex) => {
            dispatch(setPlayersActionFinished());
        })
    }
}


/** Get player by external Id */
const setPlayerGeneralInfoByExternalId = info => ({
    type: SET_PLAYER_GENERAL_INFO_BY_EXTERNAL_ID,
    payload: { info }
})

export const getPlayerGeneralInfoByExternalId = ( params, onSuccess ) => {
    return dispatch => {
        dispatch(setPlayersActionBefore());

        return axios({
            url: ApiUrls.GET_PLAYER_GENERAL_INFO_BY_EXTERNAL_ID,
            method: Methods.GET,
            params: {
                ...params
            }
        })
        .then(({data}) => {
            dispatch(setPlayerGeneralInfoByExternalId(data.value ? { ...data.value, verificationCode: params.verificationCode} : null));

            onSuccess && onSuccess(data.value);

            dispatch(setPlayersActionFinished());
        })
        .catch((ex) => {
            dispatch(setPlayersActionFinished());
        })
    }
}

export const resetPlayerGeneralInfoByExternalId = () => {
    return dispatch => {
        dispatch(setPlayerGeneralInfoByExternalId(null));
    }
}
