import React from 'react';
import PropTypes from 'prop-types';

import StandardView from './standardView';
import DropdownView from './dropdownView';

import { isMobile } from "utils/common";

const splitActions = (actions, maxNumberOfActionsInRowForMobile) => {
    let visibleActions = [], dropdownActions = [];

    if(actions.length > 0){
        if(isMobile()){
            if (actions.length <= maxNumberOfActionsInRowForMobile) {
                visibleActions = [...actions]
            } else {
                visibleActions = actions.slice( 0 - maxNumberOfActionsInRowForMobile + 1)
                dropdownActions = actions.slice(0, actions.length - maxNumberOfActionsInRowForMobile + 1)
            }
        } else {
            if(actions.length === 1){
                visibleActions = [...actions]
            } else {
                const deepViewAction = actions.find(a => a.icon === "right");
                if(deepViewAction){
                    visibleActions = [deepViewAction];
                    dropdownActions = actions.filter(a => a.icon !== "right");
                } else {
                    dropdownActions = [...actions]
                }
            }
            
        }
    }

    return {
        visible: visibleActions,
        dropdown: dropdownActions
    }
}

const CommonActions = ({ 
    rowData, 
    actions, 
    maxNumberOfActionsInRowForMobile = 2 
}) => {
    // In the actionData object, the disabled property is a function
    const activeActions = actions.filter(actionData => !actionData.disabled?.(rowData));

    if (activeActions.length === 0) {
        return null;
    }

    const splitedActions = splitActions(activeActions, maxNumberOfActionsInRowForMobile)

    return isMobile() ? (
        <div className='rt--flex rt--flex-col'>
            <StandardView actions={splitedActions.visible} rowData={rowData} />
            {
                splitedActions.dropdown.length > 0 && (
                    <DropdownView actions={splitedActions.dropdown} rowData={rowData} />
                )
            }
        </div>
    ) : (
        <div className='rt--flex rt--align-center rt--justify-end'>
            {
                splitedActions.dropdown.length > 0 && (
                    <DropdownView actions={splitedActions.dropdown} rowData={rowData} />
                )
            }
            <StandardView actions={splitedActions.visible} rowData={rowData} />
        </div>
    )
}

/** CommonActions propTypes
 * PropTypes
*/
CommonActions.propTypes = {
    actions: PropTypes.array,
    rowData: PropTypes.object,
    maxNumberOfActionsInRowForMobile: PropTypes.number
}

export default CommonActions;