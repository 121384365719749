import {
    SET_NEWSLETTER_ACTION_BEFORE,
    SET_NEWSLETTER_ACTION_FINISH,
    SET_NEWSLETTER_SAVE_ACTION_BEFORE,
    SET_NEWSLETTER_SAVE_ACTION_FINISH,
    SET_NEWSLETTER_TEMPLATES,
    SET_NEWSLETTER_TEMPLATES_SORTING,
    SET_NEWSLETTER_TEMPLATES_FILTERS,
    SET_NEWSLETTER_AFFILIATES_ACTION_BEFORE,
    SET_NEWSLETTER_AFFILIATES_ACTION_FINISH,
    SET_NEWSLETTER_AVAILABLE_AFFILIATES,
    SET_NEWSLETTER_AFFILIATES,
    SET_NEWSLETTER_AFFILIATES_SORTING,
    SET_NEWSLETTERS,
    SET_NEWSLETTERS_SORTING,
    SET_NEWSLETTERS_FILTERS,
    SET_NEWSLETTER_GENERAL_INFO,
    SET_NEWSLETTER_SEND_TEST_ACTION_FINISH,
    SET_NEWSLETTER_SEND_TEST_ACTION_BEFORE
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_NEWSLETTER_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_NEWSLETTER_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_NEWSLETTER_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_NEWSLETTER_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_NEWSLETTER_TEMPLATES:
            return {
                ...state,
                templates: {
                    ...state.templates,
                    templates: !payload.add ? payload.templates.item2 : state.templates.templates.concat(payload.templates.item2),
                    total: payload.templates.item1
                }
            };
        case SET_NEWSLETTER_TEMPLATES_SORTING:
            return {
                ...state,
                templates: {
                    ...state.templates,
                    sorting: payload.sorting,
                }
            };
        case SET_NEWSLETTER_TEMPLATES_FILTERS:
            return {
                ...state,
                templates: {
                    ...state.templates,
                    filters: payload.filters,
                    sorting: {
                        ...state.templates.sorting,
                        page: 1
                    }
                }
            };

        case SET_NEWSLETTER_AFFILIATES_ACTION_BEFORE:
            return {
                ...state,
                affiliates: {
                    ...state.affiliates,
                    isLoading: true,
                }
            };
        case SET_NEWSLETTER_AFFILIATES_ACTION_FINISH:
            return {
                ...state,
                affiliates: {
                    ...state.affiliates,
                    isLoading: false,
                }
            };
        case SET_NEWSLETTER_AVAILABLE_AFFILIATES:
            return {
                ...state,
                affiliates: {
                    ...state.affiliates,
                    availableAffiliates: payload.affiliates,
                }
            };
        case SET_NEWSLETTER_AFFILIATES:
            return {
                ...state,
                affiliates: {
                    ...state.affiliates,
                    affiliates: !payload.add ? payload.affiliates.item2 : state.affiliates.affiliates.concat(payload.affiliates.item2),
                    total: payload.affiliates.item1,
                }
            };
            
        case SET_NEWSLETTER_AFFILIATES_SORTING:
            return {
                ...state,
                affiliates: {
                    ...state.affiliates,
                    sorting: payload.sorting
                },
            };


        case SET_NEWSLETTERS:
            return {
                ...state,
                newsletters: {
                    ...state.newsletters,
                    newsletters: !payload.add ? payload.newsletters.item2 : state.newsletters.newsletters.concat(payload.newsletters.item2),
                    total: payload.newsletters.item1
                }
            };
        case SET_NEWSLETTERS_SORTING:
            return {
                ...state,
                newsletters: {
                    ...state.newsletters,
                    sorting: payload.sorting,
                }
            };
        case SET_NEWSLETTERS_FILTERS:
            return {
                ...state,
                newsletters: {
                    ...state.newsletters,
                    filters: payload.filters,
                    sorting: {
                        ...state.newsletters.sorting,
                        page: 1
                    }
                }
            };
        case SET_NEWSLETTER_SEND_TEST_ACTION_BEFORE:
            return {
                ...state,
                newsletters: {
                    ...state.newsletters,
                    isTestSending: true
                }
            };
        case SET_NEWSLETTER_SEND_TEST_ACTION_FINISH:
            return {
                ...state,
                newsletters: {
                    ...state.newsletters,
                    isTestSending: false
                }
            };
        case SET_NEWSLETTER_GENERAL_INFO:
            return {
                ...state,
                newsletters: {
                    ...state.newsletters,
                    edit: {
                        ...state.newsletters.edit,
                        general: payload.info
                    }
                }
            };
        default:
            return state;
    }
}