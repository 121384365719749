import PropTypes from 'prop-types';

import { AFFILIATE_GROUP_TYPE } from 'constants/affiliate.constants';

export default PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    groupType: PropTypes.oneOf(Object.values(AFFILIATE_GROUP_TYPE)),
    createdAt: PropTypes.string,
    createdBy: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
    isRemovable: PropTypes.bool
})