import PropTypes from 'prop-types';

import { 
    COMMISSION_PLAN_CALCULATION_PERIOD, 
    INVOICE_GENERATION_METHOD, 
    INVOICE_GENERATION_TYPE,
    INVOICE_GENERATION_PERIOD 
} from 'constants/commission.constants';

export default PropTypes.shape({
    commissionCalculationPeriod: PropTypes.oneOf(Object.values(COMMISSION_PLAN_CALCULATION_PERIOD)),
    invoiceGenerationType: PropTypes.oneOf(Object.values(INVOICE_GENERATION_TYPE)),
    invoiceGenerationMethod: PropTypes.oneOf(Object.values(INVOICE_GENERATION_METHOD)),
    invoiceGenerationPeriod: PropTypes.oneOf(Object.values(INVOICE_GENERATION_PERIOD)),
    nextInvoiceGenerationDate: PropTypes.string,
    nextCalculationDate: PropTypes.string,
    lastCalculationDate: PropTypes.string,
    approveCommissionManually: PropTypes.bool,
    isNegativeCarryOverEnabled: PropTypes.bool,
    allowNegativeCarryOverImpactCPA: PropTypes.bool
})