import PropTypes from 'prop-types';

const companyType = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.number
})

export default PropTypes.shape({
    companies: PropTypes.arrayOf(companyType),
    availableCompanies: PropTypes.arrayOf(companyType),
    mainCompany: companyType
})