import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Tabs from "components/common/tabs";

import HostsComponent from './hosts';
import SitesComponent from './sites';

/** Project Edit Page Domains Tab Component */
const DomainsComponent = ({
    onTabChange
}) => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.projects.hosts"),
            component: <HostsComponent/>
        },
        {
            title: t("backoffice.projects.sites"),
            component: <SitesComponent />
        }
    ]

    return (
        <Tabs.SubTabs 
            items={items}
            onTabChange={onTabChange}
        />
    )
}

/** DomainsComponent propTypes
    * PropTypes
*/
DomainsComponent.propTypes = {
    /** Fires when for any form in tab, saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

export default DomainsComponent;
