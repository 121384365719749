//#region react
import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { Form, Row, Col, Spin } from 'antd';

import CustomizeImgUploader from "../../customizeImgUploader";

import { NEWSLETTER_FILE_NAMES } from "constants/newsletter.constants";

/** Portal Customize Page Sections Logo Editor Component */
const LogoEditor = ({
    isLoading,
    files,
}) => {
    const { t } = useTranslation();

    const searchParams = useParams();

    return (
        <Spin spinning={isLoading}>
            <div>
                <Form
                    className="rt--form"
                    colon={false}
                    requiredMark={false}
                    layout="vertical"
                >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label={t("backoffice.promo.uploadLogo")}
                            >
                                <CustomizeImgUploader
                                    name={NEWSLETTER_FILE_NAMES.LOGO}
                                    imgPath={files[NEWSLETTER_FILE_NAMES.LOGO]}
                                    id={searchParams.id}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Spin>
    );
};

/** LogoEditor propTypes
 * PropTypes
*/
LogoEditor.propTypes = {
    /** Redux state property, is true when loading newsletter customization data */
    isLoading: PropTypes.bool,
    /** Images Paths */
    files: PropTypes.objectOf(PropTypes.string)
};


const mapStateToProps = (state) => {
    return {
        isLoading: state.newsletterCustomize.isLoading,
        files: state.newsletterCustomize.configuration.files
    };
};

export default connect(mapStateToProps, null)(LogoEditor);
