import React from 'react';

import SelectMobile from './mobile';
import SelectDesktop from './desktop';

import { isMobile } from "utils/common";

const Select = ({ ...props }) => {
    return (
        isMobile()
            ? <SelectMobile {...props} />
            : <SelectDesktop {...props} />
    );
}

export default Select;