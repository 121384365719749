import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { USER_STATE } from 'constants/user.constants';

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";
import LanguageUtils from 'utils/languages';
import { constructAffiliatesWalletData } from 'utils/affiliates';

import { getAffiliateGeneralInfo } from "./general.action";

import {
    SET_AFFILIATES_ACTION_BEFORE,
    SET_AFFILIATES_ACTION_FINISH,
    SET_AFFILIATES_SAVE_ACTION_BEFORE,
    SET_AFFILIATES_SAVE_ACTION_FINISH,
    SET_AFFILIATES,
    SET_AFFILIATES_SORTING,
    SET_AFFILIATES_FILTERS,
    SET_AFFILIATE_WALLETS_BY_CURRENCY
} from "../../../../actionTypes";

export const setAffiliatesActionBefore = () => ({
    type: SET_AFFILIATES_ACTION_BEFORE,
});

export const setAffiliatesActionFinished = () => ({
    type: SET_AFFILIATES_ACTION_FINISH,
});

export const setAffiliatesSaveActionBefore = () => ({
    type: SET_AFFILIATES_SAVE_ACTION_BEFORE,
});

export const setAffiliatesSaveActionFinished = () => ({
    type: SET_AFFILIATES_SAVE_ACTION_FINISH,
});

const setAffiliates = (affiliates, add) => ({
    type: SET_AFFILIATES,
    payload: { affiliates, add },
});

export const setAffiliatesSorting = sorting => ({
    type: SET_AFFILIATES_SORTING,
    payload: { sorting },
});

export const setAffiliatesFilters = filters => ({
    type: SET_AFFILIATES_FILTERS,
    payload: { filters },
});

export const setAffiliateWalletsByCurrency = wallets => ({
    type: SET_AFFILIATE_WALLETS_BY_CURRENCY,
    payload: { wallets }
});


export const getAffiliates = nextPage => {
    return (dispatch, getState) => {
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().affiliates.sorting.page + 1 : getState().affiliates.sorting.page;
        let params = {
            ...getState().affiliates.sorting,
            ...getState().affiliates.filters,
            page: page
        };

        if(page > 1 && getState().affiliates.total <= (page - 1 ) * getState().affiliates.sorting.limit){
            return Promise.resolve();
        }
        
        dispatch(setAffiliatesActionBefore());

        let countriesStr = "";
        let countries = getState().affiliates.filters.countries;

        if (countries?.length > 0) {
            countries.forEach(c => {
                countriesStr += `${countriesStr !== "" ? "&" : ""}countries=${c}`
            })
        }
        let currenciesStr = "";
        let currencies = getState().affiliates.filters.currencies;

        if (currencies?.length > 0) {
            currencies.forEach(code => {
                currenciesStr += `${currenciesStr !== "" ? "&" : ""}currencies=${code}`
            })
        }

        delete params.countries;
        delete params.currencies;

        return axios({
            url: `${ApiUrls.GET_AFFILIATES}?${countriesStr}${Boolean(currenciesStr) ? `&${currenciesStr}` : ""}`,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: affiliates } }) => {
                affiliates.item2 = constructAffiliatesWalletData(affiliates.item2);

                dispatch(setAffiliates(affiliates, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setAffiliatesSorting({ ...getState().affiliates.sorting, page: page }));
                dispatch(setAffiliatesActionFinished());
            })
            .catch((ex) => {
                dispatch(setAffiliatesActionFinished());
            });
    }
}

export const createAffiliate = (data, token, onSuccess) => {
    return (dispatch) => {
        dispatch(setAffiliatesSaveActionBefore());

        return axios({
            url: ApiUrls.CREATE_AFFILIATE,
            method: Methods.POST,
            data: { 
                ... data,
                token,
                languageCode: LanguageUtils.getSelectedLanguage()
            }
        })
            .then(({ data }) => {
                dispatch(setAffiliatesSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess(data.value);
                }
            })
            .catch(() => {
                dispatch(setAffiliatesSaveActionFinished());
            });
    };
};

export const changeAffiliateInactiveState = (params) => {
    return (dispatch) => {
        const { type, ...requestBody } = params;

        dispatch(setAffiliatesSaveActionBefore());

        return axios({
            url: type === USER_STATE.ACTIVE ? ApiUrls.APPROVE_AFFILIATE : ApiUrls.DECLINE_AFFILIATE,
            method: Methods.POST,
            data: requestBody
        })
            .then(({ data }) => {
                dispatch(setAffiliatesSaveActionFinished());
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    dispatch(getAffiliateGeneralInfo(params.id));
                }
            })
            .catch(() => {
                dispatch(setAffiliatesSaveActionFinished());
            });
    };
}

export const changeAffiliateState = d => {
    return dispatch => {
        dispatch(setAffiliatesSaveActionBefore());
        return axios({
            url: ApiUrls.CHANGE_ACCOUNT_STATE,
            method: Methods.POST,
            data: d
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getAffiliateGeneralInfo(d.id));
            } 
            dispatch(setAffiliatesSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliatesSaveActionFinished());
        })
    }
}

export const forceLogout = () => {
    return (dispatch, getState) => {
        dispatch(setAffiliatesSaveActionBefore());

        const filters = { ... getState().affiliates.filters };

        Object.keys(filters).map(k => {
            if(
                (
                    filters[k] === "" || filters[k] === undefined
                )
            ){
                delete filters[k];
            }
        })

        if(Array.isArray(filters.countries) && filters.countries.length === 0){
            filters.countries = null;
        }

        if(Array.isArray(filters.currencies) && filters.currencies.length === 0){
            filters.currencies = null;
        }

        return axios({
            url: ApiUrls.FORCE_LOGOUT_AFFILIATE,
            method: Methods.POST,
            data: {
                ... getState().affiliates.sorting,
                ... filters,
                page: getState().affiliates.sorting.page
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getAffiliates());
            } 
            dispatch(setAffiliatesSaveActionFinished());
        })
        .catch(() => {
            dispatch(setAffiliatesSaveActionFinished());
        })
    }
}

export const unlockAffiliate = id => {
    return dispatch => {
        dispatch(setAffiliatesSaveActionBefore());
        return axios({
            url: ApiUrls.UNLOCK_ACCOUNT,
            method: Methods.POST,
            data: { 
                id
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)){
                message.success(data.message);
                dispatch(getAffiliateGeneralInfo(id));
            }
            dispatch(setAffiliatesSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliatesSaveActionFinished());
        })
    }
}




