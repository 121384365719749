import React from "react";

import PlatformTestComponent from "pages/developer/platformTest/platformTest.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const PlatformTestRoute = () => {
    return <PlatformTestComponent />;
};

export default withPermission(
    withAuth(PlatformTestRoute),
    { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
    Paths.PORTAL
);
