import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Row, Col, Spin, Switch, DatePicker } from 'antd';

import Select from 'components/common/select';
import Input from 'components/common/input';
import Table from "components/common/table";
import LockInfo from 'components/common/lockInfo';
import Icon from 'components/common/icon';

import AffiliateActions from '../../../affiliate-actions.component';
import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";

import { EMAIL_REGEX, TEL_REGEX, ADDRESS_REGEX, LAST_NAME_REGEX, FIRST_NAME_REGEX } from "constants/regex.constants";

import { getAffiliateGeneralInfo, saveAffiliateGeneralInfo } from "store/actions/portal/affiliates/affiliates/general.action";

import { personalInfoType } from "types/affiliate/generalInfo.type";
import userInfoType from 'types/profile/userInfo.type';

import { isMobile, flagsToBinary, generateContactChannelLink, classNames } from 'utils/common';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';
import { getUser } from 'utils/auth';

import useDate from 'hooks/useDate';

import countries from "systemData/countries";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { STATUS_TYPES } from 'constants/status.constants';
import { USER_ROLE, USER_STATE } from 'constants/user.constants';
import { DEFAULT_UNIQUE_PROP } from 'components/common/table/constants';
import { getContactsTableColumns } from './columns';


const FORM_FIELDS_NAMES = {
    USERNAME: "userName",
    EMAIL: "email",
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    PHONE_NUMBER: "phoneNumber",
    COUNTRY: "country",
    CITY: "city",
    REGION: "region",
    STREET: "address",
    ZIP_CODE: "zipCode",
    BIRTH_DATE: "birthDate",
    REGISTRATION_DATE: "createdAt",
    LAST_LOGIN_DATE: "lastActiveAt",
    FORCE_PASSWORD_CHANGE: "allowEdit"
};

/** Affiliate Edit Page General Info Tab Component */
const PersonalInfo = ({
    getAffiliateGeneralInfo,
    saveAffiliateGeneralInfo,
    isSaving,
    isLoading,
    personalInfo,
    userInfo,
    onTabChange
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);
    const [contactChannelsList, setContactChannelsList] = useState([]);

    const isMobileDevice = isMobile();

    const { dateService } = useDate();

    const affiliateId = getUser()?.role === USER_ROLE.AFFILIATE ? userInfo.id : searchParams.id;

    const canEdit = hasPermission({ resource: PERMISSION_RESOURCE.AFFILIATE_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) && affiliateId !== userInfo.id

    const { mainTableColumns: contactsTableColumns } = tableColumnsCreator({
        mainColumns: getContactsTableColumns,
        additionalProps: {
            personalInfo,
            havePermissionForEdit: canEdit,
            onContactsFieldChange: (changedContactChannel) => {
                setContactChannelsList((prevChannels) => prevChannels.map((prevChannel) => {
                    if (prevChannel.type === changedContactChannel.type) {
                        return { ...changedContactChannel };
                    }

                    return { ...prevChannel };
                }));
            }
        }
    })

    const countriesOptions = useMemo(() => {
        return [
            { value: null, text: t("backoffice.common.none") },
            ...countries.map(c => ({
                value: c.iso2,
                label: (<div className={`rt--flag rt--mr-4 rt--flag-${c.iso2.toLowerCase()}`} />),
                text: t(`backoffice.countries.${c.iso2}`)
            }))
        ];
    }, [countries]);

    const formContactChannelsData = useMemo(() => {
        return contactChannelsList.reduce((acc, contactChannel) => {
            acc[contactChannel.type] = contactChannel.details;
            return acc;
        }, {})
    }, [contactChannelsList]);

    const getInitialValues = () => {
        return {
            ...personalInfo,
            [FORM_FIELDS_NAMES.BIRTH_DATE]: personalInfo[FORM_FIELDS_NAMES.BIRTH_DATE] ? dateService.makeUtcDateToLocal(personalInfo[FORM_FIELDS_NAMES.BIRTH_DATE]) : null,
            [FORM_FIELDS_NAMES.REGISTRATION_DATE]: personalInfo[FORM_FIELDS_NAMES.REGISTRATION_DATE] ? dateService.makeUtcDateToLocal(personalInfo[FORM_FIELDS_NAMES.REGISTRATION_DATE]) : null,
            [FORM_FIELDS_NAMES.LAST_LOGIN_DATE]: personalInfo[FORM_FIELDS_NAMES.LAST_LOGIN_DATE] ? dateService.makeUtcDateToLocal(personalInfo[FORM_FIELDS_NAMES.LAST_LOGIN_DATE]) : null,
        };
    };

    /** Check is form changed
       * @function
       * @param {object} formValues - form current values
       * @returns {boolean}
       * @memberOf PersonalInfo
   */
    const formChanged = formValues => {
        if (formValues.type !== undefined) formValues.type = flagsToBinary(formValues.type);

        const initialValues = { ...getInitialValues(), contactChannels: personalInfo.contactChannels }

        return isFormChanged({ ...formValues, contactChannels: formContactChannelsData }, initialValues);
    }

    /** Fires when form submitted
       * @function
       * @memberOf PersonalInfo
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveAffiliateGeneralInfo({
                    ...data,
                    id: affiliateId,
                    contactChannels: formContactChannelsData,
                    birthDate: data.birthDate ? dateService.toISOString(data.birthDate) : null
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }


    /** Get field max length
       * @function
       * @param {object} - control
       * @returns {number}
       * @memberOf PersonalInfo
   */
    const getFieldMaxLength = name => {
        switch (name) {
            case FORM_FIELDS_NAMES.USERNAME:
            case FORM_FIELDS_NAMES.CITY:
            case FORM_FIELDS_NAMES.ZIP_CODE:
                return 30;
            case FORM_FIELDS_NAMES.FIRST_NAME:
            case FORM_FIELDS_NAMES.LAST_NAME:
            case FORM_FIELDS_NAMES.PHONE_NUMBER:
                return 18;
            case FORM_FIELDS_NAMES.STREET:
                return 102;
            default:
                return undefined;
        }
    }

    /** Get rules for control 
       * @function
       * @param {object} - control
       * @returns {array}
       * @memberOf PersonalInfo
   */
    const getRulesForField = name => {
        const rules = [];

        switch (name) {
            case FORM_FIELDS_NAMES.EMAIL:
                rules.push({ required: true, message: t('backoffice.validation.fieldRequired') });
                rules.push({ pattern: EMAIL_REGEX, message: t('backoffice.validation.emailFormat') });
                break;
            case FORM_FIELDS_NAMES.FIRST_NAME:
                rules.push({ max: getFieldMaxLength(name), message: t('backoffice.validation.fieldInvalid') });
                rules.push({ pattern: FIRST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid') });
                break;
            case FORM_FIELDS_NAMES.LAST_NAME:
                rules.push({ max: getFieldMaxLength(name), message: t('backoffice.validation.fieldInvalid') });
                rules.push({ pattern: LAST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid') });
                break;
            case FORM_FIELDS_NAMES.PHONE_NUMBER:
                rules.push({ max: getFieldMaxLength(name), message: t('backoffice.validation.fieldInvalid') });
                rules.push({ pattern: TEL_REGEX, message: t('backoffice.validation.telFormat') });
                break;
            case FORM_FIELDS_NAMES.STREET:
                rules.push({ max: getFieldMaxLength(name), message: t('backoffice.validation.fieldInvalid') });
                rules.push({ pattern: ADDRESS_REGEX, message: t('backoffice.validation.fieldInvalid') });
                break;
            case FORM_FIELDS_NAMES.CITY:
                rules.push({ max: getFieldMaxLength(name), message: t('backoffice.validation.fieldInvalid') });
                break;
            default:
                break;
        }

        return rules;
    }

    /** Load Affiliate general info */
    useEffect(() => {
        getAffiliateGeneralInfo(affiliateId)
    }, []);

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue(getInitialValues());

        setContactChannelsList(Object.keys(personalInfo?.contactChannels || {}).map((key) => {
            const value = personalInfo.contactChannels[key];

            return {
                [DEFAULT_UNIQUE_PROP]: `${key}_${value}`,
                type: Number(key),
                details: value,
                link: generateContactChannelLink(Number(key), value)
            }
        }));
    }, [personalInfo])

    /** Checking isFormTouched case for Contact Channels Table */
    useEffect(() => {
        if (Object.keys(personalInfo).length !== 0 && Object.keys(formContactChannelsData) !== 0) {
            setIsFormTouched(isFormChanged({ ...formContactChannelsData }, { ...personalInfo.contactChannels }));
        }
    }, [personalInfo, formContactChannelsData]);

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: canEdit,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
            actions={<AffiliateActions />}
            id={personalInfo.id}
            longId={personalInfo.longId}
            status={{
                status: personalInfo.state,
                type: STATUS_TYPES.AFFILIATE,
                icon: [USER_STATE.LOCKED_LOGIN, USER_STATE.LOCKED_2_FA].includes(personalInfo.state) || personalInfo.stateChangeReason ? <Icon name="info" size={16} className="rt--ml-4" /> : null,
                tooltip: (
                    [USER_STATE.LOCKED_2_FA, USER_STATE.LOCKED_LOGIN].includes(personalInfo.state) ?
                        (
                            <LockInfo
                                is2FALocked={personalInfo?.loginAttemptDetails?.is2FALocked}
                                lastLogin={personalInfo?.lastLogin}
                                lockTime={personalInfo?.loginAttemptDetails?.lockTime}
                            />
                        ) : personalInfo.stateChangeReason ? personalInfo.stateChangeReason : null
                )
            }}
        >

            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(changed, formValues) => setIsFormTouched(formChanged({ ...formValues }))}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.affiliates.userName')}
                                name={FORM_FIELDS_NAMES.USERNAME}
                                className="rt--general-form-item rt--form-item-disabled"
                            >
                                <Input
                                    disabled={true}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.affiliates.email')}
                                name={FORM_FIELDS_NAMES.EMAIL}
                                rules={getRulesForField(FORM_FIELDS_NAMES.EMAIL)}
                                validateFirst
                                className={classNames("rt--general-form-item", "rt--form-item-with-suffix", !canEdit && 'rt--form-item-disabled')}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.affiliates.email')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.affiliates.email')}`}
                                    suffix={personalInfo?.isEmailVerified && <Icon name='success' className='rt--success-text' />}
                                    disabled={!canEdit}
                                    maxLength={getFieldMaxLength(FORM_FIELDS_NAMES.EMAIL)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={`${t('backoffice.affiliates.firstName')}`}
                                name={FORM_FIELDS_NAMES.FIRST_NAME}
                                rules={getRulesForField(FORM_FIELDS_NAMES.FIRST_NAME)}
                                validateFirst
                                className={classNames("rt--general-form-item", !canEdit && 'rt--form-item-disabled')}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.affiliates.firstName')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.affiliates.firstName')}`}
                                    maxLength={getFieldMaxLength(FORM_FIELDS_NAMES.FIRST_NAME)}
                                    disabled={!canEdit}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={`${t('backoffice.affiliates.lastName')}`}
                                name={FORM_FIELDS_NAMES.LAST_NAME}
                                rules={getRulesForField(FORM_FIELDS_NAMES.LAST_NAME)}
                                validateFirst
                                className={classNames("rt--general-form-item", !canEdit && 'rt--form-item-disabled')}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.affiliates.lastName')}`}
                                    maxLength={getFieldMaxLength(FORM_FIELDS_NAMES.LAST_NAME)}
                                    disabled={!canEdit}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.affiliates.phoneNumber')}
                                name={FORM_FIELDS_NAMES.PHONE_NUMBER}
                                rules={getRulesForField(FORM_FIELDS_NAMES.PHONE_NUMBER)}
                                validateFirst={true}
                                className={classNames("rt--general-form-item", !canEdit && 'rt--form-item-disabled')}
                                data-placeholder={canEdit ? `${t('backoffice.common.enter')} ${t('backoffice.affiliates.phoneNumber')}` : ""}
                            >
                                <Input
                                    placeholder={canEdit ? `${t('backoffice.common.enter')} ${t('backoffice.affiliates.phoneNumber')}` : ""}
                                    maxLength={getFieldMaxLength(FORM_FIELDS_NAMES.PHONE_NUMBER)}
                                    disabled={!canEdit}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.affiliates.country')}
                                name={FORM_FIELDS_NAMES.COUNTRY}
                                rules={getRulesForField(FORM_FIELDS_NAMES.COUNTRY)}
                                validateFirst={true}
                                className={classNames(" ", !canEdit && 'rt--form-item-disabled')}
                                data-placeholder={`${t('backoffice.common.select')} ${t('backoffice.affiliates.country')}`}
                            >
                                <Select
                                    options={countriesOptions}
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.affiliates.country')}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                    search={true}
                                    disabled={!canEdit}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.affiliates.city')}
                                name={FORM_FIELDS_NAMES.CITY}
                                rules={getRulesForField(FORM_FIELDS_NAMES.CITY)}
                                validateFirst={true}
                                className={classNames("rt--general-form-item", !canEdit && 'rt--form-item-disabled')}
                                data-placeholder={canEdit ? `${t('backoffice.common.enter')} ${t('backoffice.affiliates.city')}` : ""}
                            >
                                <Input
                                    placeholder={canEdit ? `${t('backoffice.common.enter')} ${t('backoffice.affiliates.city')}` : ""}
                                    maxLength={getFieldMaxLength(FORM_FIELDS_NAMES.CITY)}
                                    disabled={!canEdit}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.affiliates.state')}
                                name={FORM_FIELDS_NAMES.REGION}
                                rules={getRulesForField(FORM_FIELDS_NAMES.REGION)}
                                validateFirst={true}
                                className={classNames("rt--general-form-item", !canEdit && 'rt--form-item-disabled')}
                                data-placeholder={canEdit ? `${t('backoffice.common.enter')} ${t('backoffice.affiliates.state')}` : ""}
                            >
                                <Input
                                    placeholder={canEdit ? `${t('backoffice.common.enter')} ${t('backoffice.affiliates.state')}` : ""}
                                    maxLength={getFieldMaxLength(FORM_FIELDS_NAMES.REGION)}
                                    disabled={!canEdit}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.affiliates.street')}
                                name={FORM_FIELDS_NAMES.STREET}
                                rules={getRulesForField(FORM_FIELDS_NAMES.STREET)}
                                validateFirst={true}
                                className={classNames("rt--general-form-item", !canEdit && 'rt--form-item-disabled')}
                                data-placeholder={canEdit ? `${t('backoffice.common.enter')} ${t('backoffice.affiliates.street')}` : ""}
                            >
                                <Input
                                    placeholder={canEdit ? `${t('backoffice.common.enter')} ${t('backoffice.affiliates.street')}` : ""}
                                    maxLength={getFieldMaxLength(FORM_FIELDS_NAMES.STREET)}
                                    disabled={!canEdit}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.affiliates.postalCode')}
                                name={FORM_FIELDS_NAMES.ZIP_CODE}
                                rules={getRulesForField(FORM_FIELDS_NAMES.ZIP_CODE)}
                                validateFirst={true}
                                className={classNames("rt--general-form-item", !canEdit && 'rt--form-item-disabled')}
                                data-placeholder={canEdit ? `${t('backoffice.common.enter')} ${t('backoffice.affiliates.postalCode')}` : ""}
                            >
                                <Input
                                    placeholder={canEdit ? `${t('backoffice.common.enter')} ${t('backoffice.affiliates.postalCode')}` : ""}
                                    maxLength={getFieldMaxLength(FORM_FIELDS_NAMES.ZIP_CODE)}
                                    disabled={!canEdit}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.affiliates.dateOfBirth')}
                                name={FORM_FIELDS_NAMES.BIRTH_DATE}
                                className={classNames(" ", !canEdit && 'rt--form-item-disabled')}
                                data-placeholder={canEdit ? `${t('backoffice.common.enter')} ${t('backoffice.affiliates.postalCode')}` : ""}
                            >
                                <DatePicker
                                    className="rt--datepicker"
                                    dropdownClassName='rt--datepicker-popup'
                                    format={dateService.getFormat(false)}
                                    disabledDate={d => dateService.isAfter(d, dateService.startOfDay()) }
                                    showToday={false}
                                    showNow={false}
                                    allowClear={false}
                                    placeholder={t('backoffice.common.selectDate')}
                                    suffixIcon={<Icon name='date' />}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                    inputReadOnly={isMobile()}
                                    disabled={!canEdit}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.affiliates.registrationDate')}
                                name={FORM_FIELDS_NAMES.REGISTRATION_DATE}
                                className="rt--form-item-disabled"
                            >
                                <DatePicker
                                    className="rt--datepicker"
                                    dropdownClassName='rt--datepicker-popup'
                                    disabled={true}
                                    placeholder=''
                                    format={dateService.getFormat()}
                                    suffixIcon={<Icon name='date' />}
                                    getPopupContainer={isMobile() ? '' : () => document.getElementsByClassName("rt--portal-layout")[0]}
                                    inputReadOnly={true}
                                />
                            </Form.Item>
                        </Col>
                        {
                            personalInfo[FORM_FIELDS_NAMES.LAST_LOGIN_DATE] && (
                                <Col xs={24} sm={12} xl={6}>
                                    <Form.Item
                                        label={t('backoffice.affiliates.lastLoginDate')}
                                        name={FORM_FIELDS_NAMES.LAST_LOGIN_DATE}
                                        validateFirst={true}
                                        className="rt--form-item-disabled"
                                    >
                                        <DatePicker
                                            className="rt--datepicker"
                                            dropdownClassName='rt--datepicker-popup'
                                            disabled={true}
                                            placeholder=''
                                            format={dateService.getFormat()}
                                            suffixIcon={<Icon name='date' />}
                                            getPopupContainer={isMobile() ? '' : () => document.getElementsByClassName("rt--portal-layout")[0]}
                                            inputReadOnly={true}
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        }

                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                                <Form.Item
                                    name={FORM_FIELDS_NAMES.FORCE_PASSWORD_CHANGE}
                                    valuePropName="checked"
                                    className='rt--form-item-without-margin'
                                >
                                    <Switch
                                        disabled={!canEdit}
                                    />
                                </Form.Item>
                                <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.affiliates.forcePasswordChange')}</label>
                            </div>
                        </Col>
                    </Row>
                    {
                        contactChannelsList.length > 0 && (
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <h4
                                        className={
                                            'rt--subtab-table-layout-title rt--title rt--mb-16 rt--font-bold rt--font-biger ' +
                                            (!isMobileDevice ? "rt--mt-8" : "")
                                        }
                                    >
                                        {t("backoffice.affiliates.contactChannel")}
                                    </h4>
                                    <div className='rt--mb-24'>
                                        <Table
                                            columns={contactsTableColumns}
                                            data={contactChannelsList}
                                            noPagination={true}
                                            mobileLayoutVertical={true}
                                            disableFullView={true}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )
                    }

                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

PersonalInfo.propTypes = {
    /** Redux action to get affiliate General info */
    getAffiliateGeneralInfo: PropTypes.func,
    /** Redux action to save affiliate General info */
    saveAffiliateGeneralInfo: PropTypes.func,
    /** Redux state property, is true when general info is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when general info is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the general info of current editing affiliate  */
    personalInfo: personalInfoType,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getAffiliateGeneralInfo: id => {
            dispatch(getAffiliateGeneralInfo(id));
        },
        saveAffiliateGeneralInfo: data => {
            dispatch(saveAffiliateGeneralInfo(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        personalInfo: state.affiliates.edit.general.personal,
        isSaving: state.affiliates.isSaving,
        isLoading: state.affiliates.isLoading,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo)