//#region react
import React, { useState, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region actions
import {
    getAffiliates,
    setAffiliatesSorting,
    setAffiliatesFilters,
    forceLogout
} from "store/actions/portal/affiliates/affiliates/affiliates.action";
import { switchAccount } from "store/actions/auth/auth.action";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useCurrentProject from "hooks/useCurrentProject";
import useFormat from "hooks/useFormat";
import useCurrencyConversion from "hooks/useCurrencyConvertion";
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import Confirmation from "components/common/confirmation";
import CreateAffiliate from './affiliate-create.component';
import Filters from "./filters.component";
//#endregion

//#region utils
import { binaryToFlags, isMobile } from 'utils/common';
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from "utils/tableColumnsCreator";
import { hasAffilaiteEditPageAccess } from "utils/pageAccess";
import { getDisabledExpandRowsIds } from 'utils/affiliates';
import { getUser } from "utils/auth";
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { USER_ROLE, USER_STATE } from "constants/user.constants";
import { AFFILIATE_REG_TYPE } from "constants/project.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import Paths from 'constants/path.constants';
import ApiUrls from 'constants/api.constants';
import { AFFILIATES_LIST } from "constants/pageName.constants";
import { getTableColumns, getExpandTableColumns } from "./columns";
//#endregion

//#region types
import affiliateType from "types/affiliate/generalInfo.type";
import sortingType from "types/common/sorting.type";
import AdjustBalance from "pages/wallet/adjustBalance";
import { WALLET_TYPE } from "constants/wallet.constants";
//#endregion

const CONFIRMATION_POPUP_DEFAULT_DATA = {
    isVisible: false,
    title: "Confirmation",
    message: "",
    onOk: () => { },
}

const EXPAND_TABLE_ROW_UNIQUE_KEY = "currencyCode";

/** Affiliates Page Component */
const Affiliates = ({
    getAffiliates,
    forceLogout,
    affiliates,
    isLoading,
    total,
    setAffiliatesSorting,
    setAffiliatesFilters,
    sorting,
    filters,
    globalProjectId,
    switchAccount,
    currencyCode
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { role, allowImpactOnCPA } = getUser();

    const { formatAmount } = useFormat();
    const { convertCurrency } = useCurrencyConversion();

    const isAffiliate = role === USER_ROLE.AFFILIATE;

    const [showCreatePopup, setShowCreatePopup] = useState(null);
    const [confirmationPopupData, setConfirmationPopupData] = useState({ ...CONFIRMATION_POPUP_DEFAULT_DATA });

    const [adjustEarningBalancePopup, setAdjustEarningBalancePopup] = useState(null);

    const registerFormRef = useRef(null);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: AFFILIATES_LIST });

    const isMobileDevice = isMobile();

    const currentProject = useCurrentProject();
    const registrationType = currentProject?.registrationType ?? 0;
    const registrationTypes = binaryToFlags(Object.values(AFFILIATE_REG_TYPE), registrationType);

    const disabledExpandRowsIds = getDisabledExpandRowsIds(affiliates);

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasCreatePermission = hasPermission({ resource: PERMISSION_RESOURCE.AFFILIATE, action: PERMISSION_ACTION.CREATE });
    const hasDataExportPermission = hasPermission({ resource: PERMISSION_RESOURCE.AFFILIATE, action: PERMISSION_ACTION.EXPORT });
    const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.AFFILIATE, action: PERMISSION_ACTION.MODIFY });
    const hasWalletModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.AFFILIATE_WALLETS, action: PERMISSION_ACTION.MODIFY });

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setAffiliatesFilters({
            ...filters,
            nameOrId: value
        })
    }

    const handleDateSearchChange = value => {
        setAffiliatesFilters({
            ...filters,
            from: value[0],
            to: value[1]
        })
    }

    const handleRightArrowIconClick = (record) => {
        navigate({
            pathname: `${Paths.AFFILIATES_EDIT}/${record.id}`,
            search: `name=${record.userName}&longId=${record.longId}&state=${record.state}`
        })
    }


    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        expandTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded
    } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            mainColumns: getTableColumns,
            expandColumns: getExpandTableColumns,
            constructForInclude: true,
            constructForExport: true,
            additionalProps: {
                formatAmount,
                allowImpactOnCPA,
                currencyCode
            }
        })
    }, [includedColumns, allowImpactOnCPA, currencyCode])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (role === USER_ROLE.ADMIN || role === USER_ROLE.AFFILIATE_MANAGER) {
        if (hasModifyPermission) {
            tableRowActions.push({
                title: t("backoffice.common.loginAsAffiliate"),
                icon: "loginAsAffiliate",
                onClick: record => switchAccount(record.id),
                disabled: record => ![USER_STATE.ACTIVE, USER_STATE.LOCKED_2_FA, USER_STATE.LOCKED_LOGIN].includes(record.state),
            })
        }


        if (hasWalletModifyPermission) {
            tableRowActions.push({
                title: t("backoffice.wallet.adjustEarningBalance"),
                icon: "transfer",
                onClick: record => setAdjustEarningBalancePopup(record),
                disabled: record => ![USER_STATE.ACTIVE, USER_STATE.BLOCKED, USER_STATE.LOCKED_2_FA, USER_STATE.LOCKED_LOGIN].includes(record.state),
            })
        }


    }

    if (hasAffilaiteEditPageAccess()) {
        tableRowActions.push({
            title: t('backoffice.common.deepView'),
            icon: "right",
            onClick: handleRightArrowIconClick,
            disabled: record => record.state === USER_STATE.DELETED
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: !isAffiliate ? <Filters /> : undefined,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        search: !isAffiliate ? {
            onSearch: handleSearchChange,
            loadFn: getAffiliates,
            placeholder: t("backoffice.affiliates.usernameOrId"),
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.AFFILIATES,
                dependencies: [globalProjectId]
            }
        } : undefined,
        date: !isAffiliate ? {
            onSearch: handleDateSearchChange,
            placeholder: t('backoffice.affiliates.registrationDate'),
            showTime: false,
            loadFn: getAffiliates,
            value: [filters.from, filters.to]
        } : undefined,
        buttons: [
            {
                icon: (isMobileDevice ? "logout" : null),
                type: "secondary",
                className: "rt--button-secondary",
                onClick: () => setConfirmationPopupData({
                    isVisible: true,
                    title: t('backoffice.affiliates.forceLogout'),
                    message: t('backoffice.affiliates.forceLogoutConfirmationMessage'),
                    onOk: forceLogout
                }),
                text: t("backoffice.affiliates.forceLogout"),
                enabled: hasModifyPermission,
            },
            {
                icon: "plus",
                type: "primary",
                onClick: () => setShowCreatePopup(globalProjectId),
                enabled: hasCreatePermission && registrationTypes.includes(AFFILIATE_REG_TYPE.BY_AFFILIATE_MANAGER),
                text: t('backoffice.affiliates.createAffiliate'),
            }
        ],
        breadcrumbs: {
            items: [
                {
                    title: t('backoffice.menu.affiliates')
                }
            ],
        }
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            url: ApiUrls.EXPORT_AFFILIATES,
            columns: columnsForExport,
            filters: filters,
            tableName: t('backoffice.menu.affiliates')
        }
    }

    //#endregion

    const affiliatesData = useMemo(() => {
        return affiliates.map(affiliate => {
            const a = { 
                ...affiliate,
                earningBalance: 0,
                cpaBalance: 0 
            };

            (a.wallets ?? []).forEach(wallet => {
                if(wallet[WALLET_TYPE.EARNING_BALANCE]){
                    a.earningBalance += convertCurrency(wallet[WALLET_TYPE.EARNING_BALANCE].amount, wallet.currencyCode);
                }

                if(wallet[WALLET_TYPE.CPA_BALANCE]){
                    a.cpaBalance += convertCurrency(wallet[WALLET_TYPE.CPA_BALANCE].amount, wallet.currencyCode);
                }
            })

            return a;
        });
    }, [affiliates, convertCurrency])

    return (
        <MainDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={affiliatesData}
                loadFn={getAffiliates}
                sorting={sorting}
                setSortingFn={setAffiliatesSorting}
                total={total}
                updateProps={[globalProjectId]}
                actions={tableRowActions}
                expandable={{
                    title: t("backoffice.affiliates.wallets"),
                    disabled: disabledExpandRowsIds,
                    details: rowData => ({
                        columns: expandTableColumns,
                        data: rowData?.wallets,
                        uniqueKey: EXPAND_TABLE_ROW_UNIQUE_KEY,
                    }),
                }}
            />

            <Confirmation
                title={confirmationPopupData.title}
                message={confirmationPopupData.message}
                onOk={confirmationPopupData.onOk}
                onCancel={() => setConfirmationPopupData({ ...CONFIRMATION_POPUP_DEFAULT_DATA })}
                isVisible={confirmationPopupData.isVisible}
            />

            {showCreatePopup && (
                <CreateAffiliate
                    projectId={showCreatePopup}
                    registerFormRef={registerFormRef}
                    onClose={() => setShowCreatePopup(null)}
                />
            )}

            {
                adjustEarningBalancePopup && (
                    <AdjustBalance
                        affiliate={{
                            userName: adjustEarningBalancePopup.userName,
                            longId: adjustEarningBalancePopup.longId,
                            id: adjustEarningBalancePopup.id,
                            wallets: adjustEarningBalancePopup.wallets
                        }}
                        onClose={() => setAdjustEarningBalancePopup(null)}
                    />
                )
            }
        </MainDashboardLayout>
    );
};

/** Affiliates propTypes
 * PropTypes
*/
Affiliates.propTypes = {
    /** Redux action to get affiliates */
    getAffiliates: PropTypes.func,
    /** Redux action to force logout */
    forceLogout: PropTypes.func,
    /** Redux state property, represents the array of affiliates  */
    affiliates: PropTypes.arrayOf(affiliateType),
    /** Redux state property, is true when loading affiliates */
    isLoading: PropTypes.bool,
    /** Redux state property, affiliates total count */
    total: PropTypes.number,
    /** Redux action to set affiliates sorting details */
    setAffiliatesSorting: PropTypes.func,
    /** Redux action to set affiliates sorting details */
    setAffiliatesFilters: PropTypes.func,
    /** Redux state property, affiliates sorting details */
    sorting: sortingType,
    /** Redux state property, affiliates filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux action to switch account */
    switchAccount: PropTypes.func,
    /** Redux state property, User currency code */
    currencyCode: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    getAffiliates: nextPage => {
        dispatch(getAffiliates(nextPage));
    },
    setAffiliatesSorting: sorting => {
        dispatch(setAffiliatesSorting(sorting));
    },
    setAffiliatesFilters: filters => {
        dispatch(setAffiliatesFilters(filters));
    },
    switchAccount: id => {
        dispatch(switchAccount(id));
    },
    forceLogout: () => {
        dispatch(forceLogout());
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.affiliates.isLoading,
        affiliates: state.affiliates.affiliates,
        total: state.affiliates.total,
        sorting: state.affiliates.sorting,
        filters: state.affiliates.filters,
        globalProjectId: state.common.globalProjectId,
        currencyCode: state.profile.currency
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Affiliates);
