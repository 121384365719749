import {
    SET_NOTIFICATIONS_ACTION_BEFORE,
    SET_NOTIFICATIONS_ACTION_FINISH,
    SET_NOTIFICATIONS,
    ADD_NOTIFICATION,
    SET_NOTIFICATION_SOUND,
    DELETE_NOTIFICATION,
    MARK_AS_READ_NOTIFICATION
} from '../../actionTypes';

import sessionStorageUtils from "utils/sessionStorage";

import { NOTIFICATION_STATE_ENUM } from 'constants/notification.constants';

export default (state = {}, { type, payload }) => {
    let notifications = [];
    switch (type) {
        case SET_NOTIFICATIONS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_NOTIFICATIONS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_NOTIFICATIONS:
            sessionStorageUtils.set(
                "unreadNotificationsCount",
                payload.notifications.filter(
                    notification => notification.state === NOTIFICATION_STATE_ENUM.UNREAD
                ).length
            );

            return {
                ...state,
                notifications: payload.notifications
            }
        case ADD_NOTIFICATION:
            notifications = [...state.notifications];
            if (notifications.every(n => n.id !== payload.notification.id)) {
                notifications.unshift(payload.notification)
            }
            sessionStorageUtils.set(
                "unreadNotificationsCount",
                notifications.filter(
                    notification => notification.state === NOTIFICATION_STATE_ENUM.UNREAD
                ).length
            );
            return {
                ...state,
                notifications: notifications,
                sound: true
            }
        case SET_NOTIFICATION_SOUND:
            return {
                ...state,
                sound: payload.show
            }
        case MARK_AS_READ_NOTIFICATION:
            notifications = state.notifications.map(n => n.id !== payload.id ? n : { ...n, state: NOTIFICATION_STATE_ENUM.READ });
            sessionStorageUtils.set(
                "unreadNotificationsCount",
                notifications.filter(
                    notification => notification.state === NOTIFICATION_STATE_ENUM.UNREAD
                ).length
            );
            return {
                ...state,
                notifications: notifications
            }
        case DELETE_NOTIFICATION:
            notifications = state.notifications.filter(n => n.id !== payload.id);
            sessionStorageUtils.set(
                "unreadNotificationsCount", 
                notifications.filter(
                    notification => notification.state === NOTIFICATION_STATE_ENUM.UNREAD
                ).length
            );
            return {
                ...state,
                notifications: notifications
            }
        default:
            return state;
    }
}