import React from 'react';
import i18n from 'translations/config';

import Status from 'components/common/status';
import LockInfo from 'components/common/lockInfo';
import Icon from 'components/common/icon';

import { binaryToFlags } from 'utils/common';

import { STATUS_TYPES } from 'constants/status.constants';
import { USER_STATE } from 'constants/user.constants';
import { TARGET_MARKET, TARGET_MARKETS_TT_KEYS } from "constants/affiliate.constants";
import { WALLET_TYPE } from 'constants/wallet.constants';

export const getTableColumns = additionalProps => {

    const {
        formatAmount,
        allowImpactOnCPA,
        currencyCode
    } = additionalProps;

    return [
        {
            title: "backoffice.affiliates.id",
            dataIndex: "longId",
            copy: true,
            alwaysVisible: true,
            mobileLevel: 1
        },
        {
            title: "backoffice.affiliates.userName",
            dataIndex: "userName",
            alwaysVisible: true,
            sorter: true,
            mobileLevel: 2
        },
        {
            title: "backoffice.affiliates.firstName",
            dataIndex: "firstName",
            sorter: true,
        },
        {
            title: "backoffice.affiliates.lastName",
            dataIndex: "lastName",
            sorter: true,
        },
        {
            title: "backoffice.affiliates.email",
            dataIndex: "email",
            mobileLevel: 3,
        },
        {
            title: "backoffice.affiliates.status",
            dataIndex: "state",
            mobileLevel: 4,
            render: (value, record) => (
                <Status
                    status={value}
                    type={STATUS_TYPES.AFFILIATE}
                    icon={[USER_STATE.LOCKED_LOGIN, USER_STATE.LOCKED_2_FA].includes(value) ? <Icon name="info" size={16} className="rt--ml-4" /> : null}
                    tooltip={
                        [USER_STATE.LOCKED_2_FA, USER_STATE.LOCKED_LOGIN].includes(value) ?
                        (
                            <LockInfo
                                is2FALocked={record?.loginAttemptDetails?.is2FALocked}
                                lastLogin={record?.lastLogin}
                                lockTime={record?.loginAttemptDetails?.lockTime}
                            />
                        ) : null
                    } 
                />
            ),
        },
        {
            title: "backoffice.affiliates.manager", 
            dataIndex: "manager",
            mobileLevel: 5,
            render: value => value ?? "-"
        },
        {
            title: "backoffice.affiliates.country",
            dataIndex: "country",
            render: value => value ? (
                <div className='rt--flex rt--align-center'>
                    <div className={`rt--flag rt--mr-4 rt--flag-${value.toLowerCase()}`} />
                    {i18n.t(`backoffice.countries.${value}`)}
                </div>
            ) : "-"
        },
        {
            title: "backoffice.affiliates.players",
            dataIndex: "playersCount",
            render: value => value || 0,
            mobileLevel: 6
        },
        {
            title: "backoffice.affiliates.referredBy",
            dataIndex: "referralUserName",
            render: value => value ?? "-"
        },
        {
            title: "backoffice.affiliates.subAffiliatesCount",
            dataIndex: "subAffiliatesCount",
        },
        {
            title: "backoffice.affiliates.createdAt",
            dataIndex: "createdAt",
            isDateTime: true
        },
        {
            title: "backoffice.affiliates.lastLoginDate",
            dataIndex: "lastLogin",
            isDateTime: true
        },
        {
            title: "backoffice.affiliates.targetMarket",
            dataIndex: "targetMarket",
            multi: true,
            multiMapper: (value) => value ? binaryToFlags(
                Object.values(TARGET_MARKET), value).map((key) => i18n.t(`backoffice.affiliates.${TARGET_MARKETS_TT_KEYS[key]}`)
                ) : []
        },

        {
            title: "backoffice.wallet.earningBalance",
            dataIndex: "earningBalance",
            render: (_, record) => formatAmount( !allowImpactOnCPA ? record.earningBalance : record.earningBalance + record.cpaBalance , currencyCode),
        },

        !allowImpactOnCPA && {
            title: "backoffice.wallet.cpaBalance",
            dataIndex: "cpaBalance",
            render: value => formatAmount( value , currencyCode),
        },
    ]
}

export const getExpandTableColumns = additionalProps => {

    const {
        formatAmount,
        allowImpactOnCPA
    } = additionalProps;

    return [
        {
            title: "backoffice.affiliates.currency",
            dataIndex: "currencyCode",
            width: "25%"
        },
        {
            title: "backoffice.wallet.earningBalance",
            dataIndex: "earningBalance",
            render: (_, record) => !allowImpactOnCPA ? formatAmount(record[WALLET_TYPE.EARNING_BALANCE]?.amount ?? 0, record.currencyCode) : 
            formatAmount((record[WALLET_TYPE.EARNING_BALANCE]?.amount ?? 0) + (record[WALLET_TYPE.CPA_BALANCE]?.amount ?? 0), record.currencyCode),
            width: !allowImpactOnCPA ? "25%" : "75%"
        },
        !allowImpactOnCPA && {
            title: "backoffice.wallet.cpaBalance",
            dataIndex: "cpaBalance",
            render: (_, record) => formatAmount(record[WALLET_TYPE.CPA_BALANCE]?.amount ?? 0, record.currencyCode),
            width: "50%"
        },
    ]
}