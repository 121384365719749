import PropTypes from 'prop-types';

export default PropTypes.shape({
    id: PropTypes.string,
    longId: PropTypes.number,
    name: PropTypes.string,
    availableAmount: PropTypes.number,
    minThreshold: PropTypes.number,
    paymentCommissionPercent: PropTypes.number,
    paymentCommissionAmount: PropTypes.number,
    cpaBalance: PropTypes.number,
    earningBalance: PropTypes.number,
    paymentRequisiteDetails: PropTypes.object
})