//#region react
import React from "react";
import PropTypes from 'prop-types';

import SidebarItem from "./item";

/** Customize Page Sidebar Component */
const Sidebar = ({
    items
}) => {

    return (
        <div className="rt--customize-sidebar">
            {
                items.map((item, index) => (
                    <SidebarItem item={item} key={index}/>
                ))
            }
        </div>
    );
};

/** Sidebar propTypes
    * PropTypes
*/
Sidebar.propTypes = {
    /** Sidebar items */
    items: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.node,
        icon: PropTypes.string,
        unsaved: PropTypes.bool,
        popupClassName: PropTypes.string
    }))
}

export default Sidebar;