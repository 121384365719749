import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { isAuthenticated } from 'utils/auth';

import { LOGO_TYPE } from 'constants/common.constants';

const getProjectLogosPaths = (companies = [], projectId = "", authLayoutlogos = []) => {
    const logoWhite = "system/static/images/logo-sign.svg";

    const logos = {
        [LOGO_TYPE.LOGO]: "system/static/images/logo.svg",
        [LOGO_TYPE.COLLAPSED_LOGO]: "system/static/images/collapse-logo.svg",
        [LOGO_TYPE.FAVICON]: "system/static/images/favicon.svg"
    }

    const paths = {
        [LOGO_TYPE.LOGO]: "",
        [LOGO_TYPE.COLLAPSED_LOGO]: "",
        [LOGO_TYPE.FAVICON]: ""
    }

    if(isAuthenticated()){
        for(let companyData of companies) {
            for(let projectData of (companyData.projects ?? [])) {
                if (projectId === projectData.id) {
                    projectData.logoPaths.forEach(logoData => {
                        if(logoData.path){
                            paths[logoData.type] = logoData.path;
                            logos[logoData.type] = logoData.path
                        }
                    });
                    break;
                }
            }
        }
    } else {
        authLayoutlogos.forEach(logoData => {
            if(logoData.path){
                logos[logoData.type] = logoData.path
            } 
        });
        if(!authLayoutlogos[LOGO_TYPE.LOGO]?.path){
            logos[LOGO_TYPE.LOGO] = logoWhite;
        }
    }

    return { logos, paths};
}

const selectCompanies = state => state.profile.userInfo.companies;
const selectGlobalProjectId = state => state.common.globalProjectId;
const selectLogos = state => state.auth.logos;

const useLogo = () => {

    const companies = useSelector(selectCompanies);
    const globalProjectId = useSelector(selectGlobalProjectId);

    const authLayoutlogos = useSelector(selectLogos);

    const { logos, paths}  = useMemo(() => {
        return getProjectLogosPaths(companies, globalProjectId, authLayoutlogos);
    }, [companies, globalProjectId, authLayoutlogos])

    return {
        logos, paths
    }
}

export default useLogo;