export const INVOICE_STATE = {
    PENDING: 1,
    APPROVED: 2,
    REJECTED: 3,
    DELETED: 4
}

export const INVOICE_HISTORY_TYPE = {
    PENDING: 1,
    HISTORY: 2
}