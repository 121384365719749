export const TOKEN_EXPIRATION = 900;

export const ORDER_DIRECTION = {
    ASC: 1,
    DESC: 2
}

export const POPUP_SIZE = {
    SMALLEST: 340,
    SMALL: 446,
    MIDDLE: 500,
    BIG: 620,
    BIGER: 740,
    BIGEST: 910,
    EXTRA_BIG: 1200
}

export const MONTHS = {
    JANUARY: 1,
    FEBRUARY: 2,
    MARCH: 3,
    APRIL: 4,
    MAY: 5,
    JUNE: 6,
    JULY: 7,
    AUGUST: 8,
    SEPTEMBER: 9,
    OCTOBER: 10,
    NOVEMBER: 11,
    DECEMBER: 12
};

export const EXPORT_TYPE = {
    CSV: 1,
    PDF: 2
}

export const API_TYPE = {
    BACKOFFICE: 1 << 0,
    CHAT: 1 << 2,
    JOB: 1 << 3,
    INTEGRATION: 1 << 4
}

/**
 * Name of the object that keep included table columns from different pages by page name in local storage
 */
export const SELECTED_COLUMNS = "selectedColumns";

export const EXPAND_TABLE_TYPES = {
    TABLE: "table",
    CONTENT: "content",
    BOTH: "both",
}

export const DATE_FORMAT = {
    FORMAT_1: 1,
    FORMAT_2: 2,
    FORMAT_3: 3,
    FORMAT_4: 4,
    FORMAT_5: 5,
    FORMAT_6: 6
}

export const TIME_FORMAT = {
    _24_HOUR: 1,
    _12_HOUR: 2
}

export const NUMBER_FORMAT = {
    FORMAT_1: 1,
    FORMAT_2: 2,
    FORMAT_3: 3,
    FORMAT_4: 4,
    FORMAT_5: 5,
    FORMAT_6: 6
}

export const ALL = "__all__";

export const DATE_PICKER_RANGES = {
    TODAY: "today",
    YESTERDAY: "yesterday",
    THIS_MONTH: "thisMonth",
    LAST_MONTH: "lastMonth",
    LAST_3_MONTH: "last3Month",
    YTD: "ytd"
}

export const DB_CONNECTION_STATE = {
    IDLE: "idle",
    ACTIVE: "active"
}

/**
 * The name of the form pages available in the project where the user can make and lose changes.
 */
export const UNSAVED_FORM_PAGE_TYPE = {
    TAB: "tab",
    SUB_TAB: "subTab",
    CUSTOMIZE_SECTIONS: "customizeSections",
    CUSTOMIZE_STYLES: "customizeStyles",
}

export const GENERATION_TYPE = {
    AGGREGATED: 1
}

export const SIGNALR_CONNECTION_TYPES = {
    ADMIN: 0,
    JOB: 1
}

export const IMAGE_TYPE = {
    JPEG: 1,
    PNG: 2,
    GIF: 3,
    WEBP: 4,
    SVG: 5
}

export const LOGO_TYPE = {
    LOGO: 1 << 0,
    COLLAPSED_LOGO: 1 << 1,
    FAVICON: 1 << 2,
}