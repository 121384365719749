import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
    SET_COMMUNICATION_TEMPLATES_ACTION_BEFORE,
    SET_COMMUNICATION_TEMPLATES_ACTION_FINISH,
    SET_COMMUNICATION_TEMPLATES
} from "../../../../actionTypes";

export const setCommunicationTemplatesActionBefore = () => ({
    type: SET_COMMUNICATION_TEMPLATES_ACTION_BEFORE,
});

export const setCommunicationTemplatesActionFinished = () => ({
    type: SET_COMMUNICATION_TEMPLATES_ACTION_FINISH,
});

const setCommunicationTemplates = (templates) => ({
    type: SET_COMMUNICATION_TEMPLATES,
    payload: { templates },
})

export const getCommunicationTemplates = () => {
    return (dispatch) => {
        dispatch(setCommunicationTemplatesActionBefore());

        return axios({
            url: ApiUrls.GET_COMMUNICATION_TEMPLATES,
            method: Methods.GET
        })
            .then(({ data: { value: templates } }) => {
                dispatch(setCommunicationTemplates(templates));
                dispatch(setCommunicationTemplatesActionFinished());
            })
            .catch((ex) => {
                dispatch(setCommunicationTemplatesActionFinished());
            });
    }
}