import React from "react";

import ErrorsComponent from "pages/developer/errors/errors.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const ErrorsRoute = () => {
    return <ErrorsComponent />;
};

export default withPermission(
    withAuth(ErrorsRoute),
    { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
    Paths.PORTAL
);
