import { useEffect, useRef } from "react";

import { isFunction } from "utils/common";

const useFirstRender = ({
    dependencies = [],
    onFirstRenderCB,
    afterFirstRenderCB,
}) => {
    const isFirstRenderRef = useRef(true);

    useEffect(() => {
        if (isFirstRenderRef.current) {
            isFirstRenderRef.current = false;

            if (isFunction(onFirstRenderCB)) {
                onFirstRenderCB();
            }

            return;
        }

        if (isFunction(afterFirstRenderCB)) {
            afterFirstRenderCB();
        }
    }, dependencies);
}
 
export default useFirstRender;