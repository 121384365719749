export const CUSTOMIZE_IFRAME_ID = "customize-preview";

export const CUSTOMIZE_FONT_FAMILIES = [
    'Sf-Pro-Display, sans-serif',
    "Roboto, sans-serif",
    "Arial, Helvetica, sans-serif",
    "Impact, Charcoal, sans-serif",
    "Tahoma, Geneva, sans-serif",
    "Trebuchet MS, Helvetica, sans-serif",
    "Verdana, Geneva, sans-serif"
]

export const CUSTOMIZE_PREVIEW_DEVICE_MODE = {
    DESKTOP: "desktop",
    TABLET: "tablet",
    MOBILE: "mobile"
}