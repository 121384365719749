import { message } from 'antd';

import axios from "axios";

import { isResponseSuccess } from "utils/request";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
    setPromoCustomizeSaveActionBefore,
    setPromoCustomizeSaveActionFinished
} from "./common.action";

import { SET_PROMO_CUSTOMIZE_IMAGE } from "../../../actionTypes";

export const setPromoImage = (path, sectionType, sectionName, fileType, languageCode) => ({
    type: SET_PROMO_CUSTOMIZE_IMAGE,
    payload: { path, sectionType, sectionName, fileType, languageCode }
})

export const deletePromoImage = (sectionType, sectionName, fileType, languageCode, onSuccess) => {
    return dispatch => {
        dispatch(setPromoCustomizeSaveActionBefore());

        return axios({
            url: ApiUrls.DELETE_PROMO_IMAGE,
            method: Methods.DELETE,
            data: {
                sectionType,
                fileType,
                languageCode
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess();
                    message.success(data.message);
                    dispatch(setPromoImage(null, sectionType, sectionName, fileType, languageCode))
                }
                dispatch(setPromoCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeSaveActionFinished());
            })
    }
}