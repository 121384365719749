import { useSelector } from 'react-redux';

const selectCompanies = state => state.profile.userInfo.companies;

const useLongId = () => {

    //#region ------------------------------------------- REDUX --------------------------------------------//

    const companies = useSelector(selectCompanies);

    //#endregion

    // Get Company Long Id
    const getCompanyLongIdById = id => {
        if (id === null || id === undefined) return null;

        const company = companies.find(c => c.id === id);
        if(!company) return null;

        return company.longId;
    }

    return {
        getCompanyLongIdById
    }
}

export default useLongId;