//#region react
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region actions
import {
    getUsers,
    setUsersSorting,
    setUsersFilters,
    forceLogout
} from "store/actions/portal/userManagement/users/users.action";
//#endregion

//#region components
import MainDashboardLayout from "components/layouts/main";
import Table from "components/common/table";
import Confirmation from "components/common/confirmation";
import Filters from "./filters.component";
import UserCreateComponent from "./user-create.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { isMobile } from "utils/common";
import { hasPermission } from "utils/permissions";
import { hasAdminEditPageAccess } from "utils/pageAccess";
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import Paths from 'constants/path.constants';
import ApiUrls from 'constants/api.constants';
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from "constants/permissions.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { USER_STATE } from "constants/user.constants";
import { ADMINS } from "constants/pageName.constants";
import { tableColumns } from "./columns";
//#endregion

//#region types
import userType from "types/user/user.type";
import sortingType from "types/common/sorting.type";
//#endregion

const UsersComponent = ({
    getUsers,
    users,
    isLoading,
    total,
    setUsersSorting,
    setUsersFilters,
    sorting,
    filters,
    forceLogout,
    globalCompanyId
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isCreatePopupVisible, setIsCreatePopupVisible] = useState(false);
    const [isForceLogoutPopupVisible, setIsForceLogoutPopupVisible] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: ADMINS });

    const isMobileDevice = isMobile();

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.ADMIN,
        action: PERMISSION_ACTION.EXPORT
    });

    const hasAdminCreatePermission = hasPermission({
        resource: PERMISSION_RESOURCE.ADMIN,
        action: PERMISSION_ACTION.CREATE
    });

    const hasAdminsForceLogoutPermission = hasPermission({
        resource: PERMISSION_RESOURCE.ADMIN_FORCE_LOGOUT,
        action: PERMISSION_ACTION.MODIFY
    });

    const canNavigateAdminsEditPage = hasAdminEditPageAccess()

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setUsersFilters({
            ...filters,
            nameOrId: value
        })
    }

    const showForceLogoutPopup = () => {
        setIsForceLogoutPopupVisible(true);
    }

    const hideForceLogoutPopup = () => {
        setIsForceLogoutPopupVisible(false);
    }

    const showAdminCreatePopup = () => {
        setIsCreatePopupVisible(true);
    }

    const hideAdminCreatePopup = () => {
        setIsCreatePopupVisible(false);
    }

    const handleRightArrowIconClick = (record) => {
        navigate(
            `${Paths.USERS_EDIT}/${record.id}` +
            `?name=${record.userName}` +
            `&mainCompanyId=${record.companyId}` + (
                record.mainCompany
                    ? `&mainCompany=${record.mainCompany}`
                    : ""
            )

        )
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            mainColumns: tableColumns,
            constructForExport: true,
            constructForInclude: true,
        });
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (canNavigateAdminsEditPage) {
        tableRowActions.push({
            title: t('backoffice.common.deepView'),
            icon: "right",
            onClick: handleRightArrowIconClick,
            disabled: record => record.state === USER_STATE.DELETED
        });
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        search: {
            onSearch: handleSearchChange,
            loadFn: getUsers,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.USER,
                dependencies: [globalCompanyId]
            },
            placeholder: t("backoffice.users.userNameOrId"),
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns,
        },
        buttons: [
            {
                icon: (isMobileDevice ? "logout" : null),
                className: 'rt--button rt--button-secondary',
                type: "secondary",
                onClick: showForceLogoutPopup,
                text: t("backoffice.users.forceLogout"),
                enabled: hasAdminsForceLogoutPermission,
            },
            {
                icon: "plus",
                type: "primary",
                onClick: showAdminCreatePopup,
                text: t("backoffice.users.createAdmin"),
                enabled: hasAdminCreatePermission,
            },
        ],
        breadcrumbs: {
            items: [
                { title: t('backoffice.menu.admins') }
            ]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            columns: columnsForExport,
            tableName: t("backoffice.menu.admins"),
            url: ApiUrls.EXPORT_ADMINS,
            filters: filters,
        }
    }

    //#endregion

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={users}
                loadFn={getUsers}
                sorting={sorting}
                setSortingFn={setUsersSorting}
                total={total}
                updateProps={[globalCompanyId]}
                actions={tableRowActions}
            />

            <Confirmation
                title={t('backoffice.users.forceLogout')}
                message={t('backoffice.users.forceLogoutConfirmationMessage')}
                onOk={forceLogout}
                onCancel={hideForceLogoutPopup}
                isVisible={isForceLogoutPopupVisible}
            />

            {isCreatePopupVisible && <UserCreateComponent onClose={hideAdminCreatePopup} />}
        </MainDashboardLayout>
    )
};

/** UsersComponent propTypes
 * PropTypes
*/
UsersComponent.propTypes = {
    /** Redux action to get users */
    getUsers: PropTypes.func,
    /** Redux state property, represents the array of users  */
    users: PropTypes.arrayOf(userType),
    /** Redux state property, is true when loading users */
    isLoading: PropTypes.bool,
    /** Redux state property, users total count */
    total: PropTypes.number,
    /** Redux action to set users sorting details */
    setUsersSorting: PropTypes.func,
    /** Redux action to set users sorting details */
    setUsersFilters: PropTypes.func,
    /** Redux state property, users sorting details */
    sorting: sortingType,
    /** Redux state property, users filters */
    filters: PropTypes.object,
    /** Redux action to force logout */
    forceLogout: PropTypes.func,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    getUsers: nextPage => {
        dispatch(getUsers(nextPage));
    },
    setUsersSorting: sorting => {
        dispatch(setUsersSorting(sorting));
    },
    setUsersFilters: filters => {
        dispatch(setUsersFilters(filters));
    },
    forceLogout: () => {
        dispatch(forceLogout());
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.users.isLoading,
        users: state.users.users,
        total: state.users.total,
        sorting: state.users.sorting,
        filters: state.users.filters,
        globalCompanyId: state.common.globalCompanyId
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersComponent);
