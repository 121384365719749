import React from "react";

import MarketingReportsComponent from "pages/reports/marketing";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const MarketingReportsRoute = () => {
    return <MarketingReportsComponent />;
};

export default withPermission(
    withAuth(MarketingReportsRoute),
    [
        { resource: PERMISSION_RESOURCE.REPORT_CLICKS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.REPORT_MEDIA_PERFORMANCE, action: PERMISSION_ACTION.VIEW }
    ],
    Paths.PORTAL
);
