import React, { Fragment } from "react";

import { SEARCH_TYPE } from "./constants";

import AutoCompleteSearch from "./autocomplete";
import InputSearch from "./input";
import DatePickerSearch from "./datepicker";

import searchType from 'types/components/search.type';

/** Search Component */
const Search = ({
    type = SEARCH_TYPE.AUTOCOMPLETE,
    loadFn,
    placeholder,
    onSearch,
    onChange,
    maxLength,
    autoSuggestion,
    value: controledValue,
    showTime,
    enabledMountsCount,
    allowClear,
    disabledRanges,
    showId,
    inputRef
}) => {
    
    return type === SEARCH_TYPE.AUTOCOMPLETE ? (
        <AutoCompleteSearch 
            loadFn={loadFn}
            onSearch={onSearch}
            onChange={onChange}
            placeholder={placeholder}
            autoSuggestion={autoSuggestion}
            showId={showId}
        />
    ) : type === SEARCH_TYPE.INPUT || type === SEARCH_TYPE.NUMERIC ? (
        <InputSearch 
            loadFn={loadFn}
            onSearch={onSearch}
            onChange={onChange}
            placeholder={placeholder}
            maxLength={maxLength}
            isNumeric={ type === SEARCH_TYPE.NUMERIC }
            value={controledValue}
            inputRef={inputRef}
        />
    ) : type === SEARCH_TYPE.DATEPICKER ? (
        <DatePickerSearch 
            loadFn={loadFn}
            onSearch={onSearch}
            placeholder={placeholder}
            showTime={showTime}
            allowClear={allowClear}
            enabledMountsCount={enabledMountsCount}
            disabledRanges={disabledRanges}
            value={controledValue}
        />
    ) : 
    <Fragment />
}

/** Search propTypes
    * PropTypes
*/
Search.propTypes = searchType;

export default Search;

