import React from 'react';

import ResetPasswordComponent from 'components/auth/resetPassword/resetPassword.component';
import withAnanymous from 'hocs/withAnanymous';

const ResetPasswordRoute = () => {
    return (
        <ResetPasswordComponent /> 
    )
}

export default withAnanymous(ResetPasswordRoute);