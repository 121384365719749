//#region react
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

//#endregion

//#region actions
import {
    getPlayers,
    setPlayersSorting,
    setPlayersFilters,
    updatePlayerData
} from "store/actions/portal/players/players.action";
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import Filters from "./filters.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import ApiUrls from 'constants/api.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { PLAYERS } from "constants/pageName.constants";
import { tableColumns } from "./columns";
//#endregion

//#region types
import playerType from "types/player/player.type";
import sortingType from "types/common/sorting.type";
//#endregion

const PlayersComponent = ({
    getPlayers,
    players,
    isLoading,
    total,
    setPlayersSorting,
    setPlayersFilters,
    sorting,
    filters,
    globalProjectId,
    updatePlayerData
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PLAYERS });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PLAYER,
        action: PERMISSION_ACTION.EXPORT
    });

    const hasPlayerGeneralInfoViewPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PLAYER_GENERALINFO,
        action: PERMISSION_ACTION.VIEW
    });

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setPlayersFilters({
            ...filters,
            nameOrId: value
        })
    }

    const handleReloadIconClick = (record) => {
        updatePlayerData(record.id)
    }


    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        columnsThatCanBeIncluded,
        columnsForExport
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: tableColumns,
            includedColumns: includedColumns,
            constructForExport: true,
            constructForInclude: true,
        })
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasPlayerGeneralInfoViewPermission) {
        tableRowActions.push({
            title: t('backoffice.common.refresh'),
            icon: "reload",
            onClick: handleReloadIconClick,
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        search: {
            onSearch: handleSearchChange,
            loadFn: getPlayers,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.PLAYER,
                dependencies: [globalProjectId]
            },
            placeholder: t("backoffice.players.userNameOrId")
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.players') }]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            columns: columnsForExport,
            tableName: t("backoffice.menu.players"),
            url: ApiUrls.EXPORT_PLAYERS,
            filters: filters
        }
    }

    //#endregion


    return (
        <MainDashboardLayout 
            header={headerPartsData}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={players}
                loadFn={getPlayers}
                sorting={sorting}
                setSortingFn={setPlayersSorting}
                total={total}
                updateProps={[globalProjectId]}
                actions={tableRowActions}
            />

        </MainDashboardLayout>
    )
};

/** PlayersComponent propTypes
 * PropTypes
*/
PlayersComponent.propTypes = {
    /** Redux action to get players */
    getPlayers: PropTypes.func,
    /** Redux state property, represents the array of players  */
    players: PropTypes.arrayOf(playerType),
    /** Redux state property, is true when loading players */
    isLoading: PropTypes.bool,
    /** Redux state property, players total count */
    total: PropTypes.number,
    /** Redux action to set players sorting details */
    setPlayersSorting: PropTypes.func,
    /** Redux action to set players sorting details */
    setPlayersFilters: PropTypes.func,
    /** Redux state property, players sorting details */
    sorting: sortingType,
    /** Redux state property, players filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux action to update player data */
    updatePlayerData: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    getPlayers: nextPage => {
        dispatch(getPlayers(nextPage));
    },
    setPlayersSorting: sorting => {
        dispatch(setPlayersSorting(sorting));
    },
    setPlayersFilters: filters => {
        dispatch(setPlayersFilters(filters));
    },
    updatePlayerData: id => {
        dispatch(updatePlayerData(id));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.players.isLoading,
        players: state.players.players,
        total: state.players.total,
        sorting: state.players.sorting,
        filters: state.players.filters,
        globalProjectId: state.common.globalProjectId
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayersComponent);
