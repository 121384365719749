//#region react
import React from "react";
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import { setupPromo } from "store/actions/portal/projects/promo.action";

import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

/** Promo Empty Component */
const PromoEmpty = ({
    setupPromo
}) => {

    const { t } = useTranslation();

    return (
        <div className='rt--promo-website-empty'>
            <div className="rt--promo-website-empty-content rt--flex rt--flex-col rt--justify-center rt--align-center">
                <img src={`${import.meta.env.SYSTEM_CDN_URL}/system/static/images/promo-empty.png`}></img>
                <span className="rt--font-normal rt--font-regular">{t("backoffice.projects.notActivatedYet")}</span>
                <span className="rt--promo-website-empty-content-activate rt--font-normal rt--font-regular rt--mt-4 rt--mb-16">{t("backoffice.projects.notActivatedYetInfo")}</span>
                <Button 
                    onClick={setupPromo} 
                    className="rt--button rt--button-main"
                    disabled={ !hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PROMO, action: PERMISSION_ACTION.CREATE }) }                
                >
                    <span>{t("backoffice.projects.activatePromoWebsite")}</span>
                </Button>
            </div>
        </div>
    )
}


PromoEmpty.propTypes = {
    /** Redux action to setup Promo */
    setupPromo: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        setupPromo: () => {
            dispatch(setupPromo())
        },
    }
)

export default connect(null, mapDispatchToProps)(PromoEmpty)