//#region react
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Checkbox, Spin } from 'antd';
//#endregion

//#region actions
import {
    getAffiliateAccessSettings,
    saveAffiliateAccessSettings
} from "store/actions/portal/affiliates/access/accessSettings.action";
//#endregion

//#region components
import MainFormDashboardLayout from "components/layouts/main/form";
//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { binaryToFlags, flagsToBinary } from "utils/common";
//#endregion

//#region constants
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { AFFILIATE_ACCESS_TYPE, AFFILIATE_ACCESS_TYPE_TRANSLATIONS } from "constants/affiliate.constants";
//#endregion


/** Affiliate Access Settings Page Component */
const AffiliateAccessSettingsComponent = ({
    getAffiliateAccessSettings,
    saveAffiliateAccessSettings,
    accessSettings,
    isLoading,
    isSaving,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [ isFormTouched, setIsFormTouched ] = useState(false);

    const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_AFFILIATE_ACCESS_SETTINGS, action: PERMISSION_ACTION.MODIFY });

    /** Load affiliate access */
    useEffect(() => {
        globalProjectId && getAffiliateAccessSettings();
    }, [globalProjectId]);

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        const settings = binaryToFlags(Object.values(AFFILIATE_ACCESS_TYPE), accessSettings);
        const values = {};
        Object.values(AFFILIATE_ACCESS_TYPE).forEach(type => {
            values[`field_` + type] = settings.includes(type)
        })

        setFieldsValue(values);
    }, [accessSettings]);

    /** Fires when form submitted
         * @function
         * @memberOf AffiliateAccessSettingsComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const checked = Object.keys(data).filter(field => data[field]).map(field => Number(field.replace("field_", "")));
                
                saveAffiliateAccessSettings({
                    affiliateAccessType: flagsToBinary(checked)
                });
                setIsFormTouched(false);
            }).catch(() => { })
    }

    const renderItem = type => {
        const description = t("backoffice.menu.reports")

        return (
            <div className="rt--affiliates-access-item rt--pl-24 rt--pr-24 rt--pt-16 rt--pb-16 rt--mb-16">
                <div className="rt--flex">
                    <div className="rt--flex-equal rt--flex rt--flex-col">
                        <b className="rt--title rt--pb-6 rt--font-bold rt--font-big">{t(`backoffice.affiliates.${AFFILIATE_ACCESS_TYPE_TRANSLATIONS[type]}`)}</b>
                        <span className="rt--title rt--pb-6 rt--font-regular rt--font-small">{description}</span>
                    </div>
                    <Form.Item
                        name={"field_" + type}
                        valuePropName="checked"
                        className="rt--form-item-without-margin"
                    >
                        <Checkbox
                            disabled={!hasModifyPermission}
                        />
                    </Form.Item>
                </div>
            </div>
        )
    }

    return (
        <MainFormDashboardLayout
            breadcrumbs={
                {
                    items: [
                        { title: t('backoffice.menu.affiliateAccess') }
                    ]
                }
            }
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasModifyPermission,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(_, formValues) => {
                        const checked = Object.keys(formValues).filter(field => formValues[field]).map(field => Number(field.replace("field_", "")));
                        setIsFormTouched(  accessSettings !==  flagsToBinary(checked))
                    }}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6}>
                            <div className="rt--affiliates-access-info rt--pl-20 rt--pr-20 rt--mb-16 rt--flex rt--align-center rt--justify-between">
                                <div className="rt--flex-equal rt--flex rt--flex-col">
                                    <b className="rt--title rt--font-big rt--font-extra-bold rt--pb-6">{t("backoffice.menu.affiliateAccess")}</b>
                                    <span className="rt--title rt--font-small rt--font-medium">{t("backoffice.affiliates.affiliateAccessDescription")}</span>
                                </div>
                                <div className="rt--affiliates-access-info-icon rt--ml-10 rt--flex rt--align-center rt--justify-center">
                                    <img src={`${import.meta.env.SYSTEM_CDN_URL}/system/static/images/access.png`}></img>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} xl={18}>
                            <Row gutter={[16, 0]}>
                                <Col xs={24} sm={24} xl={8}>
                                    { renderItem(AFFILIATE_ACCESS_TYPE.CLICK_SOURCE) }
                                </Col>
                                <Col xs={24} sm={24} xl={8}>
                                    { renderItem(AFFILIATE_ACCESS_TYPE.DEPOSIT_COUNT) }
                                </Col>
                                <Col xs={24} sm={24} xl={8}>
                                    { renderItem(AFFILIATE_ACCESS_TYPE.DEPOSIT_AMOUNT) }
                                </Col>
                                <Col xs={24} sm={24} xl={8}>
                                    { renderItem(AFFILIATE_ACCESS_TYPE.FTD_COUNT) }
                                </Col>
                                <Col xs={24} sm={24} xl={8}>
                                    { renderItem(AFFILIATE_ACCESS_TYPE.FTD_AMOUNT) }
                                </Col>
                                <Col xs={24} sm={24} xl={8}>
                                    { renderItem(AFFILIATE_ACCESS_TYPE.BET_AMOUNT) }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6}>
                            { renderItem(AFFILIATE_ACCESS_TYPE.BET_COUNT) }
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            { renderItem(AFFILIATE_ACCESS_TYPE.WITHDRAW_COUNT) }
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            { renderItem(AFFILIATE_ACCESS_TYPE.WITHDRAW_AMOUNT) }
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            { renderItem(AFFILIATE_ACCESS_TYPE.NET_DEPOSIT_AMOUNT) }
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            { renderItem(AFFILIATE_ACCESS_TYPE.GGR) }
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            { renderItem(AFFILIATE_ACCESS_TYPE.NGR) }
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            { renderItem(AFFILIATE_ACCESS_TYPE.WON_AMOUNT) }
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            { renderItem(AFFILIATE_ACCESS_TYPE.BONUS_WON_AMOUNT) }
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            { renderItem(AFFILIATE_ACCESS_TYPE.ADMIN_FEE) }
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            { renderItem(AFFILIATE_ACCESS_TYPE.ADJUSTMENTS) }
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            { renderItem(AFFILIATE_ACCESS_TYPE.PLAYER_ID) }
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            { renderItem(AFFILIATE_ACCESS_TYPE.PLAYER_USERNAME) }
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </MainFormDashboardLayout>
    );
};

/** AffiliateAccessSettingsComponent propTypes
 * PropTypes
*/
AffiliateAccessSettingsComponent.propTypes = {
    /** Redux action to get affiliate access settings */
    getAffiliateAccessSettings: PropTypes.func,
    /** Redux action to save affiliate access settings */
    saveAffiliateAccessSettings: PropTypes.func,
    /** Redux state property, represents the affiliate access settings */
    accessSettings: PropTypes.number,
    /** Redux state property, is true when loading affiliate access settings */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when saving affiliate access settings */
    isSaving: PropTypes.bool,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    getAffiliateAccessSettings: () => {
        dispatch(getAffiliateAccessSettings());
    },
    saveAffiliateAccessSettings: data => {
        dispatch(saveAffiliateAccessSettings(data));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.affiliateAccess.isLoading,
        isSaving: state.affiliateAccess.isSaving,
        accessSettings: state.affiliateAccess.accessSettings,
        globalProjectId: state.common.globalProjectId
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AffiliateAccessSettingsComponent);
