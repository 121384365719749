import PropTypes from 'prop-types';

export default PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    symbol: PropTypes.string,
    enabled: PropTypes.bool,
    createdAt: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
    rate: PropTypes.number
})