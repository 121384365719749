import React from "react";
import PropTypes from 'prop-types';

import LeaderboardMobileView from "./mobileView";
import LeaderboardDesktopView from "./desktopView";

import { classNames, isMobile } from "utils/common";

import { LEADERBOARD_TYPE } from "./constants";

import leaderType from "types/dashboard/leader.type";

const Leaderboard = ({
    type = LEADERBOARD_TYPE.FINANCIAL,
    leaders = [],
    isLoading = false,
    currencyCode,
    isPlayerData=false
}) => {
    const isMobileDevice = isMobile();

    return isMobileDevice
        ? (
            <LeaderboardMobileView
                leaders={leaders}
                isLoading={isLoading}
                type={type}
                currencyCode={currencyCode}
                isPlayerData={isPlayerData}
            />
        ) : (
            <LeaderboardDesktopView
                leaders={leaders}
                isLoading={isLoading}
                type={type}
                currencyCode={currencyCode}
                isPlayerData={isPlayerData}
            />
        )
};

/** Leaderboard propTypes*/
Leaderboard.propTypes = {
    leaders: PropTypes.arrayOf(leaderType),
    isLoading: PropTypes.bool,
    currencyCode: PropTypes.string,
    type: PropTypes.oneOf([
        LEADERBOARD_TYPE.FINANCIAL,
        LEADERBOARD_TYPE.OPERATIONAL,
    ]),
    isPlayerData: PropTypes.bool
};

export default Leaderboard;