import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import {
    SET_ERRORS_ACTION_BEFORE,
    SET_ERRORS_ACTION_FINISH,
    SET_ERRORS,
    SET_ERRORS_SORTING,
    SET_ERRORS_FILTERS
} from "../../../../actionTypes";

const setErrorsActionBefore = () => ({
    type: SET_ERRORS_ACTION_BEFORE,
});

const setErrorsActionFinished = () => ({
    type: SET_ERRORS_ACTION_FINISH,
});

const setErrors = (errors, add) => ({
    type: SET_ERRORS,
    payload: { errors, add },
});

export const setErrorsSorting = sorting => ({
    type: SET_ERRORS_SORTING,
    payload: { sorting },
});

export const setErrorsFilters = filters => ({
    type: SET_ERRORS_FILTERS,
    payload: { filters },
});

export const getErrors = (nextPage, otherParams) => {
    return (dispatch, getState) => {
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().errors.sorting.page + 1 : getState().errors.sorting.page;
        let params = {
            ...getState().errors.sorting,
            ...getState().errors.filters,
            page: page
        };

        if(page > 1 && getState().errors.total <= (page - 1 ) * getState().errors.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().errors.total === 0){
            return Promise.resolve();
        }

        if(otherParams){
            params = {
                ...params,
                ...otherParams
            }
        }
        
        dispatch(setErrorsActionBefore());

        return axios({
            url: ApiUrls.GET_ERRORS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: errors } }) => {
                dispatch(setErrors(errors, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setErrorsSorting({ ...getState().errors.sorting, page: page }));
                dispatch(setErrorsActionFinished());
            })
            .catch((ex) => {
                dispatch(setErrorsActionFinished());
            });
    }
}
