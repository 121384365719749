export const tableColumns = [
    {
        title: "backoffice.permissiongroups.userid",
        dataIndex: "id",
        alwaysVisible: true,
        copy: true,
    },
    {
        title: "backoffice.permissiongroups.username",
        dataIndex: "userName",
        alwaysVisible: true,
        mobileLevel: 1,
    },
    {
        title: "backoffice.permissiongroups.addedAt",
        dataIndex: "addedAt",
        isDateTime: true,
        mobileLevel: 2,
    },
    {
        title: "backoffice.permissiongroups.addedBy",
        dataIndex: "addedBy",
        mobileLevel: 3,
    }
];