import {
    SET_COMMISSION_CALCULATIONS_ACTION_BEFORE,
    SET_COMMISSION_CALCULATIONS_ACTION_FINISH,
    SET_COMMISSION_CALCULATIONS,
    SET_COMMISSION_CALCULATIONS_SORTING,
    SET_COMMISSION_CALCULATIONS_FILTERS
} from '../../actionTypes';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_COMMISSION_CALCULATIONS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_COMMISSION_CALCULATIONS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_COMMISSION_CALCULATIONS:
            return {
                ...state,
                calculations: !payload.add ? payload.calculations.item2 : state.calculations.concat(payload.calculations.item2),
                total: payload.calculations.item1,
            }
        case SET_COMMISSION_CALCULATIONS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            }
        case SET_COMMISSION_CALCULATIONS_SORTING:
            return {
                ...state,
                sorting: payload.sorting
            };
        default:
            return state;
    }
}