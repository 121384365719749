import React from 'react';

import i18n from 'translations/config';

import Status from 'components/common/status';

import { STATUS_TYPES } from 'constants/status.constants';
import { AFFILIATE_ACCESS_TYPE } from 'constants/affiliate.constants';

export const getTableColumns = additionalProps => {

    const {
        formatNumber,
        formatAmount,
        currencyCode,
        formatCurrencyWithSymbol,
        hasAccess
    } = additionalProps;

    return [
        {
            title: "backoffice.reports.affiliateId",
            dataIndex: "id",
            mobileLevel: 1,
            alwaysVisible: true,
            copy: true
        },
        {
            title: "backoffice.reports.affiliateUsername",
            dataIndex: "affiliateUserName",
            mobileLevel: 2,
            alwaysVisible: true
        },
        {
            title: "backoffice.reports.affiliateStatus",
            dataIndex: "affiliateState",
            mobileLevel: 3,
            render: value => (
                <Status
                    status={value}
                    type={STATUS_TYPES.AFFILIATE}
                />
            ),
        },
        {
            title: "backoffice.reports.views",
            dataIndex: "viewCount",
            exportIndex: "totalViewCount",
            isNumeric: true,
            sorter: true,
            mobileLevel: 4
        },
        {
            title: "backoffice.reports.validClicks",
            dataIndex: "validClickCount",
            exportIndex: "totalValidClickCount",
            isNumeric: true,
            sorter: true,
            mobileLevel: 5
        },
        {
            title: "backoffice.reports.invalidClicks",
            dataIndex: "invalidClickCount",
            exportIndex: "totalInvalidClickCount",
            isNumeric: true,
            sorter: true,
            mobileLevel: 6
        },

        {
            title: "backoffice.reports.playerRegistrations",
            dataIndex: "playerRegistrationCount",
            isNumeric: true
        },

        {
            title: "backoffice.reports.crToReg",
            dataIndex: "validClicksPerRegistration",
            render: value => formatNumber(value) + "%"
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_COUNT) && {
            title: "backoffice.reports.depositCount",
            dataIndex: "depositCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.depositAmount")),
            dataIndex: "depositAmount",
            render: value=> formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.FTD_COUNT) && {
            title: "backoffice.reports.ftdCount",
            dataIndex: "ftdCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.FTD_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.ftdAmount")),
            dataIndex: "ftdAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.FTD_COUNT) && {
            title: "backoffice.reports.qftdCount",
            dataIndex: "cpaCount",
            render: value => value === null ? "-" : formatNumber(value)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NET_DEPOSIT_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.netDeposit")),
            dataIndex: "netDepositAmount",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_COUNT) && {
            title: "backoffice.reports.sportBetCount",
            dataIndex: "sportBetCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.sportBet")),
            dataIndex: "sportBetAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.WON_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.sportWonAmount")),
            dataIndex: "sportWonAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.GGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.sportGGR")),
            dataIndex: "sportGGR",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BONUS_WON_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.sportBonusWonAmount")),
            dataIndex: "sportBonusWonAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.ADMIN_FEE) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.sportAdminFee")),
            dataIndex: "sportFeeAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.sportNGR")),
            dataIndex: "sportNGR",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_COUNT) && {
            title: "backoffice.reports.casinoBetCount",
            dataIndex: "casinoBetCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.casinoBet")),
            dataIndex: "casinoBetAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.WON_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.casinoWonAmount")),
            dataIndex: "casinoWonAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.GGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.casinoGGR")),
            dataIndex: "casinoGGR",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BONUS_WON_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.casinoBonusWonAmount")),
            dataIndex: "casinoBonusWonAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.ADMIN_FEE) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.casinoAdminFee")),
            dataIndex: "casinoFeeAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.casinoNGR")),
            dataIndex: "casinoNGR",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.ngr")),
            dataIndex: "ngr",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.ADMIN_FEE) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.depositFee")),
            dataIndex: "depositFeeAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.ADJUSTMENTS) && {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.adjustments")),
            dataIndex: "adjustmentsAmount",
            render: value => formatAmount(value, currencyCode)
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.sportRevShare")),
            dataIndex: "sportRevShareAmount",
            render: value => formatAmount(value, currencyCode)
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.casinoRevShare")),
            dataIndex: "casinoRevShareAmount",
            render: value => formatAmount(value, currencyCode)
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.totalRevShare")),
            dataIndex: "totalRevShareAmount",
            render: value => formatAmount(value, currencyCode)
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.cpaAmount")),
            dataIndex: "cpaAmount",
            render: value => formatAmount(value, currencyCode)
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.fixedAmount")),
            dataIndex: "fixedCommissionAmount",
            render: value => formatAmount(value, currencyCode)
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, i18n.t("backoffice.reports.totalEarning")),
            dataIndex: "totalCommissionAmount",
            render: value => formatAmount(value, currencyCode)
        },

    ]
}

export const getExpandTableColumns = additionalProps => {
    const {
        formatAmount,
        hasAccess
    } = additionalProps;

    return [
        {
            title: "backoffice.reports.currency",
            dataIndex: "currencyCode",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_COUNT) && {
            title: "backoffice.reports.depositCount",
            dataIndex: "depositCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_AMOUNT) && {
            title: "backoffice.reports.depositAmount",
            dataIndex: "depositAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.FTD_COUNT) && {
            title: "backoffice.reports.ftdCount",
            dataIndex: "ftdCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.FTD_AMOUNT) && {
            title: "backoffice.reports.ftdAmount",
            dataIndex: "ftdAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.FTD_COUNT) && {
            title: "backoffice.reports.qftdCount",
            dataIndex: "cpaCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NET_DEPOSIT_AMOUNT) && {
            title: "backoffice.reports.netDeposit",
            dataIndex: "netDepositAmount",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NGR) && {
            title: "backoffice.reports.ngr",
            dataIndex: "ngr",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.ADJUSTMENTS) && {
            title: "backoffice.reports.adjustments",
            dataIndex: "adjustmentsAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.ADMIN_FEE) && {
            title: "backoffice.reports.depositFee",
            dataIndex: "depositFeeAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        {
            title: "backoffice.reports.sportRevShare",
            dataIndex: "sportRevShareAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        {
            title: "backoffice.reports.casinoRevShare",
            dataIndex: "casinoRevShareAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        {
            title: "backoffice.reports.totalRevShare",
            dataIndex: "totalRevShareAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        {
            title: "backoffice.reports.cpaAmount",
            dataIndex: "cpaAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        {
            title: "backoffice.reports.fixedAmount",
            dataIndex: "fixedCommissionAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        {
            title: "backoffice.reports.totalEarning",
            dataIndex: "totalCommissionAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_COUNT) && {
            title: "backoffice.reports.sportBetCount",
            dataIndex: "sportBetCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_AMOUNT) && {
            title: "backoffice.reports.sportBet",
            dataIndex: "sportBetAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.WON_AMOUNT) && {
            title: "backoffice.reports.sportWonAmount",
            dataIndex: "sportWonAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.GGR) && {
            title: "backoffice.reports.sportGGR",
            dataIndex: "sportGGR",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BONUS_WON_AMOUNT) && {
            title: "backoffice.reports.sportBonusWonAmount",
            dataIndex: "sportBonusWonAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NGR) && {
            title: "backoffice.reports.sportNGR",
            dataIndex: "sportNGR",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.ADMIN_FEE) && {
            title: "backoffice.reports.sportAdminFee",
            dataIndex: "sportFeeAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_COUNT) && {
            title: "backoffice.reports.casinoBetCount",
            dataIndex: "casinoBetCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_AMOUNT) && {
            title: "backoffice.reports.casinoBet",
            dataIndex: "casinoBetAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.WON_AMOUNT) && {
            title: "backoffice.reports.casinoWonAmount",
            dataIndex: "casinoWonAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BONUS_WON_AMOUNT) && {
            title: "backoffice.reports.casinoBonusWonAmount",
            dataIndex: "casinoBonusWonAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.GGR) && {
            title: "backoffice.reports.casinoGGR",
            dataIndex: "casinoGGR",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NGR) && {
            title: "backoffice.reports.casinoNGR",
            dataIndex: "casinoNGR",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.ADMIN_FEE) && {
            title: "backoffice.reports.casinoAdminFee",
            dataIndex: "casinoFeeAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
    ]
}