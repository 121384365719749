import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Modal from '../modal';

import { logout, refreshToken, getUser } from 'utils/auth';

/** Logout confirmation modal component, which appears when session is close to end */

const LogoutModal = ({ expires }) => {
    const { t } = useTranslation();

    const textParts = t('backoffice.common.tokenExpireText').split("%X%")

    const text = (
        <Fragment>
            {textParts[0]}
            <b>{expires}</b>
            {textParts[1]}
        </Fragment>
    )

    /** Fires on clicking refresh button
       * @function
       * @memberOf LogoutModal
   */
    const onRefresh = () => {
        const refresh_token = getUser()?.refreshToken ?? null;
        refreshToken(refresh_token);
    }

    useEffect(() => {
        if(window._SHOW_SESSION_EXPIRE_SECONDS){
            console.log(`Session will expire in ${expires} seconds`)
        }
        
    }, [expires])

    return 0 <= expires && expires <= 10 ? (

        <Modal
            title={t('backoffice.common.tokenExpiration')}
            onOk={onRefresh}
            onCancel={logout}
            okText={t('backoffice.common.refresh')}
            cancelText={t('backoffice.common.logout')}
        >
            <p>{text}</p>
        </Modal>
    ) : null

}

LogoutModal.propTypes = {
    /** Redux state property, represents seconds to access token expiration */
    expires: PropTypes.number
}

const mapStateToProps = state => {
    return {
        expires: state.auth.expires
    }
}

export default connect(
    mapStateToProps,
    null
)(
    LogoutModal
);
