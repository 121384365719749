import {
    SET_PLATFORMS_ACTION_BEFORE,
    SET_PLATFORMS_ACTION_FINISH,
    SET_PLATFORMS,
    SET_PLATFORM_TEST_ACTION_BEFORE,
    SET_PLATFORM_TEST_ACTION_FINISH,
    SET_PLATFORM_TESTS
} from '../../actionTypes';


export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_PLATFORMS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_PLATFORMS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_PLATFORMS:
            return {
                ...state,
                platforms: payload.platforms
            }
        case SET_PLATFORM_TEST_ACTION_BEFORE:
            return {
                ...state,
                isTesting: true
            }
        case SET_PLATFORM_TEST_ACTION_FINISH:
            return {
                ...state,
                isTesting: false
            }
        case SET_PLATFORM_TESTS:
            return {
                ...state,
                platformTests: payload.platformTests
            }
        default:
            return state;
    }
}