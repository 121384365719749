import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import {
    SET_EXPORT_ACTION_BEFORE,
    SET_EXPORT_ACTION_FINISH,
    SET_LANGUAGES_ACTION,
    SET_LANGUAGES_ACTION_BEFORE,
    SET_LANGUAGES_ACTION_FINISH,
    SET_POST_DEPLOYMENT_ACTIONS_ACTION_BEFORE,
    SET_POST_DEPLOYMENT_ACTIONS_ACTION_FINISH
} from "../../../../actionTypes";
import METHODS from 'constants/methods.constants';
import { downloadURI } from 'utils/common';

const setPostDeploymentActionsActionBefore = () => ({
    type: SET_POST_DEPLOYMENT_ACTIONS_ACTION_BEFORE,
});

const setPostDeploymentActionsActionFinished = () => ({
    type: SET_POST_DEPLOYMENT_ACTIONS_ACTION_FINISH,
});

const setLanguagesActionBefore = () => ({
    type: SET_LANGUAGES_ACTION_BEFORE,
});

const setLanguagesActionFinished = () => ({
    type: SET_LANGUAGES_ACTION_FINISH,
});

const setLanguagesAction = (languages) => ({
    type: SET_LANGUAGES_ACTION,
    payload: { languages }
});

const setExportActionBefore = () => ({
    type: SET_EXPORT_ACTION_BEFORE,
});

const setExportActionFinished = () => ({
    type: SET_EXPORT_ACTION_FINISH,
});

export const getLanguages = () => {
    return dispatch => {
        
        dispatch(setLanguagesActionBefore());

        return axios({
            url: ApiUrls.LANGUAGES,
            method: Methods.GET,
        })
            .then(({ data: { value } }) =>  {
                dispatch(setLanguagesAction(value));
                dispatch(setLanguagesActionFinished());
            })
            .catch(err => {
                dispatch(setLanguagesActionFinished());
            });
    }
}

export const updateTranslations = (file, languages) => {
    const formData = new FormData();
    formData.append('file', file);

    languages.forEach(languageCode => formData.append('languageCodes', languageCode));
    const cancelTokenSource = axios.CancelToken.source();
    return dispatch => {
        
        dispatch(setPostDeploymentActionsActionBefore());

        const requestParams = {
            url: ApiUrls.UPDATE_TRANSLATIONS,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            cancelToken: cancelTokenSource.token,
            data: formData,
        }

        return axios(requestParams)
            .then(({ data }) =>  {
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                }
                dispatch(setPostDeploymentActionsActionFinished());
            })
            .catch(err => {
                dispatch(setPostDeploymentActionsActionFinished());
            });
    }
}

export const flushCache = db => {
    return dispatch => {
        dispatch(setPostDeploymentActionsActionBefore());

        return axios({
            url: ApiUrls.FLUSH_CACHE,
            method: Methods.POST,
            data: {
                db: db
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
            } 

            dispatch(setPostDeploymentActionsActionFinished());
        })
        .catch(() => {
            dispatch(setPostDeploymentActionsActionFinished());
        })
    }
}

export const exportTranslations = (languages) => {
    
    return dispatch => {
        dispatch(setExportActionBefore());

        let languagesStr = "";
        languages.forEach(languageCode => {
            languagesStr += `${languagesStr !== "" ? "&" : ""}languageCodes=${languageCode}`
        })
    
        return axios({
            url: `${ApiUrls.EXPORT_TRANSLATIONS}?${languagesStr}`,
            method: METHODS.GET,
        })
            .then(({ data }) => {
                try {
                    if(isResponseSuccess(data)) {
                        const { value } = data;
                        const fileUrl = `${import.meta.env.SYSTEM_CDN_URL}/${String(value).toLowerCase()}`;
                        const fileName = value.split("/").at(-1);
                        
                        downloadURI(fileUrl, fileName);
                        dispatch(setExportActionFinished());
                    }
                } catch (ex) {
                    dispatch(setExportActionFinished());
                }
            })
            .catch((error) => {
                dispatch(setExportActionFinished());
            })
    }
}