import React from "react";

export const getTableColumns = additionalProps => {

    const {
        formatAmount
    } = additionalProps;

    return [
        {
            title: "backoffice.reports.playerId",
            dataIndex: "playerId",
            mobileLevel: 1,
            alwaysVisible: true,
            copy: true
        },
        {
            title: "backoffice.reports.playerUsername",
            dataIndex: "playerUserName",
            mobileLevel: 2,
            alwaysVisible: true
        },
        {
            title: "backoffice.reports.playerExternalId",
            dataIndex: "playerExternalId",
            copy: true
        },
        
        {
            title: "backoffice.reports.currency",
            dataIndex: "currencyCode",
            mobileLevel: 3
        },

        {
            title: "backoffice.reports.brand",
            dataIndex: "brandName",
            mobileLevel: 4
        },

        {
            title: "backoffice.reports.generationId",
            dataIndex: "generationId",
            copy: true
        },

        {
            title: "backoffice.common.from",
            dataIndex: "generationFrom",
            isDateTime: true,
            mobileLevel: 5
        },

        {
            title: "backoffice.common.to",
            dataIndex: "generationTo",
            isDateTime: true,
            mobileLevel: 6
        },

        {
            title: "backoffice.reports.depositCount",
            dataIndex: "depositCount",
            isNumeric: true,
            mobileLevel: 5
        },

        {
            title: "backoffice.reports.depositAmount",
            dataIndex: "depositAmount",
            render: (value, record) => formatAmount(value, record.currencyCode),
            mobileLevel: 6
        },

        {
            title: "backoffice.reports.withdrawCount",
            dataIndex: "withdrawCount",
            isNumeric: true
        },

        {
            title: "backoffice.reports.withdrawAmount",
            dataIndex: "withdrawAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        {
            title: "backoffice.reports.sportBetCount",
            dataIndex: "sportCount",
            isNumeric: true
        },

        {
            title: "backoffice.reports.sportTurnover",
            dataIndex: "sportTurnover",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        {
            title: "backoffice.reports.sportBonusTurnover",
            dataIndex: "sportBonusTurnover",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        {
            title: "backoffice.reports.sportGGR",
            dataIndex: "sportGGR",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },

        {
            title: "backoffice.reports.sportNGR",
            dataIndex: "sportNGR",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },
        
        {
            title: "backoffice.reports.casinoBetCount",
            dataIndex: "casinoCount",
            isNumeric: true
        },

        {
            title: "backoffice.reports.casinoTurnover",
            dataIndex: "casinoTurnover",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        {
            title: "backoffice.reports.casinoBonusTurnover",
            dataIndex: "casinoBonusTurnover",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        {
            title: "backoffice.reports.casinoGGR",
            dataIndex: "casinoGGR",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },

        {
            title: "backoffice.reports.casinoNGR",
            dataIndex: "casinoNGR",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },

        {
            title: "backoffice.reports.adjustments",
            dataIndex: "adjustments",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
    ]
}
