import {
    SET_COMMUNICATION_TEMPLATES_ACTION_BEFORE,
    SET_COMMUNICATION_TEMPLATES_ACTION_FINISH,
    SET_COMMUNICATION_TEMPLATES,
    SET_COMMUNICATION_TEMPLATE_ACTION_BEFORE,
    SET_COMMUNICATION_TEMPLATE_ACTION_FINISH,
    SET_COMMUNICATION_TEMPLATE,
    SET_COMMUNICATION_TEMPLATE_SAVE_ACTION_BEFORE,
    SET_COMMUNICATION_TEMPLATE_SAVE_ACTION_FINISH,
    SET_COMMUNICATION_TEMPLATE_VARIABLES,
    SET_COMMUNICATION_TEMPLATE_TRANSLATION,
    SET_TEMPLATE_SYSTEM_DEFAULT
} from "../../actionTypes";

import LocalStorageUtils from "utils/localStorage";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_COMMUNICATION_TEMPLATES_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_COMMUNICATION_TEMPLATES_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_COMMUNICATION_TEMPLATES:
            return {
                ...state,
                templates: payload.templates
            };
        case SET_COMMUNICATION_TEMPLATE_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            };
        case SET_COMMUNICATION_TEMPLATE_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            };
        case SET_COMMUNICATION_TEMPLATE_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true
            };
        case SET_COMMUNICATION_TEMPLATE_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false
            };
        case SET_COMMUNICATION_TEMPLATE:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    email: {
                        ...state.edit.email,
                        template: payload.template
                    }
                }
            };
        case SET_COMMUNICATION_TEMPLATE_VARIABLES:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    email: {
                        ...state.edit.email,
                        templateVariables: payload.variables
                    }
                }
            };
        case SET_COMMUNICATION_TEMPLATE_TRANSLATION: {
            return {
                ...state,
                edit: {
                    ...state.edit,
                    email: {
                        ...state.edit.email,
                        template: state.edit.email.template.map((section) => {
                            if (Object.hasOwn(payload.groupedSections, section.key)) {
                                const newTranslations = section.translations.map((translation) => {
                                    if (translation.languageCode.toLowerCase() === payload.groupedSections[section.key].languageCode.toLowerCase()) {
                                        return { ...translation, text: payload.groupedSections[section.key].text, source: payload.groupedSections[section.key].source };
                                    }

                                    return { ...translation };
                                })

                                return { ...section, translations: newTranslations };
                            }
                        })
                    }
                }
            };
        }
        case SET_TEMPLATE_SYSTEM_DEFAULT:
            LocalStorageUtils.set("systemDefaultTemplate", payload.systemDefault);
            return {
                ...state,
                edit: {
                    ...state.edit,
                    email: {
                        ...state.edit.email,
                        systemDefault: payload.systemDefault
                    }
                }
            }
        default:
            return state;
    }
};
