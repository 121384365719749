import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { ChromePicker } from 'react-color';

import OutsideAlerter from "../outsideAlerter";

/** Color Picker Component */
const ColorPicker = ({
    color,
    onChange
}) => {
    const [picker, setPicker] = useState(false);

    return (
        <div className="rt--color-picker">
            <div 
                className="rt--color-picker-button" 
                onClick={() => setPicker(!picker)}
            >
                <div 
                    className="rt--color-picker-color" 
                    style={{ background: color }} 
                >
                    
                </div>
            </div>
            {
                picker && (
                    <OutsideAlerter 
                        callback={() => picker && setPicker(false)}
                    >
                        <div className="rt--color-picker-popover">
                            <ChromePicker
                                disableAlpha={true}
                                color={color}
                                onChange={c => onChange(c.hex)}
                            />
                        </div>
                    </OutsideAlerter>
                )
            }
        </div>
    )
}

/** Color propTypes
    * PropTypes
*/
ColorPicker.propTypes = {
    /** The value */
    color: PropTypes.string,
    /** Function which fires on color change */
    onChange: PropTypes.func
}

export default ColorPicker;