import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import DateRangePicker from "components/common/dateRangePicker";

import { getProjects, setProjectsFilters } from "store/actions/portal/projects/projects.action";

import { FILTER_TEST_STATE } from "constants/filter.constants";

/** Projects Page Filters Component */

const Filters = ({
    setProjectsFilters,
    getProjects,
    filters,
    globalCompanyId
}) => {
    const { t } = useTranslation();

    return (
        <FiltersWrapper
            loadFn={getProjects}
            setFiltersFn={setProjectsFilters}
            filters={filters}
            datePickerFields={[
                { name: "date", time: false },
                { name: "modified", time: false }
            ]}
            searchFieldName="nameOrId"
            updateProps={[globalCompanyId]}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.dataType')}
                        name="isTesting"
                    >
                        <Select
                            options={[
                                { value: FILTER_TEST_STATE.ALL, text: t('backoffice.common.all') },
                                { value: FILTER_TEST_STATE.TESTING, text: t('backoffice.common.testing') },
                                { value: FILTER_TEST_STATE.NOTTESTING, text: t('backoffice.common.notTesting') }
                            ]}
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.common.dataType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.creationDate')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.lastUpdateDate')}
                        name="modified"
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get projects */
    getProjects: PropTypes.func,
    /** Redux action to set projects filters */
    setProjectsFilters: PropTypes.func,
    /** Redux state property, projects filters */
    filters: PropTypes.object,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
}

const mapDispatchToProps = dispatch => (
    {
        getProjects: () => {
            dispatch(getProjects())
        },
        setProjectsFilters: filters => {
            dispatch(setProjectsFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.projects.filters,
        globalCompanyId: state.common.globalCompanyId,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);