import React, { useState, useEffect } from 'react';

import { useLocation } from "react-router-dom";

import CommissionListComponent from './commissionPlan-list.component';
import CommissionEditComponent from './commissionPlanEdit';

const CommissionsComponent = () => {
    const { search } = useLocation();

    const [ currentCommissionId, setCurrentCommissionId] = useState((new URLSearchParams(search)).get("commissionId"));

    /** Change view depend on query string param change */
    useEffect(() => {
        const currentCommissionId = (new URLSearchParams(search)).get("commissionId");
        setCurrentCommissionId(currentCommissionId);
    }, [search])

    return !currentCommissionId ? (
        <CommissionListComponent />
    ) : (
        <CommissionEditComponent/>
    )
}

export default CommissionsComponent; 