import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Tabs from 'components/common/tabs';

import GroupsComponent from './groups';
import PermissionsComponent from './permissions';

import { getUser } from 'utils/auth';

import { USER_ROLE } from 'constants/user.constants';

/** User Edit Page Permissions & Groups Tab Component */
const PermissionsAndGroupsComponent = ({
    permissionResource,
    onTabChange
}) => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.users.groups"),
            component: <GroupsComponent permissionResource={permissionResource} />
        },
        {
            title: t("backoffice.users.permissions"),
            component: <PermissionsComponent permissionResource={permissionResource} />,
            disabled: getUser()?.role === USER_ROLE.ACCESS_MANAGER
        }
    ]

    return (
        <Tabs.SubTabs
            items={items}
            onTabChange={onTabChange}
        />
    )

    
}

/** PermissionsAndGroupsComponent propTypes
    * PropTypes
*/
PermissionsAndGroupsComponent.propTypes = {
    /** The permission resource */
    permissionResource: PropTypes.string,
    /** Fires when for any form in tab, saved/unsaved state is changed */
    onTabChange: PropTypes.func,
}


export default PermissionsAndGroupsComponent;
