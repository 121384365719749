import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setUsersActionBefore, setUsersActionFinished, setUsersSaveActionBefore, setUsersSaveActionFinished } from './users.action';

import { 
    SET_USER_PERMISSION_GROUPS,
    SET_USER_AVAILABLE_PERMISSION_GROUPS_BEFORE,
    SET_USER_AVAILABLE_PERMISSION_GROUPS_FINISH,
    SET_USER_AVAILABLE_PERMISSION_GROUPS
} from "../../../../actionTypes";

const setUserPermissionGroups = permissionGroups => ({
    type: SET_USER_PERMISSION_GROUPS,
    payload: { permissionGroups }
})

export const getUserPermissionGroups = id => {
    return dispatch => {
        dispatch(setUsersActionBefore());
        return axios({
            url: ApiUrls.GET_USER_PERMISSION_GROUPS,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: permissionGroups}}) => {
            dispatch(setUserPermissionGroups(permissionGroups))
            dispatch(setUsersActionFinished());
        })
        .catch((ex) => {
            dispatch(setUsersActionFinished());
        })
    }
}

export const addUserPermissionGroup = (id, groupId, note, onSuccess) => {
    return dispatch => {
        dispatch(setUsersSaveActionBefore());
        return axios({
            url: ApiUrls.ADD_USER_PERMISSION_GROUP,
            method: Methods.POST,
            data: {
                id,
                groupId,
                note
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getUserPermissionGroups(id));
                onSuccess && onSuccess();
            } 
            dispatch(setUsersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setUsersSaveActionFinished());
        })
    }
}

export const deleteUserPermissionGroup = (id, groupId, note) => {
    return dispatch => {
        dispatch(setUsersSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_PERMISSION_GROUP_USER,
            method: Methods.DELETE,
            data: {
                id, 
                groupId,
                note
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getUserPermissionGroups(id));
            } 
            dispatch(setUsersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setUsersSaveActionFinished());
        })
    }
}

const setUserAvailablePermissionGroupsBefore = () => ({
    type: SET_USER_AVAILABLE_PERMISSION_GROUPS_BEFORE
})

const setUserAvailablePermissionGroupsFinished = () => ({
    type: SET_USER_AVAILABLE_PERMISSION_GROUPS_FINISH
})

const setUserAvailablePermissionGroups = data => ({
    type: SET_USER_AVAILABLE_PERMISSION_GROUPS,
    payload: { data }
})


export const getUserAvailablePermissionGroups = userId => {
    return dispatch => {
        dispatch(setUserAvailablePermissionGroupsBefore());
        return axios({
            url: ApiUrls.GET_USER_AVAILABLE_PERMISSION_GROUPS,
            method: Methods.GET,
            params: { userId }
        })
        .then(({data : {value: data}}) => {
            dispatch(setUserAvailablePermissionGroups(data))
            dispatch(setUserAvailablePermissionGroupsFinished());
        })
        .catch((ex) => {
            dispatch(setUserAvailablePermissionGroupsFinished());
        })
    }
}
