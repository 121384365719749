import React from 'react';

import { useTranslation } from 'react-i18next';

import CheckboxesDropdown from "components/common/checkboxesDropdown";

import { ALL } from 'constants/common.constants';

import columnsButtonType from 'types/components/columnsButton.type';

/** Table Columns chooser component */
const ColumnsButton = ({ 
    columns, 
    onApply,
    defaultSelectedColumns 
}) => {
    const { t } = useTranslation();

    /** Function , fires on apply button click
       * @function
       * @param {array} value - selected columns
       * @memberOf ColumnsButton
   */
    const applyColumns = value => {
        onApply(value);
    }

    return (
        <CheckboxesDropdown
            columns={columns}
            buttonText={t('backoffice.common.columns')}
            buttonClassName="rt--table-columns-button"
            allowEmpty={true}
            selectAllByDefault={defaultSelectedColumns === ALL}
            defaultSelectedColumns={defaultSelectedColumns}
            onChange={applyColumns}
        />
    )
}

/** ColumnsButton propTypes
    * PropTypes
*/
ColumnsButton.propTypes = columnsButtonType;

export default ColumnsButton;