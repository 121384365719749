import React from "react";

import PromoCustomizeComponent from "pages/promoCustomize/promoCustomize.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";
import Paths from "constants/path.constants";

const PromoCustomizeRoute = () => {
    return <PromoCustomizeComponent />;
};

export default withPermission(
    withAuth(PromoCustomizeRoute),
    { resource: PERMISSION_RESOURCE.PROJECT, action: PERMISSION_ACTION.VIEW },
    Paths.PORTAL
);
