import React from "react";

import UsersEditComponent from "pages/userManagement/users/edit/user-edit.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const UsersEditRoute = () => {
    return <UsersEditComponent />;
};

export default withPermission(
    withAuth(UsersEditRoute),
    [
        {
            resource: PERMISSION_RESOURCE.ADMIN_GENERALINFO,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.ADMIN_COMPANY_ACCESS,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.ADMIN_PROJECT_ACCESS,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.ADMIN_PERMISSIONS,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.ADMIN_SESSIONS,
            action: PERMISSION_ACTION.VIEW,
        },
    ],
    Paths.PORTAL
);
