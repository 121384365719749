import React from "react";

import AffiliatesEdit from "pages/affiliates/affiliates/edit/affiliate-edit.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

import { USER_ROLE } from "constants/user.constants";

import { getUser } from "utils/auth";

const AffiliatesEditRoute = () => {
    return <AffiliatesEdit />;
};

export default withPermission(
    withAuth(AffiliatesEditRoute),
    [
        {
            resource: PERMISSION_RESOURCE.AFFILIATE,
            action: PERMISSION_ACTION.VIEW,
        },

        {
            resource: PERMISSION_RESOURCE.AFFILIATE_GENERALINFO,
            action: PERMISSION_ACTION.VIEW,
        },

        {
            resource: PERMISSION_RESOURCE.AFFILIATE_ADDITIONAL,
            action: PERMISSION_ACTION.VIEW,
        },

        {
            resource: (
                getUser()?.role === USER_ROLE.ADMIN
                    ? PERMISSION_RESOURCE.AFFILIATE_WALLETS
                    : PERMISSION_RESOURCE.AFFILIATE
            ),
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.AFFILIATE_SESSIONS,
            action: PERMISSION_ACTION.VIEW,
        },

        {
            resource: PERMISSION_RESOURCE.AFFILIATE_PAYMENT_METHODS,
            action: PERMISSION_ACTION.VIEW,
        }
    ],
    Paths.PORTAL
);
