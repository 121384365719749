import PropTypes from 'prop-types';

import { PROJECT_BRAND_PRODUCT_TYPE } from 'constants/project.constants';
import { CAMPAIGN_STATE } from 'constants/campaign.constants';

export default PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    languageCode: PropTypes.string,
    product: PropTypes.oneOf(Object.values(PROJECT_BRAND_PRODUCT_TYPE)),
    createdAt: PropTypes.string,
    createdBy: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
    brandName: PropTypes.string,
    isPrivate: PropTypes.bool,
    usesCount: PropTypes.number,
    creativeCount: PropTypes.number,
    mediaCount: PropTypes.number,
    affiliatesCount: PropTypes.number,
    status: PropTypes.oneOf(Object.values(CAMPAIGN_STATE)),
})