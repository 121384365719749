import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import LanguageUtils from 'utils/languages';

import { initTranslations } from 'translations/config';

import { setAllCompanies } from 'store/actions/portal/common/common.action';

import { 
    SET_USER_INFO_ACTION_BEFORE, 
    SET_USER_INFO_ACTION_FINISH,
    SET_USER_INFO,
    UPDATE_USER_AVATAR
} from "../../../actionTypes";

const setUserInfoActionBefore = () => ({
    type: SET_USER_INFO_ACTION_BEFORE,
});

const setUserInfoActionFinished = () => ({
    type: SET_USER_INFO_ACTION_FINISH,
});

const setUserInfo = userInfo => ({
    type: SET_USER_INFO,
    payload: { userInfo }
})

export const updateUserAvatar = avatarId => ({
    type: UPDATE_USER_AVATAR,
    payload: { avatarId }
})

export const getUserInfo = loadInBackground => {
    return dispatch => {
        if(!loadInBackground){
            dispatch(setUserInfoActionBefore());
        }

        return axios({
            url: ApiUrls.GET_USER_INFO,
            method: Methods.GET,
            params: {
                languageCode: LanguageUtils.getSelectedLanguage()
            }
        })
        .then(({data : {value: info}}) => {
            const infoValue = {...info };
            infoValue.permissions = info.permissions ?? {}

            dispatch(setUserInfo(infoValue));

            const companies = infoValue.companies.map(c => ({id: c.id, name: c.name, type: c.type, projects: c.projects ?? []}))

            dispatch(setAllCompanies(companies));

            dispatch(setUserInfoActionFinished());

            if(!loadInBackground){
                initTranslations(LanguageUtils.getSelectedLanguage(), infoValue.translationPath)
            }
        })
        .catch((ex) => {
            dispatch(setUserInfoActionFinished());
        })
    }
}