import React from 'react';

import { Skeleton } from "antd";

const ContentBlockSkeleton = () => {
    return (
        <div className="rt--customize-widget-main-content-active rt--flex-equal" >
            <div className="rt--customize-widget-main-content-header">
                <Skeleton.Button active={true} size={"small"} block={true} />
            </div>
            <div className="rt--customize-widget-main-content-block">
                <div className='rt--flex rt--mb-16'>
                    <Skeleton.Button active={true} size={"large"} block={true} className='rt--mr-16' />
                    <Skeleton.Button active={true} size={"large"} block={true} />
                </div>
                <div className='rt--flex'>
                    <Skeleton.Button active={true} size={"large"} block={true} className='rt--mr-16' />
                    <Skeleton.Button active={true} size={"large"} block={true} />
                </div>
            </div>
        </div>
    );
}

export default ContentBlockSkeleton;