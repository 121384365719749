import React from "react";

import AffiliateAccessComponent from "pages/affiliates/access/access.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const AffiliateAccessRoute = () => {
    return <AffiliateAccessComponent />;
};

export default withPermission(
    withAuth(AffiliateAccessRoute),
    { resource: PERMISSION_RESOURCE.PROJECT_AFFILIATE_ACCESS_SETTINGS, action: PERMISSION_ACTION.VIEW },
    Paths.PORTAL
);
