//#region react
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
//#endregion

//#region actions
import {
    getProjectPaymentMethods,
    deleteProjectPaymentMethod,
    changeProjectPaymentMethodState
} from "store/actions/portal/projects/paymentMethods.action";
//#endregion

//#region components
import SubTabTableDashboardLayout from "components/layouts/tab/subtab/table";
import Table from "components/common/table";
import AddPayment from "./payment-add.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { addQueryStringParamsToUrl } from 'utils/queryString';
//#endregion

//#region constants
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { tableColumns } from './columns';
import { PROJECT_PAYMENTS } from 'constants/pageName.constants';
import { PROJECT_PAYMENT_STATE } from 'constants/project.constants';
//#endregion

//#region types
import projectPaymentMethodType from 'types/project/paymentMethod.type';
//#endregion

/** Project Edit Page Financial Tab PaymentMethods Sub Tab List Component */
const PaymentMethodListComponent = ({
    getProjectPaymentMethods,
    deleteProjectPaymentMethod,
    changeProjectPaymentMethodState,
    isLoading,
    isSaving,
    paymentMethods,
}) => {
    const { t } = useTranslation();
    const { search, pathname, hash } = useLocation();
    const navigate = useNavigate();

    const [isAddPopupVisible, setIsAddPopupVisible] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PROJECT_PAYMENTS });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasCreatePermission = hasPermission({
        resource: PERMISSION_RESOURCE.PROJECT_PAYMENT_METHODS,
        action: PERMISSION_ACTION.CREATE
    });

    const hasDeletePermission = hasPermission({
        resource: PERMISSION_RESOURCE.PROJECT_PAYMENT_METHODS,
        action: PERMISSION_ACTION.DELETE
    });

    const hasModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PROJECT_PAYMENT_METHODS,
        action: PERMISSION_ACTION.MODIFY
    });

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns, columnsThatCanBeIncluded } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: tableColumns,
            constructForInclude: true,
            includedColumns,
        });
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const getAssignedAffiliatesCount = payment => {
        return (payment.currencies ?? []).reduce((accumulator, currentValue) => {
            return accumulator + currentValue.affiliatesCount
        },0);
    }

    const tableRowActions = [
        {
            title: t('backoffice.common.delete'),
            confirmationMessage: t("backoffice.projects.doYouWantToDeletePayment"),
            confirmationTitle: t("backoffice.common.delete"),
            icon: "trash",
            showConfirmation: true,
            className: "rt--button-danger",
            disabled: payment => !hasDeletePermission || getAssignedAffiliatesCount(payment) > 0,
            onClick: payment => deleteProjectPaymentMethod(payment.id, getProjectPaymentMethods)
        },
        {
            title: t('backoffice.common.deepView'),
            icon: "right",
            disabled: () => !hasModifyPermission,
            onClick: payment => {
                navigate(
                    addQueryStringParamsToUrl(
                        pathname, 
                        search, 
                        hash, 
                        {
                            paymentId: payment.id,
                            paymentName: payment.name
                        }
                    )
                )
            }
        }
    ];

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            icon: "plus",
            type: "primary",
            onClick: () => setIsAddPopupVisible(true),
            text: t("backoffice.projects.addPayment"),
            enabled: hasCreatePermission
        }
    }

    //#endregion

    return (
        <SubTabTableDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading || isSaving}
                columns={mainTableColumns}
                data={paymentMethods}
                loadFn={() => getProjectPaymentMethods()}
                noPagination={true}
                disableFullView={true}
                actions={tableRowActions}
                switcher={{
                    onChange: (isChecked, record) => changeProjectPaymentMethodState(isChecked, record.id),
                    valueIndex: "state",
                    dataIndex: "state",
                    className: !hasModifyPermission ? "rt--form-item-disabled" : "",
                    valueHandler: value => value === PROJECT_PAYMENT_STATE.ACTIVE,
                    disabled: record => !hasModifyPermission
                }}
            />

            {isAddPopupVisible && <AddPayment onClose={() => setIsAddPopupVisible(false)} />}
        </SubTabTableDashboardLayout>
    )
}

PaymentMethodListComponent.propTypes = {
    /** Redux action to get Project Payment Methods */
    getProjectPaymentMethods: PropTypes.func,
    /** Redux action to delete Project Payment Methods */
    deleteProjectPaymentMethod: PropTypes.func,
    /** Redux action to change Project Payment Method status */
    changeProjectPaymentMethodState: PropTypes.func,
    /** Redux state property, is true when loading project payments */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when changing Project Payment State */
    isSaving: PropTypes.bool,
    /** Redux state, represents the Payments of current editing Project */
    paymentMethods: PropTypes.arrayOf(projectPaymentMethodType)
}

const mapDispatchToProps = dispatch => (
    {
        getProjectPaymentMethods: () => {
            dispatch(getProjectPaymentMethods());
        },
        deleteProjectPaymentMethod: (id, onSuccess) => {
            dispatch(deleteProjectPaymentMethod(id, onSuccess));
        },
        changeProjectPaymentMethodState: (isChecked, paymentId) => {
            dispatch(changeProjectPaymentMethodState(isChecked, paymentId));
        }
    }
)

const mapStateToProps = state => {
    return {
        paymentMethods: state.projects.edit.paymentMethods.paymentMethods,
        isLoading: state.projects.isLoading,
        isSaving: state.projects.isSaving
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodListComponent)