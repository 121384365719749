import { WALLET_OWNER_TYPE } from "constants/wallet.constants";
import { updateWalletsAfterAdjustment } from "utils/affiliates";

import {
    SET_AFFILIATES_ACTION_BEFORE,
    SET_AFFILIATES_ACTION_FINISH,
    SET_AFFILIATES_SAVE_ACTION_BEFORE,
    SET_AFFILIATES_SAVE_ACTION_FINISH,
    SET_AFFILIATES,
    SET_AFFILIATES_SORTING,
    SET_AFFILIATES_FILTERS,
    SET_AFFILIATE_WALLETS_BY_CURRENCY,
    SET_AFFILIATE_GENERAL_INFO,
    SET_AFFILIATE_ADDITIONAL_INFO,
    SET_AFFILIATE_TRAFFIC_SOURCES,
    SET_AFFILIATES_SUB_AFFILIATES,
    SET_AFFILIATES_SUB_AFFILIATES_FILTERS,
    SET_AFFILIATE_AVAILABLE_PAYMENT_METHODS,
    SET_AFFILIATE_PAYMENT_METHOD_FORM_CONTROLS,
    SET_AFFILIATE_PAYMENT_METHOD_DETAILS,
    SET_AFFILIATE_PAYMENT_METHODS,
    SET_AFFILIATE_NEGATIVE_CARRY_OVER
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_AFFILIATES_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_AFFILIATES_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_AFFILIATES_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_AFFILIATES_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_AFFILIATES:
            return {
                ...state,
                affiliates: !payload.add ? payload.affiliates.item2 : state.affiliates.concat(payload.affiliates.item2),
                total: payload.affiliates.item1,
            };
        case SET_AFFILIATES_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_AFFILIATES_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_AFFILIATE_WALLETS_BY_CURRENCY:
            return {
                ...state,
                affiliates: state.affiliates.map(affiliate => ({
                    ...affiliate,
                    wallets: updateWalletsAfterAdjustment(
                        affiliate.wallets,
                        payload.wallets.filter(w => w.ownerType === WALLET_OWNER_TYPE.AFFILIATE && w.userName === affiliate.userName)
                    )
                }))
            };
        case SET_AFFILIATE_GENERAL_INFO:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...state.edit.general,
                        personal: {
                            ...payload.info
                        }
                    }
                }
            }
        case SET_AFFILIATE_ADDITIONAL_INFO:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...state.edit.general,
                        additional: {
                            ...payload.info
                        }
                    }
                }
            }
        case SET_AFFILIATE_TRAFFIC_SOURCES:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...state.edit.general,
                        trafficSources: payload.info
                    }
                }
            }
        case SET_AFFILIATES_SUB_AFFILIATES:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    subAffiliates: {
                        ...state.edit.subAffiliates,
                        subAffiliates: payload.subAffiliates.subAffiliates,
                        referralURL: payload.subAffiliates.referralURL,
                        referralCommissionPercent: payload.subAffiliates.referralCommissionPercent,
                    }
                }
            };
        case SET_AFFILIATES_SUB_AFFILIATES_FILTERS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    subAffiliates: {
                        ...state.edit.subAffiliates,
                        filters: payload.filters
                    }
                }
            };
        case SET_AFFILIATE_AVAILABLE_PAYMENT_METHODS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    finance: {
                        ...state.edit.finance,
                        paymentMethods: {
                            ...state.edit.finance.paymentMethods,
                            availablePaymentMethods: payload.availablePaymentMethods
                        }
                    }
                }
            }
        case SET_AFFILIATE_PAYMENT_METHOD_FORM_CONTROLS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    finance: {
                        ...state.edit.finance,
                        paymentMethods: {
                            ...state.edit.finance.paymentMethods,
                            paymentMethodFormControls: payload.paymentMethodFormControls
                        }
                    }
                }
            }
        case SET_AFFILIATE_PAYMENT_METHOD_DETAILS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    finance: {
                        ...state.edit.finance,
                        paymentMethods: {
                            ...state.edit.finance.paymentMethods,
                            data: state.edit.finance.paymentMethods.data.map(
                                method => method.id === payload.paymentId && method.currencyCode === payload.currencyCode ?
                                    ({
                                        ...method,
                                        details: payload.paymentDetails
                                    }) : method
                            )
                        }
                    }
                }
            }
        case SET_AFFILIATE_PAYMENT_METHODS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    finance: {
                        ...state.edit.finance,
                        paymentMethods: {
                            ...state.edit.finance.paymentMethods,
                            data: payload.paymentMethods.map(method => ({
                                ...method,
                                unique: method.id + "_" + method.currencyCode //unique key
                            }))
                        }
                    }
                }
            }
        case SET_AFFILIATE_NEGATIVE_CARRY_OVER:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    isNegativeCarryOverEnabled: payload.isNegativeCarryOverEnabled
                }
            }
        default:
            return state;
    }
};
