import React,  { Component } from 'react';

import { Navigate } from 'react-router-dom';

import  { isAuthenticated } from 'utils/auth';

import Paths from 'constants/path.constants';

/** Hocs which allows access to page only unAuthorized users 
       * @function
       * @param {Component} WrappedComponent - Component to add functionality 
   */
const WithAnanymous = WrappedComponent  => {

    class WithAnanymous extends Component {

        render () {
            const returnURL = (new URLSearchParams(location.search)).get("returnURL");

            if(isAuthenticated()){
                return <Navigate to={returnURL ? `${Paths.PORTAL}?returnURL=${encodeURIComponent(returnURL)}` : Paths.PORTAL} />
            }
    
            return <WrappedComponent {...this.props} />;
        }
    }

    WithAnanymous.displayName = `WithAnanymous(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
    return WithAnanymous;
}

export default WithAnanymous;