import React from 'react';
import { useTranslation } from "react-i18next";

import DefaultEmptyView from 'components/common/table/defaultEmptyView';

const NoData = () => {
    const { t } = useTranslation();

    return (
        <div className="rt--chart-top-section-empty rt--flex rt--flex-col rt--justify-center rt--align-center rt--pt-24 rt--pb-24">
            <i className="icon-empty rt--font-bigest" />

            <DefaultEmptyView/>
        </div>
    );
}

export default NoData;