import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Tabs from "components/common/tabs";

import PasswordSettingsComponent from './passwordSettings';
import TokenSettingsComponent from './tokenSettings';
import AuthenticationSettingsComponent from './authenticationSettings';

/** Company Edit Page Security Settings Tab Component */
const SecuritySettingsComponent = ({
    onTabChange
}) => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.companies.passwordSettings"),
            component: <PasswordSettingsComponent/>
        },
        {
            title: t("backoffice.companies.tokenAndmultidevice"),
            component: <TokenSettingsComponent />
        },
        {
            title: t("backoffice.companies.authenticationSettings"),
            component: <AuthenticationSettingsComponent />
        }
    ]

    return (
        <Tabs.SubTabs 
            items={items}
            onTabChange={onTabChange}
        />
    )
}

/** SecuritySettingsComponent propTypes
    * PropTypes
*/
SecuritySettingsComponent.propTypes = {
    /** Fires when for any form in tab, saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

export default SecuritySettingsComponent;
