import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Spin } from "antd";

import Paths from 'constants/path.constants';

import LanguageUtils from 'utils/languages';

import { verifyEmail } from 'store/actions/auth/verify.action';

/** User Activation Page Component */
const ActivateComponent = ({
    verifyEmail,
    isVerifying
}) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const token = searchParams.get("t");

    const currentLn = LanguageUtils.getSelectedLanguage();

    useEffect(() => {
        if (token) {
            verifyEmail(token, () => {
                navigate(Paths.LOGIN + "?verified=true&language=" + currentLn, {replace: true})
            })
        }
    }, [])

    return (
        <div className="rt--auth-form">
            {
                !token ? (
                    <div className='rt--auth-error rt--mb-16 rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16'>
                        <b className='rt--title rt--font-medium rt--font-normal'>
                            {
                                t("backoffice.authentication.noTokenFound")
                            }
                        </b>
                    </div>
                ) : (
                    <Spin spinning={isVerifying}>

                    </Spin>
                )
            }
        </div>
    )
}

/** ActivateComponent propTypes
     * PropTypes
*/
ActivateComponent.propTypes = {
    /** Redux state property, is true when verify request is in process */
    isVerifying: PropTypes.bool,
    /** Redux action to verify Email */
    verifyEmail: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        verifyEmail: (token, onSuccess) => {
            dispatch(verifyEmail(token, onSuccess))
        }
    }
)

const mapStateToProps = state => {
    return {
        isVerifying: state.auth.isVerifying,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateComponent);