import React from "react";

import PermissionGroupsEditComponent from "pages/userManagement/permissionGroups/edit/permissionGroup-edit.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const PermissionGroupsEditRoute = () => {
    return <PermissionGroupsEditComponent />;
};

export default withPermission(
    withAuth(PermissionGroupsEditRoute),
    {
        resource: PERMISSION_RESOURCE.PERMISSION_GROUP,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.PORTAL
);
