import React from "react";

import Status from "components/common/status";
import Icon from "components/common/icon";
import Tooltip from "components/common/tooltip";

import { STATUS_TYPES } from "constants/status.constants";

export const tableColumns = [
	{
		title: "backoffice.projects.hostname",
		dataIndex: "hostname",
		mobileLevel: 1,
	},
	{
		title: "backoffice.projects.ingress",
		dataIndex: "ingress",
		mobileLevel: 2,
		render: (value) => (
			<div className="rt--flex rt--align-center">
				<Status type={STATUS_TYPES.DOMAIN} status={value?.status} />
				{value?.errors && (
					<Tooltip
						title={value?.errors}
						trigger={["hover", "click"]}
						placement="bottomLeft"
						enableMobile={true}
					>
						<div className="rt--ml-4 rt--flex rt--align-center rt--cursor-pointer">
							<Icon name="info" size={20} />
						</div>
					</Tooltip>
				)}
			</div>
		),
	},
	{
		title: "backoffice.projects.cloudflare",
		dataIndex: "cloudflare",
		mobileLevel: 3,
		render: (value) => (
			<div className="rt--flex rt--align-center">
				<Status type={STATUS_TYPES.DOMAIN} status={value?.status} />
				{value?.errors && (
					<Tooltip
						title={value?.errors}
						trigger={["hover", "click"]}
						placement="bottomLeft"
						enableMobile={true}
					>
						<div className="rt--ml-4 rt--flex rt--align-center rt--cursor-pointer">
							<Icon name="info" size={20} />
						</div>
					</Tooltip>
				)}
			</div>
		),
	},
];
