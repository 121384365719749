export const tableColumns = [
    {
        title: "backoffice.errorlogs.controller/action",
        dataIndex: "controller.action",
        mobileLevel: 1,
        render: (_, record) => (
            (record?.controller ?? "") + "/" + (record?.action ?? "")
        ),
    },
    {
        title: "backoffice.errorlogs.id",
        dataIndex: "id",
        copy: true,
    },
    {
        title: "backoffice.errorlogs.ip",
        dataIndex: "ip",
        mobileLevel: 2,
    },
    {
        title: "backoffice.errorlogs.message",
        dataIndex: "message",
        short: true,
        mobileLevel: 3,
    },
    {
        title: "backoffice.errorlogs.time",
        dataIndex: "errorTime",
        isDateTime: true,
        mobileLevel: 4,
    },
];
