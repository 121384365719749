import React, { useEffect } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux';
import { Layout as AntLayout, ConfigProvider, Spin, message } from 'antd';

import { useTranslation } from 'react-i18next';

import LogoutModal from 'components/common/logoutModal';

import { getUserInfo } from "store/actions/portal/profile/userInfo.action";
import { getCurrencies } from "store/actions/portal/profile/currencies.action";
import { addNotification } from "store/actions/portal/notifications/notifications.action";

import { getUser, refreshToken, logout } from 'utils/auth';
import SignalRUtils from 'utils/signalR';
import LanguageUtils from 'utils/languages';
import dateService from "utils/date";

import useLogo from 'hooks/useLogo';

import { USER_ROLE, USER_TYPE } from "constants/user.constants";
import { LOGO_TYPE, SIGNALR_CONNECTION_TYPES } from 'constants/common.constants';

import userInfoType from 'types/profile/userInfo.type';

import enLang from 'antd/es/locale/en_US';
import frLang from 'antd/es/locale/fr_FR';
import esLang from 'antd/es/locale/es_ES';
import ruLang from 'antd/es/locale/ru_RU';
import faLang from 'antd/es/locale/fa_IR';
import zhLang from 'antd/es/locale/zh_CN';

const languageCode = LanguageUtils.getSelectedLanguage().toLowerCase();
const languagesMapping = {
    "en": enLang,
    "fr": frLang,
    "es": esLang,
    "ru": ruLang,
    "fa": faLang,
    "zh": zhLang
}

const mapLocaleToDateServiceLocale = {
    "en": "en",
    "fr": "fr",
    "es": "es",
    "ru": "ru",
    "fa": "fa",
    "zh": "zh-cn",
}

const locale = languagesMapping[languageCode] || enLang;

dateService.setLocale(mapLocaleToDateServiceLocale[languageCode])

let interval = null;

/** General wrapper for dashboard pages */
const Container = ({
    children,
    getUserInfo,
    isUserInfoLoading,
    addNotification,
    wsToken,
    userInfo,
    globalProjectId,
    getCurrencies
}) => {

    const { t } = useTranslation();

    /** Set Favicon */
    const { logos } = useLogo()

    useEffect(() => {
        const favicon = document.querySelector('link[rel="icon"]');
        favicon.href = `${import.meta.env.SYSTEM_CDN_URL}/${logos[LOGO_TYPE.FAVICON]}`
    }, [logos])

    /** Handle SignalR */
    useEffect(() => {

        if (wsToken) {
            SignalRUtils.buildConnections(handleSignalREvents);
        }

        return () => {
            clearInterval(interval);
            SignalRUtils.getConnections().forEach(connection => {
                connection.getConnection().off('UpdateSession');
                connection.getConnection().off('RefreshToken');
                connection.getConnection().off('Notification');
                connection.getConnection().off('Logout');
                connection.stopConnection();
            })
        }
    }, [wsToken])

    /** Function to subscribe and handle signalR events
       * @function
       * @description checks to allow only numeric characters
       * @memberOf Container
   */
    const handleSignalREvents = (connection, signalRType) => {
        if (signalRType === SIGNALR_CONNECTION_TYPES.ADMIN) {
            interval = setInterval(() => {
                if (connection.getConnection()._connectionState === "Connected") {
                    connection.getConnection().invoke(
                        "UpdateSession",
                        getUser()?.sessionId,
                        userInfo.id,
                        getUser()?.role === USER_ROLE.ADMIN ? USER_TYPE.ADMIN :
                            getUser()?.role === USER_ROLE.AFFILIATE ? USER_TYPE.AFFILIATE :
                                getUser()?.role === USER_ROLE.AFFILIATE_MANAGER ? USER_TYPE.AFFILIATE_MANAGER : null
                    );
                }
            }, 60000)
        }
        connection.getConnection().on('RefreshToken', () => {
            const token = getUser()?.refreshToken ?? null;
            refreshToken(token);
        });

        connection.getConnection().on('Logout', () => {
            if(!getUser()?.hasPrevious){
                logout();
            }
        });

        connection.getConnection().on('Notification', data => {
            if (getUser()?.role !== USER_ROLE.ACCESS_MANAGER) {
                addNotification(JSON.parse(data))
            }
        });

        connection.getConnection().on('UpdateUserInfo', data => {
            getUserInfo(true)
        })
    }

    /** Init antd configs */
    useEffect(() => {
        message.config({
            maxCount: 3
        });
    }, [])

    useEffect(() => {
        if (globalProjectId && userInfo.id) {
            if(
                (
                    getUser()?.role === USER_ROLE.ADMIN ||
                    getUser()?.role === USER_ROLE.AFFILIATE || 
                    getUser()?.role === USER_ROLE.AFFILIATE_MANAGER
                )
            ){
                getCurrencies();
            }
        }
    }, [globalProjectId, userInfo.id])

    return !isUserInfoLoading ? (
        <ConfigProvider
            getPopupContainer={trigger => trigger && trigger.parentNode ? trigger.parentNode : document.body}
            locale={locale}
        >
            <AntLayout className='rt--portal-layout'>
                {children}
                <LogoutModal />
            </AntLayout>
        </ConfigProvider >
    ) : <Spin spinning={isUserInfoLoading} />
}

/** Container propTypes
    * PropTypes
*/
Container.propTypes = {
    /** Page content */
    children: PropTypes.node,
    /** Redux action to get user info */
    getUserInfo: PropTypes.func,
    /** Redux state property, is true when loading user info */
    isUserInfoLoading: PropTypes.bool,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Redux action to add notification */
    addNotification: PropTypes.func,
    /** Redux state property, current WS Token */
    wsToken: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux action to get account currencies */
    getCurrencies: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        isUserInfoLoading: state.profile.userInfo.isLoading,
        userInfo: state.profile.userInfo,
        wsToken: state.auth.wsToken,
        globalProjectId: state.common.globalProjectId
    }
}

const mapDispatchToProps = dispatch => (
    {
        getUserInfo: loadInBackground => {
            dispatch(getUserInfo(loadInBackground))
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
        getCurrencies: () => {
            dispatch(getCurrencies());
        },
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(Container);