import axios from 'axios';
import { message } from 'antd';
import { isResponseSuccess } from "utils/request";

import Methods from 'constants/methods.constants';
import ApiUrls from 'constants/api.constants';

import {
    FORGOT_PASSWORD_ACTION_BEFORE,
    FORGOT_PASSWORD_ACTION_FINISH
} from '../../actionTypes';

const setForgotPasswordActionBefore = () => ({
    type: FORGOT_PASSWORD_ACTION_BEFORE
})

const setForgotPasswordActionFinished = () => ({
    type: FORGOT_PASSWORD_ACTION_FINISH
})

export const forgotPassword = email => {
    return dispatch => {
        dispatch(setForgotPasswordActionBefore());
        return axios({
            url: ApiUrls.FORGOT_PASSWORD,
            method: Methods.POST,
            data: { email }
        })
        .then(({ data }) => {
            dispatch(setForgotPasswordActionFinished());
            if(isResponseSuccess(data)) {
                message.success(data.message);
            } 
        })
        .catch(() => {
            dispatch(setForgotPasswordActionFinished());
        })
    }
}




