import React from 'react';

import SetPasswordComponent from 'components/auth/setPassword/setPassword.component';
import withAnanymous from 'hocs/withAnanymous';

const SetPasswordRoute = () => {
    return (
        <SetPasswordComponent /> 
    )
}

export default withAnanymous(SetPasswordRoute);