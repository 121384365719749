import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

export const recalculateCommissions = async (from, to) => {
    const response = await axios({
        url: `${ApiUrls.RECALCULATE_COMMISSIONS}`,
        method: Methods.POST,
        data: {
            from,
            to
        },
    })

    return response?.data;
}
