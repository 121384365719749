import { hasPermission, hasOneOfPermissions } from 'utils/permissions';
import { getUser } from "utils/auth";

/** Check if menu item is available
   * @function
   * @returns {boolean}
*/
export const isMenuItemAvailable = ( item, projectId ) => {

    if(
        item.userRole && !item.userRole.includes(getUser()?.role)
    ){
        return false;
    }

    /** Check permissions */
    if (item.permission) {
        if (Array.isArray(item.permission)) {
            if (!hasOneOfPermissions(item.permission)) return false;
        } else {
            if (!hasPermission(item.permission)) return false;
        }
    }

    if (item.disabled) return false;

    /** Check projectId */
    if (item.isProjectRequired && !projectId) {
        return false;
    }


    if(item.items && item.items.length > 0){
        if(item.items.every(i => !isMenuItemAvailable(i, projectId))){
            return false
        }
    }

    return true;
}