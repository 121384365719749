export const STATUS_TYPES = {
    COMPANY: "company",
    JOB: "job",
    PLAYER: "player",
    USER: "user",
    COMMISSION: "commission",
    INVOICE: "invoice",
    MEDIA: "media",
    CAMPAIGN: "campaign",
    AFFILIATE: "affiliate",
    MEDIA_CLICK: "mediaClick",
    COMMISSION_CALCULATION: "commissionCalculation",
    NEWSLETTER: "newsletter",
    DBCONNECTION: "dbConnection",
    AFFILIATE_GROUP: "affiliateGroup",
    DOMAIN: "domain"
}