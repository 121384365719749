//#region React
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
//#endregion

import { Form, Col, Row } from 'antd';

import useAutosuggestion from 'hooks/useAutosuggestion';

//#region Components
import Modal from "components/common/modal";
import Input from 'components/common/input';
import Select from 'components/common/select';
import ImageUploader from "components/common/imageUploader";
//#endregion

//#region Actions
import { createSystemPayment, getSystemPayments } from "store/actions/portal/settings/systemPayments/systemPayments.action";
//#endregion

//#region Utils
import { hasPermission } from 'utils/permissions';
//#endregion

//#region Constants
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { NAME_REGEX } from "constants/regex.constants";
import { LABEL_INPUT_REGEX } from "constants/systemPayments.constants";
import Paths from 'constants/path.constants';
import { POPUP_SIZE } from "constants/common.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
//#endregion


const CREATE_FIELD_NAMES = {
    NAME: "name",
    LABELS: "labels",
    FILE: "file"
}

/** System Payment Creating Popup Component */
const CreateSystemPayment = ({
    isSaving,
    getSystemPayments,
    createSystemPayment,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;
    const navigate = useNavigate();

    const [ systemPaymentsLabels ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.PAYMENT_LABELS
    });

    const labelsOptions = useMemo(() => {
        return systemPaymentsLabels.map(({ name }) => ({ text: name, value: name }));
    }, [systemPaymentsLabels]);

    const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.SYSTEM_PAYMENT_METHODS, action: PERMISSION_ACTION.MODIFY });

    const handleForm = () => {
        validateFields()
            .then((data) => {
                createSystemPayment(
                    { ...data },
                    record => {
                        if (hasModifyPermission) {
                            navigate(`${Paths.SETTINGS_PAYMENTS_EDIT}/${record.id}`);
                        } else {
                            getSystemPayments()
                            onClose();
                        }

                    });
            }).catch(() => { })
    };

    return (
        <Modal
            title={t('backoffice.payments.createSystemPayment')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            cancelButtonProps={{ disabled: isSaving }}
            isLoading={isSaving}
            size={POPUP_SIZE.MIDDLE}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    [CREATE_FIELD_NAMES.NAME]: "",
                    [CREATE_FIELD_NAMES.LABELS]: [],
                    [CREATE_FIELD_NAMES.FILE]: null
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.payments.name')}
                            name={CREATE_FIELD_NAMES.NAME}
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') },
                                { min: 5, message: t('backoffice.validation.fieldInvalid') },
                                { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.payments.name')}`}
                            validateFirst
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.payments.name')}`}
                                maxLength={30}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.payments.labels')}
                            name={CREATE_FIELD_NAMES.LABELS}
                            rules={[
                                { max: 5, type: "array", message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className='rt--general-form-item'
                            validateFirst
                        >
                            <Select
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.payments.labels')}`}
                                options={labelsOptions}
                                search={{ pattern: LABEL_INPUT_REGEX }}
                                add={true}
                                isMultiple={true}
                                showSelectAllButton={false}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label=""
                            name={CREATE_FIELD_NAMES.FILE}
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                            validateFirst
                        >
                            <ImageUploader
                                size={1024 * 1024}
                                title={t('backoffice.payments.logo')}
                                titleTooltip={t('backoffice.payments.headerInfo')}
                            />
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
        </Modal>
    )
}

CreateSystemPayment.propTypes = {
    /** Redux state property, is true when creating System Payment request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to create System Payment */
    createSystemPayment: PropTypes.func,
    /** Redux action to System Payments */
    getSystemPayments: PropTypes.func,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createSystemPayment: (payment, onSuccess) => {
            dispatch(createSystemPayment(payment, onSuccess));
        },
        getSystemPayments: () => {
            dispatch(getSystemPayments());
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.systemPayments.isSaving
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSystemPayment);