import React from "react";

import ProfileComponent from "pages/profile/profile.component";

import withAuth from "hocs/withAuth";

const ProfileRoute = () => {
    return <ProfileComponent />;
};

export default withAuth(ProfileRoute);
