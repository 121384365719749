import {
    SET_NEWSLETTER_CUSTOMIZE_ACTION_BEFORE,
    SET_NEWSLETTER_CUSTOMIZE_ACTION_FINISH,
    SET_NEWSLETTER_CUSTOMIZE_SAVE_ACTION_BEFORE,
    SET_NEWSLETTER_CUSTOMIZE_SAVE_ACTION_FINISH,
    SET_NEWSLETTER_CUSTOMIZE_CONFIGURATION,
    SET_NEWSLETTER_TEMPLATE_VARIABLES,
    SET_NEWSLETTER_CUSTOMIZE_COLORS,
    SET_NEWSLETTER_CUSTOMIZE_FONT_FAMILY,
    SET_NEWSLETTER_CUSTOMIZE_IMAGE,
    SET_NEWSLETTER_CUSTOMIZE_TEXT,
    SET_NEWSLETTER_CUSTOMIZE_SECTION_STATE
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_NEWSLETTER_CUSTOMIZE_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_NEWSLETTER_CUSTOMIZE_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_NEWSLETTER_CUSTOMIZE_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true
            }
        case SET_NEWSLETTER_CUSTOMIZE_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false
            }
        case SET_NEWSLETTER_CUSTOMIZE_CONFIGURATION:
            return {
                ...state,
                configuration: payload.configuration
            }
        case SET_NEWSLETTER_CUSTOMIZE_COLORS: 
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    colors: payload.colors
                },
            }
        case SET_NEWSLETTER_CUSTOMIZE_FONT_FAMILY:
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    fontFamily: payload.fontFamily
                },
            }
        case SET_NEWSLETTER_CUSTOMIZE_IMAGE:
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    files: {
                        ...state.configuration.files,
                        [payload.name]: payload.path
                    }
                },
            }
        case SET_NEWSLETTER_CUSTOMIZE_TEXT:
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    texts: {
                        ...state.configuration.texts,
                        [payload.name]: payload.text
                    }
                },
            }
        case SET_NEWSLETTER_CUSTOMIZE_SECTION_STATE:
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    sections: {
                        ...state.configuration.sections,
                        [payload.name]: payload.enable
                    }
                },
            }
        case SET_NEWSLETTER_TEMPLATE_VARIABLES:
            return {
                ...state,
                templateVariables: payload.variables,
            }
        default:
            return state;
    }
};
