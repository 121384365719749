import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import {
    SET_JOBS_ACTION_BEFORE,
    SET_JOBS_ACTION_FINISH,
    SET_JOBS,
    UPDATE_JOB,
    SET_JOBS_SAVE_ACTION_BEFORE,
    SET_JOBS_SAVE_ACTION_FINISH,
    SET_JOB_SETTINGS,
    SET_JOB_DETAILS_ACTION_BEFORE,
    SET_JOB_DETAILS_ACTION_FINISH,
    SET_JOB_DETAILS
} from "../../../../actionTypes";

const setJobsActionBefore = () => ({
    type: SET_JOBS_ACTION_BEFORE
});

const setJobsActionFinished = () => ({
    type: SET_JOBS_ACTION_FINISH
});

const setJobs = jobs => ({
    type: SET_JOBS,
    payload: { jobs }
})

export const updateJob = job => {
    return {
        type: UPDATE_JOB,
        payload: { job }
    }
}

export const getJobs = () => {
    return dispatch => {
        dispatch(setJobsActionBefore());

        return axios({
            url: ApiUrls.GET_JOBS,
            method: Methods.GET
        })
        .then(({ data: { value: jobs } }) => {
            dispatch(setJobs(jobs));
            dispatch(setJobsActionFinished());
        })
        .catch(() => {
            dispatch(setJobsActionFinished());
        })
    }
}

const setJobsSaveActionBefore = () => ({
    type: SET_JOBS_SAVE_ACTION_BEFORE
})

const setJobsSaveActionFinished = () => ({
    type: SET_JOBS_SAVE_ACTION_FINISH
})

const setJobSettings = settings => ({
    type: SET_JOB_SETTINGS,
    payload: { settings }
})

export const getJobSettings = id => {
    return dispatch => {
        return axios({
            url: ApiUrls.GET_JOB_SETTINGS,
            method: Methods.GET,
            params: { id }
        })
        .then(({data : {value: settings}}) => {
            dispatch(setJobSettings(settings))
        })
        .catch(() => {
        })
    }
}

export const saveJobSettings = settings => {
    return dispatch => {
        dispatch(setJobsSaveActionBefore());

        return axios({
            url: ApiUrls.SAVE_JOB_SETTINGS,
            method: Methods.POST,
            data: {
                ...settings
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(updateJob({...data.value, id: settings.id}))
                dispatch(setJobSettings(data.value));
            } 

            dispatch(setJobsSaveActionFinished());
        })
        .catch(() => {
            dispatch(setJobsSaveActionFinished());
        })
    }
}


const setJobDetailsActionBefore = () => ({
    type: SET_JOB_DETAILS_ACTION_BEFORE
})

const setJobDetailsActionFinished = () => ({
    type: SET_JOB_DETAILS_ACTION_FINISH
})

const setJobDetails = details => ({
    type: SET_JOB_DETAILS,
    payload: { details }
})

export const getJobDetails = id => {
    return dispatch => {
        dispatch(setJobDetailsActionBefore());
        return axios({
            url: ApiUrls.GET_JOB_DETAILS,
            method: Methods.GET,
            params: { id }
        })
        .then(({data : {value: details}}) => {
            dispatch(setJobDetails(details));
            dispatch(setJobDetailsActionFinished());
        })
        .catch(() => {
            dispatch(setJobDetailsActionFinished());
        })
    }
}

export const unlockJob = id => {
    return dispatch => {
        dispatch(setJobsActionBefore());

        return axios({
            url: ApiUrls.UNLOCK_JOB,
            method: Methods.POST,
            data: {id}
        })
        .then(({data}) => {
            if (isResponseSuccess(data)) {
                dispatch(updateJob(data.value))
            } 
            dispatch(setJobsActionFinished());
        })
        .catch(() => {
            dispatch(setJobsActionFinished());
        })
    }
}