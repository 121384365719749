import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Table as AntTable, ConfigProvider } from "antd";

import ExpandedRowTable from './expandedRowTable';
import DefaultEmptyView from '../defaultEmptyView';
import Icon from "components/common/icon";

import { classNames, isFunction, toUpperCaseFirstLetter } from 'utils/common';
import {
    allExpandableRowsAlredyExpanded,
    antdTableColumnsCreator,
    antdTableDataCreator,
    atLeastOneColumnExpandable,
    getPaginationData,
    getRowClassName,
    renderExpandedIcon
} from '../helpers';

import { ORDER_DIRECTION } from "constants/common.constants";
import { DEFAULT_UNIQUE_PROP, ROW_CHOOSER_TYPES } from '../constants';

import { TablePropTypes } from '../propTypes';

const TableDesktop = ({
    loading = false,
    noPagination = false,
    total = 0,
    expandable,
    uniqueKey = DEFAULT_UNIQUE_PROP,
    chooser,
    clickable,
    columns = [],
    data = [],
    sorting,
    setSortingFn,
    switcher,
    actions,
    enableReload = false,
    loadFn, // table data load function
    updateProps = [], // dependencies of table data load function
    filters = {},
    setFiltersFn,
    renderEmpty,
    shouldShowExpandedColumn = false, //TODO: chack showExpandColumn: shouldShowExpandedColumn || !expandable.expandAll this case and also rowClassNames whit only shouldShowExpandedColumn and change everywhere
    expandAllAvailable = false,
    formatNumber,
    totals
}) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const initialSortingRef = useRef(sorting);

    const loadTableData = useCallback((specificPage, delay = 20) => {
        if (!isFunction(loadFn)) {
            return;
        }

        // We need a delay in case of asynchronous update of some data in redux (filters, sorting...)
        setTimeout(() => loadFn(specificPage), delay);
    }, updateProps)

    const reloadTableData = () => {
        if (!isFunction(loadFn)) {
            console.error(
                "In the table, you must pass the loadFn prop, which loads the table data, if you want to activate the reload functionality"
            );
            return;
        }

        // if (isFunction(setFiltersFn)) {
        //     if (filters.to) {
        //         setFiltersFn({
        //             ...filters,
        //             to: dateService.toISOString(dateService.getNow(true)),
        //         }, true);
        //     }
        // }

        loadTableData();
    };

    const getRowEventsHandler = (record) => {
        const handleRowClick = (e) => {
            e.preventDefault();
            e.stopPropagation();

            if (clickable.disabled?.(record)) {
                return;
            }

            clickable.onClick(record);
        }

        return {
            onClick: handleRowClick,
        };
    }

    const handleRowExpandIconInternalClick = (expended, record) => {
        if (!expended) {
            return;
        }

        if (isFunction(expandable.detailsLoadFn)) {
            expandable.detailsLoadFn(record)
        }
    };

    const handleExpandableRowsInternalChange = (expendedRowKeys) => {
        if (expandable.disabled === undefined) {
            setExpandedRowKeys(expendedRowKeys);
            return;
        }

        const enabledExpandedRowKeys = (
            expendedRowKeys.filter(rowKey => !expandable.disabled.includes(rowKey))
        )

        setExpandedRowKeys(enabledExpandedRowKeys);
    }

    const expandedRowRenderer = (record) => {
        if (!expandable?.details) {
            return null;
        }

        const detailsObj = expandable.details(record);

        return (
            <ExpandedRowTable
                type={expandable.type}
                detailsObj={detailsObj}
                isLoading={!detailsObj}
                rowClassNameFn={
                    (record, index) => getRowClassName({
                        rowData: record,
                        rowIndex: index,
                        expandedRowKeys,
                        expandable,
                        clickable,
                        uniqueKey
                    })
                }
                formatNumber={formatNumber}
            />
        )
    }

    const onSortAndPaginationChange = (pagination, _, sorter) => {
        const newSortingData = {};

        if (pagination.current) {
            newSortingData.page = pagination.current;
        }

        if (pagination.pageSize) {
            newSortingData.limit = pagination.pageSize;
        }

        if (sorter.field) {
            newSortingData.orderBy = toUpperCaseFirstLetter(sorter.field);
        }

        if (sorter.order) {
            newSortingData.orderDirection = (
                sorter.order === "ascend" ? ORDER_DIRECTION.ASC : ORDER_DIRECTION.DESC
            )
        } else {
            newSortingData.orderDirection = initialSortingRef.current.orderDirection;
            newSortingData.orderBy = initialSortingRef.current.orderBy;
        }

        setSortingFn({
            ...sorting,
            ...newSortingData,
        });

        loadTableData();
    };

    const handleExpandAllIconClick = () => {
        if (expandable.disabled) {
            const dataWhitoutDisabledExpand = (
                data.filter(rowData => !expandable.disabled.includes(rowData[uniqueKey]))
            )

            setExpandedRowKeys([
                ...dataWhitoutDisabledExpand.map(rowData => rowData[uniqueKey])
            ]);

            return;
        }

        setExpandedRowKeys(data.map(rowData => rowData[uniqueKey]));
    }

    const handleColapseAllIconClikc = () => {
        if (expandable.disabled) {
            setExpandedRowKeys([]);

            return;
        }

        setExpandedRowKeys([]);
    }

    // Load Table Data
    useEffect(() => {
        loadTableData("first")
    }, [loadTableData]);

    // Close all rows when data updates
    useEffect(() => {
        if (loading && Boolean(expandable) && !expandable.expandAll) {
            setExpandedRowKeys([]);
        }
    }, [loading]);

    // Open All expand rows in case of data change
    useEffect(() => {
        if (!Boolean(expandable?.expandAll)) {
            return;
        }

        let tableData = data;

        if (expandable?.disabled) {
            tableData = tableData.filter(rowData => !expandable.disabled.includes(rowData[uniqueKey]));
        }

        setExpandedRowKeys(tableData.map(rowData => rowData[uniqueKey]));
    }, [data]);

    // Reset sorting on component unmount
    useEffect(() => {
        return () => {
            if (isFunction(setSortingFn)) {
                setSortingFn(initialSortingRef.current);
            }
        }
    }, []);

    const antdTableColumns = antdTableColumnsCreator({
        mainColumns: columns,
        additionalColumnsObj: {
            chooser,
            switcher,
            actions,
        },
        otherFeatures: {
            reloaderData: {
                isActive: enableReload,
                onClick: reloadTableData
            },
            sorting,
            formatNumber
        }
    })

    const antdTableData = antdTableDataCreator({
        rowsData: data,
        uniquePropFromRowData: uniqueKey
    })

    const rendererOfEmptyTable = (
        isFunction(renderEmpty)
            ? renderEmpty
            : () => <DefaultEmptyView />
    )

    const isAtLeastOneColumnEditable = columns.some(column => Boolean(column.editable));

    const isAtLeastOneImageColumn = columns.some(column => Boolean(column.image));

    //TODO: find optimisation ways when expandAllAvailable=false
    const isExpandAllIconVisible = atLeastOneColumnExpandable({ rowsData: antdTableData, expandable });
    const allAvilableRowsExpanded = allExpandableRowsAlredyExpanded({
        rowsData: antdTableData,
        disabledExpandRowKeys: expandable?.disabled,
        uniqueKey,
        expandedRowKeys,
    })

    return (
        <div className="rt--table-wrapper">
            {
                (expandAllAvailable && isExpandAllIconVisible) && (
                    <div
                        className={classNames(
                            "rt--table-expand-all",
                            "rt--cursor-pointer",
                            loading && "rt--table-expand-all-loading"
                        )}
                        onClick={
                            allAvilableRowsExpanded
                                ? handleColapseAllIconClikc
                                : handleExpandAllIconClick
                        }
                    >
                        <Icon
                            name={allAvilableRowsExpanded ? "collapse-all" : "expand-all"}
                        />
                    </div>
                )
            }
            <ConfigProvider
                getPopupContainer={() => document.body}
                renderEmpty={rendererOfEmptyTable}
            >
                <AntTable
                    className={classNames(
                        "rt--table",
                        Boolean(totals) && "rt--table-with-totals",
                        isAtLeastOneColumnEditable && "rt--table-prevent-row-hover",
                        isAtLeastOneImageColumn && "rt--table-large"
                    )}
                    loading={loading}
                    columns={antdTableColumns}
                    dataSource={antdTableData}
                    onChange={onSortAndPaginationChange}
                    scroll={{ x: true }}
                    showSorterTooltip={false}
                    rowClassName={
                        (record, index) => getRowClassName({
                            rowData: record,
                            rowIndex: index,
                            expandedRowKeys,
                            expandable,
                            clickable,
                            shouldShowExpandedColumn,
                            uniqueKey,
                        })
                    }
                    onRow={clickable && getRowEventsHandler}
                    pagination={!noPagination && (
                        getPaginationData({
                            total,
                            currentPage: sorting?.page,
                            currentPageSize: sorting?.limit,
                        })
                    )}
                    expandable={Boolean(expandable) && (
                        {
                            expandedRowKeys,
                            onExpand: handleRowExpandIconInternalClick,
                            onExpandedRowsChange: handleExpandableRowsInternalChange,
                            expandedRowRender: expandedRowRenderer,
                            showExpandColumn: shouldShowExpandedColumn || !expandable.expandAll,
                            expandIcon: (params) => (
                                renderExpandedIcon({
                                    params,
                                    disabledRowKeys: expandable.disabled || [],
                                    uniquePropFromRowData: uniqueKey,
                                })
                            )
                        }
                    )}
                    rowSelection={
                        (
                            chooser?.type === ROW_CHOOSER_TYPES.CHECKBOX ||
                            chooser?.type === ROW_CHOOSER_TYPES.RADIO
                        ) && (
                            {
                                type: chooser?.type,
                                onChange: value => {
                                    if (chooser?.type === ROW_CHOOSER_TYPES.CHECKBOX) {
                                        chooser.onChange && chooser.onChange(value)
                                    } else {
                                        chooser.onChange && chooser.onChange(value?.[0])
                                    }
                                },
                                columnWidth: 58,
                                selectedRowKeys: chooser?.type === ROW_CHOOSER_TYPES.CHECKBOX ? chooser.value : chooser.value ? [chooser.value] : []
                            }
                        )
                    }
                    summary={() => (Boolean(totals) && antdTableData?.length > 0) && (
                        <AntTable.Summary fixed>
                            <AntTable.Summary.Row>
                                {
                                    Boolean(expandable) && (
                                        <AntTable.Summary.Cell index={0}>
                                            {
                                                <span className='rt--font-bold'>{totals.title}</span>
                                            }
                                        </AntTable.Summary.Cell>
                                    )
                                }

                                {
                                    antdTableColumns.map((column, index) => (
                                        <AntTable.Summary.Cell
                                            key={column.dataIndex}
                                            index={column.dataIndex}
                                        >
                                            {
                                                totals?.data[column.dataIndex] || index === 0 ?
                                                <span className='rt--font-bold'>
                                                    {
                                                        index === 0 && !Boolean(expandable) ?
                                                            totals.title :
                                                            totals?.data[column.dataIndex]
                                                    }
                                                </span> : null
                                            }
                                        </AntTable.Summary.Cell>
                                    ))
                                }
                            </AntTable.Summary.Row>
                        </AntTable.Summary>
                    )}
                />
            </ConfigProvider>
        </div>
    );
}

/** TableDesktop propTypes
 * PropTypes
*/
TableDesktop.propTypes = TablePropTypes;

export default TableDesktop;