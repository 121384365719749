import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { isMobile } from "utils/common";

import Tooltip from "components/common/tooltip";

import Icon from "components/common/icon";

import useDate from "hooks/useDate";

const LastUpdated = ({ lastUpdatedDate, hasFilter }) => {
  const { t } = useTranslation();

  const { dateService } = useDate();

  if(!lastUpdatedDate){
    return null
  }

  return (
    <div className="rt--flex rt--align-center">
      {isMobile() ? (
        <Tooltip
          title={
            <div>
              <span className="rt--title rt--font-medium rt--chart-last-update">
                {`${t("backoffice.dashboard.lastUpdatedAt")} ${dateService.format(lastUpdatedDate, true, false)}`}
              </span>
            </div>
          }
          trigger={["click"]}
          placement="bottom"
          enableMobile={true}
        >   
          <Icon className={hasFilter ? 'rt--mr-48' : ''} name="info" />
        </Tooltip>
      ) : (
        <Fragment>
          <Icon name="time" className="rt--mr-6" />
          <span className="rt--title rt--font-big rt--chart-last-update rt--mr-16">
            {`${t("backoffice.dashboard.lastUpdatedAt")} ${dateService.format(lastUpdatedDate, true, false)}`}
          </span>
        </Fragment>
      )}
    </div>
  );
};

LastUpdated.propTypes = {
  lastUpdatedDate: PropTypes.string,
  hasFilter: PropTypes.bool,
};

export default LastUpdated;
