import React from "react";

import { Navigate } from "react-router";

import AffiliatesEdit from "pages/affiliates/affiliates/edit/affiliate-edit.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import { USER_ROLE } from "constants/user.constants";
import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

import { getUser } from "utils/auth";

const MyAccountRoute = () => {
    if(getUser()?.role !== USER_ROLE.AFFILIATE){
        return <Navigate to={Paths.AFFILIATES} />
    }

    return <AffiliatesEdit />;
};

export default withPermission(
    withAuth(MyAccountRoute),
    { resource: PERMISSION_RESOURCE.AFFILIATE, action: PERMISSION_ACTION.VIEW },
    Paths.PORTAL
);
