import React from "react";

import CommissionCalculationsComponent from "pages/reports/commissionCalculations/commissionCalculations.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const CommissionCalculationsRoute = () => {
    return <CommissionCalculationsComponent />;
};

export default withPermission(
    withAuth(CommissionCalculationsRoute),
    { resource: PERMISSION_RESOURCE.PAYMENTS_COMMISSION_CALCULATIONS, action: PERMISSION_ACTION.VIEW },
    Paths.PORTAL
);
