import PropTypes from 'prop-types';

import { PROJECT_BRAND_PRODUCT_TYPE } from 'constants/project.constants';

export default PropTypes.shape({
    id: PropTypes.string,
    subDomain: PropTypes.string,
    relative: PropTypes.string,
    languageCode: PropTypes.string,
    product: PropTypes.oneOf(Object.values(PROJECT_BRAND_PRODUCT_TYPE))
})