import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Spin, Alert, Divider, Row, Col } from "antd";

import TabFormDashboardLayout from "components/layouts/tab/form";

import ErrorsComponent from "./errors.component";

import { getMonitoringService } from 'store/actions/portal/developer/monitoring/monitoring.action';

import { toUpperCaseFirstLetter } from "utils/common";

import useDate from "hooks/useDate";

import monitoringType from "types/monitoring/monitoring.type";

/** Function to get default options for highchart
    * @function
    * @param { string } seriestext - series text
    * @param { string } titleText - title text
    * @param { array } data - chart data
*/
const getPieChartsDefaultOptions = (seriestext, titleText, data) => {
    const defOption = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        series: [{
            name: seriestext,
            colorByPoint: true,
            data,
            fillColor: "#F0F0F0"
        }],
        title: {
            text: titleText
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                size: 250,
                dataLabels: {
                    enabled: false
                }
            }
        }
    }
    if (data.some(elem => elem.y > 0)) {
        defOption.plotOptions.pie.dataLabels = {
            enabled: true,
            format: '<b>{point.name}</b>: {point.y}'
        }
    }
    return defOption
}

/** Service Page Component */
const ServiceComponent = ({
    monitoring,
    service,
    isLoading,
    getMonitoringService
}) => {

    const { t } = useTranslation();

    const { dateService } = useDate();

    const [barChartData, setBarChartData] = useState({});
    const [lastHourPieChartData, setLastHourPieChartData] = useState([]);
    const [lastDayPieChartData, setLastDayPieChartData] = useState([]);
    const [chartDataLoaded, setChartDataLoaded] = useState(false);

    /** Load monitoring data */
    useEffect(() => {
        getMonitoringService(monitoring.id)
    }, [])

    useEffect(() => {
        if (Object.keys(service).length) {
            handleBarChartData();
            handlePieChartData("last_hour");
            handlePieChartData("last_day");
            setTimeout(() => {
                setChartDataLoaded(true)
            }, 500)
        }
    }, [service])

    /** Function to make data for BarChart
       * @function
       * @memberOf ServiceComponent
   */
    const handleBarChartData = () => {

        const colors = ["#722ed1", "#1890ff", "#eb2f96", "#fa8c16", "#fadb14", "#2f54eb", "#faad14"];

        const result = {
            categories: [],
            series: []
        };

        
        const series = [];

        if (service.metrics) {
            const metrics = Object.keys(service.metrics);

            metrics.forEach(metric => {
                result.categories.push(dateService.format(metric, true, false, true, "DD.MM HH"));

                const metricData = Object.keys(service.metrics[metric]);
                metricData.forEach(m => {
                    if(!series.find(s => s.key === m)){
                        let col = null;
                        if (m.toLowerCase() === "success") {
                            col = "#90ed7d";
                        } else if (m.toLowerCase() === "failed") {
                            col = "#FF0000"
                        } else {
                            if (colors.length > 0) {
                                col = colors[0];
                                colors.shift()
                            }
                        }
    
                        if(!series.includes(m)){
                            series.push({
                                key: m,
                                name: toUpperCaseFirstLetter(m),
                                data: [],
                                color: col
                            });
                        }
                    }
                })
            })

            metrics.forEach(metric => {
                series.forEach(s => {
                    s.data.push(service.metrics[metric]?.[s.key] ?? 0)
                })
            })

        }
        result.series = series;
        setBarChartData(result);
    }


    /** Function to make data for PieChart
       * @function
       * @param {string } type - last_hour/last_day
       * @memberOf MonitoringPageComponent
   */
    const handlePieChartData = type => {
        const colors = ["#722ed1", "#1890ff", "#eb2f96", "#fa8c16", "#fadb14", "#2f54eb", "#faad14"];
        const result = [];
        if (service.metrics) {
            if (type === "last_hour") {
                const keys = Object.keys(service.metrics);
                if (keys.length) {
                    const lastHourMetricKey = keys[keys.length - 1];
                    const lastHourMetric = service.metrics[lastHourMetricKey];
                    Object.keys(lastHourMetric).forEach(metric => {
                        let col = null;
                        if (metric.toLowerCase() === "success") {
                            col = "#90ed7d";
                        } else if (metric.toLowerCase() === "failed") {
                            col = "#FF0000"
                        } else {
                            if (colors.length > 0) {
                                col = colors[0];
                                colors.shift()
                            }
                        }
                        result.push({
                            name: toUpperCaseFirstLetter(metric),
                            y: lastHourMetric[metric],
                            color: col
                        })
                    })
                }
            } else if (type === "last_day") {
                const keys = Object.keys(service.metrics);
                if (keys.length) {
                    const lastHourMetricKey = keys[keys.length - 1];
                    const lastHourMetric = service.metrics[lastHourMetricKey];
                    Object.keys(lastHourMetric).forEach(metric => {
                        let col = null;
                        if (metric.toLowerCase() === "success") {
                            col = "#90ed7d";
                        } else if (metric.toLowerCase() === "failed") {
                            col = "#FF0000"
                        } else {
                            if (colors.length > 0) {
                                col = colors[0];
                                colors.shift()
                            }
                        }
                        result.push({
                            name: toUpperCaseFirstLetter(metric),
                            y: Object.values(service.metrics).map(el => el[metric]).reduce((a, b) => a + b, 0),
                            color: col
                        })
                    })
                }
            }
        }
        if (type === "last_hour") {
            setLastHourPieChartData(result);
        } else if (type === "last_day") {
            setLastDayPieChartData(result);
        }
    }

    return (
        <TabFormDashboardLayout>
            <Spin spinning={isLoading && !chartDataLoaded}>
                <div>
                    {
                        service.name && service.totalFailed > 10 ?
                            <Alert
                                message={t('backoffice.monitoring.warning')}
                                description={`${(service?.name ?? "").replace("Service", " Service")} ${t('backoffice.monitoring.warningMsg').replace("%COUNT%", service.totalFailed)}`}
                                type="warning"
                                showIcon
                                style={{ marginBottom: "20px" }}
                            /> : null
                    }
                    {
                        chartDataLoaded && (
                            <Fragment>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{
                                        chart: {
                                            type: 'column'
                                        },
                                        scrollbar:{
                                            enabled:true
                                          },
                                        xAxis: {
                                            crosshair: true,
                                            categories: barChartData.categories,
                                            type: 'category',
                                            min: 0,
                                            tickLength: 0
                                        },
                                        yAxis: {
                                            min: 0,
                                        },
                                        series: barChartData.series,
                                        title: {
                                            text: (service?.name ?? "").replace("Service", " Service")
                                        },
                                    }}
                                />
                                <Divider />
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={12} >
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={getPieChartsDefaultOptions(t('backoffice.monitoring.count'), t('backoffice.monitoring.lastHour'), lastHourPieChartData)}
                                        />
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12} >
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={getPieChartsDefaultOptions(t('backoffice.monitoring.count'), t('backoffice.monitoring.last24Hour'), lastDayPieChartData)}
                                        />
                                    </Col>
                                </Row>
                            </Fragment>
                        )
                    }
                </div>

                <ErrorsComponent serviceName={service.name} />    
            </Spin>
        </TabFormDashboardLayout>
        
    );
};

/** ServiceComponent propTypes
 * PropTypes
*/
ServiceComponent.propTypes = {
    /** Current service */
    monitoring: monitoringType,
    /** Redux action to get data of monitoring service */
    getMonitoringService: PropTypes.func,
    /** Redux state property, data of current viewing monitoring service */
    service: monitoringType,
    /** Redux state property, is true when loading monitoring service data */
    isLoading: PropTypes.bool
};

const mapDispatchToProps = dispatch => ({
    getMonitoringService: id => {
        dispatch(getMonitoringService(id))
    },
});

const mapStateToProps = (state) => {
    return {
        service: state.monitoring.service,
        isLoading: state.monitoring.isLoading
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServiceComponent);
