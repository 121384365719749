//#region react
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
//#endregion

//#region actions
import {
    getAffiliateReport,
    setAffiliateReportSorting,
    setAffiliateReportFilters,
    getAffiliateReportTotals
} from 'store/actions/portal/reports/performance/affiliateReport.action';
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import ReportDetails from './details.component';
import TotalsComponent from './totals';
//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { isMobile } from 'utils/common';
import { getUser } from 'utils/auth';
//#endregion

//#region constants 
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import ApiUrls from 'constants/api.constants';
import { EXPAND_TABLE_TYPES } from 'constants/common.constants';
import { AFFILIATE_REPORT } from 'constants/pageName.constants';
import { USER_ROLE } from 'constants/user.constants';
import { getTableColumns, getExpandTableColumns } from './columns';
//#endregion

//#region hooks
import useFormat from 'hooks/useFormat';
import useIncludedColumns from 'hooks/useIncludedColumns';
import useCurrencyConversion from 'hooks/useCurrencyConvertion';
import useAccess from 'hooks/useAccess';
import useDate from 'hooks/useDate';
//#endRegion

//#region types
import affiliateReportType from 'types/reports/affiliateReport.type';
import reportTotalsType from 'types/reports/totals.type';
import sortingType from 'types/common/sorting.type';
//#endregion

const EXPAND_TABLE_ROW_UNIQUE_KEY = "currencyCode"

/** Affiliate Report Component */
const AffiliateReportComponent = ({
    report,
    sorting,
    filters,
    total,
    isLoading,
    getAffiliateReport,
    setAffiliateReportSorting,
    setAffiliateReportFilters,
    getAffiliateReportTotals,
    totals,
    globalProjectId,
    currencyCode
}) => {
    const { t } = useTranslation();

    const { role } = getUser();

    const isAffiliate = role === USER_ROLE.AFFILIATE;

    const { formatAmount, formatNumber, formatCurrencyWithSymbol } = useFormat();
    const { convertCurrency } = useCurrencyConversion();

    const { hasAccess } = useAccess();

    const { dateService } = useDate();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: AFFILIATE_REPORT });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.REPORT_AFFILIATE_PERFORMANCE,
        action: PERMISSION_ACTION.EXPORT
    })

    const hasTotalsViewPermission = hasPermission({
        resource: PERMISSION_RESOURCE.REPORT_AFFILIATE_PERFORMANCE_TOTALS,
        action: PERMISSION_ACTION.VIEW
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setAffiliateReportFilters({
            ...filters,
            affiliateUserNameOrId: value
        })
    }

    const handleDateSearchChange = value => {
        setAffiliateReportFilters({
            ...filters,
            from: value[0],
            to: value[1]
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        expandTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            expandColumns: getExpandTableColumns,
            includedColumns,
            constructForExport: true,
            constructForInclude: true,
            includeExpandColumnsInExport: true,
            additionalProps: {
                formatAmount,
                formatNumber,
                formatCurrencyWithSymbol,
                currencyCode,
                hasAccess
            }
        })
    }, [includedColumns, currencyCode, hasAccess]);

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        search: !isAffiliate ? {
            onSearch: handleSearchChange,
            loadFn: getAffiliateReport,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.AFFILIATES,
                dependencies: [globalProjectId]
            },
            placeholder: t("backoffice.reports.affiliateUserNameOrId"),
        } : undefined,
        date: {
            onSearch: handleDateSearchChange,
            placeholder: t('backoffice.common.date'),
            showTime: true,
            allowClear: false,
            enabledMountsCount: 12,
            loadFn: getAffiliateReport,
            value: [filters.from, filters.to] 
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.reports.perAffiliate"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_AFFILIATE_PERFORMANCE_REPORT,
            filters: filters
        }
    }

    //#endregion

    /** Count all totals and convert currencies */
    const reportData = useMemo(() => {
        const result = report.map(item => {
            const obj = { ...item };
            if (item.details) {
                item.details.forEach(cur => {
                    obj.casinoBetCount = (obj.casinoBetCount ?? 0) + cur.casinoBetCount;
                    obj.depositCount = (obj.depositCount ?? 0) + cur.depositCount;
                    obj.ftdCount = (obj.ftdCount ?? 0) + cur.ftdCount;
                    obj.cpaCount = (obj.cpaCount ?? 0) + cur.cpaCount;
                    obj.sportBetCount = (obj.sportBetCount ?? 0) + cur.sportBetCount;
                    obj.withdrawCount = (obj.withdrawCount ?? 0) + cur.withdrawCount;

                    obj.adjustmentsAmount = (obj.adjustmentsAmount ?? 0) + convertCurrency(cur.adjustmentsAmount, cur.currencyCode);
                    obj.depositFeeAmount = (obj.depositFeeAmount ?? 0) + convertCurrency(cur.depositFeeAmount, cur.currencyCode);
                    obj.sportRevShareAmount = (obj.sportRevShareAmount ?? 0) + convertCurrency(cur.sportRevShareAmount, cur.currencyCode);
                    obj.casinoRevShareAmount = (obj.casinoRevShareAmount ?? 0) + convertCurrency(cur.casinoRevShareAmount, cur.currencyCode);
                    obj.totalRevShareAmount = (obj.totalRevShareAmount ?? 0) + convertCurrency(cur.totalRevShareAmount, cur.currencyCode);
                    obj.casinoBetAmount = (obj.casinoBetAmount ?? 0) + convertCurrency(cur.casinoBetAmount, cur.currencyCode);
                    obj.casinoBonusWonAmount = (obj.casinoBonusWonAmount ?? 0) + convertCurrency(cur.casinoBonusWonAmount, cur.currencyCode);
                    obj.casinoFeeAmount = (obj.casinoFeeAmount ?? 0) + convertCurrency(cur.casinoFeeAmount, cur.currencyCode);
                    obj.casinoGGR = (obj.casinoGGR ?? 0) + convertCurrency(cur.casinoGGR, cur.currencyCode);
                    obj.casinoNGR = (obj.casinoNGR ?? 0) + convertCurrency(cur.casinoNGR, cur.currencyCode);
                    obj.casinoWonAmount = (obj.casinoWonAmount ?? 0) + convertCurrency(cur.casinoWonAmount, cur.currencyCode);
                    obj.cpaAmount = (obj.cpaAmount ?? 0) + convertCurrency(cur.cpaAmount, cur.currencyCode);
                    obj.fixedCommissionAmount = (obj.fixedCommissionAmount ?? 0) + convertCurrency(cur.fixedCommissionAmount, cur.currencyCode);
                    obj.totalCommissionAmount = (obj.totalCommissionAmount ?? 0) + convertCurrency(cur.totalCommissionAmount, cur.currencyCode);
                    obj.depositAmount = (obj.depositAmount ?? 0) + convertCurrency(cur.depositAmount, cur.currencyCode);
                    obj.ftdAmount = (obj.ftdAmount ?? 0) + convertCurrency(cur.ftdAmount, cur.currencyCode);
                    obj.netDepositAmount = (obj.netDepositAmount ?? 0) + convertCurrency(cur.netDepositAmount, cur.currencyCode);
                    obj.ngr = (obj.ngr ?? 0) + convertCurrency(cur.ngr, cur.currencyCode);
                    obj.sportBetAmount = (obj.sportBetAmount ?? 0) + convertCurrency(cur.sportBetAmount, cur.currencyCode);
                    obj.sportBonusWonAmount = (obj.sportBonusWonAmount ?? 0) + convertCurrency(cur.sportBonusWonAmount, cur.currencyCode);
                    obj.sportFeeAmount = (obj.sportFeeAmount ?? 0) + convertCurrency(cur.sportFeeAmount, cur.currencyCode);
                    obj.sportGGR = (obj.sportGGR ?? 0) + convertCurrency(cur.sportGGR, cur.currencyCode);
                    obj.sportNGR = (obj.sportNGR ?? 0) + convertCurrency(cur.sportNGR, cur.currencyCode);
                    obj.sportWonAmount = (obj.sportWonAmount ?? 0) + convertCurrency(cur.sportWonAmount, cur.currencyCode);
                    obj.withdrawAmount = (obj.withdrawAmount ?? 0) + convertCurrency(cur.withdrawAmount, cur.currencyCode);
                })
            }
            return obj;
        })
        return result;
    }, [report, convertCurrency]);

    const disabledExpandRowsIds = report.filter(r => {
        const currenciesCount = r.details?.length ?? 0;
        return currenciesCount === 0 || (
            currenciesCount === 1 && r.details[0].currencyCode?.toLowerCase() === currencyCode.toLowerCase
        )
    }).map(r => r.id);


    /** Reset Filters on Component unmount */
    useEffect(() => {
        return () => {
            setAffiliateReportFilters({
                affiliateUserNameOrId: "",
				from: dateService.toISOString(dateService.yesterday(true)),
				to: dateService.toISOString(dateService.startOfTomorrow()),
            })
        }
    }, [])


    /** Load Totals */
    useEffect(() => {
        if (hasTotalsViewPermission) {
            getAffiliateReportTotals();
        }
    }, [globalProjectId, filters])

    /** Table Totals */
    const reportTotalsData = useMemo(() => {
        const curs = Object.keys(totals);
        const result = {};

        if (curs.length > 0) {
            curs.forEach(cur => {
                const item = totals[cur];

                result.playerRegistrationCount = (result.playerRegistrationCount ?? 0) + item.playerRegistrationCount;
                result.casinoBetCount = (result.casinoBetCount ?? 0) + item.casinoBetCount;
                result.depositCount = (result.depositCount ?? 0) + item.depositCount;
                result.sportBetCount = (result.sportBetCount ?? 0) + item.sportBetCount;
                result.withdrawCount = (result.withdrawCount ?? 0) + item.withdrawCount;
                result.ftdCount = (result.ftdCount ?? 0) + item.ftdCount;
                result.cpaCount = (result.cpaCount ?? 0) + item.cpaCount;

                result.adjustmentsAmount = (result.adjustmentsAmount ?? 0) + convertCurrency(item.adjustmentsAmount, cur);
                result.depositFeeAmount = (result.depositFeeAmount ?? 0) + convertCurrency(item.depositFeeAmount, cur);
                result.casinoBetAmount = (result.casinoBetAmount ?? 0) + convertCurrency(item.casinoBetAmount, cur);
                result.casinoBonusWonAmount = (result.casinoBonusWonAmount ?? 0) + convertCurrency(item.casinoBonusWonAmount, cur);
                result.casinoGGR = (result.casinoGGR ?? 0) + convertCurrency(item.casinoGGR, cur);
                result.casinoNGR = (result.casinoNGR ?? 0) + convertCurrency(item.casinoNGR, cur);
                result.casinoWonAmount = (result.casinoWonAmount ?? 0) + convertCurrency(item.casinoWonAmount, cur);
                result.depositAmount = (result.depositAmount ?? 0) + convertCurrency(item.depositAmount, cur);
                result.ftdAmount = (result.ftdAmount ?? 0) + convertCurrency(item.ftdAmount, cur);
                result.netDepositAmount = (result.netDepositAmount ?? 0) + convertCurrency(item.netDepositAmount, cur);
                result.ngr = (result.ngr ?? 0) + convertCurrency(item.ngr, cur);
                result.sportBetAmount = (result.sportBetAmount ?? 0) + convertCurrency(item.sportBetAmount, cur);
                result.sportBonusWonAmount = (result.sportBonusWonAmount ?? 0) + convertCurrency(item.sportBonusWonAmount, cur);
                result.sportGGR = (result.sportGGR ?? 0) + convertCurrency(item.sportGGR, cur);
                result.sportNGR = (result.sportNGR ?? 0) + convertCurrency(item.sportNGR, cur);
                result.sportWonAmount = (result.sportWonAmount ?? 0) + convertCurrency(item.sportWonAmount, cur);
                result.withdrawAmount = (result.withdrawAmount ?? 0) + convertCurrency(item.withdrawAmount, cur);
                result.sportFeeAmount = (result.sportFeeAmount ?? 0) + convertCurrency(item.sportFeeAmount, cur);
                result.casinoFeeAmount = (result.casinoFeeAmount ?? 0) + convertCurrency(item.casinoFeeAmount, cur);
                result.sportRevShareAmount = (result.sportRevShareAmount ?? 0) + convertCurrency(item.sportRevShareAmount, cur);
                result.casinoRevShareAmount = (result.casinoRevShareAmount ?? 0) + convertCurrency(item.casinoRevShareAmount, cur);
                result.totalRevShareAmount = (result.totalRevShareAmount ?? 0) + convertCurrency(item.totalRevShareAmount, cur);
                result.cpaAmount = (result.cpaAmount ?? 0) + convertCurrency(item.cpaAmount, cur);
                result.fixedCommissionAmount = (result.fixedCommissionAmount ?? 0) + convertCurrency(item.fixedCommissionAmount, cur);
                result.totalCommissionAmount = (result.totalCommissionAmount ?? 0) + convertCurrency(item.totalCommissionAmount, cur);
            })

            result.adjustmentsAmount = formatAmount(result.adjustmentsAmount, currencyCode);
            result.depositFeeAmount = formatAmount(result.depositFeeAmount, currencyCode);
            result.casinoBetAmount = formatAmount(result.casinoBetAmount, currencyCode);
            result.casinoBonusWonAmount = formatAmount(result.casinoBonusWonAmount, currencyCode);
            result.casinoGGR = formatAmount(result.casinoGGR, currencyCode);
            result.casinoNGR = <span className={result.casinoNGR < 0 ? "rt--error-text" : ""}>{formatAmount(result.casinoNGR, currencyCode)}</span>;
            result.casinoWonAmount = formatAmount(result.casinoWonAmount, currencyCode);
            result.depositAmount = formatAmount(result.depositAmount, currencyCode);
            result.ftdAmount = formatAmount(result.ftdAmount, currencyCode);
            result.netDepositAmount = <span className={result.netDepositAmount < 0 ? "rt--error-text" : ""}>{formatAmount(result.netDepositAmount, currencyCode)}</span>;
            result.ngr = <span className={result.ngr < 0 ? "rt--error-text" : ""}>{formatAmount(result.ngr, currencyCode)}</span>;
            result.sportBetAmount = formatAmount(result.sportBetAmount, currencyCode);
            result.sportBonusWonAmount = formatAmount(result.sportBonusWonAmount, currencyCode);
            result.sportGGR = formatAmount(result.sportGGR, currencyCode);
            result.sportNGR = <span className={result.sportNGR < 0 ? "rt--error-text" : ""}>{formatAmount(result.sportNGR, currencyCode)}</span>;
            result.sportWonAmount = formatAmount(result.sportWonAmount, currencyCode);
            result.withdrawAmount = formatAmount(result.withdrawAmount, currencyCode);
            result.sportFeeAmount = formatAmount(result.sportFeeAmount, currencyCode);
            result.casinoFeeAmount = formatAmount(result.casinoFeeAmount, currencyCode);
            result.sportRevShareAmount = formatAmount(result.sportRevShareAmount, currencyCode);
            result.casinoRevShareAmount = formatAmount(result.casinoRevShareAmount, currencyCode);
            result.totalRevShareAmount = formatAmount(result.totalRevShareAmount, currencyCode);
            result.cpaAmount = formatAmount(result.cpaAmount, currencyCode);
            result.fixedCommissionAmount = formatAmount(result.fixedCommissionAmount, currencyCode);
            result.totalCommissionAmount = formatAmount(result.totalCommissionAmount, currencyCode);

            result.playerRegistrationCount = formatNumber(result.playerRegistrationCount);
            result.casinoBetCount = formatNumber(result.casinoBetCount);
            result.depositCount = formatNumber(result.depositCount);
            result.ftdCount = formatNumber(result.ftdCount);
            result.cpaCount = formatNumber(result.cpaCount);
            result.sportBetCount = formatNumber(result.sportBetCount);
            result.withdrawCount = formatNumber(result.withdrawCount);

        }
        return result;
    }, [totals, convertCurrency])


    return (
        <TabTableDashboardLayout
            header={headerPartsData}
            isContentScrollable={isMobile() && hasTotalsViewPermission}
            extraContent={isMobile() && hasTotalsViewPermission ? (
                <TotalsComponent
                    totals={reportTotalsData}
                    currencyCode={currencyCode}
                />
            ) : undefined}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={reportData}
                loadFn={getAffiliateReport}
                sorting={sorting}
                setSortingFn={setAffiliateReportSorting}
                filters={filters}
                setFiltersFn={setAffiliateReportFilters}
                total={total}
                updateProps={[globalProjectId]}
                //enableReload={true}
                expandable={{
                    title: t("backoffice.reports.additional"),
                    disabled: disabledExpandRowsIds,
                    type: isMobile() ? EXPAND_TABLE_TYPES.TABLE : EXPAND_TABLE_TYPES.CONTENT,
                    details: rowData => ({
                        columns: expandTableColumns,
                        data: rowData?.details ?? [],
                        uniqueKey: EXPAND_TABLE_ROW_UNIQUE_KEY,
                        content: !isMobile() ? <ReportDetails
                            data={rowData?.details ?? []}
                        /> : null
                    })
                }}
                totals={hasTotalsViewPermission ? {
                    data: reportTotalsData,
                    title: t("backoffice.common.total")
                } : undefined}
            />

        </TabTableDashboardLayout>
    )
}

/** AffiliateReportComponent propTypes
    * PropTypes
*/
AffiliateReportComponent.propTypes = {
    /** Redux state property, represents the array of affiliate report */
    report: PropTypes.arrayOf(affiliateReportType),
    /** Redux state property, affiliate report sorting details */
    sorting: sortingType,
    /** Redux state property, affiliate report filters */
    filters: PropTypes.object,
    /** Redux state property, affiliate report total count */
    total: PropTypes.number,
    /** Redux state property, is true when loading affiliate report */
    isLoading: PropTypes.bool,
    /** Redux action to get affiliate report */
    getAffiliateReport: PropTypes.func,
    /** Redux action to set affiliate report sorting details */
    setAffiliateReportSorting: PropTypes.func,
    /** Redux action to set affiliate report filters */
    setAffiliateReportFilters: PropTypes.func,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, affiliate report totals */
    totals: reportTotalsType,
    /** Redux action to get affiliate report totals */
    getAffiliateReportTotals: PropTypes.func,
    /** Redux state property, User currency code */
    currencyCode: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getAffiliateReport: nextPage => {
            dispatch(getAffiliateReport(nextPage))
        },
        setAffiliateReportSorting: sorting => {
            dispatch(setAffiliateReportSorting(sorting));
        },
        setAffiliateReportFilters: (filters, keepPage) => {
            dispatch(setAffiliateReportFilters(filters, keepPage));
        },
        getAffiliateReportTotals: () => {
            dispatch(getAffiliateReportTotals());
        }
    }
)

const mapStateToProps = state => {
    return {
        report: state.performanceReports.affiliateReport.report,
        total: state.performanceReports.affiliateReport.total,
        sorting: state.performanceReports.affiliateReport.sorting,
        filters: state.performanceReports.affiliateReport.filters,
        totals: state.performanceReports.affiliateReport.totals,
        isLoading: state.performanceReports.isLoading,
        globalProjectId: state.common.globalProjectId,
        currencyCode: state.profile.currency
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateReportComponent)