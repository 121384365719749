import React from "react";

import i18n from 'translations/config';

import Status from "components/common/status";
import Tooltip from "components/common/tooltip";
import Icon from "components/common/icon";

import { STATUS_TYPES } from "constants/status.constants";
import { DB_CONNECTION_STATE } from "constants/common.constants";

export const getTableColumns = additionalProps => {
    const {
        handleTerminate
    } = additionalProps

    return [
        {
            title: "backoffice.common.username",
            dataIndex: "userName",
            mobileLevel: 1,
        },
        {
            title: "backoffice.common.state",
            dataIndex: "state",
            mobileLevel: 2,
            render: value => <Status
                type={STATUS_TYPES.DBCONNECTION}
                status={value}
            />
        },
        {
            title: "backoffice.common.createdAt",
            dataIndex: "createdTime",
            mobileLevel: 3
        },

        {
            title: "backoffice.common.lastUsageAt",
            dataIndex: "lastUsageTime",
            mobileLevel: 4
        },

        {
            title: "",
            dataIndex: "query",
            mobileLevel: 5,
            render: (value, record) => (
                <div className="rt--flex rt--align-center rt--justify-end">
                    {
                        (
                            record.createdAt > 3600 &&
                            record.state === DB_CONNECTION_STATE.IDLE
                        ) && (
                            <Tooltip
                                placement="top"
                                enableMobile={true}
                                title={i18n.t("backoffice.common.terminate")}
                            >
                                <div
                                    className="rt--flex rt--align-center rt--mr-8 rt--cursor-pointer"
                                    onClick={() => handleTerminate(record.id)}
                                >
                                    <Icon name="close" className="rt--error-text"/>
                                </div>
                            </Tooltip>
                        )
                    }
                    <Tooltip
                        placement="top"
                        enableMobile={true}
                        title={value}
                    >
                        <div className="rt--flex rt--align-center rt--justify-center">
                            <Icon name="code" />
                        </div>
                    </Tooltip>
                    
                </div>
            )
        },
    ]
}