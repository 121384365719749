export const getTableColumns = additionalProps => {

    const {
        formatAmount
    } = additionalProps;

    return [
        {
            title: "backoffice.invoices.invoiceId",
            dataIndex: "id",
            copy: true,
            alwaysVisible: true,
            mobileLevel: 1,
        },
        {
            title: "backoffice.invoices.affiliateId",
            dataIndex: "affiliateId",
            copy: true
        },
        {
            title: "backoffice.invoices.username",
            dataIndex: "affiliateUserName",
            sorting: true,
            alwaysVisible: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.invoices.amount",
            dataIndex: "amount",
            sorting: true,
            isNumeric: true,
            mobileLevel: 3,
        },
        {
            title: "backoffice.invoices.currency",
            dataIndex: "currencyCode",
            sorting: true,
            mobileLevel: 4
        },
        {
            title: "backoffice.invoices.paymentMethod",
            dataIndex: "paymentName",
            sorting: true,
            mobileLevel: 5
        },
        {
            title: "backoffice.invoices.commissionAmount",
            dataIndex: "paymentCommissionAmount",
            mobileLevel: 6,
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
        {
            title:"backoffice.invoices.createdDate",
            dataIndex: "createdAt",
            isDateTime: true,
            sorter: true
        },
        {
            title:"backoffice.invoices.createdBy",
            dataIndex: "createdBy",
            sorter: true
        }
    ]
}