import React from 'react';

import { useTranslation } from 'react-i18next';

import Tabs from 'components/common/tabs';

import GeneralInfoComponent from '../edit/sections/general';
import DomainsComponent from '../edit/sections/domains';
import BrandsComponent from './sections/brands';
import Promo from './sections/promo';
import CurrenciesComponent from '../edit/sections/currencies';
import LanguagesComponent from '../edit/sections/languages';
import CommissionsComponent from '../../commissionPlans';
import ReferralSettingsComponent from '../edit/sections/refferalSettings';
import RegistrationFormComponent from '../edit/sections/registrationForm';
import Financial from '../edit/sections/financial';
// import IntegrationComponent from '../edit/sections/integration';

import Paths from 'constants/path.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

/** Project Edit Page Component */
const ProjectEditComponent = () => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.projects.general"),
            permissions: [
                { resource: PERMISSION_RESOURCE.PROJECT_GENERALINFO, action: PERMISSION_ACTION.VIEW },
                { resource: PERMISSION_RESOURCE.PROJECT_INTEGRATION, action: PERMISSION_ACTION.VIEW }
            ],
            component: <GeneralInfoComponent />
        },
        {
            title: t("backoffice.projects.domains"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_DOMAINS, action: PERMISSION_ACTION.VIEW }],
            component: <DomainsComponent />
        },
        {
            title: t("backoffice.projects.brands"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_BRANDS, action: PERMISSION_ACTION.VIEW }],
            component: <BrandsComponent />,
            pathParams: { name: "brandName", id: "brandId" }
        },
        {
            title: t("backoffice.projects.currencies"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_CURRENCIES, action: PERMISSION_ACTION.VIEW }],
            component: <CurrenciesComponent />
        },
        {
            title: t("backoffice.projects.financial"),
            permissions: [
                { resource: PERMISSION_RESOURCE.PROJECT_PAYOUT_SETTINGS, action: PERMISSION_ACTION.VIEW },
                { resource: PERMISSION_RESOURCE.PROJECT_PAYMENT_METHODS, action: PERMISSION_ACTION.VIEW }
            ],
            component: <Financial />,
            pathParams: { name: "paymentName", id: "paymentId" }
        },
        {
            title: t("backoffice.projects.commissions"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, action: PERMISSION_ACTION.VIEW }],
            component: <CommissionsComponent />,
            pathParams: { name: "commissionName", id: "commissionId" }
        },
        {
            title: t("backoffice.projects.referralProgram"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_REFERRAL_SETTINGS, action: PERMISSION_ACTION.VIEW }],
            component: <ReferralSettingsComponent />
        },
        {
            title: t("backoffice.projects.registrationForm"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_REGISTRATION_CONFIGURATION, action: PERMISSION_ACTION.VIEW }],
            component: <RegistrationFormComponent />
        },
        {
            title: t("backoffice.projects.promo"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_PROMO, action: PERMISSION_ACTION.VIEW }],
            component: <Promo />
        },
        {
            title: t("backoffice.projects.languages"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_LANGUAGE_SETTINGS, action: PERMISSION_ACTION.VIEW }],
            component: <LanguagesComponent />
        },
        // {
        //     title: t("backoffice.projects.integration"),
        //     permissions: [ { resource: PERMISSION_RESOURCE.PROJECT_INTEGRATION, action: PERMISSION_ACTION.VIEW } ],
        //     component: <IntegrationComponent />
        // },
    ]

    return (
        <Tabs
            items={items}
            mainPage={
                {
                    title: t('backoffice.menu.projects'),
                    path: Paths.PROJECTS
                }
            }
        />
    )
}

export default ProjectEditComponent;