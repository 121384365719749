//#region react
import React, { useEffect, useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
//#endregion

//#region actions
import {
    getJobs,
    updateJob,
    saveJobSettings,
    unlockJob
} from "store/actions/portal/developer/jobs/jobs.action";
//#endregion

//#region components
import MainDashboardLayout from "components/layouts/main";
import Table from "components/common/table";
import JobEditComponent from "./job-edit.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import SignalRUtils from 'utils/signalR';
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import { JOBS } from "constants/pageName.constants";
import { JOB_STATE } from 'constants/job.constants';
import { EXPAND_TABLE_TYPES } from "constants/common.constants";
import { tableColumns, expandColumns } from "./columns";
//#endregion

//#region types
import jobType from "types/job/job.type";
//#endregion

const ROW_UNIQUE_KEY_PROP = "name";
const EXPANDED_ROW_UNIQUE_KEY_PROP = "startTime";

/** Jobs Page Component */
const JobsComponent = ({
    getJobs,
    jobs,
    isLoading,
    updateJob,
    saveJobSettings,
    unlockJob
}) => {
    const { t } = useTranslation();

    const [jobEditPopupData, setJobEditPopupData] = useState({ isVisible: false });

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: JOBS });

    const groupsRef = useRef([]);

    if (groupsRef.current.length === 0) {
        groupsRef.current = [
            ...(new Set(jobs.map(job => job.groupName)))
        ]
    }

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleTableRowSwitcherChange = (checked, record) => {
        saveJobSettings({
            enabled: checked,
            cronExpression: record.cronExpression,
            allowedServerNames: record.allowedServerNames,
            id: record.id
        })
    }

    const showJobEditPopup = (record) => {
        setJobEditPopupData({
            isVisible: true,
            id: record.id,
        })
    }

    const hideJobEditPopup = () => {
        setJobEditPopupData({
            isVisible: false,
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        expandTableColumns,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: tableColumns,
            expandColumns,
            constructForInclude: true,
            includedColumns,
        })
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [
        {
            title: t('backoffice.common.edit'),
            icon: "edit",
            onClick: showJobEditPopup,
        },
        {
            title: t('backoffice.jobs.unlock'),
            icon: "lock",
            onClick: record => unlockJob(record.id),
            disabled: record => record.state !== JOB_STATE.RUNNING
        }
    ];

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.jobs') }]
        }
    }

    //#endregion

    // Load all jobs
    useEffect(() => {
        getJobs();
    }, [])

    // Subscribe to singalR events
    useEffect(() => {
        SignalRUtils.getConnections().forEach((connection, index) => {
            connection.addHandler(() => {
                connection.getConnection().invoke("Subscribe", "Jobs");
                connection.getConnection().off('Jobs');
                connection.getConnection().on('Jobs', data => {
                    const job = JSON.parse(data);

                    updateJob({
                        startTime: job.startTime,
                        nextFireTime: job.nextFireTime,
                        elapsedMilliseconds: job.elapsedMilliseconds,
                        serverName: job.serverName,
                        state: job.state,
                        message: job.message,
                        errorId: job.errorId,
                        name: job.name,
                        enabled: job.enabled,
                        id: job.id,
                        executionHistory: job.executionHistory
                    })
                });
            })
        })

        return () => {
            SignalRUtils.getConnections().forEach(connection => {
                connection.getConnection().off('Jobs');
            })
        }
    }, [])

    return (
        <MainDashboardLayout header={headerPartsData}>
            {groupsRef.current.map((group, index) => (
                <div
                    key={group}
                    className={index !== groupsRef.current.length - 1 ? "rt--mb-24" : ""}
                >
                    <h4 className='rt--title rt--font-bold rt--font-big rt--mb-16'>
                        {group}
                    </h4>

                    <Table
                        loading={isLoading}
                        columns={mainTableColumns}
                        data={jobs.filter(job => job.groupName === group).map(j => ({ ...j, key: j.id }))}
                        total={jobs.length}
                        noPagination={true}
                        disableFullView={true}
                        uniqueKey={ROW_UNIQUE_KEY_PROP}
                        actions={tableRowActions}
                        switcher={{
                            valueIndex: "enabled",
                            dataIndex: "enabled",
                            onChange: handleTableRowSwitcherChange,
                        }}
                        expandable={{
                            type: EXPAND_TABLE_TYPES.TABLE,
                            details: record => Boolean(record.executionHistory) && ({
                                columns: expandTableColumns,
                                uniqueKey: EXPANDED_ROW_UNIQUE_KEY_PROP,
                                data: record.executionHistory.map(
                                    (historyData, i) => ({
                                        ...historyData,
                                        index: i + 1
                                    })
                                ),
                            }),
                        }}
                    />
                </div>
            ))}

            {jobEditPopupData.isVisible && (
                <JobEditComponent id={jobEditPopupData.id} onClose={hideJobEditPopup} />
            )}
        </MainDashboardLayout>
    )
};

/** JobsComponent propTypes
 * PropTypes
*/
JobsComponent.propTypes = {
    /** Redux action to get jobs */
    getJobs: PropTypes.func,
    /** Redux state property, represents the array of jobs  */
    jobs: PropTypes.arrayOf(jobType),
    /** Redux state property, is true when loading jobs */
    isLoading: PropTypes.bool,
    /** Redux action to save job settings */
    saveJobSettings: PropTypes.func,
    /** Redux action to update job */
    updateJob: PropTypes.func,
    /** Redux action to unlock job */
    unlockJob: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    getJobs: () => {
        dispatch(getJobs())
    },
    updateJob: job => {
        dispatch(updateJob(job))
    },
    unlockJob: id => {
        dispatch(unlockJob(id))
    },
    saveJobSettings: settings => {
        dispatch(saveJobSettings(settings));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.jobs.isLoading,
        jobs: state.jobs.jobs
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobsComponent);
