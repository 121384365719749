//#region React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from "react-router-dom";
//#endregion

import { Form, Col, Row } from 'antd';

//#region Components
import Modal from "components/common/modal";
import NumericInput from "components/common/numericInput";
//#endregion

//#region Actions
import { changeProjectPaymentMethodDetails } from "store/actions/portal/projects/paymentMethods.action";
//#endregion

//#region Utils
import { isFormChanged } from 'utils/form';
import { numberTransform } from 'utils/common';
//#endregion

//#region Constants
import { POPUP_SIZE } from "constants/common.constants";
import { AMOUNT_REGEX } from 'constants/regex.constants';
//#endregion

//#region Types
import paymentCurrencyType from "types/project/paymentMethod.type";
//#endregion

const FORM_FIELDS_NAMES = {
    MIN_THRESHOLD: "minThreshold",
    COMISSION: "commission"
}

/** Add Currency to the Project Payment Popup Component */
const EditCurrency = ({
    currency,
    isSaving,
    changeProjectPaymentMethodDetails,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;
    const [searchParams] = useSearchParams();

    const [isFormTouched, setIsFormTouched] = useState(false);

    const handleForm = () => {
        validateFields()
            .then((data) => {
                changeProjectPaymentMethodDetails(
                    { ...data, id: searchParams.get("paymentId"), currencyCode: currency.currencyCode },
                    currency => onClose()
                );
            }).catch(() => { })
    };

    return (
        <Modal
            title={`${t('backoffice.common.edit')} ${currency.currencyCode}`}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.save')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.SMALL}
            disableOkButton={!isFormTouched}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    [FORM_FIELDS_NAMES.MIN_THRESHOLD]: currency.minThreshold,
                    [FORM_FIELDS_NAMES.COMISSION]: currency.commission
                }}
                onValuesChange={(_, values) => setIsFormTouched(
                    isFormChanged(
                        {
                            minThreshold: Number(currency.minThreshold),
                            commission: Number(currency.commission)
                        }, {
                        minThreshold: Number(values.minThreshold),
                        commission: Number(values.commission)
                    }
                    )
                )}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.projects.minThreshold')} *`}
                            name={FORM_FIELDS_NAMES.MIN_THRESHOLD}
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') },
                                { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                {
                                    validator: (_, value) => {
                                        if (Number(value) > 999999999) {
                                            return Promise.reject(t('backoffice.validation.mustBeBetween').replace("%X%", 0).replace("%Y%", 999999999))
                                        }

                                        if (Number(value) < 0) {
                                            return Promise.reject(t('backoffice.validation.mustBeBetween').replace("%X%", 0).replace("%Y%", 999999999))
                                        }

                                        return Promise.resolve()
                                    }
                                }
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.projects.minThreshold')}`}
                            validateFirst
                        >
                            <NumericInput maxLength={9} placeholder={`${t('backoffice.projects.minThreshold')}`} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.projects.commission')} *`}
                            name={FORM_FIELDS_NAMES.COMISSION}
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') },
                                { type: "number", min: 0, message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },
                                {
                                    validator: (_, value) => {
                                        if (Number(value) > 100) {
                                            return Promise.reject(t('backoffice.validation.mustBeBetween').replace("%X%", 0).replace("%Y%", 100))
                                        }

                                        if (Number(value) < 0) {
                                            return Promise.reject(t('backoffice.validation.mustBeBetween').replace("%X%", 0).replace("%Y%", 100))
                                        }

                                        return Promise.resolve()
                                    }
                                }
                            ]}
                            className='rt--general-form-item rt--form-item-without-margin'
                            data-placeholder={`${t('backoffice.projects.commission')}`}
                            validateFirst
                        >
                            <NumericInput maxLength={9} placeholder={`${t('backoffice.projects.commission')}`} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

EditCurrency.propTypes = {
    /** React Prop, represents current editing Currency data */
    currency: paymentCurrencyType,
    /** Redux state property, is true when adding Currency to the Project Payment is in process */
    isSaving: PropTypes.bool,
    /** Redux action to add Currency to the Project Payment */
    changeProjectPaymentMethodDetails: PropTypes.func,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        changeProjectPaymentMethodDetails: (params, onSuccess) => {
            dispatch(changeProjectPaymentMethodDetails(params, onSuccess));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.projects.isSaving,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCurrency);