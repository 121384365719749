import React from 'react';
import i18n from 'translations/config';

import Status from 'components/common/status';
import LockInfo from 'components/common/lockInfo';
import Icon from 'components/common/icon';

import { binaryToFlags } from 'utils/common';

import { STATUS_TYPES } from 'constants/status.constants';
import { USER_STATE } from 'constants/user.constants';
import { TARGET_MARKET, TARGET_MARKETS_TT_KEYS } from "constants/affiliate.constants";

export const tableColumns = [
    {
        title: "backoffice.affiliates.id",
        dataIndex: "longId",
        mobileLevel: 1,
        alwaysVisible: true,
    },
    {
        title: "backoffice.affiliates.userName",
        dataIndex: "userName",
        mobileLevel: 2,
        alwaysVisible: true,
    },
    {
        title: "backoffice.affiliates.status",
        dataIndex: "state",
        mobileLevel: 3,
        render: (value, record) => (
            <Status
                status={value}
                type={STATUS_TYPES.AFFILIATE}
                icon={[USER_STATE.LOCKED_LOGIN, USER_STATE.LOCKED_2_FA].includes(value) ? <Icon name="info" size={16} className="rt--ml-4" /> : null}
                tooltip={
                    [USER_STATE.LOCKED_2_FA, USER_STATE.LOCKED_LOGIN].includes(value) ?
                    (
                        <LockInfo
                            is2FALocked={record?.loginAttemptDetails?.is2FALocked}
                            lastLogin={record?.lastLogin}
                            lockTime={record?.loginAttemptDetails?.lockTime}
                        />
                    ) : null
                } 
            />
        ),
    },
    {
        title: "backoffice.affiliates.country",
        dataIndex: "country",
        render: (value) => value ? i18n.t(`backoffice.countries.${value}`) : "-",
        mobileLevel: 4
    },
    {
        title: "backoffice.affiliates.players",
        dataIndex: "playersCount",
        render: value => value || 0,
        mobileLevel: 5
    },
    {
        title: "backoffice.affiliates.registrationDate",
        dataIndex: "createdAt",
        isDateTime: true,
        mobileLevel: 6
    },
    {
        title: "backoffice.affiliates.targetMarket",
        dataIndex: "targetMarket",
        multi: true,
        multiMapper: (value) => value ? binaryToFlags(
            Object.values(TARGET_MARKET), value).map((key) => i18n.t(`backoffice.affiliates.${TARGET_MARKETS_TT_KEYS[key]}`)
            ) : []
    }
]
