export const PLAYER_STATE = {
    ACTIVE: 1 << 0,
    BLOCKED: 1 << 1,
    PARTIALY_BLOCKED: 1 << 2,
    UNKNOWN: 1 << 3
}

export const PLAYER_FIND_BY = {
    ID: 1,
    USERNAME: 2,
    EMAIL: 3,
    MOBILE: 4
}

