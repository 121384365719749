export const PROJECT_SHARE_FROM_TOTAL_SOURCE_TYPE = {
	NGR: 1,
	GGR: 2,
};

//TODO: remove everywhere
export const PROJECT_PROVIDER_TYPE = {
	SPORTBOOK: 1,
	VIRTUAL_SPORTS: 2,
};

export const REGISTRATION_FORM_TYPE = {
	AFFILIATE: 1,
};

export const PROJECT_INTEGRATION_MODE = {
	REAL: 1,
	TEST: 2,
};

export const PROJECT_GAME_PROVIDER_TYPE = {
	SPORT: 1,
};

export const WIDGET_TRANSACTION_TYPE = {
	DEPOSIT: 1,
	WITHDRAWAL: 2,
};

export const PROJECT_PAYMENT_STATE = {
	ACTIVE: 1,
	INACTIVE: 2,
	DELETED: 4,
};

export const AFFILIATE_REG_TYPE = {
	SELF_SIGN_UP: 1 << 0,
	BY_AFFILIATE_MANAGER: 1 << 1,
};

export const PROJECT_BRAND_PRODUCT_TYPE = {
	CASINO: 1 << 0,
	SPORT: 1 << 1,
	OTHER: 1 << 2,
};

export const PROJECT_REFERRAL_CALCULATION_PERIOD = {
	_1m: 1 << 0,
	_3m: 1 << 1,
	_6m: 1 << 2,
	_1y: 1 << 3,
	_2y: 1 << 4,
	ALWAYS: 1 << 5,
};

export const PROJECT_REFERRAL_COMMISSION_CALCULATION_TYPE = {
	BY_COMPANY: 1 << 0,
	BY_SUBAFFILIATE: 1 << 1,
};

export const PROJECT_DOMAIN_STATUS = {
	ACTIVE: 1 << 0,
	PENDING: 1 << 1,
	INACTIVE: 1 << 2,
	FAILED: 1 << 3,
};

export const REQUEST_DIRECTION = {
	IN: 1,
	OUT: 2,
};

export const DOMAIN_TYPE = {
	BACKOFFICE: 1 << 0,
	PROMO: 1 << 1,
	TRACKING: 1 << 2,
};
