import {
    SET_PERFORMANCE_REPORTS_ACTION_BEFORE,
    SET_PERFORMANCE_REPORTS_ACTION_FINISH,
    SET_AFFILIATE_REPORT,
    SET_AFFILIATE_REPORT_SORTING,
    SET_AFFILIATE_REPORT_FILTERS,
    SET_AFFILIATE_REPORT_TOTALS,
    SET_PLAYER_REPORT,
    SET_PLAYER_REPORT_SORTING,
    SET_PLAYER_REPORT_FILTERS,
    SET_PLAYER_REPORT_TOTALS
} from '../../actionTypes';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_PERFORMANCE_REPORTS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_PERFORMANCE_REPORTS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_AFFILIATE_REPORT:
            return {
                ...state,
                affiliateReport: {
                    ...state.affiliateReport,
                    report: !payload.add ? payload.report.item2 : state.affiliateReport.report.concat(payload.report.item2),
                    total: payload.report.item1,
                }
            }
        case SET_AFFILIATE_REPORT_FILTERS:
            return {
                ...state,
                affiliateReport: {
                    ...state.affiliateReport,
                    filters: payload.filters,
                    sorting: {
                        ...state.affiliateReport.sorting,
                        page: 1
                    }
                }
            }
        case SET_AFFILIATE_REPORT_SORTING:
            return {
                ...state,
                affiliateReport: {
                    ...state.affiliateReport,
                    sorting: payload.sorting
                }
            };
        case SET_AFFILIATE_REPORT_TOTALS:
            return {
                ...state,
                affiliateReport: {
                    ...state.affiliateReport,
                    totals: payload.totals,
                }
            }
        case SET_PLAYER_REPORT:
            return {
                ...state,
                playerReport: {
                    ...state.playerReport,
                    report: !payload.add ? payload.report.item2 : state.playerReport.report.concat(payload.report.item2),
                    total: payload.report.item1,
                }
            }
        case SET_PLAYER_REPORT_FILTERS:
            return {
                ...state,
                playerReport: {
                    ...state.playerReport,
                    filters: payload.filters,
                    sorting: {
                        ...state.playerReport.sorting,
                        page: 1
                    }
                }
            }
        case SET_PLAYER_REPORT_SORTING:
            return {
                ...state,
                playerReport: {
                    ...state.playerReport,
                    sorting: payload.sorting
                }
            };
        case SET_PLAYER_REPORT_TOTALS:
            return {
                ...state,
                playerReport: {
                    ...state.playerReport,
                    totals: payload.totals,
                }
            }
        default:
            return state;
    }
}