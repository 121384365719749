import React from 'react';
import PropTypes from 'prop-types';

import Icon from "components/common/icon";

import { makeImagePath } from 'utils/common';

const StoryImgList = ({
    data,
    showAddEditPopup,
    groupId,
    onStoryDelete,
}) => {
    return (
        <div className='rt--promo-customize-stories'>
            <div
                className='rt--promo-customize-stories-block'
                onClick={() => showAddEditPopup({
                    type: "addStory",
                    groupId
                })}
            >
                <div className='rt--promo-customize-stories-block-add'>
                    <Icon name='plus' size={18} />
                </div>
            </div>

            {Object.entries(data).map(([id, imageUrl]) => {
                return (
                    <div key={id} className='rt--promo-customize-stories-block'>
                        <div
                            className='rt--promo-customize-stories-block-img'
                            onClick={() => onStoryDelete({
                                id,
                                groupId
                            })}
                        >
                            <img
                                src={makeImagePath(imageUrl)}
                                alt='Story Image'
                            />
                            <Icon name='trash' size={18} />
                        </div>
                    </div>
                )
            })}

        </div>
    )
}

/** StoryImgList propTypes
 * PropTypes
*/
StoryImgList.propTypes = {
    data: PropTypes.object,
    showAddEditPopup: PropTypes.func,
    onStoryDelete: PropTypes.func,
    groupId: PropTypes.number,
};

export default StoryImgList;