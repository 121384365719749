import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip as AntTooltip } from 'antd';

import { isMobile } from "utils/common";

/** Tooltip Component */
const Tooltip = ({
    children,
    title,
    getPopupContainer,
    trigger,
    placement="bottomLeft",
    enableMobile,
    className
}) => {

    return (!isMobile() || enableMobile )? (
            <AntTooltip
                title={(
                    <div
                        onClick={e => e.stopPropagation()}
                    >
                        { title }
                    </div>
                )}
                getPopupContainer={getPopupContainer ? getPopupContainer : (() => document.getElementsByClassName("rt--portal-layout")[0]) }
                trigger={enableMobile && isMobile() ? ["click"] : trigger}
                placement={placement}
                overlayClassName={className}
            >
                { children }
            </AntTooltip>
        ) : children
}

/** Tooltip propTypes
    * PropTypes
*/
Tooltip.propTypes = {
    /** Tooltip content */
    children: PropTypes.node,
    /** Tooltip title */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Function to render popup container */
    getPopupContainer: PropTypes.func,
    /** Array of Tooltip trigger actions */
    trigger: PropTypes.array,
    /** Tooltip placement */
    placement: PropTypes.string,
    /** Enable For Mobile */
    enableMobile: PropTypes.bool,
    /** Tooltip css className */
    className: PropTypes.string
}

export default Tooltip;