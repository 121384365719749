import { isMobile } from "utils/common";

import { TIME_PICKER_MODES } from "./constants";

export const getShowTimeOptionValue = ({ showTime, timePickerMode, dateService }) => {
    const modes = {
        [TIME_PICKER_MODES.DEFAULT]: {
            format: dateService.getFormat(true, false),
        },
    
        [TIME_PICKER_MODES.ONLY_HOURS]: {
            hideDisabledOptions: true,
            disabledMinutes: () => constructArrayForGivenRanges({ start: 1, end: 60 }),
            defaultValue: [dateService.getDate('00:00', 'HH:mm'), dateService.getDate('00:00', 'HH:mm')],
        }
    }
    if (showTime) {
        return modes[timePickerMode];
    }

    if (isMobile()) {
        return { format: "HH:mm" };
    }

    return false; // time picker is not active
}