import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from "react-router-dom";

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";
import TextAreaInput from 'components/common/textAreaInput';

import { addPermissionGroupUsers } from "store/actions/portal/userManagement/permissionGroups/users.action";

import { getUser } from 'utils/auth';

import useAutosuggestion from 'hooks/useAutosuggestion';

import { USER_TYPE, USER_ROLE } from "constants/user.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import userInfoType from 'types/profile/userInfo.type';

/** User Adding Popup Component */
const UserAddComponent = ({
    isSaving,
    addPermissionGroupUsers,
    userInfo,
    onClose
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();
    const { search } = useLocation();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const userRole = getUser().role;

    /** Available user names */
    const [ permissionGroupAvailableUsers ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.USER_AVAILABLE_PERMISSION_GROUP,
        actionParams: searchParams.id
    })

    /** Fires when form submitted
       * @function
       * @memberOf UserAddComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {

                addPermissionGroupUsers(data.ids, searchParams.id , data.note, onClose);

            }).catch(() => { })
    }

    return (
        <Modal
            title={ t('backoffice.permissiongroups.addAdmin') }
            cancelText={t('backoffice.common.cancel')}
            okText={ t('backoffice.common.add') }
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.permissiongroups.admins')} *`}
                            name="ids"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <Select
                                options={ 
                                    permissionGroupAvailableUsers
                                    .filter(item => item.id !== userInfo.id)
                                    .map(item => (
                                        { value: item.id, text: item.name }
                                    ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.permissiongroups.admins')}`}
                                isMultiple={true}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col> 
                    {
                        userRole === USER_ROLE.ACCESS_MANAGER && (
                            <Col span={24} >
                                <Form.Item
                                    label={`${t('backoffice.users.notes')} *`}
                                    name="note"
                                    rules={[
                                        { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                        { max: 1000, message: t('backoffice.validation.fieldInvalid') },
                                        { min: 6, message: t('backoffice.validation.fieldInvalid') }
                                    ]}
                                    className="rt--form-item-without-margin rt--general-form-item"
                                    data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.notes')}`}
                                >
                                    <TextAreaInput
                                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.notes')}`}
                                        maxLength={1000}
                                        rows={6}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }              
                </Row>
            </Form>
        </Modal>
    )
}

/** UserAddComponent propTypes
    * PropTypes
*/
UserAddComponent.propTypes = {
    /** Redux state property, is true when adding user request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to add users to permission group */
    addPermissionGroupUsers: PropTypes.func,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        addPermissionGroupUsers: (ids, groupId, note, onSuccess) => {
            dispatch(addPermissionGroupUsers(ids, groupId, note, onSuccess));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.permissionGroups.isSaving,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAddComponent)