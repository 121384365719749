import React from "react";

import AffiliateManagersComponent from "pages/userManagement/affiliateManagers/affiliateManagers.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const AffiliateManagersRoute = () => {
    return <AffiliateManagersComponent />;
};

export default withPermission(
    withAuth(AffiliateManagersRoute),
    { resource: PERMISSION_RESOURCE.AFFILIATE_MANAGER, action: PERMISSION_ACTION.VIEW },
    Paths.PORTAL
);
