import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Form, Col, Row, Checkbox } from 'antd';

import Modal from "components/common/modal";
import Select from 'components/common/select';
import Input from 'components/common/input';

import { createUser, getUsers, getUserAvailableCompanies } from 'store/actions/portal/userManagement/users/users.action';

import { getCompanyPasswordSettings } from "store/actions/portal/companies/passwordSettings.action";

import Paths from 'constants/path.constants';

import { POPUP_SIZE } from "constants/common.constants";
import { EMAIL_REGEX, USERNAME_REGEX, LAST_NAME_REGEX, FIRST_NAME_REGEX } from "constants/regex.constants";
import { SECURITY_LEVELS, USER_ROLE } from "constants/user.constants";

import { validatePassword } from "utils/password";
import { hasAdminEditPageAccess } from "utils/pageAccess";

import companyType from "types/company/company.type";
import companyPasswordSettingsType from "types/company/passwordSettings.type";
import userInfoType from 'types/profile/userInfo.type';

import { isMobile } from 'utils/common';
import { getUser } from 'utils/auth';

/** User Creating Popup Component */
const UserCreateComponent = ({
    isSaving,
    createUser,
    getUsers,
    globalCompanyId,
    allCompanies,
    availableCompanies,
    isAvailableCompaniesLoading,
    getUserAvailableCompanies,
    getCompanyPasswordSettings,
    passwordSettings,
    userInfo,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue, getFieldValue } = formInstance;
    const navigate = useNavigate();

    const userRole = getUser()?.role;

    /** Load user available companies, password settings */
    useEffect(() => {
        getUserAvailableCompanies();
        getCompanyPasswordSettings();
    }, [])

    /** Detect if current company is test partner
       * @function
       * @returns {boolean}
       * @memberOf UserCreateComponent
   */
    const isCurrentCompanyTest = allCompanies.find(p => p.id === globalCompanyId)?.isTesting ?? false

    /** Main Company name */
    const mainCompanyName = allCompanies.find(p => p.id === globalCompanyId)?.name ?? ""

    /** Fires when form submitted
       * @function
       * @memberOf UserCreateComponent
    */
    const handleForm = () => {
        validateFields()
            .then((data) => {
                createUser(
                    data,
                    record => {
                        if (hasAdminEditPageAccess()) {
                            navigate(`${Paths.USERS_EDIT}/${record.id}?name=${record.userName}&mainCompany=${mainCompanyName}&mainCompanyId=${globalCompanyId}`)
                        } else {
                            getUsers();
                            onClose();
                        }
                    });
            }).catch(ex => {
                console.log(ex)
            })
    }

    const initialValues = {
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        isTesting: isCurrentCompanyTest,
        securityLevel: userInfo.securityLevel
    }
    if(userRole !== USER_ROLE.ACCESS_MANAGER){
        initialValues.companyIds = []
    }

    return (
        <Modal
            title={t('backoffice.users.createAdmin')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.BIG}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={initialValues}
            >
                <Row gutter={[16, 0]}>
                    {
                        userRole !== USER_ROLE.ACCESS_MANAGER && (
                            <Col xs={24} sm={12} >
                                <Form.Item
                                    label={`${t('backoffice.users.mainCompany')} *`}
                                    className='rt--form-item-disabled'
                                >
                                    <Input
                                        value={mainCompanyName}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }

                    {
                        userRole !== USER_ROLE.ACCESS_MANAGER && (
                            <Col xs={24} sm={12} >
                                <Form.Item
                                    label={t('backoffice.users.companies')}
                                    name="companyIds"
                                >
                                    <Select
                                        options={
                                            Object.keys(availableCompanies)
                                                .filter(item => item !== globalCompanyId)
                                                .map(item => (
                                                    { value: item, text: item === "*" ? t("backoffice.common.all") : availableCompanies[item] }
                                                ))
                                        }
                                        placeholder={`${t('backoffice.common.select')} ${t('backoffice.users.company')}`}
                                        loading={isAvailableCompaniesLoading}
                                        onChange={value => {
                                            if (value.slice(-1)[0] === "*") {
                                                setFieldsValue({ "companyIds": ["*"] })
                                            } else if (value.length > 1 && value.includes("*")) {
                                                setFieldsValue({ "companyIds": value.filter(v => v !== "*") })
                                            } else {
                                                setFieldsValue(value)
                                            }
                                        }}
                                        isMultiple={true}
                                        search={true}
                                        getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }



                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={`${t('backoffice.users.firstName')} *`}
                            name="firstName"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 48, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: FIRST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.firstName')}`}
                        >
                            <Input
                                maxLength={48}
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.firstName')}`}
                                autoFocus={(isMobile() ? false : true)}
                                autoComplete="off"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={`${t('backoffice.users.lastName')} *`}
                            name="lastName"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 48, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: LAST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            validateFirst
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.lastName')}`}
                        >
                            <Input
                                maxLength={48}
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.lastName')}`}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={t('backoffice.users.email')}
                            name="email"
                            rules={[
                                { pattern: EMAIL_REGEX, message: t('backoffice.validation.emailFormat') },
                                { max: 50, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.email')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.email')}`}
                                maxLength={50}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={`${t('backoffice.users.username')} *`}
                            name="username"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: USERNAME_REGEX, message: t('backoffice.validation.fieldInvalid') },
                            ]}
                            validateFirst
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.username')}`}
                        >
                            <Input
                                maxLength={30}
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.username')}`}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={`${t('backoffice.users.password')} *`}
                            name="password"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: passwordSettings.passwordMaxLimit || 24, message: t('backoffice.validation.fieldInvalid') },
                                ({
                                    validator(rule, value) {
                                        return validatePassword(value, passwordSettings);
                                    }
                                })
                            ]}
                            validateFirst
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.password')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.password')}`}
                                type='Password'
                                maxLength={passwordSettings.passwordMaxLimit || 24}
                                autoComplete="new-password"
                                onChange={() => {
                                    setTimeout(() => {
                                        if (getFieldValue('confirmPassword') !== "")
                                            validateFields(['confirmPassword'])
                                    }, 0)
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={`${t('backoffice.users.confirmPassword')} *`}
                            name="confirmPassword"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (value !== getFieldValue("password")) {
                                            return Promise.reject(t('backoffice.validation.passwordsDoNotMatch'))
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                            validateFirst
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.confirmPassword')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.confirmPassword')}`}
                                type='Password'
                                maxLength={passwordSettings.passwordMaxLimit || 24}
                                autoComplete="new-password"
                                onPaste={e => e.preventDefault()}
                                onContextMenu={e => e.preventDefault()}
                                onCopy={e => e.preventDefault()}
                                onChange={() => {
                                    setTimeout(() => {
                                        if (getFieldValue('confirmPassword') !== "")
                                            validateFields(['confirmPassword'])
                                    }, 0)
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={t('backoffice.users.securityLevel')}
                            name="securityLevel"
                        >
                            <Select
                                options={
                                    SECURITY_LEVELS
                                        .filter(level => level <= userInfo.securityLevel)
                                        .map(level => (
                                            { value: level, text: level }
                                        ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.users.securityLevel')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <div className="rt--flex-inline rt--align-center rt--form-item-checkbox">
                            <Form.Item
                                className='rt--form-item-inline rt--form-item-without-margin'
                                name="isTesting"
                                valuePropName='checked'
                            >
                                <Checkbox />
                            </Form.Item>
                            <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t('backoffice.users.isTestAdmin')}</span>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** UserCreateComponent propTypes
    * PropTypes
*/
UserCreateComponent.propTypes = {
    /** Redux state property, is true when creating user request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to create user */
    createUser: PropTypes.func,
    /** Redux action to get users */
    getUsers: PropTypes.func,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, represents the array of global companies  */
    allCompanies: PropTypes.arrayOf(companyType),
    /** Redux action to get available companies of user */
    getUserAvailableCompanies: PropTypes.func,
    /** Redux state property, represents the object of available companies of user */
    availableCompanies: PropTypes.object,
    /** Redux state property, is true when loading available companies of user */
    isAvailableCompaniesLoading: PropTypes.bool,
    /** Redux action to get password settings */
    getCompanyPasswordSettings: PropTypes.func,
    /** Redux state property, represents company password settings */
    passwordSettings: companyPasswordSettingsType,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createUser: (user, onSuccess) => {
            dispatch(createUser(user, onSuccess));
        },

        getUsers: () => {
            dispatch(getUsers());
        },

        getUserAvailableCompanies: () => {
            dispatch(getUserAvailableCompanies())
        },

        getCompanyPasswordSettings: () => {
            dispatch(getCompanyPasswordSettings());
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.users.isSaving,
        allCompanies: state.common.allCompanies,
        globalCompanyId: state.common.globalCompanyId,
        availableCompanies: state.users.availableCompanies.availableCompanies,
        isAvailableCompaniesLoading: state.users.availableCompanies.isLoading,
        passwordSettings: state.companies.edit.passwordSettings,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreateComponent)