import React from "react"
import PropTypes from "prop-types";

import Breadcrumbs from "components/common/breadcrumbs";

import { isMobile } from "utils/common";

import breadcrumbsType from 'types/components/breadcrumbs.type';

/** Tab layout */
const TabLayout = ({
    children,
    breadcrumbs,
    header,
}) => {
    return (
        <div className={"rt--tab-layout" + (!isMobile() ? " rt--pl-16 rt--pr-16" : "")}>
            <div className={"rt--tab-layout-header rt--flex rt--justify-between rt--align-center " + (isMobile() ? 'rt--pt-8 rt--pb-8 rt--pl-12 rt--pr-12' : 'rt--pb-16 rt--pt-16')}>
                {
                    breadcrumbs && (
                        <Breadcrumbs
                            {...breadcrumbs}
                        />
                    )
                }
                {
                    Boolean(header?.extra) && (
                        <div className={"rt--tab-layout-header-actions-rest-extra rt--align-center rt--flex" + (isMobile() ? '' : 'rt--pl-16 rt--pr-16')}>
                            {header.extra}
                        </div>
                    )
                }
            </div>
            <div className="rt--tab-layout-content">
                {children}
            </div>
        </div>
    )
}

/** TabLayout propTypes
 * PropTypes
*/
TabLayout.propTypes = {
    /** Layout Content */
    children: PropTypes.node,
    /** Breadcrumbs */
    breadcrumbs: PropTypes.shape(breadcrumbsType),
    /** Header options */
    header: PropTypes.shape({
        /** Extra content component */
        extra: PropTypes.node,
    }),
}

export default TabLayout;