import React from "react";

import i18n from "translations/config";

export const tableColumns = [
    {
        title: "backoffice.affiliates.paymentMethod",
        dataIndex: "name",
        alwaysVisible: true,
        mobileLevel: 1
    },
    {
        title: "backoffice.affiliates.currency",
        dataIndex: "currencyCode",
        mobileLevel: 2,
        render: (value, record) => (
            <div className="rt--flex rt--align-center">
                <span className="rt--title rt--font-regular rt--font-normal">{value}</span>
                {
                    record.isDefault && (
                        <div className='rt--tag rt--table-tag rt--flex rt--justify-between rt--align-center rt--pl-8 rt--pr-8 rt--ml-8 rt--mr-8'>
                            <span className='rt--title rt--font-normal rt--font-bold'>{i18n.t("backoffice.affiliates.autoInvoice")}</span>
                        </div>
                    )
                }
            </div>
        )
    },
    {
        title: "backoffice.affiliates.minThreshold",
        dataIndex: "minThreshhold",
        mobileLevel: 3
    },
    {
        title: "backoffice.affiliates.commissions",
        dataIndex: "commission",
        mobileLevel: 4
    },
    {
        title:"backoffice.affiliates.createdAt",
        dataIndex: "createdAt",
        isDateTime: true,
        mobileLevel: 5
    },
    {
        title:"backoffice.affiliates.createdBy",
        dataIndex: "createdBy",
        mobileLevel: 6
    }
];