import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Form, Button } from 'antd';

import Input from 'components/common/input';

import { EMAIL_REGEX } from "constants/regex.constants";
import Paths from 'constants/path.constants';

import { forgotPassword } from 'store/actions/auth/forgotPassword.action';

import LanguageUtils from 'utils/languages';

/** Forgot Password Page Component */

const ForgotPasswordComponent = ({
    forgotPassword,
    isForgeting
}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    /** Fires when form submitted
       * @function
       * @memberOf ForgotPasswordComponent
   */
    const handleForm = () => {
        validateFields()
            .then(({ email }) => {
                forgotPassword(email);
            }).catch(() => { })
    }

    return (
        <div className="rt--auth-form rt--auth-form-forgot">
            <Form
                form={formInstance}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    email: ""
                }}
            >
                <span className="rt--title rt--font-bigest rt--font-bold ">{t('backoffice.authentication.forgotPassword')}</span>
                <div className='rt--mt-12 rt--mb-64'>
                    <span className="rt--text-light rt--font-normal rt--font-regular">{t('backoffice.authentication.forgotPasswordInfoText')}</span>
                </div>

                <Form.Item
                    label={`${t('backoffice.authentication.emailAddress')} *`}
                    name="email"
                    validateFirst
                    rules={[
                        { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                        { pattern: EMAIL_REGEX, message: t('backoffice.validation.emailFormat') }
                    ]}
                    className='rt--general-form-item'
                    data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.authentication.emailAddress')}`}
                >
                    <Input
                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.authentication.emailAddress')}`}
                    />

                </Form.Item>

                <Form.Item>
                    <div className='rt--flex'>
                        <Button
                            loading={isForgeting}
                            type="primary"
                            htmlType="submit"
                            className="rt--button rt--button-primary rt--mt-24"
                            onClick={handleForm}
                        >
                            <span>{t('backoffice.authentication.sendResetInstructions')}</span>
                        </Button>
                        <Button
                            type="text"
                            htmlType="submit"
                            className="rt--button rt--button-ghost rt--mt-24 rt--ml-16"
                            onClick={() => navigate(Paths.LOGIN + "?language=" + LanguageUtils.getSelectedLanguage())}
                        >
                            <span>{t('backoffice.authentication.returnToLogin')}</span>
                        </Button>
                    </div>

                </Form.Item>
            </Form>

        </div>
    )
}

/** ForgotPasswordComponent propTypes
    * PropTypes
*/
ForgotPasswordComponent.propTypes = {
    /** Redux state property, is true when forgot password request is in process */
    isForgeting: PropTypes.bool,
    /** Redux action to send forgot password email */
    forgotPassword: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        forgotPassword: email => {
            dispatch(forgotPassword(email))
        }
    }
)

const mapStateToProps = state => {
    return {
        isForgeting: state.auth.isForgeting
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ForgotPasswordComponent
);