import React from 'react';

import { makeImagePath } from "utils/common";

export const tableColumns = [
    {
        title: "",
        dataIndex: "logoPath",
        alwaysVisible: true,
        image: true,
        width: "132px",
        render: (logoPath) => (
            <div className="rt--table-col-img">
                <img 
                    src={ makeImagePath(logoPath) } 
                    className="rt--table-col-img"  
                    alt="Payment Logo" 
                />
            </div>
        ),
    },
    {
        title: "backoffice.projects.paymentMethod",
        dataIndex: "name",
        alwaysVisible: true,
        mobileLevel: 1
    },
    {
        title: "backoffice.projects.currencies",
        dataIndex: "currencies",
        multi: true,
        mobileLevel: 2,
        multiMapper: (currencies) => currencies ? currencies.map(({ currencyCode }) => currencyCode) : []
    }
];