import React from "react";

import SystemNotificationsEdit from "pages/communications/systemNotifications/edit/systemNotification-edit.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const SystemNotificationsEditRoute = () => {
    return <SystemNotificationsEdit />;
};

export default withPermission(
    withAuth(SystemNotificationsEditRoute),
    [
        { resource: PERMISSION_RESOURCE.COMMUNICATION_SYSTEM_TEMPLATES, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.COMMUNICATION_PROJECT_TEMPLATES, action: PERMISSION_ACTION.VIEW }
    ],
    Paths.PORTAL
);
