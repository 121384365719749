import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProjectAvailableLanguages } from 'store/actions/portal/common/common.action';

const selectGlobalProjectId = state => state.common.globalProjectId;
const selectProjectLanguages = state => state.common.projectAvailableLanguages;

const useLanguages = (autoCall = true) => {
    //#region ------------------------------------------- REDUX --------------------------------------------//

    const dispatch = useDispatch();
    const globalProjectId = useSelector(selectGlobalProjectId);
    const languages = useSelector(selectProjectLanguages);

    //#endregion

    const fn = useCallback(() => {
        dispatch(getProjectAvailableLanguages());
    }, [globalProjectId])

    // Load Languages
    useEffect(() => {
        if (autoCall) {
            fn()
        }
    }, [globalProjectId, dispatch]);


    return [ languages, fn ];
}

export default useLanguages;