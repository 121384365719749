import React, { useEffect, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Spin } from "antd";

import NotificationSound from './sound';
import OutsideAlerter from '../outsideAlerter';
import Modal from "components/common/modal";
import Icon from "components/common/icon";

import { getNotifications, markNotificationAsRead, deleteNotification } from 'store/actions/portal/notifications/notifications.action';

import useDate from 'hooks/useDate';

import PATHS from 'constants/path.constants';
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { NOTIFICATION_STATE_ENUM, NOTIFICATION_TYPE } from "constants/notification.constants";
import { PERMISSION_REQUEST_TYPE } from "constants/permissionRequest.constants";
import { USER_STATE } from "constants/user.constants";

import { getUser } from 'utils/auth';
import { isMobile } from 'utils/common';
import { hasPermission } from 'utils/permissions';
import sessionStorageUtils from "utils/sessionStorage";
import { getUnReadNotificationCount } from 'utils/notifications';

import notificationType from 'types/notification/notification.type';

/** Notifications Component */
const NotificationsComponent = ({
    getNotifications,
    markNotificationAsRead,
    deleteNotification,
    notifications,
    sound,
    isLoading
}) => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    const navigate = useNavigate();

    const [opened, setOpened] = useState(false);

    const initialized = useRef(false);

    const hasPermissionRequestViewPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PERMISSION_REQUESTS,
        action: PERMISSION_ACTION.VIEW
    })

    const unReadNotificationsCount = getUnReadNotificationCount(notifications);

    /** Function which fires on notification click
       * @function
       * @param {object} notification
       * @memberOf NotificationsComponent
   */
    const handleNotificationClick = notification => {
        setOpened(false);
        markNotificationAsRead(notification.id);
        if (hasPermissionRequestViewPermission) {
            navigate(PATHS.PERMISSION_REQUESTS)
        }
    }

    /** Function which fires on notification delete button click
       * @function
       * @param {object} e - event object
       * @param {string} id - notification id
       * @memberOf NotificationsComponent
   */
    const handleNotificationDelete = (e, id) => {
        e.stopPropagation();
        deleteNotification(id);
    }

    const makeNotificationData = notification => {
        const data = {};
        const notificationData = JSON.parse(notification?.data ?? "");
        notificationData.forEach(d => {
            data[d.item1] = d.item2;
        })
        return data;
    }

    const makeNotificationTitle = notification => {

        const notificationData = makeNotificationData(notification);

        if (notification.type === NOTIFICATION_TYPE.ACCESS_MANAGER_REQUEST) {
            switch (notificationData.ActionType) {
                case PERMISSION_REQUEST_TYPE.ADD_COMPANY_ACCESS:
                    return t("backoffice.users.addCompanyAccess");
                case PERMISSION_REQUEST_TYPE.DELETE_COMPANY_ACCESS:
                    return t("backoffice.users.deleteCompanyAccess");
                case PERMISSION_REQUEST_TYPE.ADD_PROJECT_ACCESS:
                    return t("backoffice.users.addProjectAccess");
                case PERMISSION_REQUEST_TYPE.DELETE_PROJECT_ACCESS:
                    return t("backoffice.users.deleteProjectAccess");
                case PERMISSION_REQUEST_TYPE.ADD_PERMISSION_GROUP:
                    return t("backoffice.users.addPermissionGroup");
                case PERMISSION_REQUEST_TYPE.DELETE_PERMISSION_GROUP:
                    return t("backoffice.users.deletePermissionGroup");
                case PERMISSION_REQUEST_TYPE.GROUP_MODIFY:
                    return t("backoffice.users.permissionGroupModify");
                case PERMISSION_REQUEST_TYPE.SET_PASSWORD:
                    return t("backoffice.common.setPassword");
            }
        }
        return notification.name
    }

    const makeNotificationDescription = notification => {
        const notificationData = makeNotificationData(notification);
        if (notification.type === NOTIFICATION_TYPE.ACCESS_MANAGER_REQUEST) {
            switch (notificationData.ActionType) {
                case PERMISSION_REQUEST_TYPE.ADD_COMPANY_ACCESS:
                    return `${notificationData.Username} added Company Access to ${notificationData.ObjectName} User.`;
                case PERMISSION_REQUEST_TYPE.DELETE_COMPANY_ACCESS:
                    return `${notificationData.Username} deleted Company Access from ${notificationData.ObjectName} User.`;
                case PERMISSION_REQUEST_TYPE.ADD_PROJECT_ACCESS:
                    return `${notificationData.Username} added Project Access to ${notificationData.ObjectName} User.`;
                case PERMISSION_REQUEST_TYPE.DELETE_PROJECT_ACCESS:
                    return `${notificationData.Username} deleted Project Access from ${notificationData.ObjectName} User.`;
                case PERMISSION_REQUEST_TYPE.ADD_PERMISSION_GROUP:
                    return `${notificationData.Username} added Permission Group to ${notificationData.ObjectName} User.`;
                case PERMISSION_REQUEST_TYPE.DELETE_PERMISSION_GROUP:
                    return `${notificationData.Username} deleted Permission Group from ${notificationData.ObjectName} User.`;
                case PERMISSION_REQUEST_TYPE.GROUP_MODIFY:
                    return `${notificationData.Username} modified ${notificationData.ObjectName} Permission Group.`;
                case PERMISSION_REQUEST_TYPE.SET_PASSWORD:
                    return `${notificationData.Username} set password to ${notificationData.ObjectName} User.`;
            }

        }
        return notification.message;
    }

    const render = () => (
        notifications.length > 0 ?
            notifications.map(n => ({ ...n })).map(notification => (
                <div
                    className={"rt--notifications-item rt--pl-8 rt--pr-8 rt--pt-8 rt--pb-8 " + (isMobile() ? 'rt--flex rt--justify-between' : '') + (notification.state === NOTIFICATION_STATE_ENUM.UNREAD ? " rt--notifications-item-unseen" : "")} key={notification.id}
                    onClick={() => handleNotificationClick(notification)}
                >
                    <div className="rt--notifications-item-inner rt--pl-8 rt--pr-8 rt--pt-8 rt--pb-8">
                        <div className="rt--notifications-item-inner-head rt--mb-8 rt--flex rt--align-center rt--justify-between">
                            <div className="rt--notifications-item-title rt--flex rt--align-center">
                                <span className='rt--title rt--font-small rt--font-bold'>
                                    {
                                        makeNotificationTitle(notification)
                                    }
                                </span>
                                {
                                    notification.state === NOTIFICATION_STATE_ENUM.UNREAD && <div className="rt--notifications-item-mark rt--ml-4" />
                                }
                            </div>
                            {
                                isMobile() ?
                                    ''
                                    :
                                    <>
                                        <span className="rt--notifications-item-time rt--title rt--font-small rt--font-bold">{dateService.format(notification.time)}</span>
                                        {
                                            getUser()?.userState === USER_STATE.ACTIVE ? (
                                                <div
                                                    className="rt--notifications-item-close rt--flex rt--align-center rt--justify-center"
                                                    onClick={e => handleNotificationDelete(e, notification.id)}
                                                >
                                                    <Icon name="close" />
                                                </div>
                                            ) : <div />
                                        }
                                    </>
                            }
                        </div>

                        <span className="rt--notifications-item-desc rt--title rt--font-small rt--font-regular" title={makeNotificationDescription(notification)} >{makeNotificationDescription(notification)}</span>
                    </div>
                    {
                        isMobile() ?
                            <>
                                <div
                                    className="rt--flex rt--align-end rt--flex-col rt--justify-between"
                                    onClick={e => handleNotificationDelete(e, notification.id)}
                                >
                                    <Icon name="close" />
                                    <span className="rt--title rt--font-small rt--font-bold rt--mr-4">{dateService.format(notification.time)}</span>
                                </div>
                            </>
                            :
                            ''
                    }
                </div>
            )) :
            <div className="rt--notifications-empty rt--flex rt--flex-col rt--align-center rt--justify-center">
                {
                    isLoading ? <Spin /> : (
                        <Fragment>
                            <Icon name="speaker" size={100} />
                            <span className="rt--title rt--font-small rt--font-bold rt--pt-16">{t('backoffice.notifications.noNotifications')}</span>
                        </Fragment>
                    )
                }
            </div>
    )

    /** Load notifications */
    useEffect(() => {
        if ((
            opened || sessionStorageUtils.get("unreadNotificationsCount") === null
        ) && !initialized.current) {
            getNotifications();
            initialized.current = true;
        }
    }, [opened])


    return (

        <Fragment>
            <OutsideAlerter callback={() => !isMobile() && setOpened(false)}>
                <div
                    className='rt--header-actions rt--header-actions-content-icon rt--header-item rt--pr-16 rt--mr-16 rt--flex rt--align-center'
                >
                    <div
                        className='rt--flex rt--align-center'
                        onClick={() => setOpened(true)}
                    >
                        <Icon name="notification" />
                        {
                            unReadNotificationsCount > 0 && (
                                <div className="rt--notifications-mark rt--flex rt--justify-center rt--align-center">
                                    <span className='rt--title rt--font-smallest rt--font-bold'>{unReadNotificationsCount}</span>
                                </div>
                            )
                        }
                    </div>

                    {
                        opened && (
                            <Fragment>
                                {
                                    isMobile() ? (
                                        <Modal
                                            title={t('backoffice.notifications.notifications')}
                                            onCancel={() => setOpened(false)}
                                        >
                                            {render()}
                                        </Modal>
                                    ) : (
                                        <div className="rt--notifications">
                                            {render()}
                                        </div>
                                    )
                                }
                            </Fragment>
                        )
                    }



                </div>
            </OutsideAlerter>
            {sound && <NotificationSound />}

        </Fragment>
    )
}

/** NotificationsComponent propTypes
    * PropTypes
*/
NotificationsComponent.propTypes = {
    /** Redux action to get user profile */
    getNotifications: PropTypes.func,
    /** Redux action to mark notifications as read */
    markNotificationAsRead: PropTypes.func,
    /** Redux action to delete notification */
    deleteNotification: PropTypes.func,
    /** Redux state, represents the array of notifications  */
    notifications: PropTypes.arrayOf(notificationType),
    /** Redux state property for notification sound */
    sound: PropTypes.bool,
    /** Redux state property, is true when loading notifications */
    isLoading: PropTypes.bool,
}

const mapDispatchToProps = dispatch => (
    {
        getNotifications: () => {
            dispatch(getNotifications());
        },

        markNotificationAsRead: id => {
            dispatch(markNotificationAsRead(id));
        },

        deleteNotification: id => {
            dispatch(deleteNotification(id));
        }
    }
)

const mapStateToProps = state => {
    return {
        notifications: state.notifications.notifications,
        sound: state.notifications.sound,
        isLoading: state.notifications.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsComponent)
