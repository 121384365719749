import PropTypes from 'prop-types';

export default PropTypes.shape({
    id: PropTypes.number,
    userName: PropTypes.string,
    registrationDate: PropTypes.string,
    currencyCode: PropTypes.string,
    affiliateId: PropTypes.number,
    affiliateUserName: PropTypes.string,
    campaignId: PropTypes.string,
    campaignName: PropTypes.string,
    mediaId: PropTypes.string,
    mediaName: PropTypes.string,
    brandId: PropTypes.string,
    brandName: PropTypes.string,
    depositAmount: PropTypes.number,
    depositCount: PropTypes.number,
    withdrawAmount: PropTypes.number,
    withdrawCount: PropTypes.number,
    netDepositAmount: PropTypes.number,
    sportBetCount: PropTypes.number,
    sportBetAmount: PropTypes.number,
    sportWonAmount: PropTypes.number,
    sportBonusWonAmount: PropTypes.number,
    sportGGR: PropTypes.number,
    sportNGR: PropTypes.number,
    casinoBetCount: PropTypes.number,
    casinoBetAmount: PropTypes.number,
    casinoWonAmount: PropTypes.number,
    casinoBonusWonAmount: PropTypes.number,
    casinoGGR: PropTypes.number,
    casinoNGR: PropTypes.number,
    adjustmentsAmount: PropTypes.number
})