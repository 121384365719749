import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Outlet } from "react-router-dom";

import { Layout as AntLayout, Spin } from 'antd';

const { Content: AntContent, Header: AntHeader, Sider: AntSider } = AntLayout;

import Sidebar from 'components/common/sidebar';
import Header from 'components/common/header';

import { getUser} from 'utils/auth';
import { isMobile } from "utils/common";

const Layout = ({
    isProjectChanging,
    isLogging
}) => {

    const [collapsed, setCollapsed] = useState(false);

    return (
        <Fragment>
            <AntSider
                className='rt--sidebar'
                width={isMobile() ? "100vw" : 256}
                collapsedWidth={56}
                collapsed={isMobile() ? false : collapsed}
            >
                <Sidebar
                    onCollapse={setCollapsed}
                    collapsed={isMobile() ? false : collapsed}
                />
            </AntSider>
            <AntLayout className="rt--flex rt--flex-col">
                <Spin spinning={isProjectChanging || isLogging}>
                    <AntHeader className={'rt--header-wrapper' + (getUser()?.hasPrevious ? " rt--header-wrapper-asAffiliate" : "")}>
                        <Header />
                    </AntHeader>
                    <AntContent className='rt--flex-equal'>
                        <Outlet />
                    </AntContent>
                </Spin>
            </AntLayout>
        </Fragment>
    )
}

/** Layout propTypes
    * PropTypes
*/
Layout.propTypes = {
    /** Redux state property, is true when project change request is in process */
    isProjectChanging: PropTypes.bool,
    /** Redux state property, is true when logging */
    isLogging: PropTypes.bool,
}

const mapStateToProps = state => {
    return {
        isProjectChanging: state.common.isProjectChanging,
        isLogging: state.auth.isLoading
    }
}


export default connect(mapStateToProps, null)(Layout);

