import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

import ProjectAddComponent from './project-add.component';
import ProjectTableComponent from './project-table.component';

import { getAccountProjectAccess } from "store/actions/portal/account/projects.action";

import accountProjectAccessType from 'types/account/projectAccess.type';

/** User Edit Page Project Settings Tab Component */
const ProjectsComponent = ({
    getAccountProjectAccess,
    projectAccess
}) => {
    const searchParams = useParams();

    /** State to show/hide project add popup */
    const [showAddPopup, setShowAddPopup] = useState(null);

    /** Load user project access*/
    useEffect(() => {
        if (searchParams.id)
            getAccountProjectAccess(searchParams.id)

    }, [])

    /** Close project add popup, after save */
    useEffect(() => {
        setShowAddPopup(null);
    }, [projectAccess]);



    return (
        <Fragment>
            <ProjectTableComponent
                onAddButtonClick={companyId => setShowAddPopup({ companyId: companyId })}
            />
            {
                showAddPopup ? <ProjectAddComponent onClose={() => setShowAddPopup(null)} companyId={showAddPopup.companyId} /> : null
            }
        </Fragment>

    )
}

/** ProjectsComponent propTypes
    * PropTypes
*/
ProjectsComponent.propTypes = {
    /** Redux action to get user project access */
    getAccountProjectAccess: PropTypes.func,
    /** Redux state property, represents the projects access info of user  */
    projectAccess: accountProjectAccessType
}

const mapDispatchToProps = dispatch => (
    {
        getAccountProjectAccess: id => {
            dispatch(getAccountProjectAccess(id));
        }
    }
)

const mapStateToProps = state => {
    return {
        projectAccess: state.account.projectAccess
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProjectsComponent);
