export const TRANSLATION_SOURCE_TYPE = {
    SELF : 0,
    OVERRIDEN : 1,
    SYSTEM_TRANSLATION : 2,
    COMPANY_DEFAULT : 3,
    SYSTEM_DEFAULT : 4
}

export const TRANSLATION_TYPE = {
    PROMO: 1 << 0,
    BO: 1 << 1
}

export const BO_GROUP_ID = "24cde1b8b5a44baab289267515ce3d51";
export const PROMO_GROUP_ID = "f621682b66414b3aa6d513bb0c0e389a";

export const VALID_GROUP_IDS = [
    "24cde1b8b5a44baab289267515ce3d51", // BO
    "f621682b66414b3aa6d513bb0c0e389a", // PROMO
    "390b5eff5e5943a2acfb4c6fa4ed9268" // EMAIL TEMPLATES
]