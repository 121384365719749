import PropTypes from 'prop-types';

export default {
    /** Button icon */
    icon: PropTypes.string,
    /** Button type */
    type: PropTypes.string,
    /** Button class name */
    className: PropTypes.string,
    /** Button onclick function */
    onClick: PropTypes.func,
    /** Button text */
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /** Is Button enabled */
    enabled: PropTypes.bool,
    /** Is Button disabled */
    disabled: PropTypes.bool,
    /** Is loading */
    loading: PropTypes.bool,
    /** HTML type of button */
    htmlType: PropTypes.string
}