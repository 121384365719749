import React from 'react';

import { useTranslation } from 'react-i18next';

import Tabs from 'components/common/tabs';

import GeneralInfoComponent from '../edit/sections/general';
import CompaniesComponent from '../edit/sections/companies';
import ProjectsComponent from '../edit/sections/projects';
import PermissionsAndGroupsComponent from '../edit/sections/permissionsAndGroups';
import SessionsComponent from '../edit/sections/sessions';

import Paths from 'constants/path.constants';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

/** User Edit Page Component */
const UserEditComponent = () => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.users.general"),
            permissions: [{ resource: PERMISSION_RESOURCE.ADMIN_GENERALINFO, action: PERMISSION_ACTION.VIEW }],
            component: <GeneralInfoComponent />
        },
        {
            title: t("backoffice.users.companies"),
            permissions: [{ resource: PERMISSION_RESOURCE.ADMIN_COMPANY_ACCESS, action: PERMISSION_ACTION.VIEW }],
            component: <CompaniesComponent />
        },
        {
            title: t("backoffice.users.projects"),
            permissions: [{ resource: PERMISSION_RESOURCE.ADMIN_PROJECT_ACCESS, action: PERMISSION_ACTION.VIEW }],
            component: <ProjectsComponent />
        },
        {
            title: t("backoffice.users.permissionsAndGroups"),
            permissions: [{ resource: PERMISSION_RESOURCE.ADMIN_PERMISSIONS, action: PERMISSION_ACTION.VIEW }],
            component: <PermissionsAndGroupsComponent permissionResource={PERMISSION_RESOURCE.ADMIN_PERMISSIONS} />
        },
        {
            title: t("backoffice.users.sessions"),
            permissions: [{ resource: PERMISSION_RESOURCE.ADMIN_SESSIONS, action: PERMISSION_ACTION.VIEW }],
            component: <SessionsComponent />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={{ title: t('backoffice.menu.admins'), path: Paths.USERS }}
        />
    )
}



export default UserEditComponent;