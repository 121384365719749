import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import DateRangePicker from "components/common/dateRangePicker";
import Select from "components/common/select";
import NumericInput from 'components/common/numericInput';

import {
    getInvoicesHistory,
    setInvoicesHistoryFilters
} from 'store/actions/portal/payment/invoices/history.action';

import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { INVOICE_STATE } from 'constants/invoice.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';

/** Aggregated Bets Page Filters Component */

const Filters = ({
    setInvoicesHistoryFilters,
    getInvoicesHistory,
    filters,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const [ paymentMethods, getPaymentMethods ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.SYSTEM_PAYMENT_METHODS,
        autoGet: false
    })

    return (
        <FiltersWrapper
            loadFn={getInvoicesHistory}
            setFiltersFn={setInvoicesHistoryFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: true },
                { name: "modified", time: true }
            ]}
            searchFieldName={["affiliateNameOrId", "date"]}
            onInit={() => {
                getPaymentMethods()
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.invoices.invoiceId')}
                        name="id"
                        className='rt--general-form-item'
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.invoices.invoiceId')}`}
                    >
                        <NumericInput
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.invoices.invoiceId')}`}
                            maxLength={12}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.invoices.paymentMethod')}
                        name="paymentId"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    paymentMethods.map(item => (
                                        { value: item.id, text: item.name }
                                    ))
                                )
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.invoices.paymentMethod')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            search={true}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.invoices.status')}
                        name="state"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') },
                                    { value: INVOICE_STATE.APPROVED, text: t('backoffice.invoices.paid') },
                                    { value: INVOICE_STATE.REJECTED, text: t('backoffice.invoices.canceled') }
                                ]
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.invoices.paymentMethod')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.lastUpdateDate')}
                        name="modified"
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker
                            showTime={true}
                            allowClear={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get invoices history */
    getInvoicesHistory: PropTypes.func,
    /** Redux action to set invoices history filters */
    setInvoicesHistoryFilters: PropTypes.func,
    /** Redux state property, invoices history filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getInvoicesHistory: () => {
            dispatch(getInvoicesHistory())
        },
        setInvoicesHistoryFilters: filters => {
            dispatch(setInvoicesHistoryFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        globalProjectId: state.common.globalProjectId,
        filters: state.invoices.history.filters
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);