import React, { memo, useState } from "react";
import PropTypes from "prop-types";

import { TimePicker } from "antd";

import Icon from "components/common/icon";

import { classNames } from "utils/common";

const TimePickerCell = ({
    value,
    onEdit,
    record,
    className,
}) => {
    const [editing, setEditing] = useState(false);

    const [initialValue, setInitialValue] = useState(value);
    const [currentValue, setCurrentValue] = useState(value);

    /** Fires on Editing cancel
       * @function
       * @memberOf TimePickerCell
    */
    const cancelEditing = () => {
        setEditing(false);
        setCurrentValue(initialValue);
    }

    /** Fires on ok button click
       * @function
       * @memberOf TimePickerCell`
    */
    const handleEdit = () => {
        if (typeof onEdit === 'function') {
            onEdit(currentValue, record);
        }

        setEditing(false);
    }

    const handleTimepickerChange = (timeRange) => {
        setCurrentValue(timeRange);
    };

    const handleTimePickerOpeningChange = (isOpening) => {
        if(isOpening) {
            setEditing(true);
        }
    }

    const handleTimePickerButtonClick = e => {
        const target = e.target.closest(".rt--table-col-timepicker-button");
        const prevElement = target.previousElementSibling;
        prevElement.click()
    }

    return (
        <div
            className={classNames(
                "rt--table-col rt--table-editable-col rt--table-col-timepicker",
                editing && "rt--table-col-timepicker-active",
                "rt--flex rt--align-center rt--mt-1 rt--mb-1",
                className
            )}
        >
            <TimePicker.RangePicker
                onOpenChange={handleTimePickerOpeningChange}
                value={currentValue}
                onChange={handleTimepickerChange}
                suffixIcon={false}
                format='HH:mm'
                allowClear={false}
                placeholder={""}
                inputReadOnly={true}
                className='rt--table-timepicker'
                popupClassName='rt--table-timepicker-dropdown'
                separator={<div className='rt--table-timepicker-separator' />}
                placement="bottomRight"
                bordered={false}
                showNow={false}
            />

            {
                editing ? (
                    <div className="rt--table-col-input-actions rt--flex rt--align-center rt--justify-end">
                        <div className="rt--table-col-input-actions-wrapper rt--table-col-editable-input-actions-ok" onClick={handleEdit}>
                            <Icon name="ok" size={16} />
                        </div>
                        <div className="rt--table-col-input-actions-wrapper rt--table-col-editable-input-actions-cancel" onClick={cancelEditing}>
                            <Icon name="cancel" size={16} />
                        </div>
                    </div>
                ) : (
                    <div
                        className="rt--table-col-timepicker-button rt--ml-8 rt--cursor-pointer"
                        onClick={handleTimePickerButtonClick}
                    >
                        <Icon name="edit" size={16} />
                    </div>
                )
            }
        </div>
    );
}

/** TimePickerCell propTypes
 * PropTypes
*/

TimePickerCell.propTypes = {
    /** Cell value */
    value: PropTypes.array,
    /** Function to call on cell edit */
    onEdit: PropTypes.func,
    /** The record of row */
    record: PropTypes.object,
    /** Class to overwrite some style of edit field */
    className: PropTypes.string,
}
 
export default memo(TimePickerCell);