import {
    SET_TRANSLATIONS_ACTION_BEFORE,
    SET_TRANSLATIONS_ACTION_FINISH,
    SET_TRANSLATION_GROUPS_ACTION_BEFORE,
    SET_TRANSLATION_GROUPS_ACTION_FINISH,
    SET_TRANSLATION_GROUPS,
    SET_TRANSLATIONS,
    UPDATE_TRANSLATION,
    SET_TRANSLATIONS_SAVE_ACTION_BEFORE,
    SET_TRANSLATIONS_SAVE_ACTION_FINISH,
    SET_TRANSLATIONS_SYSTEM_DEFAULT,
    SET_TRANSLATIONS_PUBLISH_ACTION_BEFORE,
    SET_TRANSLATIONS_PUBLISH_ACTION_FINISH,
    SET_TRANSLATIONS_UNPUBLISH_ACTION_BEFORE,
    SET_TRANSLATIONS_UNPUBLISH_ACTION_FINISH
} from "../../actionTypes";

import LocalStorageUtils from "utils/localStorage";


export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_TRANSLATIONS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_TRANSLATIONS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_TRANSLATIONS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true
            }
        case SET_TRANSLATIONS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false
            }
        case SET_TRANSLATION_GROUPS_ACTION_BEFORE:
            return {
                ...state,
                isGroupsLoading: true
            }
        case SET_TRANSLATION_GROUPS_ACTION_FINISH:
            return {
                ...state,
                isGroupsLoading: false
            }
        case SET_TRANSLATIONS:
            return {
                ...state,
                translations: payload.translations
            }
        case UPDATE_TRANSLATION:
            return {
                ...state,
                translations: state.translations.map(t => t.key === payload.key ? { ...t, translations: t.translations.map(tr => tr.languageCode.toLowerCase() === payload.translation.languageCode.toLowerCase() ? payload.translation : tr) } : t)
            }
        case SET_TRANSLATION_GROUPS:
            return {
                ...state,
                groups: {
                    ...state.groups,
                    [payload.translationType]: payload.groups
                }
            }
        case SET_TRANSLATIONS_SYSTEM_DEFAULT:
            LocalStorageUtils.set("systemDefaultTranslation", payload.systemDefault);
            return {
                ...state,
                systemDefault: payload.systemDefault
            }
        case SET_TRANSLATIONS_PUBLISH_ACTION_BEFORE:
            return {
                ...state,
                isPublishing: true
            }
        case SET_TRANSLATIONS_PUBLISH_ACTION_FINISH:
            return {
                ...state,
                isPublishing: false
            }
        case SET_TRANSLATIONS_UNPUBLISH_ACTION_BEFORE:
            return {
                ...state,
                isUnpublishing: true
            }
        case SET_TRANSLATIONS_UNPUBLISH_ACTION_FINISH:
            return {
                ...state,
                isUnpublishing: false
            }
        default:
            return state;
    }
};
