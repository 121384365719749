import i18n from "translations/config";

import { NAME_REGEX } from "constants/regex.constants";

export const getTableColumns = (additionalProps) => {
    const {
        editAdditionalParam,
    } = additionalProps;

    return [
        {
            title: "backoffice.projects.key",
            dataIndex: "key",
            mobileLevel: 1
        },
        {
            title: "backoffice.projects.value",
            dataIndex: "value",
            editFieldClassName: "rt--table-col-editable-width-300",
            editable: true, 
            maxLength: 30,
            regex: NAME_REGEX,
            enableReset: () => false,
            editableLabel: () => i18n.t("backoffice.projects.value"),
            editableTitle: () => i18n.t("backoffice.projects.value"),
            onEdit: (value, record) => {
                editAdditionalParam(
                    record.key,
                    value,
                );
            },
            mobileLevel: 2
        },

    ]
}