import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "react-i18next";

import Modal from "components/common/modal";
import Tooltip from "components/common/tooltip";
import ExpandTableView from '../expandtableView';

import { classNames, isFunction } from 'utils/common';

import { hasMobileDetailsTab } from '../../helpers';

import { TABS, TAB_ORDER } from '../../constants';

const DetailsModal = ({
    columns,
    rowData,
    renderEmpty,
    expandable,
    mobileListView,
    disableFullView,
    uniqueKey,
    onCancel,
    fullViewClassName,
}) => {
    const { t } = useTranslation();

    const [detailsViewTab, setDetailsViewTab] = useState(
        disableFullView ? TAB_ORDER.SECOND_TAB : TAB_ORDER.FIRST_TAB
    );

    const handleTabClick = (tabOrder) => {
        setDetailsViewTab(tabOrder);
    }

    const showTabs = hasMobileDetailsTab({
        disableFullView,
        expandable,
        uniqueKey,
        rowData
    })

    const modalTitle = (
        !disableFullView
            ? t('backoffice.common.fullView')
            : expandable.title ? isFunction(expandable.title) ? expandable.title(rowData) : expandable.title
                : t('backoffice.common.details')
    )

    const showDetailTooltip = columnData => {
        const { dataIndex, render } = columnData;
        const content = isFunction(render) ? render(rowData[dataIndex], rowData) : rowData[dataIndex]
        if (
            columnData.multi ||
            columnData.tooltip === false ||
            columnData.editable ||
            columnData.short ||
            columnData.middle ||
            columnData.copy ||
            columnData.image ||
            content === "-"
        ) {
            return false
        }

        return true;
    }

    return (
        <Modal
            title={modalTitle}
            onCancel={onCancel}
            isLoading={false}
            className="rt--table-modal-details"
        >
            <>
                {
                    showTabs && (
                        <div className="rt--table-details-tabs rt--flex rt--align-center rt--mb-16">
                            {TABS.map(tabOrder => {
                                let tabName = t('backoffice.common.details')

                                if (tabOrder === TAB_ORDER.SECOND_TAB && Boolean(expandable.title)) {
                                    tabName = isFunction(expandable.title) ? expandable.title(rowData) : expandable.title
                                }

                                return (
                                    <div
                                        key={tabOrder}
                                        onClick={(e) => handleTabClick(tabOrder, e)}
                                        className={classNames(
                                            "rt--table-details-tabs-item rt--flex rt--align-center rt--justify-center rt--flex-equal",
                                            detailsViewTab === tabOrder && "rt--table-details-tabs-item-active"
                                        )}
                                    >
                                        <span className="rt--title rt--font-regular rt--font-big">
                                            {tabName}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    )
                }

                {
                    (!disableFullView && detailsViewTab === TAB_ORDER.FIRST_TAB) && (
                        <div
                            className={classNames(
                                "rt--table-card-view",
                                Boolean(fullViewClassName) && fullViewClassName
                            )}
                        >
                            {
                                columns.map(columnData => {
                                    const { dataIndex, title, render } = columnData;

                                    return (
                                        <div
                                            className="rt--table-card-view-row rt--flex rt--align-center rt--justify-between"
                                            key={dataIndex}
                                        >
                                            {
                                                !columnData.image && (
                                                    <b className="rt--title rt--font-normal rt--font-bold rt--table-card-view-row-title">
                                                        {isFunction(title) ? title() : title}
                                                    </b>
                                                )
                                            }

                                            {
                                                showDetailTooltip(columnData) ? (
                                                    <Tooltip
                                                        title={isFunction(render) ? render(rowData[dataIndex], rowData) : rowData[dataIndex]}
                                                        trigger={["click"]}
                                                        enableMobile={true}
                                                        className="rt--tooltip-table"
                                                    >
                                                        <span className="rt--title rt--font-normal rt--table-card-view-row-value">
                                                            {isFunction(render) ? render(rowData[dataIndex], rowData) : rowData[dataIndex]}
                                                        </span>

                                                    </Tooltip>
                                                ) : (
                                                    <span className={"rt--title rt--font-normal rt--table-card-view-row-value" + (columnData.image ? " rt--table-card-view-row-value-image": "")}>
                                                        {isFunction(render) ? render(rowData[dataIndex], rowData) : rowData[dataIndex]}
                                                    </span>
                                                )
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                }

                {
                    (Boolean(expandable) && detailsViewTab === TAB_ORDER.SECOND_TAB) && (
                        <ExpandTableView
                            detailsObj={expandable.details?.(rowData)}
                            renderEmpty={renderEmpty}
                            isLoading={expandable.loading}
                            mobileListView={mobileListView}
                            canShowContetn={true}
                            detailsLoadFn={expandable.detailsLoadFn}
                            rowData={rowData}
                        />
                    )
                }
            </>
        </Modal>
    )
}

/** DetailsModal propTypes
 * PropTypes
*/
DetailsModal.propTypes = {
    columns: PropTypes.array,
    rowData: PropTypes.object,
    renderEmpty: PropTypes.func,
    expandable: PropTypes.object,
    mobileListView: PropTypes.bool,
    disableFullView: PropTypes.bool,
    uniqueKey: PropTypes.string,
    onCancel: PropTypes.func,
    fullViewClassName: PropTypes.string
};


export default DetailsModal;