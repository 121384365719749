import {
    SET_EXPORT_ACTION_BEFORE,
    SET_EXPORT_ACTION_FINISH,
    SET_LANGUAGES_ACTION,
    SET_LANGUAGES_ACTION_BEFORE,
    SET_LANGUAGES_ACTION_FINISH,
    SET_POST_DEPLOYMENT_ACTIONS_ACTION_BEFORE,
    SET_POST_DEPLOYMENT_ACTIONS_ACTION_FINISH
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_POST_DEPLOYMENT_ACTIONS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_POST_DEPLOYMENT_ACTIONS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_EXPORT_ACTION_BEFORE:
            return {
                ...state,
                isDataExporting: true,
            }
        case SET_EXPORT_ACTION_FINISH:
            return {
                ...state,
                isDataExporting: false
            }
        case SET_LANGUAGES_ACTION_BEFORE:
            return {
                ...state,
                languagesData: {
                    ...state.languagesData,
                    isLoading: true,
                }
            }
        case SET_LANGUAGES_ACTION_FINISH:
            return {
                ...state,
                languagesData: {
                    ...state.languagesData,
                    isLoading: false,
                }
            }
        case SET_LANGUAGES_ACTION:
            return {
                ...state,
                languagesData: {
                    ...state.languagesData,
                    languages: payload.languages,
                }
            }
        default:
            return state;
    }
};
