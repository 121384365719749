import React from "react";

import GenerationsComponent from "pages/developer/generations/generations.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const GenerationsRoute = () => {
    return <GenerationsComponent />;
};

export default withPermission(
    withAuth(GenerationsRoute),
    { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
    Paths.PORTAL
);
