export const getTableColumns = additionalProps => {
    const {
        formatAmount
    } = additionalProps
    return [
        {
            title: "backoffice.projects.supportedCurrency",
            dataIndex: "currencyCode",
            mobileLevel: 1,
            alwaysVisible: true,
        },
        {
            title: "backoffice.projects.minThreshold",
            dataIndex: "minThreshold",
            mobileLevel: 2,
            render: (value, record) => formatAmount(value, record.currencyCode),
        },
        {
            title: "backoffice.projects.commission",
            dataIndex: "commission",
            mobileLevel: 3,
            render: value => `${value} %`,
        },
        {
            title: "backoffice.projects.numberOfAffiliates",
            dataIndex: "affiliatesCount",
            mobileLevel: 4,
            isNumeric: true
        }
    ]
} ;
