import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router';

import { Form, Row, Col, Spin } from 'antd';

import InputWithAdditionalActions from 'components/common/InputWithAdditionalActions';


import { saveNewsletterText } from 'store/actions/portal/newsletterCustomize/sections.action';

const FORM_FIELD_NAMES = {
    BUTTON_TEXT: "buttontext",
    BUTTON_LINK: "buttonlink"
}

const ButtonSection = ({
    isLoading,
    isSaving,
    texts,
    saveNewsletterText
}) => {
    const { t } = useTranslation();

    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { setFieldsValue } = formInstance;

    // Set Fields Value When Translations is Loaded
    useEffect(() => {
        setFieldsValue({
            [FORM_FIELD_NAMES.BUTTON_TEXT]: texts[FORM_FIELD_NAMES.BUTTON_TEXT],
            [FORM_FIELD_NAMES.BUTTON_LINK]: texts[FORM_FIELD_NAMES.BUTTON_LINK]
        })
    }, [texts]);

    return (
        <Spin spinning={isLoading || isSaving}>
            <div>
                <Form
                    className="rt--form"
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        [FORM_FIELD_NAMES.BUTTON_TEXT]: texts[FORM_FIELD_NAMES.BUTTON_TEXT]?.text,
                        [FORM_FIELD_NAMES.BUTTON_LINK]: texts[FORM_FIELD_NAMES.BUTTON_LINK]?.text
                    }}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={18}>
                            <Form.Item
                                name={FORM_FIELD_NAMES.BUTTON_TEXT}
                                label={`${t('backoffice.communication.buttonText')} *`}
                                className="rt--general-form-item"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                ]}
                            >
                                <InputWithAdditionalActions
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.communication.buttonText')}`}
                                    isOverwritten={false}
                                    maxLength={100}
                                    initialValue={texts[FORM_FIELD_NAMES.BUTTON_TEXT]}
                                    onConfirm={(value) => {
                                        saveNewsletterText(FORM_FIELD_NAMES.BUTTON_TEXT, value, searchParams.id)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={18}>
                            <Form.Item
                                name={FORM_FIELD_NAMES.BUTTON_LINK}
                                label={`${t('backoffice.communication.url')} *`}
                                className="rt--general-form-item rt--form-item-without-margin"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 100, message: t('backoffice.validation.fieldInvalid') },
                                ]}
                            >
                                <InputWithAdditionalActions
                                    type="inputDropdown"
                                    items={[{ label: "https://", value: "https://" }]}
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.communication.url')}`}
                                    isOverwritten={false}
                                    maxLength={100}
                                    initialValue={texts[FORM_FIELD_NAMES.BUTTON_LINK]}
                                    onConfirm={(value) => {
                                        saveNewsletterText(FORM_FIELD_NAMES.BUTTON_LINK, value, searchParams.id)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Spin >
    );
}

/** ButtonSection propTypes
 * PropTypes
*/
ButtonSection.propTypes = {
    /** Redux state property, is true when loading newsletter configuration */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when newsletter saving */
    isSaving: PropTypes.bool,
    /** Section texts */
    texts: PropTypes.objectOf(PropTypes.string),
    /** Redux action to save newsletter text */
    saveNewsletterText: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    saveNewsletterText: (name, text, id) => {
        dispatch(saveNewsletterText(name, text, id))
    }
});


const mapStateToProps = (state) => {
    return {
        isSaving: state.newsletterCustomize.isSaving,
        isLoading: state.newsletterCustomize.isLoading,
        texts: state.newsletterCustomize.configuration.texts
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonSection);