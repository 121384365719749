import React, { useEffect } from "react";
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";

import Customize from "components/common/customize";

import { publishPromo } from "store/actions/portal/promoCustomize/common.action";
import { getPromo } from "store/actions/portal/projects/promo.action";
import { getPromoConfiguration } from "store/actions/portal/promoCustomize/configuration.action";
import { togglePromoSection, getPromoTranslationGroups } from "store/actions/portal/promoCustomize/sections.action";

import { UNSAVED_FORM_PAGE_TYPE } from "constants/common.constants";
import {
    PROMO_UPDATE_TYPE,
    SECTIONS_DEFAULT_SELECTED_KEY,
    SECTION_PREDEFINED_DATA_BY_SECTION_NAMES,
    STYLES_DEFAULT_SELECTED_KEY,
    STYLES_EDITOR_MENU_ITEMS,
} from "constants/promo.constants";
import Paths from 'constants/path.constants';

import { sendToPromoIframe } from "utils/promo";

import useUnsavedChangesConfirmation from "hooks/useUnsavedChangesConfirmation";
import useCustomizeItems from "hooks/useCustomizeItems";

import promoConfigurationType from "types/promo/configuration.type";


/** Promo Customize Page Component */
const PromoCustomizeComponent = ({
    customizeLanguage,
    configuration,
    getPromoTranslationGroups,
    getPromoConfiguration,
    togglePromoSection,
    promoName,
    publishPromo,
    isPublishing,
    getPromo
}) => {

    const { t } = useTranslation();

    const searchParams = useParams();
    const navigate = useNavigate();

    const navigateWithConfirmation = useUnsavedChangesConfirmation({
        cb: navigate,
        subscribe: [
            UNSAVED_FORM_PAGE_TYPE.CUSTOMIZE_SECTIONS,
            UNSAVED_FORM_PAGE_TYPE.CUSTOMIZE_STYLES
        ]
    })

    const { sectionList, stylesList, unsavedTabs } = useCustomizeItems({
        sections: configuration?.sections,
        onSectionToggle: togglePromoSection,
        stylesData: STYLES_EDITOR_MENU_ITEMS,
        sectionsData: SECTION_PREDEFINED_DATA_BY_SECTION_NAMES
    })

    /** Get Configuration */
    useEffect(() => {
        getPromoConfiguration(searchParams.id, customizeLanguage);
    }, [customizeLanguage])

    /** Get Translation Groups */
    useEffect(() => {
        getPromoTranslationGroups();
    }, []);

    // Get Promo Info
    useEffect(() => {
        // In case of promo info already loaded
        if (Boolean(promoName)) {
            return;
        }

        getPromo()
    }, []);

    return (
        <Customize
            sidebarConfigs={{
                items: [
                    {
                        content: (
                            <Customize.Editor
                                items={stylesList}
                                defaultActiveSectionKey={STYLES_DEFAULT_SELECTED_KEY}
                                unsavedTabs={unsavedTabs.customizeStyles}
                                dependencies={[customizeLanguage]}
                                onSectionChange={sectionType => {
                                    sendToPromoIframe(PROMO_UPDATE_TYPE.SCROLL_TO_SECTION, {
                                        sectionType: sectionType
                                    })
                                }}
                            />
                        ),
                        icon: "pallet",
                        unsaved: unsavedTabs.customizeStyles.length > 0,
                        popupClassName: "rt--customize-sidebar-item-popup-small"
                    },
                    {
                        content: (
                            <Customize.Editor
                                items={sectionList}
                                defaultActiveSectionKey={SECTIONS_DEFAULT_SELECTED_KEY}
                                unsavedTabs={unsavedTabs.customizeSections}
                            />
                        ),
                        icon: "grid",
                        unsaved: unsavedTabs.customizeSections.length > 0,
                        popupClassName: "rt--customize-sidebar-item-popup-big"
                    },
                ]
            }}
            preview={{
                url: `${import.meta.env.SYSTEM_PROMO_DOMAIN}?id=${searchParams.id}`
            }}
            toolbarConfigs={{
                title: promoName,
                buttons: [
                    {
                        className: "rt--button-main rt--button-main-without-icon",
                        loading: false,
                        text: t("backoffice.promo.preview"),
                        onClick: () => window.open(`${import.meta.env.SYSTEM_PROMO_DOMAIN}/${customizeLanguage}?id=${searchParams.id}`, "_blank")
                    },
                    {
                        className: "rt--button-primary",
                        loading: isPublishing,
                        text: t("backoffice.promo.publish"),
                        onClick: publishPromo
                    }
                ],
                backButton: {
                    onClick: () => navigateWithConfirmation(`${Paths.PROJECTS_EDIT}/${searchParams.id}#tab=8`),
                    text: t("backoffice.promo.backToProject")
                }
            }}
        />
    );
};

/** PromoCustomizeComponent propTypes
    * PropTypes
*/
PromoCustomizeComponent.propTypes = {
    /** Redux action to load promo translation groups */
    getPromoTranslationGroups: PropTypes.func,
    /** Redux action to load promo configuration */
    getPromoConfiguration: PropTypes.func,
    /** Redux action to toggle promo sections */
    togglePromoSection: PropTypes.func,
    /** Redux state property, promo configuration */
    configuration: promoConfigurationType,
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
    /** Redux state property, promo name */
    promoName: PropTypes.string,
    /** Redux action to get Promo */
    getPromo: PropTypes.func,
    /** Redux action to set preview device mode */
    publishPromo: PropTypes.func,
    /** Redux state property, preview device mode */
    isPublishing: PropTypes.bool,
}

const mapStateToProps = state => {
    return {
        configuration: state.promoCustomize.configuration,
        customizeLanguage: state.promoCustomize.customizeLanguage,
        promoName: state.projects.edit.promo.name,
        isPublishing: state.promoCustomize.isPublishing,
    }
}

const mapDispatchToProps = dispatch => (
    {
        getPromoTranslationGroups: () => {
            dispatch(getPromoTranslationGroups())
        },
        getPromoConfiguration: (id, customizeLanguage) => {
            dispatch(getPromoConfiguration(id, customizeLanguage))
        },
        togglePromoSection: ({ type, enabled, sectionName }) => {
            dispatch(togglePromoSection({ type, enabled, sectionName }))
        },
        getPromo: () => {
            dispatch(getPromo());
        },
        publishPromo: () => {
            dispatch(publishPromo());
        },
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(PromoCustomizeComponent);
