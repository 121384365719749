import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { 
    setAffiliateGroupsActionBefore, 
    setAffiliateGroupsActionFinished, 
    setAffiliateGroupsSaveActionBefore, 
    setAffiliateGroupsSaveActionFinished 
} from './groups.action';

import { SET_AFFILIATE_GROUP_GENERAL_INFO } from "../../../../actionTypes";

const setAffiliateGroupGeneralInfo = info => ({
    type: SET_AFFILIATE_GROUP_GENERAL_INFO,
    payload: { info }
})

export const getAffiliateGroupGeneralInfo = id => {
    return dispatch => {
        dispatch(setAffiliateGroupsActionBefore());

        return axios({
            url: ApiUrls.GET_AFFILIATE_GROUP_GENERAL_INFO,
            method: Methods.GET,
            params: { id }
        })
        .then(({data : {value: info}}) => {
            dispatch(setAffiliateGroupGeneralInfo(info))
            dispatch(setAffiliateGroupsActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliateGroupsActionFinished());
        })
    }
}

export const saveAffiliateGroupGeneralInfo = group => {
    return dispatch => {
        dispatch(setAffiliateGroupsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_AFFILIATE_GROUP_GENERAL_INFO,
            method: Methods.POST,
            data: {
                ...group
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setAffiliateGroupGeneralInfo(data.value));
            } 
            dispatch(setAffiliateGroupsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliateGroupsSaveActionFinished());
        })
    }
}
