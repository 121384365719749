import React,  { Component } from 'react';

import { Navigate } from 'react-router-dom';

import Paths from 'constants/path.constants';

import { isAuthenticated } from 'utils/auth';

/** Hocs which allows access to page only authorized users 
       * @function
       * @param {Component} WrappedComponent - Component to add functionality 
   */
const withAuth = WrappedComponent => {

    class WithAuth extends Component {
        render () {
            if(!isAuthenticated()){
                const return_url = location.pathname + location.hash;
                let loginPath = `${Paths.LOGIN}?returnURL=${encodeURIComponent(return_url)}`;
                return <Navigate to={loginPath} />
            }
    
            return <WrappedComponent {...this.props} />;
        }
    }

    WithAuth.displayName = `WithAuth(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
    return WithAuth;
}

export default withAuth;