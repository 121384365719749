import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import { getUserGeneralInfo } from "./general.action";

import {
    SET_USERS_ACTION_BEFORE,
    SET_USERS_ACTION_FINISH,
    SET_USERS,
    SET_USERS_SORTING,
    SET_USERS_FILTERS,
    SET_USERS_SAVE_ACTION_BEFORE,
    SET_USERS_SAVE_ACTION_FINISH,
    SET_USER_AVAILABLE_COMPANIES_BEFORE,
    SET_USER_AVAILABLE_COMPANIES_FINISH,
    SET_USER_AVAILABLE_COMPANIES
} from "../../../../actionTypes";

export const setUsersActionBefore = () => ({
    type: SET_USERS_ACTION_BEFORE,
});

export const setUsersActionFinished = () => ({
    type: SET_USERS_ACTION_FINISH,
});

export const setUsersSaveActionBefore = () => ({
    type: SET_USERS_SAVE_ACTION_BEFORE,
});

export const setUsersSaveActionFinished = () => ({
    type: SET_USERS_SAVE_ACTION_FINISH,
});

const setUsers = (users, add) => ({
    type: SET_USERS,
    payload: { users, add },
});

export const setUsersSorting = sorting => ({
    type: SET_USERS_SORTING,
    payload: { sorting },
});

export const setUsersFilters = filters => ({
    type: SET_USERS_FILTERS,
    payload: { filters },
});


export const getUsers = nextPage => {
    return (dispatch, getState) => {
        
        const page= nextPage === "first" ? 1 : nextPage === "next" ? getState().users.sorting.page + 1 : getState().users.sorting.page;
        let params = {
            ...getState().users.sorting,
            ...getState().users.filters,
            page: page
        };

        if(page > 1 && getState().users.total <= (page - 1 ) * getState().users.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().users.total === 0){
            return Promise.resolve();
        }
        
        dispatch(setUsersActionBefore());

        return axios({
            url: ApiUrls.GET_ADMINS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: users } }) => {
                dispatch(setUsers(users, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setUsersSorting({ ...getState().users.sorting, page: page }));
                dispatch(setUsersActionFinished());
            })
            .catch((ex) => {
                dispatch(setUsersActionFinished());
            });
    }
}

export const createUser = (user, onSuccess) => {
    return (dispatch) => {
        dispatch(setUsersSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_ADMIN,
            method: Methods.POST,
            data: {
                ...user
            },
        })
            .then(({ data }) => {
                dispatch(setUsersSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(() => {
                dispatch(setUsersSaveActionFinished());
            });
    };
};

const setUserAvailableCompaniesBefore = () => ({
    type: SET_USER_AVAILABLE_COMPANIES_BEFORE,
});

const setUserAvailableCompaniesFinished = () => ({
    type: SET_USER_AVAILABLE_COMPANIES_FINISH,
});

const setUserAvailableCompanies = companies => ({
    type: SET_USER_AVAILABLE_COMPANIES,
    payload: { companies }
})

export const getUserAvailableCompanies = () => {
    return dispatch => {

        dispatch(setUserAvailableCompaniesBefore());
        return axios({
            url: ApiUrls.GET_USER_AVAILABLE_COMPANIES,
            method: Methods.GET
        })
        .then(({data : {value: companies}}) => {
            dispatch(setUserAvailableCompanies(companies))
            dispatch(setUserAvailableCompaniesFinished());
        })
        .catch((ex) => {
            dispatch(setUserAvailableCompaniesFinished());
        })
    }
}

export const forceLogout = () => {
    return (dispatch, getState) => {
        dispatch(setUsersSaveActionBefore());

        const filters = { ... getState().users.filters };

        Object.keys(filters).map(k => {
            if(filters[k] === "" || filters[k] === undefined){
                delete filters[k];
            }
        })

        return axios({
            url: ApiUrls.FORCE_LOGOUT_ACCOUNT,
            method: Methods.POST,
            data: {
                ... getState().users.sorting,
                ... filters,
                page: getState().users.sorting.page
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getUsers());
            } 
            dispatch(setUsersSaveActionFinished());
        })
        .catch(() => {
            dispatch(setUsersSaveActionFinished());
        })
    }
}

export const changeUserState = d => {
    return dispatch => {
        dispatch(setUsersSaveActionBefore());
        return axios({
            url: ApiUrls.CHANGE_ACCOUNT_STATE,
            method: Methods.POST,
            data: d
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getUserGeneralInfo(d.id));
            } 
            dispatch(setUsersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setUsersSaveActionFinished());
        })
    }
}

export const unlockUser = id => {
    return dispatch => {
        dispatch(setUsersSaveActionBefore());
        return axios({
            url: ApiUrls.UNLOCK_ACCOUNT,
            method: Methods.POST,
            data: { 
                id
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)){
                message.success(data.message);
                dispatch(getUserGeneralInfo(id));
            }
            dispatch(setUsersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setUsersSaveActionFinished());
        })
    }
}


export const deleteUser = (id, onSuccess) => {
    return dispatch => {
        dispatch(setUsersSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_USER,
            method: Methods.DELETE,
            params: { 
                id
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)){
                message.success(data.message);
                onSuccess && onSuccess()
            }
            dispatch(setUsersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setUsersSaveActionFinished());
        })
    }
}