import React from "react";
import PropTypes from 'prop-types';

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Menu } from "antd";

import Dropdown from "components/common/dropdown";
import Flag from "components/common/flag";
import Icon from "components/common/icon";

import LanguageUtils from 'utils/languages';
import { setUrlParam, isMobile } from 'utils/common';

/** Languages Dropdown Component */
const LanguagesDropdown = ({
    languages
}) => {

    const { t } = useTranslation();

    const currentLn = LanguageUtils.getSelectedLanguage();
    console.log(currentLn)

    /** Fires on dropdown item click
     * @function
     * @param {string} value - selected item value
     * @memberOf LanguagesDropdown
    */
    const handleClick = value => {

        setUrlParam('language', value)
        LanguageUtils.setSelectedLanguageToStorage(value, languages);

        window.location.reload();
    }

    return (
        <Dropdown
            className={ !isMobile() ? "rt--languages" : "" }
            disabled={Object.keys(languages).length <= 1}
            title={t("backoffice.languages.language")}
            overlay={(
                <Menu
                    className="rt--languages-list"
                >
                    {
                        Object.keys(languages).map(lang => (
                            <Menu.Item
                                key={lang}
                            >
                                <div 
                                    className={ 
                                        'rt--flex rt--align-center rt--justify-between rt--languages-list-item' + 
                                        ( lang === currentLn ? " rt--languages-list-item-selected" : "") 
                                    }
                                    onClick={() => handleClick(lang)}
                                >
                                    <div className={
                                        "rt--flex rt--align-center rt--justify-between" + 
                                        (isMobile() ? " rt--flex-row-reverse" : " rt--flex-equal")
                                    }>
                                        <span className={'rt--title rt--font-normal rt--font-regular ' + (isMobile() ? "rt--pl-8" : "rt--pr-8")}>
                                            {t(`backoffice.languages.${lang}`)}
                                        </span>
                                        <Flag code={lang} />
                                    </div>
                                    {
                                        lang === currentLn && isMobile() && <Icon name="ok" />
                                    }    
                                </div>
                            </Menu.Item>
                        ))
                    }
                </Menu>
            )}
        >
            <div className={'rt--languages-element rt--flex rt--align-center rt--pl-12 rt--pr-12' + (Object.keys(languages).length <=1 ? " rt--languages-element-disabled" : "")}>
                <Flag code={currentLn} />
            </div>

           

        </Dropdown>
    )
};

/** LanguagesDropdown propTypes
 * PropTypes
*/
LanguagesDropdown.propTypes = {
    /** Redux state property, system languages */
    languages: PropTypes.object
};

const mapStateToProps = (state) => {
    return {
        languages: state.auth.translations.languages,
    };
};

export default connect(
    mapStateToProps,
    null
)(LanguagesDropdown);
