import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Row, Col } from 'antd';

import PasswordRules from "components/common/passwordRules";
import Modal from "components/common/modal";
import Input from 'components/common/input';

import { getPasswordSettings } from "store/actions/auth/passwordSettings.action";
import { changePassword } from 'store/actions/portal/profile/changePassword.action';

import { validatePassword } from "utils/password";

import companyPasswordSettingsType from "types/company/passwordSettings.type";


/** Change Password Page Component */

const ChangePasswordComponent = ({
    changePassword,
    getPasswordSettings,
    passwordSettings,
    isLoading,
    isPasswordChanging,
    onClose
}) => {

    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields, getFieldValue } = formInstance;

    /** Load password settings */
    useEffect(() => {
        getPasswordSettings()
    }, [])


    /** Fires when form submitted
       * @function
       * @memberOf ChangePasswordComponent
   */
    const handleForm = () => {
        validateFields()
            .then((data) => {
                changePassword(data, onClose)
            }).catch(() => { })
    }

    return (
        <Modal
            title={t('backoffice.profile.changePassword')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.save')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isPasswordChanging}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{}}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.profile.currentPassword')} *`}
                            name="currentPassword"
                            validateFirst
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') }
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.profile.currentPassword')}`}

                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.profile.currentPassword')}`}
                                type='Password'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.profile.newPassword')} *`}
                            name="newPassword"
                            validateFirst
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        return validatePassword(value, passwordSettings);
                                    }
                                })
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.profile.newPassword')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.profile.newPassword')}`}
                                type='Password'
                                onChange={() => {
                                    setTimeout(() => {
                                        if (getFieldValue('confirmPassword') !== "")
                                            validateFields(['confirmPassword'])
                                    }, 0)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.profile.confirmPassword')} *`}
                            name="confirmPassword"
                            validateFirst
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (value !== getFieldValue("newPassword")) {
                                            return Promise.reject(t('backoffice.validation.passwordsDoNotMatch'))
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.profile.confirmPassword')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.profile.confirmPassword')}`}
                                type='Password'
                                onPaste={e => e.preventDefault()}
                                onContextMenu={e => e.preventDefault()}
                                onCopy={e => e.preventDefault()}
                            />
                        </Form.Item>
                    </Col>

                    {
                        !isLoading && Object.keys(passwordSettings).length > 0 ?
                            (
                                <Col span={24}>
                                    <Form.Item className='rt--form-item-without-margin'>
                                        <PasswordRules passwordSettings={passwordSettings} />
                                    </Form.Item>
                                </Col>
                            ) : null
                    }


                </Row>
            </Form>
        </Modal>
    )
}

/** ChangePasswordComponent propTypes
    * PropTypes
*/
ChangePasswordComponent.propTypes = {
    /** Redux state property, is true when change password request is in process */
    isPasswordChanging: PropTypes.bool,
    /** Redux state property, is true when loading password settings */
    isLoading: PropTypes.bool,
    /** Redux action to get company password settings */
    getPasswordSettings: PropTypes.func,
    /** Redux action to change password */
    changePassword: PropTypes.func,
    /** Redux state property, the password settings */
    passwordSettings: companyPasswordSettingsType,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getPasswordSettings: () => {
            dispatch(getPasswordSettings());
        },
        changePassword: (data, onSuccess) => {
            dispatch(changePassword(data, onSuccess))
        }
    }
)

const mapStateToProps = state => {
    return {
        isPasswordChanging: state.profile.isPasswordChanging,
        isLoading: state.companies.isLoading,
        passwordSettings: state.auth.passwordSettings.passwordSettings
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ChangePasswordComponent
);