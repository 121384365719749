import { message } from 'antd';

import axios from "axios";

import { isResponseSuccess } from "utils/request";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
    setNewsletterCustomizeSaveActionBefore,
    setNewsletterCustomizeSaveActionFinished,
    saveNewsletterTemplateContent
} from "./common.action";

import { SET_NEWSLETTER_CUSTOMIZE_IMAGE } from "../../../actionTypes";

const setNewsletterImage = (path, name) => ({
    type: SET_NEWSLETTER_CUSTOMIZE_IMAGE,
    payload: { path, name }
})

export const updateNewsletterImage = (path, name, id) => {
    return (dispatch, getState) => {
        dispatch(setNewsletterImage(path, name));
        setTimeout(() => {
            dispatch(saveNewsletterTemplateContent(id, getState().newsletterCustomize.configuration))
        }, 0);
    }
}

export const deleteNewsletterImage = (name, id, onSuccess) => {
    return dispatch => {
        dispatch(setNewsletterCustomizeSaveActionBefore());

        return axios({
            url: ApiUrls.REMOVE_NEWSLETTER_TEMPLATE_FILE,
            method: Methods.DELETE,
            data: {
                name, id
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess();
                    message.success(data.message);
                    dispatch(updateNewsletterImage(null, name, id));
                }
                dispatch(setNewsletterCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setNewsletterCustomizeSaveActionFinished());
            })
    }
}