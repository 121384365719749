import { useEffect, useState } from "react";

import localStorageUtils from 'utils/localStorage';

import { ALL, SELECTED_COLUMNS } from "constants/common.constants";
import { getUser } from "utils/auth";

const init = (userName) => {
    let storageData = localStorageUtils.get(SELECTED_COLUMNS);

    // Data does not exist in storage
    if (!Boolean(storageData)) {
        storageData = {};

        localStorageUtils.set(SELECTED_COLUMNS, storageData);
    }

    // The current user has no data in storage
    if (!Boolean(storageData?.[userName])) {
        storageData[userName] = {};

        localStorageUtils.set(SELECTED_COLUMNS, storageData);
    }

    return storageData;
}

const useIncludedColumns = ({ pageName, defaultIncludedColumns = ALL }) => {
    const userName = getUser()?.userName;

    let selectedColumnsFromStorage = init(userName);

    const [includedColumns, setIncludedColumns] = useState(
        selectedColumnsFromStorage?.[userName]?.[pageName] ?? defaultIncludedColumns
    );

    // Keep included columns in local storage
    useEffect(() => {
        selectedColumnsFromStorage[userName][pageName] = includedColumns;

        localStorageUtils.set(SELECTED_COLUMNS, selectedColumnsFromStorage);
    }, [includedColumns, pageName]);

    const keepAppliedColumns = (includedColumns) => {
        setIncludedColumns(includedColumns);
    }

    return [
        includedColumns,
        keepAppliedColumns,
    ]
}

export default useIncludedColumns;