import React from "react";

import PlayersComponent from "pages/players/players.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const PlayersRoute = () => {
    return <PlayersComponent />;
};

export default withPermission(
    withAuth(PlayersRoute),
    { resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.VIEW },
    Paths.PORTAL
);
