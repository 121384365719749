import { useEffect, useState } from "react";

import { getFinancialLiveBalances } from "../api";

const useFinancialLiveBalances = ( dependencies ) => {

    const [data, setData] = useState({
        isLoading: false,
        wallets: {},
    })

    useEffect(() => {

        setData( prevState => {
            return {
                ...prevState,
                isLoading: true
            }
        })

        const { request, cancelRequest } = getFinancialLiveBalances();

        request
            .then( response => {
                const { data: { value: payload } } = response;
                setData(prevState => {
                    return {
                        ...prevState,
                        isLoading: false,
                        wallets: payload
                    }
                })
            })
            .catch(() => {
                setData( prevState => {
                    return {
                        ...prevState,
                        isLoading: false
                    }
                })
            })

        return () => {
            cancelRequest();
        }
    }, dependencies)

    return data;
}

export default useFinancialLiveBalances;