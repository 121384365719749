import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Row, Col, Spin } from 'antd';

import TabFormDashboardLayout from "components/layouts/tab/form";

import Select from 'components/common/select';
import NumericInput from 'components/common/numericInput';
import TextAreaInput from 'components/common/textAreaInput';
import Input from 'components/common/input';

import { EMAIL_REGEX, TEL_REGEX, DESCRIPTION_REGEX, ADDRESS_REGEX, FIRST_NAME_REGEX, NAME_REGEX } from "constants/regex.constants";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { getCompanyGeneralInfo, saveCompanyGeneralInfo } from "store/actions/portal/companies/general.action";

import companyGeneralInfoType from 'types/company/generalInfo.type';

import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import countries from 'systemData/countries';

/** Company Edit Page General Info Tab Component */
const GeneralInfoComponent = ({
    generalInfo,
    getCompanyGeneralInfo,
    saveCompanyGeneralInfo,
    isSaving,
    isLoading,
    globalCompanyId,
    onTabChange
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);


    /** Get general info */
    useEffect(() => {
        if (globalCompanyId && globalCompanyId === searchParams.id) {
            getCompanyGeneralInfo();
        }
    }, [globalCompanyId, searchParams.id])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            name: generalInfo.name,
            countryCode: generalInfo.countryCode,
            email: generalInfo.email,
            phoneNumber: generalInfo.phoneNumber,
            postalCode: generalInfo.postalCode,
            comments: generalInfo.comments,
            contactName: generalInfo.contactName,
            emergencyTel: generalInfo.emergencyTel,
            fax: generalInfo.fax,
            city: generalInfo.city,
            address: generalInfo.address
        });
    }, [generalInfo])

    /** Check is form changed
       * @function
       * @param {object} formValues - form current values
       * @returns {boolean}
       * @memberOf GeneralInfoComponent
   */
    const formChanged = formValues => {
        return isFormChanged({ ...formValues, id: searchParams.id }, { ...generalInfo })
    }

    /** Fires when form submitted
       * @function
       * @memberOf GeneralInfoComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveCompanyGeneralInfo({
                    ...data
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY }),
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
            id={generalInfo.id}
            longId={generalInfo.longId}
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(changed, formValues) => setIsFormTouched(formChanged({ ...formValues }))}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={`${t('backoffice.companies.name')} *`}
                                name="name"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                    { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst={true}
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.name')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.name')}`}
                                    maxLength={30}
                                    autoComplete="off"
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY })}
                                />

                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.companies.contactName')}
                                name="contactName"
                                rules={[
                                    { pattern: FIRST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.contactName')}`}
                            >
                                <Input
                                    maxLength={48}
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.contactName')}`}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY })}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.companies.email')}
                                name="email"
                                rules={[
                                    { pattern: EMAIL_REGEX, message: t('backoffice.validation.emailFormat') },
                                    { max: 50, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst={true}
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.email')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.email')}`}
                                    maxLength={50}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY })}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.companies.fax')}
                                name="fax"
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.fax')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.fax')}`}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY })}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.companies.tel')}
                                name="phoneNumber"
                                rules={[
                                    { pattern: TEL_REGEX, message: t('backoffice.validation.telFormat') },
                                    { max: 18, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst={true}
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.tel')}`}
                            >
                                <NumericInput
                                    maxLength={18}
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.tel')}`}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY })}
                                    isMobileNumber={true}
                                />
                            </Form.Item>
                        </Col>


                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.companies.emergencyTel')}
                                name="emergencyTel"
                                rules={[
                                    { pattern: TEL_REGEX, message: t('backoffice.validation.telFormat') },
                                    { max: 18, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst={true}
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.emergencyTel')}`}
                            >
                                <NumericInput
                                    maxLength={18}
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.emergencyTel')}`}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY })}
                                    isMobileNumber={true}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={`${t('backoffice.companies.country')} *`}
                                name="countryCode"
                                rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                validateFirst={true}
                                className={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : ""}
                            >
                                <Select
                                    options={
                                        countries.map(item => (
                                            { value: item.iso2, text: t(`backoffice.countries.${item.iso2}`) }
                                        ))
                                    }
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.companies.country')}`}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY })}
                                    search={true}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={`${t('backoffice.companies.city')} *`}
                                name="city"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 30, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.city')}`}
                            >
                                <Input
                                    maxLength={30}
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.city')}`}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY })}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={`${t('backoffice.companies.address')} *`}
                                name="address"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 102, message: t('backoffice.validation.fieldInvalid') },
                                    { pattern: ADDRESS_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.address')}`}
                            >
                                <Input
                                    maxLength={102}
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.address')}`}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY })}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.companies.postalCode')}
                                name="postalCode"
                                rules={[
                                    { max: 20, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst={true}
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.postalCode')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.postalCode')}`}
                                    maxLength={20}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY })}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                className={'rt--form-item-without-margin rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                label={t('backoffice.companies.comments')}
                                name="comments"
                                rules={[
                                    { pattern: DESCRIPTION_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst={true}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.comments')}`}
                            >
                                <TextAreaInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.comments')}`}
                                    maxLength={150}
                                    rows={1}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.MODIFY })} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </TabFormDashboardLayout>
    )
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Redux state property, current editing conpany */
    generalInfo: companyGeneralInfoType,
    /** Redux action to get company General info */
    getCompanyGeneralInfo: PropTypes.func,
    /** Redux action to save company General info */
    saveCompanyGeneralInfo: PropTypes.func,
    /** Redux state property, is true when general info is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when general info is loading */
    isLoading: PropTypes.bool,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getCompanyGeneralInfo: () => {
            dispatch(getCompanyGeneralInfo());
        },

        saveCompanyGeneralInfo: data => {
            dispatch(saveCompanyGeneralInfo(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        generalInfo: state.companies.edit.general,
        isSaving: state.companies.isSaving,
        isLoading: state.companies.isLoading,
        globalCompanyId: state.common.globalCompanyId
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent)