import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Spin, Form, Row, Col, message, Button } from 'antd';

import MainDashboardLayout from "components/layouts/main";

import Upload from "components/common/upload";
import Confirmation from "components/common/confirmation";
import Select from "components/common/select";
import Icon from "components/common/icon";

import { exportTranslations, flushCache, getLanguages, updateTranslations } from "store/actions/portal/developer/postDeploymentActions/postDeploymentActions.action";

import { hasPermission } from "utils/permissions";
import { validateTranslations } from "utils/translation";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const DB_TYPE = {
    DB_0: 0,
    DB_1: 1
}

const MAX_NUMBER_OF_LANGUAGES_TO_SELECT = 10;

/** Post Deployment Actions Page Component */
const PostDeploymentActionsComponent = ({
    isLoading,
    updateTranslations,
    flushCache,
    isLanguagesLoading,
    getLanguages,
    languages,
    isDataExporting,
    exportTranslations,
}) => {

    const { t } = useTranslation();

    const [showFlushCacheConfirmation, setShowFlushCacheConfirmation] = useState(false);
    const [selectedDb, setSelectedDb] = useState(DB_TYPE.DB_0);
    const [formInstance] = Form.useForm();
    const { validateFields, getFieldValue } = formInstance;
    const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW });

    useEffect(() => {
        getLanguages();
    }, []);

    const onUpload = file => {
        const reader = new FileReader();

        validateFields(["languagesForUpload"])
            .then(() => {
                reader.onload = event => {
                    try {
                        const jsonObj = JSON.parse(event.target.result);
                        if (Array.isArray(jsonObj)) {
                            validateTranslations(jsonObj, getFieldValue("languagesForUpload")).
                                then(d => {
                                    updateTranslations(file, getFieldValue("languagesForUpload"));
                                }).
                                catch(ex => {
                                    message.info(ex);
                                })
                        } else {
                            message.info("Invalid JSON File");
                        }
                    } catch (ex) {
                        message.info("Invalid JSON File");
                    }
                };
                reader.readAsText(file);
            })
            .catch(ex => { })
    }

    const handleExportButtonClick = () => {
        validateFields(["languagesForExport"])
            .then((data) => {
                exportTranslations(data.languagesForExport)
            })
            .catch(ex => {})
    }

    return (
        <MainDashboardLayout
            header={
                {
                    breadcrumbs: {
                        items: [
                            { title: t('backoffice.menu.postDeploymentActions') }
                        ]
                    }
                }
            }
        >
            <Spin spinning={isLoading || isDataExporting || isLanguagesLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={
                        {
                            db: selectedDb,
                            languagesForExport: ["EN"],
                            languagesForUpload: ["EN"],
                        }
                    }
                >
                    <h4
                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-bigest'>
                        {t("backoffice.common.translationsExport")}
                    </h4>
                    <Row gutter={[16, 0]}>
                        <Col>
                            <Form.Item
                                name="languagesForExport"
                                rules={[
                                    { required: true, message: t('backoffice.validation.fieldRequired') },
                                    {
                                        validator: (_, value) => {
                                            if (value.length > MAX_NUMBER_OF_LANGUAGES_TO_SELECT) {
                                                return Promise.reject(t("backoffice.validation.mustBeLess").replace("%X%", MAX_NUMBER_OF_LANGUAGES_TO_SELECT))
                                            }

                                            return Promise.resolve()
                                        }
                                    }
                                ]}
                            >
                                <Select
                                    placeholder={t('backoffice.languages.selectLanguage')}
                                    isMultiple={true}
                                    showSelectAllButton={false}
                                    search={true}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                    options={languages.map(item => ({
                                        value: item.key,
                                        text: item.value,
                                    }))}
                                    className={"rt--main-layout-header-actions-dropdown-big"}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={18} lg={12} xl={8} xxl={6}>
                            <Button
                                type="primary"
                                onClick={handleExportButtonClick}
                                disabled={!hasModifyPermission}
                                className="rt--button rt--button-main rt--button-main-without-icon"
                            >
                                {t('backoffice.common.export')}
                            </Button>
                        </Col>
                    </Row>
                    <h4
                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-34 rt--font-bold rt--font-bigest'>
                        {t("backoffice.common.translationsUpdate")}
                    </h4>
                    <Row gutter={[16, 0]}>
                        <Col>
                            <Form.Item
                                name="languagesForUpload"
                                rules={[
                                    { required: true, message: t('backoffice.validation.fieldRequired') },
                                    {
                                        validator: (_, value) => {
                                            if (value.length > MAX_NUMBER_OF_LANGUAGES_TO_SELECT) {
                                                return Promise.reject(t("backoffice.validation.mustBeLess").replace("%X%", MAX_NUMBER_OF_LANGUAGES_TO_SELECT))
                                            }

                                            return Promise.resolve()
                                        }
                                    }
                                ]}
                                validateFirst={true}
                            >
                                <Select
                                    placeholder={t('backoffice.languages.selectLanguage')}
                                    isMultiple={true}
                                    search={true}
                                    showSelectAllButton={false}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                    options={languages.map(item => ({
                                        value: item.key,
                                        text: item.value,
                                    }))}
                                    className={"rt--main-layout-header-actions-dropdown-big"}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={18} lg={12} xl={8} xxl={6}>
                            <Upload
                                onFileReady={onUpload}
                                extensions={["application/json"]}
                                showUploadList={false}
                                disabled={!hasModifyPermission}
                            >
                                <div className="rt--upload rt--flex rt--align-center rt--justify-center">
                                    <div className="rt--flex rt--flex-col rt--align-center rt--justify-center rt--mr-8">
                                        <b className="rt--title rt--font-regular rt--font-normal">{t('backoffice.common.uploadFile')}</b>
                                        <span className="rt--title rt--font-regular rt--font-normal">JSON</span>
                                    </div>
                                    <Icon name="upload" />
                                </div>
                            </Upload>
                        </Col>
                    </Row>
                    <h4
                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-24 rt--font-bold rt--font-bigest'>
                        {t("backoffice.common.cache")}
                    </h4>
                    <Row gutter={[16, 0]} >
                        <Col xs={24} sm={18} lg={12} xl={8} xxl={6}>
                            <div className="rt--flex rt--width-full rt--align-center">
                                <div className="rt--mr-8 rt--flex-equal">
                                    <Form.Item
                                        className={!hasModifyPermission ? "rt--form-item-disabled" : ""}
                                        label={`${t('backoffice.common.select')} DB`}
                                        name="db"
                                    >
                                        <Select
                                            options={[
                                                { value: DB_TYPE.DB_0, text: "DB 0" },
                                                { value: DB_TYPE.DB_1, text: "DB 1" }
                                            ]}
                                            placeholder={`${t('backoffice.common.select')} DB`}
                                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                            onChange={v => setSelectedDb(v)}
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        label=" "
                                        className='rt--general-form-item'
                                    >
                                        <Button
                                            type="danger"
                                            className="rt--button rt--button-danger"
                                            onClick={() => setShowFlushCacheConfirmation(true)}
                                            disabled={!hasModifyPermission}
                                        >
                                            {t('backoffice.common.flush')}
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>

                {
                    showFlushCacheConfirmation && (
                        <Confirmation
                            title={t('backoffice.common.flushCache')}
                            message={t('backoffice.common.flushCacheConfirmationMessage')}
                            onOk={() => flushCache(selectedDb)}
                            onCancel={() => setShowFlushCacheConfirmation(false)}
                            isVisible={true}
                        />)
                }
            </Spin>
        </MainDashboardLayout>

    );
};

/** PostDeploymentActionsComponent propTypes
 * PropTypes
*/
PostDeploymentActionsComponent.propTypes = {
    /** Redux state property, is true when loading Post Deployment Actions */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when loading languages */
    isLanguagesLoading: PropTypes.bool,
    /** Redux state property, is true when data exporting */
    isDataExporting: PropTypes.bool,
    /** Redux state property, represents the array of languages */
    languages: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
    })),
    /** Redux action to update translations */
    updateTranslations: PropTypes.func,
    /** Redux action to get languages */
    getLanguages: PropTypes.func,
    /** Redux action to export translations */
    exportTranslations: PropTypes.func,
    /** Redux action to flush cache */
    flushCache: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
    updateTranslations: (translations, languages) => {
        dispatch(updateTranslations(translations, languages))
    },
    exportTranslations: (languages) => {
        dispatch(exportTranslations(languages))
    },

    flushCache: db => {
        dispatch(flushCache(db))
    },

    getLanguages: () => {
        dispatch(getLanguages())
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.postDeploymentActions.isLoading,
        isDataExporting: state.postDeploymentActions.isDataExporting,
        isLanguagesLoading: state.postDeploymentActions.languagesData.isLoading,
        languages: state.postDeploymentActions.languagesData.languages,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PostDeploymentActionsComponent);
