import i18n from 'i18next';
import { message } from 'antd';

import { RESPONSE_CODES } from "constants/errorCode.constants";

/** Show error notification
     * @function
     * @param {string} msg - error message
     * @param {string} status - error status
     * @param {string} resource - error resource
*/

export const showError = (msg, status, resource) => {
    if(!status) return message.error(msg);
    
    if(!Object.values(RESPONSE_CODES).includes(status)) return message.error(msg);

    const st = Object.keys(RESPONSE_CODES).find(key => RESPONSE_CODES[key] === status);
    let text = i18n.t(`backoffice.errors.${st}`).replace("%resource%", resource && resource !== "None" ? i18n.t(`backoffice.resources.${resource}`) : "");
    
    if(st === "InvalidParameters" && msg){
        text = msg;
    }

    message.error(text);
}