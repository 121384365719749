import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import {
    getPromoSectionTranslations,
    savePromoSectionTranslations,
    resetPromoSectionTranslations,
    getPromoSection,
    savePromoSection,
} from 'store/actions/portal/promoCustomize/sections.action';

import Table from 'components/common/table';
import TableEmptyView from '../tableEmptyView';

import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { TableDataBuilder } from 'utils/promo';

import { PROMO_SECTION_TYPE, SECTION_NAMES } from 'constants/promo.constants';
import { getTableColumns } from './columns';

const Statistics = ({
    isLoading,
    isSaving,
    data,
    getPromoSection,
    savePromoSection,
    getPromoSectionTranslations,
    savePromoSectionTranslations,
    resetPromoSectionTranslations,
    translations,
    customizeLanguage,
}) => {

    const tableData = (
        new TableDataBuilder(data)
            .mergeDataWithTranslations(translations, ["title", "description"])
            .build()
    )

    const handleTableSwitcherChange = (isChecked, record) => {
        const requestBody = {
            key: record.rowKey,
            enabled: isChecked
        }

        savePromoSection(PROMO_SECTION_TYPE.STATISTICS, requestBody);
    }

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            additionalProps: {
                savePromoSectionTranslations,
                resetPromoSectionTranslations
            }
        });
    }, [savePromoSectionTranslations, resetPromoSectionTranslations])

    // Get Statistics Data
    useEffect(() => {
        getPromoSection(PROMO_SECTION_TYPE.STATISTICS);
    }, [customizeLanguage]);

    // Get Statistics Translations
    useEffect(() => {
        getPromoSectionTranslations(
            SECTION_NAMES.STATISTICS,
            PROMO_SECTION_TYPE.STATISTICS
        );
    }, [customizeLanguage]);

    return (
        <Table
            loading={isLoading || isSaving}
            data={tableData}
            columns={mainTableColumns}
            renderEmpty={() => <TableEmptyView />}
            noPagination={true}
            switcher={{
                valueIndex: "enabled",
                dataIndex: "enabled",
                className: "rt--mr-6",
                onChange: handleTableSwitcherChange,
            }}
        />
    );
}


/** Statistics propTypes
 * PropTypes
*/
Statistics.propTypes = {
    data: PropTypes.objectOf(PropTypes.bool),
    translations: PropTypes.objectOf(PropTypes.shape({
        isOverwritten: PropTypes.bool,
        key: PropTypes.string,
        translation: PropTypes.string,
    })),
    /** Redux state property, is true when loading portal customization data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when promo saving */
    isSaving: PropTypes.bool,
    /** Redux action to get promo statistics */
    getPromoSection: PropTypes.func,
    /** Redux action to save promo statistics */
    savePromoSection: PropTypes.func,
    /** Redux action to get promo section translations */
    getPromoSectionTranslations: PropTypes.func,
    /** Redux action to save promo section translations */
    savePromoSectionTranslations: PropTypes.func,
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
    /** Redux action to reset promo section translations */
    resetPromoSectionTranslations: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
    getPromoSection: (sectionType) => {
        dispatch(getPromoSection(sectionType))
    },
    savePromoSection: (sectionType, requestBody) => {
        dispatch(savePromoSection(sectionType, requestBody))
    },
    getPromoSectionTranslations: (sectionName, sectionType) => {
        dispatch(getPromoSectionTranslations(sectionName, sectionType))
    },
    savePromoSectionTranslations: (sectionName, sectionType, key, text) => {
        dispatch(savePromoSectionTranslations(sectionName, sectionType, key, text))
    },
    resetPromoSectionTranslations: (sectionName, sectionType, key, text) => {
        dispatch(resetPromoSectionTranslations(sectionName, sectionType, key, text))
    },
});

const mapStateToProps = (state) => {
    return {
        customizeLanguage: state.promoCustomize.customizeLanguage,
        isLoading: state.promoCustomize.isLoading,
        isSaving: state.promoCustomize.isSaving,
        data: state.promoCustomize.sections[PROMO_SECTION_TYPE.STATISTICS].data,
        translations: state.promoCustomize.sections[PROMO_SECTION_TYPE.STATISTICS].translations,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);