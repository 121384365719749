import Paths from 'constants/path.constants';

import { USER_ROLE } from "constants/user.constants";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

export default () => ([
    { 
        id: '0', 
        icon: 'dashboard', 
        title: 'backoffice.menu.dashboard',
        permission: [
            { resource: PERMISSION_RESOURCE.DASHBOARD_FINANCIAL, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.DASHBOARD_OPERATIONAL, action: PERMISSION_ACTION.VIEW }
        ],
        isProjectRequired: true,
        items: [
            {
                id: '01',
                title: 'backoffice.menu.financial',
                url: Paths.DASHBOARD_FINANCIAL,
                permission: { resource: PERMISSION_RESOURCE.DASHBOARD_FINANCIAL, action: PERMISSION_ACTION.VIEW },
                match: [Paths.DASHBOARD_FINANCIAL],
                isProjectRequired: true
            },
            {
                id: '02',
                title: 'backoffice.menu.operational',
                url: Paths.DASHBOARD_OPERATIONAL,
                permission: { resource: PERMISSION_RESOURCE.DASHBOARD_OPERATIONAL, action: PERMISSION_ACTION.VIEW },
                match: [Paths.DASHBOARD_OPERATIONAL],
                isProjectRequired: true
            },        
        ],
    },
    {
        id: '1',
        icon: 'companies',
        title: 'backoffice.menu.companies',
        url: Paths.COMPANIES,
        permission: { resource: PERMISSION_RESOURCE.COMPANY, action: PERMISSION_ACTION.VIEW },
        match: [Paths.COMPANIES, Paths.COMPANIES_EDIT + "/:id"],
    },
    {
        id: '2',
        icon: 'globe',
        title: 'backoffice.menu.projects',
        url: Paths.PROJECTS,
        permission: { resource: PERMISSION_RESOURCE.PROJECT, action: PERMISSION_ACTION.VIEW },
        match: [Paths.PROJECTS, Paths.PROJECTS_EDIT + "/:id"],
    },
    {
        id: 'm',
        icon: 'affiliate',
        title: 'backoffice.menu.myAccount',
        permission: [
            { resource: PERMISSION_RESOURCE.AFFILIATE, action: PERMISSION_ACTION.VIEW }
        ],
        isProjectRequired: true,
        userRole: [USER_ROLE.AFFILIATE],
        url: Paths.MY_ACCOUNT,
        match: [Paths.MY_ACCOUNT],
    },
    {
        id: '3',
        icon: 'affiliate',
        title: 'backoffice.menu.affiliates',
        permission: [
            { resource: PERMISSION_RESOURCE.AFFILIATE, action: PERMISSION_ACTION.VIEW }
        ],
        isProjectRequired: true,
        userRole: [USER_ROLE.ADMIN, USER_ROLE.AFFILIATE_MANAGER],
        items: [
            {
                id: '31',
                title: 'backoffice.menu.accounts',
                url: Paths.AFFILIATES,
                permission: { resource: PERMISSION_RESOURCE.AFFILIATE, action: PERMISSION_ACTION.VIEW },
                match: [Paths.AFFILIATES, Paths.AFFILIATES_EDIT + "/:id"],
                isProjectRequired: true,
                userRole: [USER_ROLE.ADMIN, USER_ROLE.AFFILIATE_MANAGER]
            },
            {
                id: '32',
                title: 'backoffice.menu.affiliateGroups',
                url: Paths.AFFILIATE_GROUPS,
                permission: { resource: PERMISSION_RESOURCE.AFFILIATE_GROUPS, action: PERMISSION_ACTION.VIEW },
                match: [Paths.AFFILIATE_GROUPS, Paths.AFFILIATE_GROUPS_EDIT + "/:id"],
                isProjectRequired: true
            },
            {
                id: '33',
                title: 'backoffice.menu.affiliateAccess',
                url: Paths.AFFILIATE_ACCESS,
                permission: { resource: PERMISSION_RESOURCE.PROJECT_AFFILIATE_ACCESS_SETTINGS, action: PERMISSION_ACTION.VIEW },
                match: [Paths.AFFILIATE_ACCESS],
                isProjectRequired: true
            }
        ]
    },
    {
        id: '4',
        icon: 'mediatools',
        title: 'backoffice.menu.marketingTools',
        permission: [
            { resource: PERMISSION_RESOURCE.MARKETING_TOOLS_CAMPAIGN, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.MARKETING_TOOLS_MEDIA, action: PERMISSION_ACTION.VIEW }
        ],
        isProjectRequired: true,
        items: [
            {
                id: '41',
                title: 'backoffice.menu.campaigns',
                url: Paths.CAMPAIGNS,
                permission: { resource: PERMISSION_RESOURCE.MARKETING_TOOLS_CAMPAIGN, action: PERMISSION_ACTION.VIEW },
                match: [Paths.CAMPAIGNS, Paths.CAMPAIGNS_EDIT + "/:id"],
                isProjectRequired: true
            },
            {
                id: '42',
                title: 'backoffice.menu.mediaTools',
                url: Paths.MEDIA_TOOLS,
                permission: { resource: PERMISSION_RESOURCE.MARKETING_TOOLS_MEDIA, action: PERMISSION_ACTION.VIEW },
                match: [Paths.MEDIA_TOOLS],
                isProjectRequired: true
            }
        ]
    },
    {
        id: '5',
        icon: 'payments',
        title: 'backoffice.menu.payment',
        permission: [
            { resource: PERMISSION_RESOURCE.PAYMENTS_COMMISSION_CALCULATIONS, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.PAYMENTS_INVOICES, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.PAYMENTS_WALLETS_HISTORY, action: PERMISSION_ACTION.VIEW }
        ],
        isProjectRequired: true,
        items: [
            {
                id: '51',
                title: 'backoffice.menu.approveCommission',
                url: Paths.CALCULATION_APPROVALS,
                match: [Paths.CALCULATION_APPROVALS],
                permission: { resource: PERMISSION_RESOURCE.PAYMENTS_COMMISSION_CALCULATIONS, action: PERMISSION_ACTION.VIEW },
                isProjectRequired: true,
                userRole: [USER_ROLE.ADMIN, USER_ROLE.AFFILIATE_MANAGER]
            },
            {
                id: '52',
                title: 'backoffice.menu.invoices',
                url: Paths.INVOICES,
                match: [Paths.INVOICES],
                permission: { resource: PERMISSION_RESOURCE.PAYMENTS_INVOICES, action: PERMISSION_ACTION.VIEW },
                isProjectRequired: true,
            },
            {
                id: '53',
                title: 'backoffice.menu.balanceHistory',
                url: Paths.BALANCE_HISTORY,
                match: [Paths.BALANCE_HISTORY],
                permission: { resource: PERMISSION_RESOURCE.PAYMENTS_WALLETS_HISTORY, action: PERMISSION_ACTION.VIEW },
                isProjectRequired: true,
            }
        ]
    },
    {
        id: '6',
        icon: 'report',
        title: 'backoffice.menu.reports',
        permission: [
            { resource: PERMISSION_RESOURCE.PAYMENTS_COMMISSION_CALCULATIONS, action: PERMISSION_ACTION.VIEW},
            { resource: PERMISSION_RESOURCE.REPORT_CLICKS, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.REPORT_MEDIA_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.REPORT_AFFILIATE_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.REPORT_PLAYER_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.REPORT_AGGREGATION_HISTORY, action: PERMISSION_ACTION.VIEW}
        ],
        isProjectRequired: true,
        items: [
            {
                id: '61',
                title: 'backoffice.menu.commissionCalculations',
                url: Paths.COMMISSION_CALCULATIONS,
                match: [Paths.COMMISSION_CALCULATIONS],
                permission: { resource: PERMISSION_RESOURCE.PAYMENTS_COMMISSION_CALCULATIONS, action: PERMISSION_ACTION.VIEW },
                isProjectRequired: true,
            },
            {
                id: '62',
                title: 'backoffice.menu.marketing',
                url: Paths.MARKETING_REPORTS,
                match: [Paths.MARKETING_REPORTS],
                permission: [
                    { resource: PERMISSION_RESOURCE.REPORT_CLICKS, action: PERMISSION_ACTION.VIEW },
                    { resource: PERMISSION_RESOURCE.REPORT_MEDIA_PERFORMANCE, action: PERMISSION_ACTION.VIEW }
                ],
                isProjectRequired: true,
            },
            {
                id: '63',
                title: 'backoffice.menu.performance',
                url: Paths.PERFORMANCE_REPORTS,
                match: [Paths.PERFORMANCE_REPORTS],
                permission: [
                    { resource: PERMISSION_RESOURCE.REPORT_AFFILIATE_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
                    { resource: PERMISSION_RESOURCE.REPORT_PLAYER_PERFORMANCE, action: PERMISSION_ACTION.VIEW }
                ],
                isProjectRequired: true,
            },
            {
                id: '64',
                title: 'backoffice.menu.aggregatedData',
                url: Paths.AGGREGATED_DATA,
                match: [Paths.AGGREGATED_DATA],
                permission: { resource: PERMISSION_RESOURCE.REPORT_AGGREGATION_HISTORY, action: PERMISSION_ACTION.VIEW },
                isProjectRequired: true,
            }
        ]
    },
    // { 
    //     id: '6', 
    //     icon: 'player', 
    //     title: 'backoffice.menu.players',
    //     permission: { resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.VIEW },
    //     url: Paths.PLAYERS,
    //     match: [Paths.PLAYERS, Paths.PLAYERS_EDIT + "/:id"],
    //     isProjectRequired: true, 
    // },
    {
        id: '7',
        icon: 'users',
        title: 'backoffice.menu.userManagement',
        permission: [
            { resource: PERMISSION_RESOURCE.ADMIN, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.ACCESS_MANAGER, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.AFFILIATE_MANAGER, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.PERMISSION, action: PERMISSION_ACTION.VIEW }
        ],
        items: [
            {
                id: '71',
                title: 'backoffice.menu.admins',
                url: Paths.USERS,
                permission: { resource: PERMISSION_RESOURCE.ADMIN, action: PERMISSION_ACTION.VIEW },
                match: [Paths.USERS, Paths.USERS_EDIT + "/:id"],
                userRole: [USER_ROLE.ADMIN, USER_ROLE.ACCESS_MANAGER]
            },
            {
                id: '72',
                title: 'backoffice.menu.affiliateManagers',
                url: Paths.AFFILIATE_MANAGERS,
                permission: { resource: PERMISSION_RESOURCE.AFFILIATE_MANAGER, action: PERMISSION_ACTION.VIEW },
                match: [Paths.AFFILIATE_MANAGERS, Paths.AFFILIATE_MANAGERS_EDIT + "/:id"],
                isProjectRequired: true
            },
            {
                id: '73',
                title: 'backoffice.menu.accessManagers',
                url: Paths.ACCESS_MANAGERS,
                permission: { resource: PERMISSION_RESOURCE.ACCESS_MANAGER, action: PERMISSION_ACTION.VIEW },
                match: [Paths.ACCESS_MANAGERS, Paths.ACCESS_MANAGERS_EDIT + "/:id"],
                userRole: [USER_ROLE.ADMIN, USER_ROLE.ACCESS_MANAGER]
            },
            {
                id: '74',
                title: 'backoffice.menu.permissionGroups',
                url: Paths.PERMISSION_GROUPS,
                match: [Paths.PERMISSION_GROUPS, Paths.PERMISSION_GROUPS_EDIT + "/:id"],
                permission: { resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN, USER_ROLE.ACCESS_MANAGER]
            },
            {
                id: '75',
                title: 'backoffice.menu.permissions',
                url: Paths.PERMISSIONS,
                match: [Paths.PERMISSIONS],
                permission: { resource: PERMISSION_RESOURCE.PERMISSION, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: '76',
                title: 'backoffice.menu.requests',
                url: Paths.PERMISSION_REQUESTS,
                match: [Paths.PERMISSION_REQUESTS],
                permission: { resource: PERMISSION_RESOURCE.PERMISSION_REQUESTS, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN, USER_ROLE.ACCESS_MANAGER]
            }
        ]
    },
    {
        id: '8',
        icon: 'communication',
        title: 'backoffice.menu.communications',
        permission: [
            { resource: PERMISSION_RESOURCE.COMMUNICATION, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.COMMUNICATION_SYSTEM_TEMPLATES, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.COMMUNICATION_PROJECT_TEMPLATES, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.COMMUNICATION_NEWSLETTER, action: PERMISSION_ACTION.VIEW }
        ],
        userRole: [USER_ROLE.ADMIN, USER_ROLE.AFFILIATE_MANAGER],
        items: [
            {
                id: '81',
                title: 'backoffice.menu.systemNotifications',
                url: Paths.SYSTEM_NOTIFICATIONS,
                permission: { resource: PERMISSION_RESOURCE.COMMUNICATION, action: PERMISSION_ACTION.VIEW },
                match: [Paths.SYSTEM_NOTIFICATIONS, Paths.SYSTEM_NOTIFICATIONS_EDIT + "/:id"],
                userRole: [USER_ROLE.ADMIN, USER_ROLE.AFFILIATE_MANAGER]
            },
            {
                id: '82',
                title: 'backoffice.menu.newsletter',
                url: Paths.NEWSLETTER,
                permission: { resource: PERMISSION_RESOURCE.COMMUNICATION_NEWSLETTER, action: PERMISSION_ACTION.VIEW },
                match: [Paths.NEWSLETTER],
                userRole: [USER_ROLE.ADMIN, USER_ROLE.AFFILIATE_MANAGER]
            }
        ]
    },
    {
        id: '9',
        icon: 'userlogs',
        title: 'backoffice.menu.userLogs',
        url: Paths.USER_LOGS,
        permission: { resource: PERMISSION_RESOURCE.USER_LOGS, action: PERMISSION_ACTION.VIEW },
        match: [Paths.USER_LOGS],
        userRole: [USER_ROLE.ADMIN]
    },
    {
        id: 'a',
        icon: 'cms',
        title: 'backoffice.menu.cms',
        permission: [
            { resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.VIEW },
        ],
        userRole: [USER_ROLE.ADMIN],
        items: [
            {
                id: 'a1',
                title: 'backoffice.menu.promoTranslations',
                url: Paths.TRANSLATIONS_PROMO,
                match: [Paths.TRANSLATIONS_PROMO],
                permission: { resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'a2',
                title: 'backoffice.menu.boTranslations',
                url: Paths.TRANSLATIONS_BO,
                match: [Paths.TRANSLATIONS_BO],
                permission: { resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            }
        ]
    },
    {
        id: 'b',
        icon: 'settings',
        title: 'backoffice.menu.settings',
        permission: [
            { resource: PERMISSION_RESOURCE.SYSTEM_CURRENCIES, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.SYSTEM_LANGUAGES, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.SYSTEM_PAYMENT_METHODS, action: PERMISSION_ACTION.VIEW }
        ],
        userRole: [USER_ROLE.ADMIN],
        items: [
            {
                id: 'b1',
                title: 'backoffice.menu.currencies',
                url: Paths.SETTINGS_CURRENCIES,
                match: [Paths.SETTINGS_CURRENCIES],
                permission: { resource: PERMISSION_RESOURCE.SYSTEM_CURRENCIES, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'b2',
                title: 'backoffice.menu.languages',
                url: Paths.SETTINGS_LANGUAGES,
                match: [Paths.SETTINGS_LANGUAGES],
                permission: { resource: PERMISSION_RESOURCE.SYSTEM_LANGUAGES, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'b3',
                title: 'backoffice.menu.payments',
                url: Paths.SETTINGS_PAYMENTS,
                match: [Paths.SETTINGS_PAYMENTS],
                permission: { resource: PERMISSION_RESOURCE.SYSTEM_PAYMENT_METHODS, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            }
        ]
    },
    {
        id: 'c',
        icon: 'code',
        title: 'backoffice.menu.developerSpace',
        permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
        userRole: [USER_ROLE.ADMIN],
        items: [
            {
                id: 'c1',
                title: 'backoffice.menu.errors',
                url: Paths.DEVELOPER_ERRORS,
                match: [Paths.DEVELOPER_ERRORS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'c2',
                title: 'backoffice.menu.integrationRequests',
                url: Paths.DEVELOPER_REQUESTS,
                match: [Paths.DEVELOPER_REQUESTS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'c3',
                title: 'backoffice.menu.jobs',
                url: Paths.DEVELOPER_JOBS,
                match: [Paths.DEVELOPER_JOBS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'c4',
                title: 'backoffice.menu.serviceMonitoring',
                url: Paths.DEVELOPER_MONITORING,
                match: [Paths.DEVELOPER_MONITORING],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'c5',
                title: 'backoffice.menu.platformTest',
                url: Paths.DEVELOPER_PLATFORM_TEST,
                match: [Paths.DEVELOPER_PLATFORM_TEST],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'c6',
                title: 'backoffice.menu.generations',
                url: Paths.DEVELOPER_GENERATIONS,
                match: [Paths.DEVELOPER_GENERATIONS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'c7',
                title: 'backoffice.menu.postDeploymentActions',
                url: Paths.DEVELOPER_POST_DEPLOYMENT_ACTIONS,
                match: [Paths.DEVELOPER_POST_DEPLOYMENT_ACTIONS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'c8',
                title: 'backoffice.menu.dbConnections',
                url: Paths.DEVELOPER_DB_CONNECTIONS,
                match: [Paths.DEVELOPER_DB_CONNECTIONS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            }
        ]
    }
])