import React from 'react';

import useLogo from 'hooks/useLogo';

import { LOGO_TYPE } from 'constants/common.constants';

const Header = () => {

    const { logos } = useLogo()

    return (
        <div className='rt--customize-layout-header'>
            <div className='rt--customize-layout-header-logo'>
                <img alt="logo" src={`${import.meta.env.SYSTEM_CDN_URL}/${logos[LOGO_TYPE.LOGO]}`} />
            </div>
        </div>
    )
}

export default Header;