import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Form, Col, Checkbox } from 'antd';

import Select from "components/common/select";
import NumericInput from 'components/common/numericInput';
import Input from 'components/common/input';

import { COMPANY_CONTROL_RULE, COMPANY_CONTROL_TYPE } from "constants/company.constants";

import { toLowerCaseFirstLetter, classNames } from "utils/common";

import { REG_FORM_CONTROL_NAMES } from 'constants/affiliate.constants';

const FormItems = ({
    items,
    formInstance,
    prefix,
    isFieldDisabled,
    getFieldLabel,
    getFieldName,
    getFieldClassName,
    getFieldPopupContainer,
    getFieldRules,
    getSelectFieldData,
    handlers: { setSelectedCurrency, setSelectedPayment, setSelectedContactChannel }
}) => {
    const { t } = useTranslation();
    const { validateFields, getFieldValue } = formInstance;

    return items.map(
        control => {
            const isControlDisabled = isFieldDisabled(control.name);
            const key = prefix ? `${prefix}_${control.name}` : control.name;
            return (
                <Col
                    id={key}
                    xs={24} sm={control.controlType === COMPANY_CONTROL_TYPE.CHECKBOX ? 24 : 12}
                    key={key}
                >
                    {
                        control.controlType === COMPANY_CONTROL_TYPE.INPUT ? (
                            <Form.Item
                                label={`${getFieldLabel(control)} ${control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED ? '*' : ''}`}
                                name={getFieldName(control, prefix)}
                                rules={getFieldRules(control)}
                                className='rt--general-form-item'
                                data-placeholder={`${t('backoffice.common.enter')} ${getFieldLabel(control)}`}
                                validateFirst
                            >
                                {
                                    control.name === REG_FORM_CONTROL_NAMES.TRAFFIC_SOURCE ? (
                                        <Input.Dropdown
                                            placeholder={`${t('backoffice.common.enter')} ${getFieldLabel(control)}`}
                                            items={[
                                                {
                                                    label: "https://",
                                                    value: "https://",
                                                }
                                            ]}
                                        />
                                    ) : control.name === REG_FORM_CONTROL_NAMES.PHONE_NUMBER ? (
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${getFieldLabel(control)}`}
                                            isMobileNumber={true}
                                        />
                                    ) :
                                        (
                                            <Input
                                                placeholder={`${t('backoffice.common.enter')} ${getFieldLabel(control)}`}
                                            />
                                        )
                                }

                            </Form.Item>
                        ) : control.controlType === COMPANY_CONTROL_TYPE.PASSWORD ? (
                            <Form.Item
                                label={`${getFieldLabel(control)} ${control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED ? '*' : ''}`}
                                name={getFieldName(control, prefix)}
                                rules={getFieldRules(control)}
                                className='rt--general-form-item'
                                data-placeholder={`${t('backoffice.common.enter')} ${getFieldLabel(control)}`}
                                validateFirst
                            >
                                <Input
                                    type='Password'
                                    placeholder={`${t('backoffice.common.enter')} ${getFieldLabel(control)}`}
                                    autoComplete="new-password"
                                    onPaste={control.name === REG_FORM_CONTROL_NAMES.CONFIRM_PASSWORD ? e => e.preventDefault() : undefined}
                                    onContextMenu={control.name === REG_FORM_CONTROL_NAMES.CONFIRM_PASSWORD ? e => e.preventDefault() : undefined}
                                    onCopy={control.name === REG_FORM_CONTROL_NAMES.CONFIRM_PASSWORD ? e => e.preventDefault() : undefined}
                                    onChange={control.name === REG_FORM_CONTROL_NAMES.PASSWORD ? () => {
                                        setTimeout(() => {
                                            const fieldName = toLowerCaseFirstLetter(REG_FORM_CONTROL_NAMES.CONFIRM_PASSWORD);

                                            if (getFieldValue(fieldName) !== "")
                                                validateFields([fieldName])
                                        }, 0)
                                    } : undefined}
                                />
                            </Form.Item>
                        ) : control.controlType === COMPANY_CONTROL_TYPE.SELECT ? (
                            <Form.Item
                                label={`${getFieldLabel(control)} ${control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED ? '*' : ''}`}
                                name={getFieldName(control, prefix)}
                                rules={getFieldRules(control)}
                                className={
                                    classNames(
                                        getFieldClassName(control)
                                    )
                                }
                                validateFirst
                            >
                                <Select
                                    options={
                                        (getSelectFieldData(control).isMultiple ? [] : [{ value: null, text: t("backoffice.common.none") }]).concat(getSelectFieldData(control).items)
                                    }
                                    placeholder={isControlDisabled ? "" : `${t('backoffice.common.select')} ${getFieldLabel(control)}`}
                                    search={getSelectFieldData(control).showSearch}
                                    getPopupContainer={() => getFieldPopupContainer(key)}
                                    isMultiple={getSelectFieldData(control).isMultiple}
                                    onChange={value => {
                                        if (control.name === REG_FORM_CONTROL_NAMES.CURRENCY_CODE) {
                                            setSelectedCurrency(value);
                                            setSelectedPayment(null);
                                        } else if (control.name === REG_FORM_CONTROL_NAMES.PAYMENT_METHOD) {
                                            setSelectedPayment(value);
                                        } else if (control.name === REG_FORM_CONTROL_NAMES.CONTACT_CHANNEL) {
                                            setSelectedContactChannel(value);
                                        }
                                    }}
                                    disabled={isControlDisabled}
                                />
                            </Form.Item>
                        ) : control.controlType === COMPANY_CONTROL_TYPE.CHECKBOX ? (
                            <div className="rt--flex-inline rt--align-center rt--form-item-checkbox rt--mb-6">
                                <Form.Item
                                    label={`${getFieldLabel(control)} ${control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED ? '*' : ''}`}
                                    className='rt--form-item-inline rt--form-item-without-margin rt--form-item-checkbox-with-label'
                                    name={getFieldName(control, prefix)}
                                    valuePropName='checked'
                                    rules={getFieldRules(control)}
                                    layout="inline"
                                >
                                    <Checkbox />
                                </Form.Item>
                            </div>
                        ) : null
                    }
                </Col>
            )
        })
};

FormItems.propTypes = {
    /** React Property, get Form Field className */
    getFieldClassName: PropTypes.func,
    /** React Property, get Form Field label */
    getFieldLabel: PropTypes.func,
    /** React Property, get Form Field name */
    getFieldName: PropTypes.func,
    /** React Property, Form Items to render */
    items: PropTypes.array,
    /** React Property, Form Item name prefix */
    prefix: PropTypes.string,
    /** React Property, Form Item enabled/disabled */
    isFieldDisabled: PropTypes.func,
    /** React Property, get Form Field Rules */
    getFieldRules: PropTypes.func,
    /** React Property, get Form Select Field data */
    getSelectFieldData: PropTypes.func,
    /** React Property, get Form Field Popup Container */
    getFieldPopupContainer: PropTypes.func,
    /** React Property, Form Instance */
    formInstance: PropTypes.object,
    /** React Property, Form Fields handlers */
    handlers: PropTypes.shape({
        setSelectedCurrency: PropTypes.func,
        setSelectedPayment: PropTypes.func,
        setSelectedContactChannel: PropTypes.func
    })
}

export default FormItems;