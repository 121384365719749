import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getUser } from 'utils/auth';
import { binaryToFlags } from 'utils/common';

import { USER_ROLE } from 'constants/user.constants';
import { AFFILIATE_ACCESS_TYPE } from 'constants/affiliate.constants';

const selectAffiliateAccessType = state => state.profile.userInfo.affiliateAccessType;

const useAccess = () => {

    const affiliateAccessType = useSelector(selectAffiliateAccessType);

    const hasAccess = useCallback(type => {
        if(getUser()?.role !== USER_ROLE.AFFILIATE || affiliateAccessType === null)
        {
            return true;
        }

        const accesses = binaryToFlags(Object.values(AFFILIATE_ACCESS_TYPE), affiliateAccessType);

        return accesses?.includes(type) 
    }, [affiliateAccessType, getUser])

    return {
        hasAccess
    }
}

export default useAccess;