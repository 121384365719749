//#region react
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region actions
import {
    getAccessManagers,
    setAccessManagersSorting,
    setAccessManagersFilters
} from "store/actions/portal/userManagement/accessManagers/accessManagers.action";
//#endregion

//#region components
import MainDashboardLayout from "components/layouts/main";
import Table from "components/common/table";
import Filters from "./filters.component";
import AccessManagerCreateComponent from "./accessManager-create.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { hasPermission } from "utils/permissions";
import { hasAccessManagerEditPageAccess } from "utils/pageAccess";
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import Paths from 'constants/path.constants';
import ApiUrls from 'constants/api.constants';
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from "constants/permissions.constants";
import { DIGITAIN_COMPANY_ID } from "constants/company.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { ACCESS_MANAGERS } from "constants/pageName.constants";
import { tableColumns } from "./columns";
//#endregion

//#region types
import userType from "types/user/user.type";
import sortingType from "types/common/sorting.type";
//#endregion

const AccessManagersComponent = ({
    getAccessManagers,
    accessManagers,
    isLoading,
    total,
    setAccessManagersSorting,
    setAccessManagersFilters,
    sorting,
    filters,
    globalCompanyId
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isCreatePopupVisible, setIsCreatePopupVisible] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: ACCESS_MANAGERS });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.ACCESS_MANAGER,
        action: PERMISSION_ACTION.EXPORT
    });

    const hasAccessManagerCreatePermission = hasPermission({
        resource: PERMISSION_RESOURCE.ACCESS_MANAGER,
        action: PERMISSION_ACTION.CREATE
    });

    const canNavigateAccessManagerEditPage = hasAccessManagerEditPageAccess()

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setAccessManagersFilters({
            ...filters,
            nameOrId: value
        })
    }

    const showAccessManagerCreatePopup = () => {
        setIsCreatePopupVisible(true);
    }

    const hideAccessManagerCreatePopup = () => {
        setIsCreatePopupVisible(false);
    }

    const handleRightArrowIconClick = (record) => {
        navigate(
            `${Paths.ACCESS_MANAGERS_EDIT}/${record.id}` +
            `?name=${record.userName}`
        )
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            mainColumns: tableColumns,
            constructForExport: true,
            constructForInclude: true,
        });
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (canNavigateAccessManagerEditPage) {
        tableRowActions.push({
            title: t('backoffice.common.deepView'),
            icon: "right",
            onClick: handleRightArrowIconClick
        });
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        search: {
            onSearch: handleSearchChange,
            loadFn: getAccessManagers,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.ACCESS_MANAGER,
            },
            placeholder: t("backoffice.users.userNameOrId"),
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns,
        },
        buttons: [
            {
                icon: "plus",
                type: "primary",
                onClick: showAccessManagerCreatePopup,
                text: t("backoffice.users.createAccessManager"),
                enabled: hasAccessManagerCreatePermission && globalCompanyId === DIGITAIN_COMPANY_ID,
            },
        ],
        breadcrumbs: {
            items: [
                { title: t('backoffice.menu.accessManagers') }
            ]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            columns: columnsForExport,
            tableName: t("backoffice.menu.accessManagers"),
            url: ApiUrls.EXPORT_ACCESS_MANAGERS,
            filters: filters,
        }
    }

    //#endregion

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={accessManagers}
                loadFn={getAccessManagers}
                sorting={sorting}
                setSortingFn={setAccessManagersSorting}
                total={total}
                actions={tableRowActions}
                updateProps={[globalCompanyId]}
            />

            {isCreatePopupVisible && <AccessManagerCreateComponent onClose={hideAccessManagerCreatePopup} />}
        </MainDashboardLayout>
    )
};

/** AccessManagersComponent propTypes
 * PropTypes
*/
AccessManagersComponent.propTypes = {
    /** Redux action to get access managers */
    getAccessManagers: PropTypes.func,
    /** Redux state property, represents the array of access managers  */
    accessManagers: PropTypes.arrayOf(userType),
    /** Redux state property, is true when loading access managers */
    isLoading: PropTypes.bool,
    /** Redux state property, access managers total count */
    total: PropTypes.number,
    /** Redux action to set access managers sorting details */
    setAccessManagersSorting: PropTypes.func,
    /** Redux action to set access managers sorting details */
    setAccessManagersFilters: PropTypes.func,
    /** Redux state property, access managers sorting details */
    sorting: sortingType,
    /** Redux state property, access managers filters */
    filters: PropTypes.object,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
    getAccessManagers: nextPage => {
        dispatch(getAccessManagers(nextPage));
    },
    setAccessManagersSorting: sorting => {
        dispatch(setAccessManagersSorting(sorting));
    },
    setAccessManagersFilters: filters => {
        dispatch(setAccessManagersFilters(filters));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.accessManagers.isLoading,
        accessManagers: state.accessManagers.accessManagers,
        total: state.accessManagers.total,
        sorting: state.accessManagers.sorting,
        filters: state.accessManagers.filters,
        globalCompanyId: state.common.globalCompanyId
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccessManagersComponent);
