import PropTypes from 'prop-types';

import { USER_STATE } from "constants/user.constants";

export default PropTypes.shape({
    id: PropTypes.string,
    userName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    isTesting: PropTypes.bool,
    createdAt: PropTypes.string,
    modifiedAt: PropTypes.string,
    lastLogin: PropTypes.string,
    lastPasswordChange: PropTypes.string,
    lastBlocked: PropTypes.string,
    lastBlockedBy: PropTypes.string,
    avatarId: PropTypes.string,
    state: PropTypes.oneOf(Object.values(USER_STATE)),
    mainCompany: PropTypes.string,
    companies: PropTypes.arrayOf(PropTypes.string),
    projects: PropTypes.arrayOf(PropTypes.string),
    permissionGroups: PropTypes.arrayOf(PropTypes.string),
    permissionCount: PropTypes.number
})