import PropTypes from 'prop-types';

import { JOB_STATE, JOB_GROUP } from 'constants/job.constants';

export default PropTypes.shape({
    id: PropTypes.string,
    group: PropTypes.oneOf(Object.values(JOB_GROUP)),
    name: PropTypes.string,
    state: PropTypes.oneOf(Object.values(JOB_STATE)),
    startTime: PropTypes.string,
    nextFireTime: PropTypes.string,
    message: PropTypes.string,
    errorId: PropTypes.string,
    elapsedMilliseconds: PropTypes.number,
    serverName: PropTypes.string,
    enabled: PropTypes.bool,
    cronExpression: PropTypes.string,
    cronDescription: PropTypes.string,
    executionHistory: PropTypes.arrayOf(PropTypes.shape({
        state: PropTypes.oneOf(Object.values(JOB_STATE)),
        startTime: PropTypes.string,
        nextFireTime: PropTypes.string,
        message: PropTypes.string,
        errorId: PropTypes.string,
        elapsedMilliseconds: PropTypes.number,
        serverName: PropTypes.string,
    }))
})