import PropTypes from 'prop-types';

import { COMPANY_STATE } from "constants/company.constants";

export default PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    state: PropTypes.oneOf(Object.values(COMPANY_STATE)),
    email: PropTypes.string,
    tel: PropTypes.string,
    postalCode: PropTypes.string,
    createdAt: PropTypes.string,
    modifiedAt: PropTypes.string
})