//#region React
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
//#endregion

import { Form, Col, Row } from 'antd';

import useAutosuggestion from 'hooks/useAutosuggestion';

//#region Components
import Modal from "components/common/modal";
import Select from 'components/common/select';
//#endregion

//#region Actions
import { addProjectPaymentMethod } from "store/actions/portal/projects/paymentMethods.action";
//#endregion

//#region Utils
import { hasPermission } from 'utils/permissions';
import { addQueryStringParamsToUrl } from 'utils/queryString';
//#endregion

//#region Constants
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { POPUP_SIZE } from "constants/common.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
//#endregion

//#region types
import projectPaymentMethodType from 'types/project/paymentMethod.type';
//#endregion

const FORM_FIELD_NAMES = {
    ID: "id"
}

/** Add Payment to the Project Popup Component */
const AddPayment = ({
    paymentMethods,
    addProjectPaymentMethod,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;
    const { search, pathname, hash } = useLocation();
    const navigate = useNavigate();

    const hasModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PROJECT_PAYMENT_METHODS,
        action: PERMISSION_ACTION.MODIFY
    });

    const [ availableMethods ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.SYSTEM_PAYMENT_METHODS,
    })
    
    const projectGroupedPaymentMethods = paymentMethods.reduce((acc, paymentMethod) => {
        acc[paymentMethod.id] = paymentMethod.id;
        return acc;
    }, {});

    const methodsOptions = availableMethods.filter(
        (availableMethod) => !projectGroupedPaymentMethods[availableMethod.id]).map(
            ({ name, id }) => ({ text: name, value: id }));

    const handleForm = () => {
        validateFields()
            .then((data) => {
                addProjectPaymentMethod(
                    data,
                    payment => {
                        if (hasModifyPermission) {
                            navigate(
                                addQueryStringParamsToUrl(
                                    pathname, 
                                    search, 
                                    hash, 
                                    {
                                        paymentId: payment.id,
                                        paymentName: payment.name
                                    }
                                )
                            )
                        } else {
                            onClose();
                        }
                    });
            }).catch(() => { })
    };

    return (
        <Modal
            title={t('backoffice.projects.addPayment')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            disableOkButton={methodsOptions.length === 0}
            size={POPUP_SIZE.MIDDLE}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.projects.paymentMethod')} *`}
                            name={FORM_FIELD_NAMES.ID}
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') }
                            ]}
                            className='rt--form-item-without-margin'
                            validateFirst
                        >
                            <Select
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.paymentMethod')}`}
                                options={methodsOptions}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

AddPayment.propTypes = {
    /** Redux state, represents the Payments of current editing Project */
    paymentMethods: PropTypes.arrayOf(projectPaymentMethodType),
    /** Redux action to add Payment to the Project */
    addProjectPaymentMethod: PropTypes.func,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        addProjectPaymentMethod: (id, onSuccess) => {
            dispatch(addProjectPaymentMethod(id, onSuccess));
        }
    }
)

const mapStateToProps = state => {
    return {
        paymentMethods: state.projects.edit.paymentMethods.paymentMethods
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPayment);