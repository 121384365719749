import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import { setInvoicesActionBefore, setInvoicesActionFinished } from "./invoices.action";

import {
    SET_INVOICES_HISTORY,
    SET_INVOICES_HISTORY_SORTING,
    SET_INVOICES_HISTORY_FILTERS
} from "../../../../actionTypes";

const setInvoicesHistory = (invoices, add) => ({
    type: SET_INVOICES_HISTORY,
    payload: { invoices, add },
});

export const setInvoicesHistorySorting = sorting => ({
    type: SET_INVOICES_HISTORY_SORTING,
    payload: { sorting },
});

export const setInvoicesHistoryFilters = filters => ({
    type: SET_INVOICES_HISTORY_FILTERS,
    payload: { filters },
});


export const getInvoicesHistory = nextPage => {
    return (dispatch, getState) => {

        const invoices = getState().invoices.history;

        const filters = { ... invoices.filters };
        const sorting = { ... invoices.sorting };
        const total = invoices.total;
        const page = nextPage === "first" ? 1 : nextPage === "next" ? sorting.page + 1 : sorting.page;
        
        let params = {
            ...sorting,
            ...filters,
            page: page
        };

        if(page > 1 && total <= (page - 1 ) * sorting.limit){
            return Promise.resolve();
        }
        
        dispatch(setInvoicesActionBefore());
        return axios({
            url: ApiUrls.GET_INVOICES_HISTORY,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: invoices } }) => {
                dispatch(setInvoicesHistory(invoices, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setInvoicesHistorySorting({ ...sorting, page: page }));
                dispatch(setInvoicesActionFinished());
            })
            .catch((ex) => {
                dispatch(setInvoicesActionFinished());
            });
    }
}