import React from "react";

import SystemPaymentsEdit from "pages/settings/systemPayments/edit/systemPayments-edit.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const SystemPaymentsEditRoute = () => {
    return <SystemPaymentsEdit />;
};

export default withPermission(
    withAuth(SystemPaymentsEditRoute),
    {
        resource: PERMISSION_RESOURCE.SYSTEM_PAYMENT_METHODS,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.PORTAL
);
