import { combineReducers } from 'redux';

import AuthReducer from './auth/auth.reducer';
import RegisterReducer from './auth/register.reducer';
import CommonReducer from './portal/common.reducer';
import AccountReducer from './portal/account.reducer';
import CompaniesReducer from './portal/companies.reducer';
import ProjectsReducer from './portal/projects.reducer';
import AffiliatesReducer from './portal/affiliates.reducer';
import AffiliateGroupsReducer from './portal/affiliateGroups.reducer';
import AffiliateAccessReducer from './portal/affiliateAccess.reducer';
import WalletReducer from './portal/wallet.reducer';
import CommissionPlansReducer from './portal/commissionPlans.reducer';
import InvoicesReducer from './portal/invoices.reducer';
import BalanceHistoryReducer from './portal/balanceHistory.reducer';
import PlayersReducer from './portal/players.reducer';
import CalculationApprovalsReducer from './portal/calculationApprovals.reducer';
import CommissionCalculationsReducer from './portal/commissionCalculations.reducer';
import MarketingReportsReducer from './portal/marketingReports.reducer';
import PerformanceReportsReducer from './portal/performanceReports.reducer';
import AggregatedDataReducer from './portal/aggregatedData.reducer';
import CampaignsReducer from './portal/campaigns.reducer';
import MediaToolsReducer from './portal/mediaTools.reducer';
import UsersReducer from './portal/users.reducer';
import SystemNotificationsReducer from './portal/systemNotifications.reducer';
import NewsletterReducer from './portal/newsletter.reducer';
import AccessManagersReducer from './portal/accessManagers.reducer';
import AffiliateManagersReducer from './portal/affiliateManagers.reducer';
import PermissionsReducer from './portal/permissions.reducer';
import PermissionGroupsReducer from './portal/permissionGroups.reducer';
import PermissionRequestsReducer from './portal/permissionRequests.reducer';
import ProfileReducer from './portal/profile.reducer';
import SessionsReducer from './portal/sessions.reducer';
import SystemCurrenciesReducer from './portal/systemCurrencies.reducer';
import TranslationsReducer from './portal/translations.reducer';
import SystemLanguagesReducer from './portal/systemLanguages.reducer';
import SystemPaymentsReducer from './portal/systemPayments.reducer';
import NotificationsReducer from './portal/notifications.reducer';
import UserLogsReducer from './portal/userLogs.reducer';
import ErrorsReducer from './portal/errors.reducer';
import RequestsReducer from './portal/requests.reducer';
import GenerationsReducer from './portal/generations.reducer';
import DBConnectionsReducer from './portal/dbConnections.reducer';
import JobsReducer from './portal/jobs.reducer';
import MonitoringReducer from './portal/monitoring.reducer';
import PostDeploymentActionsReducer from './portal/postDeploymentActions.reducer';
import PlatformsReducer from './portal/platforms.reducer';
import PromoCustomizeReducer from './portal/promoCustomize.reducer';
import NewsletterCustomizeReducer from './portal/newsletterCustomize.reducer';
import SystemReducer from './system/system.reducer';

export default () => combineReducers({
    auth: AuthReducer,
    register: RegisterReducer,
    common: CommonReducer,
    account: AccountReducer,
    companies: CompaniesReducer,
    projects: ProjectsReducer,
    affiliates: AffiliatesReducer,
    affiliateGroups: AffiliateGroupsReducer,
    affiliateAccess: AffiliateAccessReducer,
    wallet: WalletReducer,
    commissionPlans: CommissionPlansReducer,
    invoices: InvoicesReducer,
    balanceHistory: BalanceHistoryReducer,
    players: PlayersReducer,
    calculationApprovals: CalculationApprovalsReducer,
    commissionCalculations: CommissionCalculationsReducer,
    marketingReports: MarketingReportsReducer,
    performanceReports: PerformanceReportsReducer,
    aggregatedData: AggregatedDataReducer,
    campaigns: CampaignsReducer,
    mediaTools: MediaToolsReducer,
    users: UsersReducer,
    systemNotifications: SystemNotificationsReducer,
    newsletter: NewsletterReducer,
    accessManagers: AccessManagersReducer,
    affiliateManagers: AffiliateManagersReducer,
    permissions: PermissionsReducer,
    permissionGroups: PermissionGroupsReducer,
    permissionRequests: PermissionRequestsReducer,
    profile: ProfileReducer,
    sessions: SessionsReducer,
    translations: TranslationsReducer,
    systemCurrencies: SystemCurrenciesReducer,
    systemLanguages: SystemLanguagesReducer,
    systemPayments: SystemPaymentsReducer,
    platforms: PlatformsReducer,
    notifications: NotificationsReducer,
    userLogs: UserLogsReducer,
    errors: ErrorsReducer,
    requests: RequestsReducer,
    generations: GenerationsReducer,
    dbConnections: DBConnectionsReducer,
    jobs: JobsReducer,
    monitoring: MonitoringReducer,
    postDeploymentActions: PostDeploymentActionsReducer,
    system: SystemReducer,
    promoCustomize: PromoCustomizeReducer,
    newsletterCustomize: NewsletterCustomizeReducer
});