import XRegExp from "xregexp";

import i18n from 'translations/config';

export const validateByRegexp = (value, regexp) => {
    if (regexp) {
        if(value){
            try {
                const regex = new XRegExp(regexp);
                if(!regex.test(value.trim())){
                    return Promise.reject(i18n.t('backoffice.validation.fieldInvalid'))
                } 
            } catch (ex) {
                return Promise.reject(i18n.t('backoffice.validation.fieldInvalid'))
            }
        }
        
    }

    return Promise.resolve();
}


/** Validate function for regex
     * @function
     * @param {string} value - value of field
 */
 export const validateRegex = value => {

    if (value) {
        try {
            new XRegExp(value);
        } catch (ex) {
            return Promise.reject(i18n.t('backoffice.validation.fieldInvalid'))
        }
    }

    return Promise.resolve();
}