import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import { setPerformanceReportsActionBefore, setPerformanceReportsActionFinished } from "./performance.action";

import {
    SET_PLAYER_REPORT,
    SET_PLAYER_REPORT_SORTING,
    SET_PLAYER_REPORT_FILTERS,
    SET_PLAYER_REPORT_TOTALS
} from "../../../../actionTypes";

const setPlayerReport = (report, add) => ({
    type: SET_PLAYER_REPORT,
    payload: { report, add },
});

export const setPlayerReportSorting = sorting => ({
    type: SET_PLAYER_REPORT_SORTING,
    payload: { sorting },
});

export const setPlayerReportFilters = (filters, keepPage) => ({
    type: SET_PLAYER_REPORT_FILTERS,
    payload: { filters, keepPage },
});


export const getPlayerReport = nextPage => {
    return (dispatch, getState) => {

        const playerReport = getState().performanceReports.playerReport;
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? playerReport.sorting.page + 1 : playerReport.sorting.page;
        
        let params = {
            ...playerReport.sorting,
            ...playerReport.filters,
            page: page
        };

        if(page > 1 && playerReport.total <= (page - 1 ) * playerReport.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && playerReport.total === 0){
            return Promise.resolve();
        }

        dispatch(setPerformanceReportsActionBefore());

        return axios({
            url: ApiUrls.GET_PLAYER_PERFORMANCE_REPORT,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: report } }) => {
                dispatch(setPlayerReport(report, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setPlayerReportSorting({ ...playerReport.sorting, page: page }));
                dispatch(setPerformanceReportsActionFinished());
            })
            .catch(() => {
                dispatch(setPerformanceReportsActionFinished());
            });
    }
}

const setPlayerReportTotals = totals => ({
    type: SET_PLAYER_REPORT_TOTALS,
    payload: { totals },
});

export const getPlayerReportTotals = () => {
    return (dispatch, getState) => {

        const playerReport = getState().performanceReports.playerReport;

        const filters = { ... playerReport.filters };
        
        let params = {
            ...filters
        };
        
        return axios({
            url: ApiUrls.GET_PLAYER_PERFORMANCE_REPORT_TOTALS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: totals } }) => {
                dispatch(setPlayerReportTotals(totals));
            })
            .catch(ex => {
                console.log(ex)
            });
    }
}