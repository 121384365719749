import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import {
    SET_AFFILIATE_MANAGERS_ACTION_BEFORE,
    SET_AFFILIATE_MANAGERS_ACTION_FINISH,
    SET_AFFILIATE_MANAGERS,
    SET_AFFILIATE_MANAGERS_SORTING,
    SET_AFFILIATE_MANAGERS_FILTERS,
    SET_AFFILIATE_MANAGERS_SAVE_ACTION_BEFORE,
    SET_AFFILIATE_MANAGERS_SAVE_ACTION_FINISH
} from "../../../../actionTypes"

import { getAffiliateManagerGeneralInfo } from './general.action';

export const setAffiliateManagersActionBefore = () => ({
    type: SET_AFFILIATE_MANAGERS_ACTION_BEFORE,
});

export const setAffiliateManagersActionFinished = () => ({
    type: SET_AFFILIATE_MANAGERS_ACTION_FINISH,
});

export const setAffiliateManagersSaveActionBefore = () => ({
    type: SET_AFFILIATE_MANAGERS_SAVE_ACTION_BEFORE,
});

export const setAffiliateManagersSaveActionFinished = () => ({
    type: SET_AFFILIATE_MANAGERS_SAVE_ACTION_FINISH,
});

const setAffiliateManagers = (affiliateManagers, add) => ({
    type: SET_AFFILIATE_MANAGERS,
    payload: { affiliateManagers, add },
});

export const setAffiliateManagersSorting = sorting => ({
    type: SET_AFFILIATE_MANAGERS_SORTING,
    payload: { sorting },
});

export const setAffiliateManagersFilters = filters => ({
    type: SET_AFFILIATE_MANAGERS_FILTERS,
    payload: { filters },
});


export const getAffiliateManagers = nextPage => {
    return (dispatch, getState) => {

        const affiliateManagersData = getState().affiliateManagers;
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? affiliateManagersData.sorting.page + 1 : affiliateManagersData.sorting.page;
        let params = {
            ...affiliateManagersData.sorting,
            ...affiliateManagersData.filters,
            page: page
        };

        if(page > 1 && affiliateManagersData.total <= (page - 1 ) * affiliateManagersData.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && affiliateManagersData.total === 0){
            return Promise.resolve();
        }
        
        dispatch(setAffiliateManagersActionBefore());

        return axios({
            url: ApiUrls.GET_AFFILIATE_MANAGERS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: affiliateManagers } }) => {
                dispatch(setAffiliateManagers(affiliateManagers, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setAffiliateManagersSorting({ ...affiliateManagersData.sorting, page: page }));
                dispatch(setAffiliateManagersActionFinished());
            })
            .catch((ex) => {
                dispatch(setAffiliateManagersActionFinished());
            });
    }
}

export const createAffiliateManager = (affiliateManager, onSuccess) => {
    return (dispatch) => {
        dispatch(setAffiliateManagersSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_AFFILIATE_MANAGER,
            method: Methods.POST,
            data: {
                ...affiliateManager,
                securityLevel: 1
            },
        })
            .then(({ data }) => {
                dispatch(setAffiliateManagersSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(() => {
                dispatch(setAffiliateManagersSaveActionFinished());
            });
    };
};

export const changeAffiliateManagerState = d => {
    return dispatch => {
        dispatch(setAffiliateManagersSaveActionBefore());
        return axios({
            url: ApiUrls.CHANGE_ACCOUNT_STATE,
            method: Methods.POST,
            data: d
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getAffiliateManagerGeneralInfo(d.id));
            } 
            dispatch(setAffiliateManagersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliateManagersSaveActionFinished());
        })
    }
}

export const unlockAffiliateManager = id => {
    return dispatch => {
        dispatch(setAffiliateManagersSaveActionBefore());
        return axios({
            url: ApiUrls.UNLOCK_ACCOUNT,
            method: Methods.POST,
            data: { 
                id
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)){
                message.success(data.message);
                dispatch(getAffiliateManagerGeneralInfo(id));
            }
            dispatch(setAffiliateManagersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliateManagersSaveActionFinished());
        })
    }
}

export const forceLogout = () => {
    return (dispatch, getState) => {
        dispatch(setAffiliateManagersSaveActionBefore());

        const filters = { ... getState().affiliateManagers.filters };
        const sorting = { ... getState().affiliateManagers.sorting };

        Object.keys(filters).map(k => {
            if(filters[k] === "" || filters[k] === undefined){
                delete filters[k];
            }
        })

        return axios({
            url: ApiUrls.FORCE_LOGOUT_AFFILIATE_MANAGERS,
            method: Methods.POST,
            data: {
                ... sorting,
                ... filters,
                page: sorting.page
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getAffiliateManagers());
            } 
            dispatch(setAffiliateManagersSaveActionFinished());
        })
        .catch(() => {
            dispatch(setAffiliateManagersSaveActionFinished());
        })
    }
}