export const COPY_SIZES = {
    MIDDLE: "middle",
    DEFAULT: "default",
}

export const TEXT_SIZES = {
    SHORT: "short",
    MIDDLE: "middle",
    DEFAULT: "default"
}

export const PAGINATION_ARROW_NAMES = {
    PREV: "prev",
    NEXT: "next",
}

export const ROW_CHOOSER_TYPES = {
    RADIO: "radio",
    CHECKBOX: "checkbox",
    ICON: "icon",
}

export const EXPANDABLE_TYPES = {
    TABLE: "table",
    CONTENT: "content",
    BOTH: "both"
}

export const DEFAULT_UNIQUE_PROP = "id";

export const PAGE_SIZE_OPTIONS = [10, 30, 50];

export const MiN_PAGE_SIZE = PAGE_SIZE_OPTIONS[0];

export const ACTIONS_MAX_NUMBER_WITH_DETAILS = 2;
export const ACTIONS_MAX_NUMBER_WITHOUT_DETAILS = 3;

export const DEFAULT_CONFIRMATION_MODAL_DATA = {
    isVisible: false,
    title: "",
    message: "",
    rowData: {},
    onOk: Function.prototype
}

export const TAB_ORDER = {
    FIRST_TAB: 1,
    SECOND_TAB: 2
}

export const TABS = [TAB_ORDER.FIRST_TAB, TAB_ORDER.SECOND_TAB]