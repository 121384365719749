import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import Select from 'components/common/select';
import DateRangePicker from 'components/common/dateRangePicker';

import FiltersWrapper from "components/common/filters";

import { getAffiliateGroups, setAffiliateGroupsFilters } from "store/actions/portal/affiliates/groups/groups.action";
import { AFFILIATE_GROUP_TYPE } from 'constants/affiliate.constants';

/** Affiliates Groups Page Filters Component */
const Filters = ({
    setAffiliateGroupsFilters,
    getAffiliateGroups,
    filters,
    globalProjectId
}) => {
    const { t } = useTranslation();

    return (
        <FiltersWrapper
            loadFn={getAffiliateGroups}
            updateProps={[globalProjectId]}
            setFiltersFn={setAffiliateGroupsFilters}
            filters={filters}
            datePickerFields={[
                { name: "date", time: false },
                { name: "modified", time: false }
            ]}
            searchFieldName={["nameOrId", "date"]}
        >
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.affiliates.groupType')}
                        name="groupType"
                    >
                        <Select
                            options={[
                                { value: "", text: t('backoffice.common.all') },
                                { value: AFFILIATE_GROUP_TYPE.STATIC, text: t('backoffice.affiliates.static') },
                                { value: AFFILIATE_GROUP_TYPE.DYNAMIC, text: t('backoffice.affiliates.dynamic') },
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.affiliates.groupType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.lastUpdateDate')}
                        name="modified"
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get affiliate groups */
    getAffiliateGroups: PropTypes.func,
    /** Redux action to set affiliate groups filters */
    setAffiliateGroupsFilters: PropTypes.func,
    /** Redux state property, affiliate groups filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getAffiliateGroups: () => {
            dispatch(getAffiliateGroups())
        },
        setAffiliateGroupsFilters: filters => {
            dispatch(setAffiliateGroupsFilters(filters))
        },
    }
)

const mapStateToProps = state => {
    return {
        filters: state.affiliateGroups.filters,
        globalProjectId: state.common.globalProjectId,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);