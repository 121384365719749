import { COMPANY_STATE } from "constants/company.constants";
import { USER_STATE } from "constants/user.constants";
import { JOB_STATE } from "constants/job.constants";
import { PLAYER_STATE } from "constants/player.constants";
import { COMMISSION_CALCULATION_STATUS, COMMISSION_PLAN_STATE } from "constants/commission.constants";
import { INVOICE_STATE } from "constants/invoice.constants";
import { MEDIA_STATE, CAMPAIGN_STATE } from "constants/campaign.constants";
import { STATUS_TYPES } from "constants/status.constants";
import { MEDIA_CLICK_STATE } from "constants/reports.constants";
import { NEWSLETTER_STATUS } from "constants/newsletter.constants";
import { DB_CONNECTION_STATE } from "constants/common.constants";
import { AFFILIATE_GROUP_TYPE } from "constants/affiliate.constants";
import { PROJECT_DOMAIN_STATUS } from "constants/project.constants";

export const STATUS_RESOURCES_BY_TYPE = {
    //#region - COMPANY
    [STATUS_TYPES.COMPANY]: {
        color: {
            [COMPANY_STATE.ACTIVE]: "green",
            [COMPANY_STATE.INACTIVE]: "red",
        },
        translationSource: {
            [COMPANY_STATE.ACTIVE]: "backoffice.common.active",
            [COMPANY_STATE.INACTIVE]: "backoffice.common.inactive",
        }
    },
    //#endregion

    //#region - JOB
    [STATUS_TYPES.JOB]: {
        color: {
            [JOB_STATE.RUNNING]: "orange",
            [JOB_STATE.SUCCESS]: "green",
            [JOB_STATE.FAILED]: "red"
        },
        translationSource: {
            [JOB_STATE.RUNNING]: "backoffice.jobs.state0",
            [JOB_STATE.SUCCESS]: "backoffice.jobs.state1",
            [JOB_STATE.FAILED]: "backoffice.jobs.state2"
        }
    },
    //#endregion

    //#region - PLAYER
    [STATUS_TYPES.PLAYER]: {
        color: {
            [PLAYER_STATE.ACTIVE]: "green",
            [PLAYER_STATE.PARTIALY_BLOCKED]: "red",
            [PLAYER_STATE.BLOCKED]: "red",
            [PLAYER_STATE.UNKNOWN]: "orange"
        },
        translationSource: {
            [PLAYER_STATE.ACTIVE]: "backoffice.common.active",
            [PLAYER_STATE.PARTIALY_BLOCKED]: "backoffice.players.partiallyBlocked",
            [PLAYER_STATE.BLOCKED]: "backoffice.common.blocked",
            [PLAYER_STATE.UNKNOWN]: "backoffice.players.unknown"
        }
    },
    //#endregion

    //#region - USER
    [STATUS_TYPES.USER]: {
        color: {
            [USER_STATE.ACTIVE]: "green",
            [USER_STATE.IN_PROGRESS]: "grey",
            [USER_STATE.LOCKED_2_FA]: "orange",
            [USER_STATE.LOCKED_LOGIN]: "orange",
            [USER_STATE.BLOCKED]: "red",
            [USER_STATE.EXPIRED]: "red",
            [USER_STATE.DELETED]: "red",
            [USER_STATE.DECLINED]: "red",
        },
        translationSource: {
            [USER_STATE.ACTIVE]: "backoffice.common.active",
            [USER_STATE.IN_PROGRESS]: "backoffice.common.inactive",
            [USER_STATE.BLOCKED]: "backoffice.common.blocked",
            [USER_STATE.EXPIRED]: "backoffice.common.expired",
            [USER_STATE.DELETED]: "backoffice.common.deleted",
            [USER_STATE.LOCKED_2_FA]: "backoffice.common.locked",
            [USER_STATE.LOCKED_LOGIN]: "backoffice.common.locked",
            [USER_STATE.DECLINED]: "backoffice.common.declined",
        }
    },
    //#endregion

    //#region - AFFILIATE
    [STATUS_TYPES.AFFILIATE]: {
        color: {
            [USER_STATE.ACTIVE]: "green",
            [USER_STATE.IN_PROGRESS]: "orange",
            [USER_STATE.LOCKED_2_FA]: "orange",
            [USER_STATE.LOCKED_LOGIN]: "orange",
            [USER_STATE.BLOCKED]: "red",
            [USER_STATE.EXPIRED]: "red",
            [USER_STATE.DELETED]: "red",
            [USER_STATE.DECLINED]: "grey",
        },
        translationSource: {
            [USER_STATE.ACTIVE]: "backoffice.affiliates.approved",
            [USER_STATE.IN_PROGRESS]: "backoffice.affiliates.pending",
            [USER_STATE.BLOCKED]: "backoffice.common.blocked",
            [USER_STATE.EXPIRED]: "backoffice.common.expired",
            [USER_STATE.DELETED]: "backoffice.common.deleted",
            [USER_STATE.LOCKED_2_FA]: "backoffice.common.locked",
            [USER_STATE.LOCKED_LOGIN]: "backoffice.common.locked",
            [USER_STATE.DECLINED]: "backoffice.common.declined",
        }
    },
    //#endregion

    //#region - Commission
    [STATUS_TYPES.COMMISSION]: {
        color: {
            [COMMISSION_PLAN_STATE.ACTIVE]: "green",
            [COMMISSION_PLAN_STATE.INACTIVE]: "grey"
        },
        translationSource: {
            [COMMISSION_PLAN_STATE.ACTIVE]: "backoffice.common.active",
            [COMMISSION_PLAN_STATE.INACTIVE]: "backoffice.common.inactive"
        }
    },
    //#endregion

    //#region - Invoice
    [STATUS_TYPES.INVOICE]: {
        color: {
            [INVOICE_STATE.PENDING]: "orange",
            [INVOICE_STATE.APPROVED]: "green",
            [INVOICE_STATE.REJECTED]: "grey",
            [INVOICE_STATE.DELETED]: "red"
        },
        translationSource: {
            [INVOICE_STATE.PENDING]: "backoffice.invoices.pending",
            [INVOICE_STATE.APPROVED]: "backoffice.invoices.paid",
            [INVOICE_STATE.REJECTED]: "backoffice.invoices.canceled",
            [INVOICE_STATE.DELETED]: "backoffice.invoices.deleted"
        }
    },
    //#endregion

    //#region - Campaign
    [STATUS_TYPES.CAMPAIGN]: {
        color: {
            [CAMPAIGN_STATE.ACTIVE]: "green",
            [CAMPAIGN_STATE.INACTIVE]: "grey",
        },
        translationSource: {
            [CAMPAIGN_STATE.ACTIVE]: "backoffice.common.active",
            [CAMPAIGN_STATE.INACTIVE]: "backoffice.common.inactive",
        }
    },
    //#endregion

    //#region - MEDIA
    [STATUS_TYPES.MEDIA]: {
        color: {
            [MEDIA_STATE.ACTIVE]: "green",
            [MEDIA_STATE.INACTIVE]: "grey",
        },
        translationSource: {
            [MEDIA_STATE.ACTIVE]: "backoffice.common.active",
            [MEDIA_STATE.INACTIVE]: "backoffice.common.inactive",
        }
    },
    //#endregion

    //#region - Media Click
    [STATUS_TYPES.MEDIA_CLICK]: {
        color: {
            [MEDIA_CLICK_STATE.VALID]: "green",
        },
        translationSource: {
            [MEDIA_CLICK_STATE.VALID]: "backoffice.reports.valid",
        },
        defaults: {
            color: "red",
            translationSource: "backoffice.reports.invalid"
        }
    },
    //#endregion

    //#region - Commission Calculation
    [STATUS_TYPES.COMMISSION_CALCULATION]: {
        color: {
            [COMMISSION_CALCULATION_STATUS.PENDING]: "orange",
            [COMMISSION_CALCULATION_STATUS.APPROVED]: "green",
        },
        translationSource: {
            [COMMISSION_CALCULATION_STATUS.PENDING]: "backoffice.commissioncalculations.pending",
            [COMMISSION_CALCULATION_STATUS.APPROVED]: "backoffice.commissioncalculations.approved",
        }
    },
    //#endregion

    //#region - Newsletter
    [STATUS_TYPES.NEWSLETTER]: {
        color: {
            [NEWSLETTER_STATUS.SCHEDULED]: "purple",
            [NEWSLETTER_STATUS.SENT]: "green",
        },
        translationSource: {
            [NEWSLETTER_STATUS.SCHEDULED]: "backoffice.communication.scheduled",
            [NEWSLETTER_STATUS.SENT]: "backoffice.communication.sent",
        }
    },
    //#endregion

    //#region - DB Connection
    [STATUS_TYPES.DBCONNECTION]: {
        color: {
            [DB_CONNECTION_STATE.ACTIVE]: "green",
            [DB_CONNECTION_STATE.IDLE]: "orange"
        },
        translationSource: {
            [DB_CONNECTION_STATE.ACTIVE]: "backoffice.common.active",
            [DB_CONNECTION_STATE.IDLE]: "backoffice.common.idle"
        }
    },
    //#endregion
    //#region - Affiliate Groups
    [STATUS_TYPES.AFFILIATE_GROUP]: {
        color: {
            [AFFILIATE_GROUP_TYPE.STATIC]: "purple",
            [AFFILIATE_GROUP_TYPE.DYNAMIC]: "orange"
        },
        translationSource: {
            [AFFILIATE_GROUP_TYPE.STATIC]: "backoffice.affiliates.static",
            [AFFILIATE_GROUP_TYPE.DYNAMIC]: "backoffice.affiliates.dynamic"
        }
    },
    //#endregion
    //#region - COMPANY
    [STATUS_TYPES.DOMAIN]: {
        color: {
            [PROJECT_DOMAIN_STATUS.ACTIVE]: "green",
            [PROJECT_DOMAIN_STATUS.INACTIVE]: "grey",
            [PROJECT_DOMAIN_STATUS.PENDING]: "orange",
            [PROJECT_DOMAIN_STATUS.FAILED]: "red"
        },
        translationSource: {
            [PROJECT_DOMAIN_STATUS.ACTIVE]: "backoffice.common.active",
            [PROJECT_DOMAIN_STATUS.INACTIVE]: "backoffice.common.inactive",
            [PROJECT_DOMAIN_STATUS.PENDING]: "backoffice.common.pending",
            [PROJECT_DOMAIN_STATUS.FAILED]: "backoffice.common.failed"
        }
    },
    //#endregion
}