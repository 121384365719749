import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import Tabs from 'components/common/tabs';
import PaymentMethods from "./paymentMethods";
import CommissionsComponent from "../../../../../commissionPlans/affiliateCommissionPlans";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

/** Affiliate Edit Page Finance Tab Component */
const AffiliateFinance = ({
    onTabChange
}) => {
    const { t } = useTranslation();

    const { search } = useLocation();

    const commissionId = (new URLSearchParams(search)).get("commissionId");

    const items = [
        {
            title: t("backoffice.affiliates.paymentMethodsTab"),
            permissions: [{ resource: PERMISSION_RESOURCE.AFFILIATE_PAYMENT_METHODS, action: PERMISSION_ACTION.VIEW }],
            component: <PaymentMethods/>
        },
        {
            title: t("backoffice.affiliates.commissions"),
            permissions: [{ resource: PERMISSION_RESOURCE.AFFILIATE_COMMISSION_PLAN, action: PERMISSION_ACTION.VIEW }],
            component: <CommissionsComponent />
        },
    ]

    return (
        <Tabs.SubTabs 
            items={items}
            onTabChange={onTabChange}
            hidden={Boolean(commissionId)}
        />
    )
}

AffiliateFinance.propTypes = {
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}


export default AffiliateFinance;