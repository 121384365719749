import {
    SET_PERMISSION_GROUPS_ACTION_BEFORE,
    SET_PERMISSION_GROUPS_ACTION_FINISH,
    SET_PERMISSION_GROUPS,
    SET_PERMISSION_GROUPS_SORTING,
    SET_PERMISSION_GROUPS_SAVE_ACTION_BEFORE,
    SET_PERMISSION_GROUPS_SAVE_ACTION_FINISH,
    SET_PERMISSION_GROUP_GENERAL_INFO,
    SET_PERMISSION_GROUP_USERS,
    SET_PERMISSION_GROUP_USERS_SORTING,
    SET_PERMISSION_GROUP_USERS_FILTERS
} from "../../actionTypes";

import { PERMISSION_ACTION } from 'constants/permissions.constants';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_PERMISSION_GROUPS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_PERMISSION_GROUPS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_PERMISSION_GROUPS:
            return {
                ...state,
                permissionGroups: !payload.add ? payload.permissionGroups.item2 : state.permissionGroups.concat(payload.permissionGroups.item2),
                total: payload.permissionGroups.item1,
            };

        case SET_PERMISSION_GROUPS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };

        case SET_PERMISSION_GROUPS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_PERMISSION_GROUPS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_PERMISSION_GROUP_GENERAL_INFO:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...payload.info,
                        permissions: payload.info.permissions.filter(perm => perm.actions && perm.actions.some(ac => ac.action === PERMISSION_ACTION.VIEW))
                    },
                },
            };
        case SET_PERMISSION_GROUP_USERS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    users: {
                        ...state.edit.users,
                        users: payload.users.item2,
                        total: payload.users.item1,
                    },
                },
            };
        case SET_PERMISSION_GROUP_USERS_SORTING:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    users: {
                        ...state.edit.users,
                        sorting: payload.sorting
                    },
                },
            };
        case SET_PERMISSION_GROUP_USERS_FILTERS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    users: {
                        ...state.edit.users,
                        filters: payload.filters,
                        sorting: {
                            ...state.edit.users.sorting,
                            page: 1
                        }
                    },
                },
            };
        default:
            return state;
    }
};
