const PATHS = {
    DEFAULT: "/",
    LOGIN: "/login",
    RESET_PASSWORD: "/reset",
    SET_PASSWORD: "/setPassword",
    FORGOT_PASSWORD: "/forgotPassword",
    REGISTER: "/register",
    ACTIVATE: "/activate",
    UNSUBSCRIBE: "/unsubscribe",
    PORTAL: "/portal",
    DASHBOARD_FINANCIAL: "/portal/financial",
    DASHBOARD_OPERATIONAL: "/portal/operational",
    PROMO_CUSTOMIZE: "/portal/promoCustomize",
    PROFILE: "/portal/profile",
    COMPANIES: "/portal/companies",
    COMPANIES_EDIT: "/portal/companies/edit",
    PROJECTS: "/portal/projects",
    PROJECTS_EDIT: "/portal/projects/edit",
    MY_ACCOUNT: "/portal/myAccount",
    AFFILIATES: "/portal/affiliates/accounts",
    AFFILIATES_EDIT: "/portal/affiliates/accounts/edit",
    AFFILIATE_GROUPS: "/portal/affiliates/groups",
    AFFILIATE_GROUPS_EDIT: "/portal/affiliates/groups/edit",
    AFFILIATE_ACCESS: "/portal/affiliates/access",
    INVOICES: "/portal/invoices",
    BALANCE_HISTORY: "/portal/balanceHistory",
    PLAYERS: "/portal/players",
    CAMPAIGNS: "/portal/campaigns",
    CAMPAIGNS_EDIT: "/portal/campaigns/edit",
    MEDIA_TOOLS: "/portal/mediaTools",
    MARKETING_REPORTS: "/portal/reports/marketing",
    PERFORMANCE_REPORTS: "/portal/reports/performance",
    COMMISSION_CALCULATIONS: "/portal/reports/commissionCalculations",
    CALCULATION_APPROVALS: "/portal/calculationApprovals",
    AGGREGATED_DATA: "/portal/reports/aggregatedData",
    USERS: "/portal/users",
    USERS_EDIT: "/portal/users/edit",
    SYSTEM_NOTIFICATIONS: "/portal/systemNotifications",
    SYSTEM_NOTIFICATIONS_EDIT: "/portal/systemNotifications/edit",
    NEWSLETTER: "/portal/newsletter",
    NEWSLETTER_CUSTOMIZE: "/portal/newsletterCustomize",
    ACCESS_MANAGERS: "/portal/accessManagers",
    ACCESS_MANAGERS_EDIT: "/portal/accessManagers/edit",
    AFFILIATE_MANAGERS: "/portal/affiliateManagers",
    AFFILIATE_MANAGERS_EDIT: "/portal/affiliateManagers/edit",
    PERMISSIONS: "/portal/permissions",
    PERMISSION_GROUPS: "/portal/permissionGroups",
    PERMISSION_GROUPS_EDIT: "/portal/permissionGroups/edit",
    PERMISSION_REQUESTS: "/portal/permissionRequests",
    TRANSLATIONS_PROMO: "/portal/cms/promoTranslations",
    TRANSLATIONS_BO: "/portal/cms/BOTranslations",
    SETTINGS_CURRENCIES: "/portal/settings/currencies",
    SETTINGS_LANGUAGES: "/portal/settings/languages",
    SETTINGS_PAYMENTS: "/portal/settings/payments",
    SETTINGS_PAYMENTS_EDIT: "/portal/settings/payments/edit",
    USER_LOGS: "/portal/userLogs",
    DEVELOPER_ERRORS: "/portal/developer/errors",
    DEVELOPER_REQUESTS: "/portal/developer/requests",
    DEVELOPER_JOBS: "/portal/developer/jobs",
    DEVELOPER_MONITORING: "/portal/developer/monitoring",
    DEVELOPER_PLATFORM_TEST: "/portal/developer/platformTest",
    DEVELOPER_GENERATIONS: "/portal/developer/generations",
    DEVELOPER_POST_DEPLOYMENT_ACTIONS: "/portal/developer/postDeploymentActions",
    DEVELOPER_DB_CONNECTIONS: "/portal/developer/dbConnections",
}

export default PATHS;