import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';


import TabTableDashboardLayout from 'components/layouts/tab/table';
import Leaderboard from 'components/common/leaderboard';
import Filters from '../../../components/filters';

import { OPERATIONAL_ENTITY_TYPE, TIME_PERIOD } from '../../../constants';
import { LEADERBOARD_TYPE } from 'components/common/leaderboard/constants';

import useFirstRender from 'hooks/useFirstRender';
import useOperationalLeaderboard from '../../../hooks/useOperationalLeaderboard';

import { isMobile } from 'utils/common';


const selectGlobalProjectId = state => state.common.globalProjectId;

const getDefaultFilters = () => (
    {
        period: {
            period: TIME_PERIOD.TODAY,
            from: null,
            to: null
        },
        dataType: OPERATIONAL_ENTITY_TYPE.PLAYER
    }
)

const TopAffiliates = ({
    updateLastUpdatedDate
}) => {
    const { t } = useTranslation();

    const [ filters, setFilters ] = useState(getDefaultFilters());

    const updateFilters = useCallback((newFilters) => {
        setFilters(prevState => {
            return {
                ...prevState,
                ...newFilters
            }
        })
    }, [])

    const globalProjectId = useSelector(selectGlobalProjectId);

    const { leaderBoardData, isLoading } = useOperationalLeaderboard(filters, updateLastUpdatedDate)

    useFirstRender({
        afterFirstRenderCB: () => {
            updateFilters(getDefaultFilters())
        },
        dependencies: [globalProjectId, updateFilters]
    })

    return (
        <TabTableDashboardLayout
            header={{
                filters: isMobile() ? (
                    <Filters 
                        filters={filters} 
                        updateFilters={updateFilters} 
                        showDataType={true} 
                        dataTypeFields={
                            [
                                { value: OPERATIONAL_ENTITY_TYPE.PLAYER, text: t("backoffice.dashboard.perPlayer") },
                                { value: OPERATIONAL_ENTITY_TYPE.MEDIA, text: t("backoffice.dashboard.perMedia") },
                                { value: OPERATIONAL_ENTITY_TYPE.SUB_AFFILIATE, text: t("backoffice.dashboard.perSubAffiliates") }
                            ]
                        }
                    />
                ) : undefined,
                content: !isMobile() ? (
                    <div className="rt--flex rt--align-center rt--justify-end rt--width-full">
                        <Filters 
                            filters={filters} 
                            updateFilters={updateFilters} 
                            showDataType={true}
                            dataTypeFields={
                                [
                                    { value: OPERATIONAL_ENTITY_TYPE.PLAYER, text: t("backoffice.dashboard.perPlayer") },
                                    { value: OPERATIONAL_ENTITY_TYPE.MEDIA, text: t("backoffice.dashboard.perMedia") },
                                    { value: OPERATIONAL_ENTITY_TYPE.SUB_AFFILIATE, text: t("backoffice.dashboard.perSubAffiliates") }
                                ]
                            }
                        />
                    </div>
                ) : undefined
            }}
        >
            <div className='rt--chart-top'>
                <Row gutter={[16, 24]}>
                    <Col span={24}>
                        <Leaderboard
                            leaders={leaderBoardData}
                            isLoading={isLoading}
                            title={t("backoffice.dashboard.topAffiliates")}
                            type={LEADERBOARD_TYPE.OPERATIONAL}
                        />
                    </Col>
                </Row>
            </div>
        </TabTableDashboardLayout>
    )

}

TopAffiliates.propTypes = {
    updateLastUpdatedDate: PropTypes.func,
}

export default TopAffiliates;