import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'translations/config';

import ConfirmationContext from './confirmationContext';

import Confirmation from "../confirmation";

import { isFunction } from 'utils/common';


const getConfirmationPopupDefaultData = () => {
    return {
        isVisible: false,
        message: i18n.t("backoffice.common.confirmationDefaultMessage"),
        title: i18n.t("backoffice.common.confirmationDefaultTitle"),
        cancelText: i18n.t('backoffice.common.no'),
        okText: i18n.t('backoffice.common.yes'),
        onReject: () => { },
        onConfirm: () => { },
    }
}

const ConfirmationProvider = ({ children }) => {
    const [confirmationPopupData, setConfirmationPopupData] = useState(
        getConfirmationPopupDefaultData()
    );

    //#region ----------------------------------------- API -------------------------------------------//

    const showConfirmationPopup = useCallback((data) => {
        setConfirmationPopupData(prevState => ({
            ...prevState,
            ...data,
            isVisible: true,
        }))
    }, [])

    const resetConfirmationPopupData = useCallback(() => {
        setConfirmationPopupData(
            getConfirmationPopupDefaultData()
        )
    }, [])

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleConfirmation = () => {
        if (isFunction(confirmationPopupData.onConfirm)) {
            confirmationPopupData.onConfirm();
        }

        setConfirmationPopupData(prevState => ({
            ...prevState,
            isVisible: false
        }))
    }

    const handleRejection = () => {
        if (isFunction(confirmationPopupData.onReject)) {
            confirmationPopupData.onReject();
        }

        setConfirmationPopupData(prevState => ({
            ...prevState,
            isVisible: false
        }))
    }

    //#endregion

    const providerValue = useMemo(() => {
        return {
            showConfirmationPopup,
            resetConfirmationPopupData,
        }
    }, [resetConfirmationPopupData, showConfirmationPopup])

    return (
        <>
            <ConfirmationContext.Provider value={providerValue}>
                {children}
            </ConfirmationContext.Provider>

            {confirmationPopupData.isVisible && (
                <Confirmation
                    onCancel={handleRejection}
                    onOk={handleConfirmation}
                    isVisible={confirmationPopupData.isVisible}
                    title={confirmationPopupData.title}
                    message={confirmationPopupData.message}
                    okText={confirmationPopupData.okText}
                    cancelText={confirmationPopupData.cancelText}
                />
            )}
        </>
    );
}

/** ConfirmationProvider propTypes
    * PropTypes
*/
ConfirmationProvider.propTypes = {
    children: PropTypes.node,
}

export default ConfirmationProvider;