import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setProjectsActionBefore, setProjectsActionFinished, setProjectsSaveActionBefore, setProjectsSaveActionFinished } from './projects.action';
import { getUserInfo } from '../profile/userInfo.action';

import { SET_PROJECT_GENERAL_INFO } from "../../../actionTypes";

const setProjectGeneralInfo = info => ({
    type: SET_PROJECT_GENERAL_INFO,
    payload: { info }
})

export const getProjectGeneralInfo = () => {
    return dispatch => {
        dispatch(setProjectsActionBefore());
        return axios({
            url: ApiUrls.GET_PROJECT_GENERAL_INFO,
            method: Methods.GET
        })
        .then(({data : {value: info}}) => {
            dispatch(setProjectGeneralInfo(info))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const saveProjectGeneralInfo = project => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_GENERAL_INFO,
            method: Methods.POST,
            data: {
                ...project
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setProjectGeneralInfo(data.value));
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

export const deleteProjectLogo = type => {
    return dispatch => {

        return axios({
            url: ApiUrls.DELETE_PROJECT_LOGO,
            method: Methods.DELETE,
            data: {
                type
            }
        })
        .then(({data}) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);

                dispatch(getUserInfo(true))
            }
        })
        .catch((ex) => {})
    }
}