import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Spin } from 'antd';

import {
    getPromoSectionTranslations,
    resetPromoSectionTranslations,
    savePromoSectionTranslations,
} from 'store/actions/portal/promoCustomize/sections.action';

import InputWithAdditionalActions from 'components/common/InputWithAdditionalActions';
import CustomizeImgUploader from '../../customizeImgUploader';

import { hasValue } from 'utils/common';

import {
    PROMO_FILE_NAMES,
    PROMO_FILE_TYPE,
    PROMO_SECTION_TYPE,
    SECTION_NAMES,
} from 'constants/promo.constants';

const FORM_FIELD_NAMES = {
    TITLE: "title",
    DESCRIPTION: "description",
    BUTTON_TEXT: "button",
}

const GeneralInfo = ({
    isLoading,
    isSaving,
    customizeLanguage,
    getPromoSectionTranslations,
    savePromoSectionTranslations,
    resetPromoSectionTranslations,
    translations,
    files,
}) => {
    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { setFieldsValue } = formInstance;

    // Get General Info Translations
    useEffect(() => {
        getPromoSectionTranslations(
            SECTION_NAMES.GENERAL_INFO,
            PROMO_SECTION_TYPE.GENERAL_INFO,
        );
    }, [customizeLanguage]);

    // Set Fields Value When Translations is Loaded
    useEffect(() => {
        if (!hasValue(translations)) {
            return;
        }

        setFieldsValue({
            [FORM_FIELD_NAMES.TITLE]: translations[FORM_FIELD_NAMES.TITLE]?.translation,
            [FORM_FIELD_NAMES.DESCRIPTION]: translations[FORM_FIELD_NAMES.DESCRIPTION]?.translation,
            [FORM_FIELD_NAMES.BUTTON_TEXT]: translations[FORM_FIELD_NAMES.BUTTON_TEXT]?.translation,
        })
    }, [translations]);

    return (
        <Spin spinning={isLoading || isSaving}>
            <div>
                <Form
                    className="rt--form"
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        [FORM_FIELD_NAMES.TITLE]: translations[FORM_FIELD_NAMES.TITLE]?.translation,
                        [FORM_FIELD_NAMES.DESCRIPTION]: translations[FORM_FIELD_NAMES.DESCRIPTION]?.translation,
                        [FORM_FIELD_NAMES.BUTTON_TEXT]: translations[FORM_FIELD_NAMES.BUTTON_TEXT]?.translation,
                    }}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label={t("backoffice.promo.banner")}
                            >
                                <CustomizeImgUploader
                                    sectionName={SECTION_NAMES.GENERAL_INFO}
                                    sectionType={PROMO_SECTION_TYPE.GENERAL_INFO}
                                    fileType={PROMO_FILE_TYPE.BANNER}
                                    languageCode={customizeLanguage}
                                    imgPath={files[PROMO_FILE_NAMES[PROMO_FILE_TYPE.BANNER]]}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={t("backoffice.promo.background")}
                            >
                                <CustomizeImgUploader
                                    sectionName={SECTION_NAMES.GENERAL_INFO}
                                    sectionType={PROMO_SECTION_TYPE.GENERAL_INFO}
                                    fileType={PROMO_FILE_TYPE.BACKGROUND}
                                    languageCode={customizeLanguage}
                                    imgPath={files[PROMO_FILE_NAMES[PROMO_FILE_TYPE.BACKGROUND]]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <Form.Item
                                name={FORM_FIELD_NAMES.TITLE}
                                label={`${t('backoffice.promo.title')} *`}
                                className="rt--general-form-item"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 300, message: t('backoffice.validation.fieldInvalid') },
                                ]}
                            >
                                <InputWithAdditionalActions
                                    type='textArea'
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.promo.title')}`}
                                    isOverwritten={translations[FORM_FIELD_NAMES.TITLE]?.isOverwritten}
                                    maxLength={300}
                                    rows={4}
                                    resizable={false}
                                    initialValue={translations[FORM_FIELD_NAMES.TITLE]?.translation}
                                    onConfirm={(value) => {
                                        savePromoSectionTranslations(
                                            SECTION_NAMES.GENERAL_INFO,
                                            PROMO_SECTION_TYPE.GENERAL_INFO,
                                            FORM_FIELD_NAMES.TITLE,
                                            value
                                        )
                                    }}
                                    onReset={() => {
                                        resetPromoSectionTranslations(
                                            SECTION_NAMES.GENERAL_INFO,
                                            PROMO_SECTION_TYPE.GENERAL_INFO,
                                            FORM_FIELD_NAMES.TITLE,
                                        )
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={FORM_FIELD_NAMES.DESCRIPTION}
                                label={`${t('backoffice.promo.description')} *`}
                                className="rt--general-form-item"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 300, message: t('backoffice.validation.fieldInvalid') },
                                ]}
                            >
                                <InputWithAdditionalActions
                                    type='textArea'
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.promo.description')}`}
                                    isOverwritten={translations[FORM_FIELD_NAMES.DESCRIPTION]?.isOverwritten}
                                    maxLength={300}
                                    rows={4}
                                    resizable={false}
                                    initialValue={translations[FORM_FIELD_NAMES.DESCRIPTION]?.translation}
                                    onConfirm={(value) => {
                                        savePromoSectionTranslations(
                                            SECTION_NAMES.GENERAL_INFO,
                                            PROMO_SECTION_TYPE.GENERAL_INFO,
                                            FORM_FIELD_NAMES.DESCRIPTION,
                                            value
                                        )
                                    }}
                                    onReset={() => {
                                        resetPromoSectionTranslations(
                                            SECTION_NAMES.GENERAL_INFO,
                                            PROMO_SECTION_TYPE.GENERAL_INFO,
                                            FORM_FIELD_NAMES.DESCRIPTION,
                                        )
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label={`${t('backoffice.promo.buttonText')} *`}
                                name={FORM_FIELD_NAMES.BUTTON_TEXT}
                                className='rt--form-item-without-margin rt--general-form-item'
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 30, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                            >
                                <InputWithAdditionalActions
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.promo.buttonText')}`}
                                    isOverwritten={translations[FORM_FIELD_NAMES.BUTTON_TEXT]?.isOverwritten}
                                    initialValue={translations[FORM_FIELD_NAMES.BUTTON_TEXT]?.translation}
                                    maxLength={30}
                                    onConfirm={(value) => {
                                        savePromoSectionTranslations(
                                            SECTION_NAMES.GENERAL_INFO,
                                            PROMO_SECTION_TYPE.GENERAL_INFO,
                                            FORM_FIELD_NAMES.BUTTON_TEXT,
                                            value
                                        )
                                    }}
                                    onReset={() => {
                                        resetPromoSectionTranslations(
                                            SECTION_NAMES.GENERAL_INFO,
                                            PROMO_SECTION_TYPE.GENERAL_INFO,
                                            FORM_FIELD_NAMES.BUTTON_TEXT,
                                        )
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Spin >
    );
}

/** GeneralInfo propTypes
 * PropTypes
*/
GeneralInfo.propTypes = {
    /** Redux state property, is true when loading portal customization data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when promo saving */
    isSaving: PropTypes.bool,
    /** Redux action to get promo section translations */
    getPromoSectionTranslations: PropTypes.func,
    /** Redux action to save promo section translations */
    savePromoSectionTranslations: PropTypes.func,
    /** Redux action to reset promo section translations */
    resetPromoSectionTranslations: PropTypes.func,
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
    /** Section Image URLs */
    files: PropTypes.objectOf(PropTypes.string),
    /** Redux state property, translations data */
    translations: PropTypes.objectOf(PropTypes.shape({
        isOverwritten: PropTypes.bool,
        key: PropTypes.string,
        translation: PropTypes.string,
    }))
};

const mapDispatchToProps = dispatch => ({
    getPromoSectionTranslations: (sectionName, sectionType) => {
        dispatch(getPromoSectionTranslations(sectionName, sectionType))
    },
    savePromoSectionTranslations: (sectionName, sectionType, key, text) => {
        dispatch(savePromoSectionTranslations(sectionName, sectionType, key, text))
    },
    resetPromoSectionTranslations: (sectionName, sectionType, key, text) => {
        dispatch(resetPromoSectionTranslations(sectionName, sectionType, key, text))
    },
});

const mapStateToProps = (state) => {
    return {
        customizeLanguage: state.promoCustomize.customizeLanguage,
        isLoading: state.promoCustomize.isLoading,
        isSaving: state.promoCustomize.isSaving,
        translations: state.promoCustomize.sections[PROMO_SECTION_TYPE.GENERAL_INFO].translations,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfo);