import React from "react";

import Operational from "pages/dashboard/operational";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const OperationalRoute = () => {
    return <Operational />;
};

export default withPermission(
    withAuth(OperationalRoute),
    {
        resource: PERMISSION_RESOURCE.DASHBOARD_OPERATIONAL,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.DASHBOARD_OPERATIONAL
);