import {
    SET_WALLET_ACTION_BEFORE,
    SET_WALLET_ACTION_FINISH,
    SET_WALLET_SAVE_ACTION_BEFORE,
    SET_WALLET_SAVE_ACTION_FINISH,
    SET_AFFILIATE_WALLETS,
    UPDATE_AFFILIATE_WALLETS,
    SET_WALLET_TRANSFER_SUCCESS,
    SET_WALLET_TRANSFER_RESULT,
    SET_WALLET_HISTORY,
    SET_WALLET_HISTORY_SORTING,
    SET_WALLET_HISTORY_FILTERS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    let wlts = [];
    switch (type) {
        case SET_WALLET_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_WALLET_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_WALLET_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_WALLET_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_AFFILIATE_WALLETS:
            return {
                ...state,
                affiliateWallet: {
                    ...state.affiliateWallet,
                    wallets: payload.wallets
                }
            }
        case UPDATE_AFFILIATE_WALLETS:
            wlts = state.affiliateWallet.wallets;
            for(let i = 0; i < payload.wallets.length; i++){
                wlts = wlts.map(w => ({
                    ...w,
                    amount: w.currencyCode === payload.wallets[i].currencyCode && w.userName === payload.wallets[i].userName && w.type === payload.wallets[i].type ? payload.wallets[i].amount : w.amount
                }))
            }

            return {
                ...state,
                affiliateWallet: {
                    ...state.affiliateWallet,
                    wallets: wlts
                }
            }
        case SET_WALLET_TRANSFER_SUCCESS:
            return {
                ...state,
                transferSuccess: payload.show
            }
        case SET_WALLET_TRANSFER_RESULT:
            return {
                ...state,
                showTransferResult: payload.show,
                transferResultData: payload.show ? payload.data : [],
                transferSuccess: !payload.show ? false : state.transferSuccess
            }
        case SET_WALLET_HISTORY:
            return {
                ...state,
                history: {
                    ...state.history,
                    transfers: !payload.add ? payload.transfers.item2 : state.history.transfers.concat(payload.transfers.item2),
                    total: payload.transfers.item1,
                }
            };

        case SET_WALLET_HISTORY_FILTERS:
            return {
                ...state,
                history: {
                    ...state.history,
                    filters: payload.filters,
                    sorting: {
                        ...state.history.sorting,
                        page: 1
                    }
                }
            };
        case SET_WALLET_HISTORY_SORTING:
            return {
                ...state,
                history: {
                    ...state.history,
                    sorting: payload.sorting
                }
            };
        default:
            return state;
    }
};
