import PropTypes from "prop-types";

import { PROJECT_DOMAIN_STATUS } from "constants/project.constants";

const hostnameType = PropTypes.shape({
	defaultHostname: PropTypes.string,
	customHostnames: PropTypes.arrayOf(
		PropTypes.shape({
			hostname: PropTypes.string,
			cloudflare: PropTypes.shape({
				status: PropTypes.oneOf(Object.values(PROJECT_DOMAIN_STATUS)),
				errors: PropTypes.string,
			}),
			ingress: PropTypes.shape({
				status: PropTypes.oneOf(Object.values(PROJECT_DOMAIN_STATUS)),
				errors: PropTypes.string,
			}),
		}),
	),
});

export default PropTypes.shape({
	tracking: hostnameType,
	portal: hostnameType,
	promo: hostnameType,
});
