import axios from "axios";
import { message } from "antd";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";
import { isResponseSuccess } from "utils/request";

import { 
    setNewsletterActionBefore, 
    setNewsletterActionFinished,
    setNewsletterSaveActionBefore,
    setNewsletterSaveActionFinished 
} from "./newsletter.action";

import {
    SET_NEWSLETTER_TEMPLATES,
    SET_NEWSLETTER_TEMPLATES_SORTING,
    SET_NEWSLETTER_TEMPLATES_FILTERS
} from "../../../../actionTypes";

import { saveNewsletterTemplateContent } from "../../newsletterCustomize/common.action";

const setNewsletterTemplates = (templates, add) => ({
    type: SET_NEWSLETTER_TEMPLATES,
    payload: { templates, add },
});

export const setNewsletterTemplatesSorting = sorting => ({
    type: SET_NEWSLETTER_TEMPLATES_SORTING,
    payload: { sorting },
});

export const setNewsletterTemplatesFilters = filters => ({
    type: SET_NEWSLETTER_TEMPLATES_FILTERS,
    payload: { filters },
});


export const getNewsletterTemplates = nextPage => {
    return (dispatch, getState) => {

        const templates = getState().newsletter.templates;

        const filters = { ... templates.filters };
        const sorting = { ... templates.sorting };
        const total = templates.total;
        const page = nextPage === "first" ? 1 : nextPage === "next" ? sorting.page + 1 : sorting.page;
        
        let params = {
            ...sorting,
            ...filters,
            page: page
        };

        if(page > 1 && total <= (page - 1 ) * sorting.limit){
            return Promise.resolve();
        }
        
        dispatch(setNewsletterActionBefore());
        return axios({
            url: ApiUrls.GET_NEWSLETTER_TEMPLATES,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: templates } }) => {
                dispatch(setNewsletterTemplates(templates, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setNewsletterTemplatesSorting({ ...sorting, page: page }));
                dispatch(setNewsletterActionFinished());
            })
            .catch((ex) => {
                dispatch(setNewsletterActionFinished());
            });
    }
}


export const createNewsletterTemplate = (template, onSuccess) => {
    return (dispatch) => {
        dispatch(setNewsletterSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_NEWSLETTER_TEMPLATE,
            method: Methods.POST,
            data: {
                ...template
            },
        })
            .then(({ data }) => {
                dispatch(setNewsletterSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess(data.value);
                    const configuration = data.value?.configuration;
                    dispatch(saveNewsletterTemplateContent(data.value?.id, configuration));
                }
            })
            .catch(() => {
                dispatch(setNewsletterSaveActionFinished());
            });
    };
};

export const renameNewsletterTemplate = (data, onSuccess) => {
    return (dispatch) => {
        dispatch(setNewsletterSaveActionBefore());
        return axios({
            url: ApiUrls.RENAME_NEWSLETTER_TEMPLATE,
            method: Methods.POST,
            data: {
                ...data
            },
        })
            .then(({ data }) => {
                dispatch(setNewsletterSaveActionFinished());
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    dispatch(getNewsletterTemplates());
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(() => {
                dispatch(setNewsletterSaveActionFinished());
            });
    };
};

export const duplicateNewsletterTemplate = (data, onSuccess) => {
    return (dispatch) => {
        dispatch(setNewsletterSaveActionBefore());
        return axios({
            url: ApiUrls.DUPLICATE_NEWSLETTER_TEMPLATE,
            method: Methods.POST,
            data: {
                ...data
            },
        })
            .then(({ data }) => {
                dispatch(setNewsletterSaveActionFinished());
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    dispatch(getNewsletterTemplates());
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(() => {
                dispatch(setNewsletterSaveActionFinished());
            });
    };
};

export const deleteNewsletterTemplate = id => {
    return (dispatch) => {
        dispatch(setNewsletterSaveActionBefore());
        return axios({
            url: ApiUrls.REMOVE_NEWSLETTER_TEMPLATE,
            method: Methods.DELETE,
            data: {
                id
            },
        })
            .then(({ data }) => {
                dispatch(setNewsletterSaveActionFinished());
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    dispatch(getNewsletterTemplates());
                }
            })
            .catch(() => {
                dispatch(setNewsletterSaveActionFinished());
            });
    };
};