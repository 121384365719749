//#region react
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Spin } from "antd";

import { getPromoColors, savePromoColors } from "store/actions/portal/promoCustomize/styles.action";

import ColorPicker from "components/common/colorPicker";

import { isFormChanged } from "utils/form";
import { sendToPromoIframe } from "utils/promo";

import { PROMO_UPDATE_TYPE, SECTION_NAMES } from "constants/promo.constants";

import promoColorsType from "types/promo/colors.type";
import { UNSAVED_FORM_PAGE_TYPE } from "constants/common.constants";

/** Portal Customize Page Sections Colors Editor Component */
const ColorsEditor = ({
    colors,
    getPromoColors,
    savePromoColors,
    isSaving,
    isLoading,
    onTabChange,
}) => {
    const { t } = useTranslation();

    const [values, setValues] = useState({});
    const [isFormTouched, setIsFormTouched] = useState(false);

    /** Load colors */
    useEffect(() => {
        getPromoColors();
    }, [])

    useEffect(() => {
        setValues(colors);
    }, [colors])

    const onChange = (key, value) => {
        const newValues = { ...values };
        newValues[key] = value;
        setValues(newValues);

        sendToPromoIframe(PROMO_UPDATE_TYPE.COLORS, { colors: newValues });
        setIsFormTouched(isFormChanged({ ...newValues }, { ...colors }))
    }

    const handleForm = () => {
        savePromoColors(values);
        setIsFormTouched(false);
    }

    useEffect(() => {
        onTabChange(isFormTouched, SECTION_NAMES.COLORS, UNSAVED_FORM_PAGE_TYPE.CUSTOMIZE_STYLES);
    }, [isFormTouched])

    return (
        <Spin spinning={isLoading}>
            <div className="rt--customize-widget-main-content-form">
                <div className="rt--flex rt--align-center rt--justify-between rt--pt-10 rt--pb-10 rt--mb-4 rt--customize-widget-main-content-form-item">
                    <span className="rt--title rt--font-normal rt--font-regular">{t("backoffice.promo.brandColor")}</span>
                    <ColorPicker
                        color={values.brandColor}
                        onChange={value => onChange("brandColor", value)}
                    />
                </div>
                <div className="rt--flex rt--align-center rt--justify-between rt--pt-10 rt--pb-10 rt--mb-4 rt--customize-widget-main-content-form-item">
                    <span className="rt--title rt--font-normal rt--font-regular">{t("backoffice.promo.secondaryColor")}</span>
                    <ColorPicker
                        color={values.secondaryColor}
                        onChange={value => onChange("secondaryColor", value)}
                    />
                </div>
                <div className="rt--flex rt--align-center rt--justify-between rt--pt-10 rt--pb-10 rt--customize-widget-main-content-form-item">
                    <span className="rt--title rt--font-normal rt--font-regular">{t("backoffice.promo.backgroundColor")}</span>
                    <ColorPicker
                        color={values.backgroundColor}
                        onChange={value => onChange("backgroundColor", value)}
                    />
                </div>
                <div className="rt--flex rt--align-center rt--justify-between rt--pt-10 rt--pb-10 rt--customize-widget-main-content-form-item">
                    <span className="rt--title rt--font-normal rt--font-regular">{t("backoffice.promo.textAndIconColor")}</span>
                    <ColorPicker
                        color={values.textAndIconColor}
                        onChange={value => onChange("textAndIconColor", value)}
                    />
                </div>
                <div className="rt--flex rt--justify-end rt--align-center rt--pt-24 rt--mt-16">
                    <Button
                        type="primary"
                        className="rt--button"
                        onClick={handleForm}
                        loading={isSaving}
                        disabled={!isFormTouched}
                    >
                        <span>{t("backoffice.common.save")}</span>
                    </Button>
                </div>
            </div>
        </Spin>
    );
};

/** ColorsEditor propTypes
 * PropTypes
*/
ColorsEditor.propTypes = {
    /** Redux state promerty, promo colors */
    colors: promoColorsType,
    /** Redux action to get promo colors */
    getPromoColors: PropTypes.func,
    /** Redux action to save promo colors */
    savePromoColors: PropTypes.func,
    /** Redux state promerty, is true when promo saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when loading portal customization data */
    isLoading: PropTypes.bool,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    getPromoColors: () => {
        dispatch(getPromoColors())
    },
    savePromoColors: colors => {
        dispatch(savePromoColors(colors))
    }
});

const mapStateToProps = (state) => {
    return {
        isSaving: state.promoCustomize.isSaving,
        colors: state.promoCustomize.colors,
        isLoading: state.promoCustomize.isLoading
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ColorsEditor);
