import React from "react";

import Status from "components/common/status";
import LockInfo from "components/common/lockInfo";
import Icon from "components/common/icon";

import { USER_STATE } from "constants/user.constants";
import { STATUS_TYPES } from "constants/status.constants";

export const tableColumns = [
    {
        title: "backoffice.users.firstName",
        dataIndex: "firstName",
        mobileLevel: 2,
        sorter: true,
    },
    {
        title: "backoffice.users.lastName",
        dataIndex: "lastName",
        sorter: true,
        mobileLevel: 3,
    },
    {
        title: "backoffice.users.username",
        dataIndex: "userName",
        alwaysVisible: true,
        sorter: true,
        mobileLevel: 1,
    },
    {
        title: "backoffice.users.status",
        dataIndex: "state",
        sorter: true,
        mobileLevel: 5,
        render: (value, record) => (
            <Status
                status={value}
                type={STATUS_TYPES.USER}
                icon={[USER_STATE.LOCKED_2_FA, USER_STATE.LOCKED_LOGIN].includes(value) ? <Icon name="info" size={16} className="rt--ml-4" /> : null}
                tooltip={
                    [USER_STATE.LOCKED_2_FA, USER_STATE.LOCKED_LOGIN].includes(value) ?
                    (
                        <LockInfo
                            is2FALocked={record?.loginAttemptDetails?.is2FALocked}
                            lastLogin={record?.lastLogin}
                            lockTime={record?.loginAttemptDetails?.lockTime}
                        />
                    ) : null
                } 
            />
        ),
    },
    {
        title: "backoffice.users.assignedAffiliates",
        dataIndex: "affiliatesCount",
        sorter: false,
        isNumeric: true,
        mobileLevel: 6,
    },
    {
        title: "backoffice.users.email",
        dataIndex: "email",
        sorter: true,
        render: value => value || "-"
    },
    {
        title: "backoffice.users.id",
        dataIndex: "longId",
        alwaysVisible: true,
        sorter: true,
        copy: true,
    },
    {
        title: "backoffice.users.createdAt",
        dataIndex: "createdAt",
        isDateTime: true,
        sorter: true,
    },
    {
        title: "backoffice.users.lastUpdatedAt",
        dataIndex: "modifiedAt",
        isDateTime: true,
        sorter: true,
        mobileLevel: 4,
    },
    {
        title: "backoffice.users.lastLoginAt",
        dataIndex: "lastLogin",
        sorter: true,
        isDateTime: true,
    },
    {
        title: "backoffice.users.lastPasswordChangeAt",
        dataIndex: "lastPasswordChange",
        sorter: true,
        isDateTime: true,
    },
    {
        title: "backoffice.users.lastBlockedAt",
        dataIndex: "lastBlocked",
        sorter: true,
        isDateTime: true,
    },
    {
        title: "backoffice.users.lastBlockedBy",
        dataIndex: "lastBlockedBy",
        render: (value) => value || "-",
    },
];
