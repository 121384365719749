import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Spin, Row, Col, Button } from "antd";

import {
    getCustomHostnames,
    removeCustomHostname,
    checkCustomHostnameStatus,
} from "store/actions/portal/projects/domains.action";

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";
import Icon from "components/common/icon";
import Table from "components/common/table";
import HostAddComponent from "./host-add.component";

import { hasPermission } from "utils/permissions";
import { tableColumnsCreator } from "utils/tableColumnsCreator";

import {
    PERMISSION_ACTION,
    PERMISSION_RESOURCE,
} from "constants/permissions.constants";
import { DOMAIN_TYPE } from "constants/project.constants";
import { tableColumns } from "./columns";

import projectCustomHostnamesType from "types/project/customHostnames.type";
import { isMobile } from "utils/common";

/** Project Edit Page Domains Tab Hosts subComponent */
const CustomHostnamesComponent = ({
    getCustomHostnames,
    removeCustomHostname,
    checkCustomHostnameStatus,
    isSaving,
    isLoading,
    customHostnames,
}) => {
    const { t } = useTranslation();

    const [addPopup, setAddPopup] = useState(null);

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasCreatePermission = hasPermission({
        resource: PERMISSION_RESOURCE.PROJECT_DOMAINS,
        action: PERMISSION_ACTION.CREATE,
    });

    const hasDeletePermission = hasPermission({
        resource: PERMISSION_RESOURCE.PROJECT_DOMAINS,
        action: PERMISSION_ACTION.DELETE,
    });

    //#endregion

    /** Get Project Custom hostnames */
    useEffect(() => {
        getCustomHostnames();
    }, []);

    const renderColumn = (type) => {
        const data =
            customHostnames[
            type === DOMAIN_TYPE.BACKOFFICE
                ? "portal"
                : type === DOMAIN_TYPE.TRACKING
                    ? "tracking"
                    : "promo"
            ];

        return (
            <Col md={24} sm={24} xs={24} lg={12}>
                <div className="rt--card-section rt--card-section-block">
                    <div className="rt--flex rt--flex-col rt--pl-24 rt--pr-24 rt--pt-24 rt--pb-24 rt--card-section-block-inner">
                        <div className="rt--flex rt--flex-col">
                            <div className={"rt--flex rt--justify-between rt--mb-8 " + (isMobile() ? 'rt--flex-col rt--align-start' : '')}>
                                <span className="rt--card-section-title rt--font-extra-bold  rt--font-bigest">
                                    {type === DOMAIN_TYPE.BACKOFFICE
                                        ? t("backoffice.projects.portalDomain")
                                        : type === DOMAIN_TYPE.TRACKING
                                            ? t("backoffice.projects.trackingDomain")
                                            : t("backoffice.projects.promoDomain")}
                                </span>
                                <div className={"rt--flex rt--align-center rt--justify-end rt--flex-equal " + (isMobile() ? 'rt--mt-8' : '')}>
                                    {hasCreatePermission && (
                                        <Button
                                            type="primary"
                                            className="rt--button rt--button-main"
                                            icon={<Icon name="plus" />}
                                            onClick={() => setAddPopup({ type: type })}
                                        >
                                            <span>{t("backoffice.projects.addHostname")}</span>
                                        </Button>
                                    )}
                                </div>
                            </div>
                            {type !== DOMAIN_TYPE.PROMO && (
                                <span className="rt--card-section-subTitle rt--font-regular rt--font-normal rt--mb-4">
                                    {t("backoffice.projects.defaultDomain")}:
                                    <b className="rt--font-bold rt--pl-8 rt--pr-8">
                                        {data.defaultHostname}
                                    </b>
                                </span>
                            )}
                        </div>
                        <div className="rt--mt-16 rt--card-section-block-table">
                            <Table
                                loading={isLoading}
                                columns={mainTableColumns}
                                data={data.customHostnames}
                                noPagination={true}
                                disableFullView={true}
                                actions={getTableRowActions(type)}
                                uniqueKey="hostname"
                            />
                        </div>
                    </div>
                </div>
            </Col>
        );
    };

    //#region --------------------------------- HANDLERS ---------------------------------//

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: tableColumns,
        });
    }, []);

    /** Get Table row actions */
    const getTableRowActions = (type) => {
        const tableRowActions = [
            {
                title: t("backoffice.common.refresh"),
                icon: "reload",
                onClick: (record) => checkCustomHostnameStatus(type, record.hostname),
            },
        ];

        if (hasDeletePermission) {
            tableRowActions.push({
                title: t("backoffice.common.delete"),
                confirmationMessage: t("backoffice.projects.doYouWantToDeleteHostname"),
                confirmationTitle: t("backoffice.common.delete"),
                icon: "trash",
                showConfirmation: true,
                className: "rt--button-danger",
                onClick: (record) => removeCustomHostname(type, record.hostname),
            });
        }

        return tableRowActions;
    };
    //#endregion

    return (
        <SubTabFormDashboardLayout>
            <Spin spinning={isLoading || isSaving} wrapperClassName="rt--form-spin">
                <Row gutter={[16, 24]}>
                    {renderColumn(DOMAIN_TYPE.BACKOFFICE)}
                    {renderColumn(DOMAIN_TYPE.TRACKING)}
                    {renderColumn(DOMAIN_TYPE.PROMO)}
                </Row>
            </Spin>
            {addPopup && (
                <HostAddComponent
                    onClose={() => setAddPopup(null)}
                    type={addPopup?.type}
                />
            )}
        </SubTabFormDashboardLayout>
    );
};

/** CustomHostnamesComponent propTypes
 * PropTypes
 */
CustomHostnamesComponent.propTypes = {
    /** Redux state property, is true when custom hostnames are saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when custom hostnames are loading */
    isLoading: PropTypes.bool,
    /** Redux state property, current editing project custom hostnames are */
    customHostnames: projectCustomHostnamesType,
    /** Redux action to get custom hostnames */
    getCustomHostnames: PropTypes.func,
    /** Redux action to remove custom hostnames */
    removeCustomHostname: PropTypes.func,
    /** Redux action to check custom hostname status */
    checkCustomHostnameStatus: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    getCustomHostnames: () => {
        dispatch(getCustomHostnames());
    },
    removeCustomHostname: (type, customHostname) => {
        dispatch(removeCustomHostname(type, customHostname));
    },
    checkCustomHostnameStatus: (type, customHostname) => {
        dispatch(checkCustomHostnameStatus(type, customHostname));
    },
});

const mapStateToProps = (state) => {
    return {
        customHostnames: state.projects.edit.customHostnames,
        isSaving: state.projects.isSaving,
        isLoading: state.projects.isLoading,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomHostnamesComponent);
