import {
    SET_NEWSLETTER_ACTION_BEFORE,
    SET_NEWSLETTER_ACTION_FINISH,
    SET_NEWSLETTER_SAVE_ACTION_BEFORE,
    SET_NEWSLETTER_SAVE_ACTION_FINISH
} from "../../../../actionTypes";

export const setNewsletterActionBefore = () => ({
    type: SET_NEWSLETTER_ACTION_BEFORE,
});

export const setNewsletterActionFinished = () => ({
    type: SET_NEWSLETTER_ACTION_FINISH
});

export const setNewsletterSaveActionBefore = () => ({
    type: SET_NEWSLETTER_SAVE_ACTION_BEFORE,
});

export const setNewsletterSaveActionFinished = () => ({
    type: SET_NEWSLETTER_SAVE_ACTION_FINISH
});
