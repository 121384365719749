import PropTypes from 'prop-types';

import { NEWSLETTER_STATUS } from 'constants/newsletter.constants';

export default PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    subject: PropTypes.string,
    languageCode: PropTypes.string,
    status: PropTypes.oneOf(Object.values(NEWSLETTER_STATUS)),
    sendDate: PropTypes.string,
    sentBy: PropTypes.string,
    receivedCount: PropTypes.number,
    openedCount: PropTypes.number,
    clickedCount: PropTypes.number,
    targetAffiliatesCount: PropTypes.number
})