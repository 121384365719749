import React from "react";

import i18n from "translations/config";

import Status from "components/common/status";

import { COMMISSION_PLAN_SOURCE, COMMISSION_PLAN_TYPE } from "constants/commission.constants";
import { STATUS_TYPES } from "constants/status.constants";
import { PROJECT_BRAND_PRODUCT_TYPE } from "constants/project.constants";

export const tableColumns = [
    {
        title: "backoffice.commissionplans.name",
        dataIndex: "name",
        alwaysVisible: true,
        sorter: true,
        mobileLevel: 1
    },
    {
        title: "backoffice.commissionplans.commissionType",
        dataIndex: "type",
        sorter: true,
        render: value => value === COMMISSION_PLAN_TYPE.REVSHARE ? i18n.t("backoffice.commissionplans.revshare") :
                    value === COMMISSION_PLAN_TYPE.CPA ? i18n.t("backoffice.commissionplans.cpa") : 
                    value === COMMISSION_PLAN_TYPE.FIX ? i18n.t("backoffice.commissionplans.fix") : "",
        mobileLevel: 2
    },
    {
        title: "backoffice.commissionplans.currency",
        dataIndex: "currencyCode",
        mobileLevel: 3
    },
    {
        title: "backoffice.commissionplans.status",
        dataIndex: "status",
        sorter: true,
        render: value => <Status type={STATUS_TYPES.COMMISSION} status={value} />,
        mobileLevel: 4
    },
    {
        title: "backoffice.commissionplans.source",
        dataIndex: "source",
        render: value => value === COMMISSION_PLAN_SOURCE.GGR ? i18n.t("backoffice.commissionplans.ggr") :
                    value === COMMISSION_PLAN_SOURCE.NGR ? i18n.t("backoffice.commissionplans.ngr") : 
                    value === COMMISSION_PLAN_SOURCE.TURNOVER ? i18n.t("backoffice.commissionplans.turnover") :
                    value === COMMISSION_PLAN_SOURCE.NET_DEPOSIT ? i18n.t("backoffice.commissionplans.netDeposit") : "-",
        mobileLevel: 5
    },
    {
        title: "backoffice.projects.product",
        dataIndex: "products",
        render: value => value ? value.map(
            v => v === PROJECT_BRAND_PRODUCT_TYPE.CASINO ? i18n.t(`backoffice.projects.casino`) : 
            v === PROJECT_BRAND_PRODUCT_TYPE.SPORT ? i18n.t(`backoffice.projects.sport`) : ""
        ).join(", ") : "-",
        mobileLevel: 6
    },
    {
        title: "backoffice.commissionplans.startDateTime",
        dataIndex: "startDate",
        isDateTime: true
    },
    {
        title: "backoffice.commissionplans.nextCalculationTime",
        dataIndex: "nextCalculationTime",
        isDateTime: true
    },
];