import React from "react";
import PropTypes from "prop-types";

import { CUSTOMIZE_IFRAME_ID, CUSTOMIZE_PREVIEW_DEVICE_MODE } from "constants/customize.constants";

/** Customize Page Preview Component */
const Preview = ({
    previewDeviceMode,
    preview
}) => {
    return (
        <div className="rt--customize-page">
            <div className="rt--customize-page-background"></div>
            <div className="rt--customize-page-preview rt--flex-equal" data-type={previewDeviceMode}>
                <iframe
                    id={CUSTOMIZE_IFRAME_ID}
                    src={preview.url}
                    srcDoc={preview.html}
                    height="100%" 
                    width="100%" 
                    title="Promo Preview"
                    frameBorder="0"
                    scrolling="yes"
                />
            </div>
        </div>
    );
};

/** Preview propTypes
 * PropTypes
*/
Preview.propTypes = {
    /** Preview device mode */
    previewDeviceMode: PropTypes.oneOf(Object.values(CUSTOMIZE_PREVIEW_DEVICE_MODE)),
    /** Preview */
    preview: PropTypes.shape({
        url: PropTypes.string,
        html: PropTypes.string
    })
};


export default Preview;
