import PropTypes from 'prop-types';

export default PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
    generations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        betCount: PropTypes.number,
        chunkCount: PropTypes.number,
        packageId: PropTypes.string,
        createdAt: PropTypes.string,
        lastUpdatedAt: PropTypes.string,
    }))
})