import React, {useEffect, Fragment, Suspense } from 'react';

import { isRTL } from 'utils/common';

import LanguageUtils from 'utils/languages';


const RTL = React.lazy(() => import('./rtl'))
const LTR = React.lazy(() => import('./ltr'))


const currentLn = LanguageUtils.getSelectedLanguage();

const Styles = () => {

    useEffect(() => {
        if (currentLn) {
            if (isRTL(currentLn)) {
                document.body.classList.add('rt--rtl');
            }
        }
    }, [currentLn])

    return currentLn ?
        isRTL(currentLn) ? <Suspense fallback={<Fragment />}><RTL /></Suspense> : <Suspense fallback={<Fragment />}><LTR /></Suspense>
        : <Suspense fallback={<Fragment />}><LTR /></Suspense>
}



export default Styles;