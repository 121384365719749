import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import { MODULE_NO_SCROLL } from "yet-another-react-lightbox";

import { ExtraFooterModule } from "./customModules";

const getExtraFooterPlugin = (extraFooterOptions) => {
    return ({ augment, addSibling }) => {
        augment((props) => ({
            extraFooterProps: { ...extraFooterOptions },
            ...props,
        }));

        addSibling(MODULE_NO_SCROLL, ExtraFooterModule);
    }
}

export const getNeededPlugins = ({
    showThumbnails,
    extraFooterOptions,
}) => {
    const plugins = [Fullscreen, Zoom];

    if (showThumbnails) {
        plugins.push(Thumbnails);
    }

    if (extraFooterOptions) {
        plugins.push(
            getExtraFooterPlugin(extraFooterOptions)
        )
    }

    return plugins;
}