export const WALLET_OPERATION_TYPE = {
    COMMISSION_CALCULATION: 1,
    COMMISSION_RECALCULATION: 2,
    COMMISSION_APPROVE: 4,
    INVOICE_CREATE: 8,
    INVOICE_APPROVE: 16,
    ROLLBACK: 32,
    DEPOSIT: 64,
    WITHDRAW: 128,
    REFERRAL_CALCULATION: 256,
    REFERRAL_CALCULATION_APPROVE: 512
}

export const WALLET_TYPE = {
    CPA_NOT_APPROVED_BALANCE: 1 << 0,
    EARNING_NOT_APPROVED_BALANCE: 1 << 1,
    CPA_BALANCE: 1 << 2,
    EARNING_BALANCE: 1 << 3,
    FROZEN_BALANCE: 1 << 4,
    PAIDOUT_BALANCE: 1 << 5
}

export const WALLET_TYPE_TRANSLATION_RESOURCES = {
    [WALLET_TYPE.EARNING_BALANCE]: "backoffice.wallet.earningBalance",
    [WALLET_TYPE.CPA_BALANCE]: "backoffice.wallet.cpaBalance",
    [WALLET_TYPE.EARNING_NOT_APPROVED_BALANCE]: "backoffice.wallet.earningNotApprovedBalance",
    [WALLET_TYPE.CPA_NOT_APPROVED_BALANCE]: "backoffice.wallet.cpaNotApprovedBalance",
    [WALLET_TYPE.PAIDOUT_BALANCE]: "backoffice.wallet.paidoutBalance",
    [WALLET_TYPE.FROZEN_BALANCE]: "backoffice.wallet.frozenBalance",
}

export const WALLET_OWNER_TYPE = {
    AFFILIATE: 1
}

export const WALLET_ADJUSTMENT_TYPE = {
    MANUAL_DEDUCTION: 1,
    MANUAL_BONUS: 2
}
