import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { Divider, Menu } from 'antd';

import Dropdown from "components/common/dropdown";
import Flag from "components/common/flag"
import Icon from "components/common/icon"

import useUnsavedChangesConfirmation from 'hooks/useUnsavedChangesConfirmation';


import { logout, getUser, changeCurrency } from 'utils/auth';
import { isMobile } from "utils/common";

import Paths from 'constants/path.constants';
import { setUrlParam } from "utils/common";
import LanguageUtils from 'utils/languages';

import { USER_ROLE } from "constants/user.constants";
import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';

import currencyType from "types/profile/currency.type";
import userInfoType from "types/profile/userInfo.type";

/** Profile dropdown component on Header */

const ProfileDropdown = ({
    userInfo,
    currency,
    currencies,
    languages
}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [showCurrenciesList, setShowCurrenciesList] = useState(false);
    const [showLanguagesList, setShowLanguagesList] = useState(false);

    const currentLn = LanguageUtils.getSelectedLanguage();

    const logoutWithConfirmation = useUnsavedChangesConfirmation({
        cb: logout,
        subscribe: [UNSAVED_FORM_PAGE_TYPE.TAB, UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    const navigateWithConfirmation = useUnsavedChangesConfirmation({
        cb: navigate,
        subscribe: [UNSAVED_FORM_PAGE_TYPE.TAB, UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    const changeLanguage = (key) => {
        setUrlParam('language', key)
        LanguageUtils.setSelectedLanguageToStorage(key, languages);
        window.location.reload();
    }

    const changeLanguageWithConfirmation = useUnsavedChangesConfirmation({
        cb: changeLanguage,
        subscribe: [UNSAVED_FORM_PAGE_TYPE.TAB, UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    useEffect(() => {
        if (!dropdownVisible) {
            setShowCurrenciesList(false);
            setShowLanguagesList(false);
        }
    }, [dropdownVisible])

    /** Fires on dropdown item click
       * @function
       * @param {string} value - selected item value
       * @memberOf ProfileDropdown
   */
    const handleClick = value => {
        if (value === "logout") {
            setDropdownVisible(false)
            logoutWithConfirmation()
        } else if (value === "currency") {
            setShowCurrenciesList(true)
        } else if (value === "language") {
            if (Object.keys(languages).length > 1) {
                setShowLanguagesList(true)
            }
        } else if (value === "profile") {
            navigateWithConfirmation(Paths.PROFILE);
            setDropdownVisible(false)
        }
    }

    /** Function, fires on currencies dropdown item click
       * @function
       * @param {string} key
       * @memberOf ProfileDropdown
   */
    const handleCurrencyDropdownChange = key => {
        if (key) {
            if (key === "back") {
                setShowCurrenciesList(false);
            } else {
                const curr = currencies.find(c => c.currencyCode === key);
                if (currency !== curr?.currencyCode) {
                    changeCurrency(curr?.currencyCode);
                }
                setShowCurrenciesList(false);
            }
        }
    }

    /** Function, fires on languages dropdown item click
       * @function
       * @param {string} key
       * @memberOf ProfileDropdown
   */
    const handleLanguageDropdownChange = key => {
        if (key) {
            if (key === "back") {
                setShowLanguagesList(false)
            } else {
                setDropdownVisible(false)
                changeLanguageWithConfirmation(key)
            }
        }
    }

    /** Function, to build avatar path
       * @function
       * @returns {string} path
       * @memberOf ProfileDropdown
   */
    const buildAvatarPath = () => {
        const avatarId = userInfo?.avatarId ?? null;
        const companyLongId = userInfo?.mainCompanyLongId ?? null;
        const projectLongId = getUser()?.projectLongId ?? null;
        const userLongId = userInfo?.longId ?? null;
        if(getUser()?.role === USER_ROLE.ADMIN || getUser()?.role === USER_ROLE.ACCESS_MANAGER){
            return `${import.meta.env.SYSTEM_CDN_URL}/companies/${companyLongId}/users/${userLongId}/${avatarId}_small.png`
        } else {
            return `${import.meta.env.SYSTEM_CDN_URL}/companies/${companyLongId}/projects/${projectLongId}/users/${userLongId}/${avatarId}_small.png`
        }
    }

    return (
        <div className='rt--header-actions'>
            {
                getUser() && (
                    <Dropdown
                        className="rt--dropdown-menu-with-back"
                        getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        overlay={showCurrenciesList ? (
                            <Menu onClick={e => handleCurrencyDropdownChange(e ? e.key : null)}>
                                <Menu.Item
                                    key="back"
                                    className='rt--menu-item-back'
                                >
                                    <div className='rt--flex rt--align-center'>
                                        <Icon name='left' />
                                        <span className='rt--title rt--font-normal rt--font-bold rt--pl-4'>
                                            {t('backoffice.currencies.currency')}
                                        </span>
                                    </div>
                                </Menu.Item>
                                {
                                    currencies.map(c => {
                                        return (
                                            <Menu.Item
                                                key={c.currencyCode}
                                                className={c.currencyCode === currency ? "rt--dropdown-item-active" : ""}
                                            >
                                                <span className='rt--title rt--font-normal rt--font-regular'>
                                                    {c.currencyCode}
                                                </span>
                                                {
                                                    isMobile() && c.currencyCode === currency && (
                                                        <Icon name='ok' />
                                                    )
                                                }

                                            </Menu.Item>
                                        )
                                    })
                                }
                            </Menu>
                        ) : showLanguagesList ? (
                            <Menu onClick={e => handleLanguageDropdownChange(e ? e.key : null)}>
                                <Menu.Item
                                    key="back"
                                    className='rt--menu-item-back'
                                >
                                    <div className='rt--flex rt--align-center'>
                                        <Icon name='left' />
                                        <span className='rt--title rt--font-normal rt--font-bold rt--pl-4'>
                                            {t('backoffice.languages.language')}
                                        </span>
                                    </div>
                                </Menu.Item>
                                {
                                    Object.keys(languages).map(lang => {
                                        return (
                                            <Menu.Item
                                                key={lang}
                                                className={lang === currentLn ? "rt--dropdown-item-active" : ""}
                                            >
                                                <div className='rt--flex rt--align-center'>
                                                    <Flag code={lang} />
                                                    <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>
                                                        {t(`backoffice.languages.${lang}`)}
                                                    </span>
                                                </div>
                                                {
                                                    isMobile() && lang === currentLn && (
                                                        <Icon name='ok' />
                                                    )
                                                }
                                            </Menu.Item>
                                        )
                                    })
                                }
                            </Menu>
                        ) : (
                            <Menu
                                onClick={e => e.key && handleClick(e.key)}
                            >

                                <Menu.Item key="profile">
                                    <span className='rt--title rt--font-normal rt--font-regular'>
                                        {t('backoffice.common.profile')}
                                    </span>
                                </Menu.Item>
                                {
                                    (
                                        getUser()?.role !== USER_ROLE.ACCESS_MANAGER
                                    ) && (
                                        <Menu.Item key="currency" className="rt--dropdown-item-not-selected">
                                            <div className="rt--flex rt--width-full rt--align-center rt--justify-between">
                                                <span className='rt--title rt--font-normal rt--font-regular'>
                                                    {`${t("backoffice.currencies.currency")}: `}
                                                    <strong className='rt--title rt--font-normal rt--font-regular rt--pl-4'>{currency}</strong>
                                                </span>
                                                <Icon name='right' />
                                            </div>
                                        </Menu.Item>
                                    )
                                }
                                <Menu.Item key="language" className="rt--dropdown-item-not-selected">
                                    <div className="rt--flex rt--width-full rt--align-center rt--justify-between">
                                        <span className='rt--title rt--font-normal rt--font-regular'>
                                            {`${t("backoffice.languages.language")}: `}
                                            <strong className='rt--title rt--font-normal rt--font-regular rt--pl-4'>{t(`backoffice.languages.${currentLn}`)}</strong>
                                        </span>

                                        {
                                            Object.keys(languages).length > 1 && (
                                                <Icon name='right' />
                                            )
                                        }

                                    </div>
                                </Menu.Item>

                                <Menu.Item key="divider" className='rt--divider-dropdown-menu-item'>
                                    <Divider className='rt--divider-dropdown' />
                                </Menu.Item>

                                <Menu.Item key="logout">
                                    <span className='rt--title rt--font-normal rt--font-regular'>
                                        {t('backoffice.common.logout')}
                                    </span>
                                </Menu.Item>
                            </Menu>
                        )}
                        disabled={false}
                        popupVisible={dropdownVisible}
                        onVisibleChange={visible => setDropdownVisible(visible)}
                    >
                        {
                            isMobile() ? (
                                <div className='rt--header-avatar rt--flex rt--align-center rt--justify-center'>
                                    <span className='rt--title rt--font-normal rt--font-bold'>{getUser()?.userName?.substring(0, 2)}</span>
                                </div>
                            ) : (
                                <div className='rt--header-actions-content rt--flex rt--align-center'>
                                    <div className='rt--header-avatar rt--flex rt--align-center rt--justify-center rt--mr-8'>
                                        {
                                            userInfo?.avatarId ?? null !== null ? (
                                                <img src={buildAvatarPath()} 
                                            />
                                            ) : (
                                                <Icon name="user-avatar" size={20} />
                                            )
                                        }
                                    </div>
                                    <span className='rt--header-actions-name rt--title rt--font-normal rt--font-bold rt--pr-4'>
                                        {
                                            userInfo?.firstName || userInfo?.lastName ?
                                                `${userInfo?.firstName ?? ""} ${userInfo?.lastName ?? ""}` :
                                                getUser()?.userName
                                        }
                                    </span>
                                    <Icon name="down-small" className='rt--header-actions-content-arrow' />
                                </div>
                            )
                        }


                    </Dropdown>
                )
            }

        </div>
    )
}

/** ProfileDropdown propTypes
    * PropTypes
*/
ProfileDropdown.propTypes = {
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Redux state property, the current user currency */
    currency: PropTypes.string,
    /** Redux state property, current user currencies */
    currencies: PropTypes.arrayOf(currencyType),
    /** Redux state property, system languages */
    languages: PropTypes.object
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo,
        currency: state.profile.currency,
        currencies: state.profile.currencies,
        languages: state.auth.translations.languages
    }
}

export default connect(mapStateToProps, null)(ProfileDropdown)

