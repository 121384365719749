import React from 'react';
import PropTypes from 'prop-types';

import Icon from "components/common/icon";

const TitleWithEditIcon = ({ title }) => {
    return (
        <span className="rt--flex rt--align-center">
            {title}
            <div className="rt--editable-column-icon rt--ml-4">
                <Icon name="edit" size={12} />
            </div>
        </span>
    );
}

/** TitleWithEditIcon propTypes
 * PropTypes
*/
TitleWithEditIcon.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default TitleWithEditIcon;