import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import { Form } from 'antd';

import Modal from 'components/common/modal';
import CustomizeImgUploader from '../../customizeImgUploader';

import { PROMO_SECTION_TYPE, SECTION_NAMES } from 'constants/promo.constants';

const MarketingToolsEditComponent = ({
    logoImgPath,
    backgroundImgPath,
    logoFileType,
    backgroundFileType,
    onClose,
    customizeLanguage,
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={t('backoffice.promo.editMarketing')}
            closable={true}
            onCancel={onClose}
        >
            <Form
                className="rt--form"
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Form.Item
                    className='rt--general-form-item'
                    label={t("backoffice.promo.logo")}
                >
                    <CustomizeImgUploader
                        sectionName={SECTION_NAMES.MARKETING_TOOLS}
                        sectionType={PROMO_SECTION_TYPE.MARKETING_TOOLS}
                        fileType={logoFileType}
                        languageCode={customizeLanguage}
                        imgPath={logoImgPath}
                    />
                </Form.Item>
                <Form.Item
                    className='rt--general-form-item rt--form-item-without-margin'
                    label={t("backoffice.promo.background")}
                >
                    <CustomizeImgUploader
                        sectionName={SECTION_NAMES.MARKETING_TOOLS}
                        sectionType={PROMO_SECTION_TYPE.MARKETING_TOOLS}
                        fileType={backgroundFileType}
                        languageCode={customizeLanguage}
                        imgPath={backgroundImgPath}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}

/** MarketingToolsEditComponent propTypes
    * PropTypes
*/
MarketingToolsEditComponent.propTypes = {
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
    /** Logo image URL */
    logoImgPath: PropTypes.string,
    /** Background image URL */
    backgroundImgPath: PropTypes.string,
    /** Logo file Type */
    logoFileType: PropTypes.number,
    /** Background file Type */
    backgroundFileType: PropTypes.number,
}

export default MarketingToolsEditComponent;