import PropTypes from 'prop-types';

export default PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    createdAt: PropTypes.string,
    modifiedAt: PropTypes.string,
    createdBy: PropTypes.string,
    modifiedBy: PropTypes.string,
    languageCode: PropTypes.string
})