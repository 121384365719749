import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/common/tooltip';
import Icon from "components/common/icon";

const TitleWithTooltip = ({ title, tooltip }) => {
    return (
        <span className="rt--flex rt--align-center">
            <b className="rt--font-normal rt--font-regular">
                {title}
            </b>
            <Tooltip
                title={tooltip}
                trigger={["hover", "click"]}
                placement="top"
                enableMobile={true}
            >
                <Icon name="info" size={18} className="rt--ml-4" />
            </Tooltip>
        </span>
    );
}

/** TitleWithTooltip propTypes
 * PropTypes
*/
TitleWithTooltip.propTypes = {
    title: PropTypes.string,
    tooltip: PropTypes.string
}

export default TitleWithTooltip;