import React from 'react';

import Status from "components/common/status";
import LockInfo from "components/common/lockInfo";
import Icon from "components/common/icon";

import { USER_STATE } from "constants/user.constants";
import { STATUS_TYPES } from "constants/status.constants";

export const tableColumns = [
    {
        title: "backoffice.users.id",
        dataIndex: "longId",
        alwaysVisible: true,
        mobileLevel: 1
    },
    {
        title: "backoffice.users.username",
        dataIndex: "userName",
        alwaysVisible: true,
        mobileLevel: 2
    },
    {
        title: "backoffice.users.status", 
        dataIndex: "state",
        mobileLevel: 3,
        render: (value, record) => (
            <Status
                status={value}
                type={STATUS_TYPES.AFFILIATE}
                icon={[USER_STATE.LOCKED_2_FA, USER_STATE.LOCKED_LOGIN].includes(value) ? <Icon name="info" size={16} className="rt--ml-4" /> : null}
                tooltip={
                    [USER_STATE.LOCKED_2_FA, USER_STATE.LOCKED_LOGIN].includes(value) ?
                    (
                        <LockInfo
                            is2FALocked={record?.loginAttemptDetails?.is2FALocked}
                            lastLogin={record?.lastLogin}
                            lockTime={record?.loginAttemptDetails?.lockTime}
                        />
                    ) : null
                } 
            />
        ),
    },
    {
        title: "backoffice.users.firstName",
        dataIndex: "firstName",
        mobileLevel: 4,
        render: value => value || "-",
    },
    {
        title: "backoffice.users.lastName",
        dataIndex: "lastName",
        mobileLevel: 5,
        render: value => value || "-",
    },
];