import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

const TwoFactorQRView = ({ QRsrc }) => {
	const { t } = useTranslation();

	return (
		<div className="rt--flex rt--flex-col rt--align-center">
			<span className='rt--title rt--font-big rt--font-regular rt--mb-16'>
				{t('backoffice.authentication.scanQRCode')}
			</span>
			{QRsrc && <img src={QRsrc} alt="QR code" width={140} height={140} />}
		</div>
	);
};

/** TwoFactorQRView propTypes
	 * PropTypes
*/
TwoFactorQRView.propTypes = {
	/** QR code src */
	QRsrc: PropTypes.string
};

export default TwoFactorQRView;
