import {
    SET_SYSTEM_LANGUAGES_ACTION_BEFORE,
    SET_SYSTEM_LANGUAGES_ACTION_FINISH,
    SET_SYSTEM_LANGUAGES,
    SET_SYSTEM_LANGUAGES_SORTING,
    SET_SYSTEM_LANGUAGES_SAVE_ACTION_BEFORE,
    SET_SYSTEM_LANGUAGES_SAVE_ACTION_FINISH,
    SET_AVAILABLE_LANGUAGES
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_SYSTEM_LANGUAGES_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_SYSTEM_LANGUAGES_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_SYSTEM_LANGUAGES:
            return {
                ...state,
                languages: !payload.add ? payload.languages.item2 : state.languages.concat(payload.languages.item2),
                total: payload.languages.item1,
            };
        case SET_SYSTEM_LANGUAGES_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_SYSTEM_LANGUAGES_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_SYSTEM_LANGUAGES_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_AVAILABLE_LANGUAGES:
            return {
                ...state,
                availableLanguages: payload.languages,
            };
        default:
            return state;
    }
};
