import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";
import TextAreaInput from 'components/common/textAreaInput';

import { addAccountProjectAccess } from "store/actions/portal/account/projects.action";

import { getUser } from 'utils/auth';

import { USER_ROLE } from 'constants/user.constants';

import accountProjectAccessType from 'types/account/projectAccess.type';

/** User Project Adding Popup Component */
const ProjectAddComponent = ({
    isSaving,
    addAccountProjectAccess,
    projectAccess,
    companyId,
    onClose
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const [value, setValue] = useState(null);

    const userRole = getUser()?.role;


    /** Fires when form submitted
       * @function
       * @memberOf ProjectAddComponent
    */
    const handleForm = () => {
        validateFields()
            .then(({ ids, note }) => {
                const projectIds = Array.isArray(ids) ? ids : [ids];
                addAccountProjectAccess(searchParams.id, companyId, projectIds, note);
            }).catch(() => { })
    }

    /** Get available projects
       * @function
       * @returns {array}
       * @memberOf ProjectAddComponent
   */
    const getAvailableItems = () => {
        let selected = projectAccess.companies.find(p => p.id === companyId);
        selected = selected || {
            projects: [],
            availableProjects: []
        };
        let available = selected.availableProjects;
        available = available.filter(a => !(selected.projects).map(i => i.id).includes(a.id))
        available.sort((a, b) => a === "*" ? 1 : -1);
        return available;
    }

    return (
        <Modal
            title={t('backoffice.users.addNewProject')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.common.select')} ${t('backoffice.users.project')}`}
                            name="ids"
                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                        >
                            <Select
                                options={
                                    getAvailableItems().map(item => (
                                        { value: item.id, text: item.name === "*" ? t('backoffice.common.all') : item.name }
                                    ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.users.project')}`}
                                value={value}
                                onChange={value => setValue(value)}
                                isMultiple={userRole === USER_ROLE.ACCESS_MANAGER}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                        {
                            value === "*" ?
                                <small><i>{t('backoffice.users.allFutureProjects')}</i></small> :
                                null
                        }
                    </Col>
                    {
                        userRole === USER_ROLE.ACCESS_MANAGER && (
                            <Col span={24} >
                                <Form.Item
                                    label={`${t('backoffice.users.notes')} *`}
                                    name="note"
                                    rules={[
                                        { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                        { max: 1000, message: t('backoffice.validation.fieldInvalid') },
                                        { min: 6, message: t('backoffice.validation.fieldInvalid') }
                                    ]}
                                    className="rt--form-item-without-margin rt--general-form-item"
                                    data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.notes')}`}
                                >
                                    <TextAreaInput
                                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.notes')}`}
                                        maxLength={1000}
                                        rows={6}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }
                </Row>
            </Form>
        </Modal>
    )
}

/** ProjectAddComponent propTypes
    * PropTypes
*/
ProjectAddComponent.propTypes = {
    /** Redux state property, is true when user project access is adding */
    isSaving: PropTypes.bool,
    /** Redux action to add project to user */
    addAccountProjectAccess: PropTypes.func,
    /** Redux state property, represents the project access info of user  */
    projectAccess: accountProjectAccessType,
    /** Editing Company Id */
    companyId: PropTypes.string,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        addAccountProjectAccess: (id, companyId, projectId, note) => {
            dispatch(addAccountProjectAccess(id, companyId, projectId, note));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.account.isSaving,
        projectAccess: state.account.projectAccess
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAddComponent)