import React from 'react';
import i18n from "translations/config";

export const getTableColumns = (additionalProps) => {
    const { isAllProjectsSelected } = additionalProps;

    return [
        {
            title: "backoffice.users.projectName",
            dataIndex: "name",
            showOnlyValue: true,
            mobileLevel: 1,
            render: value => value === "*" ? i18n.t('backoffice.common.all') : value,
            mobileRender: (value, record) => (
                <span className="rt--font-normal rt--font-extra-bold">
                    {record.id === "*" ? i18n.t("backoffice.common.all") : value}
                </span>
            )
        },
        !isAllProjectsSelected && {
            title: "backoffice.users.projectId",
            dataIndex: "id",
            showOnlyValue: true,
            mobileLevel: 2,
            render: value => value === "*" ? "" : value,
            mobileRender: value => `${i18n.t("backoffice.users.id")}: ${value}`,
        }
    ];
}