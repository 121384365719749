import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import PaymentMethodRequisitsForm from './requisitsForm';

import { 
    getPaymentMethodFormControlsWithDetails, 
    saveAffiliatePaymentMethod 
} from "store/actions/portal/affiliates/affiliates/paymentMethods.action";

import { POPUP_SIZE } from 'constants/common.constants';
import { COMPANY_CONTROL_RULE } from 'constants/company.constants';
import { USER_ROLE } from 'constants/user.constants';

import { toLowerCaseFirstLetter } from 'utils/common';
import { isFormChanged } from 'utils/form';
import { getUser } from 'utils/auth';

import paymentMethodType from 'types/project/paymentMethod.type';
import userInfoType from 'types/profile/userInfo.type';

const PAYMENT_DETAILS_FORM_PREFIX = "paymentDetails";

/** Affiliate Payment Method Edit Popup Component */
const EditAffiliatePaymentMethod = ({
    paymentMethod,
    getPaymentMethodFormControlsWithDetails,
    paymentMethodFormControls,
    saveAffiliatePaymentMethod,
    userInfo,
    onClose
}) => {

    const { t } = useTranslation();
    const routeParams = useParams();

    const [isFormTouched, setIsFormTouched] = useState(false);

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const affiliateId = getUser()?.role === USER_ROLE.AFFILIATE ? userInfo.id : routeParams.id;

    /** Get Payment Requisite Fields list for Edit */
    useEffect(() => {
        if(paymentMethod?.id) {
            getPaymentMethodFormControlsWithDetails(paymentMethod.id, paymentMethod.currencyCode, affiliateId)
        }
    }, [paymentMethod?.id]);

    /** Form initial values */
    const initialValues = useMemo(() => {
        const activeFormControls = paymentMethodFormControls?.formControls?.filter(control => control.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN) ?? []
        const values = {};
        activeFormControls.forEach(control => {
            values[control.name] = paymentMethodFormControls?.paymentDetails?.[toLowerCaseFirstLetter(control.name)] || null;
        })
        return values;
    }, [paymentMethodFormControls])


    /** Set default values */
    useEffect(() => {
        if(paymentMethodFormControls?.paymentId === paymentMethod?.id){ // If is not old data in store
            setFieldsValue({
                [PAYMENT_DETAILS_FORM_PREFIX]: initialValues
            })
        }
    }, [paymentMethodFormControls])


    const handleForm = () => {
        validateFields()
            .then((data) => {
                const d = {
                    ...data,
                    id: affiliateId,
                    paymentId: paymentMethod?.id,
                    currencyCode: paymentMethod?.currencyCode 
                }
                if(!d[PAYMENT_DETAILS_FORM_PREFIX]){
                    d[PAYMENT_DETAILS_FORM_PREFIX] = {}
                }

                saveAffiliatePaymentMethod(d, onClose)
            }).catch(() => { })
    };

    return (
        <Modal
            title={t('backoffice.payments.editPaymentMethod')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.save')}
            onOk={handleForm}
            onCancel={onClose}
            size={POPUP_SIZE.MIDDLE}
            disableOkButton={!isFormTouched}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                onValuesChange={(_, formValues) => setIsFormTouched(isFormChanged({ ...formValues[PAYMENT_DETAILS_FORM_PREFIX] }, {...initialValues}))}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <div className='rt--flex rt--flex-col rt--text-light rt--affiliate-payment-method-edit-info rt--mb-16'>
                            <div>
                                <span className='rt--text-light rt--font-normal'>{t('backoffice.affiliates.minThreshold')}</span>
                                :&nbsp;
                                <span className='rt--title rt--font-bold'>{paymentMethod?.minThreshhold ?? "-"}</span>
                            </div>
                            <div className='rt--mt-4'>
                                <span className='rt--text-light rt--font-normal'>{t('backoffice.affiliates.commissions')}</span>
                                :&nbsp;
                                <span className='rt--title rt--font-bold'>{`${paymentMethod ? `${paymentMethod?.commission}%` :"-" }`}</span>
                            </div>
                        </div>
                    </Col>
                    {
                        paymentMethod && (
                            <PaymentMethodRequisitsForm 
                                formControls={paymentMethodFormControls?.formControls}
                                translations={paymentMethodFormControls?.translations}
                                prefix={PAYMENT_DETAILS_FORM_PREFIX}
                            />
                        )
                    }
                </Row>
            </Form>
        </Modal>
    )
}

EditAffiliatePaymentMethod.propTypes = {
    /**T he Payment Method of current editing Affiliate */
    paymentMethod: paymentMethodType,
    /** Redux action to get Affiliate Payment form controls with details */
    getPaymentMethodFormControlsWithDetails: PropTypes.func,
    /** Redux state, Payment Method Form Controls */
    paymentMethodFormControls: PropTypes.object,
    /** Redux action to save Payment Method */
    saveAffiliatePaymentMethod: PropTypes.func,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getPaymentMethodFormControlsWithDetails: (paymentId, currencyCode, id) => {
            dispatch(getPaymentMethodFormControlsWithDetails(paymentId, currencyCode, id));
        },
        saveAffiliatePaymentMethod: (params, onSuccess) => {
            dispatch(saveAffiliatePaymentMethod(params, onSuccess));
        },
    }
)

const mapStateToProps = state => {
    return {
        paymentMethodFormControls: state.affiliates.edit.finance.paymentMethods.paymentMethodFormControls,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAffiliatePaymentMethod);