import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Lightbox from "yet-another-react-lightbox";
import { Spin } from 'antd';

import CustomizedThumbnail from './customizedThumbnail';

import { getNeededPlugins } from './helpers';

import { isFunction } from 'utils/common';

/** Lightbox Component */
const LightboxComponent = ({
    items,
    onClose,
    showThumbnails = false,
    showExtraFooter = false,
    onSelect,
}) => {
    const [activeSlideData, setActiveSlideData] = useState({ index: 0 });

    const handleSlideChange = (activeSlideIndex) => {
        const selectedSlideData = items[activeSlideIndex];

        if (!Boolean(selectedSlideData)) {
            return;
        }

        const shouldLoadHTML = (
            selectedSlideData.type === "iframe" &&
            !Boolean(selectedSlideData.html) &&
            isFunction(selectedSlideData.loadHTML)
        )

        if (shouldLoadHTML) {
            selectedSlideData.loadHTML(selectedSlideData)
        }

        setActiveSlideData({
            ...selectedSlideData,
            index: activeSlideIndex,
        });
    }

    const handleSelect = (slideData) => {
        if (isFunction(onSelect)) {
            onSelect(slideData)
        }

        onClose();
    }

    const lightboxPlugins = getNeededPlugins({
        showThumbnails,
        extraFooterOptions: showExtraFooter && {
            onOk: handleSelect,
            onCancel: onClose,
        }
    })

    return (
        <>
            <Lightbox
                slides={items}
                index={activeSlideData.index}
                on={{
                    view: ({ index }) => {
                        handleSlideChange(index)
                    }
                }}
                open={true}
                close={onClose}
                plugins={lightboxPlugins}
                className='rt--lightbox'
                carousel={{
                    finite : true,
                }}
                render={{
                    buttonPrev: items.length <= 1 ? () => null : undefined,
                    buttonNext: items.length <= 1 ? () => null : undefined,
                    slide: ({ slide }) => {
                        if (slide.type === "iframe") {
                            if (!Boolean(slide.html)) {
                                return <Spin size="large" />
                            }

                            return (
                                <iframe
                                    srcDoc={slide.html}
                                    width={slide.width}
                                    height={slide.height}
                                    frameBorder="0"
                                />
                            )
                        }

                        if (slide.type === "html") {
                            return slide.html;
                        }

                        return undefined;
                    },
                    thumbnail: ({ slide }) => {
                        return (
                            <CustomizedThumbnail
                                imgSrc={slide.src}
                                name={slide.name}
                                imgSize={`${slide.height}x${slide.width}`}
                            />
                        )
                    },
                }}
                styles={{
                    root: { "--yarl__color_backdrop": "rgba(20, 20, 43, 0.8)" },
                    thumbnailsContainer: { backgroundColor: "rgba(20, 20, 43, 0.95)" },
                }}
            />
        </>
    )
}

/** Lightbox propTypes
    * PropTypes
*/
LightboxComponent.propTypes = {
    /** Items of lightbox */
    items: PropTypes.array,
    /** Function fires on lightbox close */
    onClose: PropTypes.func,
    /** Show Thumbnails */
    showThumbnails: PropTypes.bool,
    /** Fires when some picture is selected */
    onSelect: PropTypes.func,
    showExtraFooter: PropTypes.bool,
}

export default LightboxComponent;