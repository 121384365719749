import {
    SET_GENERATIONS_ACTION_BEFORE,
    SET_GENERATIONS_ACTION_FINISH,
    SET_GENERATIONS
} from '../../actionTypes';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_GENERATIONS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_GENERATIONS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_GENERATIONS:
            return {
                ...state,
                generations: payload.generations
            }
        default:
            return state;
    }
}