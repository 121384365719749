import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { 
    setSystemPaymentsActionBefore, 
    setSystemPaymentsActionFinished, 
    setSystemPaymentSaveActionBefore, 
    setSystemPaymentSaveActionFinished 
} from './systemPayments.action';

import { SET_SYSTEM_PAYMENTS_GENERAL_INFO } from "../../../../actionTypes";

const setSystemPaymentGeneralInfo = info => ({
    type: SET_SYSTEM_PAYMENTS_GENERAL_INFO,
    payload: { info }
})

export const getSystemPaymentGeneralInfo = id => {
    return dispatch => {
        dispatch(setSystemPaymentsActionBefore());

        return axios({
            url: ApiUrls.GET_SYSTEM_PAYMENT_METHOD,
            method: Methods.GET,
            params: { id }
        })
        .then(({data : {value: info}}) => {
            dispatch(setSystemPaymentGeneralInfo(info))
            dispatch(setSystemPaymentsActionFinished());
        })
        .catch((ex) => {
            dispatch(setSystemPaymentsActionFinished());
        })
    }
}

export const saveSystemPaymentGeneralInfo = info => {
    return dispatch => {
        dispatch(setSystemPaymentSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_SYSTEM_PAYMENT_METHOD,
            method: Methods.POST,
            data: {
                ...info
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setSystemPaymentGeneralInfo(data.value));
            } 
            dispatch(setSystemPaymentSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setSystemPaymentSaveActionFinished());
        })
    }
}
