import React from 'react';
import i18n from 'i18next';

import Tooltip from 'components/common/tooltip';
import Icon from 'components/common/icon';

import { TEMPLATE_TRIGGER_NAMES_TT_KEYS } from "constants/systemNotifications.constants";

export const tableColumns = [
    {
        title: "backoffice.communication.triggerName",
        dataIndex: "triggerType",
        alwaysVisible: true,
        mobileLevel: 1,
        render: (triggerType) => (
            <div className='rt--flex rt--align-center'>
                <span className='rt--mr-6'>{i18n.t(`backoffice.communication.${TEMPLATE_TRIGGER_NAMES_TT_KEYS[triggerType]}`)}</span>
                <Tooltip
                    title={<span>{i18n.t(`backoffice.communication.${TEMPLATE_TRIGGER_NAMES_TT_KEYS[triggerType]}Description`)}</span>}
                    trigger={["hover", "click"]}
                    placement="rightTop"
                    enableMobile={true}
                >
                    <Icon name='info' size={18} />
                </Tooltip>
            </div>
        )
    },
    {
        title: "backoffice.communication.modifiedAt",
        dataIndex: "modifiedAt",
        isDateTime: true,
        mobileLevel: 2,
    },
    {
        title: "backoffice.communication.modifiedBy",
        dataIndex: "modifiedBy",
        mobileLevel: 3,
    }
];