import React from 'react';

import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import Paths from 'constants/path.constants';

//#region Layouts
import RootLayout from "layouts/root";
import AuthLayoutRoute from 'layouts/auth';
import PortalLayoutRoute from 'layouts/portal';
import PortalCustomizeLayoutRoute from 'layouts/portal/customize';
import PortalMainRoute from 'layouts/portal/main';
//#endregion

//#region Routes
import LoginRoute from 'routes/auth/login.route';
import ResetPasswordRoute from 'routes/auth/resetPassword.route';
import SetPasswordRoute from 'routes/auth/setPassword.route';
import RegisterRoute from 'routes/auth/register.route';
import ActivateRoute from 'routes/auth/activate.route';
import ForgotPasswordRoute from 'routes/auth/forgotPassword.route';
import UnsubscibeRoute from 'routes/auth/unsubscibe.route';

import PortalRoute from 'routes/portal/portal.route';
import ProfileRoute from 'routes/portal/profile.route';
import FinancialRoute from 'routes/portal/financial.route';
import OperationalRoute from 'routes/portal/operational.route';
import CompaniesRoute from 'routes/portal/companies.route';
import CompaniesEditRoute from "routes/portal/companiesEdit.route";
import ProjectsRoute from 'routes/portal/projects.route';
import ProjectsEditRoute from "routes/portal/projectsEdit.route";
import CalculationApprovalsRoute from "routes/portal/calculationApprovals.route";
import InvoicesRoute from "routes/portal/invoices.route";
import BalanceHistoryRoute from "routes/portal/balanceHistory.route";
import CampaignsRoute from "routes/portal/campaigns.route";
import CampaignsEditRoute from "routes/portal/campaignEdit.route";
import MarketingReportsRoute from 'routes/portal/marketingReports.route';
import PerformanceReportsRoute from 'routes/portal/performanceReports.route';
import AggregatedDataRoute from 'routes/portal/aggregatedData.route';
import CommissionCalculationsRoute from 'routes/portal/commissionCalculations.route';
import PlayersRoute from 'routes/portal/players.route';
import MyAccountRoute from 'routes/portal/myAccount.route';
import AffiliatesRoute from 'routes/portal/affiliates.route';
import AffiliatesEditRoute from 'routes/portal/affiliatesEdit.route';
import AffiliateGroupsRoute from 'routes/portal/affiliateGroups.route';
import AffiliateGroupEditRoute from 'routes/portal/affiliateGroupEdit.route';
import AffiliateAccessRoute from 'routes//portal/affiliateAccess.route';
import UsersRoute from 'routes/portal/users.route';
import UsersEditRoute from 'routes/portal/usersEdit.route';
import SystemNotificationsRoute from 'routes/portal/systemNotifications.route';
import SystemNotificationsEditRoute from 'routes/portal/systemNotificationsEdit.route';
import NewsletterRoute from 'routes/portal/newsletter.route'
import AccessManagersRoute from 'routes/portal/accessManagers.route';
import AccessManagersEditRoute from 'routes/portal/accessManagersEdit.route';
import AffiliateManagersRoute from 'routes/portal/affiliateManagers.route';
import AffiliateManagersEditRoute from 'routes/portal/affiliateManagersEdit.route';
import PermissionsRoute from 'routes/portal/permissions.route';
import PermissionGroupsRoute from 'routes/portal/permissionGroups.route';
import PermissionGroupsEditRoute from 'routes/portal/permissionGroupsEdit.route';
import PermissionsRequestsRoute from 'routes/portal/permissionRequests.route';
import PromoTranslationsRoute from "routes/portal/promoTranslations.route";
import BOTranslationsRoute from "routes/portal/BOTranslations.route";
import UserLogsRoute from "routes/portal/userLogs.route";
import SystemCurrenciesRoute from "routes/portal/systemCurrencies.route";
import SystemLanguagesRoute from "routes/portal/systemLanguages.route";
import SystemPaymentsRoute from "routes/portal/systemPayments.route";
import SystemPaymentsEditRoute from "routes/portal/systemPaymentsEdit.route"
import ErrorsRoute from "routes/portal/errors.route";
import RequestsRoute from "routes/portal/requests.route";
import JobsRoute from "routes/portal/jobs.route";
import GenerationsRoute from "routes/portal/generations.route";
import DbConnectionsRoute from 'routes/portal/dbConnections.route';
import MonitoringRoute from 'routes/portal/monitoring.route';
import PlatformTestRoute from 'routes/portal/platformTest.route';
import PostDeploymentActionsRoute from 'routes/portal/postDeploymentActions.route';
import MediaToolsRoute from 'routes/portal/mediaTools.route';
import PromoCustomizeRoute from 'routes/portal/promoCustomize.route';
import NewsletterCustomizeRoute from 'routes/portal/newsletterCustomize.route';
//#endregion

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path={Paths.DEFAULT} element={<RootLayout />} >
            
            <Route path={Paths.LOGIN} element={<AuthLayoutRoute />} >
                <Route path="" element={<LoginRoute />} />
            </Route>

            <Route path={Paths.REGISTER} element={<AuthLayoutRoute />} >
                <Route path="" element={<RegisterRoute />} />
            </Route>

            <Route path={Paths.ACTIVATE} element={<AuthLayoutRoute />} >
                <Route path="" element={<ActivateRoute />} />
            </Route>

            <Route path={Paths.RESET_PASSWORD} element={<AuthLayoutRoute />} >
                <Route path="" element={<ResetPasswordRoute />} />
            </Route>

            <Route path={Paths.SET_PASSWORD} element={<AuthLayoutRoute />} >
                <Route path="" element={<SetPasswordRoute />} />
            </Route>

            <Route path={Paths.FORGOT_PASSWORD} element={<AuthLayoutRoute />} >
                <Route path="" element={<ForgotPasswordRoute />} />
            </Route>

            <Route path={Paths.UNSUBSCRIBE} element={<AuthLayoutRoute />} >
                <Route path="" element={<UnsubscibeRoute />} />
            </Route>

            <Route path="" element={<PortalLayoutRoute />}>
                <Route path="" element={<PortalMainRoute />} >
                    <Route path="" element={<PortalRoute />} />
                </Route>
            </Route>

            <Route path={Paths.PORTAL} element={<PortalLayoutRoute />} >
                <Route path="" element={<PortalMainRoute />} >
                    <Route path="" element={<PortalRoute />} />
                    <Route path={Paths.PROFILE} element={<ProfileRoute />} />
                    <Route path={Paths.DASHBOARD_FINANCIAL} element={<FinancialRoute />} />
                    <Route path={Paths.DASHBOARD_OPERATIONAL} element={<OperationalRoute />} />
                    <Route path={Paths.COMPANIES} element={<CompaniesRoute />} />
                    <Route path={`${Paths.COMPANIES_EDIT}/:id`} element={<CompaniesEditRoute />} />
                    <Route path={Paths.PROJECTS} element={<ProjectsRoute />} />
                    <Route path={`${Paths.PROJECTS_EDIT}/:id`} element={<ProjectsEditRoute />} />
                    <Route path={Paths.MY_ACCOUNT} element={<MyAccountRoute />} />
                    <Route path={Paths.AFFILIATES} element={<AffiliatesRoute />} />
                    <Route path={`${Paths.AFFILIATES_EDIT}/:id`} element={<AffiliatesEditRoute />} />
                    <Route path={Paths.AFFILIATE_GROUPS} element={<AffiliateGroupsRoute />} />
                    <Route path={`${Paths.AFFILIATE_GROUPS_EDIT}/:id`} element={<AffiliateGroupEditRoute />} />
                    <Route path={Paths.AFFILIATE_ACCESS} element={<AffiliateAccessRoute />} />
                    <Route path={Paths.CALCULATION_APPROVALS} element={<CalculationApprovalsRoute />} />
                    <Route path={Paths.INVOICES} element={<InvoicesRoute />} />
                    <Route path={Paths.BALANCE_HISTORY} element={<BalanceHistoryRoute />} />
                    <Route path={Paths.CAMPAIGNS} element={<CampaignsRoute />} />
                    <Route path={`${Paths.CAMPAIGNS_EDIT}/:id`} element={<CampaignsEditRoute />} />
                    <Route path={Paths.MEDIA_TOOLS} element={<MediaToolsRoute />} />
                    <Route path={Paths.MARKETING_REPORTS} element={<MarketingReportsRoute />} />
                    <Route path={Paths.PERFORMANCE_REPORTS} element={<PerformanceReportsRoute />} />
                    <Route path={Paths.COMMISSION_CALCULATIONS} element={<CommissionCalculationsRoute />} />
                    <Route path={Paths.AGGREGATED_DATA} element={<AggregatedDataRoute />} />
                    <Route path={Paths.PLAYERS} element={<PlayersRoute />} />
                    <Route path={Paths.USERS} element={<UsersRoute />} />
                    <Route path={`${Paths.USERS_EDIT}/:id`} element={<UsersEditRoute />} />
                    <Route path={Paths.SYSTEM_NOTIFICATIONS} element={<SystemNotificationsRoute />} />
                    <Route path={`${Paths.SYSTEM_NOTIFICATIONS_EDIT}/:id`} element={<SystemNotificationsEditRoute />} />
                    <Route path={Paths.NEWSLETTER} element={<NewsletterRoute />} />
                    <Route path={Paths.ACCESS_MANAGERS} element={<AccessManagersRoute />} />
                    <Route path={`${Paths.ACCESS_MANAGERS_EDIT}/:id`} element={<AccessManagersEditRoute />} />
                    <Route path={Paths.AFFILIATE_MANAGERS} element={<AffiliateManagersRoute />} />
                    <Route path={`${Paths.AFFILIATE_MANAGERS_EDIT}/:id`} element={<AffiliateManagersEditRoute />} />
                    <Route path={Paths.PERMISSION_GROUPS} element={<PermissionGroupsRoute />} />
                    <Route path={`${Paths.PERMISSION_GROUPS_EDIT}/:id`} element={<PermissionGroupsEditRoute />} />
                    <Route path={Paths.PERMISSIONS} element={<PermissionsRoute />} />
                    <Route path={Paths.PERMISSION_REQUESTS} element={<PermissionsRequestsRoute />} />
                    <Route path={Paths.TRANSLATIONS_PROMO} element={<PromoTranslationsRoute />} />
                    <Route path={Paths.TRANSLATIONS_BO} element={<BOTranslationsRoute />} />
                    <Route path={Paths.USER_LOGS} element={<UserLogsRoute />} />
                    <Route path={Paths.SETTINGS_CURRENCIES} element={<SystemCurrenciesRoute />} />
                    <Route path={Paths.SETTINGS_LANGUAGES} element={<SystemLanguagesRoute />} />
                    <Route path={Paths.SETTINGS_PAYMENTS} element={<SystemPaymentsRoute />} />
                    <Route path={`${Paths.SETTINGS_PAYMENTS_EDIT}/:id`} element={<SystemPaymentsEditRoute />} />
                    <Route path={Paths.DEVELOPER_ERRORS} element={<ErrorsRoute />} />
                    <Route path={Paths.DEVELOPER_REQUESTS} element={<RequestsRoute />} />
                    <Route path={Paths.DEVELOPER_JOBS} element={<JobsRoute />} />
                    <Route path={Paths.DEVELOPER_MONITORING} element={<MonitoringRoute />} />
                    <Route path={Paths.DEVELOPER_PLATFORM_TEST} element={<PlatformTestRoute />} />
                    <Route path={Paths.DEVELOPER_GENERATIONS} element={<GenerationsRoute />} />
                    <Route path={Paths.DEVELOPER_DB_CONNECTIONS} element={<DbConnectionsRoute />} />
                    <Route path={Paths.DEVELOPER_POST_DEPLOYMENT_ACTIONS} element={<PostDeploymentActionsRoute />} />
                </Route>

                <Route path={`${Paths.PROMO_CUSTOMIZE}/:id`} element={<PortalCustomizeLayoutRoute />} >
                    <Route path="" element={<PromoCustomizeRoute />} />
                </Route>

                <Route path={`${Paths.NEWSLETTER_CUSTOMIZE}/:id`} element={<PortalCustomizeLayoutRoute />} >
                    <Route path="" element={<NewsletterCustomizeRoute />} />
                </Route>
            </Route>
        </Route>
    )
)

export default router;