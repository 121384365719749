import { NEWSLETTER_DEFAULT_FILES, NEWSLETTER_TEMPLATE_HTML } from "constants/newsletter.constants";
import { isIOS } from "./common";

const htmlToElement = html => {  
    const parser = new DOMParser();
    const document = parser.parseFromString(html.trim(), "text/html");
    return document;
}

export const makeNewslaterHTML = (templateType, configuration) => {
    let htmlStr = NEWSLETTER_TEMPLATE_HTML[templateType];

    if(Object.keys(configuration).length === 0) return "";

    /** Replace colors */
    const colors = configuration.colors || {};
    Object.keys(colors).forEach(color => {
        htmlStr = htmlStr.replaceAll(`var(--${color})`, colors[color]);
    });

    /** Replace font family */
    htmlStr = htmlStr.replaceAll(`var(--font)`, configuration.fontFamily);

    const DOMElementDocument = htmlToElement(htmlStr);

    /** Replace images */
    const images = configuration.files || {};
    Object.keys(images).forEach(image => {
        const imageUrl = images[image] !== null ? `${import.meta.env.SYSTEM_CDN_URL}/${images[image]}` : NEWSLETTER_DEFAULT_FILES[image] ? `${import.meta.env.SYSTEM_CDN_URL}/${NEWSLETTER_DEFAULT_FILES[image]}` : null;
        
        if(imageUrl){
            DOMElementDocument.getElementById(image)?.setAttribute("src", imageUrl);
        }
    });

    /** Affiliate Logo */
    DOMElementDocument.getElementById("affiliate-logo")?.setAttribute("src", `${import.meta.env.SYSTEM_CDN_URL}/${NEWSLETTER_DEFAULT_FILES["affiliate-logo"]}`);

    /** Sections */
    const sections = configuration.sections || {};
    Object.keys(sections).forEach(section => {
        const enabled = sections[section];
        if(!enabled){
            DOMElementDocument.getElementById(section)?.remove()
        }
    });

    /** texts */
    const texts = configuration.texts || {};
    Object.keys(texts).forEach(text => {
        const textContent = texts[text];
        if(textContent !== null){
            if(DOMElementDocument.getElementById(text)){
                if(text.toLowerCase() === "buttonlink"){
                    DOMElementDocument.getElementById(text).href = textContent;
                } else {
                    DOMElementDocument.getElementById(text).innerHTML = textContent;
                }
            }
        }
    });

    htmlStr = DOMElementDocument.documentElement.outerHTML;

    return htmlStr;
}

export const openBase64HtmlInNewTab = htmlContent => {
    const content = htmlContent === null ? "" : htmlContent;
    const blob = new Blob([content], { type: 'text/html' });
    const url = URL.createObjectURL(blob);

    const aTag = document.createElement("a");
    aTag.href = url;

    if(!isIOS()){
        aTag.target = '_blank'
    }

    aTag.click()
}