import { PROJECT_PAYMENT_STATE } from "constants/project.constants";

import {
	SET_PROJECTS_ACTION_BEFORE,
	SET_PROJECTS_ACTION_FINISH,
	SET_PROJECTS,
	SET_PROJECTS_SORTING,
	SET_PROJECTS_FILTERS,
	SET_PROJECTS_SAVE_ACTION_BEFORE,
	SET_PROJECTS_SAVE_ACTION_FINISH,
	SET_PROJECT_GENERAL_INFO,
	SET_PROJECT_CUSTOM_HOSTNAMES,
	SET_PROJECT_DOMAIN_SITES,
	UPDATE_PROJECT_DOMAIN_SITES,
	SET_PROJECT_BRANDS,
	ADD_PROJECT_BRAND,
	UPDATE_PROJECT_BRAND,
	DELETE_PROJECT_BRAND,
	SET_PROJECT_CURRENCIES,
	SET_PROJECT_AVAILABLE_CURRENCIES_BEFORE,
	SET_PROJECT_AVAILABLE_CURRENCIES_FINISH,
	SET_PROJECT_AVAILABLE_CURRENCIES,
	SET_PROJECT_LANGUAGES,
	SET_PROJECT_AFFILIATE_REGISTRATION_FORM,
	SET_PROJECT_REFERRAL_SETTINGS,
	SET_PROJECT_PAYOUT_SETTINGS,
	SET_PROJECT_FEE_SETTINGS,
	SET_PROJECT_INTEGRATION,
	SET_PROJECT_CHATUI,
	SET_PROJECT_PROMO,
	SET_PROJECT_PROMO_STATUS,
	SET_GAME_PROVIDERS,
	SET_PROJECT_PAYMENT_METHODS,
	SET_PROJECT_PAYMENT_STATE,
	SET_PROJECT_PAYMENT_AVAILABLE_CURRENCIES,
	ADD_PROJECT_PAYMENT_CURRENCY,
	SET_PROJECT_PAYMENT_METHOD_DETAILS,
	CHANGE_PROJECT_PAYMENT_METHOD_DETAILS,
    SET_PROJECT_ADDITIONAL_PARAMS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_PROJECTS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true,
			};
		case SET_PROJECTS_ACTION_FINISH:
			return {
				...state,
				isLoading: false,
			};
		case SET_PROJECTS:
			return {
				...state,
				projects: !payload.add
					? payload.projects.item2
					: state.projects.concat(payload.projects.item2),
				total: payload.projects.item1,
			};

		case SET_PROJECTS_FILTERS:
			return {
				...state,
				filters: payload.filters,
				sorting: {
					...state.sorting,
					page: 1,
				},
			};
		case SET_PROJECTS_SORTING:
			return {
				...state,
				sorting: payload.sorting,
			};
		case SET_PROJECTS_SAVE_ACTION_BEFORE:
			return {
				...state,
				isSaving: true,
			};
		case SET_PROJECTS_SAVE_ACTION_FINISH:
			return {
				...state,
				isSaving: false,
			};
		case SET_PROJECT_GENERAL_INFO:
			return {
				...state,
				edit: {
					...state.edit,
					general: {
						...payload.info,
					},
				},
			};
        case SET_PROJECT_ADDITIONAL_PARAMS:
            return {
				...state,
				edit: {
					...state.edit,
					additionalParams: payload.additionalParams,
				},
			};
		case SET_PROJECT_CUSTOM_HOSTNAMES:
			return {
				...state,
				edit: {
					...state.edit,
					customHostnames: {
						...payload.hostnames,
					},
				},
			};
		case SET_PROJECT_DOMAIN_SITES:
			return {
				...state,
				edit: {
					...state.edit,
					sites: payload.sites,
				},
			};
		case UPDATE_PROJECT_DOMAIN_SITES:
			return {
				...state,
				edit: {
					...state.edit,
					sites: state.edit.sites.map((s) =>
						s.id === payload.site.id ? payload.site : s,
					),
				},
			};
		case SET_PROJECT_BRANDS:
			return {
				...state,
				edit: {
					...state.edit,
					brands: payload.brands,
				},
			};
		case ADD_PROJECT_BRAND:
			return {
				...state,
				edit: {
					...state.edit,
					brands: [payload.brand, ...state.edit.brands],
				},
			};
		case UPDATE_PROJECT_BRAND:
			return {
				...state,
				edit: {
					...state.edit,
					brands: state.edit.brands.map((brand) =>
						brand.id === payload.brand.id ? payload.brand : brand,
					),
				},
			};
		case DELETE_PROJECT_BRAND:
			return {
				...state,
				edit: {
					...state.edit,
					brands: state.edit.brands.filter((brand) => brand.id !== payload.id),
				},
			};
		case SET_PROJECT_CURRENCIES:
			return {
				...state,
				edit: {
					...state.edit,
					currencies: payload.currencies,
				},
			};
		case SET_PROJECT_AVAILABLE_CURRENCIES_BEFORE:
			return {
				...state,
				availableCurrencies: {
					...state.availableCurrencies,
					isLoading: true,
				},
			};
		case SET_PROJECT_AVAILABLE_CURRENCIES_FINISH:
			return {
				...state,
				availableCurrencies: {
					...state.availableCurrencies,
					isLoading: false,
				},
			};
		case SET_PROJECT_AVAILABLE_CURRENCIES:
			return {
				...state,
				availableCurrencies: {
					...state.availableCurrencies,
					availableCurrencies: payload.currencies,
				},
			};
		case SET_PROJECT_LANGUAGES:
			return {
				...state,
				edit: {
					...state.edit,
					languages: {
						...state.edit.languages,
						languages: payload.languages,
					},
				},
			};
		case SET_PROJECT_AFFILIATE_REGISTRATION_FORM:
			return {
				...state,
				edit: {
					...state.edit,
					registrationConfig: {
						...state.edit.registrationConfig,
						...payload.registrationConfig,
					},
				},
			};
		case SET_PROJECT_REFERRAL_SETTINGS:
			return {
				...state,
				edit: {
					...state.edit,
					referralSettings: payload.referralSettings,
				},
			};
		case SET_PROJECT_PAYOUT_SETTINGS:
			return {
				...state,
				edit: {
					...state.edit,
					payoutSettings: payload.payoutSettings,
				},
			};
		case SET_PROJECT_FEE_SETTINGS:
			return {
				...state,
				edit: {
					...state.edit,
					feeSettings: payload.feeSettings,
				},
			};
		case SET_PROJECT_INTEGRATION:
			return {
				...state,
				edit: {
					...state.edit,
					integration: payload.integration,
				},
			};
		case SET_PROJECT_PROMO:
			return {
				...state,
				edit: {
					...state.edit,
					promo: {
						...payload.info,
					},
				},
			};
		case SET_PROJECT_PROMO_STATUS:
			return {
				...state,
				edit: {
					...state.edit,
					promo: {
						...state.edit.promo,
						enabled: payload.enabled,
					},
				},
			};

		case SET_PROJECT_CHATUI:
			return {
				...state,
				edit: {
					...state.edit,
					chatUI: {
						...payload.chatUI,
					},
				},
			};
		case SET_GAME_PROVIDERS:
			return {
				...state,
				gameProviders: payload.providers,
			};
		case SET_PROJECT_PAYMENT_METHODS:
			return {
				...state,
				edit: {
					...state.edit,
					paymentMethods: {
						...state.edit.paymentMethods,
						paymentMethods: payload.paymentMethods,
					},
				},
			};
		case SET_PROJECT_PAYMENT_STATE:
			return {
				...state,
				edit: {
					...state.edit,
					paymentMethods: {
						...state.edit.paymentMethods,
						paymentMethods: state.edit.paymentMethods.paymentMethods.map(
							(paymentMethod) => {
								const newPaymentMethod = { ...paymentMethod };
								if (newPaymentMethod.id === payload.paymentId) {
									newPaymentMethod.state = payload.isChecked
										? PROJECT_PAYMENT_STATE.ACTIVE
										: PROJECT_PAYMENT_STATE.INACTIVE;
								}

								return newPaymentMethod;
							},
						),
					},
				},
			};
		case SET_PROJECT_PAYMENT_AVAILABLE_CURRENCIES:
			return {
				...state,
				edit: {
					...state.edit,
					paymentMethods: {
						...state.edit.paymentMethods,
						edit: {
							...state.edit.paymentMethods.edit,
							paymentMethod: {
								...state.edit.paymentMethods.edit.paymentMethod,
								availableCurrencies: payload.currencies,
							},
						},
					},
				},
			};
		case SET_PROJECT_PAYMENT_METHOD_DETAILS:
			return {
				...state,
				edit: {
					...state.edit,
					paymentMethods: {
						...state.edit.paymentMethods,
						edit: {
							...state.edit.paymentMethods.edit,
							paymentMethod: {
								...state.edit.paymentMethods.edit.paymentMethod,
								...payload.payment,
							},
						},
					},
				},
			};
		case CHANGE_PROJECT_PAYMENT_METHOD_DETAILS:
			return {
				...state,
				edit: {
					...state.edit,
					paymentMethods: {
						...state.edit.paymentMethods,
						edit: {
							...state.edit.paymentMethods.edit,
							paymentMethod: {
								...state.edit.paymentMethods.edit.paymentMethod,
								currencies:
									state.edit.paymentMethods.edit.paymentMethod.currencies.map(
										(currency) =>
											currency.currencyCode === payload.currency.currencyCode
												? {
														...currency,
														...payload.currency,
														commission: Number(
															payload.currency.commission,
														),
														minThreshold: Number(
															payload.currency.minThreshold,
														),
												  }
												: currency,
									),
							},
						},
					},
				},
			};
		case ADD_PROJECT_PAYMENT_CURRENCY:
			return {
				...state,
				edit: {
					...state.edit,
					paymentMethods: {
						...state.edit.paymentMethods,
						edit: {
							...state.edit.paymentMethods.edit,
							paymentMethod: {
								...state.edit.paymentMethods.edit.paymentMethod,
								currencies: [
									...state.edit.paymentMethods.edit.paymentMethod.currencies,
									{
										currencyCode: payload.currencyCode,
										commission: 0,
										minThreshold: 0,
									},
								],
							},
						},
					},
				},
			};
		default:
			return state;
	}
};
