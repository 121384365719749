import PropTypes from 'prop-types';

import { 
    COMMISSION_PLAN_CALCULATION_PERIOD,
    COMMISSION_CALCULATION_STATUS 
} from 'constants/commission.constants';

export default PropTypes.shape({
    affiliateId: PropTypes.number,
    affiliateUserName: PropTypes.string,
    calculationId: PropTypes.number,
    calculationPeriod: PropTypes.oneOf(Object.values(COMMISSION_PLAN_CALCULATION_PERIOD)),
    to: PropTypes.string,
    from: PropTypes.string,
    currencyCode: PropTypes.string,
    status: PropTypes.oneOf(Object.values(COMMISSION_CALCULATION_STATUS)),
    calculationDate: PropTypes.string,
    sportSource: PropTypes.number,
    sportCommission: PropTypes.number,
    casinoSource: PropTypes.number,
    casinoCommission: PropTypes.number,
    cpa: PropTypes.number,
    earningTotal: PropTypes.number,
    fixed: PropTypes.number,
    paidReferralCommission: PropTypes.number,
    sourceType: PropTypes.array,
    projectId: PropTypes.number
})