export const FILTER_TEST_STATE = {
    ALL : null,
    TESTING : "true",
    NOTTESTING: "false"
}

export const FILTER_PLAYER_STATE = {
    ALL: "",
    ACTIVE: "1",
    BLOCKED: "2",
    PARTIALY_BLOCKED: "4",
    UNKNOWN: "8"
}

export const FILTER_USER_STATE = {
    ALL: "",
    IN_PROGRESS: "1",
    ACTIVE: "2",
    BLOCKED: "4",
    EXPIRED: "8",
    DELETED: "16",
    LOCKED_2_FA: "32",
    LOCKED_LOGIN: "64",
    DECLINED: "128"
}

export const FILTER_COUNTRY = {
    ALL: [],
    NONE: "none"
}