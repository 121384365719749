import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { 
    setAccessManagersActionBefore, 
    setAccessManagersActionFinished, 
    setAccessManagersSaveActionBefore, 
    setAccessManagersSaveActionFinished 
} from './accessManagers.action';

import { SET_ACCESS_MANAGER_GENERAL_INFO } from "../../../../actionTypes";

const setAccessManagerGeneralInfo = info => ({
    type: SET_ACCESS_MANAGER_GENERAL_INFO,
    payload: { info }
})

export const getAccessManagerGeneralInfo = id => {
    return dispatch => {
        dispatch(setAccessManagersActionBefore());

        return axios({
            url: ApiUrls.GET_ACCESS_MANAGER_GENERAL_INFO,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: info}}) => {
            dispatch(setAccessManagerGeneralInfo(info))
            dispatch(setAccessManagersActionFinished());
        })
        .catch((ex) => {
            dispatch(setAccessManagersActionFinished());
        })
    }
}

export const saveAccessManagerGeneralInfo = user => {
    return dispatch => {
        dispatch(setAccessManagersSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_ACCESS_MANAGER_GENERAL_INFO,
            method: Methods.POST,
            data: {
                ...user
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setAccessManagerGeneralInfo(data.value));
            } 
            dispatch(setAccessManagersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAccessManagersSaveActionFinished());
        })
    }
}
