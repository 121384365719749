//#region react
import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from 'react-redux';

import { Spin} from 'antd';

import TabFormDashboardLayout from "components/layouts/tab/form";

import PromoEmpty from "./promoEmpty";
import PromoWebsite from "./promoWebsite";

import { getPromo, savePromoInfo } from "store/actions/portal/projects/promo.action";


import promoType from "types/project/promo.type";

/** Promo Component */
const Promo = ({
    getPromo,
    promo,
    isLoading
}) => {

    /** Load promo */
    useEffect(() => {
        getPromo();
    }, [])

    return (
        <TabFormDashboardLayout>
            <Spin
                spinning={isLoading}
                wrapperClassName={!promo.exists ? "rt--promo-website-spin" : ""}
            >
                {
                    promo.exists ?
                        (
                            <PromoWebsite />
                        ) : (
                            <PromoEmpty />
                        )
                }
            </Spin>
        </TabFormDashboardLayout>
    )
}


Promo.propTypes = {
    /** Redux action to get Promo */
    getPromo: PropTypes.func,
    /** Redux state property, promo info */
    promo: promoType,
    /**  Redux state property, is true when loading promo data */
    isLoading: PropTypes.bool
}

const mapDispatchToProps = dispatch => (
    {
        getPromo: () => {
            dispatch(getPromo());
        },
    }
)

const mapStateToProps = state => {
    return {
        promo: state.projects.edit.promo,
        isLoading: state.projects.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Promo)