import {
    SET_INVOICES_ACTION_BEFORE,
    SET_INVOICES_ACTION_FINISH,
    SET_INVOICES_SAVE_ACTION_BEFORE,
    SET_INVOICES_SAVE_ACTION_FINISH,
    SET_PENDING_INVOICES,
    SET_PENDING_INVOICES_SORTING,
    SET_PENDING_INVOICES_FILTERS,
    SET_INVOICES_HISTORY,
    SET_INVOICES_HISTORY_SORTING,
    SET_INVOICES_HISTORY_FILTERS,
    SET_INVOICES_AVAILABLE_PAYMENT_METHODS,
    SET_INVOICES_PAYMENT_TRANSLATIONS,
    SET_INVOICES_TOTALS_ACTION_BEFORE,
    SET_INVOICES_TOTALS_ACTION_FINISH,
    SET_INVOICES_TOTALS,
    SET_INVOICE_GENERATION_TYPE
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_INVOICES_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_INVOICES_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_INVOICES_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_INVOICES_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_INVOICES_AVAILABLE_PAYMENT_METHODS:
            return {
                ...state,
                availablePayments: payload.paymentMethods,
            };
        case SET_INVOICES_PAYMENT_TRANSLATIONS:
            return {
                ...state,
                paymentTranslations: {
                    ...state.paymentTranslations,
                    [payload.paymentId] : payload.translations
                }
            };
        case SET_PENDING_INVOICES:
            return {
                ...state,
                pending: {
                    ...state.pending,
                    invoices: !payload.add ? payload.invoices.item2 : state.pending.invoices.concat(payload.invoices.item2),
                    total: payload.invoices.item1
                }
            };
        case SET_PENDING_INVOICES_SORTING:
            return {
                ...state,
                pending: {
                    ...state.pending,
                    sorting: payload.sorting,
                }
            };
        case SET_PENDING_INVOICES_FILTERS:
            return {
                ...state,
                pending: {
                    ...state.pending,
                    filters: payload.filters,
                    sorting: {
                        ...state.pending.sorting,
                        page: 1
                    }
                }
            };
        case SET_INVOICES_HISTORY:
            return {
                ...state,
                history: {
                    ...state.history,
                    invoices: !payload.add ? payload.invoices.item2 : state.history.invoices.concat(payload.invoices.item2),
                    total: payload.invoices.item1
                }
            };
        case SET_INVOICES_HISTORY_SORTING:
            return {
                ...state,
                history: {
                    ...state.history,
                    sorting: payload.sorting,
                }
            };
        case SET_INVOICES_HISTORY_FILTERS:
            return {
                ...state,
                history: {
                    ...state.history,
                    filters: payload.filters,
                    sorting: {
                        ...state.history.sorting,
                        page: 1
                    }
                }
            };
        case SET_INVOICES_TOTALS_ACTION_BEFORE:
            return {
                ...state,
                totals: {
                    ...state.totals,
                    isLoading: true
                }
            };
        case SET_INVOICES_TOTALS_ACTION_FINISH:
            return {
                ...state,
                totals: {
                    ...state.totals,
                    isLoading: false
                }
            };
        case SET_INVOICES_TOTALS:
            return {
                ...state,
                totals: {
                    ...state.totals,
                    [payload.type]: payload.totals
                }
            };
        case SET_INVOICE_GENERATION_TYPE:
            return {
                ...state,
                invoiceGenerationType: payload.invoiceGenerationType
            };
        default:
            return state;
    }
}