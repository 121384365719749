export const USER_STATE = {
    IN_PROGRESS: 1 << 0,
    ACTIVE: 1 << 1,
    BLOCKED: 1 << 2,
    EXPIRED: 1 << 3,
    DELETED: 1 << 4,
    LOCKED_2_FA: 1 << 5,
    LOCKED_LOGIN: 1 << 6,
    DECLINED: 1 << 7
}

export const USER_GENDER = {
    MALE: 1,
    FEMALE: 2,
    NONE: 4,  
}

export const SECURITY_LEVELS = [ 1, 2, 3, 4, 5, 6, 7]

export const USER_TYPE = {
    ADMIN: 1 << 0,
    AFFILIATE_MANAGER: 1 << 1,
    AFFILIATE: 1 << 2,
    PLAYER: 1 << 3,
    ACCESS_MANAGER: 1 << 4
}

export const USER_ROLE = {
    ADMIN: "Admin",
    AFFILIATE: "Affiliate",
    AFFILIATE_MANAGER: "AffiliateManager",
    ACCESS_MANAGER: "AccessManager"
}