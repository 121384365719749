import PropTypes from 'prop-types';

import { USER_LOGS_ACTION_TYPE } from "constants/userLog.constants"

export default PropTypes.shape({
    id: PropTypes.string,
    logTime: PropTypes.string,
    userId: PropTypes.string,
    userName: PropTypes.string,
    resourceId: PropTypes.string,
    resourceName: PropTypes.string,
    resource: PropTypes.string,
    action: PropTypes.string,
    count: PropTypes.number,
    actionType: PropTypes.oneOf(Object.values(USER_LOGS_ACTION_TYPE)),
    details: PropTypes.arrayOf(PropTypes.shape({
        currentValue: PropTypes.string,
        name: PropTypes.string,
        previousValue: PropTypes.string
    }))
})