import {
    SET_REQUESTS_ACTION_BEFORE,
    SET_REQUESTS_ACTION_FINISH,
    SET_REQUESTS,
    SET_REQUESTS_SORTING,
    SET_REQUESTS_FILTERS
} from '../../actionTypes';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_REQUESTS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_REQUESTS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_REQUESTS:
            return {
                ...state,
                requests: !payload.add ? payload.requests.item2 : state.requests.concat(payload.requests.item2),
                total: payload.requests.item1,
            };
        case SET_REQUESTS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_REQUESTS_SORTING:
            return {
                ...state,
                sorting: payload.sorting
            };
        default:
            return state;
    }
}