import {
    SET_ACCESS_MANAGERS_ACTION_BEFORE,
    SET_ACCESS_MANAGERS_ACTION_FINISH,
    SET_ACCESS_MANAGERS,
    SET_ACCESS_MANAGERS_SORTING,
    SET_ACCESS_MANAGERS_FILTERS,
    SET_ACCESS_MANAGERS_SAVE_ACTION_BEFORE,
    SET_ACCESS_MANAGERS_SAVE_ACTION_FINISH,
    SET_ACCESS_MANAGER_GENERAL_INFO
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_ACCESS_MANAGERS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_ACCESS_MANAGERS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_ACCESS_MANAGERS:
            return {
                ...state,
                accessManagers: !payload.add ? payload.accessManagers.item2 : state.accessManagers.concat(payload.accessManagers.item2),
                total: payload.accessManagers.item1,
            };

        case SET_ACCESS_MANAGERS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_ACCESS_MANAGERS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_ACCESS_MANAGERS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_ACCESS_MANAGERS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_ACCESS_MANAGER_GENERAL_INFO:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...payload.info
                    }
                }
            };
        default:
            return state;
    }
};
