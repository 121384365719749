import PropTypes from 'prop-types';

import { TEMPLATE_TRIGGER_TYPE, TEMPLATE_CHANNEL_TYPE } from 'constants/systemNotifications.constants';

export default PropTypes.shape({
    id: PropTypes.string,
    triggerType: PropTypes.oneOf(Object.values(TEMPLATE_TRIGGER_TYPE)),
    channelType: PropTypes.oneOf(Object.values(TEMPLATE_CHANNEL_TYPE)),
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string
})