import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Form, Col, Row, Checkbox } from 'antd';

import Modal from "components/common/modal";
import Input from 'components/common/input';

import { createAccessManager, getAccessManagers } from 'store/actions/portal/userManagement/accessManagers/accessManagers.action';

import { getCompanyPasswordSettings } from "store/actions/portal/companies/passwordSettings.action";

import Paths from 'constants/path.constants';

import { POPUP_SIZE } from "constants/common.constants";
import { EMAIL_REGEX, USERNAME_REGEX, LAST_NAME_REGEX, FIRST_NAME_REGEX } from "constants/regex.constants";

import { validatePassword } from "utils/password";
import { hasAccessManagerEditPageAccess } from "utils/pageAccess";

import companyPasswordSettingsType from "types/company/passwordSettings.type";

import { isMobile } from 'utils/common';

/** Access Manager Creating Popup Component */
const AccessManagerCreateComponent = ({
    isSaving,
    createAccessManager,
    getAccessManagers,
    getCompanyPasswordSettings,
    passwordSettings,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields, getFieldValue } = formInstance;
    const navigate = useNavigate();

    /** Load company password settings */
    useEffect(() => {
        getCompanyPasswordSettings();
    }, [])


    /** Fires when form submitted
       * @function
       * @memberOf AccessManagerCreateComponent
    */
    const handleForm = () => {
        validateFields()
            .then((data) => {
                createAccessManager(
                    data,
                    record => {
                        if (hasAccessManagerEditPageAccess()) {
                            navigate(`${Paths.ACCESS_MANAGERS_EDIT}/${record.id}?name=${record.userName}`)
                        } else {
                            getAccessManagers();
                            onClose();
                        }
                    });
            }).catch(ex => {
                console.log(ex)
            })
    }

    return (
        <Modal
            title={t('backoffice.users.createAccessManager')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.BIG}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    firstName: "",
                    lastName: "",
                    userName: "",
                    email: "",
                    isTesting: false
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={`${t('backoffice.users.firstName')} *`}
                            name="firstName"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 48, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: FIRST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.firstName')}`}
                        >
                            <Input
                                maxLength={48}
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.firstName')}`}
                                autoFocus={(isMobile() ? false : true)}
                                autoComplete="off"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={`${t('backoffice.users.lastName')} *`}
                            name="lastName"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 48, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: LAST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            validateFirst
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.lastName')}`}
                        >
                            <Input
                                maxLength={48}
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.lastName')}`}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={t('backoffice.users.email')}
                            name="email"
                            rules={[
                                { pattern: EMAIL_REGEX, message: t('backoffice.validation.emailFormat') },
                                { max: 50, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.email')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.email')}`}
                                maxLength={50}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={`${t('backoffice.users.username')} *`}
                            name="username"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: USERNAME_REGEX, message: t('backoffice.validation.fieldInvalid') },
                            ]}
                            validateFirst
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.username')}`}
                        >
                            <Input
                                maxLength={30}
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.username')}`}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={`${t('backoffice.users.password')} *`}
                            name="password"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: passwordSettings.passwordMaxLimit || 24, message: t('backoffice.validation.fieldInvalid') },
                                ({
                                    validator(rule, value) {
                                        return validatePassword(value, passwordSettings);
                                    }
                                })
                            ]}
                            validateFirst
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.password')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.password')}`}
                                type='Password'
                                maxLength={passwordSettings.passwordMaxLimit || 24}
                                autoComplete="new-password"
                                onChange={() => {
                                    setTimeout(() => {
                                        if (getFieldValue('confirmPassword') !== "")
                                            validateFields(['confirmPassword'])
                                    }, 0)
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={`${t('backoffice.users.confirmPassword')} *`}
                            name="confirmPassword"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (value !== getFieldValue("password")) {
                                            return Promise.reject(t('backoffice.validation.passwordsDoNotMatch'))
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                            validateFirst
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.confirmPassword')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.confirmPassword')}`}
                                type='Password'
                                maxLength={passwordSettings.passwordMaxLimit || 24}
                                autoComplete="new-password"
                                onPaste={e => e.preventDefault()}
                                onContextMenu={e => e.preventDefault()}
                                onCopy={e => e.preventDefault()}
                                onChange={() => {
                                    setTimeout(() => {
                                        if (getFieldValue('confirmPassword') !== "")
                                            validateFields(['confirmPassword'])
                                    }, 0)
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <div className="rt--flex-inline rt--align-center rt--form-item-checkbox">
                            <Form.Item
                                className='rt--form-item-inline rt--form-item-without-margin'
                                name="isTesting"
                                valuePropName='checked'
                            >
                                <Checkbox />
                            </Form.Item>
                            <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t('backoffice.users.isTestAccessManager')}</span>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** AccessManagerCreateComponent propTypes
    * PropTypes
*/
AccessManagerCreateComponent.propTypes = {
    /** Redux state property, is true when creating access manager request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to create access manager */
    createAccessManager: PropTypes.func,
    /** Redux action to get access managers */
    getAccessManagers: PropTypes.func,
    /** Redux action to get password settings */
    getCompanyPasswordSettings: PropTypes.func,
    /** Redux state property, represents company password settings */
    passwordSettings: companyPasswordSettingsType,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createAccessManager: (accessManager, onSuccess) => {
            dispatch(createAccessManager(accessManager, onSuccess));
        },

        getAccessManagers: () => {
            dispatch(getAccessManagers());
        },

        getCompanyPasswordSettings: () => {
            dispatch(getCompanyPasswordSettings());
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.accessManagers.isSaving,
        passwordSettings: state.companies.edit.passwordSettings
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessManagerCreateComponent)