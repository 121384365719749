import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { 
    setAffiliateManagersActionBefore, 
    setAffiliateManagersActionFinished, 
    setAffiliateManagersSaveActionBefore, 
    setAffiliateManagersSaveActionFinished 
} from './affiliateManagers.action';

import { SET_AFFILIATE_MANAGER_GENERAL_INFO } from "../../../../actionTypes";

const setAffiliateManagerGeneralInfo = info => ({
    type: SET_AFFILIATE_MANAGER_GENERAL_INFO,
    payload: { info }
})

export const getAffiliateManagerGeneralInfo = userNameOrId => {
    return dispatch => {
        dispatch(setAffiliateManagersActionBefore());

        return axios({
            url: ApiUrls.GET_AFFILIATE_MANAGER_GENERAL_INFO,
            method: Methods.GET,
            params: {
                userNameOrId
            }
        })
        .then(({data : {value: info}}) => {
            dispatch(setAffiliateManagerGeneralInfo(info))
            dispatch(setAffiliateManagersActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliateManagersActionFinished());
        })
    }
}

export const saveAffiliateManagerGeneralInfo = user => {
    return dispatch => {
        dispatch(setAffiliateManagersSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_AFFILIATE_MANAGER_GENERAL_INFO,
            method: Methods.POST,
            data: {
                ...user
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setAffiliateManagerGeneralInfo(data.value));
            } 
            dispatch(setAffiliateManagersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliateManagersSaveActionFinished());
        })
    }
}
