import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Spin, Form, Checkbox, Row, Col } from "antd";

import {
    getAffiliateRegistrationConfig,
    saveAffiliateRegistrationConfig,
} from "store/actions/portal/projects/registrationForm.action";

import TabFormDashboardLayout from 'components/layouts/tab/form';
import FormBuilder from 'components/common/formBuilder';
import Select from "components/common/select";

import useAutosuggestion from "hooks/useAutosuggestion";

import { flagsToBinary, binaryToFlags } from 'utils/common';
import { hasPermission } from 'utils/permissions';
import { isFormChanged } from "utils/form";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { AFFILIATE_REG_TYPE } from 'constants/project.constants';
import { USER_STATE } from "constants/user.constants";

import registrationFormType from "types/project/registrationForm.type";

const REG_CONFIG_FIELDS_NAMES = {
    REG_TYPE: "registrationType",
    DEFAULT_MANAGER_ID: "defaultAffiliateManagerId"
}

/** Project Edit Page Registration Form Tab Component */
const RegistrationFormComponent = ({
    getAffiliateRegistrationConfig,
    saveAffiliateRegistrationConfig,
    formConfigs,
    isLoading,
    isSaving,
    onTabChange,
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const affiliateManagerState = useMemo(() => {
        return flagsToBinary(Object.values(USER_STATE).filter(
            (state) => state === USER_STATE.ACTIVE || state === USER_STATE.LOCKED_2_FA || state === USER_STATE.LOCKED_LOGIN
        ));
    }, [flagsToBinary, USER_STATE]);

    const [ affiliateManagers ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AFFILIATE_MANAGERS,
        actionParams: { state: affiliateManagerState }
    });
    const affiliateManagerOptions = useMemo(() => {
        return [
            { value: null, text: t("backoffice.common.none") },
            ...affiliateManagers.map(({ name, id }) => ({ text: name, value: id }))
        ];
    }, [affiliateManagers]);

    const [isConfigFormTouched, setIsConfigFormTouched] = useState(null);

    const formBuilderRef = useRef({
        handleForm: null,
        isFormTouched: null,
    });

    const sectionsPredefinedItems = useMemo(() => (
        [
            {
                title: t("backoffice.registrationform.contactDetailFields"),
                controls: ["UserName", "Password", "ConfirmPassword", "FirstName", "LastName", "Email", "PhoneNumber", "Country", "State", "City", "Address", "ZipCode", "ContactChannel",]
            },

            {
                title: t("backoffice.registrationform.paymentDetailFields"),
                controls: ["PaymentMethod", "CurrencyCode"]
            },

            {
                title: t("backoffice.registrationform.additionalFields"),
                controls: ["AdditionalInfo", "TargetMarket", "TrafficSource", "ReceiveNewsletter"]
            }
        ]
    ), []);

    const regConfigInitialValues = useMemo(() => {
        const regTypes = formConfigs.registrationType ? binaryToFlags(Object.values(AFFILIATE_REG_TYPE), formConfigs.registrationType) : [];
        return {
            [REG_CONFIG_FIELDS_NAMES.REG_TYPE]: regTypes.map((regType) => `${regType}`),
            [REG_CONFIG_FIELDS_NAMES.DEFAULT_MANAGER_ID]: formConfigs?.defaultAffiliateManager?.id || null
        };
    }, [formConfigs]);

    const handleFormTouch = (touched) => {
        onTabChange(touched)
    }

    const handleSave = (formControlsFormValues) => {
        validateFields()
            .then((data) => {
                const { [REG_CONFIG_FIELDS_NAMES.DEFAULT_MANAGER_ID]: defaultAffiliateManagerId, ...regConfigData } = data;

                if (data[REG_CONFIG_FIELDS_NAMES.REG_TYPE]) {
                    regConfigData[REG_CONFIG_FIELDS_NAMES.REG_TYPE] = flagsToBinary(data[REG_CONFIG_FIELDS_NAMES.REG_TYPE]);
                }
                regConfigData.defaultAffiliateManagerId = defaultAffiliateManagerId || null;

                saveAffiliateRegistrationConfig({ ...regConfigData, ...formControlsFormValues });
                setIsConfigFormTouched(false);
            }).catch(() => { })
    };

    /** Get form fields values */
    useEffect(() => {
        getAffiliateRegistrationConfig();
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue(regConfigInitialValues);
    }, [regConfigInitialValues]);

    useEffect(() => {
        handleFormTouch(isConfigFormTouched);
    }, [isConfigFormTouched])

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: () => formBuilderRef.current.handleForm(),
                        text: t("backoffice.common.save"),
                        enabled: hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_REGISTRATION_CONFIGURATION, action: PERMISSION_ACTION.MODIFY }),
                        loading: isSaving,
                        disabled: !(formBuilderRef.current.isFormTouched || isConfigFormTouched)
                    }
                ]
            }
        >
            <Spin spinning={isLoading}>
                <h4 className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-biger'>
                    {t("backoffice.projects.affiliateRegConfig")}
                </h4>
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        [REG_CONFIG_FIELDS_NAMES.REG_TYPE]: [`${AFFILIATE_REG_TYPE.SELF_SIGN_UP}`]
                    }}
                    onValuesChange={
                        (changed, formValues) => {
                            setIsConfigFormTouched(isFormChanged({ ...formValues }, { ...regConfigInitialValues }));
                        }
                    }
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item shouldUpdate>
                                {({ getFieldValue }) => {
                                    return (
                                        <Form.Item
                                            name={REG_CONFIG_FIELDS_NAMES.REG_TYPE}
                                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                            className='rt--general-form-item'
                                        >
                                            <Checkbox.Group
                                                options={[
                                                    { 
                                                        label: t('backoffice.projects.selfSignUp'), 
                                                        value: `${AFFILIATE_REG_TYPE.SELF_SIGN_UP}`, 
                                                        disabled: (
                                                            getFieldValue(REG_CONFIG_FIELDS_NAMES.REG_TYPE).length === 1 && 
                                                            Number(getFieldValue(REG_CONFIG_FIELDS_NAMES.REG_TYPE)[0]) === AFFILIATE_REG_TYPE.SELF_SIGN_UP 
                                                        )
                                                    },
                                                    { 
                                                        label: t('backoffice.projects.byAffiliateManager'), 
                                                        value: `${AFFILIATE_REG_TYPE.BY_AFFILIATE_MANAGER}`,  
                                                        disabled: (
                                                            getFieldValue(REG_CONFIG_FIELDS_NAMES.REG_TYPE).length === 1 && 
                                                            Number(getFieldValue(REG_CONFIG_FIELDS_NAMES.REG_TYPE)[0]) === AFFILIATE_REG_TYPE.BY_AFFILIATE_MANAGER 
                                                        )
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    )
                                }}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} xl={6}>
                            <Form.Item
                                label={t('backoffice.projects.defaultManager')}
                                name={REG_CONFIG_FIELDS_NAMES.DEFAULT_MANAGER_ID}
                                className='rt--mb-8'
                            >
                                <Select
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.defaultManager')}`}
                                    search={true}
                                    options={affiliateManagerOptions}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                />
                            </Form.Item>
                            <Form.Item>
                                <div className='rt--text-lightest rt--font-small rt--font-regular rt--font-italic'>
                                    {t("backoffice.projects.affiliateManagerTextInfo")}
                                </div>
                            </Form.Item>

                        </Col>
                    </Row>
                </Form>
                <FormBuilder
                    sections={sectionsPredefinedItems}
                    onTabChange={handleFormTouch}
                    ref={formBuilderRef}
                    formData={formConfigs.formControls}
                    userCanModify={hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_REGISTRATION_CONFIGURATION, action: PERMISSION_ACTION.MODIFY })}
                    onFormFinish={(values) => handleSave(values)}
                    fieldNameRender={fieldName => t("backoffice.registrationform." + fieldName)}
                />
            </Spin>
        </TabFormDashboardLayout>
    )
}

/** RegistrationFormComponent propTypes
    * PropTypes
*/
RegistrationFormComponent.propTypes = {
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
    /** Redux state property, is true when loading registration form data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when saving registration form data */
    isSaving: PropTypes.bool,
    /** Redux action to get affiliate registration form */
    getAffiliateRegistrationConfig: PropTypes.func,
    /** Redux action to save affiliate registration form */
    saveAffiliateRegistrationConfig: PropTypes.func,
    /** Redux state property, represents project affiliate registration form  */
    formConfigs: PropTypes.shape({
        formControls: PropTypes.arrayOf(registrationFormType),
        defaultAffiliateManager: PropTypes.shape({
            id: PropTypes.string,
            longId: PropTypes.number,
            userName: PropTypes.string
        }),
        registrationType: PropTypes.number,
    })
}

const mapDispatchToProps = dispatch => (
    {
        getAffiliateRegistrationConfig: () => {
            dispatch(getAffiliateRegistrationConfig());
        },
        saveAffiliateRegistrationConfig: formData => {
            dispatch(saveAffiliateRegistrationConfig(formData));
        },
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.projects.isLoading,
        isSaving: state.projects.isSaving,
        formConfigs: state.projects.edit.registrationConfig
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationFormComponent);
