import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router';

import { Form, Row, Col, Spin } from 'antd';

import InputWithAdditionalActions from 'components/common/InputWithAdditionalActions';
import RichEditor from 'components/common/richEditor';

import { saveNewsletterText } from 'store/actions/portal/newsletterCustomize/sections.action';

const FORM_FIELD_NAMES = {
    TITLE: "title1",
    DESCRIPTION: "description1"
}

const Section1 = ({
    isLoading,
    isSaving,
    texts,
    saveNewsletterText,
    templateVariables
}) => {
    const { t } = useTranslation();

    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { setFieldsValue } = formInstance;

    // Set Fields Value When Translations is Loaded
    useEffect(() => {
        setFieldsValue({
            [FORM_FIELD_NAMES.TITLE]: texts[FORM_FIELD_NAMES.TITLE],
            [FORM_FIELD_NAMES.DESCRIPTION]: texts[FORM_FIELD_NAMES.DESCRIPTION]
        })
    }, [texts]);

    return (
        <Spin spinning={isLoading || isSaving}>
            <div>
                <Form
                    className="rt--form"
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        [FORM_FIELD_NAMES.TITLE]: texts[FORM_FIELD_NAMES.TITLE]?.text,
                        [FORM_FIELD_NAMES.DESCRIPTION]: texts[FORM_FIELD_NAMES.DESCRIPTION]?.text
                    }}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <Form.Item
                                name={FORM_FIELD_NAMES.TITLE}
                                label={`${t('backoffice.communication.title')} *`}
                                className="rt--general-form-item"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 300, message: t('backoffice.validation.fieldInvalid') },
                                ]}
                            >
                                <InputWithAdditionalActions
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.communication.title')}`}
                                    isOverwritten={false}
                                    maxLength={100}
                                    initialValue={texts[FORM_FIELD_NAMES.TITLE]}
                                    onConfirm={(value) => {
                                        saveNewsletterText(FORM_FIELD_NAMES.TITLE, value, searchParams.id)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name={FORM_FIELD_NAMES.DESCRIPTION}
                                className="rt--general-form-item"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                ]}
                            >
                                <RichEditor.WithAdditionalActions
                                    height={200}
                                    initialValue={texts[FORM_FIELD_NAMES.DESCRIPTION]}
                                    onConfirm={value => {
                                        saveNewsletterText(FORM_FIELD_NAMES.DESCRIPTION, value, searchParams.id)
                                    }}
                                    variables={templateVariables}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Spin >
    );
}

/** Section1 propTypes
 * PropTypes
*/
Section1.propTypes = {
    /** Redux state property, is true when loading newsletter configuration */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when newsletter saving */
    isSaving: PropTypes.bool,
    /** Section texts */
    texts: PropTypes.objectOf(PropTypes.string),
    /** Redux action to save newsletter text */
    saveNewsletterText: PropTypes.func,
    /** Redux state property, template variables */
    templateVariables: PropTypes.array
};

const mapDispatchToProps = dispatch => ({
    saveNewsletterText: (name, text, id) => {
        dispatch(saveNewsletterText(name, text, id))
    }
});


const mapStateToProps = (state) => {
    return {
        isSaving: state.newsletterCustomize.isSaving,
        isLoading: state.newsletterCustomize.isLoading,
        texts: state.newsletterCustomize.configuration.texts,
        templateVariables: state.newsletterCustomize.templateVariables,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Section1);