import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Tabs from 'components/common/tabs';

import GeneralInfoComponent from './sections/general';
import AffiliatesComponent from './sections/affiliates';

/** Newsletter Edit Component */
const NewsletterEditComponent = ({
    onTabChange
}) => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.communication.general"),
            component: <GeneralInfoComponent/>
        },
        {
            title: t("backoffice.communication.receivers"),
            component: <AffiliatesComponent/>
        }
    ]

    return <Tabs.SubTabs
        items={items}
        onTabChange={onTabChange}
        isDeepView={true}
    />
}

/** NewsletterEditComponent propTypes
    * PropTypes
*/
NewsletterEditComponent.propTypes = {
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
}


export default NewsletterEditComponent;
