import PropTypes from 'prop-types';

import { INVOICE_STATE } from 'constants/invoice.constants';

export default PropTypes.shape({
    id: PropTypes.number,
    createdAt: PropTypes.string,
    createdBy: PropTypes.string,
    projectId: PropTypes.number,
    state: PropTypes.oneOf(Object.values(INVOICE_STATE)),
    affiliateId: PropTypes.number,
    affiliateUserName: PropTypes.string,
    currencyCode: PropTypes.string,
    amount: PropTypes.number,
    paymentId: PropTypes.string,
    paymentLongId: PropTypes.number,
    paymentName: PropTypes.string,
    attachmentPath: PropTypes.string,
    note: PropTypes.string,
    paymentDate: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
    invoiceRequisiteDetails: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.string
        })
    )
})