import React from "react";
import { useTranslation } from 'react-i18next';

import Tooltip from "components/common/tooltip";

import { STATUS_RESOURCES_BY_TYPE } from "./constants";

import { StatusPropTypes } from 'types/status/status.type';

const Status = ({
    status,
    type,
    icon,
    tooltip
}) => {
    const { t } = useTranslation();

    const { color, translationSource, defaults } = STATUS_RESOURCES_BY_TYPE[type];

    const renderContent = () => (
        <div
            className="rt--status"
            data-highlight={color?.[status] ?? defaults?.color}
        >
            <div className="rt--flex rt--align-center">

                <span className="rt--status-content rt--title rt--font-small">
                    {
                        t(translationSource?.[status] ?? defaults?.translationSource ?? "")
                    }
                </span>

                {
                    icon ?
                        tooltip ? (
                            <Tooltip
                                title={tooltip}
                                trigger={["hover", "click"]}
                                placement="top"
                                enableMobile={true}
                            >
                                {icon}
                            </Tooltip>
                        ) : icon
                        : null
                }
            </div>
        </div>
    )

    return (tooltip && !icon) ? (
        <Tooltip
            title={tooltip}
            trigger={["hover", "click"]}
            placement="top"
            enableMobile={true}
        >
            {renderContent()}
        </Tooltip>
    ) : renderContent()
};


Status.propTypes = StatusPropTypes;

export default Status;