import React from 'react';
import PropTypes from 'prop-types';

import { Radio, Row, Col } from 'antd';

/** Template Chooser */

const TemplateChooser = ({
    onChange,
    value,
    items
}) => {

    return (
        <Radio.Group
            value={value}
            onChange={onChange}
            className="rt--template-chooser"
        >
            <Row gutter={[16, 0]}>
                {
                    items.map(item => (
                        <Col span={24} lg={12} key={item.value} >
                            <div className='rt--template-chooser-item-wrapper'>
                                <div 
                                    className='rt--template-chooser-item rt--pl-24 rt--pr-24 rt--pb-24 rt--pt-24'
                                    onClick={e => {
                                        const parentElement = e.target.closest(".rt--template-chooser-item")
                                        const input = parentElement.querySelector("input");
                                        input.click();
                                    }}
                                >
                                    <Radio value={item.value} disabled={item.disabled} />
                                    <div className='rt--template-chooser-item-inner'>
                                        <img src={item.img} alt={item.name} className="rt--mb-16" />
                                        <b className='rt--text-secondary rt--font-big rt--font-bold rt--text-center'>{item.name}</b>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))
                }
            </Row>
        </Radio.Group>
    )
}

/** Input propTypes
    * PropTypes
*/

TemplateChooser.propTypes = {
    /** Callback which called on input change */
    onChange: PropTypes.func,
    /** Input value */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Items */
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            img: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            disabled: PropTypes.bool
        })
    )
}

export default TemplateChooser;