//#region React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
//#endregion

import { Form, Col, Row } from 'antd';

//#region Components
import Modal from "components/common/modal";
import Input from 'components/common/input';
//#endregion

//#region Actions
import { addDomainSite } from "store/actions/portal/projects/domains.action";
//#endregion

//#region Constants
import { POPUP_SIZE } from "constants/common.constants";
import { DOMAIN_REGEX } from 'constants/regex.constants';
//#endregion

/** Add Site Popup Component */
const AddSiteComponent = ({
    addDomainSite,
    isSaving,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const [value, setValue] = useState("")

    const handleForm = () => {
        validateFields()
            .then((data) => {
                addDomainSite(data.domain, onClose);
            }).catch(() => { })
    };

    return (
        <Modal
            title={t('backoffice.projects.addSite')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.add')}
            onOk={handleForm}
            disableOkButton={isSaving || value === ""}
            onCancel={onClose}
            size={POPUP_SIZE.SMALLEST}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.projects.site')} *`}
                            name="domain"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { pattern: DOMAIN_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className="rt--general-form-item rt--form-item-without-margin"
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.site')}`}
                            validateFirst
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.site')}`}
                                onChange={e => setValue(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

AddSiteComponent.propTypes = {
    /** Redux action to add site to the Project */
    addDomainSite: PropTypes.func,
    /** Redux ste property, is true when save request is in process */
    isSaving: PropTypes.bool,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        addDomainSite: (id, onSuccess) => {
            dispatch(addDomainSite(id, onSuccess));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.projects.isSaving
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSiteComponent);