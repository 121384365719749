import React from 'react';

import i18n from 'translations/config';

export const tableColumns = [
    {
        title: "backoffice.users.companyName",
        dataIndex: "name",
        showOnlyValue: true,
        mobileLevel: 1,
        render: (value, record) => (
            record.id === "*"
                ? i18n.t("backoffice.common.all")
                : value
        ),
        mobileRender: (value, record) => (
            <span className="rt--font-normal rt--font-extra-bold">
                {
                    record.id === "*"
                        ? i18n.t("backoffice.common.all")
                        : value
                }
            </span>
        ),
    },
    {
        title: "backoffice.users.id",
        dataIndex: "id",
        showOnlyValue: true,
        mobileLevel: 2,
        mobileRender: value => `${i18n.t("backoffice.users.id")}: ${value}`,
    }
];