//#region react
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
//#endregion

//#region actions
import {
    getAffiliateTrafficSources,
    deleteAffiliateTrafficSource
} from "store/actions/portal/affiliates/affiliates/general.action";
//#endregion

//#region components
import SubTabTableDashboardLayout from "components/layouts/tab/subtab/table";
import Table from "components/common/table";
import AddTrafficSource from "./trafficSource-add.component";

//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { getUser } from 'utils/auth';
//#endregion

//#region constants
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { tableColumns } from './columns';
import { USER_ROLE } from 'constants/user.constants';
//#endregion

//#region types
import { trafficSourceType } from 'types/affiliate/generalInfo.type';
import userInfoType from 'types/profile/userInfo.type';
//#endregion

/** Affiliate Edit Page TrafficSource Sub Tab Component */
const TrafficSource = ({
    getAffiliateTrafficSources,
    deleteAffiliateTrafficSource,
    isLoading,
    isSaving,
    trafficSources,
    userInfo
}) => {
    const { t } = useTranslation();
    const routeParams = useParams();

    const affiliateId = getUser()?.role === USER_ROLE.AFFILIATE ? userInfo.id : routeParams.id;

    const [showAddPopup, setShowAddPopup] = useState(null);
    const [showEditPopup, setShowEditPopup] = useState(null);

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.AFFILIATE_GENERALINFO, action: PERMISSION_ACTION.MODIFY });

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//
    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({ mainColumns: tableColumns });
    }, [])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [
        {
            title: t('backoffice.common.delete'),
            confirmationMessage: t("backoffice.projects.doYouWantToDeleteTrafficSource"),
            confirmationTitle: t("backoffice.common.delete"),
            icon: "trash",
            showConfirmation: true,
            className: "rt--button-danger",
            disabled: () => !hasModifyPermission,
            onClick: (trafficSource) => deleteAffiliateTrafficSource(
                { sourceId: trafficSource.id, id: affiliateId },
                () => getAffiliateTrafficSources(affiliateId)
            )
        },
        {
            title: t('backoffice.common.edit'),
            icon: "edit",
            onClick: (trafficSource) => setShowEditPopup(trafficSource),
            disabled: () => !hasModifyPermission
        }
    ];

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        button: {
            icon: "plus",
            type: "primary",
            onClick: () => setShowAddPopup(true),
            text: t("backoffice.affiliates.addTrafficSource"),
            enabled: hasModifyPermission
        }
    }

    //#endregion

    return (
        <SubTabTableDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading || isSaving}
                columns={mainTableColumns}
                data={trafficSources || []}
                loadFn={() => getAffiliateTrafficSources(affiliateId)}
                noPagination={true}
                disableFullView={true}
                actions={tableRowActions}
            />

            {showAddPopup && <AddTrafficSource onClose={() => setShowAddPopup(null)} />}
            {showEditPopup && <AddTrafficSource trafficSource={showEditPopup} onClose={() => setShowEditPopup(null)} />}
        </SubTabTableDashboardLayout>
    )
}

TrafficSource.propTypes = {
    /** Redux action to get Project Payment Methods */
    getAffiliateTrafficSources: PropTypes.func,
    /** Redux action to delete Affiliate Traffic Source */
    deleteAffiliateTrafficSource: PropTypes.func,
    /** Redux state property, is true when loading Affiliate Traffic Sources */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when adding/deleting Affiliate Traffic Source */
    isSaving: PropTypes.bool,
    /** Redux state, represents the Payments of current editing Project */
    trafficSources: PropTypes.arrayOf(trafficSourceType),
    /** Redux state property, the user info */
    userInfo: userInfoType,
}

const mapDispatchToProps = dispatch => (
    {
        getAffiliateTrafficSources: (affiliateId) => {
            dispatch(getAffiliateTrafficSources(affiliateId));
        },
        deleteAffiliateTrafficSource: (id, onSuccess) => {
            dispatch(deleteAffiliateTrafficSource(id, onSuccess));
        }
    }
)

const mapStateToProps = state => {
    return {
        trafficSources: state.affiliates.edit.general.trafficSources,
        isLoading: state.affiliates.isLoading,
        isSaving: state.projects.isSaving,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrafficSource)