import PropTypes from 'prop-types';

import { PERMISSION_REQUEST_OBJECT_TYPE, PERMISSION_REQUEST_TYPE, PERMISSION_REQUEST_STATUS } from 'constants/permissionRequest.constants';

export default PropTypes.shape({
    id: PropTypes.string,
    userId: PropTypes.number,
    note: PropTypes.string,
    userName: PropTypes.string,
    createdAt: PropTypes.string,
    modifiedBy: PropTypes.string,
    modifiedAt: PropTypes.string,
    changes: PropTypes.arrayOf(PropTypes.string),
    actionType: PropTypes.oneOf(Object.values(PERMISSION_REQUEST_TYPE)),
    objectType: PropTypes.oneOf(Object.values(PERMISSION_REQUEST_OBJECT_TYPE)),
    status: PropTypes.oneOf(Object.values(PERMISSION_REQUEST_STATUS)),
    objectId: PropTypes.string,
    objectName: PropTypes.string,
    rejectNote: PropTypes.string,
})