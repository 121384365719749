import React from 'react';
import PropTypes from 'prop-types';

import { Table as AntTable, Spin } from 'antd';

import { antdTableColumnsCreator, antdTableDataCreator } from '../../helpers';

import { EXPANDABLE_TYPES } from "../../constants";

const ExpandedRowTable = ({ detailsObj, type, isLoading, rowClassNameFn, formatNumber }) => {
    const showAdditionalContent = Boolean(detailsObj?.content) && type !== EXPANDABLE_TYPES.TABLE;
    const showTable = (
        type !== EXPANDABLE_TYPES.CONTENT &&
        Boolean(detailsObj?.columns) &&
        (
            !detailsObj?.hideEmpty ||
            detailsObj?.data.length > 0
        )
    )

    if (isLoading) {
        return (
            <Spin spinning={isLoading}>
                <></>
            </Spin>
        )
    }

    return (
        <>
            {showAdditionalContent && (
                detailsObj.content
            )}

            {showTable && (
                <AntTable
                    columns={antdTableColumnsCreator({
                        mainColumns: detailsObj.columns,
                        additionalColumnsObj: {
                            actions: detailsObj.actions
                        },
                        otherFeatures: {
                            formatNumber
                        }
                    })}
                    dataSource={antdTableDataCreator({
                        rowsData: detailsObj.data,
                        uniquePropFromRowData: detailsObj.uniqueKey
                    })}
                    rowClassName={rowClassNameFn}
                    pagination={false}
                />
            )}
        </>
    );
}

/** ExpandedRowTable propTypes
 * PropTypes
*/
ExpandedRowTable.propTypes = {
    detailsObj: PropTypes.object,
    type: PropTypes.string,
    isLoading: PropTypes.bool,
    rowClassNameFn: PropTypes.func,
    formatNumber: PropTypes.func
}


export default ExpandedRowTable;