import React from 'react';

import { makeImagePath } from 'utils/common';

import { INPUT_REGEX, PROMO_SECTION_TYPE, SECTION_NAMES } from "constants/promo.constants";

export const getTableColumns = (additionalProps) => {
    const {
        savePromoSectionTranslations,
        resetPromoSectionTranslations,
    } = additionalProps;

    return [
        {
            title: "backoffice.promo.title",
            dataIndex: "title",
            editFieldClassName: "rt--table-col-editable-width-200",
            editable: true,
            maxLength: 300,
            regex: INPUT_REGEX,
            enableReset: (record) => record.titleIsOverwritten,
            onReset: (record) => {
                resetPromoSectionTranslations(
                    SECTION_NAMES.TESTIMONIAL,
                    PROMO_SECTION_TYPE.TESTIMONIAL,
                    record.titleTranslationKey
                );
            },
            onEdit: (value, record) => {
                savePromoSectionTranslations(
                    SECTION_NAMES.TESTIMONIAL,
                    PROMO_SECTION_TYPE.TESTIMONIAL,
                    record.titleTranslationKey,
                    value,
                );
            }
        },
        {
            title: "backoffice.promo.description",
            dataIndex: "description",
            maxLength: 300,
            editFieldClassName: "rt--table-col-editable-width-200",
            editable: true,
            regex: INPUT_REGEX,
            enableReset: (record) => record.descriptionIsOverwritten,
            onReset: (record) => {
                resetPromoSectionTranslations(
                    SECTION_NAMES.TESTIMONIAL,
                    PROMO_SECTION_TYPE.TESTIMONIAL,
                    record.descriptionTranslationKey
                );
            },
            onEdit: (value, record) => {
                savePromoSectionTranslations(
                    SECTION_NAMES.TESTIMONIAL,
                    PROMO_SECTION_TYPE.TESTIMONIAL,
                    record.descriptionTranslationKey,
                    value,
                );
            }
        },
        {
            title: "backoffice.promo.profileImage",
            dataIndex: "logo",
            editFieldClassName: "rt--table-col-editable-width-200",
            render: (imgPath) => {
                if (Boolean(imgPath)) {
                    return (
                        <img
                            src={makeImagePath(imgPath)}
                            alt="Logo"
                            className="rt--promo-customize-img-rounded"
                        />
                    )
                }

                return "-"
            }
        }
    ]
}