import React, { useState, useEffect } from 'react';

import { useLocation } from "react-router-dom";

import BrandListComponent from './brand-list.component';
import BrandEditComponent from './edit';

const BrandsComponent = () => {
    const { search } = useLocation();

    const [ currentBrandId, setCurrentBrandId] = useState((new URLSearchParams(search)).get("brandId"));

    /** Change view depend on query string param change */
    useEffect(() => {
        const currentBrandId = (new URLSearchParams(search)).get("brandId");
        setCurrentBrandId(currentBrandId);
    }, [search])

    return !currentBrandId ? (
        <BrandListComponent />
    ) : (
        <BrandEditComponent
            brandId={currentBrandId}
        />
    )
}

export default BrandsComponent