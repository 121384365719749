//#region react
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
//#endregion

//#region actions
import {
    getSystemCurrencies,
    setSystemCurrenciesSorting
} from "store/actions/portal/settings/systemCurrencies/systemCurrencies.action";
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import SystemCurrencyAddEditComponent from "./systemCurrency-add-edit.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { SYSTEM_CURRENCY } from "constants/pageName.constants";
import ApiUrls from 'constants/api.constants';
import { tableColumns } from "./columns";
//#endregion

//#region types
import currencyType from "types/currency/currency.type";
import sortingType from "types/common/sorting.type";
//#endregion

/** System Currencies Page Component */
const SystemCurrenciesComponent = ({
    getSystemCurrencies,
    currencies,
    isLoading,
    total,
    setSystemCurrenciesSorting,
    sorting
}) => {
    const { t } = useTranslation();

    const [addEditPopupData, setAddEditPopupData] = useState({ isVisible: false });

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: SYSTEM_CURRENCY });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.SYSTEM_CURRENCIES,
        action: PERMISSION_ACTION.EXPORT,
    });

    const hasCreatePermission = hasPermission({
        resource: PERMISSION_RESOURCE.SYSTEM_CURRENCIES,
        action: PERMISSION_ACTION.CREATE,
    });

    const hasModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.SYSTEM_CURRENCIES,
        action: PERMISSION_ACTION.MODIFY,
    });

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const showAddEditPopup = (currencyCode) => {
        const addEditPopupData = {
            isVisible: true,
        }

        if (currencyCode) {
            addEditPopupData.currencyCode = currencyCode;
        }

        setAddEditPopupData(addEditPopupData);
    }

    const hideAddEditPopup = () => {
        setAddEditPopupData({
            isVisible: false,
        });
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: tableColumns,
            constructForExport: true,
            constructForInclude: true,
            includedColumns
        });
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasModifyPermission) {
        tableRowActions.push({
            title: t('backoffice.common.edit'),
            icon: "edit",
            onClick: record => showAddEditPopup(record.code),
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        buttons: [{
            icon: "plus",
            type: "primary",
            onClick: () => showAddEditPopup(),
            text: t("backoffice.currencies.addCurrency"),
            enabled: hasCreatePermission
        }],
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.currencies') }]
        }
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            columns: columnsForExport,
            tableName: t("backoffice.menu.currencies"),
            url: ApiUrls.EXPORT_SYSTEM_CURRENCIES,
        }
    }

    //#endregion

    // Close currency add/edit popup, after save
    useEffect(() => {
        hideAddEditPopup();
    }, [currencies])

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={currencies}
                loadFn={getSystemCurrencies}
                sorting={sorting}
                setSortingFn={setSystemCurrenciesSorting}
                total={total}
                actions={tableRowActions}
            />

            {addEditPopupData.isVisible && (
                <SystemCurrencyAddEditComponent
                    onClose={hideAddEditPopup}
                    editingCurrency={addEditPopupData.currencyCode}
                />
            )}
        </MainDashboardLayout>
    )
};

/** System CurrenciesComponent propTypes
 * PropTypes
*/
SystemCurrenciesComponent.propTypes = {
    /** Redux action to get system currencies */
    getSystemCurrencies: PropTypes.func,
    /** Redux state property, represents the array of system currencies  */
    currencies: PropTypes.arrayOf(currencyType),
    /** Redux state property, is true when loading system currencies */
    isLoading: PropTypes.bool,
    /** Redux state property, system currencies total count */
    total: PropTypes.number,
    /** Redux action to set system currencies sorting details */
    setSystemCurrenciesSorting: PropTypes.func,
    /** Redux state property, system currencies sorting details */
    sorting: sortingType
};

const mapDispatchToProps = dispatch => ({
    getSystemCurrencies: nextPage => {
        dispatch(getSystemCurrencies(nextPage));
    },
    setSystemCurrenciesSorting: sorting => {
        dispatch(setSystemCurrenciesSorting(sorting));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.systemCurrencies.isLoading,
        currencies: state.systemCurrencies.currencies,
        total: state.systemCurrencies.total,
        sorting: state.systemCurrencies.sorting
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SystemCurrenciesComponent);
