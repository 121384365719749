import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setProjectsActionBefore, setProjectsActionFinished, setProjectsSaveActionBefore, setProjectsSaveActionFinished } from './projects.action';

import {
    SET_PROJECT_ADDITIONAL_PARAMS
} from "../../../actionTypes";

const setAdditionalParams = additionalParams => ({
    type: SET_PROJECT_ADDITIONAL_PARAMS,
    payload: { additionalParams },
});

export const getAdditionalParams = () => {
    return (dispatch) => {
        dispatch(setProjectsActionBefore());
        return axios({
            url: ApiUrls.GET_PROJECT_ADDITIONAL_PARAMS,
            method: Methods.GET,
        })
        .then(({ data: { value: additionalParams } }) => {
            dispatch(setAdditionalParams(additionalParams));
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        });
    };
};

export const addAdditionalParam = (key, value, onSuccess) => {
    return (dispatch) => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.ADD_PROJECT_ADDITIONAL_PARAMS,
            method: Methods.POST,
            data: {
                additionalParam: {
                    [key]: value
                }
            }
        })
        .then(({ data }) => {
            dispatch(setAdditionalParams(data.value));
            if (isResponseSuccess(data)) {
                message.success(data.message);
                onSuccess && onSuccess();
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        });
    };
};

export const editAdditionalParam = (key, value) => {
    return (dispatch) => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.EDIT_PROJECT_ADDITIONAL_PARAMS,
            method: Methods.POST,
            data: {
                additionalParam: {
                    [key]: value
                }
            }
        })
        .then(({ data: { value: additionalParams } }) => {
            dispatch(setAdditionalParams(additionalParams));
            if (isResponseSuccess(data)) {
                message.success(data.message);
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        });
    };
};

export const deleteAdditionalParam = (key, value) => {
    return (dispatch) => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_PROJECT_ADDITIONAL_PARAMS,
            method: Methods.DELETE,
            data: {
                additionalParam: {
                    [key]: value
                }
            }
        })
        .then(({ data: { value: additionalParams } }) => {
            dispatch(setAdditionalParams(additionalParams));
            if (isResponseSuccess(data)) {
                message.success(data.message);
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        });
    };
};