import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Row, Col, Spin } from 'antd';

import { getProjectGeneralInfo, saveProjectGeneralInfo } from "store/actions/portal/projects/general.action";

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";
import TextAreaInput from 'components/common/textAreaInput';
import Input from 'components/common/input';

import projectGeneralInfoType from "types/project/generalInfo.type";

import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { DESCRIPTION_REGEX, DOMAIN_REGEX, NAME_REGEX } from 'constants/regex.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

const FORM_INITIAL_VALUES = {
    name: "",
    description: ""
};

/** Project Edit Page General Info Tab Main Subtab Component */
const GeneralInfoMainComponent = ({
    getProjectGeneralInfo,
    saveProjectGeneralInfo,
    isSaving,
    isLoading,
    generalInfo,
    onTabChange
}) => {
    const { t } = useTranslation();


    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_GENERALINFO, action: PERMISSION_ACTION.MODIFY });

    const formValues = useMemo(() => {
        return Object.keys(FORM_INITIAL_VALUES).reduce((acc, fieldKey) => {
            acc[fieldKey] = Object.hasOwn(generalInfo, fieldKey) ? generalInfo[fieldKey] : FORM_INITIAL_VALUES[fieldKey];

            return acc;
        }, {});
    }, [FORM_INITIAL_VALUES, generalInfo]);


    /** Fires when form submitted
       * @function
       * @memberOf GeneralInfoComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveProjectGeneralInfo({
                    ...data,
                    enabled: generalInfo.enabled
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    };

    /** Set current editing project id as global project id */
    useEffect(() => {
        getProjectGeneralInfo()
    }, []);

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue(formValues);
    }, [formValues]);

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched]);

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasModifyPermission,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
            id={generalInfo.id}
            longId={generalInfo.longId}
            alwaysShowId={true}
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={FORM_INITIAL_VALUES}
                    onValuesChange={(changed, allValues) => setIsFormTouched(isFormChanged({ ...allValues }, { ...formValues }))}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={`${t('backoffice.projects.name')} *`}
                                name="name"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                    { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst={true}
                                className={'rt--general-form-item rt--form-item-disabled'}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.name')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.name')}`}
                                    maxLength={30}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.projects.description')}
                                name="description"
                                rules={[
                                    { pattern: DESCRIPTION_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst={true}
                                className={
                                    "rt--form-item-without-margin rt--general-form-item" + (
                                        !hasModifyPermission ? " rt--form-item-disabled" : ""
                                    )
                                }
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.description')}`}
                            >
                                <TextAreaInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.description')}`}
                                    maxLength={150}
                                    rows={4}
                                    disabled={!hasModifyPermission}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** GeneralInfoMainComponent propTypes
    * PropTypes
*/
GeneralInfoMainComponent.propTypes = {
    /** Redux action to save project General info */
    saveProjectGeneralInfo: PropTypes.func,
    /** Redux state property, is true when general info is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when general info is loading */
    isLoading: PropTypes.bool,
    /** Redux state property, current editing project general info */
    generalInfo: projectGeneralInfoType,
    /** Redux action to get project General info */
    getProjectGeneralInfo: PropTypes.func,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        getProjectGeneralInfo: () => {
            dispatch(getProjectGeneralInfo());
        },

        saveProjectGeneralInfo: data => {
            dispatch(saveProjectGeneralInfo(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        generalInfo: state.projects.edit.general,
        isSaving: state.projects.isSaving,
        isLoading: state.projects.isLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoMainComponent)