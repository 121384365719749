import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { 
    setProjectsActionBefore, 
    setProjectsActionFinished,
    setProjectsSaveActionBefore,
    setProjectsSaveActionFinished 
} from './projects.action';

import { 
    SET_PROJECT_LANGUAGES
} from "../../../actionTypes";

const setProjectLanguages = languages => ({
    type: SET_PROJECT_LANGUAGES,
    payload: { languages }
})

export const getProjectLanguages = () => {
    return dispatch => {
        dispatch(setProjectsActionBefore());
        return axios({
            url: ApiUrls.GET_PROJECT_LANGUAGES,
            method: Methods.GET
        })
        .then(({data : {value: languages}}) => {
            dispatch(setProjectLanguages(languages))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const addProjectLanguage = (languageCode, onSuccess) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.ADD_PROJECT_LANGUAGE,
            method: Methods.POST,
            data: {
                languageCode
            }
        })
        .then(({data : {value: languages}}) => {
            dispatch(setProjectLanguages(languages));

            onSuccess && onSuccess();
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

