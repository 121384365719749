import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import {
    getClickReport,
    setClickReportFilters
} from 'store/actions/portal/reports/marketing/clickReport.action';

import FiltersWrapper from "components/common/filters";
import DateRangePicker from "components/common/dateRangePicker";
import Select from 'components/common/select';
import NumericInput from 'components/common/numericInput';

import useAutosuggestion from 'hooks/useAutosuggestion';

import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { MEDIA_CLICK_STATE, MEDIA_CLICK_STATE_TRANSLATION_KEYS } from 'constants/reports.constants';
import countries from 'systemData/countries';

/** Media Tool Page Filters Component */
const Filters = ({
    setClickReportFilters,
    getClickReport,
    filters,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const [allInvalid, setAllInvalid] = useState("")

    const [ campaigns, getCampaigns ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.CAMPAIGN, 
        autoGet: false
    });

    const [ brands, getBrands ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BRAND, 
        autoGet: false
    });

    const [ affiliateNames, getAffiliateNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AFFILIATES, 
        autoGet: false
    });

    const onFilterChange = (changed, setFieldsValue, values) => {
        if(changed.isAllInvalid === false || changed.isAllInvalid === ""){
            setFieldsValue({"status": ""})
        }
        setAllInvalid(values.isAllInvalid);
    }

    return (
        <FiltersWrapper
            loadFn={getClickReport}
            setFiltersFn={setClickReportFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: true },
            ]}
            onFilterChange={onFilterChange}
            onFiltersReset={() => setAllInvalid("")}
            onInit={() => {
                getAffiliateNames();
                getBrands();
                getCampaigns();
            }}
            searchFieldName={["mediaNameOrId", "date"]}
        >
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.status')}
                        name="isAllInvalid"
                    >
                        <Select
                            options={[
                                { value: "", text: t('backoffice.common.all') },
                                { value: false, text: t('backoffice.reports.valid') },
                                { value: true, text: t('backoffice.reports.invalid') }
                            ]}
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.reports.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.reason')}
                        name="status"
                        className={allInvalid !== true ? "rt--form-item-disabled" : ""}
                    >
                        <Select
                            options={[
                                { value: "", text: t('backoffice.common.all') },
                                ...(
                                    Object.values(MEDIA_CLICK_STATE)
                                    .filter(k => k !== MEDIA_CLICK_STATE.VALID)
                                    .map(k => (
                                        { value: k, text: t(MEDIA_CLICK_STATE_TRANSLATION_KEYS[k])}
                                    ))
                                )
                            ]}
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.reports.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            disabled={allInvalid !== true}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.brand')}
                        name="brandId"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    brands.map(brand => ({
                                        value: brand.id,
                                        text: brand.name
                                    }))
                                )
                            }
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.reports.brand')}`}
                            search={true}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.affiliateUsername')}
                        name="affiliateId"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    affiliateNames.map(affiliate => ({
                                        value: affiliate.id,
                                        text: affiliate.name
                                    }))
                                )
                            }
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.reports.affiliateUsername')}`}
                            search={true}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.campaign')}
                        name="campaignNameOrId"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    campaigns.map(campaign => ({
                                        value: campaign.id,
                                        text: campaign.name
                                    }))
                                )
                            }
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.reports.campaign')}`}
                            search={true}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.country')}
                        name="countryCode"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    countries.map(c => (
                                        { value: c.iso2, text: t(`backoffice.countries.${c.iso2}`) }
                                    ))
                                )
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.reports.country')}`}
                            search={true}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.ipAddress')}
                        name="iPAddress"
                        className='rt--general-form-item rt--form-item-without-margin'
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.reports.ipAddress')}`}
                    >
                        <NumericInput
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.reports.ipAddress')}`}
                            maxLength={50}
                            isIPAddress={true}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get click report */
    getClickReport: PropTypes.func,
    /** Redux action to set click report filters */
    setClickReportFilters: PropTypes.func,
    /** Redux state property, click report filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getClickReport: () => {
            dispatch(getClickReport())
        },
        setClickReportFilters: (filters, keepPage) => {
            dispatch(setClickReportFilters(filters, keepPage));
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.marketingReports.clickReport.filters,
        globalProjectId: state.common.globalProjectId,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);