import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Outlet } from "react-router-dom";
import { connect } from 'react-redux';

import { Spin } from "antd";

import withAnanymous from 'hocs/withAnanymous';

import AuthSidebar from "./sidebar";
import LanguagesDropdown from "components/common/languagesDropdown"

import { getLanguages } from 'store/actions/auth/translations.action';
import { getLogos } from 'store/actions/auth/auth.action';

import { isMobile, setUrlParam } from 'utils/common';
import LanguageUtils from 'utils/languages';

import useLogo from 'hooks/useLogo';

import { initTranslations } from 'translations/config';

import Styles from '../../components/styles'

import { LOGO_TYPE } from 'constants/common.constants';

const Layout = ({
    isLanguagesLoaded,
    getLanguages,
    translationsLoaded,
    languages,
    getLogos
}) => {

    const { logos } = useLogo()

    /** Set Favicon */
    useEffect(() => {
        const favicon = document.querySelector('link[rel="icon"]');
        favicon.href = `${import.meta.env.SYSTEM_CDN_URL}/${logos[LOGO_TYPE.FAVICON]}`
    }, [logos])

    /** Get Logos */
    useEffect(() => {
        getLogos()
    }, [])

    /** Get system languages */
    useEffect(() => {
        getLanguages();
    }, [])


    /** Set default language */
    useEffect(() => {
        if (isLanguagesLoaded) {
            let language = LanguageUtils.getSelectedLanguage()

            if (!Object.keys(languages).map(l => l.toLowerCase()).includes(language.toLowerCase())) {
                language = LanguageUtils.defaultLang;
            }
            initTranslations(language);
            setUrlParam('language', language)
        }
    }, [isLanguagesLoaded])    

    return translationsLoaded ? (
        <div className="rt--auth rt--flex">
            <Styles/>
            <div className={'rt--auth-header rt--flex rt--align-center' + (isMobile() ? ' rt--justify-between' : ' rt--justify-end')}>
                {
                    (isMobile() ? <img src={`${import.meta.env.SYSTEM_CDN_URL}/${logos[LOGO_TYPE.COLLAPSED_LOGO]}`}/> : null)
                }
                <LanguagesDropdown />
            </div>

            {
                !isMobile() && <AuthSidebar />
            }

            <div className={"rt--auth-content rt--flex rt--align-center rt--justify-center" + (isMobile() ? '' : ' rt--flex-equal')}>
                <Styles/>
                <Outlet />
            </div>
        </div>
    ) : <Spin spinning={true} />
}

/** Layout propTypes
    * PropTypes
*/
Layout.propTypes = {
    /** Redux state property , is true when languages are loaded */
    isLanguagesLoaded: PropTypes.bool,
    /** Redux action to get system languages */
    getLanguages: PropTypes.func,
    /** Redux state property , is true when translations are loaded*/
    translationsLoaded: PropTypes.bool,
    /** Redux state property, system languages */
    languages: PropTypes.object,
    /** Redux action to get logos */
    getLogos: PropTypes.func
}

const mapStateToProps = state => {
    return {
        translationsLoaded: state.auth.translations.loaded,
        languages: state.auth.translations.languages,
        isLanguagesLoaded: state.auth.translations.isLanguagesLoaded,
    }
}

const mapDispatchToProps = dispatch => (
    {
        getLanguages: () => {
            dispatch(getLanguages());
        },

        getLogos: () => {
            dispatch(getLogos());
        }
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(withAnanymous(Layout));