import React from "react";

import { Navigate } from "react-router";

import AffiliatesComponent from "pages/affiliates/affiliates/affiliates.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import { getUser } from "utils/auth";

import { USER_ROLE } from "constants/user.constants";
import Paths from "constants/path.constants";

import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const AffiliatesRoute = () => {
    if(getUser()?.role === USER_ROLE.AFFILIATE){
        return <Navigate to={Paths.MY_ACCOUNT} />
    }

    return <AffiliatesComponent />;
};

export default withPermission(
    withAuth(AffiliatesRoute),
    { resource: PERMISSION_RESOURCE.AFFILIATE, action: PERMISSION_ACTION.VIEW },
    Paths.PORTAL
);
