import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import {
    getCommissionCalculations,
    setCommissionCalculationsFilters
} from 'store/actions/portal/reports/commissionCalculations/commissionCalculations.action';

import useCurrencies from 'hooks/useCurrencies';

import FiltersWrapper from "components/common/filters";
import DateRangePicker from "components/common/dateRangePicker";
import Select from "components/common/select";

import { COMMISSION_CALCULATION_STATUS, COMMISSION_PLAN_TYPE } from 'constants/commission.constants';

/** Commission Calculations History Page Filters Component */
const Filters = ({
    setCommissionCalculationsFilters,
    getCommissionCalculations,
    filters,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const [ currencies, getCurrencies ] = useCurrencies(false);

    return (
        <FiltersWrapper
            loadFn={getCommissionCalculations}
            setFiltersFn={setCommissionCalculationsFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "modified", time: true },
                { name: "date", time: true },
            ]}
            searchFieldName={["affiliateUserNameOrId", "date"]}
            onInit={() => {
                getCurrencies();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.commissioncalculations.status')}
                        name="status"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') },
                                    { value: COMMISSION_CALCULATION_STATUS.PENDING, text: t('backoffice.commissioncalculations.pending') },
                                    { value: COMMISSION_CALCULATION_STATUS.APPROVED, text: t('backoffice.commissioncalculations.approved') },
                                ]
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissioncalculations.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.commissioncalculations.currency')}
                        name="currencyCode"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    currencies.map(item => (
                                        { value: item.code, text: item.code }
                                    ))
                                )
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissioncalculations.currency')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.commissioncalculations.commissionType')}
                        name="type"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') },
                                    { value: COMMISSION_PLAN_TYPE.REVSHARE, text: t('backoffice.commissionplans.revshare') },
                                    { value: COMMISSION_PLAN_TYPE.CPA, text: t('backoffice.commissionplans.cpa') },
                                    { value: COMMISSION_PLAN_TYPE.FIX, text: t('backoffice.commissionplans.fix') }
                                ]
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissioncalculations.commissionType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.commissioncalculations.approvedDate')}
                        name="modified"
                    >
                        <DateRangePicker
                            showTime={true}
                            allowClear={false}
                            enabledMountsCount={12}
                        />
                    </Form.Item>
                </Col>

            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get commission calculations */
    getCommissionCalculations: PropTypes.func,
    /** Redux action to set commission calculations filters */
    setCommissionCalculationsFilters: PropTypes.func,
    /** Redux state property, commission calculations filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getCommissionCalculations: () => {
            dispatch(getCommissionCalculations())
        },
        setCommissionCalculationsFilters: filters => {
            dispatch(setCommissionCalculationsFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.commissionCalculations.filters,
        globalProjectId: state.common.globalProjectId,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);