import React from 'react';

import ForgotPasswordComponent from 'components/auth/forgotPassword/forgotPassword.component';
import withAnanymous from 'hocs/withAnanymous';

const ForgotPasswordRoute = () => {
    return (
        <ForgotPasswordComponent /> 
    )
}

export default withAnanymous(ForgotPasswordRoute);