import React, { Fragment } from 'react';

import { useNavigate } from 'react-router-dom';

import { Breadcrumb } from 'antd';

import Icon from 'components/common/icon';

import useUnsavedChangesConfirmation from 'hooks/useUnsavedChangesConfirmation';

import { isMobile } from "utils/common";

import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';

import breadcrumbsType from 'types/components/breadcrumbs.type';

/** Breadcrumbs Component */
const Breadcrumbs = ({
    items,
    className,
    onBack,
    extra
}) => {

    const navigate = useNavigate();

    const navigateWithConfirmationDependFromBoth = useUnsavedChangesConfirmation({
        cb: navigate,
        subscribe: [UNSAVED_FORM_PAGE_TYPE.TAB, UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    const navigateWithConfirmationDependFromSubTab = useUnsavedChangesConfirmation({
        cb: navigate,
        subscribe: [UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    return !isMobile() ? (
        <Breadcrumb className={"rt--breadcrumbs rt--flex-equal " + (className ? className : "")}>
            <Breadcrumb.Item>
                <Icon name='home' size={16} />
            </Breadcrumb.Item>
            {
                items.map((item, index) => (
                    <Breadcrumb.Item
                        key={item.title}
                        className={item.path ? "rt--cursor-pointer" : ""}
                        onClick={() => item.path && (
                            index === 0
                                ? navigateWithConfirmationDependFromBoth(item.path, item.replace ? { replace: true } : undefined)
                                : navigateWithConfirmationDependFromSubTab(item.path, item.replace ? { replace: true } : undefined)
                        )}
                    >
                        {
                            item.title
                        }
                    </Breadcrumb.Item>
                ))
            }
        </Breadcrumb>
    ) : (
        <div className={'rt--breadcrumbs rt--flex rt--align-center rt--justify-between ' + (className ? className : "")}>
            <div className="rt--flex rt--align-center">
                {
                    (items[items.length - 2]?.path || onBack) && (
                        <Icon
                            name="left"
                            className='rt--mr-4'
                            onClick={onBack ? onBack : () => (
                                items.length > 2
                                    ? navigateWithConfirmationDependFromSubTab(items[items.length - 2].path)
                                    : navigateWithConfirmationDependFromBoth(items[items.length - 2].path)
                            )}
                        />
                    )
                }
                <h4 className='rt--title rt--font-extra-bold rt--font-biger'>
                    {items[items.length - 1].title}
                </h4>
            </div>

            {extra ? extra : <Fragment />}
        </div>
    )
}

/** Breadcrumbs propTypes
    * PropTypes
*/
Breadcrumbs.propTypes = breadcrumbsType;

export default Breadcrumbs;