import PropTypes from 'prop-types';

export default PropTypes.shape({
    id: PropTypes.string,
    longId: PropTypes.number,
    name: PropTypes.string,
    isTesting: PropTypes.bool,
    projects: PropTypes.arrayOf(PropTypes.string),
    createdAt: PropTypes.string,
    createdBy: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.string),
    formControls: PropTypes.array,
    logoPath: PropTypes.string
})