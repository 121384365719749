import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";
import TextAreaInput from 'components/common/textAreaInput';

import { addUserPermissionGroup, getUserAvailablePermissionGroups } from "store/actions/portal/userManagement/users/permissionGroups.action";

import { getUser } from 'utils/auth';

import { USER_ROLE } from 'constants/user.constants';

import userPermissionGroupType from 'types/user/permissionGroup.type';

/** Group Adding Popup Component */
const GroupAddComponent = ({
    isSaving,
    addUserPermissionGroup,
    availableGroups,
    getUserAvailablePermissionGroups,
    groups,
    isAvailableGroupsLoading,
    onClose
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const userRole = getUser()?.role;

    /** Load user names */
    useEffect(() => {
        getUserAvailablePermissionGroups(searchParams.id);
    }, [])

    /** Fires when form submitted
       * @function
       * @memberOf GroupAddComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {

                addUserPermissionGroup(searchParams.id, data.id, data.note, onClose);

            }).catch(() => { })
    }

    return (
        <Modal
            title={t('backoffice.users.addGroup')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.users.permissionGroups')}
                            name="id"
                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                        >
                            <Select
                                options={
                                    Object.keys(availableGroups)
                                        .filter(item => !groups.some(g => g.id === item))
                                        .map(item => (
                                            { value: item, text: availableGroups[item] }
                                        ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.users.permissionGroups')}`}
                                loading={isAvailableGroupsLoading}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                    {
                        userRole === USER_ROLE.ACCESS_MANAGER && (
                            <Col span={24} >
                                <Form.Item
                                    label={`${t('backoffice.users.notes')} *`}
                                    name="note"
                                    rules={[
                                        { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                        { max: 1000, message: t('backoffice.validation.fieldInvalid') },
                                        { min: 6, message: t('backoffice.validation.fieldInvalid') }
                                    ]}
                                    className="rt--form-item-without-margin rt--general-form-item"
                                    data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.notes')}`}
                                >
                                    <TextAreaInput
                                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.notes')}`}
                                        maxLength={1000}
                                        rows={6}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }
                </Row>
                
            </Form>
        </Modal>
    )
}

/** GroupAddComponent propTypes
    * PropTypes
*/
GroupAddComponent.propTypes = {
    /** Redux state property, is true when adding group request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to add permission group to user */
    addUserPermissionGroup: PropTypes.func,
    /** Redux action to get user available permission groups */
    getUserAvailablePermissionGroups: PropTypes.func,
    /** Redux state property, represents  available permission groups of user */
    availableGroups: PropTypes.object,
    /** Redux state property, represents the permission groups of current editing user */
    groups: PropTypes.arrayOf(userPermissionGroupType),
    /** Redux state property, is true when user available permission groups are loading */
    isAvailableGroupsLoading: PropTypes.bool,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        addUserPermissionGroup: (id, groupId, note, onSuccess) => {
            dispatch(addUserPermissionGroup(id, groupId, note, onSuccess));
        },
        getUserAvailablePermissionGroups: userId => {
            dispatch(getUserAvailablePermissionGroups(userId))
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.users.isSaving,
        availableGroups: state.users.edit.permissionGroups.availableGroups,
        groups: state.users.edit.permissionGroups.groups,
        isAvailableGroupsLoading: state.users.edit.permissionGroups.isAvailableGroupsLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupAddComponent)