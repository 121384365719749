import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Carousel, Spin } from "antd";

import useFinancialLiveBalances from "../../hooks/useFinancialLiveBalances";

import { getUser } from "utils/auth";
import { isMobile } from "utils/common";

import useCurrencyConversion from "hooks/useCurrencyConvertion";
import useFormat from "hooks/useFormat";

import { LIVE_BALANCES_TEXT_MAPPING } from "../../constants";
import { WALLET_TYPE } from "constants/wallet.constants";

const LiveBalances = ({ globalProjectId, currencyCode }) => {
  const { t } = useTranslation();

  const allowImpactOnCPA = getUser()?.allowImpactOnCPA;

  const { convertCurrency } = useCurrencyConversion();
  const { formatCurrencyWithSymbol, formatAmount } = useFormat();

  const { wallets, isLoading } = useFinancialLiveBalances([globalProjectId]);

  const availableWallets = Object.values(WALLET_TYPE).filter(
    (w) =>
      !allowImpactOnCPA ||
      (w !== WALLET_TYPE.CPA_BALANCE &&
        w !== WALLET_TYPE.CPA_NOT_APPROVED_BALANCE)
  );

  const amounts = useMemo(() => {
    const result = {};
    if (!currencyCode) return result;
    const currency = currencyCode?.toLowerCase();
    Object.keys(wallets).forEach((cur) => {
      const currencyWallets = wallets[cur];
      const values = Object.keys(currencyWallets);
      values.forEach((v) => {
        if (result[v] === undefined) {
          result[v] = 0;
        }
        result[v] += convertCurrency(currencyWallets[v], cur, currency);
      });
    });
    return result;
  }, [currencyCode, wallets]);

  const getAmount = (wallet) => {
    let amount = amounts[wallet] ?? 0;
    if (allowImpactOnCPA) {
      if (wallet === WALLET_TYPE.EARNING_BALANCE) {
        amount += amounts[WALLET_TYPE.CPA_BALANCE] ?? 0;
      }

      if (wallet === WALLET_TYPE.EARNING_NOT_APPROVED_BALANCE) {
        amount += amounts[WALLET_TYPE.CPA_NOT_APPROVED_BALANCE] ?? 0;
      }
    }
    return amount;
  };

  const renderWallet = (wallet) => (
    <div
      className="rt--chart-financial-live-balances-widget rt--mr-16 rt--pl-32 rt--pr-16 rt--pt-16 rt--pb-16 rt--flex-col rt--flex"
      data-type={wallet}
      key={wallet}
    >
      <b
        title={t(LIVE_BALANCES_TEXT_MAPPING[wallet].title)}
        className="rt--title rt--font-extra-bold rt--font-small"
      >
        {t(LIVE_BALANCES_TEXT_MAPPING[wallet].title)}
      </b>
      <h6
        title={
          currencyCode
            ? `${formatCurrencyWithSymbol(
                currencyCode,
                formatAmount(getAmount(wallet), currencyCode),
                false
              )}`
            : ""
        }
        className="rt--font-extra-bold rt--font-bigest rt--mt-4 rt--mb-6"
      >
        {currencyCode
          ? `${formatCurrencyWithSymbol(
              currencyCode,
              formatAmount(getAmount(wallet), currencyCode),
              false
            )}`
          : ""}
      </h6>
      <span title={t(LIVE_BALANCES_TEXT_MAPPING[wallet].description)} className="rt--text-light rt--font-regular rt--font-smallest">
        {t(LIVE_BALANCES_TEXT_MAPPING[wallet].description)}
      </span>
    </div>
  );

  return (
    <div>
      <Spin spinning={isLoading}>
        <h4 className="rt--form-section-title rt--title rt--mb-8 rt--font-medium rt--font-normal">
          {t("backoffice.dashboard.liveBalances")}
        </h4>
        <div className="rt--chart-financial-live-balances rt--flex rt--align-center">
          {!isMobile() ? (
            availableWallets.map((wallet) => renderWallet(wallet))
          ) : (
            <div className="rt--chart-financial-carousel">
              <Carousel infinite={true}>
                {availableWallets.map((wallet) => renderWallet(wallet))}
              </Carousel>
            </div>
          )}
        </div>
      </Spin>
    </div>
  );
};

LiveBalances.propTypes = {
  globalProjectId: PropTypes.string,
  currencyCode: PropTypes.string,
};

export default LiveBalances;
