import React from "react";
import PropTypes from "prop-types";

import InputCell from "./inputCell";
import TimePickerCell from "./timePickerCell";

import { EDITABLE_CELL_FIELD_TYPES } from "constants/table.constants";

/** Table Editable Cell Component */
const EditableCell = ({
    value,
    onEdit,
    record,
    extraButtons,
    maxLength,
    regex,
    type = EDITABLE_CELL_FIELD_TYPES.INPUT,
    className,
    onReset,
    enableReset,
}) => {
    const renderEditableCell = (type) => {
        switch(type) {
            case EDITABLE_CELL_FIELD_TYPES.INPUT:
                return (
                    <InputCell
                        value={value}
                        onEdit={onEdit}
                        record={record}
                        extraButtons={extraButtons}
                        maxLength={maxLength}
                        regex={regex}
                        className={className}
                        onReset={onReset}
                        enableReset={enableReset}
                    />
                )
            case EDITABLE_CELL_FIELD_TYPES.TIME_PICKER:
                return (
                    <TimePickerCell
                        value={value}
                        onEdit={onEdit}
                        record={record}
                        className={className}
                    />
                )
            default: <></>
        }
    }

    return (
        <>
            {renderEditableCell(type)}
        </>
    )
}

/** EditableCell propTypes
 * PropTypes
*/

EditableCell.propTypes = {
    /** Cell value */
    value: PropTypes.oneOfType(
        [PropTypes.string, PropTypes.array]
    ),
    /** Function to call on cell edit */
    onEdit: PropTypes.func,
    /** The record of row */
    record: PropTypes.object,
    /** The array of extra buttons */
    extraButtons: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        disabled: PropTypes.func,
        onClick: PropTypes.func,
        title: PropTypes.string
    })),
    /** Max char count of input */
    maxLength: PropTypes.number,
    /** Regex */
    regex: PropTypes.instanceOf(RegExp),
    /** Type of edit component */
    type: PropTypes.oneOf([EDITABLE_CELL_FIELD_TYPES.INPUT, EDITABLE_CELL_FIELD_TYPES.TIME_PICKER]),
    /** Class to overwrite some style of edit field */
    className: PropTypes.string,
    /** Enable reset button */
    enableReset: PropTypes.func,
    /** Fires when reset icon is clicked */
    onReset: PropTypes.func,
}

export default EditableCell;