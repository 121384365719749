import PropTypes from 'prop-types';

import { PROJECT_BRAND_PRODUCT_TYPE } from 'constants/project.constants';
import { CREATIVE_TYPE, MEDIA_TYPE, MEDIA_STATE } from "constants/campaign.constants";

export default PropTypes.shape({
    id: PropTypes.string,
    longId: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.oneOf(Object.values(MEDIA_TYPE)),
    status: PropTypes.oneOf(Object.values(MEDIA_STATE)),
    createdAt: PropTypes.string,
    createdBy: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
    affiliateId: PropTypes.string,
    affiliateLongId: PropTypes.number,
    affiliateUserName: PropTypes.string,
    product: PropTypes.oneOf(Object.values(PROJECT_BRAND_PRODUCT_TYPE)),
    languageCode: PropTypes.string,
    campaignId: PropTypes.string,
    campaignName: PropTypes.string,
    brandId: PropTypes.string,
    brandDomain: PropTypes.string,
    brandName: PropTypes.string,
    viewCount: PropTypes.number,
    clickCount: PropTypes.number,
    creativeId: PropTypes.string,
    creativeType: PropTypes.oneOf(Object.values(CREATIVE_TYPE)),
    creativeFilePath: PropTypes.string,
    url: PropTypes.string,
    trafficSources: PropTypes.arrayOf(PropTypes.number)
})