import PropTypes from 'prop-types';

import {
    COMMISSION_PLAN_STATE,
    COMMISSION_PLAN_TYPE,
    COMMISSION_PLAN_SOURCE,
    COMMISSION_PLAN_STRUCTURE,
    COMMISSION_PLAN_SOURCE_TYPE
} from 'constants/commission.constants';
import { PROJECT_BRAND_PRODUCT_TYPE } from 'constants/project.constants';

export default PropTypes.shape({
    id: PropTypes.string,
    longId: PropTypes.number,
    name: PropTypes.string,
    currencyCode: PropTypes.string,
    createdAt: PropTypes.string,
    createdBy: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
    type: PropTypes.oneOf(Object.values(COMMISSION_PLAN_TYPE)),
    status: PropTypes.oneOf(Object.values(COMMISSION_PLAN_STATE)),
    revShareConfiguration: PropTypes.shape({
        source: PropTypes.oneOf(Object.values(COMMISSION_PLAN_SOURCE)),
        product: PropTypes.oneOf(Object.values(PROJECT_BRAND_PRODUCT_TYPE)),
        structure: PropTypes.oneOf(Object.values(COMMISSION_PLAN_STRUCTURE)),
        percent: PropTypes.number,
        sourceType: PropTypes.oneOf(Object.values(COMMISSION_PLAN_SOURCE_TYPE)),
        range: PropTypes.arrayOf(
            PropTypes.shape({
                from: PropTypes.number,
                to: PropTypes.number,
                value: PropTypes.number
            })
        ),
    }),
    cpaConfiguration: PropTypes.shape({
        structure: PropTypes.oneOf(Object.values(COMMISSION_PLAN_STRUCTURE)),
        amount: PropTypes.number,
        range: PropTypes.arrayOf(
            PropTypes.shape({
                from: PropTypes.number,
                to: PropTypes.number,
                value: PropTypes.number
            })
        )
    }),
    fixedConfiguration: PropTypes.shape({
        amount: PropTypes.number
    })
})