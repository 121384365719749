import {
    SET_REGISTRATION_FORM_ACTION_BEFORE,
    SET_REGISTRATION_FORM_ACTION_FINISH,
    SET_REGISTRATION_FORM,
    SET_REGISTRATION_ACTION_BEFORE,
    SET_REGISTRATION_ACTION_FINISH,
    SET_REGISTRATION_AVAILABLE_CURRENCIES,
    SET_REGISTRATION_AVAILABLE_PAYMENTS,
    SET_REGISTRATION_AVAILABLE_CHANNELS,
    SET_REGISTRATION_PASSWORD_SETTINGS,
    SET_REGISTRATION_PAYMENT_DETAILS
} from '../../actionTypes';


export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_REGISTRATION_FORM_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_REGISTRATION_FORM_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_REGISTRATION_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true
            }
        case SET_REGISTRATION_ACTION_FINISH:
            return {
                ...state,
                isSaving: false
            }
        case SET_REGISTRATION_FORM:
            return {
                ...state,
                registrationForm: payload.registrationForm
            }
        case SET_REGISTRATION_AVAILABLE_CURRENCIES:
            return {
                ...state,
                availableCurrencies: payload.currencies
            }
        case SET_REGISTRATION_AVAILABLE_PAYMENTS:
            return {
                ...state,
                availablePayments: payload.payments
            }
        case SET_REGISTRATION_AVAILABLE_CHANNELS:
            return {
                ...state,
                availableChannels: payload.channels
            }
        case SET_REGISTRATION_PASSWORD_SETTINGS:
            return {
                ...state,
                passwordSettings: payload.passwordSettings
            }
        case SET_REGISTRATION_PAYMENT_DETAILS:
            return {
                ...state,
                paymentDetails: payload.paymentDetails
            }
        default:
            return state;
    }
}