import React from "react";

import MediaToolsComponent from "pages/marketingTools/mediaTools/mediaTools.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const MediaToolsRoute = () => {
    return <MediaToolsComponent />;
};

export default withPermission(
    withAuth(MediaToolsRoute),
    { resource: PERMISSION_RESOURCE.MARKETING_TOOLS_MEDIA, action: PERMISSION_ACTION.VIEW },
    Paths.PORTAL
);
