//#region react
import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
//#endregion

//#region libraries
import html2canvas from 'html2canvas';
import { Spin } from "antd";
//#endregion

//#region actions
import {
    getPlatforms,
    testPlatform,
    resetPlatformTests
} from "store/actions/portal/platform/platform.action";
//#endregion

//#region components
import MainDashboardLayout from "components/layouts/main";
import Table from "components/common/table";
import Icon from "components/common/icon";
//#endregion

//#region utils
import { isMobile, copyElementToClipboard } from "utils/common";
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import { tableColumns } from "./columns";
//#endregion

//#region types
import platformType from "types/platform/platform.type";
import platformTestType from "types/platform/platformTest.type";
//#endregion

const ROW_UNIQUE_KEY_PROP = "description";

/** Platform Test Component */
const PlatformTestComponent = ({
    platforms,
    platformTests,
    isTesting,
    getPlatforms,
    testPlatform,
    resetPlatformTests
}) => {
    const { t } = useTranslation();

    const [platformId, setPlatformId] = useState(null);

    const isMobileDevice = isMobile();

    const headerDropdownItems = platforms.map(platform => ({
        key: platform.id,
        value: platform.name
    }))

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const getScreenshot = (id) => {
        const elem = document.getElementById(`gateway-${id}`);

        if (!Boolean(elem)) {
            return;
        }

        html2canvas(elem).then(canvas => {
            const base64image = canvas.toDataURL("image/png");

            const image = document.createElement("img");
            image.src = base64image;
            document.body.appendChild(image);
            copyElementToClipboard(image);
            image.remove();
        });
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({ mainColumns: tableColumns })
    }, [])

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.platformTest') }]
        }
    }

    if (isMobileDevice) {
        headerPartsData.buttons = [{
            text: t("backoffice.platforms.test"),
            icon: "test",
            type: "primary",
            enabled: true,
            disabled: !platformId,
            onClick: () => testPlatform(platformId),
        }]
    }

    const headerDropdownData = {
        placeholder: `${t("backoffice.common.select")} ${t("backoffice.platforms.platform")}`,
        value: platformId,
        items: headerDropdownItems,
        onChange: value => setPlatformId(value),
    }

    if (!isMobileDevice) {
        headerDropdownData.button = {
            text: t("backoffice.platforms.test"),
            icon: "test",
            type: "primary",
            enabled: true,
            disabled: !platformId,
            onClick: () => testPlatform(platformId),
        }
    }

    //#endregion

    // Load Platforms
    useEffect(() => {
        getPlatforms();

        return () => {
            resetPlatformTests()
        }
    }, [])

    return (
        <MainDashboardLayout
            header={headerPartsData}
            dropdowns={[headerDropdownData]}
        >
            <Spin spinning={isTesting}>
                {platformTests.gateways.map((gateway, index) => (
                    <div
                        id={`gateway-${gateway.id}`}
                        key={gateway.id}
                        className={index !== platformTests.gateways.length - 1 ? "rt--mb-24" : ""}
                    >
                        <h4 className='rt--title rt--font-bold rt--font-big rt--flex rt--justify-between rt--align-center rt--mb-16'>
                            <span>{gateway.name}</span>
                            <Icon name="copy" className="rt--cursor-pointer" onClick={() => getScreenshot(gateway.id)} />
                        </h4>

                        <Table
                            columns={mainTableColumns}
                            data={gateway.tests || []}
                            total={gateway.tests.length}
                            uniqueKey={ROW_UNIQUE_KEY_PROP}
                            disableFullView={true}
                            noPagination={true}
                        />
                    </div>
                ))}
            </Spin>
        </MainDashboardLayout>
    )
};

/** PlatformTestComponent propTypes
 * PropTypes
*/
PlatformTestComponent.propTypes = {
    /** Redux action to get platforms */
    getPlatforms: PropTypes.func,
    /** Redux action to test platform */
    testPlatform: PropTypes.func,
    /** Redux state property, platforms */
    platforms: PropTypes.arrayOf(platformType),
    /** Redux state property, platform tests */
    platformTests: platformTestType,
    /** Redux state property, to reset platform tests */
    resetPlatformTests: PropTypes.func,
    /** Redux state property, is true when testing */
    isTesting: PropTypes.bool,
};

const mapDispatchToProps = dispatch => ({
    getPlatforms: () => {
        dispatch(getPlatforms())
    },

    testPlatform: platformId => {
        dispatch(testPlatform(platformId))
    },

    resetPlatformTests: () => {
        dispatch(resetPlatformTests())
    }
});

const mapStateToProps = (state) => {
    return {
        isTesting: state.platforms.isTesting,
        platforms: state.platforms.platforms,
        platformTests: state.platforms.platformTests
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlatformTestComponent);
