import React from "react";

import RequestsComponent from "pages/developer/requests/requests.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const RequestsRoute = () => {
    return <RequestsComponent />;
};

export default withPermission(
    withAuth(RequestsRoute),
    { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
    Paths.DEFAULT
);