import {
    SET_PLAYERS_ACTION_BEFORE,
    SET_PLAYERS_ACTION_FINISH,
    SET_PLAYERS,
    SET_PLAYERS_SORTING,
    SET_PLAYERS_FILTERS,
    SET_PLAYERS_SAVE_ACTION_BEFORE,
    SET_PLAYERS_SAVE_ACTION_FINISH,
    SET_UPDATE_PLAYER_DATA,
    SET_PLAYER_GENERAL_INFO,
    SET_PLAYER_GENERAL_INFO_BY_EXTERNAL_ID
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_PLAYERS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_PLAYERS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_PLAYERS:
            return {
                ...state,
                players: !payload.add ? payload.players.item2 : state.players.concat(payload.players.item2),
                total: payload.players.item1,
            };
        case SET_UPDATE_PLAYER_DATA:
            return {
                ...state,
                players: state.players.map(player => player.id === payload.id ? payload.data : player)
            };
        case SET_PLAYERS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_PLAYERS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_PLAYERS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_PLAYERS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_PLAYER_GENERAL_INFO:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...payload.info
                    }
                }
            }
        case SET_PLAYER_GENERAL_INFO_BY_EXTERNAL_ID:
            return {
                ...state,
                info: payload.info ? {
                    ...payload.info
                } : null,
                withdrawal: null
            }
        default:
            return state;
    }
};
