import PropTypes from 'prop-types';

import { REQUEST_DIRECTION } from 'constants/project.constants';

export default PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    createdAt: PropTypes.string,
    httpStatus: PropTypes.string,
    httpStatusCode: PropTypes.number,
    httpStatusDescription: PropTypes.string,
    method: PropTypes.string,
    projectId: PropTypes.string,
    projectName: PropTypes.string,
    requestDirection: PropTypes.oneOf(Object.values(REQUEST_DIRECTION)),
    request: PropTypes.shape({
        action: PropTypes.string,
        url: PropTypes.string,
        body: PropTypes.string,
        queryStrings: PropTypes.objectOf(PropTypes.string),
        headers: PropTypes.objectOf(PropTypes.string),
    }),
    response: PropTypes.shape({
        content: PropTypes.string,
        errorMessage: PropTypes.string,
        statusCode: PropTypes.number,
        status: PropTypes.string,
        headers: PropTypes.objectOf(PropTypes.string),
    }),
    machineName: PropTypes.string
})