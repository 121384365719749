import React from "react";

import {
  changeRGBAOpacityToGivenNumber,
  customizeIndicationLine,
  validateRGBA,
  yAxisTextDefaultFormatter,
} from "./helpers";

import { isMobile } from 'utils/common';

import {
  LINE_CHART_AVAILABLE_COLOR,
  MARKET_SYMBOL_TYPES,
} from "constants/lineChart.constants";
import { isFunction } from "utils/common";

//#region ------------------------------------- X AXIS CONFIGS ----------------------------------------//

export const getXAxisConfigs = ({
  textColor = LINE_CHART_AVAILABLE_COLOR.GRAY,
  fontSize = isMobile ? "10" : "12",
  checkPoints = [],
} = {}) => {
  return {
    title: {
      text: null,
    },
    max: isMobile() ? checkPoints.length - 0.70 : null,
    categories: checkPoints,
    labels: {
      style: {
        color: textColor,
        fontSize: fontSize,
      },
    },
    tickAmount: checkPoints.length, // Adjust if necessary
  };
};

//#endregion

//#region ------------------------------------- Y AXIS CONFIGS ----------------------------------------//

export const getYAxisConfigs = ({
  textColor = LINE_CHART_AVAILABLE_COLOR.GRAY,
  fontSize = isMobile ? "10" : "12",
  horizontalLinesColor = "#EEEEEE",
  textFormatter = yAxisTextDefaultFormatter,
} = {}) => {
  return {
    title: {
      text: null,
    },
    gridLineColor: horizontalLinesColor,
    labels: {
      style: {
        color: textColor,
        fontSize: fontSize,
      },
      formatter: function () {
        const value = this.value;
        return textFormatter(value);
      },
    },
    // Ensure axis labels do not overlap
    stackLabels: {
      enabled: false,
    },
    // Allow space for labels if necessary
    padding: {
      top: 10,
      bottom: 10,
    },
  };
};

//#endregion

//#region ------------------------------------- LEGEND CONFIGS ----------------------------------------//

export const getLegendConfigs = ({
  horizontalAlignment = "center",
  verticalAlignment = "bottom",
  valueFormatter = value => value
} = {}) => {
  return {
    legend: {
      enabled: true,
      align: horizontalAlignment,
      verticalAlign: verticalAlignment,
      useHTML: true,
      symbolWidth: 0,
      labelFormatter: function () {
        // Access the legend items count
        const legendItemsCount = this.chart.series.length;

        // Add a class if there is only one legend item
        const legendItemClass = legendItemsCount === 1 ? "rt--highchart-legend-single" : "";

        // Function to convert rgba() string to a new rgba() with given opacity
        function adjustOpacity(rgbaString, opacity) {
          const match = rgbaString.match(/rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/);
          if (!match) return rgbaString; // Return the original string if it doesn't match the expected format
          const [, r, g, b] = match;
          return `rgba(${r}, ${g}, ${b}, ${opacity})`;
        }

        // Adjust the this.color from rgba() to rgba with 0.37 opacity
        const rgbaColor = adjustOpacity(this.color, 0.14);

        // Calculate total from data series
        const series = this.chart.series.find(s => s.name === this.name);
        const total = series ? series.yData.reduce((sum, y) => sum + (y || 0), 0) : 0;
        
        const symbol = `
          <div class="rt--highchart-legend">
            <div
              class="rt--highchart-legend-bg"
              style="background-color: ${this.color}"
            >
            </div>

            <div class="rt--highchart-legend-circle" style="background-color: ${this.color};
              box-shadow: 
              0px 100px 80px 0px ${rgbaColor},
              0px 64.815px 46.852px 0px ${rgbaColor}, 
              0px 38.519px 25.481px 0px ${rgbaColor}, 
              0px 20px 13px 0px ${rgbaColor}, 
              0px 8.148px 6.519px 0px ${rgbaColor}, 
              0px 1.852px 3.148px 0px ${rgbaColor};
            "> 
            </div>
          </div>
        `;

        return `
          <div
            class="${legendItemClass}"
            style="display:flex; font-size: 12px; color: rgb(51, 51, 51); font-weight: 500"
          > 
          <div class='rt--legend-item rt--flex rt--flex-col rt--justify-center rt--align-center'>
          <div class='rt--flex'>
            ${symbol} 
             <span>
              ${this.name}
            </span>
          </div>
            
            <span class='rt--highchart-legend-count'>
                ${valueFormatter(total)}
            </span>
            </div>
            
          </div>
        `;
      },
    },
  };
};



//#endregion

//#region ------------------------------- CHECKPOINTS TOOLTIP CONFIGS ---------------------------------//

// tooltipHTMLGenerator should return HTML(not react component) for render tooltip on checkpoints hover
export const getCheckPointsTooltipConfigs = ({ tooltipHTMLGenerator }) => {
  return {
    useHTML: true,
    borderWidth: 0,
    backgroundColor: "transparent",
    shadow: false,
    formatter: function () {
      return isFunction(tooltipHTMLGenerator) ? (
        tooltipHTMLGenerator(this)
      ) : (
        <></>
      );
    },
  };
};

//#endregion

//#region ------------------------------------ MAIN LINE CONFIGS --------------------------------------//

export const getMainLineConfigs = ({
  data = [],
  name = `<span class='rt--highchart-legend-title'></span>`,
  // Main Line Configs
  mainLineColor = LINE_CHART_AVAILABLE_COLOR.BLUE,
  mainLineWidth = 4,
  mainLineWidthOnHover = 4,

  // Indication Symbol Configs
  indicationSymbolType = MARKET_SYMBOL_TYPES.CIRCLE,
  indicationSymbolColor = LINE_CHART_AVAILABLE_COLOR.WHITE,
  indicationSymbolBorderColor = mainLineColor,
  indicationSymbolBorderWidth = 3,

  // Indication Line Configs
  indicationLineColor = mainLineColor,

  // Line Shadow Configs
  lineShadowColor = mainLineColor,

  // Line Area Shadow Configs
  lineAreaShadowColor = mainLineColor,

  showMarkersOnlyOnHover = true,
} = {}) => {
  if (!validateRGBA(mainLineColor)) {
    console.error(
      new Error(
        "mainLineColor must be in 'rgba' format only, please fix it, otherwise the function will not work."
      )
    );

    return {};
  }

  return {
    data,
    name,
    color: mainLineColor,
    lineWidth: mainLineWidth,
    marker: {
      enabled: !showMarkersOnlyOnHover,
      symbol: indicationSymbolType,
      lineColor: indicationSymbolBorderColor,
      lineWidth: indicationSymbolBorderWidth,
      states: {
        hover: {
          fillColor: indicationSymbolColor,
          lineColor: indicationSymbolBorderColor,
          lineWidth: indicationSymbolBorderWidth,
        },
      },
    },
    point: {
      events: {
        mouseOver: function () {
          customizeIndicationLine({
            element: this,
            color: indicationLineColor,
          });
        },
      },
    },
    states: {
      hover: {
        lineWidth: mainLineWidthOnHover,
      },
    },
    shadow: {
      color: lineShadowColor,
      width: 18,
      opacity: 0.1,
      offsetX: 0,
      offsetY: 10,
    },
    fillColor: {
      linearGradient: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 1,
      },
      stops: [
        [
          0,
          changeRGBAOpacityToGivenNumber({
            rgba: lineAreaShadowColor,
            opacity: 0.01,
          }),
        ],
        [
          0.3,
          changeRGBAOpacityToGivenNumber({
            rgba: lineAreaShadowColor,
            opacity: 0.1,
          }),
        ],
        [
          0.6,
          changeRGBAOpacityToGivenNumber({
            rgba: lineAreaShadowColor,
            opacity: 0.01,
          }),
        ],
        [
          1,
          changeRGBAOpacityToGivenNumber({
            rgba: lineAreaShadowColor,
            opacity: 0.07,
          }),
        ],
      ],
    },
  };
};

//#endregion
