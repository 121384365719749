//#region React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
//#endregion

import { Form, Col, Row, message } from 'antd';

//#region hooks
import useDate from 'hooks/useDate';
//#region hooks

//#region Components
import Modal from "components/common/modal";
import DateRangePicker from 'components/common/dateRangePicker';
//#endregion

//#region Constants
import { POPUP_SIZE } from "constants/common.constants";
//#endregion

import { recalculateCommissions } from './api';

/** Recalculate Popup Component */
const Recalculate = ({
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const [isLoading, setIsLoading] = useState(false);
    const [isFormTouched, setIsFormTouched] = useState(false);

    const { dateService } = useDate();

    const handleForm = () => {
        validateFields()
            .then(data => {
                let from = null, to = null;
                from = data?.date?.[0] ? dateService.getDate(data?.date?.[0]) : "";
                to = data?.date?.[1] ? dateService.getDate(data?.date?.[1]) : "";
                setIsLoading(true);
                recalculateCommissions(from, to)
                    .then(data => {
                        if(data.value){
                            message.success(data.message);
                        } else {
                            message.error(t('backoffice.commissionplans.recalculationIsInProgress'))
                        }
                        
                    })
                    .finally(() => {
                        setIsLoading(false);
                        onClose()
                    })
            }).catch(() => { })
    };

    const handleValuesChange = values => {
        if (!values.date) {
            setIsFormTouched(false)
        } else {
            setIsFormTouched(Boolean(values.date[0]) && Boolean(values.date[1]));
        }
    }

    return (
        <Modal
            title={t('backoffice.commissionplans.recalculation')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.commissionplans.recalculate')}
            onOk={handleForm}
            onCancel={onClose}
            size={POPUP_SIZE.SMALL}
            disableOkButton={!isFormTouched}
            isLoading={isLoading}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                onValuesChange={(_, values) => handleValuesChange(values)}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.commissionplans.dateDuration')} *` }
                            name="date"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <DateRangePicker
                                showTime={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <span className='rt--text-light rt--font-small rt--font-regular'>
                            {t('backoffice.commissionplans.recalculateInfo')}
                        </span>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

Recalculate.propTypes = {
    /** Fires on popup close */
    onClose: PropTypes.func
}


export default Recalculate;