import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { setNotificationSound } from 'store/actions/portal/notifications/notifications.action';

/** Notification sound Component */
const NotificationSound = ({
    setNotificationSound
}) => {
    return (
        <audio autoPlay src={`${import.meta.env.SYSTEM_CDN_URL}/system/static/media/notification.mp3`} onEnded={() => setNotificationSound(false)}/>
    )
}

/** NotificationSound propTypes
    * PropTypes
*/
NotificationSound.propTypes = {
    /** Redux action for notification sound */
    setNotificationSound: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        setNotificationSound: sound => {
            dispatch(setNotificationSound(sound))
        }
    }
)

export default connect(null, mapDispatchToProps)(NotificationSound);