export const tableColumns = [
    {
        title: "backoffice.currencies.name",
        dataIndex: "name",
        sorter: true,
        alwaysVisible: true,
        mobileLevel: 1,
    },
    {
        title: "backoffice.currencies.code",
        dataIndex: "code",
        sorter: true,
        mobileLevel: 2,
    },
    {
        title: "backoffice.currencies.symbol",
        dataIndex: "symbol",
        mobileLevel: 3
    },
    {
        title: "backoffice.currencies.rateToDefault",
        dataIndex: "rate",
        mobileLevel: 4,
        isNumeric: true
    },
    {
        title: "backoffice.currencies.createdAt",
        dataIndex: "createdAt",
        isDateTime: true
    },
    {
        title: "backoffice.currencies.lastUpdatedAt",
        dataIndex: "modifiedAt",
        isDateTime: true,
        mobileLevel: 6
    },
    {
        title: "backoffice.currencies.lastUpdatedBy",
        dataIndex: "modifiedBy",
        mobileLevel: 5
    },
];
