import PropTypes from 'prop-types';

export default {
    /** Array od columns of table which can be exported */
    columns: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func]),
        key: PropTypes.string
    })),
    /** Table Name */
    tableName: PropTypes.string,
    /** Export api url */
    url: PropTypes.string,
    /** Redux action, export data */
    exportData: PropTypes.func,
    /** Table filters */
    filters: PropTypes.object,
    /** Is the request post */
    isPostRequest: PropTypes.bool
}