
import React from 'react';
import i18n from 'translations/config';

import { WALLET_TYPE_TRANSLATION_RESOURCES } from 'constants/wallet.constants';
import { RESPONSE_CODES } from 'constants/errorCode.constants';

export const tableColumns = [
    {
        title: "backoffice.wallet.name",
        dataIndex: "userName",
        mobileLevel: 1,
    },
    {
        title: "backoffice.wallet.walletType",
        dataIndex: "type",
        mobileLevel: 2,
        render: (value) =>
            WALLET_TYPE_TRANSLATION_RESOURCES[value]
                ? i18n.t(WALLET_TYPE_TRANSLATION_RESOURCES[value])
                : "",
    },
    {
        title: "backoffice.wallet.amount",
        dataIndex: "changeAmount",
        isNumeric: true,
        mobileLevel: 3,
    },
    {
        title: "backoffice.wallet.message",
        dataIndex: "message",
        mobileLevel: 4,
        render: (value, record) =>
            record.status === RESPONSE_CODES.Success ? (
                <span className="rt--success-text">
                    {i18n.t("backoffice.wallet.success")}
                </span>
            ) : (
                <span className="rt--error-text">{value}</span>
            ),
    },
];
