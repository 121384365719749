import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import i18n from 'translations/config';

import { useTranslation } from 'react-i18next';

import Modal from "components/common/modal";

/** Confirmation Component, the comfirmation modal, which appears before important action */
const Confirmation = ({
    isVisible,
    onCancel,
    onOk,
    message,
    title,
    cancelText = i18n.t('backoffice.common.no'),
    okText = i18n.t('backoffice.common.yes'),
}) => {
    const { t } = useTranslation();

    return isVisible ? (
        <Modal
            title={title}
            cancelText={cancelText}
            okText={okText}
            onOk={() => {
                onOk();
                onCancel();
            }}
            onCancel={onCancel}
            className="rt--modal-confirmation"
        >
            <span className='rt--title rt--font-normal rt--font-regular rt--text-center'>{message}</span>
        </Modal>
    ) : <Fragment />
}

/** Confirmation propTypes
    * PropTypes
*/
Confirmation.propTypes = {
    /** is Modal visible */
    isVisible: PropTypes.bool,
    /** Modal message */
    message: PropTypes.node,
    /** Modal title */
    title: PropTypes.string,
    /** Modal OK button click handler */
    onOk: PropTypes.func,
    /** Modal Cancel button click handler */
    onCancel: PropTypes.func,
    /** Modal Cancel button text */
    cancelText: PropTypes.string,
    /** Modal OK button text */
    okText: PropTypes.string,
}

export default Confirmation;