import {
    SET_JOBS_ACTION_BEFORE,
    SET_JOBS_ACTION_FINISH,
    SET_JOBS,
    UPDATE_JOB,
    SET_JOBS_SAVE_ACTION_BEFORE,
    SET_JOBS_SAVE_ACTION_FINISH,
    SET_JOB_SETTINGS,
    SET_JOB_DETAILS_ACTION_BEFORE,
    SET_JOB_DETAILS_ACTION_FINISH,
    SET_JOB_DETAILS
} from '../../actionTypes';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_JOBS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_JOBS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_JOBS:
            return {
                ...state,
                jobs: payload.jobs
            }
        case UPDATE_JOB:
            return {
                ...state,
                jobs: state.jobs.map(j => j.id === payload.job.id ? { ...j, ...payload.job } : j)
            };
        case SET_JOBS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true
            }
        case SET_JOBS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false
            }
        case SET_JOB_SETTINGS:
            return {
                ...state,
                jobSettings: payload.settings
            }
        case SET_JOB_DETAILS_ACTION_BEFORE:
            return {
                ...state,
                isDetailsLoading: true
            }
        case SET_JOB_DETAILS_ACTION_FINISH:
            return {
                ...state,
                isDetailsLoading: false
            }
        case SET_JOB_DETAILS:
            return {
                ...state,
                jobDetails: payload.details
            }
        default:
            return state;
    }
}