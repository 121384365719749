import { useEffect, useState, useMemo } from "react";

import { getFinancialLineChartData } from "../api";

import { makeTimePeriodCustomRangeOutputValue } from "../helpers";

import useCurrencyConversion from "hooks/useCurrencyConvertion";
import useDate from "hooks/useDate";

const useFinancialLineChart = (dataType, period, updateLastUpdatedDate, currencyCode, isActive) => {

    const [chartState, setChartState] = useState({
        isLoading: false,
        chartData: [],
    })

    const { convertCurrency } = useCurrencyConversion();
    const { dateService } = useDate();

    useEffect(() => {
        if(isActive === false){
            return
        }

        setChartState((prevState) => {
            return {
                ...prevState,
                isLoading: true
            }
        })

        const { request, cancelRequest } = getFinancialLineChartData({
            dataType,
            timePeriod: period?.period,
            ...makeTimePeriodCustomRangeOutputValue(period?.from, period?.to, dateService)
        })

        request
            .then((response) => {
                const { data: { value: payload } } = response;
                updateLastUpdatedDate(payload.lastUpdatedAt);
                setChartState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false,
                        chartData: 
                            Object.keys(
                                payload.data
                            )
                            .reduce(
                                (accumulator, currentValue) => {
                                    accumulator.push({
                                        time: currentValue,
                                        ...payload.data[currentValue] 
                                    })
                                    return accumulator;
                                }, []
                            )
                    }
                })
            })
            .catch(() => {
                setChartState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false
                    }
                })
            })

        return () => {
            cancelRequest();
        }
    }, [dataType, period, updateLastUpdatedDate, isActive])

    const data = useMemo(() => {
        
        return {
            isLoading: chartState.isLoading,
            chartData: chartState.chartData.map(chart => ({
                time: chart.time,
                ...Object.keys(chart).reduce(
                    (accumulator, currentValue) => {
                        if(currentValue === "time") return accumulator;
                        const data = chart[currentValue];
                        Object.keys(data).forEach(key => {
                            if(accumulator[key] === undefined){
                                accumulator[key] = 0;
                            }

                            accumulator[key] += convertCurrency(data[key], currentValue, currencyCode)
                        })
                        return accumulator
                    }, {}
                )
            }))
        };
    }, [chartState, currencyCode])

    return data;

}

export default useFinancialLineChart;