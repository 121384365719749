import {
    SET_WALLET_ACTION_BEFORE,
    SET_WALLET_ACTION_FINISH,
    SET_WALLET_SAVE_ACTION_BEFORE,
    SET_WALLET_SAVE_ACTION_FINISH
} from "../../../actionTypes";

export const setWalletActionBefore = () => ({
    type: SET_WALLET_ACTION_BEFORE,
});

export const setWalletActionFinished = () => ({
    type: SET_WALLET_ACTION_FINISH,
});

export const setWalletSaveActionBefore = () => ({
    type: SET_WALLET_SAVE_ACTION_BEFORE,
});

export const setWalletSaveActionFinished = () => ({
    type: SET_WALLET_SAVE_ACTION_FINISH,
});
