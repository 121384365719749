import { isFunction } from "utils/common";

export const splitDropdownAndInputValues = (wholeValue, selectedItem) => {
    if (wholeValue === undefined || wholeValue === null) {
        wholeValue = ""
    }

    const inputValue = String(wholeValue).replace(selectedItem, "");
    const dropdownValue = selectedItem;

    return [inputValue, dropdownValue];
}

export const componentShouldBeControlled = (props) => {
    if (!Object.hasOwn(props, "value")) {
        return false;
    }

    if (!isFunction(props.onChange)) {
        console.warn(
            "You have not passed the 'onChange' prop. Please ensure that you pass an 'onChange' function to ensure proper functionality. Or remove value prop"
        );

        return false
    }

    return true
}