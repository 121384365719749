export const COMPANY_STATE = {
    ACTIVE: 1,
    INACTIVE: 2
}

export const COMPANY_CONTROL_RULE = {
    REQUIRED: 1 << 0,
    OPTIONAL: 1 << 1,
    HIDDEN: 1 << 2,
}

export const COMPANY_DOCUMENT_TYPE = {
    PASSPORT : 1 << 0,
    ID : 1 << 1,
    DRIVING_LICENCES : 1 << 2
}

export const COMPANY_CONTROL_TYPE = {
    INPUT: 1,
    SELECT: 2,
    PASSWORD: 3,
    DATEPICKER: 4,
    ATTACHMENT: 5,
    CHECKBOX: 6
}

export const COMPANY_PREVALIDATION_RULE = {
    NONE: 0,
    REGEXP: 1
}

export const COMPANY_PASSWORD_PATTERN = {
    CONTAINS: 1,
    PATTERN: 2
}

export const COMPANY_PASSWORD_PATTERN_CONTAINS = {
    UPPERCASE: 1,
    LOWERCASE: 2,
    DIGITS: 4,
    DASH: 8,
    UNDERSCORE: 16,
    SPACE: 32,
    SPECIAL: 64,
    BRACKETS: 128
}

export const COMPANY_PASSWORD_PATTERN_RULE = {
    MAY: 1,
    MUST: 2,
    NONE: 4
}

export const COMPANY_PASSWORD_FORCE_CHANGE_TYPE = {
    NEVER: 1,
    EVERY_1_MONTH : 2,
    EVERY_2_MONTH : 4,
    EVERY_3_MONTH : 8,
    EVERY_4_MONTH : 16,
    EVERY_5_MONTH : 32,
    EVERY_6_MONTH : 64,
    EVERY_7_MONTH : 128,
    EVERY_8_MONTH : 256,
    EVERY_9_MONTH : 512,
    EVERY_10_MONTH : 1024,
    EVERY_11_MONTH : 2048
}

export const DIGITAIN_COMPANY_ID = "a57ce327d81b4bc589f54930a9a8c064";

