import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
    SET_GENERATIONS_ACTION_BEFORE,
    SET_GENERATIONS_ACTION_FINISH,
    SET_GENERATIONS
} from "../../../../actionTypes";

const setGenerationsActionBefore = () => ({
    type: SET_GENERATIONS_ACTION_BEFORE,
});

const setGenerationsActionFinished = () => ({
    type: SET_GENERATIONS_ACTION_FINISH,
});

const setGenerations = generations => ({
    type: SET_GENERATIONS,
    payload: { generations },
});

export const getGenerations = (type, from, to) => {
    return dispatch => {

        dispatch(setGenerationsActionBefore());

        return axios({
            url: ApiUrls.GET_GENERATIONS,
            method: Methods.GET,
            params: {
                type, from, to
            },
        })
            .then(({ data: { value: generations } }) => {
                dispatch(setGenerations(generations));
                dispatch(setGenerationsActionFinished());
            })
            .catch((ex) => {
                dispatch(setGenerationsActionFinished());
            });
    }
}
