import { ALL_VALUE } from "./constants";

export const filterAndSortBySearchValue = ({ options, searchValue, add }) => {
    const searchValueLower = searchValue.toLowerCase().trim();

    if (!searchValueLower) {
        return options;
    }

    const compare = (optionKey) => {
        if (add) {
            return optionKey === searchValue;
        }

        return optionKey.toLowerCase().includes(searchValueLower);
    }

    let filtered = options.filter(option =>
        (option.text && compare(option.text)) ||
        (option.value && compare(option.value.toString()))
    );

    filtered.sort((a, b) => {
        let aIndex = 1000000; // too big number
        let bIndex = 1000000;
        const aText = a.text ? a.text.toLowerCase() : "";
        const bText = b.text ? b.text.toLowerCase() : "";

        if (aText.includes(searchValueLower)) {
            aIndex = aText.indexOf(searchValueLower);
        }
        if (bText.includes(searchValueLower)) {
            bIndex = bText.indexOf(searchValueLower);
        }
        return aIndex >= bIndex ? 1 : -1;
    });

    if (add && searchValue !== "" && searchValue !== ALL_VALUE && filtered.length === 0) {
        return [{ text: `${searchValue} (Add)`, value: searchValue }];
    }

    return filtered;
}

export const getMergedSelectedValues = ({ isMultiple, selectedValues, options, isAllOptionVisible }) => {
    if (!isMultiple) {
        return selectedValues;
    }

    if (isMultiple && !isAllOptionVisible) {
        if (Array.isArray(selectedValues)) {
            return selectedValues
        }

        return [];
    }

    if (!selectedValues || !options) {
        return [];
    }

    if (selectedValues.length === options.length) {
        return [ALL_VALUE, ...selectedValues];
    } else {
        return [...selectedValues];
    }

}

export const getSelectedValues = ({ isMultiple, options, newSelectedValues, prevSelectedValues }) => {
    if (!isMultiple) {
        return newSelectedValues;
    }

    // case1: select all
    if (newSelectedValues.at(-1) === ALL_VALUE) {
        return options.map(option => option.value);
    }

    // case2: when all options have been selected and click on any option (unselect that option and all option)
    if (newSelectedValues.includes(ALL_VALUE) && newSelectedValues.length === options.length) {
        return newSelectedValues.filter(sekectedValue => sekectedValue !== ALL_VALUE);
    }

    // case3: when all options have been selected and click on all option (unselect all options)
    if (prevSelectedValues.includes(ALL_VALUE) && newSelectedValues.length === options.length) {
        return [];
    }

    return newSelectedValues;
}

export const getPropFromMatchObj = ({ value, options, prop }) => {
    const foundOptionObj = options.find(optionObj => optionObj.value === value);

    return foundOptionObj ? foundOptionObj[prop] : value
}