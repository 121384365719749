import {
    SET_COMMISSION_PLANS_ACTION_BEFORE,
    SET_COMMISSION_PLANS_ACTION_FINISH,
    SET_COMMISSION_PLANS,
    SET_COMMISSION_PLANS_SAVE_ACTION_BEFORE,
    SET_COMMISSION_PLANS_SAVE_ACTION_FINISH,
    SET_COMMISSION_PLANS_FILTERS,
    SET_COMMISSION_PLANS_SORTING,
    SET_COMMISSION_PLAN_GENERAL_INFO,
    UPDATE_COMMISSION_PLAN_STATE,
    SET_AVAILABLE_COMMISSION_PLANS_ACTION_BEFORE,
    SET_AVAILABLE_COMMISSION_PLANS_ACTION_FINISH,
    SET_AVAILABLE_COMMISSION_PLANS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_COMMISSION_PLANS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_COMMISSION_PLANS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_COMMISSION_PLANS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_COMMISSION_PLANS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_COMMISSION_PLANS:
            return {
                ...state,
                commissionPlans: !payload.add ? payload.commissionPlans.item2 : state.commissionPlans.concat(payload.commissionPlans.item2),
                total: payload.commissionPlans.item1,
                edit: {
                    ...state.edit,
                    general: {}
                }
            };
        case SET_COMMISSION_PLANS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_COMMISSION_PLANS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_COMMISSION_PLAN_GENERAL_INFO:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...payload.info
                    }
                }
            }
        case UPDATE_COMMISSION_PLAN_STATE:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...state.edit.general,
                        status: payload.status
                    }
                }
            }
        case SET_AVAILABLE_COMMISSION_PLANS_ACTION_BEFORE:
            return {
                ...state,
                isAvailableLoading: true
            }
        case SET_AVAILABLE_COMMISSION_PLANS_ACTION_FINISH:
            return {
                ...state,
                isAvailableLoading: false
            }
        case SET_AVAILABLE_COMMISSION_PLANS:
            return {
                ...state,
                availableCommissionPlans: payload.commissionPlans
            }
        default:
            return state;
    }
};
