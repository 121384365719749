import {
    AUTHENTICATE_ACTION_SET_EXPIRE,
    AUTHENTICATE_ACTION_BEFORE,
    AUTHENTICATE_ACTION_FINISH,
    AUTHENTICATE_ACTION_SET_QRBASE64,
    AUTHENTICATE_ACTION_SET_WS_TOKEN,
    AUTHENTICATE_ACTION_SET_ERROR_DATA,
    SET_PASSWORD_SETTINGS_ACTION_BEFORE,
    SET_PASSWORD_SETTINGS_ACTION_FINISH,
    SET_PASSWORD_SETTINGS,
    SET_RESET_PASSWORD_ACTION_BEFORE,
    SET_RESET_PASSWORD_ACTION_FINISH,
    VERIFY_EMAIL_ACTION_BEFORE,
    VERIFY_EMAIL_ACTION_FINISH,
    FORGOT_PASSWORD_ACTION_BEFORE,
    FORGOT_PASSWORD_ACTION_FINISH,
    SET_PUBLISH_LANGUAGES_ACTION_BEFORE,
    SET_PUBLISH_LANGUAGES_ACTION_FINISH,
    SET_PUBLISH_LANGUAGES,
    SET_TRANSLATIONS_LOADED,
    SET_LOGOS
} from '../../actionTypes';


export default (state = {}, { type, payload }) => {
    switch (type) {
        case AUTHENTICATE_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case AUTHENTICATE_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case AUTHENTICATE_ACTION_SET_EXPIRE:
            return {
                ...state,
                expires: payload.expires
            }
        case AUTHENTICATE_ACTION_SET_QRBASE64:
            return {
                ...state,
                QRBase64: payload.base64
            }
        case AUTHENTICATE_ACTION_SET_WS_TOKEN:
            return {
                ...state,
                wsToken: payload.wsToken
            }
        case AUTHENTICATE_ACTION_SET_ERROR_DATA:
            return {
                ...state,
                errorData: payload.errorData
            }
        case SET_LOGOS:
            return {
                ...state,
                logos: payload.logos
            }
        case SET_PASSWORD_SETTINGS_ACTION_BEFORE:
            return {
                ...state,
                passwordSettings: {
                    ...state.passwordSettings,
                    isLoading: true
                }
            }
        case SET_PASSWORD_SETTINGS_ACTION_FINISH:
            return {
                ...state,
                passwordSettings: {
                    ...state.passwordSettings,
                    isLoading: false
                }
            }
        case SET_PASSWORD_SETTINGS:
            return {
                ...state,
                passwordSettings: {
                    ...state.passwordSettings,
                    passwordSettings: payload.passwordSettings
                }
            }
        case SET_RESET_PASSWORD_ACTION_BEFORE:
            return {
                ...state,
                isReseting: true
            }
        case SET_RESET_PASSWORD_ACTION_FINISH:
            return {
                ...state,
                isReseting: false
            }
        case VERIFY_EMAIL_ACTION_BEFORE:
            return {
                ...state,
                isVerifying: true
            }
        case VERIFY_EMAIL_ACTION_FINISH:
            return {
                ...state,
                isVerifying: false
            }
        case FORGOT_PASSWORD_ACTION_BEFORE:
            return {
                ...state,
                isForgeting: true
            }
        case FORGOT_PASSWORD_ACTION_FINISH:
            return {
                ...state,
                isForgeting: false
            }
        case SET_PUBLISH_LANGUAGES_ACTION_BEFORE:
            return {
                ...state,
                translations: {
                    ...state.translations,
                    isLanguagesLoading: true
                }
            }
        case SET_PUBLISH_LANGUAGES_ACTION_FINISH:
            return {
                ...state,
                translations: {
                    ...state.translations,
                    isLanguagesLoading: false
                }
            }
        case SET_PUBLISH_LANGUAGES:
            return {
                ...state,
                translations: {
                    ...state.translations,
                    languages: payload.data,
                    isLanguagesLoaded: true
                }
            }
        case SET_TRANSLATIONS_LOADED:
            return {
                ...state,
                translations: {
                    ...state.translations,
                    loaded: true
                }
            }
        default:
            return state;
    }
}