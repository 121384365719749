import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import {
    SET_ACCESS_MANAGERS_ACTION_BEFORE,
    SET_ACCESS_MANAGERS_ACTION_FINISH,
    SET_ACCESS_MANAGERS,
    SET_ACCESS_MANAGERS_SORTING,
    SET_ACCESS_MANAGERS_FILTERS,
    SET_ACCESS_MANAGERS_SAVE_ACTION_BEFORE,
    SET_ACCESS_MANAGERS_SAVE_ACTION_FINISH
} from "../../../../actionTypes";

import { getAccessManagerGeneralInfo } from './general.action';

export const setAccessManagersActionBefore = () => ({
    type: SET_ACCESS_MANAGERS_ACTION_BEFORE,
});

export const setAccessManagersActionFinished = () => ({
    type: SET_ACCESS_MANAGERS_ACTION_FINISH,
});

export const setAccessManagersSaveActionBefore = () => ({
    type: SET_ACCESS_MANAGERS_SAVE_ACTION_BEFORE,
});

export const setAccessManagersSaveActionFinished = () => ({
    type: SET_ACCESS_MANAGERS_SAVE_ACTION_FINISH,
});

const setAccessManagers = (accessManagers, add) => ({
    type: SET_ACCESS_MANAGERS,
    payload: { accessManagers, add },
});

export const setAccessManagersSorting = sorting => ({
    type: SET_ACCESS_MANAGERS_SORTING,
    payload: { sorting },
});

export const setAccessManagersFilters = filters => ({
    type: SET_ACCESS_MANAGERS_FILTERS,
    payload: { filters },
});


export const getAccessManagers = nextPage => {
    return (dispatch, getState) => {
        
        const page= nextPage === "first" ? 1 : nextPage === "next" ? getState().accessManagers.sorting.page + 1 : getState().accessManagers.sorting.page;
        let params = {
            ...getState().accessManagers.sorting,
            ...getState().accessManagers.filters,
            page: page
        };

        if(page > 1 && getState().accessManagers.total <= (page - 1 ) * getState().accessManagers.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().accessManagers.total === 0){
            return Promise.resolve();
        }
        
        dispatch(setAccessManagersActionBefore());

        return axios({
            url: ApiUrls.GET_ACCESS_MANAGERS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: accessManagers } }) => {
                dispatch(setAccessManagers(accessManagers, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setAccessManagersSorting({ ...getState().accessManagers.sorting, page: page }));
                dispatch(setAccessManagersActionFinished());
            })
            .catch((ex) => {
                dispatch(setAccessManagersActionFinished());
            });
    }
}

export const createAccessManager = (accessManager, onSuccess) => {
    return (dispatch) => {
        dispatch(setAccessManagersSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_ACCESS_MANAGER,
            method: Methods.POST,
            data: {
                ...accessManager
            },
        })
            .then(({ data }) => {
                dispatch(setAccessManagersSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(() => {
                dispatch(setAccessManagersSaveActionFinished());
            });
    };
};

export const changeAccessManagerState = d => {
    return dispatch => {
        dispatch(setAccessManagersSaveActionBefore());
        return axios({
            url: ApiUrls.CHANGE_ACCOUNT_STATE,
            method: Methods.POST,
            data: d
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getAccessManagerGeneralInfo(d.id));
            } 
            dispatch(setAccessManagersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAccessManagersSaveActionFinished());
        })
    }
}

export const unlockAccessManager = id => {
    return dispatch => {
        dispatch(setAccessManagersSaveActionBefore());
        return axios({
            url: ApiUrls.UNLOCK_ACCOUNT,
            method: Methods.POST,
            data: { 
                id
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)){
                message.success(data.message);
                dispatch(getAccessManagerGeneralInfo(id));
            }
            dispatch(setAccessManagersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAccessManagersSaveActionFinished());
        })
    }
}