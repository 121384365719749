import React from 'react';

import { Outlet } from "react-router-dom";

import Header from './header';

const Layout = () => {

    return (
        <div className='rt--customize-layout rt--flex rt--flex-col'>
            <Header />
            <div className='rt--customize-layout-content rt--flex-equal rt--pb-24 rt--pt-24 rt--pl-16 rt--pr-16'>
                <Outlet />
            </div>
        </div>
    )
}


export default Layout;

