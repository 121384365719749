import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Select from 'components/common/select';
import DateRangePicker from "components/common/dateRangePicker";

import { getUsers, setUsersFilters } from "store/actions/portal/userManagement/users/users.action";

import { FILTER_USER_STATE } from "constants/filter.constants";
import { USER_TYPE } from "constants/user.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';

/** Users Page Filters Component */

const Filters = ({
    setUsersFilters,
    getUsers,
    filters,
    globalCompanyId
}) => {
    const { t } = useTranslation();

    const [ permissionGroupNames, getPermissionGroupNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.PERMISSION_GROUP, 
        actionParams: USER_TYPE.ADMIN,
        autoGet: false
    });

    return (
        <FiltersWrapper
            loadFn={getUsers}
            setFiltersFn={setUsersFilters}
            filters={filters}
            updateProps={[globalCompanyId]}
            datePickerFields={[
                { name: "date", time: false },
                { name: "modified", time: false },
            ]}
            searchFieldName="nameOrId"
            onInit={() => {
                getPermissionGroupNames();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.status')}
                        name="state"
                    >
                        <Select
                            options={[
                                { value: FILTER_USER_STATE.ALL, text: t('backoffice.common.all') },
                                { value: FILTER_USER_STATE.IN_PROGRESS, text: t('backoffice.common.inactive') },
                                { value: FILTER_USER_STATE.ACTIVE, text: t('backoffice.common.active') },
                                { value: FILTER_USER_STATE.BLOCKED, text: t('backoffice.common.blocked') },
                                { value: FILTER_USER_STATE.EXPIRED, text: t('backoffice.common.expired') },
                                { value: FILTER_USER_STATE.DELETED, text: t('backoffice.common.deleted') }
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.users.permissionGroup')}
                        name="permissionGroup"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }, 
                                    ...permissionGroupNames.map(item => (
                                        { value: item.id, text: item.name }
                                    ))
                                ]
                            }
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.users.permissionGroup')}`}
                            search={true}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.creationDate')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.lastUpdateDate')}
                        name="modified"
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get users */
    getUsers: PropTypes.func,
    /** Redux action to set users filters */
    setUsersFilters: PropTypes.func,
    /** Redux state property, users filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalCompanyId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getUsers: () => {
            dispatch(getUsers())
        },
        setUsersFilters: filters => {
            dispatch(setUsersFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.users.filters,
        globalCompanyId: state.common.globalCompanyId
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);