import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Form, Button } from 'antd';

import TwoFactorComponent from "./twoFactor";
import ErrorComponent from "./error.component";
import Input from 'components/common/input';

import { authenticate } from 'store/actions/auth/auth.action';

import { loginUser } from 'utils/auth';
import LanguageUtils from 'utils/languages';
import { isMobile } from 'utils/common';

import { TOKEN_TYPE } from 'constants/auth.constants';
import Paths from 'constants/path.constants';
import { RESPONSE_CODES } from 'constants/errorCode.constants';


/** Login Page Component */

const LoginComponent = ({
    authenticate,
    isLoading,
    errorData
}) => {

    const { t } = useTranslation();

    const { executeRecaptcha } = useGoogleReCaptcha();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const verified = searchParams.get("verified") === "true";

    const [twoFactor, setTwoFactor] = useState(null);

    /** Fires after success login
       * @function
       * @param {Object} data - The data returned from server after login call
       * @memberOf LoginComponent
   */
    const authenticateCB = data => {
        if (data.tokenType === TOKEN_TYPE.NONE) {
            loginUser(data);
            setTimeout(() => {
                location.reload();
            }, 0)
        } else if (data.tokenType === TOKEN_TYPE.QR || data.tokenType === TOKEN_TYPE.TOKEN) {
            setTwoFactor({
                tokenType: data.tokenType,
                token: data.token,
                userName: data.userName
            });
        } else if (data.tokenType === TOKEN_TYPE.PASSWORD_EXPIRED) {
            navigate(`${Paths.SET_PASSWORD}?t=${data.token}&type=forceChange&userName=${data.userName}}`);
        }
    }

    /** Fires when form submitted
       * @function
       * @memberOf LoginComponent
   */
    const handleForm = () => {
        validateFields()
            .then(({ username, password }) => {
                if (import.meta.env.MODE === "development") {
                    authenticate(username, password, "34f530b1728d4b28a5d1e39bb92be946", authenticateCB);
                } else {
                    executeRecaptcha()
                        .then(token => {
                            authenticate(username, password, token, authenticateCB);
                        })
                }
            }).catch(ex => { 
                console.log(ex)
            })
    }

    useEffect(() => {
        if (errorData) {
            setTwoFactor(null)
        }
    }, [errorData])


    return (
        <div className="rt--auth-form">
            <Form
                form={formInstance}
                requiredMark={false}
                layout="vertical"
            >
                <span className="rt--sign-in rt--title rt--font-bigest rt--font-regular">{t('backoffice.authentication.signIn')}</span>
                <b className="rt--title rt--font-bigest rt--font-bold rt--mb-64 rt--mt-8">{t('backoffice.authentication.welcomeBack')}</b>
                {
                    errorData && <ErrorComponent />
                }
                {
                    verified && (
                        <div className='rt--auth-success rt--mb-16 rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16'>
                            <b className='rt--title rt--font-medium rt--font-normal'>
                                {
                                    t("backoffice.authentication.yourEmailVarificationIsComplete")
                                }
                            </b>
                        </div>
                    )
                }
                <Form.Item
                    className='rt--general-form-item'
                    label={t('backoffice.authentication.username')}
                    name="username"
                    data-placeholder={t('backoffice.authentication.username')}
                    rules={[
                        { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                        { max: 30, message: t('backoffice.validation.fieldInvalid') }
                    ]}

                >
                    <Input placeholder={t('backoffice.authentication.username')} />

                </Form.Item>

                <Form.Item
                    label={t('backoffice.authentication.password')}
                    name="password"
                    data-placeholder={t('backoffice.authentication.password')}
                    className='rt--general-form-item rt--form-item-without-margin'
                    rules={[
                        { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                        { max: 24, message: t('backoffice.validation.fieldInvalid') },
                        { min: 8, message: t('backoffice.validation.fieldInvalid') }
                    ]}
                >
                    <Input
                        type='Password'
                        placeholder={t('backoffice.authentication.password')}
                    />
                </Form.Item>
                {
                    (
                        errorData?.resultCode !== RESPONSE_CODES.NotApproved && 
                        errorData?.resultCode !== RESPONSE_CODES.Declined
                    ) && (
                        <div className='rt--flex rt--justify-end rt--mt-12'>
                            <Link to={`${Paths.FORGOT_PASSWORD}?language=${LanguageUtils.getSelectedLanguage()}`}>
                                <span className='rt--title rt--auth-link rt--font-bold rt--font-normal'>{t('backoffice.authentication.forgotPassword')}</span>
                            </Link>
                        </div>
                    )
                }
                

                <div className='rt--mt-40'>
                    <Form.Item >
                        <Button
                            loading={isLoading}
                            type="primary"
                            htmlType="submit"
                            className="rt--button rt--button-primary rt--button-sign-in"
                            onClick={handleForm}
                            disabled={errorData}
                        >
                            <span className='rt--font-normal'>{t('backoffice.authentication.signIn')}</span>
                        </Button>
                    </Form.Item>
                </div>
            </Form>

            {
                isMobile() ?
                    (
                        <div className='rt--auth-sidebar-powered'>
                            <span className='rt--font-small'>Powered by</span>
                            <a className='rt--auth-link rt--font-small rt--ml-8' href='https://centrivo.io/' target='blank'>
                                Digitain.com
                            </a>
                        </div>
                    ) : ''
            }

            {
                twoFactor && (
                    <TwoFactorComponent
                        tokenType={twoFactor.tokenType}
                        token={twoFactor.token}
                        userName={twoFactor.userName}
                        onClose={() => setTwoFactor(null)}
                    />
                )
            }

        </div>
    )
}


/** LoginComponent propTypes
    * PropTypes
*/
LoginComponent.propTypes = {
    /** Redux state property, is true when authenticating */
    isLoading: PropTypes.bool,
    /** Redux action to authenticate */
    authenticate: PropTypes.func,
    /** Redux state property the error data */
    errorData: PropTypes.shape({
        resultCode: PropTypes.number,
        expireTime: PropTypes.string
    })
}

const mapDispatchToProps = dispatch => (
    {
        authenticate: (username, password, captchaToken, callback) => {
            dispatch(authenticate(username, password, captchaToken, callback))
        }
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.auth.isLoading,
        errorData: state.auth.errorData
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    LoginComponent
);