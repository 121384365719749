import React from 'react';
import i18n from 'translations/config';

import { humanFileSize, makeImagePath } from 'utils/common';
import { getUser } from 'utils/auth';

import { USER_ROLE } from 'constants/user.constants';
import { CREATIVE_IMAGE_FORMATS, CREATIVE_TYPE } from 'constants/campaign.constants';

export const getTableColumns = additionalProps => {
    const {
        onThumbnailClick 
    } = additionalProps; 

    return [
        {
            title: "",
            dataIndex: "filePath",
            render: (filePath, record) => (
                <div 
                    className="rt--table-col-img"
                    onClick={() => onThumbnailClick(record)}
                >
                    <img
                        src={
                            record.type === CREATIVE_TYPE.HTML ? 
                                `${import.meta.env.SYSTEM_CDN_URL}/system/static/images/html.png` : 
                                makeImagePath(filePath)
                            }
                        alt="Creative"
                    />
                </div>
            ),
            image: true,
            alwaysVisible: true,
            width: "132px",
            disableExport: true
        },
        {
            title: "backoffice.campaigns.name",
            dataIndex: "name",
            alwaysVisible: true,
            sorter: true,
            mobileLevel: 2
        },
        {
            title: "backoffice.common.id",
            dataIndex: "longId",
            alwaysVisible: true,
            sorter: true,
            copy: true,
            mobileLevel: 3
        },
        {
            title: "backoffice.campaigns.creativeType",
            dataIndex: "type",
            sorter: true,
            mobileLevel: 4,
            render: value => value === CREATIVE_TYPE.IMAGE ? i18n.t("backoffice.campaigns.banner") :
                value === CREATIVE_TYPE.HTML ? i18n.t("backoffice.campaigns.bannerHTML") : "-"
        },
        {
            title: "backoffice.campaigns.dimension",
            dataIndex: "dimension",
            sorter: true,
            mobileLevel: 5
        },
        {
            title: "backoffice.campaigns.size",
            dataIndex: "size",
            render: value => value ? humanFileSize(value) : " - "
        },
        {
            title: "backoffice.campaigns.fileType",
            dataIndex: "imageType",
            render: value => value ? CREATIVE_IMAGE_FORMATS[value] : " - "
        },
        getUser().role !== USER_ROLE.AFFILIATE && {
            title: "backoffice.campaigns.activeMedias",
            dataIndex: "mediaCount",
            isNumeric: true
        },
        {
            title: "backoffice.campaigns.country",
            dataIndex: "countryCodes",
            multi: true,
            multiMapper: value => value ? value.map(v => i18n.t(`backoffice.countries.${v}`)) : [],
        },
        {
            title: "backoffice.campaigns.createdAt",
            dataIndex: "createdAt",
            isDateTime: true,
            sorter: true,
            mobileLevel: 6
        },
        {
            title: "backoffice.campaigns.createdBy",
            dataIndex: "createdBy",
            sorter: true,
        },
        {
            title: "backoffice.campaigns.lastModifiedAt",
            dataIndex: "modifiedAt",
            isDateTime: true,
            sorter: true,
        },
        {
            title: "backoffice.campaigns.lastModifiedBy",
            dataIndex: "modifiedBy",
            sorter: true,
        }
    ]
};