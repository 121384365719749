import React from "react";

import AccessManagersEditComponent from "pages/userManagement/accessManagers/edit/accessManager-edit.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const AccessManagersEditRoute = () => {
    return <AccessManagersEditComponent />;
};

export default withPermission(
    withAuth(AccessManagersEditRoute),
    [
        {
            resource: PERMISSION_RESOURCE.ACCESS_MANAGER_GENERALINFO,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.ACCESS_MANAGER_SESSIONS,
            action: PERMISSION_ACTION.VIEW,
        },
    ],
    Paths.PORTAL
);
