import PropTypes from 'prop-types';

export const paymentMethodType = PropTypes.shape({
    id: PropTypes.string,
    longId: PropTypes.number,
    name: PropTypes.string,
    logoPath: PropTypes.string,
    currencyCode: PropTypes.string,
    commission: PropTypes.number,
    minThreshhold: PropTypes.number,
    createAt: PropTypes.string,
    createdBy: PropTypes.string
})