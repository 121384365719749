import {
    SET_ACCOUNTS_ACTION_BEFORE,
    SET_ACCOUNTS_ACTION_FINISH,
    SET_ACCOUNTS_SAVE_ACTION_BEFORE,
    SET_ACCOUNTS_SAVE_ACTION_FINISH
} from "../../../actionTypes";

export const setAccountsActionBefore = () => ({
    type: SET_ACCOUNTS_ACTION_BEFORE,
});

export const setAccountsActionFinished = () => ({
    type: SET_ACCOUNTS_ACTION_FINISH,
});

export const setAccountsSaveActionBefore = () => ({
    type: SET_ACCOUNTS_SAVE_ACTION_BEFORE,
});

export const setAccountsSaveActionFinished = () => ({
    type: SET_ACCOUNTS_SAVE_ACTION_FINISH,
});