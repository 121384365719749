import { useEffect, useState } from "react";

import { getOperationalPieChartData } from "../api";

const useOperationalPieChart = (entityType, globalProjectId, updateLastUpdatedDate, isActive) => {

    const [chartState, setChartState] = useState({
        isLoading: false,
        chartData: {
            count: 0,
            data: {},
        },
    })

    useEffect(() => {
        if(isActive === false){
            return
        }

        setChartState((prevState) => {
            return {
                ...prevState,
                isLoading: true
            }
        })

        const { request, cancelRequest } = getOperationalPieChartData({
            entityType,
        })

        request
            .then((response) => {
                const { data: { value: payload } } = response;

                updateLastUpdatedDate(payload.lastUpdateAt);
                setChartState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false,
                        chartData: {
                            data: payload.data,
                            count: payload.count,
                        }
                    }
                })
            })
            .catch(() => {
                setChartState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false
                    }
                })
            })

        return () => {
            cancelRequest();
        }
    }, [entityType, globalProjectId, updateLastUpdatedDate, isActive])

    return chartState;

}

export default useOperationalPieChart;