import React from 'react';

import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router';

import { Form, Row, Col, Spin } from 'antd';

import CustomizeImgUploader from '../../customizeImgUploader';

import { NEWSLETTER_FILE_NAMES } from 'constants/newsletter.constants';

const Banner = ({
    isLoading,
    isSaving,
    files,
}) => {
    const { t } = useTranslation();

    const searchParams = useParams();

    return (
        <Spin spinning={isLoading || isSaving}>
            <div>
                <Form
                    className="rt--form"
                    colon={false}
                    requiredMark={false}
                    layout="vertical"
                >
                    <Row gutter={[16, 0]}>
                        <Col span={18}>
                            <Form.Item
                                label={t("backoffice.promo.banner")}
                            >
                                <CustomizeImgUploader
                                    name={NEWSLETTER_FILE_NAMES.BANNER1}
                                    imgPath={files[NEWSLETTER_FILE_NAMES.BANNER1]}
                                    id={searchParams.id}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Spin >
    );
}

/** Banner propTypes
 * PropTypes
*/
Banner.propTypes = {
    /** Redux state property, is true when loading newsletter configuration */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when promo saving */
    isSaving: PropTypes.bool,
    /** Section Image URLs */
    files: PropTypes.objectOf(PropTypes.string)
};

const mapStateToProps = (state) => {
    return {
        isSaving: state.newsletterCustomize.isSaving,
        isLoading: state.newsletterCustomize.isLoading,
        files: state.newsletterCustomize.configuration.files
    };
};

export default connect(mapStateToProps, null)(Banner);