import { message } from 'antd';
import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import {
    SET_COMMUNICATION_TEMPLATE_ACTION_BEFORE,
    SET_COMMUNICATION_TEMPLATE_ACTION_FINISH,
    SET_COMMUNICATION_TEMPLATE,
    SET_COMMUNICATION_TEMPLATE_SAVE_ACTION_BEFORE,
    SET_COMMUNICATION_TEMPLATE_SAVE_ACTION_FINISH,
    SET_COMMUNICATION_TEMPLATE_VARIABLES,
    SET_COMMUNICATION_TEMPLATE_TRANSLATION,
    SET_TEMPLATE_SYSTEM_DEFAULT
} from "../../../../actionTypes";

export const setCommunicationTemplateActionBefore = () => ({
    type: SET_COMMUNICATION_TEMPLATE_ACTION_BEFORE
});

export const setCommunicationTemplateActionFinished = () => ({
    type: SET_COMMUNICATION_TEMPLATE_ACTION_FINISH
});

export const setCommunicationTemplateSaveActionBefore = () => ({
    type: SET_COMMUNICATION_TEMPLATE_SAVE_ACTION_BEFORE
});

export const setCommunicationTemplateSaveActionFinished = () => ({
    type: SET_COMMUNICATION_TEMPLATE_SAVE_ACTION_FINISH
});

const setCommunicationTemplate = (template) => ({
    type: SET_COMMUNICATION_TEMPLATE,
    payload: { template },
})

const setCommunicationTemplateVariables = (variables) => ({
    type: SET_COMMUNICATION_TEMPLATE_VARIABLES,
    payload: { variables },
})

const setCommunicationTemplateTranslation = (groupedSections) => ({
    type: SET_COMMUNICATION_TEMPLATE_TRANSLATION,
    payload: { groupedSections },
})

export const setTemplateSystemDefault = systemDefault => ({
    type: SET_TEMPLATE_SYSTEM_DEFAULT,
    payload: { systemDefault }
})

export const getCommunicationTemplate = ({ languageCodes, ...rest }) => {
    return (dispatch) => {
        dispatch(setCommunicationTemplateActionBefore());
        const languageCodesQuery = languageCodes.map(l => "languageCodes=" + l).join("&");

        return axios({
            url: `${ApiUrls.GET_COMMUNICATION_TEMPLATE}?${languageCodesQuery}`,
            method: Methods.GET,
            params: { ...rest, baseLanguageCode: "en" }
        })
            .then(({ data: { value: template } }) => {
                dispatch(setCommunicationTemplate(template));
                dispatch(setCommunicationTemplateActionFinished());
            })
            .catch((ex) => {
                dispatch(setCommunicationTemplateActionFinished());
            });
    }
}

export const getCommunicationTemplateVariables = () => {
    return (dispatch) => {
        dispatch(setCommunicationTemplateActionBefore());

        return axios({
            url: ApiUrls.GET_TEMPLATE_VARIABLES,
            method: Methods.GET
        })
            .then(({ data: { value: variables } }) => {
                dispatch(setCommunicationTemplateVariables(Object.values(variables)));
                dispatch(setCommunicationTemplateActionFinished());
            })
            .catch((ex) => {
                dispatch(setCommunicationTemplateActionFinished());
            });
    }
}

export const resetCommunicationTemplateTranslation = (requestBody) => {
    return (dispatch) => {
        dispatch(setCommunicationTemplateActionBefore());

        return axios({
            url: ApiUrls.RESET_COMMUNICATION_TEMPLATE_TRANSLATION,
            method: Methods.DELETE,
            data: requestBody
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    dispatch(setCommunicationTemplateTranslation(data.value));
                } 
                dispatch(setCommunicationTemplateActionFinished());
            })
            .catch((ex) => {
                dispatch(setCommunicationTemplateActionFinished());
            });
    }
}

export const saveCommunicationTemplateTranslation = (requestBody) => {
    return dispatch => {
        dispatch(setCommunicationTemplateSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_COMMUNICATION_TEMPLATE_TRANSLATION,
            method: Methods.POST,
            data: requestBody
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setCommunicationTemplateTranslation(data.value));
            } 
            dispatch(setCommunicationTemplateSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommunicationTemplateSaveActionFinished());
        })
    }
}