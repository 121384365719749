import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Upload as AntUpload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { getUser } from 'utils/auth';
import { doUploadRequest } from "utils/upload";

const Upload = ({
    label,
    btnText,
    action,
    onChange,
    onFileReady,
    onRemove,
    defaultFileUrl,
    defaultFileName,
    data,
    extensions,
    size,
    showUploadList,
    onBeforeUpload,
    globalCompanyId,
    globalProjectId,
    disabled,
    children
}) => {

    const { t } = useTranslation();

    const [files, setFiles] = useState(defaultFileUrl ? [{
        url: defaultFileUrl,
        name: defaultFileName,
        uid: Math.random(),
        status: "done"
    }] : []);

    useEffect(() => {
        setFiles(defaultFileUrl ? [{
            url: defaultFileUrl,
            name: defaultFileName,
            uid: Math.random(),
            status: "done"
        }] : [])
    }, [defaultFileUrl])


    const headersForUpload = {};
    if (getUser()?.token) {
        headersForUpload['Authorization'] = 'Bearer ' + getUser()?.token;
    }

    if (globalCompanyId) {
        headersForUpload['CompanyId'] = globalCompanyId;
    }

    if (globalProjectId) {
        headersForUpload['ProjectId'] = globalProjectId;
    }

    /** Fires on upload value change
       * @function
       * @description keep only first file, skip others
       * @param {object} info - info about files
       * @memberOf Upload
   */
    const handleChange = info => {
        let fileList = [...info.fileList.filter(f => f.status !== undefined)];
        fileList = fileList.slice(-1);
        setFiles(fileList);
        let logo = fileList.filter(f => f.status === "done")[0];
        if (logo) {
            onChange && onChange(logo)
        }
    }

    /** Fires before uploading
       * @function
       * @description check if file format and size are allowed
       * @param {object} file - the file to upload
       * @memberOf Upload
   */
    const beforeUpload = file => {
        if (extensions && !extensions.includes(file.type)) {
            message.info(t('backoffice.errors.InvalidFile'));
            return Promise.reject(t('backoffice.errors.InvalidFile'));
        } else if (size && size < file.size) {
            message.info(t('backoffice.errors.InvalidFileSize'));
            return Promise.reject(t('backoffice.errors.InvalidFileSize'));
        }
        if(!action){
            return
        }
        onBeforeUpload && onBeforeUpload();
        return true;
    }

    return (
        <AntUpload
            fileList={files}
            action={action}
            extensions={extensions}
            showUploadList={showUploadList}
            onChange={handleChange}
            multiple={false}
            onRemove={onRemove}
            headers={headersForUpload}
            data={data}
            beforeUpload={beforeUpload}
            accept={extensions}
            customRequest={({ onSuccess, onError, onProgress, file }) => {
                onFileReady && onFileReady(file);
                if(action){
                    doUploadRequest({ action, onSuccess, onError, onProgress, file, data })
                } 
            }}
            disabled={disabled}
        >
            {
                children ? children : (
                    <Button
                        disabled={disabled}
                    >
                        <UploadOutlined />
                        {btnText}
                    </Button>
                )
            }

        </AntUpload>
    )
}

Upload.propTypes = {
    /**The lable of form control */
    label: PropTypes.string,
    /**Upload button text */
    btnText: PropTypes.string,
    /** The url to upload */
    action: PropTypes.string,
    /** upload change handler */
    onChange: PropTypes.func,
    /** Fires on file Ready */
    onFileReady: PropTypes.func,
    /** Fires on remove button click next to file  */
    onRemove: PropTypes.func,
    /** Default file url */
    defaultFileUrl: PropTypes.string,
    /** Default file name */
    defaultFileName: PropTypes.string,
    /** Files data of upload */
    data: PropTypes.object,
    /** Files extension allowed for upload */
    extensions: PropTypes.arrayOf(PropTypes.string),
    /** Max file size allowed for upload */
    size: PropTypes.number,
    /** Whether to show default upload list */
    showUploadList: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    /** Whether the upload is disabled */
    disabled: PropTypes.bool,
    /** Function which will be executed before uploading */
    onBeforeUpload: PropTypes.func,
    /** Redux state, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state, represents global project id */
    globalProjectId: PropTypes.string,
    /** The JSX content of upload */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
}

const mapStateToProps = state => {
    return {
        globalCompanyId: state.common.globalCompanyId,
        globalProjectId: state.common.globalProjectId
    }
}

export default connect(mapStateToProps, null)(Upload)
