import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
    SET_NEWSLETTER_AFFILIATES_ACTION_BEFORE,
    SET_NEWSLETTER_AFFILIATES_ACTION_FINISH,
    SET_NEWSLETTER_AFFILIATES,
    SET_NEWSLETTER_AFFILIATES_SORTING,
    SET_NEWSLETTER_AVAILABLE_AFFILIATES
} from "../../../../actionTypes";

import { isMobile } from "utils/common";


const setNewsletterAffiliatesActionBefore = () => ({
    type: SET_NEWSLETTER_AFFILIATES_ACTION_BEFORE,
});

const setNewsletterAffiliatesActionFinished = () => ({
    type: SET_NEWSLETTER_AFFILIATES_ACTION_FINISH
});

const setNewsletterAvailableAffiliates = affiliates => ({
    type: SET_NEWSLETTER_AVAILABLE_AFFILIATES,
    payload: { affiliates }
})


export const getNewsletterAvailableAffiliates = filters => {
    return dispatch => {

        dispatch(setNewsletterAffiliatesActionBefore());
        return axios({
            url: ApiUrls.GET_NEWSLETTER_AVAILABLE_AFFILIATES,
            method: Methods.GET,
            params: filters,
        })
            .then(({ data: { value: affiliates } }) => {
                dispatch(setNewsletterAvailableAffiliates(affiliates));
                dispatch(setNewsletterAffiliatesActionFinished());
            })
            .catch((ex) => {
                dispatch(setNewsletterAffiliatesActionFinished());
            });
    }
}

/** Newsletter Affiliates */


const setNewsletterAffiliates = (affiliates, add) => ({
    type: SET_NEWSLETTER_AFFILIATES,
    payload: { affiliates, add }
})


export const setNewsletterAffiliatesSorting = sorting => ({
    type: SET_NEWSLETTER_AFFILIATES_SORTING,
    payload: { sorting },
});

export const getNewsletterAffiliates = (id, nextPage) => {
    return (dispatch, getState) => {
        const affiliatesData = getState().newsletter.affiliates;

        const page = nextPage === "first" ? 1 : nextPage === "next" ? affiliatesData.sorting.page + 1 : affiliatesData.sorting.page;
        
        let params = {
            ...affiliatesData.sorting,
            id
        };

        if(page > 1 && affiliatesData.total <= (page - 1 ) * affiliatesData.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && affiliatesData.total === 0){
            return Promise.resolve();
        }

        dispatch(setNewsletterAffiliatesActionBefore());
        return axios({
            url: ApiUrls.GET_NEWSLETTER_AFFILIATES,
            method: Methods.GET,
            params: params
        })
        .then(({data : {value: affiliates}}) => {
            dispatch(setNewsletterAffiliates(affiliates, isMobile() && page !== 1));
            if (nextPage)
                dispatch(setNewsletterAffiliatesSorting({ ...affiliatesData.sorting, page: page }));
            dispatch(setNewsletterAffiliatesActionFinished());
        })
        .catch((ex) => {
            dispatch(setNewsletterAffiliatesActionFinished());
        })
    }
}