//#region React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from "react-router-dom";
//#endregion

import { Form, Col, Row } from 'antd';

//#region Components
import Modal from "components/common/modal";
import Select from 'components/common/select';
//#endregion

//#region Actions
import {
    addProjectPaymentMethodCurrency,
    getProjectPaymentMethods
} from "store/actions/portal/projects/paymentMethods.action";
//#endregion

//#region Hooks
import useCurrencies from 'hooks/useCurrencies';
//#endregion

//#region Constants
import { POPUP_SIZE } from "constants/common.constants";
//#endregion

//#region Types
import paymentCurrencyType from "types/project/paymentMethod.type";
//#endregion

const FORM_FIELD_NAMES = {
    CURRENCY: "currencyCode"
}

/** Add Currency to the Project Payment Popup Component */
const AddCurrency = ({
    isSaving,
    paymentCurrencies,
    addProjectPaymentMethodCurrency,
    getProjectPaymentMethods,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;
    const [searchParams] = useSearchParams();

    const paymentId = searchParams.get("paymentId");

    const [ currencies ] = useCurrencies();

    const groupedPaymentCurrencies = paymentCurrencies.reduce((acc, paymentCurrency) => {
        acc[paymentCurrency.currencyCode] = true;
        return acc;
    }, {});

    const currencyOptions = currencies.filter(
        ({ code }) => !groupedPaymentCurrencies[code]).map(
            ({ code, name }) => ({ text: name, value: code }));

    const handleForm = () => {
        validateFields()
            .then((data) => {
                addProjectPaymentMethodCurrency(
                    { ...data, id: paymentId },
                    currency => {
                        onClose();
                        getProjectPaymentMethods(paymentId)
                    }
                );
            }).catch(() => { })
    };

    return (
        <Modal
            title={t('backoffice.projects.addCurrency')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            disableOkButton={currencyOptions.length === 0}
            size={POPUP_SIZE.SMALL}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.projects.currency')} *`}
                            name={FORM_FIELD_NAMES.CURRENCY}
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') }
                            ]}
                            className='rt--form-item-without-margin'
                            validateFirst
                        >
                            <Select
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.currency')}`}
                                options={currencyOptions}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

AddCurrency.propTypes = {
    /** React Prop, represents the currencies of current editing payment */
    paymentCurrencies: PropTypes.arrayOf(paymentCurrencyType),
    /** Redux state property, is true when adding Currency to the Project Payment is in process */
    isSaving: PropTypes.bool,
    /** Redux action to add Currency to the Project Payment */
    addProjectPaymentMethodCurrency: PropTypes.func,
    /** Redux action to get Project Payment Details */
    getProjectPaymentMethods: PropTypes.func,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        addProjectPaymentMethodCurrency: (params, onSuccess) => {
            dispatch(addProjectPaymentMethodCurrency(params, onSuccess));
        },
        getProjectPaymentMethods: paymentId => {
            dispatch(getProjectPaymentMethods(paymentId));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.projects.isSaving,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCurrency);