import axios from 'axios';

import store from 'store/configureStore';

import { setTokenExpiration, setAuthenticateActionWSToken } from 'store/actions/auth/auth.action';
import { setGlobalCompanyId, setGlobalProjectId } from 'store/actions/portal/common/common.action';
import { setUserCurrency } from 'store/actions/portal/profile/currencies.action';

import sessionStorageUtils from './sessionStorage';

import { TOKEN_TYPE } from 'constants/auth.constants';

import Paths from 'constants/path.constants';
import ApiUrls from 'constants/api.constants';
import Methods from 'constants/methods.constants';
import { USER_ROLE } from 'constants/user.constants';
import { TOKEN_EXPIRATION } from 'constants/common.constants';

/** Checks if user is Authenticated 
     * @function
     * @returns {boolean}
 */
export const isAuthenticated = () => {
    const authData = sessionStorageUtils.get('authorizationData');
    return !!authData;
}

/** Get current user 
     * @function
     * @returns {Object}
 */
export const getUser = () => {
    const authData = sessionStorageUtils.get('authorizationData');
    return authData ? {
        isLoggedIn: true,
        token: authData.token,
        wsToken: authData.wsToken,
        refreshToken: authData.refreshToken,
        userName: authData.userName,
        expires: authData.expires,
        role: authData.role,
        sessionId: authData.sessionId,
        userState: authData.userState,
        companyId: authData.companyId,
        companyLongId: authData.companyLongId,
        projectId: authData.projectId,
        projectLongId: authData.projectLongId,
        hasPrevious: authData.hasPrevious,
        allowImpactOnCPA: authData.allowImpactOnCPA
    } : null;
}

/** Function to Login user 
     * @function
     * @param {Object} user - user data got from user authenticate request
 */
export const loginUser = user => {
    if(user){
        store.dispatch(setAuthenticateActionWSToken(user.wsToken));
    }
    sessionStorageUtils.set('authorizationData', user);
    sessionStorageUtils.set("selectedCompanyId", user?.companyId);
    if(user?.role !== USER_ROLE.ACCESS_MANAGER){
        sessionStorageUtils.set("selectedProjectId", user?.projectId);
        sessionStorageUtils.set("selectedProjectType", user?.projectType);
    }
    
    startWatchingRefreshTokenExpiration();
}

/** Function to Logout user 
     * @function
 */
export const logout = () => {
    stopWatchingRefreshTokenExpiration();
    store.dispatch(setAuthenticateActionWSToken(null));
    let loginPath = Paths.LOGIN;

    sessionStorageUtils.remove("authorizationData");
    sessionStorageUtils.remove("selectedCompanyId");
    sessionStorageUtils.remove("selectedProjectId");
    sessionStorageUtils.remove("selectedProjectType");
    sessionStorageUtils.remove("unreadNotificationsCount");
    sessionStorageUtils.remove("currency");

    window.location.href = loginPath;
}

/** Function to change Currency */
export const changeCurrency = currency => {
    store.dispatch(setUserCurrency(currency))
    sessionStorageUtils.set("currency", currency);
}

const pendingRequests = [];
let isRefreshingToken = false;

/** Sends request to server to refresh token
     * @function
     * @param {string} refresh_token - refresh token
     * @param {string} userId - user id
     * @param {string} userName - user name
     * @param {Object} requestConfig - the request configuration which will be sends, when new tokens got from server
     * @returns {Promise}
 */
export const refreshToken = (refresh_token, requestConfig) => {

    if (!isRefreshingToken) {
        isRefreshingToken = true;
        return axios({
            url: ApiUrls.REFRESH_TOKEN,
            method: Methods.POST,
            data: { refreshToken: refresh_token },
            headers: { 
                grant_type: "refresh_token",
            }
        }).then(({ status, data: { value: authData } }) => {
            if (status === 200 && authData.tokenType === TOKEN_TYPE.NONE) {
                loginUser(authData);
                store.dispatch(setGlobalProjectId(authData.projectId));
                store.dispatch(setGlobalCompanyId(authData.companyId));

                isRefreshingToken = false;

                pendingRequests.forEach(req => {
                    req.request && axios.request(req.request).then(d => req.resolve(d)).catch(err => req.reject(err));
                });
                pendingRequests.splice(0, pendingRequests.length);
                return requestConfig && axios.request(requestConfig);
            } else {
                logout();
            }
        }).catch(() => {
            isRefreshingToken = false;
        })
    } else {
        const obj = {
            request: requestConfig
        }
        const promise = new Promise((resolve, reject) => {
            obj.resolve = resolve;
            obj.reject = reject;
            pendingRequests.push(obj);
        });
        obj.promise = promise;
        return promise;
    }

}

let interval = null;
let timer = 0;

/** Start interval, to check if token is near to be expired
    * @function
*/

export const startWatchingRefreshTokenExpiration = () => {
    stopWatchingRefreshTokenExpiration()
    interval = setInterval(() => {
        timer--;
        if (timer < 0) {
            logout();
        }
        store.dispatch(setTokenExpiration(timer));
    }, 1000)
}

/** stop interval, to check if token is near to be expired
     * @function
 */
const stopWatchingRefreshTokenExpiration = () => {
    clearInterval(interval);
    timer = TOKEN_EXPIRATION;
    store.dispatch(setTokenExpiration(timer));
}