import React from 'react';
import i18n from 'translations/config';

import { binaryToFlags } from 'utils/common';

import { TARGET_MARKET, TARGET_MARKETS_TT_KEYS } from 'constants/affiliate.constants';

import { LEADERBOARD_TYPE } from "../constants";

export const getTableColumns = (additionalProps) => {
    const {
        type,
        currencyCode,
        formatAmount,
        isPlayerData
    } = additionalProps;

    return [
        {
            title: "#",
            render: (value, rowData, index) => <div className={'rt--chart-top-num rt--chart-top-num' + '-' + (index + 1)}>
                <span className={'rt--font-normal rt--font-regular'}>
                    {index + 1}
                </span>
            </div>
        },
        {
            title: "backoffice.dashboard.id",
            dataIndex: "id",
            copy: true,
        },
        {
            title: "backoffice.dashboard.username",
            dataIndex: "userName",
        },
        !isPlayerData && {
            title: "backoffice.dashboard.country",
            dataIndex: "countryCode",
            mobileLevel: 3,
            render: value => value ? (
                <div className='rt--flex rt--align-center'>
                    <div className={`rt--flag rt--mr-4 rt--flag-${value.toLowerCase()}`} />
                    {i18n.t(`backoffice.countries.${value}`)}
                </div>
            ) : "-"
        },
        !isPlayerData && {
            title: "backoffice.dashboard.targetMarket",
            dataIndex: "targetMarket",
            multi: true,
            multiMapper: (value) => value ? binaryToFlags(
                Object.values(TARGET_MARKET), value).map((key) => i18n.t(`backoffice.affiliates.${TARGET_MARKETS_TT_KEYS[key]}`)
                ) : []
        },
        type === LEADERBOARD_TYPE.FINANCIAL && {
            title: "backoffice.dashboard.amount",
            dataIndex: "amount",
            render: value => formatAmount(value, currencyCode)
        },
        type === LEADERBOARD_TYPE.OPERATIONAL && {
            title: "backoffice.dashboard.count",
            dataIndex: "count",
            isNumeric: true,
        }
    ];
}