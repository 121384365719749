import React from "react";

import BalanceHistoryComponent from "pages/payment/balanceHistory/balanceHistory-component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";
import Paths from "constants/path.constants";

const BalanceHistoryRoute = () => {
    return <BalanceHistoryComponent />;
};

export default withPermission(
    withAuth(BalanceHistoryRoute),
    { resource: PERMISSION_RESOURCE.PAYMENTS_WALLETS_HISTORY, action: PERMISSION_ACTION.VIEW },
    Paths.PORTAL
);
