import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Spin, Button } from 'antd';

import {
    addPromoBrand,
    deletePromoBrand,
    getPromoBrands,
    savePromoBrand
} from 'store/actions/portal/promoCustomize/sections.action';

import Table from 'components/common/table';
import Confirmation from 'components/common/confirmation';
import BrandsAddEditComponent from './brands-edit-component';
import TableEmptyView from '../tableEmptyView';

import { tableColumnsCreator } from 'utils/tableColumnsCreator';

import { tableColumns } from './columns';
import {
    PROMO_SECTION_TYPE
} from 'constants/promo.constants';

const DELETE_CONFIRMATION_DEFAULT_DATA = {
    isVisible: false,
    brandId: null
}

const Brands = ({
    data,
    isLoading,
    isSaving,
    customizeLanguage,
    getPromoBrands,
    addPromoBrand,
    savePromoBrand,
    deletePromoBrand,
}) => {
    const { t } = useTranslation();

    const [addEditPopupData, setAddEditPopupData] = useState(null);

    const [deleteConfirmationData, setDeleteConfirmationData] = useState({
        ...DELETE_CONFIRMATION_DEFAULT_DATA
    })

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const showAddEditPopup = ({ brandName = "", url = "", ...restProps }) => {
        setAddEditPopupData({
            isVisible: true,
            brandName,
            url,
            ...restProps
        })
    }

    const hideEditPopup = () => {
        setAddEditPopupData(null)
    }

    const showDeleteConfirmation = (rowData) => {
        setDeleteConfirmationData({
            brandId: rowData.id,
            isVisible: true,
        })
    }

    const hideDeleteConfirmation = () => {
        setDeleteConfirmationData({
            ...DELETE_CONFIRMATION_DEFAULT_DATA
        })
    }

    const handleBrandAddEdit = ({ brandName, url }) => {
        const requestBody = {
            name: brandName,
            url,
        }

        if (addEditPopupData.type === "add") {
            return addPromoBrand(requestBody);
        }

        requestBody.id = addEditPopupData.id
        requestBody.enabled = addEditPopupData.enabled

        savePromoBrand(requestBody);
    }

    const handleBrandDelete = (record) => {
        const requestBody = {
            id: deleteConfirmationData.brandId,
        }

        deletePromoBrand(requestBody)
        hideDeleteConfirmation();
    }

    const handleTableSwitcherChange = (isChecked, record) => {
        const requestBody = {
            name: record.name,
            id: record.id,
            url: record.url,
            enabled: isChecked
        }

        savePromoBrand(requestBody);
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({ mainColumns: tableColumns });
    }, [])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [
        {
            title: t("backoffice.promo.editBrand"),
            icon: "edit",
            onClick: record => showAddEditPopup({
                brandName: record.name,
                url: record.url,
                id: record.id,
                enabled: record.enabled,
                type: "edit",
            }),
        },
        {
            title: t("backoffice.promo.deleteBrand"),
            icon: "trash",
            onClick: showDeleteConfirmation,
        },
    ]

    //#endregion

    useEffect(() => {
        getPromoBrands();
    }, [customizeLanguage]);

    return (
        <Spin spinning={isLoading || isSaving}>
            <div className='rt--flex rt--justify-end rt--mb-16'>
                <Button
                    type="primary"
                    className="rt--button rt--button-main"
                    onClick={() => showAddEditPopup({
                        type: "add",
                    })}
                >
                    <span className='rt--font-normal'>
                        {t('backoffice.promo.addBrand')}
                    </span>
                </Button>
            </div>

            <div className="rt--promo-customize-table-container-400">
                <Table
                    data={data}
                    renderEmpty={() => <TableEmptyView />}
                    columns={mainTableColumns}
                    noPagination={true}
                    actions={tableRowActions}
                    switcher={{
                        valueIndex: "enabled",
                        dataIndex: "enabled",
                        onChange: handleTableSwitcherChange,
                    }}
                />
            </div>

            {addEditPopupData && (
                <BrandsAddEditComponent
                    onClose={hideEditPopup}
                    brandName={addEditPopupData.brandName}
                    url={addEditPopupData.url}
                    type={addEditPopupData.type}
                    onOk={handleBrandAddEdit}
                />
            )}

            <Confirmation
                title={t('backoffice.promo.deleteBrand')}
                message={t('backoffice.promo.deleteBrandMessage')}
                onOk={handleBrandDelete}
                onCancel={hideDeleteConfirmation}
                isVisible={deleteConfirmationData.isVisible}
            />
        </Spin>
    );
}

/** Brands propTypes
 * PropTypes
*/
Brands.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
        enabled: PropTypes.bool,
        id: PropTypes.number,
    })),
    /** Redux state property, is true when loading portal customization data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when promo saving */
    isSaving: PropTypes.bool,
    /** Redux action to get promo brands */
    getPromoBrands: PropTypes.func,
    /** Redux action to add promo brand */
    addPromoBrand: PropTypes.func,
    /** Redux action to save promo brand */
    savePromoBrand: PropTypes.func,
    /** Redux action to delete promo brand */
    deletePromoBrand: PropTypes.func,
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    getPromoBrands: () => {
        dispatch(getPromoBrands())
    },
    addPromoBrand: (requestBody) => {
        dispatch(addPromoBrand(requestBody))
    },
    savePromoBrand: (requestBody) => {
        dispatch(savePromoBrand(requestBody))
    },
    deletePromoBrand: (requestBody) => {
        dispatch(deletePromoBrand(requestBody))
    },
});

const mapStateToProps = (state) => {
    return {
        customizeLanguage: state.promoCustomize.customizeLanguage,
        isLoading: state.promoCustomize.isLoading,
        isSaving: state.promoCustomize.isSaving,
        data: state.promoCustomize.sections[PROMO_SECTION_TYPE.BRANDS].data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Brands);