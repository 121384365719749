import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setProjectsActionBefore, setProjectsActionFinished, setProjectsSaveActionBefore, setProjectsSaveActionFinished } from './projects.action';

import { 
    SET_PROJECT_FEE_SETTINGS
} from "../../../actionTypes";

const setProjectFeeSettings = feeSettings => ({
    type: SET_PROJECT_FEE_SETTINGS,
    payload: { feeSettings }
})

export const getProjectFeeSettings = () => {
    return dispatch => {
        dispatch(setProjectsActionBefore());
        return axios({
            url: ApiUrls.GET_PROJECT_FEE_SETTINGS,
            method: Methods.GET
        })
        .then(({data : {value: feeSettings}}) => {
            dispatch(setProjectFeeSettings(feeSettings))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const saveProjectFeeSettings = feeSettings => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_FEE_SETTINGS,
            method: Methods.POST,
            data: {
                ...feeSettings
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setProjectFeeSettings(data.value));
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}
