import React from 'react';

import { useTranslation } from 'react-i18next';

import Tabs from 'components/common/tabs';

import GeneralInfoComponent from '../edit/sections/general';

import Paths from 'constants/path.constants';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';


/** System Payments Edit Page Component */
const SystemPaymentsEdit = () => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.payments.general"),
            permissions: [{ resource: PERMISSION_RESOURCE.SYSTEM_PAYMENT_METHODS, action: PERMISSION_ACTION.VIEW }],
            component: <GeneralInfoComponent />
        }
    ]

    return (
        <Tabs
            items={items}
            mainPage={
                {
                    title: t('backoffice.menu.payments'),
                    path: Paths.SETTINGS_PAYMENTS
                }
            }
        />
    )
}


export default SystemPaymentsEdit;