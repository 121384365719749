import React from "react";

import PortalComponent from "pages/portal.component";

import withAuth from "hocs/withAuth";

const PortalRoute = () => {
    return <PortalComponent />;
};

export default withAuth(PortalRoute);
