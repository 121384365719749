import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Spin } from 'antd';

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";

import PermissionsList from 'components/common/permissions';

import { getUserPermissions, saveUserPermissions } from "store/actions/portal/userManagement/users/permissions.action";

import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { PERMISSION_ACTION } from 'constants/permissions.constants';
import permissionType from "types/permission/permission.type";

/** User Edit Page Permissions and Groups Tab Permissions Sub Tab Component */
const PermissionsComponent = ({
    getUserPermissions,
    saveUserPermissions,
    isSaving,
    isLoading,
    permissions,
    userId,
    onTabChange,
    permissionResource
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue, getFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const [initialValues, setInitialValues] = useState({});

    const [isCheckedAll, setIsCheckedAll] = useState(false);

    const _permissionRef = useRef(null);

    const canEdit = hasPermission({ resource: permissionResource, action: PERMISSION_ACTION.MODIFY }) && searchParams.id !== userId

    /** Load permissions */
    useEffect(() => {
        if (searchParams.id) {
            getUserPermissions(searchParams.id);
        }
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        const values = {}
        permissions.forEach(perm => {
            let data = {};
            data['parent'] = {};
            perm.actions.forEach(a => {
                data['parent'][a.action] = a.checked
            })
            perm.subPermissions.forEach(sub => {
                data[sub.resource] = {};
                sub.actions.forEach(a => {
                    data[sub.resource][a.action] = a.checked
                })
            })
            values[perm.resource] = data;
        })
        setFieldsValue(values);
        setInitialValues(values);
    }, [permissions])


    /** Fires when form submitted
       * @function
       * @memberOf PermissionsComponent
   */
    const handleForm = forceSave => {
        validateFields()
            .then(data => {
                const perms = Object.keys(data).map(resource => ({
                    resource: resource,
                    actions: data[resource].parent ? Object.keys(data[resource].parent).filter(k => data[resource]['parent'][k] === true) : [],
                    subPermissions: Object.keys(data[resource]).filter(r => r !== "parent").map(r => ({
                        resource: r,
                        actions: data[resource][r] ? Object.keys(data[resource][r]).filter(k => data[resource][r][k] === true) : []
                    }))
                }))

                saveUserPermissions({
                    id: searchParams.id,
                    permissions: perms
                })

                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: canEdit,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(_, formValues) => {
                        setIsFormTouched(isFormChanged({ ...formValues }, initialValues))
                    }}
                >
                    {
                        canEdit && (
                            <Form.Item
                                className='rt--form-item-without-height'
                            >
                                <div className='rt--flex rt--justify-end rt--align-center'>
                                    <span
                                        className='rt--title rt--font-normal rt--font-medium rt--cursor-pointer'
                                        onClick={() => _permissionRef.current && _permissionRef.current.toggleAll(isCheckedAll)}
                                    >
                                        {
                                            isCheckedAll ? t("backoffice.common.deselectAll") : t("backoffice.common.selectAll")
                                        }
                                    </span>
                                </div>
                            </Form.Item>
                        )
                    }

                    <PermissionsList
                        ref={_permissionRef}
                        permissions={permissions}
                        isLoading={isLoading}
                        formInstance={formInstance}
                        initialFormValues={initialValues}
                        editable={true}
                        onCheckAll={() => {
                            setIsFormTouched(isFormChanged({ ...getFieldsValue(true) }, initialValues))
                        }}
                        onChange={value => setIsCheckedAll(value)}
                        disabled={!canEdit}

                    />
                </Form>
            </Spin>
        </SubTabFormDashboardLayout>


    )
}

/** PermissionsComponent propTypes
    * PropTypes
*/
PermissionsComponent.propTypes = {
    /** Redux action to get user permissions */
    getUserPermissions: PropTypes.func,
    /** Redux action to save user permissions */
    saveUserPermissions: PropTypes.func,
    /** Redux state property, represents the array of user permissions */
    permissions: PropTypes.arrayOf(permissionType),
    /** Redux state property, is true when user permissions are saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when user permissions are loading */
    isLoading: PropTypes.bool,
    /** Redux state property, current user id */
    userId: PropTypes.string,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
    /** The permission resource */
    permissionResource: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getUserPermissions: id => {
            dispatch(getUserPermissions(id))
        },
        saveUserPermissions: permissions => {
            dispatch(saveUserPermissions(permissions))
        }
    }
)

const mapStateToProps = state => {
    return {
        permissions: state.users.edit.permissions,
        isSaving: state.users.isSaving,
        isLoading: state.users.isLoading,
        userId: state.profile.userInfo.id
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsComponent)