import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Spin } from 'antd';

import {
    getPromoSectionTranslations,
    savePromoSectionTranslations,
    resetPromoSectionTranslations,
    getPromoSection,
    savePromoSection,
} from 'store/actions/portal/promoCustomize/sections.action';

import CustomizeImgUploader from '../../customizeImgUploader';
import Table from 'components/common/table';
import TableEmptyView from '../tableEmptyView';

import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { TableDataBuilder } from 'utils/promo';

import {
    PROMO_FILE_NAMES,
    PROMO_FILE_TYPE,
    PROMO_SECTION_TYPE,
    SECTION_NAMES,
} from 'constants/promo.constants';
import { getTableColumns } from './columns';

const EarnProcess = ({
    isLoading,
    isSaving,
    customizeLanguage,
    data,
    getPromoSection,
    savePromoSection,
    getPromoSectionTranslations,
    savePromoSectionTranslations,
    resetPromoSectionTranslations,
    translations,
    files,
}) => {
    const { t } = useTranslation();

    const tableData = (
        new TableDataBuilder(data)
            .mergeDataWithTranslations(translations, ["title", "description"])
            .build()
    )

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            additionalProps: {
                savePromoSectionTranslations,
                resetPromoSectionTranslations
            }
        });
    }, [savePromoSectionTranslations, resetPromoSectionTranslations])

    const handleTableSwitcherChange = (isChecked, record) => {
        const requestBody = {
            key: record.rowKey,
            enabled: isChecked,
        }

        savePromoSection(PROMO_SECTION_TYPE.EARN_PROCESS, requestBody);
    }

    // Get Earn Process Data
    useEffect(() => {
        getPromoSection(PROMO_SECTION_TYPE.EARN_PROCESS);
    }, [customizeLanguage]);

    // Get Statistics Translations
    useEffect(() => {
        getPromoSectionTranslations(
            SECTION_NAMES.EARN_PROCESS,
            PROMO_SECTION_TYPE.EARN_PROCESS
        );
    }, [customizeLanguage]);

    return (
        <Spin spinning={isLoading || isSaving}>
            <div>
                <Form
                    className="rt--form"
                    colon={false}
                    requiredMark={false}
                    layout="vertical"
                >
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label={t("backoffice.promo.background")}
                            >
                                <CustomizeImgUploader
                                    sectionName={SECTION_NAMES.EARN_PROCESS}
                                    sectionType={PROMO_SECTION_TYPE.EARN_PROCESS}
                                    fileType={PROMO_FILE_TYPE.BACKGROUND}
                                    languageCode={customizeLanguage}
                                    imgPath={files[PROMO_FILE_NAMES[PROMO_FILE_TYPE.BACKGROUND]]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <Table
                data={tableData}
                columns={mainTableColumns}
                renderEmpty={() => <TableEmptyView />}
                noPagination={true}
                switcher={{
                    valueIndex: "enabled",
                    dataIndex: "enabled",
                    className: "rt--mr-6",
                    onChange: handleTableSwitcherChange,
                }}
            />
        </Spin >
    );
}

/** EarnProcess propTypes
 * PropTypes
*/
EarnProcess.propTypes = {
    data: PropTypes.objectOf(PropTypes.bool),
    translations: PropTypes.objectOf(PropTypes.shape({
        isOverwritten: PropTypes.bool,
        key: PropTypes.string,
        translation: PropTypes.string,
    })),
    /** Redux state property, is true when loading portal customization data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when promo saving */
    isSaving: PropTypes.bool,
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
    /** Redux action to get promo earn process */
    getPromoSection: PropTypes.func,
    /** Redux action to save promo earn process */
    savePromoSection: PropTypes.func,
    /** Redux action to get promo section translations */
    getPromoSectionTranslations: PropTypes.func,
    /** Redux action to save promo section translations */
    savePromoSectionTranslations: PropTypes.func,
    /** Redux action to reset promo section translations */
    resetPromoSectionTranslations: PropTypes.func,
    /** Section Image URLs */
    files: PropTypes.objectOf(PropTypes.string),
};

const mapDispatchToProps = dispatch => ({
    getPromoSection: (sectionType) => {
        dispatch(getPromoSection(sectionType))
    },
    savePromoSection: (sectionType, requestBody) => {
        dispatch(savePromoSection(sectionType, requestBody))
    },
    getPromoSectionTranslations: (sectionName, sectionType) => {
        dispatch(getPromoSectionTranslations(sectionName, sectionType))
    },
    savePromoSectionTranslations: (sectionName, sectionType, key, text) => {
        dispatch(savePromoSectionTranslations(sectionName, sectionType, key, text))
    },
    resetPromoSectionTranslations: (sectionName, sectionType, key, text) => {
        dispatch(resetPromoSectionTranslations(sectionName, sectionType, key, text))
    },
});

const mapStateToProps = (state) => {
    return {
        customizeLanguage: state.promoCustomize.customizeLanguage,
        isLoading: state.promoCustomize.isLoading,
        isSaving: state.promoCustomize.isSaving,
        data: state.promoCustomize.sections[PROMO_SECTION_TYPE.EARN_PROCESS].data,
        translations: state.promoCustomize.sections[PROMO_SECTION_TYPE.EARN_PROCESS].translations,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EarnProcess);