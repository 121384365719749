import {
    SET_AFFILIATE_GROUPS_ACTION_BEFORE,
    SET_AFFILIATE_GROUPS_ACTION_FINISH,
    SET_AFFILIATE_GROUPS_SAVE_ACTION_BEFORE,
    SET_AFFILIATE_GROUPS_SAVE_ACTION_FINISH,
    SET_AFFILIATE_GROUPS,
    SET_AFFILIATE_GROUPS_SORTING,
    SET_AFFILIATE_GROUPS_FILTERS,
    SET_AFFILIATE_GROUP_AVAILABLE_AFFILIATES,
    SET_AFFILIATE_GROUP_GENERAL_INFO,
    SET_AFFILIATE_GROUP_MEMBERS,
    SET_AFFILIATE_GROUP_MEMBERS_SORTING,
    SET_AFFILIATE_GROUP_MEMBERS_FILTERS,
    SET_AFFILIATE_GROUP_FILTER
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_AFFILIATE_GROUPS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_AFFILIATE_GROUPS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_AFFILIATE_GROUPS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_AFFILIATE_GROUPS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_AFFILIATE_GROUPS:
            return {
                ...state,
                groups: !payload.add ? payload.groups.item2 : state.groups.concat(payload.groups.item2),
                total: payload.groups.item1,
            };
        case SET_AFFILIATE_GROUPS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_AFFILIATE_GROUPS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_AFFILIATE_GROUP_AVAILABLE_AFFILIATES:
            return {
                ...state,
                availableAffiliates: payload.affiliates,
            };
        case SET_AFFILIATE_GROUP_GENERAL_INFO:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...payload.info
                    }
                }
            }
            case SET_AFFILIATE_GROUP_MEMBERS:
                return {
                    ...state,
                    edit: {
                        ...state.edit,
                        members: {
                            ...state.edit.members,
                            members: !payload.add ? payload.members.item2 : state.edit.members.members.concat(payload.members.item2),
                            total: payload.members.item1,
                        },
                    },
                };
            case SET_AFFILIATE_GROUP_MEMBERS_SORTING:
                return {
                    ...state,
                    edit: {
                        ...state.edit,
                        members: {
                            ...state.edit.members,
                            sorting: payload.sorting
                        },
                    },
                };
            case SET_AFFILIATE_GROUP_MEMBERS_FILTERS:
                return {
                    ...state,
                    edit: {
                        ...state.edit,
                        members: {
                            ...state.edit.members,
                            filters: payload.filters,
                            sorting: {
                                ...state.edit.members.sorting,
                                page: 1
                            }
                        },
                    },
                };
            case SET_AFFILIATE_GROUP_FILTER:
                return {
                    ...state,
                    edit: {
                        ...state.edit,
                        members: {
                            ...state.edit.members,
                            groupFilter: payload.filter
                        },
                    },
                };
        default:
            return state;
    }
};
