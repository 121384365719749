
import axios from "axios";

import {
    setPromoCustomizeActionBefore,
    setPromoCustomizeActionFinished,
    setPromoCustomizeLanguage
} from "./common.action";

import {
    SET_PROMO_CUSTOMIZE_CONFIGURATION,
} from "store/actionTypes";

import LanguageUtils from 'utils/languages';

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

const setPromoConfiguration = configuration => ({
    type: SET_PROMO_CUSTOMIZE_CONFIGURATION,
    payload: { configuration }
})

export const getPromoConfiguration = (id, customizeLanguage) => {
    return dispatch => {
        dispatch(setPromoCustomizeActionBefore());

        const params = {
            languageCode: customizeLanguage ? customizeLanguage.toUpperCase() : LanguageUtils.getSelectedLanguage().toUpperCase(),
            id
        };

        return axios({
            url: ApiUrls.GET_PROMO_CONFIGURATION,
            method: Methods.GET,
            params: params
        })
            .then(({ data: { value: configuration } }) => {
                dispatch(setPromoConfiguration(configuration));
                dispatch(setPromoCustomizeLanguage(customizeLanguage ? customizeLanguage : configuration.defaultLanguageCode))
                dispatch(setPromoCustomizeActionFinished());
            })
            .catch((ex) => {
                dispatch(setPromoCustomizeActionFinished());
            })
    }
}