import {
    SET_ERRORS_ACTION_BEFORE,
    SET_ERRORS_ACTION_FINISH,
    SET_ERRORS,
    SET_ERRORS_SORTING,
    SET_ERRORS_FILTERS
} from '../../actionTypes';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_ERRORS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_ERRORS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_ERRORS:
            return {
                ...state,
                errors: !payload.add ? payload.errors.item2 : state.errors.concat(payload.errors.item2),
                total: payload.errors.item1,
            }
        case SET_ERRORS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_ERRORS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        default:
            return state;
    }
}