import React from 'react';
import i18n from "translations/config";

import Status from 'components/common/status';

import { STATUS_TYPES } from 'constants/status.constants';

export const tableColumns = [
    {
        title: "backoffice.companies.name",
        dataIndex: "name",
        alwaysVisible: true,
        sorter: true,
        mobileLevel: 1,
        render: (value, record) => (record.isTesting ? `${value} (Test)` : value),
    },
    {
        title: "backoffice.companies.id",
        dataIndex: "longId",
        alwaysVisible: true,
        copy: true,
        mobileLevel: 2,
    },
    {
        title: "backoffice.companies.country",
        dataIndex: "countryCode",
        mobileLevel: 3,
        render: value => value ? (
            <div className='rt--flex rt--align-center'>
                <div className={`rt--flag rt--mr-4 rt--flag-${value.toLowerCase()}`} />
                {i18n.t(`backoffice.countries.${value}`)}
            </div>
        ) : "-"
    },
 
    {
        title: "backoffice.companies.createdAt",
        dataIndex: "createdAt",
        isDateTime: true,
        sorter: true,
        mobileLevel: 4
    },
    {
        title: "backoffice.companies.lastUpdatedAt",
        dataIndex: "modifiedAt",
        isDateTime: true,
        sorter: true,
        mobileLevel: 5
    },
];