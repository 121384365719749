import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Outlet  } from "react-router-dom";

import { Spin } from "antd"

import Container from 'pages/common/container';

import { getLanguages } from 'store/actions/auth/translations.action';
import { getUserInfo } from "store/actions/portal/profile/userInfo.action";

import Styles from '../../components/styles'

const Layout = ({
    getLanguages,
    translationsLoaded,
    getUserInfo
}) => {

    /** Get system languages, Get user info */
    useEffect(() => {
        getUserInfo();
        getLanguages();
    }, [])

    return translationsLoaded ? (
        <Container>
            <Styles/>
            <Outlet />
        </Container>
    ) : <Spin spinning={true} />
}

/** Layout propTypes
    * PropTypes
*/
Layout.propTypes = {
    /** Redux action to get system languages */
    getLanguages: PropTypes.func,
    /** Redux state property , is true when translations are loaded*/
    translationsLoaded: PropTypes.bool,
    /** Redux action to get user info */
    getUserInfo: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        translationsLoaded: state.auth.translations.loaded,
    }
}

const mapDispatchToProps = dispatch => (
    {
        getLanguages: () => {
            dispatch(getLanguages());
        },

        getUserInfo: () => {
            dispatch(getUserInfo())
        },
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

