import {
    SET_SESSIONS_ACTION_BEFORE,
    SET_SESSIONS_ACTION_FINISH,
    SET_SESSIONS,
    SET_SESSIONS_SORTING,
    SET_SESSIONS_FILTERS,
    SET_SESSIONS_SAVE_ACTION_BEFORE,
    SET_SESSIONS_SAVE_ACTION_FINISH
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_SESSIONS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_SESSIONS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_SESSIONS:
            return {
                ...state,
                sessions: !payload.add ? payload.sessions.item2 : state.sessions.concat(payload.sessions.item2),
                total: payload.sessions.item1,
            };

        case SET_SESSIONS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: payload.keepPage ? state.sorting.page : 1
                }
            };
        case SET_SESSIONS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_SESSIONS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_SESSIONS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        
        default:
            return state;
    }
};
