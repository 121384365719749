import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { 
    setAffiliatesActionBefore, 
    setAffiliatesActionFinished, 
    setAffiliatesSaveActionBefore, 
    setAffiliatesSaveActionFinished 
} from './affiliates.action';

import { 
    SET_AFFILIATE_PAYMENT_METHODS_ACTION_BEFORE,
    SET_AFFILIATE_PAYMENT_METHODS_ACTION_FINISH,
    SET_AFFILIATE_PAYMENT_METHODS, 
    SET_AFFILIATE_AVAILABLE_PAYMENT_METHODS,
    SET_AFFILIATE_PAYMENT_METHOD_FORM_CONTROLS,
    SET_AFFILIATE_PAYMENT_METHOD_DETAILS 
} from "store/actionTypes";

const setAffiliatePaymentMethodsActionBefore = () => ({
    type: SET_AFFILIATE_PAYMENT_METHODS_ACTION_BEFORE
})

const setAffiliatePaymentMethodsActionFinished = () => ({
    type: SET_AFFILIATE_PAYMENT_METHODS_ACTION_FINISH
})

const setAffiliatePaymentMethods = paymentMethods => ({
    type: SET_AFFILIATE_PAYMENT_METHODS,
    payload: { paymentMethods }
})

const setAvailablePaymentMethods = availablePaymentMethods => ({
    type: SET_AFFILIATE_AVAILABLE_PAYMENT_METHODS,
    payload: { availablePaymentMethods }
})

const setPaymentMethodFormControls = paymentMethodFormControls => ({
    type: SET_AFFILIATE_PAYMENT_METHOD_FORM_CONTROLS,
    payload: { paymentMethodFormControls }
})

const setPaymentMethodDetails = (paymentDetails, paymentId, currencyCode) => ({
    type: SET_AFFILIATE_PAYMENT_METHOD_DETAILS,
    payload: { paymentDetails, paymentId, currencyCode }
})

export const getAvailablePaymentMethods = (id, currencyCode) => {
    return dispatch => {
        dispatch(setAffiliatePaymentMethodsActionBefore());

        return axios({
            url: ApiUrls.GET_AFFILIATE_AVAILABLE_PAYMENT_METHODS,
            method: Methods.GET,
            params: { id, currencyCode }
        })
        .then(({data : {value: availablePaymentMethods}}) => {
            dispatch(setAvailablePaymentMethods(availablePaymentMethods))
            dispatch(setAffiliatePaymentMethodsActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliatePaymentMethodsActionFinished());
        })
    }
}

export const getPaymentMethodFormControls = id => {
    return dispatch => {
       
        return axios({
            url: ApiUrls.GET_SYSTEM_PAYMENT_METHOD_FORM_TRANSLATIONS,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({ data }) => {
            dispatch(setPaymentMethodFormControls(data.value));
        })
        .catch((ex) => {
            console.log(ex);
        })
    }
}

export const getPaymentMethodFormControlsWithDetails = (paymentId, currencyCode, id) => {
    return dispatch => {
       
        return axios({
            url: ApiUrls.GET_AFFILIATE_PAYMENT_METHOD_DETAILS,
            method: Methods.GET,
            params: { paymentId, currencyCode, id }
        })
        .then(({ data }) => {
            dispatch(setPaymentMethodFormControls(data.value));
        })
        .catch((ex) => {
            console.log(ex);
        })
    }
}

export const getAffiliatePaymentMethodDetails = (paymentId, currencyCode, id) => {
    return dispatch => {
        dispatch(setAffiliatesActionBefore());

        return axios({
            url: ApiUrls.GET_AFFILIATE_PAYMENT_METHOD_DETAILS,
            method: Methods.GET,
            params: { paymentId, currencyCode, id }
        })
        .then(({data : { value: paymentDetails }}) => {
            dispatch(setPaymentMethodDetails(paymentDetails, paymentId, currencyCode))
            dispatch(setAffiliatesActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliatesActionFinished());
        })
    }
}

export const getAffiliatePaymentMethods = (id) => {
    return dispatch => {
        dispatch(setAffiliatesActionBefore());

        return axios({
            url: ApiUrls.GET_AFFILIATE_PAYMENT_METHODS,
            method: Methods.GET,
            params: { id }
        })
        .then(({data : {value: paymentMethods}}) => {
            dispatch(setAffiliatePaymentMethods(paymentMethods))
            dispatch(setAffiliatesActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliatesActionFinished());
        })
    }
}

export const addAffiliatePaymentMethod = (requestBody, onSuccess) => {
    return (dispatch) => {
        dispatch(setAffiliatesSaveActionBefore());
        return axios({
            url: ApiUrls.ADD_AFFILIATE_PAYMENT_METHOD,
            method: Methods.POST,
            data: requestBody
        })
            .then(({ data }) => {
                dispatch(setAffiliatesSaveActionFinished());
                if (isResponseSuccess(data)) {
                    dispatch( getAffiliatePaymentMethods(requestBody.id))
                    message.success(data.message);
                    onSuccess && onSuccess();
                }
            })
            .catch(() => {
                dispatch(setAffiliatesSaveActionFinished());
            });
    };
};

export const saveAffiliatePaymentMethod = (requestBody, onSuccess) => {
    return dispatch => {
        dispatch(setAffiliatesSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_AFFILIATE_PAYMENT_METHOD,
            method: Methods.POST,
            data: {
                ...requestBody
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                dispatch( getAffiliatePaymentMethods(requestBody.id))
                message.success(data.message);
                onSuccess && onSuccess();
            } 
            dispatch(setAffiliatesSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliatesSaveActionFinished());
        })
    }
}

export const deleteAffiliatePaymentMethod = (paymentId, currencyCode, id) => {
    return (dispatch) => {
        dispatch(setAffiliatesSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_AFFILIATE_PAYMENT_METHOD,
            method: Methods.DELETE,
            data: {
                paymentId, currencyCode, id
            }
        })
            .then(({ data }) => {
                dispatch(setAffiliatesSaveActionFinished());
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    dispatch(getAffiliatePaymentMethods(id));
                }
            })
            .catch(() => {
                dispatch(setAffiliatesSaveActionFinished());
            });
    };
};

export const setAffiliateDefaultPaymentMethod = (paymentId, currencyCode, id) => {
    return (dispatch) => {
        dispatch(setAffiliatesSaveActionBefore());
        return axios({
            url: ApiUrls.SET_AFFILIATE_DEFAULT_PAYMENT_METHOD,
            method: Methods.POST,
            data: {
                paymentId, currencyCode, id
            }
        })
            .then(({ data }) => {
                dispatch(setAffiliatesSaveActionFinished());
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    dispatch(getAffiliatePaymentMethods(id));
                }
            })
            .catch(() => {
                dispatch(setAffiliatesSaveActionFinished());
            });
    };
};
