import PropTypes from 'prop-types';

import { USER_GENDER } from "constants/user.constants";

export default PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userName: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.number,
    tel: PropTypes.string,
    address: PropTypes.string,
    allowEdit: PropTypes.bool,
    expirationEnabled: PropTypes.bool,
    expirationDate: PropTypes.string,
    isTesting: PropTypes.bool,
    avatarId: PropTypes.string,
    securityLevel: PropTypes.number,
    mainCompany: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.number
    }),
    passwordExpirationEnabled: PropTypes.bool,
    lastActiveAt: PropTypes.string,
    isOnline: PropTypes.bool
})