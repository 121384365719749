import React from 'react';
import PropTypes from 'prop-types';

import Icon from "components/common/icon";

import { isFunction } from "utils/common";

const TableColumnContent = ({ columnData, rowData, onEdit, smallInMobile }) => {
    const {
        mobileRender,
        render,
        dataIndex,
        editable,
        editableTitle,
        editableLabel,
        maxLength,
        regex,
        extraButtons,
        editableFieldType,
    } = columnData;

    const handleEditIconInternalClick = () => {
        const cellEditData = {
            maxLength,
            regex,
            extraButtons,
            rowData,
            editableFieldType,
            title: editableTitle(rowData),
            label: editableLabel(rowData),
            onSave: value => columnData.onEdit(value, rowData),
            initialValue: (
                isFunction(render)
                    ? render(rowData[dataIndex], rowData)
                    : rowData[dataIndex]
            ),
        }

        onEdit(cellEditData);
    }

    const valueToRender = (
        isFunction(mobileRender)
            ? mobileRender(rowData[dataIndex], rowData)
            : isFunction(render)
                ? render(rowData[dataIndex], rowData)
                : rowData[dataIndex]
    )

    return (
        <div className="rt--flex rt--align-center rt--table-item-col-inner">
            <span className="rt--title rt--font-normal rt--font-bold">
                {valueToRender}
            </span>
            {
                Boolean(editable) && (
                    <div className='rt--flex rt--align-center rt--cursor-pointer rt--ml-2 rt--mr-2' onClick={handleEditIconInternalClick}>
                        <Icon name="edit" size={smallInMobile? 16 : 20} />
                    </div>
                )
            }
        </div>
    )
}

/** TableColumnContent propTypes
 * PropTypes
*/
TableColumnContent.propTypes = {
    onEdit: PropTypes.func,
    rowData: PropTypes.object,
    columnData: PropTypes.object,
    smallInMobile: PropTypes.bool
}

export default TableColumnContent