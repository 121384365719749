import PropTypes from 'prop-types';

import { WALLET_TYPE } from 'constants/wallet.constants';

export default PropTypes.shape({
    currencyCode: PropTypes.string,
    wallets: PropTypes.arrayOf(PropTypes.shape({
        amount: PropTypes.number,
        type: PropTypes.oneOf(Object.values(WALLET_TYPE))
    })),
    currency: PropTypes.shape({
        code: PropTypes.string,
        decimalCount: PropTypes.number,
        isDefault: PropTypes.bool,
        name: PropTypes.string,
        projectId: PropTypes.string,
        rate: PropTypes.number
    })
})