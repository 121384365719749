import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
    SET_PERMISSIONS_ACTION_BEFORE,
    SET_PERMISSIONS_ACTION_FINISH,
    SET_PERMISSIONS,
    SET_PERMISSION_RESOURCES_ACTION_BEFORE,
    SET_PERMISSION_RESOURCES_ACTION_FINISH,
    SET_PERMISSIONS_RESOURCES
} from '../../../../actionTypes';

const setPermissionsActionBefore = () => ({
    type: SET_PERMISSIONS_ACTION_BEFORE
})

const setPermissionsActionFinished = () => ({
    type: SET_PERMISSIONS_ACTION_FINISH
})

const setPermissionResourcesActionBefore = () => ({
    type: SET_PERMISSION_RESOURCES_ACTION_BEFORE
})

const setPermissionResourcesActionFinished = () => ({
    type: SET_PERMISSION_RESOURCES_ACTION_FINISH
})

const setPermissions = permissions => ({
    type: SET_PERMISSIONS,
    payload: { permissions }
})

const setPermissionResources = resources => ({
    type: SET_PERMISSIONS_RESOURCES,
    payload: { resources }
})

export const getPermissions = (resource, type) => {
    return dispatch => {
        dispatch(setPermissionsActionBefore());

        return axios({
            url: ApiUrls.GET_SYSTEM_PERMISSIONS,
            method: Methods.GET,
            params: { resource, type }
        })
        .then(({data : {value: permissions}}) => {
            dispatch(setPermissions(permissions));
            dispatch(setPermissionsActionFinished());
        })
        .catch(() => {
            dispatch(setPermissionsActionFinished());
        })
    }
}

export const getPermissionResources = type => {
    return dispatch => {
        dispatch(setPermissionResourcesActionBefore());

        return axios({
            url: ApiUrls.GET_SYSTEM_PERMISSION_RESOURCES,
            method: Methods.GET,
            params: { type }
        })
        .then(({data : {value: resources}}) => {
            dispatch(setPermissionResources(resources));
            dispatch(setPermissionResourcesActionFinished());
        })
        .catch(() => {
            dispatch(setPermissionResourcesActionFinished());
        })
    }
}