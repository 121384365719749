import { message } from 'antd'
import axios from 'axios';
import Methods from 'constants/methods.constants';
import ApiUrls from 'constants/api.constants';

import { isResponseSuccess } from "utils/request";

import {
    CHANGE_PASSWORD_ACTION_BEFORE,
    CHANGE_PASSWORD_ACTION_FINISH
} from '../../../actionTypes';


const setChangePasswordActionBefore = () => ({
    type: CHANGE_PASSWORD_ACTION_BEFORE
})

const setChangePasswordActionFinished = () => ({
    type: CHANGE_PASSWORD_ACTION_FINISH
})


export const changePassword = (data, onSuccess) => {
    return dispatch => {
        dispatch(setChangePasswordActionBefore());

        return axios({
            url: ApiUrls.CHANGE_PASSWORD,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);

                onSuccess && onSuccess();
            } 
            dispatch(setChangePasswordActionFinished());
        })
        .catch((ex) => {
            dispatch(setChangePasswordActionFinished());
        })
    }
}

