import React, { Fragment } from "react"
import PropTypes from "prop-types";

import { Button } from "antd";

import Select from "components/common/select";
import ColumnsButton from "components/common/columnsButton";
import Icon from "components/common/icon";

import { isMobile } from "utils/common";

import buttonType from 'types/components/button.type';
import columnsButtonType from "types/components/columnsButton.type";

const PLACEMENT = {
    RIGHT: "right",
    LEFT: "left"
}

/** Sub Tab Table dashboard layout */
const SubTabTableDashboardLayout = ({
    children,
    header,
    buttons = [],
    dropdowns,
    button,
    extraContent,
}) => {

    const isMobileDevice = isMobile();

    const enabledRightPlacedButtons = buttons.filter(
        buttonProps => buttonProps.placement !== PLACEMENT.LEFT && buttonProps.enabled
    );
    const enabledLeftPlacedButtons = buttons.filter(
        buttonProps => buttonProps.placement === PLACEMENT.LEFT && buttonProps.enabled
    );

    const hasEnabledButtons = enabledRightPlacedButtons.length + (isMobileDevice ? 0 : enabledLeftPlacedButtons.length) > 0;

    /** Function to Render dropdown 
        * @function
        * @param {object} dropdown
        * @returns {JSX}
        * @memberOf SubTabTableDashboardLayout
   */
    const renderDropdown = dropdown => (
        <Select
            options={
                dropdown.items.map(item => (
                    { value: item.key, text: item.value }
                ))
            }
            placeholder={dropdown.placeholder}
            value={dropdown.value}
            onChange={dropdown.onChange}
            isDark={!isMobile()}
            className={"rt--subtab-table-layout-actions-dropdown" + (dropdown.small ? " rt--subtab-table-layout-actions-dropdown-small" : "")}
            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
        />
    )

    return (
        <div className="rt--subtab-table-layout rt--flex rt--flex-col">
            <div className="rt--subtab-table-layout-content rt--flex-equal rt--flex rt--flex-col">
                {
                    (
                        (button && button.enabled) ||
                        isMobile() && dropdowns
                    ) && (
                        <div className={isMobile() ? "rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16" : "rt--pl-24 rt--pr-24 rt--pt-24 rt--pb-24"}>
                            {
                                button && button.enabled && (
                                    <div className="rt--subtab-table-layout-content-header rt--align-center rt--flex rt--jsutify-between rt--mb-16">
                                        <Button
                                            icon={<Icon name={button.icon} />}
                                            type={button.type}
                                            className={
                                                "rt--button " +
                                                (button.className ? button.className : "") +
                                                (button.type === "primary" ? "rt--button-main" : "")
                                            }
                                            onClick={button.onClick}
                                            disabled={button.disabled ? true : false}
                                        >
                                            {
                                                button.text
                                            }

                                        </Button>
                                    </div>
                                )
                            }
                            {
                                isMobile() && dropdowns && (
                                    <div className="rt--subtab-table-layout-content-extra rt--flex rt--align-center">
                                        {
                                            dropdowns.map((dr, index) => (
                                                <Fragment key={index}>
                                                    {renderDropdown(dr)}
                                                </Fragment>
                                            ))
                                        }
                                    </div>
                                )
                            }

                        </div>
                    )
                }

                {
                    extraContent && (
                        <div className={"rt--subtab-table-layout-content-extra rt--pt-16" + (!isMobile() ? " rt--pl-24 rt--pr-24" : " rt--pl-16 rt--pr-16")}>
                            {extraContent}
                        </div>
                    )
                }

                <div className={"rt--flex-equal rt--subtab-table-layout-content-table rt--main-scrollable " + (isMobile() ? "rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16" : "rt--pl-24 rt--pr-24 rt--pt-24 rt--pb-24")}>
                    {children}
                </div>

                {
                    hasEnabledButtons && (
                        <div
                            className={
                                ("rt--subtab-form-layout-footer") + (isMobileDevice ? " rt--flex" : " rt--flex rt--align-center") +
                                (isMobileDevice ? "rt--pl-16 rt--pr-16 rt--pt-8 rt--pb-8" : "rt--pl-24 rt--pr-24 rt--pt-16 rt--pb-16") +
                                ((!isMobileDevice && enabledLeftPlacedButtons.length > 0) ? " rt--justify-between" : " rt--justify-end")
                            }
                        >
                            {
                                (!isMobileDevice && enabledLeftPlacedButtons.length > 0) && (
                                    <div className="rt--flex rt--align-center">
                                        {
                                            enabledLeftPlacedButtons.map(buttonProps => {
                                                const { type, className = "rt--button-primary", onClick, text, disabled = false, htmlType = "submit", loading } = buttonProps;
                                                return (
                                                    <Button
                                                        type={type}
                                                        className={"rt--button " + (className)}
                                                        onClick={onClick}
                                                        key={text}
                                                        disabled={disabled}
                                                        htmlType={htmlType}
                                                        loading={loading}
                                                    >
                                                        {
                                                            text
                                                        }

                                                    </Button>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }

                            {
                                enabledRightPlacedButtons.length > 0 && (
                                    <div
                                        className={
                                            "rt--flex rt--align-center" +
                                            (isMobileDevice && enabledRightPlacedButtons.length > 1 ? " rt--justify-between" : " rt--justify-end")
                                        }
                                    >
                                        {
                                            enabledRightPlacedButtons.map(buttonProps => {
                                                const { type, className = "", onClick, text, disabled = false, htmlType = "submit", loading } = buttonProps;

                                                return (
                                                    <Button
                                                        type={type}
                                                        className={"rt--button rt--button-primary" + (className)}
                                                        onClick={onClick}
                                                        key={text}
                                                        disabled={disabled}
                                                        htmlType={htmlType}
                                                        loading={loading}
                                                    >
                                                        {
                                                            text
                                                        }

                                                    </Button>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                        </div>
                    )
                }

            </div>
            {
                header && (
                    header.button && header.button.enabled || (dropdowns && !isMobile())
                ) && (
                    <div className="rt--subtab-table-layout-actions rt--flex rt--align-center rt--justify-end">
                        {
                            !isMobile() && dropdowns && dropdowns.map((dr, index) => (
                                <div className="rt--ml-16" key={index}>
                                    {renderDropdown(dr)}
                                </div>
                            ))
                        }
                        {
                            <>
                                {header.columns && !isMobile() && (
                                    <ColumnsButton
                                        {...header.columns}
                                    />
                                )}
                                <Button
                                    icon={<Icon name={header.button.icon} />}
                                    type={header.button.type}
                                    className={
                                        "rt--button-main " +
                                        (!isMobile() ? "rt--ml-16 " : "") +
                                        (header.button.className ? header.button.className : "") +
                                        (header.button.type === "primary" ? "rt--button" : "")
                                    }
                                    onClick={header.button.onClick}
                                    disabled={header.button.disabled ? true : false}
                                >
                                    {
                                        isMobile() ? null : header.button.text
                                    }

                                </Button>
                            </>
                        }
                    </div>
                )
            }
        </div>
    )
}

/** SubTabTableDashboardLayout propTypes
 * PropTypes
*/
SubTabTableDashboardLayout.propTypes = {
    /** Layout Content */
    children: PropTypes.node,
    /** Header */
    header: PropTypes.shape({
        /** Columns Button */
        columns: PropTypes.shape(columnsButtonType),
        /** Header button */
        button: PropTypes.shape(buttonType),
        /** Extra content under table */
        extraContent: PropTypes.node,
    }),
    /** Dropdowns options */
    dropdowns: PropTypes.arrayOf(PropTypes.object),
    /** Content Header button */
    button: PropTypes.shape(buttonType),
    /** Extra content under table */
    extraContent: PropTypes.node,
    /** Buttons */
    buttons: PropTypes.arrayOf(PropTypes.shape(buttonType)),
}

export default SubTabTableDashboardLayout;