import {
    SET_CAMPAIGNS_ACTION_BEFORE,
    SET_CAMPAIGNS_ACTION_FINISH,
    SET_CAMPAIGNS,
    SET_CAMPAIGNS_SORTING,
    SET_CAMPAIGNS_FILTERS,
    SET_CAMPAIGNS_SAVE_ACTION_BEFORE,
    SET_CAMPAIGNS_SAVE_ACTION_FINISH,
    SET_CAMPAIGN_GENERAL_INFO,
    UPDATE_CAMPAIGN_STATE,
    SET_CAMPAIGN_CREATIVES,
    SET_CAMPAIGN_CREATIVES_SORTING,
    SET_CAMPAIGN_CREATIVES_FILTERS,
    SET_CREATIVE_HTML_ACTION_BEFORE,
    SET_CREATIVE_HTML_ACTION_FINISH,
    SET_CREATIVE_HTML
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_CAMPAIGNS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_CAMPAIGNS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_CAMPAIGNS:
            return {
                ...state,
                campaigns: !payload.add ? payload.campaigns.item2 : state.campaigns.concat(payload.campaigns.item2),
                total: payload.campaigns.item1,
            };
        case SET_CAMPAIGNS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_CAMPAIGNS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_CAMPAIGNS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_CAMPAIGNS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_CAMPAIGN_GENERAL_INFO:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...payload.info
                    }
                }
            }
        case UPDATE_CAMPAIGN_STATE:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...state.edit.general,
                        state: payload.status
                    }
                }
            }
        case SET_CAMPAIGN_CREATIVES:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    creatives: {
                        ...state.edit.creatives,
                        creatives: !payload.add ? payload.creatives.item2 : state.edit.creatives.creatives.concat(payload.creatives.item2),
                        total: payload.creatives.item1,
                    },
                },
            };
        case SET_CAMPAIGN_CREATIVES_SORTING:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    creatives: {
                        ...state.edit.creatives,
                        sorting: payload.sorting
                    },
                },
            };
        case SET_CAMPAIGN_CREATIVES_FILTERS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    creatives: {
                        ...state.edit.creatives,
                        filters: payload.filters,
                        sorting: {
                            ...state.edit.creatives.sorting,
                            page: 1
                        }
                    },
                },
            };
        case SET_CREATIVE_HTML_ACTION_BEFORE:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    creatives: {
                        ...state.edit.creatives,
                        isHtmlLoading: true
                    },
                },
            };
        case SET_CREATIVE_HTML_ACTION_FINISH:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    creatives: {
                        ...state.edit.creatives,
                        isHtmlLoading: false
                    },
                },
            };
        case SET_CREATIVE_HTML:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    creatives: {
                        ...state.edit.creatives,
                        htmls: {
                            ...state.edit.creatives.htmls,
                            [payload.id]: payload.data
                        },

                    },
                },
            };
        default:
            return state;
    }
};
