//#region react
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
//#endregion

//#region actions
import {
    getAffiliateManagerAffiliates,
    deleteAffiliateManagerAffiliate,
    setAffiliateManagerAffiliatesFilters,
    setAffiliateManagerAffiliatesSorting
} from "store/actions/portal/userManagement/affiliateManagers/affiliates.action";
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import AffiliateAddComponent from "./affiliate-add.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { AFFILIATE_MANAGER_AFFILIATES } from 'constants/pageName.constants';
import { tableColumns } from './columns';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
//#endregion

//#region types
import affiliateType from 'types/affiliate/generalInfo.type';
import sortingType from 'types/common/sorting.type';
//#endregion

/** Affiliate Manager Edit Page Affilaites Tab Component */
const AffiliatesComponent = ({
    affiliates,
    getAffiliateManagerAffiliates,
    deleteAffiliateManagerAffiliate,
    setAffiliateManagerAffiliatesFilters,
    setAffiliateManagerAffiliatesSorting,
    isLoading,
    sorting,
    filters,
    total
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    // State to show/hide company add/edit popup
    const [isAddPopupVisible, setIsAddPopupVisible] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({
        pageName: AFFILIATE_MANAGER_AFFILIATES
    });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasCreatePermission = hasPermission({
        resource: PERMISSION_RESOURCE.AFFILIATE_MANAGER_AFFILIATES,
        action: PERMISSION_ACTION.MODIFY
    })

    const hasDeletePermission = hasPermission({
        resource: PERMISSION_RESOURCE.AFFILIATE_MANAGER_AFFILIATES,
        action: PERMISSION_ACTION.MODIFY
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setAffiliateManagerAffiliatesFilters({
            ...filters,
            nameOrId: value
        })
    }

    const showAddPopup = () => {
        setIsAddPopupVisible(true);
    }

    const hideAddPopup = () => {
        setIsAddPopupVisible(false);
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns, columnsThatCanBeIncluded } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: tableColumns,
            constructForInclude: true,
            includedColumns,
        });
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasDeletePermission) {
        tableRowActions.push({
            icon: "trash",
            title: t('backoffice.common.delete'),
            confirmationTitle: t("backoffice.users.removeAffiliate"),
            confirmationMessage: t("backoffice.users.doYouWanttoRemoveAffiliate"),
            showConfirmation: true,
            onClick: record => handleDelete(searchParams.id, record.id),
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        search: {
            placeholder: t("backoffice.users.userNameOrId"),
            onSearch: handleSearchChange,
            loadFn: () => getAffiliateManagerAffiliates(searchParams.id),
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.AFFILIATES
            }
        },
        button: {
            icon: "plus",
            type: "primary",
            text: t("backoffice.users.addAffiliate"),
            onClick: showAddPopup,
            enabled: hasCreatePermission
        },
    }

    //#endregion

    // Close user add/edit popup, after save
    useEffect(() => {
        hideAddPopup()
    }, [affiliates])

    /** Actions */
    const handleDelete = (managerId, id) => {
        deleteAffiliateManagerAffiliate(managerId, id)
    }

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={affiliates}
                loadFn={nextPage => getAffiliateManagerAffiliates(searchParams.id, nextPage)}
                setSortingFn={setAffiliateManagerAffiliatesSorting}
                sorting={sorting}
                total={total}
                disableFullView={true}
                actions={tableRowActions}
            />

            {isAddPopupVisible && <AffiliateAddComponent onClose={hideAddPopup} />}
        </TabTableDashboardLayout>
    )
}

/** AffiliatesComponent propTypes
    * PropTypes
*/
AffiliatesComponent.propTypes = {
    /** Redux action to get affiliate manager affiliates */
    getAffiliateManagerAffiliates: PropTypes.func,
    /** Redux action to delete affiliate from affiliate manager */
    deleteAffiliateManagerAffiliate: PropTypes.func,
    /** Redux state property, is true when affiliate manager affiliates is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the users of current editing affiliate manager affiliates  */
    affiliates: PropTypes.arrayOf(affiliateType),
    /** Redux action to set affiliate manager affiliates sorting */
    setAffiliateManagerAffiliatesSorting: PropTypes.func,
    /** Redux action to set affiliate manager affiliates filters */
    setAffiliateManagerAffiliatesFilters: PropTypes.func,
    /** Redux state property, represents the sorting data of affiliates of current editing affiliate manager */
    sorting: sortingType,
    /** Redux state property, represents the filters data of affiliates of current editing affiliate manager */
    filters: PropTypes.object,
    /** Redux state property, represents the total numbers of affiliates of current editing affiliate manager */
    total: PropTypes.number
}

const mapDispatchToProps = dispatch => (
    {
        getAffiliateManagerAffiliates: (managerId, nextPage) => {
            dispatch(getAffiliateManagerAffiliates(managerId, nextPage));
        },

        deleteAffiliateManagerAffiliate: (managerId, id) => {
            dispatch(deleteAffiliateManagerAffiliate(managerId, id));
        },

        setAffiliateManagerAffiliatesSorting: sorting => {
            dispatch(setAffiliateManagerAffiliatesSorting(sorting))
        },

        setAffiliateManagerAffiliatesFilters: filters => {
            dispatch(setAffiliateManagerAffiliatesFilters(filters))
        },
    }
)

const mapStateToProps = state => {
    return {
        affiliates: state.affiliateManagers.edit.affiliates.affiliates,
        isLoading: state.affiliateManagers.isLoading,
        sorting: state.affiliateManagers.edit.affiliates.sorting,
        filters: state.affiliateManagers.edit.affiliates.filters,
        total: state.affiliateManagers.edit.affiliates.total
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AffiliatesComponent)