import { useContext, useEffect } from "react";

import UnsavedChangesContext from "components/common/unsavedChangesProvider/unsavedChangesContext";

const useUnsavedChanges = (name = "") => {
    const {
        register,
        remove,
        setToStore,
    } = useContext(UnsavedChangesContext)

    const setToStoreWrapped = (changed) => {
        setToStore(name, changed);
    }

    useEffect(() => {
        register(name);

        return () => {
            remove(name);
        }
    }, []);

    return {
        setToStore: setToStoreWrapped,
    };
}

export default useUnsavedChanges;