import PropTypes from 'prop-types';

export default PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    lastTotal: PropTypes.number,
    lastTotalFailed: PropTypes.number,
    lastTotalSuccess: PropTypes.number,
    total: PropTypes.number,
    totalFailed: PropTypes.number,
    totalSuccess: PropTypes.number,
    metrics: PropTypes.object
})