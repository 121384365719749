export const NOTIFICATION_STATE_ENUM = {
    UNREAD: 1,
    READ: 2
}

export const NOTIFICATION_TYPE = {
    LIMIT: 1,
    COMMISSION_PLAN_CHANGE: 2,
    REQUEST_PROCESSED: 8,
    ACCESS_MANAGER_REQUEST: 16
}