//#region react
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
//#endregion

//#region actions
import {
    getProjectPaymentMethods,
    deleteProjectPaymentCurrency
} from "store/actions/portal/projects/paymentMethods.action";
//#endregion

//#region components
import SubTabTableDashboardLayout from "components/layouts/tab/subtab/table";
import Table from "components/common/table";
import Icon from "components/common/icon";
import AddCurrency from "./currency-add.component";
import EditCurrency from "./currency-edit.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { deleteQueryStringParamsToUrl } from 'utils/queryString';
//#endregion

//#region constants
import { PROJECT_PAYMENTS_EDIT } from 'constants/pageName.constants';
import { getTableColumns } from './columns';
//#endregion

//#region hooks
import useFormat from 'hooks/useFormat';
//#endregion

//#region types
import { paymentCurrencyType } from 'types/project/paymentMethod.type';
//#endregion


/** Project Edit Page Payment Method Edit Component */
const PaymentMethodEdit = ({
    getProjectPaymentMethods,
    deleteProjectPaymentCurrency,
    isLoading,
    isSaving,
    currencies,
}) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const { search, hash, pathname } = useLocation();
    const navigate = useNavigate();

    const { formatAmount } = useFormat();

    const [isAddPopupVisible, setIsAddPopupVisible] = useState(null);
    const [editingCurrency, setEditingCurrency] = useState(null);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PROJECT_PAYMENTS_EDIT });

    const paymentId = searchParams.get("paymentId");

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns, columnsThatCanBeIncluded } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            constructForInclude: true,
            includedColumns,
            additionalProps: {
                formatAmount
            } 
        });
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [
        {
            title: `${t('backoffice.common.edit')}`,
            icon: "edit",
            onClick: currency => setEditingCurrency(currency)
        },
        {
            title: t('backoffice.common.delete'),
            confirmationMessage: t("backoffice.currencies.doYouWanttoDeleteCurrency"),
            confirmationTitle: t("backoffice.common.delete"),
            icon: "trash",
            showConfirmation: true,
            className: "rt--button-danger",
            disabled: record => currencies.length === 1 || record.affiliatesCount > 0,
            onClick: ({ currencyCode }) => {
                deleteProjectPaymentCurrency({ currencyCode, id: paymentId }, () => getProjectPaymentMethods(paymentId))
            }
        }
    ];

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            icon: "plus",
            type: "primary",
            onClick: () => setIsAddPopupVisible(true),
            text: t("backoffice.projects.addCurrency"),
            enabled: true
        }
    }

    //#endregion

    return (
        <SubTabTableDashboardLayout
            header={headerPartsData}
            buttons={
                [
                    {
                        type: "secondary",
                        onClick: () => {
                            const url = deleteQueryStringParamsToUrl(pathname, search, hash, ["paymentId", "paymentName"] );
                            navigate(url, { replace: true } );
                        },
                        text: (
                            <span className='rt--back-button'>
                                <Icon name="left" size={16} />
                                <span>
                                    {t("backoffice.common.back")}
                                </span>
                            </span>
                        ),
                        enabled: true,
                        className: "rt--button-secondary",
                        placement: "left",
                    },
                ]
            }
        >
            <Table
                loading={isLoading || isSaving}
                columns={mainTableColumns}
                data={currencies}
                loadFn={() => getProjectPaymentMethods(paymentId)}
                noPagination={true}
                disableFullView={true}
                actions={tableRowActions}
            />
            {isAddPopupVisible && (
                <AddCurrency paymentCurrencies={currencies} onClose={() => setIsAddPopupVisible(false)} />
            )}
            {editingCurrency !== null && (
                <EditCurrency currency={editingCurrency} onClose={() => setEditingCurrency(null)} />
            )}
        </SubTabTableDashboardLayout>
    )
}

PaymentMethodEdit.propTypes = {
    /** Redux action to get Project Payment Details */
    getProjectPaymentMethods: PropTypes.func,
    /** Redux action to delete Project Payment currency */
    deleteProjectPaymentCurrency: PropTypes.func,
    /** Redux state property, is true when Project Payment Methods are loading */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when Project Payment Currency Delete is in Progress */
    isSaving: PropTypes.bool,
    /** Redux state, represents the currencies of current editing Payment Method  */
    currencies: PropTypes.arrayOf(paymentCurrencyType),
}

const mapDispatchToProps = dispatch => (
    {
        getProjectPaymentMethods: paymentId => {
            dispatch(getProjectPaymentMethods(paymentId));
        },
        deleteProjectPaymentCurrency: (params, onSuccess) => {
            dispatch(deleteProjectPaymentCurrency(params, onSuccess));
        }
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.projects.isLoading,
        isSaving: state.projects.isSaving,
        currencies: state.projects.edit.paymentMethods.edit.paymentMethod.currencies
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodEdit)