import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import dateService from "utils/date"

export const getOperationalPieChartData = ({ entityType }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_OPERATIONS_PIE_CHART,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            entityType
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getOperationalLineChartData = ({ dataType, timePeriod, from, to }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_OPERATIONS_LINE_CHART,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            dataType,
            timePeriod,
            from,
            to,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getOperationalTopAffiliates = ({ dataType, timePeriod, from, to }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_OPERATIONS_TOP_AFFILIATES,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            dataType, 
            timePeriod,
            from,
            to,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getFinancialLiveBalances = () => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_WALLETS,
        method: Methods.GET,
        signal: abortController.signal,
        params: {}
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getFinancialLineChartData = ({ dataType, timePeriod, from, to }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_FINANCIAL_LINE_CHART,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            dataType,
            timePeriod,
            from,
            to,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getFinancialTopAffiliates = ({ dataType, currencyCode, timePeriod, from, to }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_FINANCIAL_TOP_AFFILIATES,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            dataType, 
            currencyCode,
            timePeriod,
            from,
            to,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getFinancialTopPlayers = ({ dataType, currencyCode, timePeriod, from, to }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_FINANCIAL_TOP_PLAYERS,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            dataType, 
            currencyCode,
            timePeriod,
            from,
            to,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}