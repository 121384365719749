import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Tabs, Spin } from 'antd';

import {
    getPromoSectionTranslations,
} from 'store/actions/portal/promoCustomize/sections.action';

import Contacts from './contacts';
import PrivacyPolicy from './privacyPolicy';
import TermsAndConditions from './termsAndConditions';

import { sendToPromoIframe } from 'utils/promo';

import { PROMO_SECTION_TYPE, PROMO_UPDATE_TYPE, SECTION_NAMES } from 'constants/promo.constants';

const TAB_KEYS = {
    CONTACTS: "contacts",
    PRIVACY_POLICY: "privacy",
    TERMS_AND_CONDITIONS: "terms",
}

const Footer = ({
    isLoading,
    isSaving,
    getPromoSectionTranslations,
    customizeLanguage,
    onTabChange,
}) => {
    const { t } = useTranslation();

    // Get Footer Translations
    useEffect(() => {
        getPromoSectionTranslations(
            SECTION_NAMES.FOOTER,
            PROMO_SECTION_TYPE.FOOTER,
        );
    }, [customizeLanguage]);

    return (
        <Spin spinning={isLoading || isSaving}>
            <Tabs 
                animated={false} 
                className='rt--tabs'
                onTabClick={k => {
                    sendToPromoIframe(PROMO_UPDATE_TYPE.SCROLL_TO_SECTION, {
                        sectionType: k === "contacts" ? PROMO_SECTION_TYPE.FOOTER : k
                    })
                }}
            >
                <Tabs.TabPane
                    key={TAB_KEYS.CONTACTS}
                    tab={(<span>{t("backoffice.promo.contacts")}</span>)}
                >
                    <div className="rt--tabs-contact rt--mt-24">
                        <Contacts onTabChange={onTabChange} />
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                    key={TAB_KEYS.PRIVACY_POLICY}
                    tab={(<span>{t("backoffice.promo.privacyPolicy")}</span>)}
                >
                    <div className="rt--mt-24">
                        <PrivacyPolicy />
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                    key={TAB_KEYS.TERMS_AND_CONDITIONS}
                    tab={(<span>{t("backoffice.promo.termsAndConditions")}</span>)}
                >
                    <div className="rt--mt-24">
                        <TermsAndConditions />
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </Spin>
    );
}


/** Footer propTypes
 * PropTypes
*/
Footer.propTypes = {
    /** Redux state property, is true when loading portal customization data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when promo saving */
    isSaving: PropTypes.bool,
    /** Redux action to get promo section translations */
    getPromoSectionTranslations: PropTypes.func,
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    getPromoSectionTranslations: (sectionName, sectionType) => {
        dispatch(getPromoSectionTranslations(sectionName, sectionType))
    }
});

const mapStateToProps = (state) => {
    return {
        customizeLanguage: state.promoCustomize.customizeLanguage,
        isLoading: state.promoCustomize.isLoading,
        isSaving: state.promoCustomize.isSaving,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);