import PropTypes from 'prop-types';

import { CREATIVE_TYPE, MEDIA_TYPE } from 'constants/campaign.constants';
import { MEDIA_CLICK_STATE } from 'constants/reports.constants';

export default PropTypes.shape({
    id: PropTypes.number,
    clickDate: PropTypes.string,
    status: PropTypes.oneOf(Object.values(MEDIA_CLICK_STATE)),
    affiliateId: PropTypes.string,
    affiliateUserName: PropTypes.string,
    brandId: PropTypes.string,
    brandName: PropTypes.string,
    campaignId: PropTypes.string,
    campaignName: PropTypes.string,
    mediaId: PropTypes.string,
    mediaName: PropTypes.string,
    mediaType: PropTypes.oneOf(Object.values(MEDIA_TYPE)),
    ipAddress: PropTypes.string,
    countryCode: PropTypes.string,
    redirectingURL: PropTypes.string,
    creativeId: PropTypes.string,
    creativeName: PropTypes.string,
    creativeType: PropTypes.oneOf(Object.values(CREATIVE_TYPE)),
    browserName: PropTypes.string,
    browserVersion: PropTypes.string,
    os: PropTypes.string,
    deviceType: PropTypes.string,
    referrer: PropTypes.string
})