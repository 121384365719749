import i18n from "translations/config";

import { REQUEST_DIRECTION } from "constants/project.constants";

export const tableColumns = [
	{
		title: "backoffice.errorlogs.platform",
		dataIndex: "name",
		key: "name",
		alwaysVisible: true,
		mobileLevel: 1,
	},
	{
		title: "backoffice.errorlogs.action",
		dataIndex: "action",
		render: (_, record) => record?.request.action ?? "",
		mobileLevel: 2,
	},
	{
		title: "backoffice.errorlogs.method",
		dataIndex: "method",
		mobileLevel: 3,
	},
	{
		title: "backoffice.errorlogs.project",
		dataIndex: "projectName",
		key: "projectName",
		alwaysVisible: true,
		mobileLevel: 4,
	},
	{
		title: "backoffice.errorlogs.status",
		dataIndex: "httpStatusCode",
		mobileLevel: 5,
		render: (value, record) => `${value} - ${record.httpStatus}`,
		tooltip: (_, record) => record.httpStatusDescription,
	},
	{
		title: "backoffice.errorlogs.direction",
		dataIndex: "requestDirection",
		render: (value) =>
			value === REQUEST_DIRECTION.IN
				? i18n.t("backoffice.errorlogs.in")
				: i18n.t("backoffice.errorlogs.out"),
		mobileLevel: 6,
	},
	{
		title: "backoffice.errorlogs.time",
		dataIndex: "createdAt",
		isDateTime: true,
	},
];
