import PropTypes from 'prop-types';

import { COMPANY_CONTROL_TYPE, COMPANY_CONTROL_RULE } from "constants/company.constants"

export default PropTypes.shape({
    name: PropTypes.string,
    controlType: PropTypes.oneOf(Object.values(COMPANY_CONTROL_TYPE)),
    defaultValue: PropTypes.string,
    rule: PropTypes.number,
    selectedRule: PropTypes.oneOf(Object.values(COMPANY_CONTROL_RULE)),
    regExp: PropTypes.string,
    preValidationRule: PropTypes.number,
    isUnique: PropTypes.bool
})