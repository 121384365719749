import React from "react";

import { useTranslation } from "react-i18next";

import Tabs from 'components/common/tabs';

import PersonalInformationComponent from "./personalInformation/personalInformation.component";
import FormatSettingsComponent from "./formatSettings/formatSettings.component";

/** Profile Page Component */
const ProfileComponent = () => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.players.general"),
            component: <PersonalInformationComponent/>
        },
        {
            title: t("backoffice.profile.formatSettings"),
            component: <FormatSettingsComponent />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={{ title: t('backoffice.menu.profile') }}
        />
    )

};

export default ProfileComponent;
