import React from "react"
import PropTypes from "prop-types";

/** Sub Tab layout */
const SubTabLayout = ({
    children
}) => {

    return (
        <div className="rt--subtab-layout">
            {
                children
            }
        </div>
    )
}

/** SubTabLayout propTypes
 * PropTypes
*/
SubTabLayout.propTypes = {
    /** Layout Content */
    children: PropTypes.node
}

export default SubTabLayout;