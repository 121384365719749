import React from 'react';

import PropTypes from 'prop-types';

const mapping = {
    "ar": "ar.svg",
    "bg": "bg.svg",
    "de": "de.svg",
    "en": "en.svg",
    "es": "es.svg",
    "fa": "fa.svg",
    "fr": "fr.svg",
    "he": "he.svg",
    "hy": "hy.svg",
    "ko": "ko.svg",
    "pt": "pt.svg",
    "ro": "ro.svg",
    "ru": "ru.svg",
    "tr": "tr.svg",
    "zh": "zh.svg"
}

/** Flag Component */
const Flag = ({
    code,
}) => {

    const flagImg = code ? mapping[code.toLowerCase()] : null

    return (
        <div>
             {
                flagImg ? '' : (
                    <span className='rt--flag-noIcon'>{code}</span>
                )
            }
            
            <div
                className="rt--flag-icon"
                style={
                    flagImg ? {
                        backgroundImage: `url(${import.meta.env.SYSTEM_CDN_URL}/system/static/images/flags/${flagImg})`
                    } : {}
                }
            />
        </div>
    )
}

/** Flag propTypes
    * PropTypes
*/
Flag.propTypes = {
    /** Flag code */
    code: PropTypes.string,
    /** For add styles to container */
    className: PropTypes.string,
}

export default Flag;