import PropTypes from 'prop-types';
import registrationFormType from 'types/project/registrationForm.type';

export default PropTypes.shape({
    id: PropTypes.string,
    longId: PropTypes.number,
    name: PropTypes.string,
    logoPath: PropTypes.string,
    currencyDetails: PropTypes.arrayOf(
        PropTypes.shape({
            currencyCode: PropTypes.string,
            commission: PropTypes.number,
            minThreshold: PropTypes.number
        })
    ),
    formControls: PropTypes.arrayOf(registrationFormType),
    translations: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            text: PropTypes.string,
            translations: PropTypes.arrayOf(
                PropTypes.shape({
                    languageCode: PropTypes.string,
                    text: PropTypes.string,
                    source: PropTypes.number
                })
            )
        })
    )
})