import React from "react";

import CompaniesEditComponent from "pages/companies/edit/company-edit.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";
import Paths from "constants/path.constants";

const CompaniesEditRoute = () => {
    return <CompaniesEditComponent />;
};

export default withPermission(
    withAuth(CompaniesEditRoute),
    [
        {
            resource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO,
            action: PERMISSION_ACTION.VIEW,
        },
    ],
    Paths.PORTAL
);
