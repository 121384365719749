import React from "react";

import { useTranslation } from "react-i18next";

import Tabs from "components/common/tabs";

import NewslettersComponent from "./newsletters/newsletters.component";
import TemplatesComponent from "./templates/templates.component";

/** NewsLetter Page Component */
const NewsLetterComponent = () => {

    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.communication.mail"),
            component: <NewslettersComponent />,
            pathParams: { name: "newsletterName", id: "newsletterId" }
        },
        {
            title: t("backoffice.communication.newsletters"),
            component: <TemplatesComponent />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={[
                { title: t('backoffice.menu.communications') },
                { title: t('backoffice.menu.newsletter') }
            ]}
        />
    )
};

export default NewsLetterComponent;
