import React from 'react';

import { Skeleton } from 'antd';

const SKELETON_ITEMS = [1, 2, 3, 4, 5];

const MenuSkeleton = () => {
    return (
        <div className="rt--customize-widget-main-menu rt--pt-8">
                {SKELETON_ITEMS.map(item => {
                    return (
                        <div key={item} className='rt--mr-8 rt--ml-8 rt--mb-8'>
                            <Skeleton.Button active={true} size={"large"} block={true} />
                        </div>
                    )
                })}
            </div>
    );
}
 
export default MenuSkeleton;